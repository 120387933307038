import {
  LapHutangSupplierPage,
  LaporanKomisiPage,
} from "common/form-item-generator/common-informasi"
import {
  InformasiRatePajakFormItem,
  InformasiRateValasFormItem,
} from "common/form-item-generator/informasi"
import { GeneralMap, PoTypeEnum } from "common/type"
import {
  formatKartuStockDisplay,
  formatNumber,
  formatOnlyDate,
  genFieldQueryParamObj,
  genFieldQueryParamStr,
} from "common/util"
import { TableCell } from "components/data-table"
import { InformasiPageProps } from "components/page/type"
import { TagihanLaporanPages } from "../../common/form-item-generator/tagihan"

export const InformasiPages: GeneralMap<InformasiPageProps> = {
  "info-barang": {
    title: "Barang",
    permissionRequired: "Barang",
    filterName: "infoBarang",
    pdfUrl: "/barang/download",
    fields: [
      {
        dataKey: "kode",
        label: "Kode",
      },
      {
        dataKey: "nama",
        label: "Nama Barang",
      },
      {
        dataKey: "subKelompok.kelompokUtama.nama",
        label: "Kelompok",
      },
      {
        dataKey: "satuanStock.nama",
        label: "Satuan",
      },
    ],
    sourceUrl: "barang",
  },
  "info-pelanggan": {
    title: "Pelanggan",
    permissionRequired: "Pelanggan",
    filterName: "cabangOnly",
    pdfUrl: "/pelanggan/download",
    fields: [
      {
        dataKey: "kodePelanggan",
        label: "Kode",
      },
      {
        dataKey: "namaPanjang",
        label: "Nama Pelanggan",
      },
      {
        dataKey: "kota",
        label: "Kota",
      },
      {
        dataKey: "phone",
        label: "Phone",
      },
    ],
    sourceUrl: "pelanggan",
  },
  "info-supplier": {
    permissionRequired: "Supplier",
    title: "Supplier",
    sourceUrl: "supplier",
    filterName: "isSupplierImport",
    pdfUrl: "/supplier/download",
    fields: [
      {
        dataKey: "kodeSupplier",
        label: "Kode",
      },
      {
        dataKey: "namaPanjang",
        label: "Nama Supplier",
      },
      {
        dataKey: "kota",
        label: "Kota",
      },
      {
        dataKey: "phone",
        label: "Phone",
      },
    ],
  },
  "informasi-rate-valas": InformasiRateValasFormItem,
  "rate-pajak": InformasiRatePajakFormItem,
  "info-po": {
    title: "Informasi PO",
    permissionRequired: "InformasiPO",
    children: [
      "pencarian-po-ttlc",
      "info-po-ttlc-pusat",
      "info-po-ttlc-cabang",
      "info-kb-ttlc",
    ],
  },
  "pencarian-po-ttlc": {
    title: "Pencarian Data Informasi PO TT/LC Berdasarkan Nomor SC",
    sourceUrl: `purchase-order`,
    sourceParam: genFieldQueryParamObj({
      poType: PoTypeEnum.TTLC,
    }),
    permissionRequired: "Penawaran",
    filterName: "pencarianPoTTLC",
    fields: [
      {
        dataKey: "noPo",
        label: "Nomor PO",
      },
      {
        dataKey: "tanggal",
        label: "Tanggal PO",
      },
      {
        dataKey: "niaiPo",
        label: "Nilai PO",
      },
      {
        dataKey: "salesContract.noSalesContract",
        label: "No. Sales Contract",
      },
      {
        dataKey: "salesContract.tanggal",
        label: "Tanggal SC",
      },
      {
        dataKey: "nilaiSc",
        label: "Nilai SC",
      },
      {
        dataKey: "payment",
        label: "Payment",
      },
      {
        dataKey: "tanggalBayar",
        label: "Tanggal Bayar",
      },
    ],
    detailInfo: {
      childTables: [
        {
          id: "pengiriman",
          title: "Shipment",
          fields: [
            {
              dataKey: "tglShipment",
              label: "Tgl. Ship.",
            },
            {
              dataKey: "itemPurchaseOrderStock.itemPenawaran.barang.nama",
              label: "Nama Barang",
            },
            {
              dataKey: "itemPurchaseOrderStock.itemPenawaran.qty",
              label: "Qty",
              type: "number",
            },
            {
              dataKey: "keterangan",
              label: "Ket.",
            },
            {
              dataKey: "orderConfirmation",
              label: "OC",
            },
            {
              dataKey: "tglInvoice",
              label: "Tgl. Inv",
            },
            {
              dataKey: "noInvoice",
              label: "Invoice",
            },
            { dataKey: "tglKeluar", label: "Tgl. Kel." },
          ],
        },
        {
          id: "pembayarans",
          title: "Payment",
          fields: [
            {
              dataKey: "tanggal",
              label: "tanggal",
            },
            {
              dataKey: "jlhBayar",
              label: "Jumlah Bayar",
              type: "number",
            },
            { dataKey: "jenisPembayaran", label: "Jenis Bayar" },
          ],
        },
        {
          id: "pengajuanKbs",
          title: "Pembayaran KB",
          fields: [
            {
              dataKey: "tanggal",
              label: "tanggal",
            },
            {
              dataKey: "nilaiKb",
              label: "Jumlah Bayar KB",
              type: "number",
            },
            { dataKey: "keterangan", label: "Keterangan" },
          ],
        },
      ],
    },
  },
  "info-po-ttlc-pusat": {
    title: "PO TT/LC Pusat",
    permissionRequired: "PurchaseOrder",
    sourceUrl: `purchase-order?${genFieldQueryParamStr({
      poType: "TTLC",
    })}`,
    fields: [
      {
        dataKey: "tanggal",
        label: "Tanggal PO",
      },
      {
        dataKey: "noPo",
        label: "Nomor PO",
      },
      {
        dataKey: "nilaiPO",
        label: "Nilai PO",
      },
      {
        dataKey: "salesContract.penawaran.pelanggan.namaPanjang",
        label: "Pelanggan",
      },
      {
        dataKey: "salesContract.supplier.namaPanjang",
        label: "Supplier",
      },
      {
        dataKey: "salesContract.penawaran.sales.nama",
        label: "Sales",
      },
      {
        dataKey: "salesContract.noPoPelanggan",
        label: "PO Cust.",
      },
    ],
  },
  "info-po-ttlc-cabang": {
    permissionRequired: "PurchaseOrder",
    title: "PO TT/LC Cabang",
    sourceUrl: `purchase-order?${genFieldQueryParamStr({
      poType: "TTLC",
    })}`,
    fields: [
      {
        dataKey: "salesContract.tanggal",
        label: "Tanggal SC",
      },
      {
        dataKey: "salesContract.noSalesContract",
        label: "No. Sales Kontrak",
      },
      {
        dataKey: "nilaiSC",
        label: "Nilai SC",
      },
      {
        dataKey: "salesContract.penawaran.pelanggan.namaPanjang",
        label: "Pelanggan",
      },
      {
        dataKey: "supplier.namaPanjang",
        label: "Supplier",
      },
      {
        dataKey: "salesContract.penawaran.sales.nama",
        label: "Sales",
      },
      {
        dataKey: "salesContract.noPoPelanggan",
        label: "PO Cust.",
      },
    ],
  },
  "info-kb-ttlc": {
    title: "Laporan KB TT/LC",
    permissionRequired: "PurchaseOrder",
    filterName: "periodeOnly",
    // check is this from pembayaran sales contract
    sourceUrl: `purchase-order?${genFieldQueryParamStr({
      poType: "TTLC",
    })}`,
    fields: [
      {
        dataKey: "salesContract.penawaran.pelanggan.namaPanjang",
        label: "Nama Customer",
      },
      {
        dataKey: "noPo",
        label: "No. PO",
      },
      {
        dataKey: "salesContract.noSalesContract",
        label: "No. Informasi",
      },
      {
        dataKey: "tanggal",
        label: "Tanggal",
      },

      {
        dataKey: "salesContract.penawaran.mataUang.mataUang",
        label: "Curr",
      },
      {
        dataKey: "nilaiPo",
        label: "Nilai PO",
      },
      {
        dataKey: "salesContract.penawaran.kb",
        label: "KB",
      },
      {
        dataKey: "jumlahBayar",
        label: "Jumlah Bayar",
      },
      {
        dataKey: "nilaiKb",
        label: "Nilai KB",
      },
    ],
  },
  "jurnal-spb": {
    // TODO: handle show detail in info page
    title: "Jurnal SPB",
    filterName: "laporanSpb",
    permissionRequired: "SuratFabrikasiBarang",
    sourceUrl: "surat-fabrikasi-barang",
    pdfUrl: "/surat-fabrikasi-barang/laporan/download",
    excelUrl: "/surat-fabrikasi-barang/laporan/excel",
    fields: [
      {
        dataKey: "salesContract.penawaran.no",
        label: "No. Penawaran",
      },
      {
        dataKey: "noSpb",
        label: "No. SPB",
      },
      {
        dataKey: "tanggalSpb",
        label: "Tanggal SPB",
      },
      {
        dataKey: "salesContract.penawaran.sales.nama",
        label: "Nama Sales",
      },
      {
        dataKey: "salesContract.penawaran.pelanggan.namaPanjang",
        label: "Customers",
      },
      {
        dataKey: "salesContract.noPoPelanggan",
        label: "PO Cust.",
      },
    ],
  },
  "jurnal-sc": {
    title: "Jurnal SC",
    permissionRequired: "SalesContract",
    sourceUrl: "sales-contract",
    filterName: "jurnalSc",
    pdfUrl: "/sales-contract/laporan/download",
    excelUrl: "/sales-contract/laporan/excel",
    fields: [
      {
        dataKey: "penawaran.no",
        label: "No. Penawaran",
      },
      {
        dataKey: "noSalesContract",
        label: "Nomor SC",
      },
      {
        dataKey: "tanggal",
        label: "Tanggal SC",
      },
      {
        dataKey: "penawaran.sales.nama",
        label: "Nama Sales",
      },
      {
        dataKey: "penawaran.pelanggan.namaPanjang",
        label: "Customers",
      },
      {
        dataKey: "noPoPelanggan",
        label: "PO Cust.",
      },
    ],
  },
  "stock-barang": {
    permissionRequired: "StockBarang",
    title: "Stock Barang",
    children: [
      "laporan-stock-opname",
      "laporan-kartu-stock",
      "laporan-stock-barang",
      "laporan-stock-per-cabang",
      "laporan-stock-barang-jadi",
      "laporan-pps",
      "daftar-pemakaian-workshop",
    ],
  },
  "laporan-stock-opname": {
    title: "Laporan Stock Opname",
    permissionRequired: "StockBarang",
    sourceUrl: "",
    fields: [
      { dataKey: "", label: "No. BAPB/Stock" },
      { dataKey: "", label: "Tanggal" },
      { dataKey: "", label: "Kode Brg." },
      { dataKey: "", label: "Nama Barang" },
      { dataKey: "", label: "Panjang" },
      { dataKey: "", label: "Lebar" },
      { dataKey: "", label: "Qty" },
    ],
  },
  "laporan-kartu-stock": {
    title: "Laporan Kartu Stock",
    permissionRequired: "StockBarang",
    sourceUrl: "RiwayatStockBarang",
    filterName: "kartuStock",
    pdfUrl: "/RiwayatStockBarang/download",
    excelUrl: "/RiwayatStockBarang/excel",
    disablePagination: true,
    sourceParam: {
      sortBy: "createdAt",
      sortType: "asc",
    },
    disableAdd(data, filterData) {
      return !filterData?.["barang.id"] || !filterData?.["organisasi.id"]
    },
    requiredToAdd: ["Barang", "Organisasi"],
    responseMapper(data) {
      return {
        ...data,
        tanggal: formatOnlyDate(new Date(data.createdAt)),
        refId: data.refId,
        currStock: formatKartuStockDisplay(+data.currStock, +data.delta),
        currWaste: formatKartuStockDisplay(+data.currWaste, +data.waste),
        currSample: formatKartuStockDisplay(+data.currSample, +data.sample),
        currBarangJadi: formatKartuStockDisplay(
          +data.currBarangJadi,
          +data.barangJadi
        ),
      }
    },
    fields: [
      {
        dataKey: "tanggal",
        label: "Tanggal",
      },
      {
        dataKey: "refId",
        label: "No. SJ/BAPB",
      },
      {
        dataKey: "dariKepada",
        label: "Dari/Kepada",
      },
      {
        dataKey: "currStock",
        label: "Available",
      },
      {
        dataKey: "currWaste",
        label: "Waste",
      },
      {
        dataKey: "currSample",
        label: "Sample",
      },
      {
        dataKey: "currBarangJadi",
        label: "Barang Jadi",
      },
      {
        dataKey: "panjang",
        label: "Panjang",
        type: "number",
      },
      {
        dataKey: "lebar",
        label: "Lebar",
        type: "number",
      },
    ],
  },
  "laporan-stock-barang": {
    title: "Laporan Stock Barang",
    filterName: "laporanStockBarang",
    sourceUrl: "RiwayatStockBarang/laporan-stock",
    pdfUrl: "/RiwayatStockBarang/laporan-stock/download",
    excelUrl: "/RiwayatStockBarang/laporan-stock/excel",
    sourceParam: {
      sortBy: "barang.kode",
      sortType: "asc",
    },
    permissionRequired: "StockBarang",
    disablePagination: true,
    fields: [
      {
        dataKey: "namaSubkelompok",
        label: "Sub Kelompok Barang",
      },
      {
        dataKey: "kodeBarang",
        label: "Kode Brg",
      },
      {
        dataKey: "namaBarang",
        label: "Nama Barang",
      },
      {
        dataKey: "totalQty",
        label: "Total Stock",
      },
      {
        dataKey: "kodeSatuan",
        label: "Satuan",
      },
    ],
  },
  "laporan-stock-per-cabang": {
    title: "Laporan Stock per Cabang",
    permissionRequired: "StockBarang",
    filterName: "laporanStockPerCabang",
    sourceUrl: "RiwayatStockBarang/laporan-stock/per-cabang",
    pdfUrl: "/RiwayatStockBarang/laporan-stock/per-cabang/download",
    excelUrl: "/RiwayatStockBarang/laporan-stock/per-cabang/excel",
    sourceParam: {
      sortBy: "barang.kode",
      sortType: "asc",
    },
    disablePagination: true,
    fields: [
      {
        dataKey: "namaSubkelompok",
        label: "Sub Kelompok Barang",
      },
      {
        dataKey: "kodeBarang",
        label: "Kode Brg",
      },
      {
        dataKey: "namaBarang",
        label: "Nama Barang",
      },
      {
        dataKey: "totalQty",
        label: "Total Stock",
      },
      {
        dataKey: "kodeSatuan",
        label: "Satuan",
      },
    ],
  },
  "laporan-stock-barang-jadi": {
    title: "Laporan Stock Barang Jadi",
    sourceUrl: "surat-fabrikasi-barang",
    permissionRequired: "StockBarang",
    filterName: "laporanStockBarangJadi",
    fields: [],
  },
  "laporan-pps": {
    title: "Laporan PPS",
    permissionRequired: "LaporanPps",
    filterName: "laporanPps",
    sourceUrl: "item-pengadaan-stock",
    excelUrl: "/item-pengadaan-stock/laporan/excel",
    fields: [
      {
        dataKey: "pengadaan.no",
        label: "No. PPS",
      },
      {
        dataKey: "pengadaan.tanggal",
        label: "Tanggal PPS",
      },
      {
        dataKey: "barang.kode",
        label: "Kode Brg",
      },
      {
        dataKey: "barang.nama",
        label: "Nama Brg",
      },
      {
        dataKey: "qty",
        label: "Jumlah",
      },
    ],
  },
  "daftar-pemakaian-workshop": {
    title: "Daftar Pemakaian Barang Stock di Workshop",
    sourceUrl: "",
    permissionRequired: "WorkOrderSfb",
    fields: [
      {
        dataKey: "",
        label: "Nomor",
      },
      {
        dataKey: "",
        label: "Yang Meminta",
      },
      {
        dataKey: "",
        label: "Keterangan",
      },
    ],
  },
  "laporan-progress-penawaran": {
    title: "Laporan Progress Penawaran",
    permissionRequired: "Penawaran",
    sourceUrl: "penawaran/laporan-progress",
    filterName: "progressPenawaran",
    pdfUrl: "/penawaran/laporan/download",
    fields: [
      {
        dataKey: "no",
        label: "No. Penawaran",
      },
      {
        dataKey: "tanggal",
        label: "Tanggal",
      },
      {
        dataKey: "salesContracts.0.noSalesContract",
        label: "No. Sales Kontrak",
      },
      {
        dataKey: "salesContracts.0.tanggal",
        label: "Tanggal SC",
      },
      {
        dataKey: "salesContracts.0.purchaseOrders.0.noPo",
        label: "No. Purchase Order",
      },
      {
        dataKey: "salesContracts.0.purchaseOrders.0.tanggal",
        label: "Tanggal PO",
      },
      {
        dataKey: "salesContracts.0.suratJalanCustomers.0.no",
        label: "No. Surat Jalan",
      },
      {
        dataKey: "salesContracts.0.noPoPelanggan",
        label: "No. Invoice",
      },
      {
        dataKey: "salesContracts.0.suratJalanCustomers.0.tanggal",
        label: "Tanggal SJ",
      },
      {
        dataKey: "salesContracts.0.suratJalanCustomers.0.tanggalInvoice",
        label: "Tgl Kirim Inv.",
      },
    ],
  },
  "laporan-penawaran-barang": {
    title: "Laporan Penawaran Barang",
    permissionRequired: "Penawaran",
    sourceUrl: "item-penawaran/laporan-barang",
    pdfUrl: "item-penawaran/laporan-barang/download",
    filterName: "laporanBarangPenawaran",
    fields: [
      {
        dataKey: "penawaran.no",
        label: "No. Penawaran",
      },
      {
        dataKey: "",
        label: "Periode",
        render: (data) => {
          const d = new Date(data.penawaran?.tanggal)
          return (
            <TableCell>{`${String(d.getMonth() + 1).padStart(
              2,
              "0"
            )}-${d.getFullYear()}`}</TableCell>
          )
        },
      },
      {
        dataKey: "penawaran.tanggal",
        label: "Tanggal",
      },
      {
        dataKey: "barang.kode",
        label: "Kode Brg.",
      },
      {
        dataKey: "barang.nama",
        label: "Nama Barang",
      },
    ],
  },
  "laporan-komisi": LaporanKomisiPage,
  "laporan-biaya-supplier": {
    title: "Laporan Biaya Supplier",
    permissionRequired: "BiayaSupplier",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "No. Debit Note",
      },
      {
        dataKey: "",
        label: "Periode",
      },
      {
        dataKey: "",
        label: "Tanggal",
      },
      {
        dataKey: "",
        label: "Keterangan",
      },
      {
        dataKey: "",
        label: "Jumlah",
      },
    ],
  },
  ...TagihanLaporanPages("info-tagihan"),
  "info-penjualan": {
    title: "Penjualan",
    permissionRequired: "Penjualan",
    children: [
      "laporan-penjualan",
      "laporan-penjualan-perbulan",
      "laporan-penjualan-per-cust-produk",
      "laporan-jurnal-transaksi-penjualan",
      "laporan-omzet-penjualan-sales-cabang",
      "laporan-omzet-penjualan-per-subkelompok",
      "laporan-omzet-penjualan-ttlc",
      "laporan-omzet-penjualan-omset-type",
      "pembatalan-penjualan",
      "laporan-tracking-penjualan",
      "laporan-standing-wo",
    ],
  },
  "laporan-penjualan": {
    title: "Laporan Penjualan",
    filterName: "laporanPenjualan",
    sourceUrl: "laporan/penjualan",
    permissionRequired: "Penjualan",
    pdfUrl: "/laporan/penjualan/download",
    fields: [
      {
        dataKey:
          "suratJalanCustomer.salesContract.penawaran.pelanggan.namaPanjang",
        label: "Nama Customers",
      },
      {
        dataKey: "suratJalanCustomer.salesContract.noPoPelanggan",
        label: "No. Invoice",
      },
      {
        dataKey: "suratJalanCustomer.salesContract.penawaran.mataUang.mataUang",
        label: "Valas",
      },
      {
        dataKey: "suratJalanCustomer.salesContract.penawaran.rate",
        label: "Rate",
      },
      {
        dataKey: "itemPenawaran.barang.kode",
        label: "Kode Brg",
      },
      {
        dataKey: "itemPenawaran.barang.subKelompok.kode",
        label: "Kode Sub",
      },
      {
        dataKey: "itemPenawaran.barang.nama",
        label: "Nama Barang",
      },
      {
        dataKey: "itemPenawaran.qty",
        label: "Jumlah",
        type: "number",
      },
    ],
  },
  "laporan-penjualan-perbulan": {
    title: "Laporan Penjualan per Bulan",
    permissionRequired: "Penjualan",
    filterName: "laporanPenjualanPerBulan",
    sourceUrl: "laporan/penjualan",
    pdfUrl: "/laporan/penjualan-per-bulan/download",
    fields: [
      {
        dataKey: "itemPenawaran.barang.subKelompok.kode",
        label: "Sub Kelompok Barang",
      },
      {
        dataKey: "suratJalanCustomer.salesContract.penawaran.organisasi.nama",
        label: "Cabang",
      },
      {
        dataKey:
          "suratJalanCustomer.salesContract.penawaran.pelanggan.namaPanjang",
        label: "Customers",
      },
      {
        dataKey: "itemPenawaran.barang.kode",
        label: "Kode Brg.",
      },
      {
        dataKey: "itemPenawaran.barang.nama",
        label: "Nama Barang",
      },
      {
        dataKey: "itemPenawaran.qty",
        label: "Qty",
      },
      {
        dataKey: "itemPenawaran.barang.satuanJual.nama",
        label: "Satuan",
      },
    ],
  },
  "laporan-penjualan-per-cust-produk": {
    title: "Laporan Penjualan per Cust. & Produk",
    permissionRequired: "Penjualan",
    filterName: "laporanPenjualanPerCustProduk",
    sourceUrl: "laporan/penjualan",
    ignoreFilterParamOnFetch: ["jenisLaporan"],
    pdfUrl: "/laporan/penjualan-per-cust-produk/download",
    fields: [
      {
        dataKey: "itemPenawaran.barang.subKelompok.kelompokUtama.nama",
        label: "Kelompok Barang",
      },
      {
        dataKey: "itemPenawaran.barang.subKelompok.nama",
        label: "Sub Kelompok Barang",
      },
      {
        dataKey: "itemPenawaran.barang.kode",
        label: "Kode Brg.",
      },
      {
        dataKey: "itemPenawaran.barang.nama",
        label: "Nama Barang",
      },
      {
        dataKey: "itemPenawaran.barang.satuanJual.nama",
        label: "Satuan",
      },
      {
        dataKey: "itemPenawaran.qty",
        label: "Qty",
        type: "number",
      },
      {
        dataKey: "itemPenawaran.hargaJual",
        label: "Harga",
        type: "number",
      },
    ],
  },
  "laporan-jurnal-transaksi-penjualan": {
    title: "Laporan Jurnal Transaksi Penjualan",
    permissionRequired: "Penjualan",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "Kode",
      },
      {
        dataKey: "",
        label: "Nama Customers",
      },
      {
        dataKey: "",
        label: "No. Invoice",
      },
      {
        dataKey: "",
        label: "Tanggal SJ",
      },
      {
        dataKey: "",
        label: "Tgl Invoice",
      },
      {
        dataKey: "",
        label: "Tgl Terima Cust",
      },
      {
        dataKey: "",
        label: "Tgl Kontra Bon",
      },
      {
        dataKey: "",
        label: "Tgl Lunas",
      },
      {
        dataKey: "",
        label: "Nilai Invoice",
      },
    ],
  },
  "laporan-omzet-penjualan-sales-cabang": {
    title: "Laporan Omzet Penjualan Sales/Cabang",
    permissionRequired: "Penjualan",
    sourceUrl: "omset/sales-cabang",
    sourceParam: {
      sortBy: "sales.nama",
      sortType: "asc",
      // ...genFieldQueryParamObj({
      //   "itemSuratJalanCustomer.suratJalanCustomer.kontraBons": "notNull",
      // }),
    },
    pdfUrl: "/omset/sales-cabang/download",
    filterName: "laporanOmset",
    fields: [
      {
        dataKey: "namaCabang",
        label: "Cabang",
      },
      {
        dataKey: "namaDivisi",
        label: "Divisi",
      },
      {
        dataKey: "namaSales",
        label: "Nama Sales",
      },
      {
        dataKey: "keter",
        label: "keterangan",
      },
      {
        dataKey: "total",
        label: "Omset",
        type: "number",
      },
    ],
  },
  "laporan-omzet-penjualan-per-subkelompok": {
    title: "Laporan Omzet Penjualan Per Sub Kelompok",
    permissionRequired: "Penjualan",
    sourceUrl: "InvoicePelanggan/sales-cabang",
    sourceParam: {
      sortBy: "divisi.nama",
      sortType: "asc",
    },
    pdfUrl: "/omset/sub-kelompok/download",
    filterName: "laporanOmsetSubKelompok",
    fields: [
      {
        dataKey: "namaCabang",
        label: "Cabang",
      },
      {
        dataKey: "namaDivisi",
        label: "Divisi",
      },
      {
        dataKey: "namaSales",
        label: "Nama Sales",
      },
      {
        dataKey: "keterangan",
        label: "keterangan",
      },
      {
        dataKey: "total",
        label: "Omset",
      },
    ],
  },
  "laporan-omzet-penjualan-ttlc": {
    title: "Laporan Omzet Penjualan TTLC",
    permissionRequired: "Penjualan",
    sourceUrl: "omset/ttlc",
    sourceParam: {
      sortBy: "divisi.nama",
      sortType: "asc",
    },
    pdfUrl: "/omset/ttlc/download",
    filterName: "laporanOmsetTTLC",
    fields: [
      {
        dataKey: "poCust",
        label: "PO Cust",
      },
      {
        dataKey: "namaCustomer",
        label: "Nama Customer",
      },
      {
        dataKey: "namaSales",
        label: "Nama Sales",
      },
      {
        dataKey: "namaSubKelompok",
        label: "Sub Kelompok Barang",
      },
      {
        dataKey: "omset",
        label: "Omset",
        type: "number",
      },
    ],
  },
  "laporan-omzet-penjualan-omset-type": {
    title: "Laporan Omzet berdasarakan omset Type/Sales",
    permissionRequired: "Penjualan",
    sourceUrl: "InvoicePelanggan/sales-cabang",
    sourceParam: {
      sortBy: "divisi.nama",
      sortType: "asc",
    },
    pdfUrl: "/omset/sub-kelompok/download",
    filterName: "laporanOmsetSubKelompok",
    fields: [
      {
        dataKey: "namaCabang",
        label: "Cabang",
      },
      {
        dataKey: "namaDivisi",
        label: "Divisi",
      },
      {
        dataKey: "namaSales",
        label: "Nama Sales",
      },
      {
        dataKey: "keterangan",
        label: "keterangan",
      },
      {
        dataKey: "total",
        label: "Omset",
      },
    ],
  },
  "pembatalan-penjualan": {
    // Note: cant open menu in sekawan app
    title: "Pembatalan Penjualan",
    permissionRequired: "Penjualan",
    sourceUrl: "",
  },
  "laporan-tracking-penjualan": {
    permissionRequired: "LaporanPenjualan",
    title: "Laporan Tracking Penjualan",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "No. Invoice",
      },
      {
        dataKey: "",
        label: "Tgl SC",
      },
      {
        dataKey: "",
        label: "Tgl Input SJ",
      },
      {
        dataKey: "",
        label: "Tgl Cetak SJ",
      },
      {
        dataKey: "",
        label: "SJ diterima Pusat",
      },
      {
        dataKey: "",
        label: "SJ diterima Cab",
      },
      {
        dataKey: "",
        label: "Tgl Ctk Inv",
      },
      {
        dataKey: "",
        label: "Tgl Trm OS",
      },
      {
        dataKey: "",
        label: "Diterima Collector",
      },
    ],
  },
  "laporan-standing-order": {
    title: "Laporan Standing Order",
    permissionRequired: "LaporanStandingOrder",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "Sub Kelompok Barang",
      },
      {
        dataKey: "",
        label: "Kode Barang",
      },
      {
        dataKey: "",
        label: "Nama Barang",
      },
      {
        dataKey: "",
        label: "Jumlah 4 Bln",
      },
      {
        dataKey: "",
        label: "Jumlah 6 Bln",
      },
    ],
  },
  "laporan-standing-wo": {
    title: "Laporan Standing WO",
    permissionRequired: "LaporanStandingWo",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "No. Work Order",
      },
      {
        dataKey: "",
        label: "No. SPB",
      },
      {
        dataKey: "",
        label: "Tanggal SPB",
      },
      {
        dataKey: "",
        label: "Nama Sales",
      },
      {
        dataKey: "",
        label: "Customers",
      },
      {
        dataKey: "",
        label: "PO Cust.",
      },
    ],
  },
  "laporan-standing-sttb-internal": {
    title: "Laporan Standing STTB Internal",
    permissionRequired: "LaporanStandingSttbInternal",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "Tgl WO",
      },
      {
        dataKey: "",
        label: "No. WO",
      },
      {
        dataKey: "",
        label: "Cabang",
      },
      {
        dataKey: "",
        label: "Customers",
      },
      {
        dataKey: "",
        label: "No. SPB",
      },
      {
        dataKey: "",
        label: "Tgl. SPB",
      },
      {
        dataKey: "",
        label: "Keter SPB",
      },
    ],
  },
  "laporan-pembelian": {
    title: "Laporan Pembelian",
    permissionRequired: "LaporanPembelian",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "Nama Devisi",
      },
      {
        dataKey: "",
        label: "Lokal",
      },
      {
        dataKey: "",
        label: "Nama Supplier",
      },
      {
        dataKey: "",
        label: "Tgl BAPB",
      },
      {
        dataKey: "",
        label: "No. BAPB",
      },
      {
        dataKey: "",
        label: "No. Biaya Import",
      },
      {
        dataKey: "",
        label: "Curr.",
      },
      {
        dataKey: "",
        label: "Nilai PO",
      },
      {
        dataKey: "",
        label: "B. Import",
      },
    ],
  },
  "laporan-indent-belum-realisasi": {
    title: "Laporan Indent yang Belum Realisasi",
    permissionRequired: "LaporanIndent",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "No. Penawaran",
      },
      {
        dataKey: "",
        label: "No. SC",
      },
      {
        dataKey: "",
        label: "Tgl SC",
      },
      {
        dataKey: "",
        label: "No. PO",
      },
      {
        dataKey: "",
        label: "Tgl PO",
      },
      {
        dataKey: "",
        label: "No. BAPB",
      },
      {
        dataKey: "",
        label: "Tgl. BAPB",
      },
      {
        dataKey: "",
        label: "Jumlah",
      },
      {
        dataKey: "",
        label: "Sales",
      },
    ],
  },
  "laporan-hpp": {
    title: "Laporan HPP",
    filterName: "laporanHpp",
    permissionRequired: "Hpp",
    disablePagination: true,
    sourceUrl: "hpp/laporan",
    disableAdd(data, filterData) {
      return !filterData?.["barang.id"]
    },
    requiredToAdd: ["Barang", "Divisi"],
    pdfUrl: "/hpp/laporan/download",
    ignoreFilterParamOnFetch: [
      "barang.satuanStock.nama",
      "barang.divisi.id",
      "barang.subKelompok.nama",
    ],
    fields: [
      {
        dataKey: "barang.kode",
        label: "Kode Barang",
      },
      {
        dataKey: "barang.nama",
        label: "Nama Barang",
      },
      {
        dataKey: "noBapb",
        label: "No. BAPB",
      },
      {
        dataKey: "tglBapb",
        label: "Tgl. BAPB",
      },
      {
        dataKey: "mataUang.mataUang",
        label: "Curr.",
      },
      {
        dataKey: "rate",
        label: "Rate",
        type: "number",
      },
      {
        dataKey: "metadata.hargaBarang",
        label: "Harga Beli",
        render: (data) => (
          <TableCell>
            {formatNumber(+data.metadata?.hargaBarang / +data.rate)}
          </TableCell>
        ),
      },
      {
        dataKey: "nilaiRupiah",
        label: "HPP Rupiah",
        type: "number",
      },
      {
        dataKey: "nilaiValas",
        label: "HPP Valas",
        type: "number",
      },
      {
        dataKey: "rerataRupiah",
        label: "Rata - Rata Rupiah",
        type: "number",
      },
      {
        dataKey: "rerataValas",
        label: "Rata - Rata Valas",
        type: "number",
      },
      {
        dataKey: "metadata.stockBaru",
        label: "Stock Baru",
        type: "number",
      },
      {
        dataKey: "metadata.stockAkhir",
        label: "Stock Akhir",
        render: (data) => (
          <TableCell>
            {formatNumber(data.metadata?.stockAkhir?.toFixed(2))}
          </TableCell>
        ),
      },
    ],
  },
  "saldo-awal-hutang": {
    title: "Saldo Awal Hutang",
    permissionRequired: "SaldoAwalHutang",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "No. Saldo Awal",
      },
      {
        dataKey: "",
        label: "No. Invoice",
      },
      {
        dataKey: "",
        label: "Tanggal",
      },
      {
        dataKey: "",
        label: "Mata Uang",
      },
      {
        dataKey: "",
        label: "Jumlah",
      },
    ],
  },
  "saldo-awal-piutang": {
    title: "Saldo Awal Piutang",
    permissionRequired: "SaldoAwal",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "No. Saldo Awal",
      },
      {
        dataKey: "",
        label: "No. Invoice",
      },
      {
        dataKey: "",
        label: "Tanggal",
      },
      {
        dataKey: "",
        label: "Mata Uang",
      },
      {
        dataKey: "",
        label: "Jumlah",
      },
    ],
  },
  "laporan-hutang-supplier": LapHutangSupplierPage,
  "laporan-uang-muka": {
    title: "Laporan Uang Muka",
    permissionRequired: "LaporanUangMuka",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "No. PO",
      },
      {
        dataKey: "",
        label: "Tanggal",
      },
      {
        dataKey: "",
        label: "Nilai PO",
      },
      {
        dataKey: "",
        label: "Uang Muka",
      },
      {
        dataKey: "",
        label: "Penyelesaian UM",
      },
      {
        dataKey: "",
        label: "Curr",
      },
      {
        dataKey: "",
        label: "No. Pembayaran",
      },
      {
        dataKey: "",
        label: "Tgl Bayar",
      },
      {
        dataKey: "",
        label: "No. Mohon",
      },
      {
        dataKey: "",
        label: "Tgl Mohon",
      },
      {
        dataKey: "",
        label: "P",
      },
    ],
  },
  "laporan-kasbon-biaya-import": {
    title: "Laporan Kasbon Biaya Import",
    permissionRequired: "KasbonBiayaImport",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "Nomor",
      },
      {
        dataKey: "",
        label: "Tanggal",
      },
      {
        dataKey: "",
        label: "Keterangan",
      },
      {
        dataKey: "",
        label: "Jumlah Kasbon",
      },
      {
        dataKey: "",
        label: "Jumlah Biaya Import",
      },
    ],
  },
  "laporan-retur-customer": {
    title: "Laporan Retur Customer",
    permissionRequired: "ReturCustomer",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "No. Retur",
      },
      {
        dataKey: "",
        label: "Tanggal",
      },
      {
        dataKey: "",
        label: "Customers",
      },
    ],
  },
}

export const InformasiPageKeys = Object.keys(InformasiPages)
