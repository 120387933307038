import {
  basicKodeNamaMapper,
  basicSearchByKeys,
  formatNumber,
  generateCrudUrl,
  genFieldQueryParamObj,
  genFieldQueryParamStr,
  genFormItems,
  genOptionFromArray,
  getToday,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import { NoAccountPiutangMockOption } from "../options"
import {
  barangResourceColumnKeys,
  mataUangOptionMapper,
  mataUangSearchField,
  perkiraanMapper,
  perkiraanResourceColumnKeys,
  perkiraanSearchKey,
  supplierSearchKey,
} from "../mapper"
import { genBaseSortQueryParam, genBaseSortQueryParamObj } from "constant/url"
import {
  buildKodeSubKelompok,
  parseSubkelompokCode,
} from "common/trx-util/sub-kelompok-barang"
import { m } from "framer-motion"
import { Text } from "@chakra-ui/react"
import { DEFAULT_PELANGGANG_PERKIRAAN } from "constant/etc"

export const MasterPages: { [key: string]: BasePageProps<any> } = {
  "global-constant": {
    title: "Variabel Global",
    urls: {
      ...generateCrudUrl("global-constant"),
      update: {
        endpoint: `/global-constant`,
        method: "post",
      },
    },
    permissionRequired: "SIT_master::variabel_global",
    formGroupItems: [
      {
        id: "key",
        label: "Nama",
        isDataView: true,
      },
      {
        id: "value",
        label: "Nilai",
        type: "text-area",
        isDataView: true,
      },
    ],
    cetakActions: [],
  },
  "satuan-barang": {
    title: "Satuan Barang",
    urls: generateCrudUrl("satuanBarang"),
    permissionRequired: "SIT_master::satuan_barang",
    primaryKey: "kode",
    formGroupItems: [
      {
        id: "kode",
        label: "Kode",
        isDataView: true,
      },
      {
        id: "nama",
        label: "Nama",
        isDataView: true,
      },
    ],
    cetakActions: [],
  },
  "kelompok-utama-barang": {
    permissionRequired: "SIT_master::kelompok_utama_barang",
    title: "Kelompok Utama Barang",
    urls: generateCrudUrl("kelompokUtamaBarang"),
    formGroupItems: genFormItems([
      {
        id: "divisi.id",
        label: "Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      {
        id: "kode",
        label: "Kode",
        isDataView: true,
      },
      {
        id: "nama",
        label: "Nama",
        isDataView: true,
      },
    ]),
    cetakActions: [],
  },
  // "kelompok-barang": {
  //   permissionRequired: "KelompokBarang",
  //   title: "Kelompok Barang",
  //   urls: generateCrudUrl("kelompokBarang"),
  //   filter: <BaseFilter keyName="kelompokBarang" />,
  //   formGroupItems: genFormItems([
  //     {
  //       id: "kelompokUtamaId",
  //       label: "Kelompok Utama",
  //       type: "async-select",
  //       resourceUrl: `kelompokUtamaBarang?${genBaseSortQueryParam()}`,
  //     },
  //     {
  //       id: "kode",
  //       label: "Kode",
  //       dataViewLabel: "Kode",
  //       isDataView: true,
  //     },

  //     {
  //       id: "nama",
  //       label: "Nama",
  //       dataViewLabel: "Nama",
  //       isDataView: true,
  //     },
  //   ]),
  // },
  "sub-kelompok-barang": {
    permissionRequired: "SIT_master::sub_kelompok_barang",
    title: "Sub Kelompok Barang",
    urls: {
      ...generateCrudUrl(`subKelompokBarang`),
      read: {
        endpoint: `/subKelompokBarang`,
        method: "get",
      },
    },
    responseMapper: (data) => {
      const { kode, ...rest } = data
      return {
        ...rest,
        ...parseSubkelompokCode(kode),
      }
    },
    filter: <BaseFilter keyName="subKelompokBarang" />,
    cetakActions: [],
    customFormDataMapper: (data) => {
      const kode = buildKodeSubKelompok(data)

      return {
        ...data,
        kode,
      }
    },
    formGroupItems: genFormItems([
      {
        id: "kelompokUtama.id",
        label: "Kelompok Utama Barang",
        type: "async-select",
        resourceUrl: `kelompokUtamaBarang?${genBaseSortQueryParam()}`,
        resourceMapper: basicKodeNamaMapper,
        customOnChange: (data, setValue) => {
          setValue("kodeKelompokUtama", data?.kode?.substring(0, 1))
        },
      },
      {
        id: "kode",
        label: "Kode",
        isDataView: true,
        isIgnored: true,
        columnRender(data) {
          // @ts-ignore
          return <Text fontWeight={600}>{buildKodeSubKelompok(data)}</Text>
        },
      },
      {
        id: "kodeKelompokUtama",
        type: "hidden",
      },
      {
        id: "kodeKelompok",
        label: "Kode Kelompok",
        inputPrefix: (data) => {
          return data.kodeKelompokUtama ?? " "
        },
        formRule: {
          maxLength: { value: 2, message: "Kode kelompok max. 2 karakter" },
          minLength: { value: 2, message: "Kode kelompok min. 2 karakter" },
        },
        suffix: {
          id: "kodeSub",
          label: "Kode Sub Kelompok",
          formRule: {
            maxLength: {
              value: 2,
              message: "Kode sub-kelompok max. 2 karakter",
            },
            minLength: {
              value: 2,
              message: "Kode sub-kelompok min. 2 karakter",
            },
          },
        },
      },
      {
        id: "nama",
        label: "Nama Sub Kelompok Barang",
        dataViewLabel: "Nama",
        isDataView: true,
      },
      {
        id: "target",
        label: "Target",
        type: "number",
      },
      {
        id: "pembagi",
        label: "Pembagi (%)",
        type: "number",
      },
      {
        id: "minMargin",
        type: "number",
        label: "Margin Minimal (%)",
      },
      {
        id: "maxMargin",
        type: "number",
        label: "Margin Maksimal (%)",
      },
      {
        id: "allowKb",
        type: "check",
        label: "Izinkan KB?",
      },
    ]),
  },
  barang: {
    permissionRequired: "SIT_master::barang",
    title: "Barang",
    urls: {
      ...generateCrudUrl("barang"),
      read: {
        method: "get",
        endpoint: "/barang",
        param: {
          ...genBaseSortQueryParamObj("kode", true),
        },
      },
    },
    customFormDataMapper: (data) => {
      const { stockBarangs, ...rest } = data
      return rest
    },
    cetakActions: [],
    generateCodeParam: {
      entity: {},
      entityName: "Barang",
    },
    filter: <BaseFilter keyName="divisiOnly" />,
    formGroupItems: genFormItems([
      {
        id: "divisi.id",
        label: "Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      {
        id: "kode",
        label: "Kode Barang",
        dataViewLabel: "Kode",
        isDataView: true,
        isGeneratedCodeField: true,
        disabled: true,
      },
      {
        id: "nama",
        label: "Nama Barang",
        dataViewLabel: "Nama",
        isDataView: true,
      },
      {
        id: "subKelompok.kelompokUtama.id",
        label: "Grup Barang",
        type: "async-select",
        resourceUrl: (_, filter, data) => {
          let url = "kelompokUtamaBarang?"
          if (!!data.divisi?.id) {
            url += `${genFieldQueryParamStr({ "divisi.id": data.divisi?.id })}&`
          }
          url += genBaseSortQueryParam()

          return url
        },
      },
      {
        id: "subKelompok.id",
        label: "Sub Kelompok Barang",
        type: "async-select",
        resourceUrl: `subKelompokBarang?${genBaseSortQueryParam()}`,
        dependOn: ["subKelompok.kelompokUtama.id"],
      },
      {
        id: "satuanBeli.kode",
        label: "Satuan Beli",
        type: "async-select",
        resourceUrl: `satuanBarang?${genBaseSortQueryParam()}`,
        resourceMapper: (data) => ({
          label: `${data.kode} - ${data.nama}`,
          value: data.kode,
        }),
      },
      {
        id: "satuanJual.kode",
        label: "Satuan Jual",
        type: "async-select",
        resourceUrl: `satuanBarang?${genBaseSortQueryParam()}`,
        resourceMapper: (data) => ({
          label: `${data.kode} - ${data.nama}`,
          value: data.kode,
        }),
      },
      {
        id: "satuanStock.kode",
        label: "Satuan Stock",
        isDataView: true,
        type: "async-select",
        resourceUrl: `satuanBarang?${genBaseSortQueryParam()}`,
        resourceMapper: (data) => ({
          label: `${data.kode} - ${data.nama}`,
          value: data.kode,
        }),
      },
      {
        id: "negara.id",
        label: "Negara",
        type: "async-select",
        resourceUrl: `negara?${genBaseSortQueryParam()}`,
      },
      {
        id: "jenisMesin",
        label: "Jenis Mesin",
        value: "-",
      },
      {
        id: "customers",
        label: "Pelanggan",
        value: "-",
      },
      {
        id: "supplier.id",
        label: "Supplier",
        isOptional: true,
        type: "async-select",
        resourceUrl: `supplier?${genBaseSortQueryParam("namaPanjang")}`,
        resourceMapper: (data) => ({
          label: data.namaPanjang,
          value: data.id,
        }),
        searchBy: supplierSearchKey,
      },
      {
        id: "jlhKonversi",
        label: "Jumlah Konversi",
        type: "number",
        value: 0,
        isDataView: true,
      },
      {
        id: "waste",
        label: "Waste (%)",
        type: "number",
        value: 0,
      },
      {
        id: "overlaf",
        label: "Overlaf (%)",
        type: "number",
        value: 0,
      },
      {
        id: "isDead",
        label: "Dead",
        type: "check",
        value: false,
      },
      {
        id: "urlGambar",
        label: "Gambar",
        type: "file",
        isOptional: true,
      },
      {
        id: "urlFile",
        label: "File PDF",
        type: "file",
        isOptional: true,
      },
    ]),
  },
  "harga-barang": {
    permissionRequired: "SIT_master::price_list_barang",
    title: "Price List Barang",
    urls: generateCrudUrl("harga-barang"),
    filter: <BaseFilter keyName="hargaBarang" />,
    cetakActions: [],
    formGroupItems: genFormItems([
      {
        id: "barang.kode",
        type: "hidden",
        isDataView: true,
        label: "Kode",
      },
      {
        id: "barang.id",
        label: "Barang",
        dataViewKey: "barang.nama",
        isDataView: true,
        isIgnored: (formMode) => formMode === "create",
        type: "async-select",
        customOnChange: (data, setValue) => {
          setValue("barang", data)
        },
        resourceColumnLabelKey: barangResourceColumnKeys,
        resourceUrl: `barang?${genBaseSortQueryParam()}`,
      },
      {
        id: "mataUang.mataUang",
        label: "Mata Uang",
        isDataView: true,
        dataViewKey: "mataUang.mataUang",
        isIgnored: (formMode) => formMode === "create",
        type: "async-select",
        resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
        resourceMapper: mataUangOptionMapper,
        searchBy: mataUangSearchField,
        value: "IDR",
      },
      {
        id: "tanggal",
        label: "Tanggal",
        type: "date",
        isDataView: true,
      },
      {
        id: "hargaPrinciple",
        label: "Harga Principle",
        type: "number",
        value: 0,
        isIgnored: (formMode) => formMode === "create",
      },
      {
        id: "hargaBeli",
        label: "Harga Beli",
        type: "number",
        isDataView: true,
        isIgnored: (formMode) => formMode === "create",
        formRule(data) {
          return {
            min: {
              value: data.hargaPrinciple,
              message: `Harga beli tidak boleh kurang dari ${formatNumber(
                data.hargaPrinciple
              )}`,
            },
          }
        },
      },
      {
        id: "hargaJual",
        label: "Harga Jual",
        type: "number",
        isDataView: true,
        isIgnored: (formMode) => formMode === "create",
        formRule(data) {
          return {
            min: {
              value: data.hargaBeli,
              message: `Harga jual tidak boleh kurang dari ${formatNumber(
                data.hargaBeli
              )}`,
            },
          }
        },
      },
    ]),
    additionalForms: (pageMode) =>
      pageMode === "update"
        ? []
        : [
            {
              id: "hargaBarang",
              title: "Harga Barang",
              formItems: [
                {
                  id: "barang.id",
                  label: "Barang",
                  type: "async-select",
                  customOnChange: (data, setValue) => {
                    setValue("barang", data)
                  },
                  isDataView: true,
                  dataViewKey: "barang.nama",
                  resourceUrl: `barang?${genBaseSortQueryParam()}`,
                },
                {
                  id: "mataUang",
                  label: "Mata Uang",
                  type: "async-select",
                  resourceUrl: `valuta-asing?${genBaseSortQueryParam(
                    "mataUang"
                  )}`,
                  resourceMapper: mataUangOptionMapper,
                  searchBy: mataUangSearchField,
                  value: "IDR",
                },
                {
                  id: "tanggal",
                  label: "Tanggal",
                  type: "date",
                  isDataView: true,
                },
                {
                  id: "hargaPrinciple",
                  label: "Harga Principle",
                  type: "number",
                  value: 0,
                },
                {
                  id: "hargaBeli",
                  label: "Harga Beli",
                  type: "number",
                  isDataView: true,
                  formRule(data) {
                    return {
                      min: {
                        value: data.hargaPrinciple,
                        message: `Harga beli tidak boleh kurang dari ${formatNumber(
                          data.hargaPrinciple
                        )}`,
                      },
                    }
                  },
                },
                {
                  id: "hargaJual",
                  label: "Harga Jual",
                  type: "number",
                  isDataView: true,
                  formRule(data) {
                    return {
                      min: {
                        value: data.hargaBeli,
                        message: `Harga jual tidak boleh kurang dari ${formatNumber(
                          data.hargaBeli
                        )}`,
                      },
                    }
                  },
                },
              ],
            },
          ],
  },
  "master-jos": {
    permissionRequired: "SIT_master::master_jos",
    title: "Master JOS",
    urls: generateCrudUrl("jos"),
    cetakActions: [],
    formGroupItems: genFormItems([
      {
        id: "kode",
        label: "Serial Number",
        isDataView: true,
      },
      {
        id: "nama",
        label: "Nama Alat",
        isDataView: true,
      },
    ]),
    additionalForms: [
      {
        id: "konfigurasiUpahJos",
        formItems: [
          {
            id: "startTime",
            label: "Waktu Mulai",
            isDataView: true,
            type: "time",
          },
          {
            id: "endTime",
            label: "Waktu Selesai",
            isDataView: true,
            type: "time",
          },
          {
            id: "biayaIntensifAlat",
            label: "Biaya Intensif",
            isDataView: true,
            type: "number",
            value: 0,
          },
          {
            id: "biayaLembur",
            label: "Biaya Lembur",
            isDataView: true,
            type: "number",
            value: 0,
            suffix: {
              id: "isLibur",
              value: false,
              type: "check",
              label: "Hari Libur",
            },
          },
          {
            id: "biayaMakanTransport",
            label: "Biaya Makan Transport",
            isDataView: true,
            type: "number",
            value: 0,
          },
          {
            id: "description",
            label: "Keterangan",
            isOptional: true,
          },
        ],
        title: "Upah Teknisi",
      },
      {
        id: "konfigurasiBiayaAlats",
        title: "Biaya Alat",
        formItems: [
          {
            id: "radiusStart",
            label: "Radius Minimum",
            type: "number",
            isDataView: true,
            value: 0,
          },
          {
            id: "radiusEnd",
            isOptional: true,
            type: "number",
            label: "Radius Maksimum",
            isDataView: true,
            value: 0,
          },
          {
            id: "harga",
            type: "number",
            label: "Harga",
            value: 0,
            isOptional: true,
            isDataView: true,
          },
        ],
      },
    ],
  },
  "kelompok-pelanggan": {
    permissionRequired: "SIT_master::kelompok_customer",
    title: "Kelompok Pelanggan",
    urls: generateCrudUrl("kelompok-pelanggan", {
      ...genBaseSortQueryParamObj("kode", true),
    }),
    cetakActions: [],
    generateCodeParam: {
      entity: {},
      entityName: "KelompokPelanggan",
    },
    formGroupItems: genFormItems([
      {
        id: "kode",
        label: "Kode Kelompok Pelanggan",
        dataViewLabel: "Kode",
        isGeneratedCodeField: true,
      },
      {
        id: "nama",
        label: "Nama Kelompok Pelanggan",
        dataViewLabel: "Nama",
      },
    ]),
  },
  pelanggan: {
    permissionRequired: "SIT_master::customers",
    title: "Pelanggan",
    urls: generateCrudUrl("pelanggan", {
      ...genBaseSortQueryParamObj("kodePelanggan", true),
    }),
    filter: <BaseFilter keyName="cabangOnly" />,
    generateCodeParam: (data, filter) => ({
      entity: {
        organisasi: {
          id: filter?.["organisasi.id"],
        },
      },
      entityName: "Pelanggan",
    }),
    cetakActions: [],
    formGroupItems: genFormItems([
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: "organisasi?filterFields=jenis&filterValues=CABANG",
      },
      {
        id: "kodePelanggan",
        label: "Kode Pelanggan",
        isDataView: true,
        dataViewLabel: "Kode",
        disabled: true,
        isGeneratedCodeField: true,
        suffix: {
          id: "isPpn",
          value: false,
          label: "PPN",
          type: "check",
        },
      },
      {
        id: "namaPendek",
        label: "Nama Pendek",
        isDataView: true,
      },
      {
        id: "namaPanjang",
        label: "Nama Panjang",
        isDataView: true,
        dataViewLabel: "Nama Pelanggan",
      },
      {
        id: "kontak",
        label: "Kontak",
      },
      {
        id: "npwp",
        label: "NPWP",
        type: "numeric",
      },
      {
        id: "alamatKantor",
        label: "Alamat Kantor",
      },
      {
        id: "kota",
        label: "Kota",
        isDataView: true,
      },
      {
        id: "kodePos",
        label: "Kode POS",
        type: "numeric",
      },
      {
        id: "phone",
        label: "Phone",
        isDataView: true,
        type: "numeric",
      },
      {
        id: "fax",
        label: "Fax",
        type: "numeric",
      },
      {
        id: "alamatPabrik",
        label: "Alamat Pabrik",
      },
      {
        id: "kelompokPelanggan.id",
        type: "async-select",
        label: "Kelompok Customer",
        resourceUrl: `kelompok-pelanggan?${genBaseSortQueryParam()}`,
      },
      {
        id: "paguKredit",
        label: "Pagu Kredit",
        value: 0,
      },
      {
        id: "tanggalKontrakBon",
        label: "Tanggal Kontra Bon",
        isOptional: true,
      },
      {
        id: "barang",
        label: "Barang",
        type: "radio",
        options: genOptionFromArray(["A1", "A2"]),
        suffix: {
          id: "jasa",
          label: "Jasa",
          type: "radio",
          options: genOptionFromArray(["A1", "A2"]),
          value: "A1",
          isHalfSize: true,
        },
      },
      {
        id: "noAccPiutang",
        label: "No. Account Piutang",
        value: DEFAULT_PELANGGANG_PERKIRAAN,
        type: "async-select",
        resourceUrl: "perkiraan",
        resourceMapper: perkiraanMapper,
        searchBy: "nomorPerkiraan,keterangan",
      },
    ]),
    additionalForms: [
      {
        id: "rekeningKbs",
        title: "Rekening KB",
        primaryKey: "norek",
        formItems: [
          {
            id: "norek",
            label: "No. Rekening",
            isDataView: true,
            type: "numeric",
          },
          {
            id: "namaRekening",
            label: "Atas Nama",
            isDataView: true,
          },
          {
            id: "namaBank",
            label: "Bank",
            isDataView: true,
          },
          {
            id: "keterangan",
            label: "Keterangan",
          },
        ],
      },
    ],
  },
  cabang: {
    permissionRequired: "SIT_master::cabang",
    title: "Cabang",
    urls: generateCrudUrl("organisasi", { jenis: "CABANG" }),
    responseMapper(data) {
      return {
        ...data,
        kode: data.kode.replace("B", ""),
        kodeRaw: data.kode,
      }
    },
    customFormDataMapper(data) {
      return {
        ...data,
        kode: `B${data.kode}`,
      }
    },
    formGroupItems: genFormItems([
      {
        id: "kodeRaw",
        label: "Kode",
        isDataView: true,
        isIgnored: true,
      },
      {
        id: "kode",
        label: "Kode",
        inputPrefix: "B",
      },
      {
        id: "nama",
        label: "Nama",
        isDataView: true,
      },
      {
        id: "alamat",
        label: "Alamat",
      },
      {
        id: "kota",
        label: "Kota",
        isDataView: true,
      },
      {
        id: "kodePost",
        label: "Kode Pos",
        type: "numeric",
      },
      {
        id: "noHp",
        label: "No. HP",
        type: "numeric",
      },
      {
        id: "noFax",
        label: "No. Fax",
        type: "numeric",
      },
      {
        id: "salesManager.0",
        label: "Sales Manager",
        isOptional: true,
        suffix: {
          id: "salesManager.1",
          isOptional: true,
          isHalfSize: true,
        },
      },
      {
        id: "jenis",
        type: "hidden",
        value: "CABANG",
      },
      {
        id: "jenis",
        label: "Tipe",
        type: "hidden",
        value: "CABANG",
      },
      {
        id: "isCabang",
        label: "Cabang",
        type: "check",
        value: true,
      },
      {
        id: "isWorkshop",
        label: "Workshop",
        type: "check",
        value: false,
      },
      {
        id: "isPusat",
        label: "Pusat",
        type: "check",
        value: false,
      },
      {
        id: "isGudangPusat",
        label: "Gudang Pusat",
        type: "check",
        value: false,
      },
    ]),
    additionalForms: [
      {
        id: "rekeningOrganisasi",
        title: "Rekening Cabang",
        formItems: [
          {
            id: "nomorRekening",
            label: "No. Rekening",
            isDataView: true,
            type: "numeric",
          },
          {
            id: "atasNama",
            label: "Atas Nama",
            isDataView: true,
          },
          {
            id: "bank",
            label: "Bank",
            isDataView: true,
          },
          {
            id: "isPpn",
            label: "PPN",
            type: "check",
          },
          {
            id: "keterangan",
            label: "Keterangan",
          },
          {
            id: "mataUang",
            label: "Mata Uang",
            type: "async-select",
            resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
            resourceMapper: mataUangOptionMapper,
            searchBy: mataUangSearchField,
          },
        ],
      },
    ],
    cetakActions: [],
  },
  divisi: {
    title: "Divisi",
    permissionRequired: "SIT_master::divisi",
    urls: {
      ...generateCrudUrl("divisi"),
      read: {
        endpoint: "/divisi",
        param: {
          sortBy: "kode",
          sortType: "asc",
        },
        method: "get",
      },
    },
    cetakActions: [],
    formGroupItems: genFormItems([
      {
        id: "kode",
        label: "Kode",
        isDataView: true,
      },
      {
        id: "nama",
        label: "Nama",
        dataViewLabel: "Nama Divisi",
        isDataView: true,
      },
      {
        id: "namaDirektur",
        label: "Nama Direktur",
      },
    ]),
  },
  negara: {
    permissionRequired: "SIT_master::negara",
    title: "Negara",
    urls: generateCrudUrl("negara", genBaseSortQueryParamObj("kode", true)),
    cetakActions: [],
    formGroupItems: genFormItems([
      {
        id: "kode",
        label: "Kode",
        isDataView: true,
      },
      {
        id: "nama",
        label: "Nama",
        isDataView: true,
      },
      {
        id: "isImport",
        label: "Import/Luar Negeri",
        type: "check",
      },
    ]),
  },
  supplier: {
    permissionRequired: "SIT_master::supplier",
    title: "Supplier",
    cetakActions: [],
    urls: generateCrudUrl("supplier", {
      ...genBaseSortQueryParamObj("kodeSupplier", true),
    }),
    generateCodeParam: {
      entity: {},
      entityName: "Supplier",
    },
    filter: <BaseFilter keyName="isSupplierImport" />,
    formGroupItems: genFormItems([
      {
        id: "isImportLuarNegeri",
        label: "Supplier Import/Luar Negeri",
        type: "check",
        suffix: {
          isHalfSize: true,
          id: "isPkp",
          type: "check",
          label: "PKP",
          value: true,
        },
      },
      {
        id: "kodeSupplier",
        label: "Kode Supplier",
        dataViewLabel: "Kode",
        isGeneratedCodeField: true,
        disabled: true,
      },
      {
        id: "namaPendek",
        label: "Nama Pendek",
      },
      {
        id: "namaPanjang",
        label: "Nama Panjang",
        dataViewLabel: "Nama",
      },
      {
        id: "kontak",
        label: "Kontak",
      },
      {
        id: "npwp",
        label: "NPWP",
        type: "numeric",
        renderCondition: (formData) => !formData?.isImportLuarNegeri,
      },
      {
        id: "alamatKantor",
        label: "Alamat Kantor",
      },
      {
        id: "kota",
        label: "Kota",
        isDataView: true,
      },
      {
        id: "kodePos",
        label: "Kode Pos",
      },
      {
        id: "negara.id",
        label: "Negara",
        type: "async-select",
        resourceUrl: `negara?${genBaseSortQueryParam()}`,
        resourceMapper: (data) => ({
          label: data.nama,
          value: String(data.id),
        }),
      },
      {
        id: "phone",
        label: "Phone",
      },
      {
        id: "fax",
        label: "Fax",
      },
      {
        id: "email",
        label: "E-mail",
      },
      {
        id: "website",
        label: "Website",
      },
      {
        id: "mataUang.mataUang",
        label: "Mata Uang",
        type: "async-select",
        resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
        searchBy: mataUangSearchField,
        resourceMapper: mataUangOptionMapper,
      },
      {
        id: "perkiraan.id",
        label: "No. Perkiraan",
        type: "async-select",
        resourceUrl: `perkiraan?${genFieldQueryParamStr({
          level: "DETAIL",
        })}`,
        resourceMapper: perkiraanMapper,
        searchBy: "nomorPerkiraan,keterangan",
      },
      {
        id: "swiftCode",
        label: "Swift Code",
      },
      {
        id: "noAccount",
        label: "No. Account",
      },
    ]),
  },
  angkutan: {
    permissionRequired: "SIT_master::angkutan",
    title: "Angkutan",
    urls: generateCrudUrl(
      "angkutan",
      genBaseSortQueryParamObj("kodeAngkutan", true)
    ),
    generateCodeParam: {
      entityName: "Angkutan",
      entity: {},
    },
    cetakActions: [],
    formGroupItems: genFormItems([
      {
        id: "kodeAngkutan",
        label: "Kode",
        isDataView: true,
        isGeneratedCodeField: true,
        disabled: true,
      },
      {
        id: "namaPendek",
        label: "Nama Pendek",
      },
      {
        id: "namaPanjang",
        label: "Nama Panjang",
        isDataView: true,
        dataViewLabel: "Nama Angkutan",
      },
      {
        id: "kontak",
        label: "Kontak",
        type: "numeric",
      },
      {
        id: "npwp",
        label: "NPWP",
        type: "numeric",
      },
      {
        id: "alamat",
        label: "Alamat Kantor",
      },
      {
        id: "kota",
        label: "Kota",
        isDataView: true,
      },
      {
        id: "kodePos",
        label: "Kode POS",
        type: "numeric",
      },
      {
        id: "phone",
        label: "Phone",
        isDataView: true,
        type: "numeric",
      },
      {
        id: "fax",
        label: "Fax",
        type: "numeric",
      },
      {
        id: "email",
        label: "Email",
      },
      {
        id: "website",
        label: "Website",
      },
    ]),
  },
  sales: {
    permissionRequired: "SIT_master::sales",
    title: "Sales",
    generateCodeParam: (data, filter) => {
      const kodeOrg = data.organisasi?.kode
      if (!kodeOrg) return
      return {
        entity: {
          jenisJabatan: "SALES",
          organisasi: { id: filter?.["organisasi.id"] },
        },
        entityName: "Karyawan",
      }
    },
    urls: {
      ...generateCrudUrl("karyawan"),
      read: {
        endpoint: `/karyawan`,
        param: genFieldQueryParamObj({
          jenisJabatan: "SALES",
        }),
        method: "get",
      },
    },
    filter: <BaseFilter keyName="cabangOnly" />,
    formGroupItems: genFormItems([
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({})}`,
        customOnChange: (data, setValue, getValue, generateCodeCb) => {
          setValue("organisasi.kode", data.kode)
          generateCodeCb()
        },
      },
      {
        id: "kode",
        label: "Kode Sales",
        dataViewLabel: "Kode",
        isDataView: true,
        disabled: true,
        isGeneratedCodeField: true,
      },
      {
        id: "nama",
        label: "Nama Sales",
        isDataView: true,
      },
      {
        id: "divisi.id",
        label: "Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      {
        id: "noHp",
        label: "Handphone",
        type: "numeric",
      },
      {
        id: "email",
        label: "Email",
      },
      {
        id: "jenisJabatan",
        type: "hidden",
        value: "SALES",
      },
    ]),
    cetakActions: [],
  },
  teknisi: {
    permissionRequired: "SIT_master::teknisi",
    title: "Teknisi",
    urls: {
      ...generateCrudUrl("karyawan"),
      read: {
        endpoint: `/karyawan`,
        param: {
          ...genFieldQueryParamObj({
            jenisJabatan: "TEKNISI",
          }),
          ...genBaseSortQueryParamObj("kode", true),
        },
        method: "get",
      },
    },
    generateCodeParam: (data, filter) => {
      const kodeOrg = data?.organisasi?.kode
      if (!kodeOrg) return
      return {
        entity: {
          jenisJabatan: "TEKNISI",
          organisasi: {
            id: filter?.["organisasi.id"],
          },
        },
        entityName: "Karyawan",
      }
    },
    filter: <BaseFilter keyName="teknisi" />,
    formGroupItems: genFormItems([
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({
          isWorkshop: true,
        })}&${genBaseSortQueryParam()}`,
        customOnChange: (data, setValue, getValue, generateCode) => {
          setValue("organisasi.kode", data.kode)
          generateCode()
        },
        suffix: {
          id: "isCabangUmum",
          label: "Umum",
          type: "check",
          value: false,
        },
      },
      {
        id: "divisi.id",
        label: "Divisi",
        type: "async-select",
        resourceUrl: "divisi",
        dataViewKey: "divisi.id",
      },
      {
        id: "kode",
        label: "Kode Teknisi",
        dataViewLabel: "Kode",
        isDataView: true,
        isGeneratedCodeField: true,
        disabled: true,
      },
      {
        id: "nama",
        label: "Nama Teknisi",
        isDataView: true,
      },
      {
        id: "noHp",
        label: "Handphone",
        type: "numeric",
      },
      {
        id: "email",
        label: "Email",
      },
      {
        id: "jenisJabatan",
        type: "hidden",
        value: "TEKNISI",
      },
    ]),
    cetakActions: [],
  },
  karyawan: {
    permissionRequired: "GL_Sekawan::master::divisi_keuangan",
    title: "Karyawan",
    urls: {
      ...generateCrudUrl("karyawan"),
      read: {
        endpoint: "/karyawan",
        method: "get",
        param: {
          ...genFieldQueryParamObj({
            jenisJabatan_$In: "KEUANGAN;ADMIN",
          }),
          ...genBaseSortQueryParamObj("kode", true),
        },
      },
    },
    generateCodeParam: (data) => {
      const kodeOrg = data?.organisasi?.kode
      if (!kodeOrg) return
      return {
        entity: {
          jenisJabatan: "KEUANGAN",
          organisasi: {
            kode: kodeOrg,
          },
        },
        entityName: "Karyawan",
      }
    },
    filter: <BaseFilter keyName="cabangOnly" />,
    formGroupItems: genFormItems([
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr(
          {}
        )}&${genBaseSortQueryParam()}`,
        customOnChange: (data, setValue, getValue, generateCode) => {
          setValue("organisasi.kode", data?.kode)
          generateCode()
        },
      },
      {
        id: "kode",
        label: "Kode Karyawan",
        dataViewLabel: "Kode",
        isGeneratedCodeField: true,
        disabled: true,
        isDataView: true,
      },
      {
        id: "nama",
        label: "Nama Karyawan",
        isDataView: true,
      },

      {
        id: "divisi.id",
        label: "Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      {
        id: "noHp",
        label: "Handphone",
        type: "numeric",
      },
      {
        id: "email",
        label: "Email",
      },
      {
        id: "statusKaryawan",
        label: "Status Karyawan",
        type: "select",
        options: ["K0", "K1", "K2", "K3", "TK"].map((i) => ({
          label: i,
          value: i,
        })),
      },
      {
        id: "noKtp",
        label: "No. KTP",
        type: "numeric",
      },
      {
        id: "nik",
        label: "NIK",
        type: "numeric",
      },
      {
        id: "jabatan",
        label: "Jabatan",
      },
      // {
      //   id: "jenisJabatan",
      //   label: "Jenis Jabatan",
      //   value: "KEUANGAN",
      //   type: "hidden",
      // },
      {
        id: "ttdUrl",
        type: "file",
        label: "Tanda Tangan",
        isOptional: true,
      },
      // {
      //   id: "role.id",
      //   type: "async-select",
      //   label: "Role",
      //   resourceUrl: `role?${genBaseSortQueryParam("roleName")}`,
      //   resourceMapper: (data) => ({
      //     label: data.roleName,
      //     value: data.id,
      //   }),
      // },
    ]),
  },
  "kode-nomor-perkiraan": {
    title: "Kode Nomor Perkiraan",
    permissionRequired: "GL_Sekawan::master::kode_nomor_perkiraan",
    urls: generateCrudUrl(
      "perkiraan",
      genBaseSortQueryParamObj("nomorPerkiraan", true)
    ),
    customFormDataMapper(data, filterData) {
      const { organisasi, divisi, ...rest } = data
      const result: any = rest
      if (data.organisasi?.id !== "0") {
        result.organisasi = {
          id: data.organisasi?.id,
        }
      }
      if (data.divisi?.id !== "0") {
        result.divisi = {
          id: data.divisi?.id,
        }
      }
      return result
    },
    responseMapper(data) {
      const { organisasi, divisi, ...rest } = data

      return {
        ...rest,
        organisasi: organisasi ?? {
          id: "0",
        },
        divisi: divisi ?? {
          id: "0",
        },
      }
    },
    cetakActions: [],
    formGroupItems: genFormItems([
      {
        id: "nomorPerkiraan",
        label: "Nomor Perkiraan",
        dataViewLabel: "No. Perkiraan",
        isDataView: true,
      },
      {
        id: "keterangan",
        label: "Keterangan",
        isDataView: true,
      },
      {
        id: "level",
        label: "Level",
        type: "radio",
        options: genOptionFromArray(["HEADER", "DETAIL"]),
        isDataView: true,
        customOnChange: (data, setValue) => {
          if (data === "HEADER") {
            setValue("perkiraanHeader.id", null)
          }
        },
      },
      {
        id: "kelompok",
        label: "Kelompok",
        type: "select",
        options: genOptionFromArray(["NERACA", "LABA_RUGI"]),
      },
      {
        id: "cashflowType",
        label: "Tipe Cashflow",
      },
      {
        id: "perkiraanHeader.id",
        label: "No Perkiraan Header",
        type: "async-select",
        disabled: (data) => {
          return data.level === "HEADER"
        },
        resourceMapper: perkiraanMapper,
        resourceUrl: `perkiraan?${genFieldQueryParamStr({
          level: "HEADER",
        })}`,
        searchBy: "nomorPerkiraan,keterangan",
      },
      {
        id: "isValas",
        label: "Valas",
        type: "check",
        value: false,
      },
      {
        id: "mataUang.mataUang",
        label: "Standar Mata Uang",
        type: "async-select",
        resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
        resourceMapper: mataUangOptionMapper,
        renderCondition: (data) => !!data.isValas,
        searchBy: mataUangSearchField,
      },
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: "organisasi?filterFields=jenis&filterValues=CABANG", // Tambah semua cabang -> kirim 0
        baseResources: [
          {
            nama: "Semua Cabang",
            id: "0",
          },
        ],
      },
      {
        id: "divisi.id",
        label: "Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`, // Tambah semua divisi -> kirim "0"
        baseResources: [
          {
            nama: "Semua Divisi",
            id: "0",
          },
        ],
      },
    ]),
  },
  "saldo-awal-organisasi": {
    title: "Saldo Awal",
    permissionRequired: "SaldoAwal",
    urls: generateCrudUrl("saldo-awal-organisasi"),
    filter: <BaseFilter keyName="saldoAwalOrganisasi" />,
    recalculateCallback(rhf, globRhf) {
      const data = rhf.getValues()
      rhf.setValue("total", +data.debetRupiah - +data.kreditRupiah)
    },
    disableAdd(data, filterData) {
      return !filterData?.["tahun"] || !filterData?.["organisasi.id"]
    },
    cetakActions: [],
    requiredToAdd: ["Tahun", "Cabang"],
    disablePagination: true,
    isHighlightRowCb: (data) => {
      return [-1, -2].includes(+data.perkiraan?.id)
    },
    formGroupItems: genFormItems([
      {
        id: "organisasi.id",
        label: "Cabang",
        resourceUrl: `organisasi?${genBaseSortQueryParam()}`,
        type: "async-select",
      },
      {
        id: "tahun",
        type: "hidden",
      },
      {
        id: "perkiraan.id",
        label: "Nomor Perkiraan",
        type: "async-select",
        dataViewKey: "perkiraan.nomorPerkiraan",
        isDataView: true,
        resourceUrl: `perkiraan?${genBaseSortQueryParam("nomorPerkiraan")}`,
        resourceMapper: perkiraanMapper,
        searchBy: perkiraanSearchKey,
        resourceColumnLabelKey: perkiraanResourceColumnKeys,
      },
      {
        id: "perkiraan.keterangan",
        type: "hidden",
        label: "Keterangan",
        isDataView: true,
      },
      {
        id: "debetRupiah",
        label: "Debit",
        type: "number",
        isCallRecalculateCb: true,
        isDataView: true,
        value: 0,
        suffix: {
          id: "kreditRupiah",
          label: "Kredit",
          type: "number",
          isCallRecalculateCb: true,
          isDataView: true,
          isHalfSize: true,
          value: 0,
        },
      },
      {
        id: "saldo",
        label: "Total Saldo",
        type: "number",
        isDataView: true,
        dataViewLabel: "Saldo",
        disabled: true,
      },
    ]),
  },
  // "saldo-awal-gl": {
  //   title: "Saldo Awal GL",
  //   permissionRequired: "GL_Sekawan::master::saldo_awal",
  //   urls: generateCrudUrl("saldoAwal"),
  //   cetakActions: [],
  //   filter: <BaseFilter keyName="organisasiSupplier" />,
  //   formGroupItems: genFormItems([
  //     {
  //       id: "cabang.id",
  //       label: "Nama Cabang",
  //       type: "async-select",
  //       resourceUrl: `organisasi?${genBaseSortQueryParam()}`,
  //     },
  //     {
  //       id: "divisi.id",
  //       label: "Nama Divisi",
  //       type: "async-select",
  //       resourceUrl: `divisi?${genBaseSortQueryParam()}`,
  //     },
  //     {
  //       id: "supplier.id",
  //       label: "Nama Supplier",
  //       type: "async-select",
  //       resourceUrl: `supplier?${genBaseSortQueryParam("namaPanjang")}`,
  //       resourceMapper: (data) => ({
  //         label: data.namaPanjang,
  //         value: data.id,
  //       }),
  //       searchBy: supplierSearchKey,
  //     },
  //     {
  //       id: "no",
  //       label: "Nomor Saldo Awal",
  //       dataViewLabel: "No.",
  //       isDataView: true,
  //     },
  //     {
  //       id: "noInvoice",
  //       label: "Nomor Invoice",
  //       dataViewLabel: "No. Invoice",
  //       isDataView: true,
  //     },
  //     {
  //       id: "tanggal",
  //       label: "Tanggal",
  //       type: "date",
  //       isDataView: true,
  //     },
  //     {
  //       id: "jumlah",
  //       label: "Jumlah",
  //       type: "number",
  //       isDataView: true,
  //     },
  //     {
  //       id: "tglJatuhTempo",
  //       label: "Tanggal Jatuh Tempo",
  //       type: "date",
  //       isDataView: true,
  //     },
  //     {
  //       id: "isPpn",
  //       label: "PPN",
  //       type: "check",
  //     },
  //     {
  //       id: "komisi",
  //       label: "Komisi",
  //       type: "number",
  //     },
  //     {
  //       id: "jlhKb",
  //       label: "Jumlah KB",
  //       type: "number",
  //     },
  //     {
  //       id: "ratePajak",
  //       label: "Rate Pajak",
  //       type: "number",
  //     },
  //     {
  //       id: "tipe",
  //       label: "Tipe Saldo Awal",
  //       type: "select",
  //       options: genOptionFromArray(["HUTANG", "PIUTANG"]),
  //     },
  //   ]),
  // },
  "kode-voucher": {
    permissionRequired: "GL_Sekawan::master::kode_voucher",
    title: "Kode Voucher",
    urls: generateCrudUrl(
      "voucher",
      genBaseSortQueryParamObj("kodeVoucher", true)
    ),
    filter: <BaseFilter keyName="cabangOnly" />,
    disableAdd(data, filterData) {
      return !filterData?.["organisasi.id"]
    },
    requiredToAdd: ["Cabang"],
    formGroupItems: genFormItems([
      {
        id: "kodeVoucher",
        label: "Kode Voucher",
        dataViewLabel: "Kode",
        isDataView: true,
        suffix: {
          isHalfSize: true,
          id: "isUsingPpn",
          label: "PPN",
          type: "check",
          value: false,
          isOptional: true,
        },
      },
      {
        id: "keterangan",
        label: "Keterangan",
        isDataView: true,
      },
      {
        id: "perkiraan.id",
        label: "Nomor Perkiraan",
        type: "async-select",
        isOptional: true,
        resourceUrl: `perkiraan?${genBaseSortQueryParam(
          "nomorPerkiraan"
        )}&${genFieldQueryParamObj({ level: "DETAIL" })}`,
        resourceMapper: perkiraanMapper,
        resourceColumnLabelKey: perkiraanResourceColumnKeys,
        isDataView: true,
        dataViewKey: "perkiraan.nomorPerkiraan",
        disabled: (data) => data.isJurnalPenyesuaian,
        searchBy: "nomorPerkiraan,keterangan",
      },
      {
        id: "jenis",
        label: "Jenis",
        type: "radio",
        isDataView: true,
        options: genOptionFromArray(["KREDIT", "DEBIT"]),
        suffix: {
          id: "isJurnalPenyesuaian",
          label: "Jurnal Penyesuaian",
          type: "check",
          value: false,
          isOptional: true,
          customOnChange: (data, setValue) => {
            if (!!data) {
              setValue("perkiraan.id", null)
              setValue("perkiraan", null)
            }
          },
          isHalfSize: true,
        },
      },
      {
        id: "mataUang.mataUang",
        type: "async-select",
        label: "Mata Uang",
        resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
        resourceMapper: mataUangOptionMapper,
        searchBy: "mataUang,nama",
        isDataView: true,
        suffix: {
          id: "",
          type: "blank",
          isOptional: true,
          isHalfSize: true,
        },
      },
    ]),
    cetakActions: [],
  },

  "batas-no-pajak": {
    permissionRequired: "GL_Sekawan::master::batas_pajak",
    title: "Batas No. Pajak",
    urls: {
      ...generateCrudUrl("nomor-pajak"),
      read: {
        endpoint: "/nomor-pajak",
        param: genBaseSortQueryParamObj("organisasi.kode", true),
        method: "get",
      },
    },
    requiredToAdd: ["Tahun"],
    disableAdd(data, filterData) {
      return !filterData?.["year"]
    },
    filter: <BaseFilter keyName="noBatasPajak" />,
    responseMapper(data) {
      const { noAwal, noAkhir } = data
      return {
        ...data,
        prefixAwal: noAwal.slice(0, 2),
        suffixAwal: noAwal.slice(2, noAwal.length),
        prefixAkhir: noAkhir.slice(0, 2),
        suffixAkhir: noAkhir.slice(2, noAkhir.length),
      }
    },
    formGroupItems: genFormItems([
      {
        id: "kodePajak",
        label: "Kode Pajak",
        dataViewLabel: "Kode",
        isDataView: true,
      },
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "async-select",
        isDataView: true,
        dataViewKey: "organisasi.nama",
        resourceUrl: `organisasi?filterFields=jenis&filterValues=CABANG&${genBaseSortQueryParam()}`,
      },
      {
        id: "month",
        label: "Bulan Periode",
        type: "numeric",
        isDataView: true,
        value: new Date().getMonth() + 1,
        suffix: {
          id: "year",
          label: "Tahun Periode",
          type: "numeric",
        },
      },
      {
        id: "year",
        type: "hidden",
        isDataView: true,
        label: "Tahun Periode",
      },
      {
        id: "suffixAwal",
        label: "No. Awal",
        type: "ignored",
        isDataView: true,
      },
      {
        id: "noAwal",
        label: "No. Awal",
        type: "numeric",
      },
      {
        id: "suffixAkhir",
        label: "No. Akhir",
        type: "ignored",
        isDataView: true,
      },
      {
        id: "noAkhir",
        label: "No. Akhir",
        type: "numeric",
      },
    ]),
    cetakActions: [],
  },
  "biaya-operasional": {
    permissionRequired: "GL_Sekawan::master::cost_/_biaya_operasional",
    title: "Biaya Operasional",
    cetakActions: [],
    urls: generateCrudUrl(
      "biayaOperasional",
      genBaseSortQueryParamObj("organisasi.kode", true)
    ),
    requiredToAdd: ["Divisi"],
    disableAdd(data, filterData) {
      return !filterData?.["divisi.id"]
    },
    filter: <BaseFilter keyName="biayaOperasional" />,
    formGroupItems: genFormItems([
      {
        id: "organisasi.kode",
        isDataView: true,
        label: "Kode",
        isIgnored: true,
      },
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: "organisasi",
        isDataView: true,
        dataViewKey: "organisasi.nama",
      },
      {
        id: "divisi.id",
        label: "Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
        isDataView: true,
        dataViewKey: "divisi.nama",
      },

      {
        id: "bulan",
        label: "Bulan",
        isDataView: true,
      },
      {
        id: "tahun",
        label: "Tahun",
        isDataView: true,
      },
      {
        id: "nilai",
        label: "Nilai (%)",
        type: "number",
        value: "25",
        isDataView: true,
      },
    ]),
  },
  "valuta-asing": {
    permissionRequired: "SIT_master::kode_valuta_asing",
    title: "Kode Valuta Asing",
    urls: generateCrudUrl(
      "valuta-asing",
      genBaseSortQueryParamObj("mataUang", true)
    ),
    primaryKey: "mataUang",
    cetakActions: [],
    formGroupItems: [
      {
        id: "mataUang",
        label: "Kode",
        isDataView: true,
      },
      {
        id: "nama",
        label: "Nama",
        isDataView: true,
      },
      {
        id: "simbol",
        label: "Simbol",
        isDataView: true,
      },
    ],
  },
  "target-penjualan": {
    permissionRequired: "SIT_master::target_penjualan",
    title: "Target Penjualan",
    urls: generateCrudUrl("target-penjualan"),
    filter: <BaseFilter keyName="targetPenjualan" />,
    cetakActions: [],
    formGroupItems: genFormItems([
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: `organisasi?filterFields=jenis&filterValues=CABANG&${genBaseSortQueryParam()}`,
      },
      {
        id: "divisi.id",
        label: "Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      {
        id: "subKelompokBarang.kelompokUtama.id",
        label: "Grup Barang",
        type: "async-select",
        resourceUrl: (_, filter, data) => {
          let url = `kelompokUtamaBarang?`
          if (!!data.divisi?.id) {
            url += `${genFieldQueryParamStr({ "divisi.id": data.divisi?.id })}&`
          }
          return url + genBaseSortQueryParam()
        },
      },
      {
        id: "subKelompokBarang.id",
        label: "Sub Kelompok Barang",
        type: "async-select",
        resourceUrl: (_, filter, data) => {
          let url = `subKelompokBarang?`
          if (!!data.kelompokUtama?.id) {
            url += `${genFieldQueryParamStr({
              "kelompokUtama.id": data.kelompokUtama?.id,
            })}&`
          }
          return url + genBaseSortQueryParam()
        },
      },
      {
        id: "sales.id",
        label: "Sales",
        type: "async-select",
        resourceUrl: `karyawan?${genFieldQueryParamStr({
          jenisJabatan: "SALES",
        })}&${genBaseSortQueryParam()}`,
        dataViewKey: "sales.nama",
        isDataView: true,
      },
      {
        id: "tahun",
        label: "Tahun",
        type: "numeric",
        isDataView: true,
        suffix: {
          isHalfSize: true,
          id: "target",
          label: "Target",
          type: "number",
          isDataView: true,
        },
      },
    ]),
  },
  "hpp-manual": {
    permissionRequired: "SIT_master::hpp_manual",
    title: "HPP Manual",
    filter: <BaseFilter keyName="hppManual" />,
    urls: generateCrudUrl("hpp", { isLatest: 1 }),
    cetakActions: [],
    requiredToAdd: ["Divisi"],
    disableAdd(data, filterData) {
      return !filterData?.["barang.divisi.id"]
    },
    formGroupItems: genFormItems([
      {
        id: "barang.divisi.id",
        label: "Divisi",
        type: "async-select",
        resourceUrl: "divisi",
        searchBy: basicSearchByKeys,
      },
      {
        id: "barang.id",
        label: "Barang",
        type: "async-select",
        resourceUrl: (data, filterData) =>
          `barang?${genBaseSortQueryParam()}&${genFieldQueryParamStr({
            "divisi.id": filterData?.["barang.divisi.id"],
          })}`,
        dataViewKey: "barang.nama",
        isDataView: true,
      },
      {
        id: "noBapb",
        label: "No. BAPB",
        isDataView: true,
      },
      {
        id: "tglBapb",
        label: "Tanggal BAPB",
        type: "date",
        isDataView: true,
      },
      {
        id: "mataUang",
        label: "Mata Uang",
        type: "async-select",
        resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
        resourceMapper: mataUangOptionMapper,
        searchBy: mataUangSearchField,
      },
      {
        id: "rate",
        label: "Nilai Tukar Rupiah",
        type: "number",
        isDataView: true,
      },
      {
        id: "nilaiValas",
        label: "Nilai Valas",
        type: "number",
        isDataView: true,
      },
      {
        id: "rerataValas",
        label: "Rata - Rata Valas",
        type: "number",
      },
      {
        id: "nilaiRupiah",
        label: "Nilai Rupiah",
        type: "number",
        isDataView: true,
      },
      {
        id: "rerataRupiah",
        label: "Rata - Rata Rupiah",
        type: "number",
      },
    ]),
  },
}

export const MasterPageKeys = Object.keys(MasterPages)
