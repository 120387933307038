interface KodeSubKelompokBarangComponent {
  kodeKelompokUtama: string
  kodeKelompok: string
  kodeSub: string
}

export function parseSubkelompokCode(
  kode: string
): KodeSubKelompokBarangComponent {
  return {
    kodeKelompokUtama: kode.substring(0, 1),
    kodeKelompok: kode.substring(1, 3),
    kodeSub: kode.substring(3, 5),
  }
}

export function buildKodeSubKelompok(
  data: KodeSubKelompokBarangComponent
): string {
  return `${data.kodeKelompokUtama}${data.kodeKelompok}${data.kodeSub}`
}
