import { genOptionFromArray } from "common/util"

export const ModeOfShipmentOptions = genOptionFromArray([
  "UDARA",
  "LAUT",
  "KURIR",
])

export const NoAccountPiutangMockOption = genOptionFromArray([
  "1129820",
  "228232",
])

export const JenisKomisiOption = [
  {
    label: "Potong Komisi",
    value: "KOMISI",
  },
  {
    label: "Potong Reimburse",
    value: "REIMBURSE",
  },
]
