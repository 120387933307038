import { DataTableColumn } from "components/data-table/type"

export const POSTING_TRANSAKSI_COLUMNS: DataTableColumn<any>[] = [
  {
    dataKey: "noVoucher",
    label: "No. Voucher",
  },
  {
    dataKey: "perkiraan.nomorPerkiraan",
    label: "No. Perkiraan",
  },
  {
    dataKey: "perkiraan.keterangan",
    label: "Nama Perkiraan",
  },
  {
    dataKey: "keterangan",
    label: "Keterangan",
  },
  {
    dataKey: "debit",
    label: "Debit",
    type: "number",
  },
  {
    dataKey: "kredit",
    label: "Kredit",
    type: "number",
  },
]
