import generateItemSuratJalanCustomerFormItems from "common/form-item-generator/item-surat-jalan-customer"
import generateSuratJalanCustomerFormItems from "common/form-item-generator/surat-jalan-customer"
import {
  CetakItem,
  CetakItemParam,
  SuratJalanCustomerTypeEnum,
} from "common/type"
import {
  formatNumber,
  generateCrudUrl,
  genFieldQueryParamObj,
  genFormItems,
  getToday,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import { getAPI } from "hooks"
import { StockAvailability } from "services/stock"

const SuratJalanCustomerTitle = {
  [SuratJalanCustomerTypeEnum.STOCK]: "Surat Jalan ke Customer",
  [SuratJalanCustomerTypeEnum.BARANG_KHUSUS]: "Surat Jalan ke Customer",
  [SuratJalanCustomerTypeEnum.SERVICE]: "Surat Jalan Service ke Customer",
}

function generateSuratJalanCustomer(
  tipe: SuratJalanCustomerTypeEnum
): BasePageProps<any> {
  return {
    title: SuratJalanCustomerTitle[tipe],
    permissionRequired: "SuratJalanCustomer",
    urls: generateCrudUrl("surat-jalan-customer"),
    requiredToAdd: ["Cabang", "Divisi"],
    disableAdd: (data, filterData) =>
      !filterData?.["asal.id"] ||
      !filterData?.["salesContract.penawaran.divisi.id"],
    filter: <BaseFilter keyName="suratJalanCustomer" />,
    responseMapper(data) {
      const { salesContract } = data
      const { penawaran, ...restSc } = salesContract

      const penawaranFormatted = {
        ...penawaran,
        salesContracts: [restSc],
      }
      return {
        ...data,
        itemSuratJalanCustomers: data.itemSuratJalanCustomers.map((i: any) => {
          const dataItem = !!i.itemBarangJadi?.id
            ? i.itemBarangJadi
            : !!i.itemPenawaran?.id
            ? i.itemPenawaran
            : i.itemService
          return {
            ...i,
            item: {
              ...dataItem,
              penawaran: penawaranFormatted,
            },
            penawaran: penawaranFormatted,
          }
        }),
      }
    },
    generateCodeParam: (data, filter) => {
      if (!data.asal?.kode) return undefined
      return {
        entityName: "SuratJalanCustomer",
        entity: {
          tipe,
          asal: { kode: data.asal?.kode },
          salesContract: {
            penawaran: { isPpn: filter?.["salesContract.penawaran.isPpn"] },
          },
        },
      }
    },
    formGroupItems: genFormItems(generateSuratJalanCustomerFormItems(tipe)),
    cetakActions: (data) => {
      const invoiceParam: CetakItemParam[] = [
        {
          id: "invoicePelanggan.tanggalInvoice",
          type: "date",
          label: "Tanggal Invoice",
          value: getToday(),
          disabled(data) {
            return !!data.invoicePelanggan?.tanggalFaktur
          },
        },
      ]
      if (
        data?.salesContract?.penawaran?.isPpn &&
        data?.salesContract?.penawaran?.pelanggan?.barang === "A2"
      ) {
        invoiceParam.push({
          id: "isCetakPpn",
          type: "check",
          label: "PPN",
          value: true,
        })
      }
      const cetaks: CetakItem[] = [
        {
          label: "Cetak Surat Jalan",
          url: "/surat-jalan-customer/{id}/download/surat-jalan",
        },
        {
          label: "Cetak Invoice",
          url: "/surat-jalan-customer/{id}/download/invoice",
          params: invoiceParam,
        },
      ]

      if (!!data?.salesContract?.penawaran?.isPpn) {
        cetaks.push({
          label: "Cetak Faktur Pajak",
          url: "/surat-jalan-customer/{id}/download/faktur-pajak",
          params: [
            {
              id: "invoicePelanggan.tanggalFaktur",
              type: "date",
              label: "Tanggal Faktur Pajak",
              value: getToday(),
              disabled(data) {
                return !!data.invoicePelanggan?.tanggalFaktur
              },
            },
          ],
        })
      }

      return cetaks
    },

    additionalForms: [
      {
        id: "itemSuratJalanCustomers",
        title: "Detail Barang",
        disableAdd:
          SuratJalanCustomerTypeEnum.SERVICE === tipe
            ? (data: any) => !data?.tujuan?.id
            : (data: any) => !data?.tujuan?.id || !data?.salesContract?.id,
        formItems: generateItemSuratJalanCustomerFormItems(tipe),
        async validateDataCb(formData, globData, formMode) {
          if (!!formData.itemPenawaran?.id) {
            const stock: StockAvailability = await getAPI(
              "/RiwayatStockBarang/latest-stock",
              {
                barangId: +formData?.itemPenawaran?.barang?.id,
                organisasiId: +globData?.asal?.id,
              }
            )

            let latestStock = !!globData.salesContract?.penawaran?.isAtSpb
              ? +stock.barangJadi
              : +stock.available
            if (formMode === "update") {
              latestStock += +formData.jumlahAvailable
            }

            if (+formData.jumlahAvailable > latestStock) {
              return `Stock tidak cukup, mohon cek kembali. Stock terakhir: ${formatNumber(
                latestStock
              )}`
            }
          }
          return undefined
        },
      },
    ],
  }
}

export default generateSuratJalanCustomer
