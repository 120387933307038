import generateItemKonsinyasiFormItems from "common/form-item-generator/item-konsinyasi"
import generateKonsinyasiFormItems from "common/form-item-generator/konsinyasi"
import { getSQMForCounting } from "common/procedures/speisfikasi"
import {
  generateCrudUrl,
  genFieldQueryParamObj,
  genFormItems,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import { genBaseSortQueryParamObj } from "constant/url"
import { handleDownload } from "hooks"

function generateKonsinyasiForm(): BasePageProps<any> {
  return {
    permissionRequired: `SIT_sekawan::transaksi::jos::konsinyasi`,
    title: "Konsinyasi",
    urls: {
      ...generateCrudUrl("konsinyasi", genBaseSortQueryParamObj("id", false)),
    },
    filter: <BaseFilter keyName="konsinyasi" />,
    generateCodeParam: (data, filter) => {
      return {
        entity: {
          tipe: data.tipe,
          divisi: { id: filter?.["divisi.id"] },
          organisasi: { id: filter?.["organisasi.id"] },
        },
        entityName: "Konsinyasi",
      }
    },
    pageActions: [
      {
        label: "Cetak",
        action: async (filters) => {
          await handleDownload(
            "/konsinyasi/download/all",
            genFieldQueryParamObj(filters),
            false
          )
          console.log(filters)
        },
        confirmMsg: "Apakah anda yakin untuk mencetak data konsinyasi?",
      },
    ],
    formGroupItems: genFormItems(generateKonsinyasiFormItems()),
    additionalForms: [
      {
        id: "itemKonsinyasis",
        title: "Daftar Barang",
        recalculateCallback(rhf, globRhf) {
          const data = rhf.getValues()
          const luas = getSQMForCounting(
            data.spesifikasi?.panjang,
            data.spesifikasi?.lebar
          )
          rhf.setValue("spesifikasi.luas", luas.toFixed(2))
        },
        formItems: generateItemKonsinyasiFormItems(),
      },
    ],
  }
}

export default generateKonsinyasiForm
