import {
  Flex,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Image,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useToast,
  Input,
  FormControl,
  FormLabel,
} from "@chakra-ui/react"
import {
  AdditionFormType,
  CetakItem,
  CetakItemParam,
  CommonBasePageProps,
} from "common/type"
import Paper from "components/container/paper"
import FormGroup from "components/form/form-group"
import { FormGroupItem } from "components/form/type"
import { useBasePage, useBasePageDataTable } from "components/page/common"
import PageAdditionalForm from "components/page/page-additional-form"
import { AppPageInterface, CustomAction } from "components/page/type"
import SubTitle from "components/text/sub-title"
import { handleDownload } from "hooks"
import { useCallback, useState } from "react"
import { UseFormReturn } from "react-hook-form"
import ModalCetakWithParam from "./modal-cetak-with-param"
import { useStateLogger } from "hooks/logger"
import { getDataByKey } from "common/util"

interface ModalUndoableFormProps extends CommonBasePageProps {
  isCanUndo: boolean
  isCanRedo: boolean
  isOpen: boolean
  rhf: UseFormReturn
  isLoading: boolean
  onUndo?: () => any
  onRedo?: () => any
  formGroupItems: FormGroupItem[]
  onFormGroupChangeField?: (field: string) => void
  onClose: () => void
  onSubmit?: (data: any) => Promise<void> | void
  title: string
  additionalTitle?: string
  removeAction?: boolean
  cetakActions?: CetakItem[]
  customAction?: CustomAction
  onCheckTrx?: () => Promise<void>
  fetchMasterData?: () => Promise<any>
  isOnlyViewData?: boolean
}

const ModalUndoableForm: React.FC<ModalUndoableFormProps> = ({
  isCanUndo,
  isCanRedo,
  rhf,
  onUndo,
  onRedo,
  formGroupItems,
  onFormGroupChangeField,
  isOpen,
  onClose,
  onSubmit,
  title,
  additionalForms,
  additionalTitle,
  recalculateCallback,
  removeAction,
  cetakActions,
  customAction,
  fetchMasterData,
  alertMessage,
  generateCodeParam,
  onCheckTrx,
  isLoading,
  isOnlyViewData,
}) => {
  const toast = useToast()
  const { pageMode, setPageMode, formMode, pageRhf } = useBasePageDataTable()
  const { reset } = rhf
  const onUndoRedo = useCallback(
    (cb?: () => any) => {
      const state = cb?.() ?? {}
      reset(state)
    },
    [reset]
  )
  const [loadingCetak, setLoadingCetak] = useState<boolean>(false)
  const [cetakActionWithParam, setCetakActionWithParam] = useState<CetakItem>()

  const downloadDoc = useCallback(
    async (cetakItem: CetakItem) => {
      if (!!cetakItem.params?.length) {
        setCetakActionWithParam(cetakItem)

        return
      }
      setLoadingCetak(true)
      await handleDownload(cetakItem.url)
        .catch((err) => {
          toast({
            title: "Gagal",
            description: err.message ?? "Terjadi Kesalahan",
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top",
          })
        })
        .finally(() => {
          setLoadingCetak(false)
        })
    },
    [toast]
  )

  const _alertMessage =
    typeof alertMessage === "function"
      ? alertMessage(rhf.watch())
      : alertMessage
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={"4xl"}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        {/* @ts-ignore */}
        <ModalContent borderRadius="16px">
          <ModalHeader pb="4px">
            <Flex pr="48px">
              <SubTitle>{title}</SubTitle>
              <Spacer />
              {pageMode === "edit" && (
                <Flex>
                  <Image
                    src={`/images/ic_undo${isCanUndo ? "_active" : ""}.svg`}
                    width="20px"
                    alt="undo"
                    mr="8px"
                    cursor="pointer"
                    onClick={() => onUndoRedo(onUndo)}
                  />
                  <Image
                    src={`/images/ic_redo${isCanRedo ? "_active" : ""}.svg`}
                    width="20px"
                    alt="redo"
                    cursor="pointer"
                    onClick={() => onUndoRedo(onRedo)}
                  />
                </Flex>
              )}
            </Flex>
          </ModalHeader>
          <ModalCloseButton
            bg="rgba(138, 146, 166, 0.12)"
            borderRadius={99}
            fontSize={"6px"}
            mt="8px"
            mr="10px"
          />
          <ModalBody pb="0px">
            {_alertMessage && (
              <Alert
                status="warning"
                mb="8px"
                borderRadius={12}
                fontSize="14px"
              >
                <AlertIcon />
                <AlertTitle>Perhatian!!</AlertTitle>
                <AlertDescription>{_alertMessage}</AlertDescription>
              </Alert>
            )}
            {formGroupItems.filter(
              (i) =>
                i.type !== "hidden" &&
                i.type !== "ignored" &&
                !(typeof i.isIgnored === "function"
                  ? i.isIgnored(formMode)
                  : i.isIgnored)
            ).length > 0 && (
              <Paper isBorder={true}>
                <FormGroup
                  items={formGroupItems}
                  formHook={rhf}
                  recalculateCallback={recalculateCallback}
                  generateCodeParam={generateCodeParam}
                />
              </Paper>
            )}
            {!!additionalForms?.length && !!additionalTitle && (
              <Box mt="20px">
                <SubTitle>{additionalTitle}</SubTitle>
              </Box>
            )}
            {(typeof additionalForms === "function"
              ? additionalForms(formMode ?? "create")
              : additionalForms
            )?.map((form, key) => {
              return (
                <>
                  <PageAdditionalForm
                    isOnlyViewData={isOnlyViewData}
                    key={key}
                    validateDataCb={form.validateDataCb}
                    title={form.title}
                    id={form.id}
                    // @ts-ignore
                    viewData={rhf.watch(form.id, [])}
                    viewFields={form.formItems}
                    requiredToAdd={form.requiredToAdd}
                    recalculateCallback={form.recalculateCallback}
                    onUpdateData={(data) => {
                      const newValue: any[] = []
                      const currValue = rhf.watch(form.id) ?? []

                      for (const val of currValue) {
                        if (val.xId === data.xId) {
                          newValue.push(data)
                        } else {
                          newValue.push(val)
                        }
                      }

                      rhf.setValue(form.id, newValue)
                      recalculateCallback?.(rhf)
                      toast({
                        title: "Berhasil",
                        description: "Data berhasil diubah",
                        position: "top-right",
                        status: "success",
                        duration: 8000,
                        isClosable: true,
                      })
                    }}
                    generateCodeParam={form.generateCodeParam}
                    onAddData={(data) => {
                      const newValue: any[] = []
                      // @ts-ignore
                      const currValue = rhf.watch(form.id) ?? []

                      let isAdded = false
                      for (const val of currValue) {
                        if (
                          data[form.primaryKey ?? "xId"] ===
                          val[form.primaryKey ?? "xId"]
                        ) {
                          newValue.push(data)
                          isAdded = true
                        } else {
                          newValue.push(val)
                        }
                      }
                      if (!isAdded) {
                        newValue.push(data)
                      }
                      rhf.setValue(form.id, newValue)
                      recalculateCallback?.(rhf, pageRhf)
                      toast({
                        title: "Berhasil",
                        description: `Data berhasil ${
                          !isAdded ? "ditambah" : "diubah"
                        }`,
                        position: "top-right",
                        status: "success",
                        duration: 8000,
                        isClosable: true,
                      })
                    }}
                    onRemoveData={(id) => {
                      // @ts-ignore
                      const currValue = rhf.watch(form.id) ?? []
                      rhf.setValue(
                        form.id,
                        currValue.filter((i: any) => i.xId !== id)
                      )
                      recalculateCallback?.(rhf, pageRhf)
                      toast({
                        title: "Berhasil",
                        description: "Data berhasil dihapus",
                        position: "top-right",
                        status: "warning",
                        duration: 8000,
                        isClosable: true,
                      })
                    }}
                    hideAdd={form.hideAdd}
                    enableEdit={form.enableEdit}
                    disableAdd={form.disableAdd}
                    idGenerator={form.idGenerator}
                    primaryKey={form.primaryKey}
                    additionalForms={form.additionalForms}
                    summaryCallback={form.summaryCallback}
                  />
                </>
              )
            })}
          </ModalBody>

          {!removeAction ? (
            <ModalFooter>
              {!customAction?.preventView && pageMode === "view" ? (
                <>
                  {cetakActions?.map((action, key) => (
                    // @ts-ignore
                    <Button
                      colorScheme={"blue"}
                      variant="outline"
                      onClick={async () => {
                        await downloadDoc(action)
                        if (!!action.isRefetch) {
                          await fetchMasterData?.()
                        }
                      }}
                      mr="8px"
                      key={key}
                      isLoading={isLoading || loadingCetak}
                    >
                      {action.label}
                    </Button>
                  ))}
                  {!isOnlyViewData && (
                    <Button
                      colorScheme={"green"}
                      variant="outline"
                      onClick={() => {
                        if (!!onCheckTrx) {
                          onCheckTrx?.().then(() => setPageMode?.("edit"))
                        } else {
                          setPageMode?.("edit")
                        }
                      }}
                      isLoading={isLoading}
                    >
                      Edit
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <Button
                    colorScheme={"red"}
                    variant="outline"
                    mr={3}
                    onClick={onClose}
                    isLoading={isLoading}
                  >
                    Batal
                  </Button>
                  {!isOnlyViewData && onSubmit && (
                    <Button
                      colorScheme={customAction?.color ?? "green"}
                      variant="outline"
                      onClick={rhf.handleSubmit(onSubmit)}
                      isLoading={isLoading}
                    >
                      {customAction?.label ?? "Simpan"}
                    </Button>
                  )}
                </>
              )}
            </ModalFooter>
          ) : (
            <Box h="20px" />
          )}
        </ModalContent>
      </Modal>
      {!!cetakActionWithParam && (
        <ModalCetakWithParam
          cetakActionWithParam={cetakActionWithParam}
          reloadData={async () => {
            await fetchMasterData?.()
            onClose()
          }}
          onClose={() => {
            setCetakActionWithParam(undefined)
          }}
        />
      )}
    </>
  )
}

export default ModalUndoableForm
