import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react"
import { AdditionFormType, GeneralMap } from "common/type"
import DataTable, {
  buildBulkUpdatePageDataTableColumn,
} from "components/data-table"
import BaseFilter from "components/filter/_base"
import ModuleDashboard from "components/layout/module-dashboard"
import ModalConfirm from "components/modal/modal-confirm"
import ModalInfo from "components/modal/modal-info"
import { withAuth } from "hoc/auth"
import { useCetak, usePost } from "hooks"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  BasePageContext,
  BasePageDataTableContext,
  useDataPage,
  useGetFormItem,
} from "./common"
import { BulkPageProps } from "./type"
import ModalUndoableForm from "components/modal/modal-undoable-form"
import { Controller, useForm } from "react-hook-form"
import { DataTableColumn } from "components/data-table/type"
import FormError from "components/error/form-error"

const BulkUpdatePage: React.FC<BulkPageProps> = ({
  title,
  filterName,
  urls,
  formItems,
  actionLabel,
  disablePagination,
  bulkDataKey,
  excelUrl,
  bulkRequestDataMapper,
  isAutoAddAll,
  responseMapper,
  ignoreFilterOnFetch,
  additionalForms: additionalFormProps,
  requiredFilterKey,
  isHighlightRowCb,
  bulkActionCb,
  actions,
}) => {
  useEffect(() => {
    document.title = `${title} | Sekawan`
  }, [title])
  const toast = useToast()
  const [detail, setDetail] = useState<any>()
  const [bulkDataPairs, setBulkDataPairs] = useState<GeneralMap<any>>({})
  const [isConfirmSave, setIsConfirmSave] = useState<boolean>(false)
  const [activeActionIdx, setActiveActionIdx] = useState<number>(-1)

  const [flagValue, setFlagValue] = useState<boolean | undefined>()

  const {
    masters,
    isLoadingGet,
    setFilterQuery,
    setDataListPageSize,
    dataListCurrentPage,
    setDataListCurrentPage,
    setQuerySearch,
    setSorter,
    dataListPageSize,
    fetchMasterData,
    filterQuery,
    isDisableAdd,
    queryParam,
  } = useDataPage<any>(
    urls?.read.endpoint,
    useMemo(() => formItems.map((i) => i.dataKey), [formItems]),
    urls?.read?.param,
    responseMapper,
    disablePagination,
    ignoreFilterOnFetch,
    useCallback(
      (data, filterData) => {
        for (const filterKey of requiredFilterKey ?? []) {
          if (!filterData?.[filterKey]) return true
        }

        return false
      },
      [requiredFilterKey]
    )
  )

  const excelForm = useForm()
  const [isDownloadExcel, setIsDownloadExcel] = useState<boolean>(false)

  const cetakQueryParam = useMemo(() => {
    const { page, pageSize, ...restParam } = queryParam

    return restParam
  }, [queryParam])

  const excelFileName = excelForm.watch("fileName")
  const { doCetak: downloadExcel, isLoading: isLoadingExcel } = useCetak(
    excelUrl,
    useMemo(
      () => ({ ...cetakQueryParam, fileName: excelFileName }),
      [cetakQueryParam, excelFileName]
    ),
    true
  )

  const onDownloadExcel = useCallback(async () => {
    try {
      await downloadExcel()

      setIsDownloadExcel(false)
      excelForm.reset()
    } catch (err: any) {
      toast({
        title: "Gagal",
        description: err.message ?? "Terjadi Kesalahan",
        status: "error",
        duration: 8000,
        isClosable: true,
        position: "top",
      })
    }
  }, [downloadExcel, excelForm, toast])

  const { doFetch: bulkUpdate, isLoading: isLoadingBulk } = usePost(
    urls.update.endpoint,
    urls.update.method
  )

  useEffect(() => {
    if (isAutoAddAll || !!masters?.data) {
      const pairs: GeneralMap<boolean> = {}
      for (const i of masters?.data ?? []) {
        pairs[i.id] = !!i[bulkDataKey ?? ""]
      }

      setBulkDataPairs(pairs)
    }
  }, [masters, isAutoAddAll, bulkDataKey])

  const onConfirm = useCallback(() => {
    let actionMapper:
      | ((data: any[], updateValue: any) => Promise<void>)
      | undefined = undefined
    if (!isConfirmSave && activeActionIdx >= 0) {
      actionMapper = actions?.[activeActionIdx]?.action
    }

    if (!actionMapper) {
      actionMapper = bulkRequestDataMapper
    }

    bulkUpdate(
      actionMapper?.(masters?.data ?? [], bulkDataPairs) ?? bulkDataPairs
    )
      .then((res: any) => {
        setIsConfirmSave(false)
        setActiveActionIdx(-1)

        if (!bulkActionCb) {
          toast({
            title: "Berhasil",
            description: "Data berhasil diubah",
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top",
          })
        } else {
          bulkActionCb(res.data, toast)
        }

        fetchMasterData()
        setBulkDataPairs({})
      })
      .catch((err) => {
        toast({
          title: "Gagal",
          description:
            err.message || "Terjadi kesalahan! Mohon coba beberapa saat lagi.",
          status: "error",
          duration: 8000,
          isClosable: true,
          position: "top",
        })
      })
      .finally(() => {})
  }, [
    isConfirmSave,
    activeActionIdx,
    actions,
    bulkDataPairs,
    toast,
    fetchMasterData,
    bulkUpdate,
    bulkRequestDataMapper,
    masters?.data,
  ])

  const rhf = useForm()

  const additionalForms: AdditionFormType = useMemo(() => {
    return (
      additionalFormProps?.map((item) => ({
        id: item.id,
        title: item.title,
        formItems: item.formItems.map((i) => ({
          id: i.dataKey,
          label: i.label as string,
          isDataView: true,
        })),
        hideAdd: true,
        enableEdit: false,
      })) ?? []
    )
  }, [additionalFormProps])

  const formGroupItems = useGetFormItem(
    formItems?.map((item: DataTableColumn<any>) => ({
      id: item.dataKey,
      label: item.label as string,
      isDataView: true,
      type: item.type,
    })) ?? [],
    "view",
    "create",
    additionalForms,
    detail,
    filterQuery,
    ignoreFilterOnFetch
  )

  const isAllChecked = useMemo(() => {
    if (!masters?.data.length) return false
    let checkedAmount = 0
    for (const pair in bulkDataPairs) {
      if (!!bulkDataPairs[pair]) checkedAmount += 1
    }

    return checkedAmount === masters?.data.length
  }, [bulkDataPairs, masters?.data.length])

  const sortableFields = useMemo(() => {
    return formItems.filter((i) => !!i.sortable).map((i) => i.dataKey)
  }, [formItems])

  return (
    <BasePageContext.Provider
      value={{
        onChangeQuery: setFilterQuery,
        globVars: new Map(),
        detail: {},
        changeListener: 0,
        preCheckOrderCb: console.log,
      }}
    >
      <ModuleDashboard
        title={`${title}`}
        filter={filterName ? <BaseFilter keyName={filterName} /> : undefined}
        dataView={
          <>
            <BasePageDataTableContext.Provider
              value={{ pageMode: "view", formMode: "update" }}
            >
              {isDisableAdd && (
                // @ts-ignore
                <Alert
                  status="warning"
                  mb="8px"
                  borderRadius={12}
                  fontSize="14px"
                >
                  <AlertIcon />
                  <AlertDescription>
                    Field wajib pada filter di atas tidak boleh kosong
                  </AlertDescription>
                </Alert>
              )}
              <DataTable
                onClickAdd={
                  !bulkDataKey
                    ? undefined
                    : () => {
                        setFlagValue(undefined)
                        setIsConfirmSave(true)
                      }
                }
                isHighlightRowCb={isHighlightRowCb}
                hideData={isDisableAdd || isLoadingGet}
                disableAdd={isDisableAdd}
                dataSource={masters?.data ?? []}
                addLabel="Simpan"
                columns={buildBulkUpdatePageDataTableColumn(
                  formItems,
                  actionLabel,
                  setBulkDataPairs,
                  bulkDataPairs,
                  useMemo(() => bulkDataKey ?? "", [bulkDataKey]),
                  isAllChecked,
                  useMemo(() => masters?.data ?? [], [masters?.data]),
                  sortableFields
                )}
                pageSize={dataListPageSize}
                totalData={!disablePagination ? masters?.total ?? 0 : undefined}
                onPageChange={(currentPage) => {
                  setDataListCurrentPage(currentPage + 1)
                }}
                onPageSizeChange={(pageSize) => {
                  setDataListPageSize(pageSize)
                }}
                onSearch={(query) => {
                  setQuerySearch(query)
                }}
                onSort={(sortBy, sortTypeNum) => {
                  if (sortTypeNum !== 0) {
                    setSorter({
                      sortBy,
                      sortType: sortTypeNum === 1 ? "asc" : "desc",
                    })
                  } else {
                    setSorter(undefined)
                  }
                }}
                currPage={dataListCurrentPage - 1}
                isLoading={isLoadingGet}
                onDoubleClickRow={(_data) => {
                  // const data = _data as any
                  // setDetail(data)
                  setBulkDataPairs((prevState: GeneralMap<any>) => ({
                    ...prevState,
                    [_data.id]: !prevState[_data.id],
                  }))
                }}
              />
              <ModalUndoableForm
                isOnlyViewData={true}
                formGroupItems={formGroupItems}
                isCanRedo={false}
                isLoading={isLoadingBulk}
                isCanUndo={false}
                isOpen={!!detail}
                onClose={() => {
                  setDetail(undefined)
                }}
                rhf={rhf}
                onFormGroupChangeField={undefined}
                title={title}
                additionalForms={additionalForms}
                cetakActions={[]}
                fetchMasterData={fetchMasterData}
              />
              <ModalConfirm
                isOpen={isConfirmSave || activeActionIdx >= 0}
                onClose={() => {
                  setIsConfirmSave(false)
                  setActiveActionIdx(-1)
                }}
                onConfirm={onConfirm}
                isLoading={isLoadingBulk}
                confirmAction={{
                  label: "Simpan",
                  color: "blue",
                  msg: "Apakah anda yakin untuk menyimpan data?",
                  title: "Konfirmasi",
                }}
              />
              {!!actions && (
                <Flex justifyContent="flex-end" mt="16px">
                  {actions?.map((action, key) => (
                    <Button
                      variant="outline"
                      colorScheme={action.color}
                      mr="8px"
                      onClick={() => {
                        setActiveActionIdx(key)
                      }}
                    >
                      {action.label}
                    </Button>
                  ))}
                </Flex>
              )}
            </BasePageDataTableContext.Provider>
            {excelUrl && (
              // @ts-ignore
              <Flex justifyContent={"flex-end"}>
                <Button
                  colorScheme="blue"
                  variant="outline"
                  ml="8px"
                  onClick={() => setIsDownloadExcel(true)}
                  isLoading={isLoadingExcel}
                  disabled={!masters?.data?.length}
                >
                  Export Excel
                </Button>
              </Flex>
            )}
            <ModalInfo
              isOpen={isDownloadExcel}
              onClose={() => setIsDownloadExcel(false)}
              title="Download Laporan Excel"
            >
              <>
                <FormControl
                  isRequired={true}
                  isInvalid={!!excelForm.formState?.errors?.fileName}
                >
                  <FormLabel>Nama File</FormLabel>
                  <Controller
                    control={excelForm.control}
                    name={"fileName"}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        placeholder={"Nama File"}
                        value={value}
                        onChange={({ target: { value } }) => {
                          onChange(value)
                        }}
                        height={"32px"}
                        fontSize={"13px"}
                      />
                    )}
                  />
                  <FormError error={excelForm.formState?.errors?.fileName} />
                </FormControl>
                <Flex justify="flex-end">
                  <Button
                    colorScheme="blue"
                    variant="outline"
                    mt="8px"
                    onClick={excelForm.handleSubmit(onDownloadExcel)}
                    isLoading={isLoadingExcel}
                  >
                    Export Excel
                  </Button>
                </Flex>
              </>
            </ModalInfo>
          </>
        }
      />
    </BasePageContext.Provider>
  )
}

export default withAuth(BulkUpdatePage)
