import { ACCESS_TOKEN_KEY } from "constant"

export interface HttpApiError {
  code: number
  message: string
}

export interface DataList<T> {
  data: T[]
  total: number
  warning?: string
}

export interface IResponse<T> {
  data: T
}

export function buildEndpointWithParam(
  endpoint: string,
  param: { [key: string]: any }
): string {
  let finalEndpoint = endpoint
  for (const paramKey in param) {
    finalEndpoint = finalEndpoint.replace(`{${paramKey}}`, param[paramKey])
  }

  return finalEndpoint
}

export function mapDataList<T>(
  rsp: any,
  mapper: (itemResponse: any) => T
): DataList<T> {
  const { data = {} } = rsp
  return {
    data: data.items?.map((i: any) => mapper(i)) || [],
    total: data.count ?? 0,
    warning: data.warning,
  }
}

export function literalMapper(data: any) {
  return data
}

export function LiteralListMapper(data: any) {
  return mapDataList(data, (i) => i)
}

export function LiteralListMapperWithCustomItemMapper(
  itemMapper: (data: any) => any
) {
  return (data: any) => mapDataList(data, itemMapper)
}

export const apiBaseUrl = process.env.REACT_APP_API_URL!!
export const kursApiBaseUrl = process.env.REACT_APP_KURS_API_URL!!

export function getReqHeader(): any {
  const headers: any = {}
  const token = localStorage.getItem(ACCESS_TOKEN_KEY)
  if (!!token) {
    headers["Authorization"] = `Bearer ${token}`
  }

  return headers
}
