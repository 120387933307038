import {
  Image,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Spacer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Select,
  Button,
  Box,
  Spinner,
  Checkbox,
  Icon,
} from "@chakra-ui/react"
import { MdPrint, MdOpenInNew, MdLock, MdLockOpen } from "react-icons/md"
import { formatNumber, formatOnlyDate, getDataByKey } from "common/util"
import { BasePageFormGroupItem, LockModuleSpec } from "components/page/type"
import { ColorDict } from "constant/theme"
import { useDebounce } from "hooks"
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import ReactPaginate from "react-paginate"
import { DataTableCellType, DataTableColumn, DataTableProps } from "./type"
import { GeneralMap } from "common/type"
import { useBasePage } from "components/page/common"
import { useStateLogger } from "hooks/logger"
import { Profile } from "services/profile"

interface SortingImageProps {
  sort: number
  onClick: () => void
}

const SortingImage: React.FC<SortingImageProps> = ({ sort, onClick }) => {
  const imgName = useMemo(() => {
    switch (sort) {
      case 1:
        return "ic_asc_sort.svg"
      case -1:
        return "ic_desc_sort.svg"
      default:
        return "ic_default_sort.svg"
    }
  }, [sort])

  return (
    // @ts-ignore
    <Image
      src={`/images/${imgName}`}
      alt="sort"
      width="14px"
      ml="8px"
      cursor={"pointer"}
      onClick={onClick}
    />
  )
}

const SORT_PAIR: { [key: number]: number } = {
  0: 1,
  1: -1,
  [-1]: 0,
}

function renderCell(
  value: any,
  type?: DataTableCellType
): ReactElement | string {
  if (typeof value === "undefined" || value === null) return ""
  switch (type) {
    case "number":
      return value === 0 ? "0" : !!value ? formatNumber(value) : ""
    case "date":
      return formatOnlyDate(new Date(value))
    default:
      return value
  }
}

export const TableCell: React.FC<{ fontWeight?: number }> = ({
  children,
  fontWeight,
}) => {
  return (
    <Text fontSize="14px" fontWeight={fontWeight || 500}>
      {children}
    </Text>
  )
}

function DataTable<DataType>({
  columns,
  dataSource,
  onPageChange,
  pageSize,
  totalData,
  onSearch,
  onClickRow,
  onDoubleClickRow,
  isHighlightRowCb,
  onClickAdd,
  onPageSizeChange,
  onSort,
  isLoading,
  disableAdd,
  currPage,
  hideData,
  addLabel,
  summaryCallback,
}: DataTableProps<DataType>) {
  const { globVars, filterData } = useBasePage()
  const [search, setSearch] = useState<string>()
  const [sortingColumns, setSortingColumns] = useState<{
    [key: string]: number
  }>({})

  const searchQuery = useDebounce(search, 800)

  useEffect(() => {
    if (typeof searchQuery !== "undefined") {
      onSearch?.(searchQuery)
    }
  }, [searchQuery, onSearch])

  useStateLogger(sortingColumns, "sortingColumns")

  const onChangeSort = useCallback(
    (id: string) => {
      setSortingColumns((prevState) => {
        const newState: {
          [key: string]: number
        } = {}
        const newSortEnum = SORT_PAIR[prevState[id] ?? 0]

        newState[id] = newSortEnum

        onSort?.(id, newSortEnum)
        return newState
      })
    },
    [onSort]
  )

  return (
    <>
      {/* @ts-ignore */}
      <Flex mb={4} mt="8px">
        {!!onClickAdd && (
          <Button onClick={onClickAdd} isDisabled={disableAdd}>
            {addLabel ?? "Tambah"}
          </Button>
        )}
        <Spacer />
        {!!onSearch && (
          <Box w={300}>
            <InputGroup>
              <InputLeftAddon
                bg="transparent"
                border="solid 1px #E7EEF7"
                borderRight="none"
                borderRadius={22}
                h="32px"
                pr="0"
              >
                {isLoading ? (
                  <Spinner size="xs" />
                ) : (
                  <Image
                    src="/images/ic_search.svg"
                    width="16px"
                    alt="calendar"
                  />
                )}
              </InputLeftAddon>
              <Input
                placeholder="Cari..."
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                borderLeft="none"
                _focus={{
                  outline: "none",
                }}
              />
            </InputGroup>
          </Box>
        )}
      </Flex>
      {!hideData && (
        <>
          <Box overflow="auto">
            <Table variant={"simple"}>
              <Thead>
                <Tr>
                  {columns.map((column) => (
                    <Th
                      key={column.dataKey}
                      color={ColorDict.primary}
                      fontSize="14px"
                      letterSpacing={0}
                      px="8px"
                    >
                      <Flex>
                        {typeof column.label === "string"
                          ? column.label
                          : column.label()}
                        {!column.disableSort && (
                          <SortingImage
                            sort={sortingColumns[column.dataKey]}
                            onClick={() => {
                              onChangeSort(column.dataKey)
                            }}
                          />
                        )}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {dataSource.length > 0 ? (
                  <>
                    {dataSource.map((dataItem, key) => {
                      const isHighlighted =
                        !!isHighlightRowCb && isHighlightRowCb(dataItem)
                      return (
                        <Tr
                          key={key}
                          _hover={{
                            backgroundColor: "rgba(72, 138, 199, 0.1)",
                          }}
                          backgroundColor={
                            isHighlighted ? "rgba(72, 72, 72, 0.075)" : "white"
                          }
                          cursor={!!onClickRow ? "pointer" : "default"}
                        >
                          {columns.map((column) => (
                            <Td
                              py="6px"
                              px="10px"
                              whiteSpace={"nowrap"}
                              borderColor={isHighlighted ? "white" : undefined}
                              onClick={
                                !!onDoubleClickRow
                                  ? undefined
                                  : () => {
                                      if (!column.isActionColumn) {
                                        onClickRow?.(dataItem)
                                      }
                                    }
                              }
                              onDoubleClick={
                                !!onClickRow
                                  ? undefined
                                  : () => {
                                      if (!column.isActionColumn) {
                                        onDoubleClickRow?.(dataItem)
                                      }
                                    }
                              }
                            >
                              {!column.render ? (
                                <TableCell
                                  fontWeight={isHighlighted ? 700 : 500}
                                >
                                  {column.type === "pre" ? (
                                    <pre style={{ font: "inherit" }}>
                                      {getDataByKey(
                                        dataItem as any,
                                        column.dataKey
                                      )}
                                    </pre>
                                  ) : (
                                    renderCell(
                                      getDataByKey(
                                        dataItem as any,
                                        column.dataKey
                                      ),
                                      column.type
                                    )
                                  )}
                                </TableCell>
                              ) : (
                                column.render(dataItem, filterData, globVars)
                              )}
                            </Td>
                          ))}
                        </Tr>
                      )
                    })}
                    {!!summaryCallback &&
                      summaryCallback.map((cb, cbKey) => {
                        const result = cb(dataSource)
                        return (
                          <Tr>
                            <Td colSpan={columns.length - 2} textAlign="right">
                              <Text
                                fontSize="14px"
                                color="black"
                                fontWeight={"bold"}
                              >
                                {result.label}
                              </Text>
                            </Td>
                            <Td textAlign={"left"} py="7px" pl="8px" pr="0px">
                              <Text
                                fontSize="14px"
                                color="black"
                                fontWeight={"bold"}
                              >
                                {typeof result.total === "number"
                                  ? formatNumber(result.total)
                                  : result.total}
                              </Text>
                            </Td>
                          </Tr>
                        )
                      })}
                  </>
                ) : (
                  <Tr>
                    <Td colSpan={columns.length} textAlign="center">
                      <Text fontSize="14px" color={ColorDict.text}>
                        Data Kosong
                      </Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>

          {typeof totalData !== "undefined" &&
            typeof pageSize !== "undefined" && (
              <Flex>
                <Flex align={"center"} ml={2}>
                  <Text fontSize={13} color="#737B8B">
                    Menampilkan
                  </Text>
                  <Select
                    fontSize={12}
                    mx={"4px"}
                    h={"28px"}
                    value={pageSize}
                    onChange={({ currentTarget: { value } }) => {
                      onPageChange?.(0)
                      onPageSizeChange?.(parseInt(value))
                    }}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </Select>
                  <Text fontSize={13} color="#737B8B" whiteSpace={"nowrap"}>
                    dari {totalData}
                  </Text>
                </Flex>
                <Spacer />
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel=">"
                  breakLabel="..."
                  breakClassName="break-pagination"
                  pageCount={Math.ceil(totalData / pageSize)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={pageSize}
                  onPageChange={({ selected }) => onPageChange?.(selected)}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  forcePage={currPage}
                />
              </Flex>
            )}
        </>
      )}
    </>
  )
}

export function mapFormItemsToDataTableColumn(
  formItems: BasePageFormGroupItem[],
  disableSort?: boolean,
  filterData?: any,
  profile?: Profile
): DataTableColumn<any>[] {
  const result: DataTableColumn<any>[] = []
  for (const item of formItems) {
    const isDataView =
      typeof item.isDataView === "function"
        ? item.isDataView(profile)
        : item.isDataView
    if (isDataView || !!item.dataViewLabel) {
      result.push({
        label:
          item.dataViewLabel ??
          (typeof item.label === "function"
            ? item.label({}, filterData)
            : item.label) ??
          "",
        dataKey:
          (typeof item.dataViewKey === "function" ? null : item.dataViewKey) ??
          item.id,
        type: item.type as DataTableCellType,
        disableSort,
        render: item.columnRender,
      })
    }

    if (!!item.suffix && item.suffix.isDataView) {
      result.push({
        label:
          (typeof item.suffix.label === "function"
            ? item.suffix.label({}, filterData)
            : item.suffix.label) ?? "",
        dataKey:
          (typeof item.suffix.dataViewKey === "function"
            ? null
            : item.suffix.dataViewKey) ?? item.suffix.id,
        type: item.suffix.type as DataTableCellType,
        disableSort,
      })
    }
  }
  return result
}

interface DataTableActionProps {
  onPrint?: (data: any) => Promise<void>
  data: any
  changeDetail: (data: any, idx?: number) => Promise<void>
  doDelete: (id: any) => void
  primaryKey?: string
  colIdx?: number
  doLock?: (data: any, lockOpen: boolean) => void
  getIsLockOpen?: (data: any) => boolean
}

const DataTableAction: React.FC<DataTableActionProps> = ({
  onPrint,
  data,
  changeDetail,
  doDelete,
  primaryKey,
  colIdx,
}) => {
  const [loadingPrint, setLoadingPrint] = useState<boolean>(false)
  const [loadingCheckUpdate, setLoadingCheckUpdate] = useState<boolean>(false)
  return (
    <Flex justify="flex-end">
      {!!onPrint && (
        <Flex
          bg="rgba(72, 138, 199, 0.12)"
          cursor={loadingPrint ? "not-allowed" : "pointer"}
          onClick={() => {
            if (loadingPrint) return
            setLoadingPrint(true)
            onPrint(data).finally(() => setLoadingPrint(false))
          }} // send id that will be overwritten to enable update detection in module without id
          mr="8px"
          justify={"center"}
          align="center"
          h="28px"
          w="28px"
          borderRadius={99}
          opacity={!loadingPrint ? 1 : 0.5}
        >
          <Icon as={MdPrint} />
        </Flex>
      )}
      <Flex
        bg="rgba(72, 138, 199, 0.12)"
        cursor={loadingCheckUpdate ? "not-allowed" : "pointer"}
        onClick={() => {
          if (loadingCheckUpdate) return
          setLoadingCheckUpdate(true)
          changeDetail({ id: -1, ...data }, colIdx).finally(() =>
            setLoadingCheckUpdate(false)
          )
        }} // send id that will be overwritten to enable update detection in module without id
        mr="8px"
        justify={"center"}
        align="center"
        h="28px"
        w="28px"
        borderRadius={99}
        opacity={!loadingCheckUpdate ? 1 : 0.5}
      >
        <Image src="/images/ic_edit.svg" width="12px" alt="edit" />
      </Flex>
      <Flex
        bg="rgba(214, 3, 18, 0.12)"
        cursor="pointer"
        onClick={() => {
          doDelete(data[primaryKey ?? "id"])
        }}
        mr="8px"
        justify={"center"}
        align="center"
        h="28px"
        w="28px"
        borderRadius={99}
      >
        <Image src="/images/ic_trash.svg" width="14px" alt="edit" />
      </Flex>
    </Flex>
  )
}

export function buildCrudDataTableColumn(
  formItems: BasePageFormGroupItem[],
  changeDetail: (data: any, idx?: number) => Promise<void>,
  doDelete: (id: any) => void,
  primaryKey?: string,
  disableSort?: boolean,
  notEditable?: boolean,
  onPrint?: (data: any) => Promise<void>,
  onLock?: (data: any, lockOpen: boolean) => Promise<void>,
  lockOpenSpec?: LockModuleSpec,
  filterData?: any,
  profile?: Profile
): DataTableColumn<any>[] {
  const base: DataTableColumn<any>[] = mapFormItemsToDataTableColumn(
    formItems,
    disableSort,
    filterData,
    profile
  )

  if (!!onLock && !!lockOpenSpec) {
    base.push({
      label: lockOpenSpec.title,
      disableSort: true,
      dataKey: "lock",
      isActionColumn: true,
      render: (data, colIdx) => {
        return (
          // @ts-ignore
          <Flex
            bg="rgba(72, 138, 199, 0.12)"
            cursor="pointer"
            onClick={() => {
              onLock(data, !lockOpenSpec.getIsLocked(data))
            }} // send id that will be overwritten to enable update detection in module without id
            mr="8px"
            justify={"center"}
            align="center"
            h="28px"
            w="28px"
            borderRadius={99}
          >
            <Icon as={!!lockOpenSpec.getIsLocked(data) ? MdLockOpen : MdLock} />
          </Flex>
        )
      },
    })
  }

  const res = base.concat(
    notEditable
      ? [
          {
            label: " ",
            disableSort: true,
            dataKey: "action",
            isActionColumn: true,
            render: (data, colIdx) => {
              return (
                // @ts-ignore
                <Flex
                  bg="rgba(72, 138, 199, 0.12)"
                  cursor="pointer"
                  onClick={() => {
                    // send id that will be overwritten to enable update detection in module without id
                    changeDetail({ id: -1, ...data }, colIdx)
                  }}
                  mr="8px"
                  justify={"center"}
                  align="center"
                  h="28px"
                  w="28px"
                  borderRadius={99}
                >
                  <Icon as={MdOpenInNew} />
                </Flex>
              )
            },
          },
        ]
      : [
          {
            label: " ",
            disableSort: true,
            dataKey: "action",
            isActionColumn: true,
            render: (data, colIdx) => {
              return (
                <DataTableAction
                  changeDetail={changeDetail}
                  data={data}
                  doDelete={doDelete}
                  colIdx={colIdx}
                  onPrint={onPrint}
                  primaryKey={primaryKey}
                />
              )
            },
          },
        ]
  )

  return res
}

export function buildBulkUpdatePageDataTableColumn(
  formItems: DataTableColumn<any>[],
  actionLabel: string | undefined,
  setBulkDataCb: SetStateAction<any>,
  bulkData: GeneralMap<any>,
  actionKey: string,
  isAllChecked: boolean,
  baseData: any[],
  sortableFields: string[]
): DataTableColumn<any>[] {
  const base: DataTableColumn<any>[] = formItems.map((i) => ({
    ...i,
    disableSort: !sortableFields.includes(i.dataKey),
  }))

  if (!actionLabel) return base

  return [
    {
      label: () => {
        return (
          <Box
            bg={"white"}
            ml="4px"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Checkbox
              isChecked={isAllChecked}
              onDoubleClick={() => {
                setBulkDataCb((prevState: GeneralMap<any>) => {
                  const result: { [key: string]: boolean } = {}
                  for (const item of baseData) {
                    result[item.id] = !isAllChecked
                  }

                  setBulkDataCb(result)
                })
              }}
            />
          </Box>
        )
      },
      disableSort: true,
      dataKey: actionKey,
      isActionColumn: true,
      render: (data, colIdx) => {
        return (
          <Box
            bg={"white"}
            ml="4px"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Checkbox
              isChecked={!!bulkData[data.id]}
              onDoubleClick={() => {
                setBulkDataCb((prevState: GeneralMap<any>) => ({
                  ...prevState,
                  [data.id]: !prevState[data.id],
                }))
              }}
            />
          </Box>
        )
      },
    },
    ...base,
  ]
}

export default DataTable
