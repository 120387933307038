import {
  Modal,
  Text,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react"
import { ColorDict } from "constant/theme"

interface ModalConfirmProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  isLoading?: boolean
  confirmAction: {
    label: string
    color: string
    msg: string
    title: string
  }
}

const ModalConfirm: React.FC<ModalConfirmProps> = ({
  isOpen,
  onClose,
  onConfirm,
  confirmAction,
  isLoading,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent borderRadius="16px">
        <ModalHeader pb="4px">
          <Text color={ColorDict.primary} fontSize={"20px"}>
            {confirmAction.title}
          </Text>
        </ModalHeader>
        <ModalCloseButton
          bg="rgba(138, 146, 166, 0.12)"
          borderRadius={99}
          fontSize={"6px"}
          mt="8px"
          mr="10px"
        />
        <ModalBody pb="0px">
          <Text>{confirmAction.msg}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            mr={3}
            onClick={onClose}
            isLoading={isLoading}
          >
            Batal
          </Button>
          <Button
            colorScheme={confirmAction.color}
            variant="outline"
            onClick={onConfirm}
            isLoading={isLoading}
          >
            {confirmAction.label}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalConfirm
