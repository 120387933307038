import generateInformasiPOFormItems from "common/form-item-generator/informasi-po"
import {
  calcBiayaPO,
  calcNilaiPoTTLC,
  calcNilaiScOfPo,
} from "common/procedures/informasi-po"
import {
  formatNumber,
  generateCrudUrl,
  genFieldQueryParamStr,
  genFormItems,
  getToday,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"

export enum InformasiPOType {
  TTLC = "TTLC",
  STOCK = "STOCK",
}

function generateInformasiPOForm(tipe: InformasiPOType): BasePageProps<any> {
  return {
    title: "Informasi PO",
    urls: generateCrudUrl("informasi-po"),
    permissionRequired: "InformasiPO",
    filter: <BaseFilter keyName="informasiPo" />,
    formGroupItems: genFormItems(generateInformasiPOFormItems(tipe)),
    cetakActions: [],
    onOpen(detailData, userData, setValue) {
      if (!!detailData?.purchaseOrder) {
        setValue("nilaiSc", calcNilaiScOfPo(detailData?.purchaseOrder))
        setValue("nilaiPo", calcNilaiPoTTLC(detailData?.purchaseOrder))
        setValue("biaya", calcBiayaPO(detailData?.purchaseOrder))
        setValue("purchaseOrder", detailData?.purchaseOrder)
      }
    },
    additionalForms: [
      {
        id: "informasiPayments",
        title: "Payment",
        requiredToAdd: ["PO"],
        disableAdd(data, filterData) {
          return !data.purchaseOrder?.id
        },
        async validateDataCb(formData, globData) {
          const maxPay = +globData.nilaiSc + +globData.biaya
          const informasiPayments = globData.informasiPayments.filter(
            (i: any) => i.xId !== formData.xId
          )
          const currPay =
            +formData.jumlah +
            (informasiPayments.reduce((total: number, item: any) => {
              return total + +item.jumlah
            }, 0) ?? 0)

          if (currPay > maxPay) {
            return `Total pembayaran tidak boleh melebihi ${formatNumber(
              maxPay
            )}`
          }
          return undefined
        },
        formItems: genFormItems([
          {
            id: "tanggalBayar",
            label: "Tanggal Bayar",
            dataViewLabel: "Tanggal",
            type: "date",
            value: getToday(),
          },
          {
            id: "jumlah",
            type: "number",
            label: "Jumlah Bayar",
            isDataView: true,
          },
          {
            id: "jenis",
            type: "radio",
            label: "Jenis Bayar",
            options: [
              {
                label: "Tunai",
                value: "TUNAI",
              },
              {
                label: "Kredit/Hutang",
                value: "HUTANG",
              },
            ],
          },
        ]),
      },
      {
        id: "informasiShipments",
        title: "Shipment",
        requiredToAdd: ["PO"],
        disableAdd(data, filterData) {
          return !data.purchaseOrder?.id
        },
        formItems: genFormItems([
          {
            id: "tanggalShipment",
            label: "Tanggal Shipment",
            dataViewLabel: "Tanggal",
            type: "date",
            value: getToday(),
          },
          {
            id: "itemPurchaseOrderStock.id",
            type: "async-table-select",
            column: 1,
            label: "Barang",
            resourceUrl(data, filterData, detailData) {
              return `item-purchase-order-stock?${genFieldQueryParamStr({
                "purchaseOrderStock.id": detailData.purchaseOrder?.id,
              })}`
            },
            resourceColumnLabelKey: `${
              tipe === InformasiPOType.STOCK
                ? "itemPengadaanStock"
                : "itemPenawaran"
            }.barang.kode`,
            resourceColumn: [
              {
                dataKey: `${
                  tipe === InformasiPOType.STOCK
                    ? "itemPengadaanStock"
                    : "itemPenawaran"
                }.barang.kode`,
                label: "Kode",
              },
              {
                dataKey: `${
                  tipe === InformasiPOType.STOCK
                    ? "itemPengadaanStock"
                    : "itemPenawaran"
                }.barang.nama`,
                label: "Nama Barang",
              },
              {
                dataKey: "qty",
                type: "number",
                label: "Qty",
              },
            ],
            customOnChange: (data, setValue) => {
              setValue("itemPurchaseOrderStock", data)
            },
          },
          {
            id: `itemPurchaseOrderStock.${
              tipe === InformasiPOType.STOCK
                ? "itemPengadaanStock"
                : "itemPenawaran"
            }.barang.kode`,
            label: "Kode",
            isDataView: true,
            type: "ignored",
          },
          {
            id: `itemPurchaseOrderStock.${
              tipe === InformasiPOType.STOCK
                ? "itemPengadaanStock"
                : "itemPenawaran"
            }.barang.nama`,
            label: "Nama Barang",
            isDataView: true,
            type: "ignored",
          },
          {
            id: "itemPurchaseOrderStock.qty",
            label: "Qty",
            isDataView: true,
            type: "number",
            isIgnored: true,
          },
          {
            id: "orderConfirmation",
            label: "Order Confirmation",
          },
          {
            id: "gudang",
            label: "Gudang",
          },
          {
            id: "tanggalInvoice",
            label: "Tanggal Invoice",
            type: "date",
            value: getToday(),
          },
          {
            id: "noInvoice",
            label: "No. Invoice",
          },
          {
            id: "tanggalKeluar",
            label: "Tanggal Keluar",
            type: "date",
            value: getToday(),
          },
          {
            id: "keterangan",
            label: "Keterangan",
            type: "text-area",
          },
        ]),
      },
    ],
  }
}

export default generateInformasiPOForm
