import generatePurchaseOrderFormItems from "common/form-item-generator/purchase-order"
import { GeneralMap, PoTypeEnum } from "common/type"
import {
  generateCrudUrl,
  genFormItems,
  genFieldQueryParamStr,
  strToSafeNum,
  isDivisiBelting,
  genFieldQueryParamObj,
} from "common/util"
import { TableCell } from "components/data-table"
import BaseFilter from "components/filter/_base"
import { BasePageFormGroupItem, BasePageProps } from "components/page/type"
import { ignoredSupplierFilter } from "constant/filters"
import { genBaseSortQueryParamObj } from "constant/url"
import { UseFormReturn } from "react-hook-form"

const PurchaseOrderTitle: GeneralMap<string> = {
  [PoTypeEnum.STOCK]: "Purchase Order Stock",
  [PoTypeEnum.TTLC]: "Purchase Order",
  [PoTypeEnum.SERVICE]: "Purchase Order Service",
  [PoTypeEnum.INDENT]: "Purchase Order Indent",
}

const PermissionModulePOParent: GeneralMap<string> = {
  [PoTypeEnum.STOCK]: "franco_stock",
  [PoTypeEnum.TTLC]: "TT/LC",
  [PoTypeEnum.SERVICE]: "service",
  [PoTypeEnum.INDENT]: "franco_stock",
}

const PermissionModulePOChild: GeneralMap<string> = {
  [PoTypeEnum.STOCK]: "po_stock",
  [PoTypeEnum.TTLC]: "po_stock",
  [PoTypeEnum.SERVICE]: "po_stock",
  [PoTypeEnum.INDENT]: "po_indent",
}

function generatePurchaseOrderForm(poType: PoTypeEnum): BasePageProps<any> {
  let additionalFormItems: BasePageFormGroupItem[] = []

  if (poType === PoTypeEnum.STOCK) {
    additionalFormItems = additionalFormItems.concat([
      {
        id: "itemPengadaanStock.id",
        label: "Barang",
        type: "async-select",
        resourceUrl: (_, filter, data) => {
          return data.pengadaan?.id
            ? `item-pengadaan-stock?${genFieldQueryParamStr({
                "pengadaan.id": data.pengadaan?.id,
              })}`
            : `item-pengadaan-stock`
        },
        resourceMapper: (data) => ({
          label: `${data.barang?.kode} - ${data.barang?.nama}`,
          value: data.id,
        }),
        disabled: true,
        customOnChange: (data, setValue) => {
          setValue("itemPengadaanStock.judul", data.judul)
          setValue("itemPengadaanStock.qty", data.qty)
          setValue("itemPengadaanStock.barang", data.barang)
          setValue("diskon", data.diskon)
          setValue("itemPengadaanStock.calonPembeli", data.calonPembeli)
          setValue("itemPengadaanStock.footer", data.footer)
          setValue("itemPengadaanStock.hargaUnit", data.hargaUnit)
        },
      },
      {
        id: "itemPengadaanStock.barang.nama",
        label: "Barang",
        isDataView: true,
        isIgnored: true,
      },
      {
        id: "itemPengadaanStock.barang.kode",
        label: "Barang",
        disabled: true,
      },
      {
        id: "itemPengadaanStock.qty",
        label: "Quantity",
        type: "number",
        isDataView: true,
        disabled: true,
      },
      {
        id: "itemPengadaanStock.calonPembeli",
        label: "Calon Pembeli",
        disabled: true,
      },
      {
        id: "itemPengadaanStock.footer",
        label: "Footer Barang",
        disabled: true,
      },
      {
        id: "itemPengadaanStock.hargaUnit",
        label: "Unit Price",
        disabled: true,
        isDataView: true,
        type: "number",
      },
      {
        id: "itemPengadaanStock.diskon",
        label: "Diskon",
        type: "number",
        disabled: true,
        isDataView: true,
      },
      {
        id: "itemPengadaanStock.barang.satuanBeli.nama",
        label: "Jenis Harga",
        disabled: true,
      },
      {
        id: "itemPengadaanStock.spesifikasi.panjang",
        label: "Panjang (mm)",
        isOptional: true,
        type: "number",
        disabled: true,
      },
      {
        id: "itemPengadaanStock.spesifikasi.lebar",
        label: "Lebar (mm)",
        isOptional: true,
        type: "number",
        disabled: true,
      },
      {
        id: "itemPengadaanStock.spesifikasi.luas",
        label: "Luas (SQM)",
        type: "number",
        disabled: true,
      },
    ])
  } else if (poType === PoTypeEnum.TTLC || poType === PoTypeEnum.INDENT) {
    additionalFormItems = additionalFormItems.concat([
      {
        id: "itemPenawaran.barang.kode",
        label: "Kode Barang",
        isDataView: true,
      },
      {
        id: "itemPenawaran.barang.nama",
        label: "Nama Barang",
        isDataView: true,
        disabled: true,
      },
      {
        id: "itemPenawaran.qty",
        label: "Kuantitas",
        isDataView: true,
        type: "number",
      },
      {
        id: "itemPenawaran.hargaBeli",
        label: "Amount",
        isDataView: true,
        type: "number",
      },
    ])
  } else if (poType === PoTypeEnum.SERVICE) {
    additionalFormItems = additionalFormItems.concat([
      {
        id: "itemPenawaran.barang.nama",
        label: "Nama Barang",
        isDataView: true,
        disabled: true,
        columnRender(data, filterData, globVars) {
          return (
            <TableCell>
              {!data.itemPenawaran?.id
                ? data.itemService.namaBarang
                : data.itemPenawaran.barang.nama}
            </TableCell>
          )
        },
      },
      {
        id: "itemPenawaran.qty",
        label: "Quantity",
        isDataView: true,
        disabled: true,
        columnRender(data, filterData, globVars) {
          return (
            <TableCell>
              {!data.itemPenawaran?.id
                ? data.itemService.qty
                : data.itemPenawaran.qty}
            </TableCell>
          )
        },
      },
      {
        id: "itemPenawaran.hargaJual",
        label: "Ongkos",
        isDataView: true,
        type: "number",
        disabled: true,
        columnRender(data, filterData, globVars) {
          return (
            <TableCell>
              {!data.itemPenawaran?.id
                ? data.itemService.ongkos
                : data.itemPenawaran.hargaJual}
            </TableCell>
          )
        },
      },
    ])
  }

  return {
    title: PurchaseOrderTitle[poType],
    permissionRequired: `SIT_sekawan::transaksi::${PermissionModulePOParent[poType]}::purchasing::${PermissionModulePOChild[poType]}`,
    requiredToAdd:
      poType === PoTypeEnum.TTLC ? ["divisi"] : ["divisi", "supplier"],
    disableAdd:
      poType === PoTypeEnum.TTLC
        ? (data, filter) => {
            return !filter?.["salesContract.penawaran.divisi.id"]
          }
        : [PoTypeEnum.INDENT, PoTypeEnum.SERVICE].includes(poType)
        ? (data, filter) => {
            return (
              !filter?.["salesContract.penawaran.divisi.id"] ||
              !filter?.["supplier.id"]
            )
          }
        : (data, filter) => {
            return !filter?.["pengadaan.divisi.id"] || !filter?.["supplier.id"]
          },
    customFormDataMapper: (data) => {
      return {
        ...data,
        itemPurchaseOrderStocks:
          data?.itemPurchaseOrderStocks?.map((i: any) => ({
            ...i,
            qty: i[
              poType === PoTypeEnum.STOCK
                ? "itemPengadaanStock"
                : poType === PoTypeEnum.SERVICE
                ? "itemService"
                : "itemPenawaran"
            ]?.qty,
          })) ?? [],
      }
    },
    urls: {
      ...generateCrudUrl("purchase-order"),
      read: {
        endpoint: `/purchase-order`,
        method: "get",
        param: {
          ...genFieldQueryParamObj({
            [poType === PoTypeEnum.STOCK ? "poType_$In" : "poType"]:
              poType === PoTypeEnum.STOCK ? "STOCK;SAMPLE" : poType,
            ...(poType === PoTypeEnum.STOCK ? { pengadaan: "notNull" } : {}),
          }),
          ...genBaseSortQueryParamObj("noPo", true),
        },
      },
    },
    ignoreFilterOnFetch: ignoredSupplierFilter,
    filter: (
      <BaseFilter
        keyName={
          poType === PoTypeEnum.TTLC
            ? "poTtlc"
            : [PoTypeEnum.INDENT, PoTypeEnum.SERVICE].includes(poType)
            ? "poIndent"
            : "supplierDivisi"
        }
      />
    ),
    alertMessage:
      poType === PoTypeEnum.STOCK
        ? (data) => {
            const timeDiff =
              new Date().getTime() - new Date(data.tanggal).getTime()

            return timeDiff > 2 * 30 * 24 * 60 * 60 * 1000
              ? "Purchase order stock sudah melebihi 2 bulan"
              : undefined
          }
        : undefined,
    generateCodeParam: (data) => {
      const entityData: any = { poType }
      if (poType === PoTypeEnum.STOCK) {
        if (!data.pengadaan?.tipePps) return undefined
        entityData.pengadaan = {
          tipePps: data.pengadaan?.tipePps,
        }
      }
      return {
        entityName: "PurchaseOrder",
        entity: entityData,
      }
    },
    formGroupItems: genFormItems(generatePurchaseOrderFormItems(poType)),
    responseMapper(data) {
      return {
        ...data,
        scRef: data.salesContract?.noSalesContract,
        itemPurchaseOrderStocks: data.itemPurchaseOrderStocks?.map(
          (i: any) => ({
            ...i,
            barang: i.itemPengadaanStock?.barang,
          })
        ),
      }
    },

    additionalForms: [
      {
        id: "itemPurchaseOrderStocks",
        title: "Barang",
        hideAdd:
          poType === PoTypeEnum.TTLC ||
          poType === PoTypeEnum.SERVICE ||
          poType === PoTypeEnum.INDENT ||
          poType === PoTypeEnum.STOCK,
        formItems: genFormItems(additionalFormItems),
      },
    ],
  }
}

export default generatePurchaseOrderForm
