export function getTotalItemKasKecilPPN(items: any[]): number {
  if (!items) return 0
  return items.reduce(
    (total, item) => total + (item.isPpn ? +item.jumlah : 0),
    0
  )
}

export function getTotalItemKasKecilNonPPN(items: any[]): number {
  if (!items) return 0
  return items.reduce(
    (total, item) => total + (!item.isPpn ? +item.jumlah : 0),
    0
  )
}
