import { DataTableColumn } from "components/data-table/type"
import {
  BulkPageProps,
  BulkPageTableColumn,
  InformasiPageTableColumn,
} from "components/page/type"
import { transferGlBodyRequestMapper } from "constant/mapper"

const titleMap: { [key: string]: string } = {
  PEMBELIAN: "Pembelian",
  BIAYA_IMPORT: "Biaya Import & Kasbon Biaya Import",
  PEMBAYARAN_HUTANG: "Pembayaran Hutang",
  PENJUALAN: "Penjualan",
  PELUNASAN: "Pelunasan",
  RETUR_PENJUALAN: "Retur",
  KASBON: "Kasbon dan Biaya Penjualan",
}

const endpointMap: { [key: string]: string } = {
  PEMBELIAN: "pembelian",
  BIAYA_IMPORT: "biaya-import",
  PEMBAYARAN_HUTANG: "pembayaran-hutang",
  PENJUALAN: "penjualan",
  PELUNASAN: "pelunasan",
  RETUR_PENJUALAN: "retur",
  KASBON: "kasbon",
}

const filterName: { [key: string]: string } = {
  PENJUALAN: "transferPenjualan",
  PELUNASAN: "transferPenjualan",
  RETUR_PENJUALAN: "transferPenjualan",
  KASBON: "transferPenjualan",
}

export function genTransferGLForm(
  tipe: string,
  columns: BulkPageTableColumn[]
): BulkPageProps {
  return {
    title: `Transfer ${titleMap[tipe]}`,
    actionLabel: "...",
    permissionRequired: "TransferPembelian",
    bulkReqKey: "data",
    filterName: filterName[tipe] ?? "transferGlPeriod",
    disablePagination: true,
    bulkRequestDataMapper: transferGlBodyRequestMapper(tipe, false),
    isHighlightRowCb: (data) => {
      return data.isTransfer === 1
    },
    bulkActionCb: (data: any, toast: any) => {
      toast({
        title: !!data?.errors?.length
          ? "Berhasil (Dengan Catatan)"
          : "Berhasil",
        description: !!data?.errors?.length
          ? `Beberapa jurnal tidak diproses dengan alasan: \n${data?.errors?.join(
              ", "
            )}`
          : "Data berhasil diubah",
        status: !!data?.errors?.length ? "warning" : "success",
        duration: 8000,
        isClosable: true,
        position: "top",
      })
    },
    actions: [
      {
        color: "red",
        label: "UnTransfer dari GL",
        action: transferGlBodyRequestMapper(tipe, false),
      },
      {
        color: "blue",
        label: "Transfer ke GL",
        action: transferGlBodyRequestMapper(tipe, true),
      },
    ],
    urls: {
      read: {
        endpoint: `/jurnal/transfer/${endpointMap[tipe]}`,
        method: "get",
      },
      update: {
        endpoint: "/jurnal/transfer-gl/bulk-update",
        method: "post",
      },
    },
    formItems: columns,
  }
}
