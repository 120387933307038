import { PenawaranTypeEnum, SpesificModuleFormItem } from "common/type"
import { genFieldQueryParamStr, strToSafeNum } from "common/util"
import { BasePageFormGroupItem } from "components/page/type"
import {
  supplierOptionMapper,
  supplierResourceColumnKey,
  supplierSearchKey,
} from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"

function generateLampiranKontrakFormItems(
  tipe: PenawaranTypeEnum
): BasePageFormGroupItem[] {
  const items: SpesificModuleFormItem<PenawaranTypeEnum>[] = [
    {
      id: "salesContract.id",
      type: "async-table-select",
      resourceUrl: (data, filterData) => {
        const divisiId = filterData["salesContract.penawaran.divisi.id"]
        const q: any = {
          "penawaran.tipe": tipe,
          "lampiranKontraks.id": "null",
        }
        if (!!divisiId) {
          q["penawaran.divisi.id"] = divisiId
        }
        const organisasiId = filterData["salesContract.penawaran.organisasi.id"]
        if (!!organisasiId) {
          q["penawaran.organisasi.id"] = organisasiId
        }
        return `sales-contract?${genFieldQueryParamStr(q)}`
      },
      column: 1,
      resourceColumn: [
        {
          dataKey: "noSalesContract",
          label: "Nomor Sales Contract",
        },
        {
          dataKey: "penawaran.no",
          label: "Nomor Penawaran",
        },
        {
          dataKey: "penawaran.tipe",
          label: "Jenis Penawaran",
        },
        {
          dataKey: "penawaran.pelanggan.namaPanjang",
          label: "Nama Customer",
        },
      ],
      customOnChange: (data, setValue, getValue) => {
        setValue("salesContract", data)
        setValue("packing", data?.penawaran?.packing ?? 0)
        setValue("freight", data?.penawaran?.freight ?? 0)
        if (!getValue("diskon")) {
          setValue("diskon", data?.penawaran?.diskonSupplier ?? 0)
        }
        if (!getValue("komisi")) {
          setValue("komisi", data?.penawaran?.komisi ?? 0)
        }
        setValue("bankCharge", data?.penawaran?.bankCharge ?? 0)
        const insurnacePercentage = data?.penawaran?.insurance ?? 0
        setValue(
          "insurance",
          (insurnacePercentage / 100) *
            data?.penawaran?.itemPenawarans?.reduce(
              (total: number, item: any) =>
                total + strToSafeNum(item.hargaJual),
              0
            )
        )
        if (data?.penawaran?.tipe === "INDENT") {
          setValue("alamatPenerima", "PT. SEKAWAN")
        } else {
          if (!data?.penawaran?.isDeliverToPabrik) {
            setValue("alamatPenerima", data?.penawaran?.pelanggan?.alamatKantor)
          } else {
            setValue("alamatPenerima", data?.penawaran?.pelanggan?.alamatPabrik)
          }
        }
      },
      label: "Nomor Penawaran",
      dataViewKey: "salesContract.penawaran.no",
      resourceColumnLabelKey: "penawaran.no",
      isDataView: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.TTLC,
      ],
    },
    {
      id: "salesContract.penawaran.pelanggan.namaPanjang",
      disabled: true,
      label: "Customer",
      isDataView: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.TTLC,
      ],
    },
    {
      id: "salesContract.noSalesContract",
      label: "Nomor Sales Kontrak",
      disabled: true,
      isDataView: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.TTLC,
      ],
      suffix: {
        id: "salesContract.tanggal",
        type: "date",
        isHalfSize: true,
        disabled: true,
        label: "Tanggal SC/PO",
      },
    },

    {
      id: "salesContract.penawaran.sales.nama",
      disabled: true,
      label: "Sales",
      enabledAt: [PenawaranTypeEnum.INDENT],
      suffix: {
        id: "salesContract.penawaran.packing",
        disabled: true,
        label: "Packing",
        type: "number",
        isHalfSize: true,
      },
    },
    {
      id: "salesContract.penawaran.freight",
      disabled: true,
      label: "Freight",
      type: "number",
      enabledAt: [PenawaranTypeEnum.INDENT],
      suffix: {
        id: "salesContract.penawaran.diskonSupplier",
        disabled: true,
        label: "Diskon Supplier (%)",
        type: "number",
        isHalfSize: true,
      },
    },
    {
      id: "salesContract.penawaran.bankCharge",
      disabled: true,
      label: "Bank Charge",
      type: "number",
      enabledAt: [PenawaranTypeEnum.INDENT],
      suffix: {
        id: "salesContract.penawaran.insurance",
        disabled: true,
        label: "Insurance",
        type: "number",
        isHalfSize: true,
      },
    },
    {
      id: "jenisPengiriman",
      label: "Jenis Pengiriman",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.TTLC,
      ],
    },
    {
      id: "batasPenyerahan",
      label: "Batas Penyerahan",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.TTLC,
      ],
    },
    {
      id: "supplier.id",
      label: "Supplier",
      type: "async-select",
      resourceColumnLabelKey: supplierResourceColumnKey,
      resourceUrl: (data, filter) => {
        let url = `supplier?${genBaseSortQueryParam("kodeSupplier")}`
        const q: any = {}
        if (data.salesContract?.penawaran?.tipeIndent === "LOKAL") {
          q["isPkp"] = !!data.salesContract?.penawaran?.isPkp
          q["is_import_luar_negeri"] = false
        } else if (data.salesContract?.penawaran?.tipeIndent === "LOKAL") {
          q["is_import_luar_negeri"] = true
        }
        url += `&${genFieldQueryParamStr(q)}`

        return url
      },
      resourceMapper: supplierOptionMapper,
      searchBy: supplierSearchKey,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.TTLC,
      ],
    },
    {
      id: "noTanggalOffer",
      label: "Nomor / Tanggal Offer",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.TTLC,
      ],
    },
    {
      id: "shipmentMaker",
      label: "Shipment/Maker",
      suffix: {
        type: "blank",
        id: "blank",
        isOptional: true,
        isHalfSize: true,
      },
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.TTLC,
      ],
    },
    {
      type: "blank",
      id: "blank",
      enabledAt: [PenawaranTypeEnum.TTLC],
    },
    {
      id: "keterangan",
      label: "Keterangan",
      type: "text-area",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.TTLC,
      ],
    },
    {
      id: "alamatPenerima",
      label:
        PenawaranTypeEnum.TTLC === tipe
          ? "Alamat Consignee"
          : "Alamat Pengiriman",
      type: "text-area",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.TTLC,
      ],
    },
    {
      id: "komisi",
      label: "Komisi (%)",
      value: 0,
      type: tipe === PenawaranTypeEnum.STOCK ? "hidden" : "number",
      enabledAt: [PenawaranTypeEnum.TTLC],
      suffix: {
        id: "salesContract.penawaran.diskonSupplier",
        disabled: true,
        label: "Diskon Supplier (%)",
        isHalfSize: true,
        type: "number",
      },
    },
    {
      id: "operational",
      label: "Operasional (%)",
      type: "number",
      value: 0,
      suffix: {
        type: "blank",
        id: "blank",
        isOptional: true,
        isHalfSize: true,
      },
      enabledAt: [PenawaranTypeEnum.TTLC],
    },
  ]

  return items
    .filter((i: SpesificModuleFormItem<PenawaranTypeEnum>) =>
      i.enabledAt.includes(tipe)
    )
    .map(({ enabledAt, ...data }) => data)
}

export default generateLampiranKontrakFormItems
