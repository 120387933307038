import { getSQM } from "common/procedures/speisfikasi"
import { formatNumber, genFieldQueryParamStr } from "common/util"
import { TableCell } from "components/data-table"
import { BasePageFormGroupItem } from "components/page/type"

function generateItemWorkOrderFormItem(
  tipe: "STOCK" | "BARANG_KHUSUS"
): BasePageFormGroupItem[] {
  return [
    {
      id: "itemPenawaran.id",
      label: "Barang",
      dataViewLabel: "Kode Barang",
      column: 1,
      type: "async-table-select",
      resourceUrl: (data, filter, detail) => {
        return `item-penawaran?${genFieldQueryParamStr({
          [tipe === "STOCK" ? "penawaran.id" : "itemBarangJadi.id"]:
            tipe === "STOCK"
              ? detail.sfb?.salesContract?.penawaran?.id
              : detail.itemBarangJadi?.id,
          "itemPenawaran.sisaWo_$gt": 0.0001,
        })}`
      },
      resourceColumnLabelKey: "barang.nama",
      dataViewKey: "itemPenawaran.barang.kode",
      resourceColumn: [
        {
          dataKey: "barang.kode",
          label: "Kode Barang",
        },
        {
          dataKey: "barang.nama",
          label: "Nama Barang",
        },
        {
          dataKey: "spesifikasi.panjang",
          label: "Panjang",
          type: "number",
        },
        {
          dataKey: "spesifikasi.lebar",
          label: "Lebar",
          type: "number",
        },
        {
          dataKey: "sisaWo",
          label: "Qty",
          type: "number",
        },
        {
          dataKey: "keterangan",
          label: "Keterangan",
        },
      ],
      isDataView: true,
      customOnChange: (data, setValue, getValue) => {
        setValue("itemPenawaran", data)
        if (!getValue("qty")) {
          setValue("qty", data.sisaWo)
        }
        setValue(
          "sqm",
          getSQM(data.spesifikasi?.panjang, data.spesifikasi?.lebar)
        )
        // TODO: set kode barang khusus for penawaran khusus case
        setValue("kodeBarangHabasit", data.barang?.kode)
      },
    },
    {
      id: "itemBarangMasuk.id",
      label: "No. Masuk Barang",
      type: "async-table-select",
      resourceColumnLabelKey: "no",
      customOnChange: (data, setValue) => {
        setValue("itemBarangMasuk", data)
      },
      disabled: (data) => !data.itemPenawaran?.barang?.id,
      column: 1,
      resourceUrl: (data, filter, glob) => {
        if (!data.itemPenawaran?.barang?.id) return "item-bapb"
        return `item-barang-masuk?${genFieldQueryParamStr({
          "itemBapb.barang.id": data.itemPenawaran?.barang?.id,
          sisaSqmAvailable_$gt: +data.sqm - 0.00001,
          "organisasi.id": filter?.["sfb.workshop.id"],
        })}`
      },
      resourceColumn: [
        {
          dataKey: "no",
          label: "No. Masuk Barang",
        },
        {
          dataKey: "itemBapb.barang.nama",
          label: "Nama Barang",
        },
        {
          dataKey: "panjang",
          label: "Panjang",
          type: "number",
        },
        {
          dataKey: "lebar",
          label: "Lebar",
          type: "number",
        },
        {
          dataKey: "sisaSqmAvailable",
          label: "Sisa SQM",
        },
      ],
    },
    {
      id: "itemPenawaran.barang.nama",
      label: "Nama Barang",
      type: "ignored",
      isDataView: true,
    },
    {
      id: "itemBarangMasuk.panjang",
      type: "number",
      label: "Panjang (Stock)",
      disabled: true,
      suffix: {
        id: "itemBarangMasuk.lebar",
        type: "number",
        label: "Lebar (Stock)",
        disabled: true,
      },
    },
    {
      id: "itemBarangMasuk.sisaSqmAvailable",
      label: "Luas (Stock)",
      type: "number",
      disabled: true,
      suffix: {
        isHalfSize: true,
        id: "itemBarangMasuk.itemBapb.noManufaktur",
        label: "Nomor Manufacture",
        disabled: true,
      },
    },
    {
      id: "itemPenawaran.spesifikasi.panjang",
      type: "number",
      label: "Panjang (SPB)",
      disabled: true,
      suffix: {
        id: "itemPenawaran.spesifikasi.lebar",
        type: "number",
        label: "Lebar (SPB)",
        disabled: true,
      },
    },
    {
      id: "sqm",
      type: "number",
      label: "Luas (SPB)",
      disabled: true,
      suffix: {
        id: "qty",
        label: "Jumlah/Kuantitas (SPB)",
        type: "number",
        disabled: true,
        isHalfSize: true,
      },
    },

    {
      id: "itemPenawaran.barang.kode",
      label: "Kode Barang",
      disabled: true,
      suffix: {
        id: "kodeBarangHabasit",
        label: "Kode Barang Habasit",
        disabled: true,
        isHalfSize: true,
      },
    },
    {
      id: "jumlahAvailable",
      label: "Available",
      type: "number",
      dataViewLabel: "Qty",
      disabled: (data) => !!data.itemBarangMasuk?.isWaste,
      value: 0,
      columnRender(data, filterData, globVars) {
        return (
          <TableCell>
            {formatNumber(+data.jumlahAvailable + +data.jumlahWaste)}
          </TableCell>
        )
      },
      formRule(data, detail) {
        var maxValue = +data.qty
        return {
          max: {
            value: maxValue,
            message: `Tidak boleh lebih dari ${formatNumber(maxValue)}`,
          },
        }
      },
      suffix: {
        id: "jumlahWaste",
        isHalfSize: true,
        label: "Waste",
        disabled: (data) => !data.itemBarangMasuk?.isWaste,
        type: "number",
        value: 0,
      },
    },
    {
      id: "kordinatSpesifikasiBarangs",
      label: "Posisi",
      type: "area-editor",
      disabled: (data) => {
        return (
          !data.itemPenawaran?.spesifikasi?.panjang ||
          !data.itemPenawaran?.spesifikasi?.lebar
        )
      },
      itemWorkOrderEditorProps: (data) => {
        return {
          spaceHeight: data.itemPenawaran?.spesifikasi?.panjang,
          spaceWidth: data.itemPenawaran?.spesifikasi?.lebar,
          availableQty: data.itemBapb?.qtyAktual
            ? parseFloat(data.itemBapb?.qtyAktual)
            : undefined,
          fabricatedQty: data.jumlahAvailable
            ? parseFloat(data.jumlahAvailable)
            : undefined,
          itemBarangMasukId: data.itemBarangMasuk?.id,
        }
      },
    },
  ]
}

export default generateItemWorkOrderFormItem
