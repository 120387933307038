export const LOCALE_MONTHS: string[] = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
]

export const CIKARANG_ORG_ID = "12"

// TODO: set for production also
export const DEFAULT_PELANGGANG_PERKIRAAN = "504"
