import { PenawaranTypeEnum } from "common/type"

export function getInvoicePaidDate(invoice: any): Date {
  let d = new Date()
  d.setTime(0)
  if (invoice.penawaran.tipe === PenawaranTypeEnum.TTLC) {
    d = new Date(invoice.tanggal)
  } else {
    for (const item of invoice.pelunasanPiutangs) {
      const currDate = new Date(item.tanggal)
      if (currDate.getTime() > d.getTime()) {
        d = currDate
      }
    }
  }

  return d
}

export function getInvoiceCreatedDate(invoice: any): Date {
  if (invoice.penawaran.tipe === PenawaranTypeEnum.TTLC) {
    return new Date(invoice.tanggalInvoice)
  } else {
    return new Date(invoice.kontraBons[0].tanggal)
  }
}
