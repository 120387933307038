import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  SimpleGrid,
  Text,
} from "@chakra-ui/react"
import { GeneralMap } from "common/type"
import { getDataByKey } from "common/util"
import FormError from "components/error/form-error"
import { useBasePageDataTable, useBasePage } from "components/page/common"
import { FormModeType } from "components/page/type"
import { ColorDict } from "constant/theme"
import React, { useCallback, useEffect, useMemo } from "react"
import { Controller, UseFormReturn } from "react-hook-form"
import { useCreateGeneratedCode } from "services/generated-code.service"
import { isRequired } from "./common"
import FormField from "./form-field"
import { FormGroupItem, FormGroupItemAssociated, FormGroupProps } from "./type"
import { useStateLogger } from "hooks/logger"
import { useScreenDetector } from "hooks/device"
import { useAuth } from "context/auth.context"

interface FormGroupGridItemProps {
  item: FormGroupItem
  data: any
  formMode: FormModeType
  globVars: Map<string, string>
  errors: any
  formHook: UseFormReturn
  recalculateCallback?: (rhf: UseFormReturn<any>) => void
  doGenerateEntityCode: () => void
}

const FormGroupGridItem: React.FC<FormGroupGridItemProps> = ({
  item,
  data,
  formMode,
  globVars,
  errors,
  formHook,
  recalculateCallback,
  doGenerateEntityCode,
}) => {
  const { detail, filterData } = useBasePage()

  const isItemRequired = useMemo(
    () => isRequired(item, data, formMode, globVars),
    [item, data, formMode, globVars]
  )

  const isSuffixRequired = useMemo(
    () => !!item.suffix && isRequired(item.suffix, data, formMode, globVars),
    [item, data, formMode, globVars]
  )

  const getLabel = useCallback(
    (item: FormGroupItem) => {
      return typeof item.label === "function"
        ? item.label(data, filterData)
        : item.label
    },
    [data, filterData]
  )

  const description = useMemo(
    () =>
      typeof item.description === "function"
        ? item.description(data)
        : item.description,
    [data, item]
  )

  const suffixDescription = useMemo(
    () =>
      typeof item.suffix?.description === "function"
        ? item.suffix?.description(data)
        : item.suffix?.description,
    [data, item.suffix]
  )

  const isRendered = useMemo(
    () =>
      !item.renderCondition ||
      item.renderCondition?.(data, formMode, filterData),
    [data, item, formMode, filterData]
  )

  return (
    <>
      {isRendered && (
        // @ts-ignore
        <Flex width="100%">
          {item.type !== "blank" &&
            (item.type === "title" ? (
              <Text fontSize="20px" fontWeight={800} color={ColorDict.primary}>
                {item.label}
              </Text>
            ) : (
              <FormControl
                isRequired={isItemRequired}
                isInvalid={!!getDataByKey(errors, item.id)?.type}
              >
                <FormLabel
                  fontSize={item.strong ? "15px" : "13px"}
                  fontWeight={item.strong ? "bold" : "normal"}
                >
                  {getLabel(item)}
                </FormLabel>
                {description && (
                  <Text color="orange" fontSize="11px" mt="-4px">
                    {description}
                  </Text>
                )}
                <Controller
                  control={formHook.control}
                  name={item.id}
                  rules={{
                    required: isItemRequired,
                    ...((typeof item.formRule === "function"
                      ? item.formRule(data, detail)
                      : item.formRule) ?? {}),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormField
                      id={item.id}
                      watchData={data}
                      type={item.type}
                      value={value ?? ""}
                      inputPrefix={item.inputPrefix}
                      onChange={(val) => {
                        onChange(val)
                        // spesificOnChangeHandler?.(item.id)
                        if (item.isCallRecalculateCb) {
                          recalculateCallback?.(formHook)
                        }
                      }}
                      options={item.options}
                      label={getLabel(item)}
                      sourceValueKey={item.sourceValueKey}
                      resourceUrl={item.resourceUrl}
                      resourceMapper={item.resourceMapper}
                      dependOn={item.dependOn}
                      resourceColumn={item.resourceColumn}
                      resourceColumnLabelKey={item.resourceColumnLabelKey}
                      disabled={item.disabled}
                      strong={item.strong}
                      formRhf={formHook}
                      dataViewKey={item.dataViewKey}
                      customOnChange={item.customOnChange}
                      itemWorkOrderEditorProps={item.itemWorkOrderEditorProps}
                      generateCodeCb={doGenerateEntityCode}
                      baseResources={item.baseResources}
                      searchBy={item.searchBy}
                    />
                  )}
                />
                <FormError
                  error={getDataByKey(errors, item.id)}
                  fieldName={getLabel(item)}
                />
              </FormControl>
            ))}
          {!!item.suffix && (
            <Box
              ml="8px"
              width={!!item.suffix.isHalfSize ? "100%" : "fit-content"}
            >
              <FormControl
                isRequired={isSuffixRequired}
                isInvalid={!!errors[item.suffix?.id]?.type}
              >
                <FormLabel
                  fontSize={item.suffix?.strong ? "15px" : "13px"}
                  fontWeight={item.suffix?.strong ? "bold" : "normal"}
                  whiteSpace="nowrap"
                >
                  {!!item.suffix.label ? getLabel(item.suffix) : <>&nbsp;</>}
                </FormLabel>
                {suffixDescription === " " && <Box height="16px" />}
                {suffixDescription && (
                  <FormLabel color="orange" fontSize="11px" mt="-4px">
                    {suffixDescription}
                  </FormLabel>
                )}
                <Controller
                  control={formHook.control}
                  name={item.suffix?.id}
                  rules={{
                    required: isSuffixRequired,
                    ...((typeof item.suffix.formRule === "function"
                      ? item.suffix.formRule(data, detail)
                      : item.suffix.formRule) ?? {}),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormField
                      {...item.suffix}
                      label={!item.suffix ? "" : getLabel(item.suffix)}
                      watchData={data}
                      value={value ?? ""}
                      id={item.suffix?.id ?? ""}
                      onChange={(val) => {
                        onChange(val)
                        if (item.suffix?.isCallRecalculateCb) {
                          recalculateCallback?.(formHook)
                        }
                      }}
                      formRhf={formHook}
                      generateCodeCb={doGenerateEntityCode}
                    />
                  )}
                />
                <FormError
                  error={getDataByKey(errors, item.suffix.id)}
                  fieldName={getLabel(item.suffix)}
                />
              </FormControl>
            </Box>
          )}
        </Flex>
      )}
    </>
  )
}
const FormGroup: React.FC<FormGroupProps> = ({
  items,
  formHook,
  recalculateCallback,
  generateCodeParam,
}) => {
  const { isMobile } = useScreenDetector()
  const {
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = formHook

  const { doFetch: createGenerateCode } = useCreateGeneratedCode()
  const { formMode } = useBasePageDataTable()
  const { globVars, filterData } = useBasePage()

  useEffect(() => {
    for (const item of items) {
      if (item.type === "button") continue
      if (typeof item.value !== "undefined") {
        setValue(item.id, item.value)
      } else setValue(item.id, null)

      if (item.suffix) {
        if (item.suffix.type === "check" && item.suffix.value === null) {
          setValue(item.suffix.id, false)
        } else {
          if (typeof item.suffix.value !== "undefined") {
            setValue(item.suffix.id, item.suffix.value)
          } else {
            setValue(item.suffix.id, null)
          }
        }
      }
    }
  }, [setValue, items])

  const data = watch()

  const { profile } = useAuth()

  useStateLogger(profile, "profile")

  const gridItems = useMemo<FormGroupItemAssociated[]>(() => {
    console.log({ items })
    const dict: GeneralMap<FormGroupItem[]> = {}
    for (const i of items.filter(
      (i) =>
        i.type !== "ignored" &&
        !(typeof i.isIgnored == "function"
          ? i.isIgnored(formMode)
          : i.isIgnored)
    )) {
      const type = typeof i.type === "function" ? i.type(data, profile) : i.type
      if (type !== "hidden") {
        const column = i.column ?? 2
        if (!dict[column]) {
          dict[column] = []
        }
        dict[column].push(i)
      }
    }

    const result: FormGroupItemAssociated[] = []
    for (const i in dict) {
      result.push({
        column: parseInt(i),
        items: dict[i],
      })
    }

    return result
  }, [items, formMode, data])

  const doGenerateEntityCode = useCallback(() => {
    if (formMode !== "create") {
      return
    }
    const _generateCodeParam =
      typeof generateCodeParam === "function"
        ? generateCodeParam(getValues(), filterData)
        : generateCodeParam
    if (_generateCodeParam) {
      createGenerateCode(_generateCodeParam).then((res) => {
        const codeField = items.find((i) => i.isGeneratedCodeField)
        if (codeField) {
          setValue(codeField.id, res?.data?.code)
        }
      })
    }
  }, [
    formMode,
    generateCodeParam,
    getValues,
    createGenerateCode,
    items,
    setValue,
    filterData,
  ])

  useEffect(() => {
    doGenerateEntityCode()
  }, [doGenerateEntityCode])

  return (
    <form>
      {gridItems.map((gridItem, key) => (
        <>
          {/* @ts-ignore */}
          <SimpleGrid
            columns={isMobile ? 1 : gridItem.column}
            spacingY={3}
            spacingX={6}
            key={key}
          >
            {gridItem.items.map((item, keyItem) => (
              <FormGroupGridItem
                key={keyItem}
                formHook={formHook}
                formMode={formMode}
                data={watch()}
                doGenerateEntityCode={doGenerateEntityCode}
                errors={errors}
                globVars={globVars}
                item={item}
                recalculateCallback={recalculateCallback}
              />
            ))}
          </SimpleGrid>
          {key < gridItems.length - 1 && <Box h="12px" />}
        </>
      ))}
    </form>
  )
}

export default FormGroup
