import { strToSafeNum } from "common/util"
import { getAdditionalCostPenawaran } from "./penawaran"
import { totalmem } from "os"

// TODO: sync this with be
export function calculcateSuratJalanCustomerTotalHargaJual(
  data: any,
  ppnPercentage: number
): number {
  let totalItem = getBaseTagihanSj(
    data,
    data.salesContract?.penawaran?.diskon ?? 0
  )

  totalItem +=
    getPpnPercentageByPenawaran(data.salesContract?.penawaran, ppnPercentage) *
    totalItem

  totalItem += getAdditionalCostPenawaran(
    data.salesContract?.penawaran,
    totalItem
  )

  return totalItem
}

export function getBaseTagihanSj(sj: any, diskon: number): number {
  let totalItem =
    sj?.itemSuratJalanCustomers?.reduce((total: number, item: any) => {
      return (
        total + strToSafeNum(item.jumlahAvailable) * strToSafeNum(item.hargaRp)
      )
    }, 0) ?? 0
  if (diskon > 0) {
    totalItem -= (+diskon / 100) * totalItem
  }

  return totalItem
}

export function getPpnPercentageByPenawaran(
  penawaran: any,
  ppnPercentage: number
): number {
  if (!!penawaran?.isPpn) {
    if (
      (penawaran?.tipe !== "SERVICE" &&
        penawaran?.pelanggan?.barang === "A2") ||
      (penawaran?.tipe === "SERVICE" && penawaran?.pelanggan?.jasa === "A2")
    ) {
    } else {
      return ppnPercentage / 100
    }
  }

  return 0
}
