import { getSQM } from "common/procedures/speisfikasi"
import { SpesificModuleFormItem, SuratJalanInternalTypeEnum } from "common/type"
import { formatNumber, genFieldQueryParamStr, strToSafeNum } from "common/util"
import { TableCell } from "components/data-table"
import { FormGroupItem } from "components/form/type"
import { BasePageFormGroupItem } from "components/page/type"

function getItemBapbKonversi(itemBapb: any): number {
  const totalqty = parseFloat(itemBapb?.qtyAktual) + parseFloat(itemBapb?.foc)
  return parseFloat(itemBapb?.jumlahKonversi) / totalqty
}

function genQtyFisikForm(tipe: SuratJalanInternalTypeEnum): FormGroupItem {
  return {
    id: "qtyFisik",
    label:
      tipe === SuratJalanInternalTypeEnum.SPPB
        ? "Jml/Qty Kirim"
        : "Jumlah/Quantity",
    value: 0,
    formRule: (data, detail) => {
      const existingQty = detail?.qtyFisik ?? 0
      const max =
        +(tipe === SuratJalanInternalTypeEnum.SPPB
          ? data.itemPermintaanPengirimanBarang?.qty
          : parseFloat(data.itemBapb?.sisaBarang) /
            getItemBapbKonversi(data.itemBapb)) + +existingQty
      return {
        max: {
          value: max,
          message: `Quantity tidak boleh lebih dari ${formatNumber(max)}`,
        },
      }
    },
    customOnChange: (data, setValue, getValue) => {
      const konversi =
        tipe === SuratJalanInternalTypeEnum.SPPB
          ? getSQM(
              getValue("itemPermintaanPengirimanBarang.spesifikasi.panjang"),
              getValue("itemPermintaanPengirimanBarang.spesifikasi.lebar")
            )
          : getItemBapbKonversi(getValue("itemBapb"))
      setValue("qty", konversi * data)
    },
    type: "number",
  }
}

function generateItemSJIFormItems(
  tipe: SuratJalanInternalTypeEnum
): BasePageFormGroupItem[] {
  const items: SpesificModuleFormItem<SuratJalanInternalTypeEnum>[] = [
    {
      id: "itemPermintaanPengirimanBarang.id",
      label: "Surat Permintaan Pengiriman Barang",
      type: "async-table-select",
      resourceUrl(data, filterData, detailData) {
        return `item-permintaan-pengiriman-barang?${genFieldQueryParamStr({
          "permintaan.id": detailData.permintaanPengirimanBarang?.id,
          sisaPengirimanSJI_$gt: 0.0001,
        })}`
      },
      customOnChange: (data, setValue) => {
        setValue("itemPermintaanPengirimanBarang", data)
        setValue("keterangan", data.keterangan)
      },
      resourceColumnLabelKey: "permintaan.no",
      dataViewLabel: "No. SPPB",
      dataViewKey: "itemPermintaanPengirimanBarang.permintaan.no",
      column: 1,
      resourceColumn: [
        {
          dataKey: "barang.kode",
          label: "Kode Brg",
        },
        {
          dataKey: "barang.nama",
          label: "Nama Barang",
        },
        {
          label: "L (mm)",
          dataKey: "spesifikasi.panjang",
          type: "number",
        },
        {
          label: "W (mm)",
          dataKey: "spesifikasi.lebar",
          type: "number",
        },
        {
          dataKey: "sisaPengirimanSJI",
          label: "Qty",
        },
        {
          dataKey: "keterangan",
          label: "keterangan",
        },
      ],
      enabledAt: [SuratJalanInternalTypeEnum.SPPB],
    },
    // {
    //   id: "itemBarangMasukSjis",
    //   label: "Item Barang Masuk",
    //   resourceUrl: (data, filterData) => {
    //     const orgId = filterData?.["asal.id"]
    //     const barangId = data.itemPermintaanPengirimanBarang?.barang?.id
    //     if (!orgId || !barangId) {
    //       return ""
    //     }
    //     return `item-barang-masuk?${genFieldQueryParamStr({
    //       "organisasi.id": orgId,
    //       "itemBapb.barang.id": barangId,
    //     })}`
    //   },
    //   resourceMapper: (data) => ({
    //     label: data.no,
    //     value: data.id,
    //   }),
    //   type: "select-multiple-item",
    //   column: 1,
    //   customOnChange: (data, setValue) => {
    //     setValue(
    //       "itemBarangMasukSjis",
    //       data.map((i: string) => ({
    //         itemBarangMasuk: { id: i },
    //       }))
    //     )
    //   },
    //   enabledAt: [SuratJalanInternalTypeEnum.SPPB],
    // },
    {
      id: "itemPermintaanPengirimanBarang.permintaan.organisasiAsal.nama",
      label: "Cabang",
      isIgnored: true,
      isDataView: true,
      enabledAt: [SuratJalanInternalTypeEnum.SPPB],
    },
    // TODO: get stock tersedia from organisasiAsal

    {
      id: "itemPermintaanPengirimanBarang.barang.kode",
      label: "Kode",
      disabled: true,
      enabledAt: [SuratJalanInternalTypeEnum.SPPB],
      isDataView: true,
      suffix: {
        id: "itemPermintaanPengirimanBarang.stockAvailability.available",
        label: "Stock Tersedia",
        isHalfSize: true,
        disabled: true,
        type: "number",
      },
    },
    {
      id: "itemPermintaanPengirimanBarang.stockAvailability.sample",
      label: "Stock Tersedia Sample",
      disabled: true,
      type: "number",
      suffix: {
        id: "itemPermintaanPengirimanBarang.stockAvailability.waste",
        label: "Stock Tersedia Waste",
        disabled: true,
        isHalfSize: true,
        type: "number",
      },
      enabledAt: [SuratJalanInternalTypeEnum.SPPB],
    },
    {
      id: "itemPermintaanPengirimanBarang.barang.kode",
      label: "Barang",
      disabled: true,
      enabledAt: [SuratJalanInternalTypeEnum.SPPB],
    },
    {
      id: "itemPermintaanPengirimanBarang.barang.nama",
      label: "Nama Barang",
      type: "ignored",
      enabledAt: [SuratJalanInternalTypeEnum.SPPB],
      isDataView: true,
    },
    {
      id: "itemBapb.id",
      label: "Nomor PO",
      isDataView: true,
      dataViewKey: "itemBapb.itemPurchaseOrderStock.purchaseOrderStock.noPo",
      type: "async-table-select",
      resourceUrl: (_, filter, data) => {
        const q: any = {
          "itemBapb.sisaBarang_$gt": 0,
          "bapb.id": data.bapb?.id,
        }
        return `item-bapb?${genFieldQueryParamStr(q)}`
      },
      column: 1,
      customOnChange: (data, setValue, getValue) => {
        setValue("itemBapb", data)
        if (!data.barang) {
          setValue(
            "itemBapb.barang.nama",
            data.itemPurchaseOrderStock.itemService?.namaBarang
          )
        }

        if (!getValue("qtyFisik")) {
          setValue(
            "qtyFisik",
            parseFloat(data.sisaBarang) / getItemBapbKonversi(data)
          )
          setValue("qty", +data.sisaBarang)
        }
      },
      resourceColumn: [
        {
          dataKey: "itemPurchaseOrderStock.purchaseOrderStock.noPo",
          label: "Nomor PO",
        },
        {
          dataKey: "barang.kode",
          label: "Kode",
          render(data, idx, globVars) {
            return (
              <TableCell>{!data.barang ? "-" : data.barang.kode}</TableCell>
            )
          },
        },
        {
          dataKey: "barang.nama",
          label: "Nama Barang",
          render(data, idx, globVars) {
            return (
              <TableCell>
                {!data.barang
                  ? data.itemPurchaseOrderStock.itemService?.namaBarang
                  : data.barang.kode}
              </TableCell>
            )
          },
        },
        {
          dataKey: "qtyAktual",
          label: "Quantity",
          render(data, idx) {
            return (
              <TableCell>
                {formatNumber(
                  parseFloat(data.sisaBarang) / getItemBapbKonversi(data)
                )}
              </TableCell>
            )
          },
        },
        {
          dataKey: "harga",
          label: "Harga",
          type: "number",
        },
        {
          dataKey: "sisaBarang",
          label: "Sisa",
          type: "number",
        },
      ],
      resourceColumnLabelKey: "itemPurchaseOrderStock.purchaseOrderStock.noPo",
      enabledAt: [SuratJalanInternalTypeEnum.STOCK],
    },
    {
      id: "itemBapb.barang.nama",
      label: "Barang",
      disabled: true,
      enabledAt: [SuratJalanInternalTypeEnum.STOCK],
    },
    {
      id: "itemBapb.barang.kode",
      type: "ignored",
      label: "Kode Barang",
      isDataView: true,
      enabledAt: [SuratJalanInternalTypeEnum.STOCK],
    },
    {
      id: "itemBapb.barang.nama",
      type: "ignored",
      label: "Nama Barang",
      isDataView: true,
      enabledAt: [SuratJalanInternalTypeEnum.STOCK],
    },
    {
      ...genQtyFisikForm(tipe),
      enabledAt: [SuratJalanInternalTypeEnum.STOCK],
      suffix: {
        id: "qty",
        label: "Jumlah Konversi",
        type: "number",
        disabled: true,
        isHalfSize: true,
      },
    },
    {
      id: "itemPermintaanPengirimanBarang.sisaPengirimanSJI",
      dataViewKey: "itemPermintaanPengirimanBarang.qty",
      label: "Jml/Qty Permintaan",
      type: "number",
      isDataView: true,
      disabled: true,
      enabledAt: [SuratJalanInternalTypeEnum.SPPB],
      suffix: { ...genQtyFisikForm(tipe), isHalfSize: true },
    },
    {
      id: "qtyShow",
      isIgnored: true,
      label: "Quantity",
      isDataView: true,
      columnRender(data, filterData, globVars) {
        return (
          <TableCell>
            {formatNumber(
              strToSafeNum(data.qtyFisik) +
                strToSafeNum(data.qtySample) +
                strToSafeNum(data.qtyWaste)
            )}
          </TableCell>
        )
      },
      enabledAt: [
        SuratJalanInternalTypeEnum.STOCK,
        SuratJalanInternalTypeEnum.SPPB,
      ],
    },
    {
      id: "qtyWaste",
      label: "Waste",
      type: "number",
      value: 0,
      suffix: {
        id: "qtySample",
        label: "Sample",
        type: "number",
        isHalfSize: true,
        value: 0,
      },
      enabledAt: [SuratJalanInternalTypeEnum.SPPB],
    },
    {
      id: "itemPermintaanPengirimanBarang.barang.satuanStock.nama",
      label: "Satuan",
      type: "ignored",
      enabledAt: [SuratJalanInternalTypeEnum.SPPB],
    },
    {
      id: "itemBapb.spesifikasi.panjang",
      label: "Panjang",
      type: "number",
      disabled: true,
      suffix: {
        id: "itemBapb.spesifikasi.lebar",
        label: "Lebar",
        type: "number",
        disabled: true,
        isHalfSize: true,
      },
      enabledAt: [SuratJalanInternalTypeEnum.STOCK],
    },
    {
      id: "itemBapb.harga",
      label: "Harga",
      type: "number",
      isDataView: true,
      disabled: true,
      enabledAt: [SuratJalanInternalTypeEnum.STOCK],
    },
    {
      id: "itemPermintaanPengirimanBarang.spesifikasi.panjang",
      label: "Panjang (mm)",
      disabled: true,
      type: "number",
      enabledAt: [SuratJalanInternalTypeEnum.SPPB],
      suffix: {
        id: "itemPermintaanPengirimanBarang.spesifikasi.lebar",
        label: "Lebar (mm)",
        disabled: true,
        isHalfSize: true,
        type: "number",
      },
    },
    {
      id: "keterangan",
      label: "Keterangan",
      isOptional: true,
      type: "text-area",
      enabledAt: [
        SuratJalanInternalTypeEnum.STOCK,
        SuratJalanInternalTypeEnum.SPPB,
      ],
    },
  ]

  return items
    .filter((i: SpesificModuleFormItem<SuratJalanInternalTypeEnum>) =>
      i.enabledAt.includes(tipe)
    )
    .map(({ enabledAt, ...data }) => data)
}

export default generateItemSJIFormItems
