export function genBaseSortQueryParam(
  field: string = "kode",
  isAsc: boolean = true
): string {
  return `sortType=${isAsc ? "asc" : "desc"}&sortBy=${field}`
}

export function genBaseSortQueryParamObj(
  field: string = "kode",
  isAsc: boolean = true
): any {
  return {
    sortType: isAsc ? "asc" : "desc",
    sortBy: field,
  }
}
