import { Text } from "@chakra-ui/react"
import { getValue } from "@testing-library/user-event/dist/utils"
import generateJurnalUmumForms from "common/form-generator/jurnal-umum"
import generateKasbonKaryawanForms from "common/form-generator/kasbon-karyawan"
import generatePembayaranHutangForm from "common/form-generator/pembayaran-hutang"
import generatePermohonanPembayaranHutangForm from "common/form-generator/permohonan-pembayaran-hutang"
import generatePersetujuanKasbonBiayaImportForm from "common/form-generator/persetujuan-kasbon-biaya-import"
import { genTransferGLForm } from "common/form-generator/transfer-gl"
import generateItemKasKecilFormItems from "common/form-item-generator/item-kas-kecil"
import { getTransferBiayaImportData } from "common/gl-util"
import { mapItemPengajuanKasKecilToRenderable } from "common/procedures/kas-kecil"
import { calculatePenerimaanKomisiValas } from "common/procedures/penerimaan-komisi"
import {
  getTotalItemKasKecilNonPPN,
  getTotalItemKasKecilPPN,
} from "common/procedures/pengajuan-kas-kecil"
import {
  getInvoiceCreatedDate,
  getInvoicePaidDate,
} from "common/procedures/pengajuan-kb"
import {
  adjustKursValas,
  calculateValasKurs,
} from "common/procedures/reimburse-supplier"
import { getSQM, getSQMForCounting } from "common/procedures/speisfikasi"
import {
  GeneralMap,
  KasbonKaryawanTypeEnum,
  PenawaranTypeEnum,
} from "common/type"
import {
  formatNumber,
  formatOnlyDate,
  generateCrudUrl,
  genFieldQueryParamObj,
  genFieldQueryParamStr,
  genFormItems,
  genOptionFromArray,
  getToday,
  isValueEmpty,
  strToSafeNum,
  translateApiResponse,
} from "common/util"
import { TableCell } from "components/data-table"
import BaseFilter from "components/filter/_base"
import {
  BasePageProps,
  BulkPageProps,
  FormModeType,
} from "components/page/type"
import { POSTING_TRANSAKSI_COLUMNS } from "constant/columns"
import { LOCALE_MONTHS } from "constant/etc"
import { GLOB_VAR_KODE_SUB_NO_BIAYA_OPERASIONAL } from "constant/global-var-keys"
import {
  mataUangOptionMapper,
  mataUangSearchField,
  pelangganOptionMapper,
  pelangganSearchKeys,
  perkiraanMapper,
  perkiraanResourceColumnKeys,
  perkiraanSearchKey,
  postingBodyRequestMapper,
  supplierOptionMapper,
  supplierResourceColumnKey,
  supplierSearchKey,
  transferGlBodyRequestMapper,
  voucherMapper,
  voucherResourceColumnKeys,
  voucherSearchKey,
} from "constant/mapper"
import { genBaseSortQueryParam, genBaseSortQueryParamObj } from "constant/url"
import { getAPI, postAPI } from "hooks"
import { getKursByMataUang } from "hooks/http/custom"

export const GLPages: GeneralMap<BasePageProps<any>> = {
  "rate-valas": {
    title: "Rate Valas per Hari",
    permissionRequired: "RateValas",
    filter: <BaseFilter keyName="rateValas" />,
    urls: {
      ...generateCrudUrl("rate-valas"),
      read: {
        endpoint: `/rate-valas`,
        param: genFieldQueryParamObj({
          tanggal: formatOnlyDate(new Date()),
        }),
        method: "get",
      },
    },
    formGroupItems: genFormItems([
      {
        id: "tanggal",
        label: "Tanggal",
        value: getToday(),
        type: "date",
        disabled: true,
      },
      {
        id: "kurs",
        label: "Mata Uang",
        disabled: true,
        isDataView: true,
      },
      {
        id: "mataUang.nama",
        label: "Valuta Asing",
        disabled: true,
        isDataView: true,
      },
      {
        id: "value",
        label: "Nilai",
        type: "number",
        isDataView: true,
      },
    ]),
  },
  kasbon: {
    title: "Kas Bon",
    children: [
      "pemasukan-kasbon-karyawan",
      "pemasukan-biaya-operasional",
      "penyelesaian-kasbon",
      "pengajuan-kas-kecil",
      "penambahan-kas-kecil",
    ],
    permissionRequired: "Kasbon",
  },
  "pemasukan-biaya-operasional": generateJurnalUmumForms(),
  "pemasukan-kasbon-karyawan": generateKasbonKaryawanForms(
    KasbonKaryawanTypeEnum.KASBON
  ),
  "penyelesaian-kasbon": {
    permissionRequired: "PenyelesaianKasbon",
    title: "Penyelesaian Kasbon",
    filter: <BaseFilter keyName="penyelesaianKasbon" />,
    urls: generateCrudUrl("penyelesaian-kasbon"),
    requiredToAdd: ["Cabang"],
    disableAdd(data, filterData) {
      return !filterData?.["organisasi.id"]
    },
    generateCodeParam: (data, filter) => ({
      entity: {
        organisasi: {
          id: filter?.["organisasi.id"],
        },
      },
      entityName: "PenyelesaianKasbon",
    }),
    responseMapper(data) {
      return {
        ...data,
        disableVoucherChange: !!data.itemKasbons.find(
          (i: any) => !!i.kasbonBiayaImport?.id
        ),
        itemKasbons: data.itemKasbons.map((i: any) =>
          i.tipe === "KASBON_BI"
            ? {
                nomor: i.kasbonBiayaImport.nomor,
                id: i.kasbonBiayaImport.id,
                kasbon: {
                  namaKaryawan: i.kasbonBiayaImport.karyawan.nama,
                  tanggal: i.kasbonBiayaImport.tanggal,
                  jumlahDibayar: i.kasbonBiayaImport.jumlah,
                  isPpn: i.kasbonBiayaImport.voucher.isUsingPpn,
                  keterangan: i.kasbonBiayaImport.keterangan,
                  nomor: i.kasbonBiayaImport.nomor,
                  voucherId: i.kasbonBiayaImport.voucher.id,
                  perkiraanId: i.kasbonBiayaImport.perkiraan.id,
                  nomorPerkiraan: i.kasbonBiayaImport.perkiraan.nomorPerkiraan,
                  keteranganPerkiraan: i.kasbonBiayaImport.perkiraan.keterangan,
                  kodeVoucher: i.kasbonBiayaImport.voucher.kodeVoucher,
                  jenisVoucher: i.kasbonBiayaImport.voucher.jenisVoucher,
                  id: i.kasbonBiayaImport.id,
                  tipe: i.tipe,
                },
                tipe: "KASBON_BI",
              }
            : {
                nomor: i.kasbonKaryawan.nomor,
                id: i.kasbonKaryawan.id,
                kasbon: {
                  namaKaryawan: i.kasbonKaryawan.karyawan.nama,
                  tanggal: i.kasbonKaryawan.tanggal,
                  jumlahDibayar: i.kasbonKaryawan.jumlah,
                  isPpn: i.kasbonKaryawan.voucher.isUsingPpn,
                  keterangan: i.kasbonKaryawan.keterangan,
                  nomor: i.kasbonKaryawan.nomor,
                  voucherId: i.kasbonKaryawan.voucher.id,
                  perkiraanId: i.kasbonKaryawan.voucher.perkiraan.id,
                  nomorPerkiraan:
                    i.kasbonKaryawan.voucher.perkiraan.nomorPerkiraan,
                  keteranganPerkiraan:
                    i.kasbonKaryawan.voucher.perkiraan.keterangan,
                  kodeVoucher: i.kasbonKaryawan.voucher.kodeVoucher,
                  jenisVoucher: i.kasbonKaryawan.voucher.jenisVoucher,
                  id: i.kasbonKaryawan.id,
                  tipe: i.tipe,
                },
                tipe: "KASBON_KARYAWAN",
              }
        ),
        itemPenyelesaianKasbons: data.itemPenyelesaianKasbons.map((i: any) => ({
          ...i,
          divisi: i.subDivisi.divisi,
        })),
      }
    },
    customFormDataMapper(data, filterData) {
      return {
        ...data,
        itemKasbons: data.itemKasbons.map((i: any) => {
          const body: any = {
            tipe: i.kasbon.tipe,
          }
          if (i.kasbon.tipe === "KASBON_BI") {
            const { id, jumlahDibayar } = i.kasbon
            body.kasbonBiayaImport = {
              id,
              jumlah: jumlahDibayar,
            }
          } else {
            const { id, jumlahDibayar } = i.kasbon
            body.kasbonKaryawan = {
              id,
              jumlah: jumlahDibayar,
            }
          }
          return body
        }),
      }
    },
    formGroupItems: genFormItems([
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "hidden",
      },
      {
        id: "nomor",
        label: "Nomor",
        isDataView: true,
        isGeneratedCodeField: true,
        disabled: true,
        suffix: {
          id: "tanggal",
          label: "Tanggal",
          value: getToday(),
          type: "date",
          isHalfSize: true,
          isDataView: true,
        },
      },
      {
        id: "disableVoucherChange",
        type: "hidden",
      },
      {
        id: "voucher.id",
        label: "Jenis Voucher",
        type: "async-select",
        resourceUrl: (data, filter) =>
          `voucher?${genBaseSortQueryParam(
            "kodeVoucher"
          )}&${genFieldQueryParamStr({
            "organisasi.id": filter?.["organisasi.id"],
          })}`,
        resourceColumnLabelKey: "voucher.kodeVoucher,voucher.keterangan",
        resourceMapper: voucherMapper,
        searchBy: voucherSearchKey,
        customOnChange: (data, setValue, getValue) => {
          setValue("voucher", data)
          if (!!data?.perkiraan?.id) {
            setValue("perkiraan.id", data?.perkiraan?.id)
            setValue(
              "perkiraan.nomorPerkiraan",
              data?.perkiraan?.nomorPerkiraan
            )
            setValue("perkiraan.keterangan", data?.perkiraan?.keterangan)
          } else {
            setValue("perkiraan.id", null)
            setValue("perkiraan.nomorPerkiraan", null)
            setValue("perkiraan.keterangan", null)
          }
        },
      },
      {
        id: "voucher.jenis",
        type: "radio",
        label: "Jenis",
        disabled: true,
        options: genOptionFromArray(["DEBIT", "KREDIT"]),
        suffix: {
          id: "voucher.isUsingPpn",
          label: "PPn",
          type: "check",
          disabled: true,
        },
      },
      {
        id: "perkiraan.id",
        label: "Nomor Perkiraan",
        type: "async-select",
        resourceUrl: (data, filter) =>
          `perkiraan?${genFieldQueryParamStr({
            "$or_organisasi.id||organisasi.id": `${filter["organisasi.id"]}||null`,
          })}`,
        resourceColumnLabelKey: perkiraanResourceColumnKeys,
        disabled: (data, detail) => {
          return !!data?.voucher?.perkiraan?.nomorPerkiraan
        },
        resourceMapper: perkiraanMapper,
        searchBy: perkiraanSearchKey,
      },
      {
        id: "keterangan",
        label: "Keterangan",
        type: "text-area",
        isDataView: true,
      },
      {
        id: "total",
        label: "Jumlah",
        type: "number",
        value: 0,
      },
      {
        id: "debet",
        type: "ignored",
        label: "Debet",
        isDataView: true,
        columnRender: (data) => {
          return (
            // @ts-ignore
            <Text fontSize="14px" fontWeight={600}>
              {formatNumber(
                (data?.voucher?.jenis === "DEBIT" ? +data?.total : 0) +
                  data?.itemPenyelesaianKasbons
                    ?.filter((i: any) => i.jenis === "DEBIT")
                    .reduce(
                      (total: number, item: any) =>
                        total + strToSafeNum(item.jumlah),
                      0
                    ) ?? 0
              )}
            </Text>
          )
        },
      },
      {
        id: "kredit",
        type: "ignored",
        label: "Kredit",
        isDataView: true,
        columnRender: (data) => {
          return (
            <Text fontSize="14px" fontWeight={600}>
              {formatNumber(
                (data?.voucher?.jenis === "KREDIT" ? +data?.total : 0) +
                  data?.itemPenyelesaianKasbons
                    ?.filter((i: any) => i.jenis === "KREDIT")
                    .reduce(
                      (total: number, item: any) =>
                        total + strToSafeNum(item.jumlah),
                      0
                    ) ?? 0
              )}
            </Text>
          )
        },
      },
    ]),
    additionalForms: [
      {
        id: "itemKasbons",
        title: "Daftar Kasbon/Reimburse",
        formItems: genFormItems([
          {
            id: "kasbon.id",
            label: "No. Kasbon",
            column: 1,
            type: "async-table-select",
            resourceUrl: (data, filter) =>
              `item-kasbon/kasbon?${genFieldQueryParamStr({
                "organisasi.id": filter?.["organisasi.id"],
              })}`,
            resourceColumn: [
              {
                dataKey: "nomor",
                label: "Nomor",
              },
              {
                dataKey: "tanggal",
                label: "Tanggal",
                type: "date",
              },
              {
                dataKey: "namaKaryawan",
                label: "Nama Karyawan",
              },
              {
                dataKey: "keterangan",
                label: "Keterangan",
              },
              {
                dataKey: "jumlahDibayar",
                label: "Jumlah Dibayar",
                type: "number",
              },
              {
                dataKey: "isPpn",
                label: "PPn",
                render: (data) => (
                  // @ts-ignore
                  <Text fontSize="14px" fontWeight={600}>
                    {data.isPpn ? "Y" : "T"}
                  </Text>
                ),
              },
            ],
            customOnChange: (data, setValue) => {
              setValue("kasbon", data)
            },
            resourceColumnLabelKey: "nomor",
          },
          {
            id: "kasbon.nomor",
            label: "Nomor",
            isIgnored: true,
            isDataView: true,
          },
          {
            id: "kasbon.tanggal",
            label: "Tanggal",
            type: "date",
            isIgnored: true,
            isDataView: true,
          },
          {
            id: "kasbon.namaKaryawan",
            label: "Nama Karyawan",
            type: "ignored",
            isDataView: true,
          },
          {
            id: "kasbon.keterangan",
            label: "Keterangan",
            type: "ignored",
            isDataView: true,
          },
          {
            id: "kasbon.jumlahDibayar",
            label: "Jumlah Dibayar",
            isIgnored: true,
            isDataView: true,
            type: "number",
          },
          {
            id: "kasbon.isPpn",
            label: "PPn",
            type: "ignored",
            isDataView: true,
            columnRender: (data) => (
              // @ts-ignore
              <Text fontSize="14px" fontWeight={600}>
                {data.kasbon?.isPpn ? "Y" : "T"}
              </Text>
            ),
          },
        ]),
      },
      {
        id: "itemPenyelesaianKasbons",
        title: "Daftar Penyelesaian Kasbon",
        summaryCallback: [
          (items) => {
            return {
              label: "TOTAL",
              total: formatNumber(
                items.reduce(
                  (total, item) =>
                    total + +item.jumlah * (item.jenis === "KREDIT" ? -1 : 1),
                  0
                )
              ),
            }
          },
        ],
        formItems: genFormItems([
          {
            id: "perkiraan.id",
            label: "Nomor Perkiraan",
            dataViewKey: "perkiraan.nomorPerkiraan",
            type: "async-select",
            resourceUrl: (data, filter) =>
              `perkiraan?${genBaseSortQueryParam(
                "nomorPerkiraan"
              )}&${genFieldQueryParamStr({
                level: "DETAIL",
                "$or_organisasi.id||organisasi.id": `${filter["organisasi.id"]}||null`,
              })}`,
            resourceMapper: perkiraanMapper,
            searchBy: "nomorPerkiraan,keterangan",
            isDataView: true,
            resourceColumnLabelKey: perkiraanResourceColumnKeys,
          },
          {
            id: "jumlah",
            label: "Jumlah",
            type: "number",
          },
          {
            id: "jenis",
            label: "Jenis",
            type: "radio",
            options: genOptionFromArray(["DEBIT", "KREDIT"]),
            isDataView: true,
          },
          {
            id: "keterangan",
            type: "text-area",
            label: "Keterangan",
            dataViewLabel: "Deskripsi",
          },
          {
            id: "jumlah",
            label: "Jumlah",
            type: "number",
            isIgnored: true,
            isDataView: true,
          },
          {
            id: "divisi.id",
            label: "Divisi",
            type: "async-select",
            resourceUrl: `divisi?${genBaseSortQueryParam()}`,
          },
          {
            id: "subDivisi.id",
            label: "Sub Divisi",
            type: "async-select",
            resourceUrl: (data) =>
              `sub-divisi?${genBaseSortQueryParam()}&${genFieldQueryParamStr({
                "divisi.id": data.divisi?.id,
              })}`,
          },
        ]),
      },
    ],
  },
  "pengajuan-kas-kecil": {
    title: "Pengajuan Penambahan Kas Kecil",
    permissionRequired: "PengajuanKasKecil",
    urls: generateCrudUrl("pengajuan-penambahan-kas-kecil"),
    filter: <BaseFilter keyName="pengajuanKasKecil" />,
    generateCodeParam: (data, filter) => ({
      entity: {
        organisasi: {
          id: filter?.["organisasi.id"],
        },
      },
      entityName: "PengajuanPenambahanKasKecil",
    }),
    requiredToAdd: ["Cabang"],
    disableAdd(data, filterData) {
      return !filterData?.["organisasi.id"]
    },
    onOpen(detailData, userData, setValue, filter, formMode) {
      if (formMode === "create") {
        getAPI(
          "/item-kasbon/pengajuan-kas-kecil",
          genFieldQueryParamObj({
            "organisasi.id": filter?.["organisasi.id"],
          })
        ).then((resp: any) => {
          setValue("itemPengajuanPenambahanKasKecils", resp.items ?? [])
        })
      }
    },
    customFormDataMapper(data, filterData) {
      return {
        ...data,
        itemPengajuanPenambahanKasKecils: (
          data.itemPengajuanPenambahanKasKecils ?? []
        ).map((i: any) => {
          const { id, ...body } = i

          return {
            ...body,
            jurnalUmum: {
              id,
            },
            kasbonKaryawan: {
              id,
            },
            kasbonBiayaImport: {
              id,
            },
            penyelesaianKasbon: {
              id,
            },
          }
        }),
      }
    },
    responseMapper(data) {
      return {
        ...data,
        itemPengajuanPenambahanKasKecils: mapItemPengajuanKasKecilToRenderable(
          data.itemPengajuanPenambahanKasKecils
        ),
      }
    },
    formGroupItems: genFormItems([
      {
        id: "nomor",
        label: "Nomor",
        disabled: true,
        isGeneratedCodeField: true,
        isDataView: true,
        suffix: {
          id: "tanggal",
          type: "date",
          value: getToday(),
          label: "Tanggal",
          isHalfSize: true,
          isDataView: true,
        },
      },
      {
        id: "organisasi.id",
        type: "hidden",
      },
      {
        id: "blank",
        type: "blank",
      },
      {
        id: "voucher.id",
        dataViewKey: "voucher.kodeVoucher",
        dataViewLabel: "Kode Voucher",
        label: "Jenis Voucher",
        type: "async-select",
        resourceUrl: (data, filter) =>
          `voucher?${genBaseSortQueryParam(
            "kodeVoucher"
          )}&${genFieldQueryParamStr({
            "organisasi.id": filter?.["organisasi.id"],
          })}`,
        resourceMapper: voucherMapper,
        resourceColumnLabelKey: voucherResourceColumnKeys,
        searchBy: voucherSearchKey,
        customOnChange: (data, setValue, getValue, genCode) => {
          const itemKasKecils = getValue("itemPengajuanPenambahanKasKecils")

          const total = data?.isUsingPpn
            ? getTotalItemKasKecilPPN(itemKasKecils)
            : getTotalItemKasKecilNonPPN(itemKasKecils)

          setValue("jumlah", total)
          setValue("voucher", data)
          setValue("perkiraan", data.perkiraan)
        },
      },
      {
        id: "perkiraan.nomorPerkiraan",
        disabled: true,
        label: "Nomor Perkiraan",
      },
      {
        id: "voucher.jenis",
        label: "Jenis",
        type: "radio",
        disabled: true,
        options: genOptionFromArray(["DEBIT", "KREDIT"]),
        suffix: {
          id: "voucher.isUsingPpn",
          label: "PPn",
          type: "check",
          isCallRecalculateCb: true,
          disabled: true,
          isHalfSize: true,
        },
      },
      {
        id: "jumlah",
        label: "Jumlah",
        type: "number",
        disabled: true,
        isDataView: true,
      },
      {
        id: "keterangan",
        label: "Keterangan",
        type: "text-area",
        isDataView: true,
      },
    ]),
    additionalForms: [
      {
        id: "itemPengajuanPenambahanKasKecils",
        hideAdd: true,
        title: "Daftar Kasbon dan Biaya Reimburse",
        formItems: generateItemKasKecilFormItems(),
        summaryCallback: [
          (items) => {
            return {
              label: "TOTAL PPN",
              total: getTotalItemKasKecilPPN(items),
            }
          },
          (items) => {
            return {
              label: "TOTAL NON-PPN",
              total: getTotalItemKasKecilNonPPN(items),
            }
          },
          (items) => {
            return {
              label: "TOTAL",
              total: items.reduce((total, item) => total + +item.jumlah, 0),
            }
          },
        ],
      },
    ],
  },
  "penambahan-kas-kecil": {
    title: "Penambahan Kas Kecil",
    permissionRequired: "PenambahanKasKecil",
    urls: generateCrudUrl("penambahan-kas-kecil"),
    filter: <BaseFilter keyName="penambahanKasKecil" />,
    responseMapper(data) {
      return {
        ...data,
        itemKasKecils: mapItemPengajuanKasKecilToRenderable(
          data.pengajuanPenambahanKasKecil?.itemPengajuanPenambahanKasKecils
        ),
      }
    },
    generateCodeParam: (data, filter) => ({
      entity: {
        pengajuanPenambahanKasKecil: {
          organisasi: {
            id: filter?.["pengajuanPenambahanKasKecil.organisasi.id"],
          },
        },
      },
      entityName: "PenambahanKasKecil",
    }),
    disableAdd(data, filterData) {
      return !filterData?.["pengajuanPenambahanKasKecil.organisasi.id"]
    },
    requiredToAdd: ["Cabang"],
    formGroupItems: genFormItems([
      {
        id: "pengajuanPenambahanKasKecil.id",
        resourceColumnLabelKey: "nomor",
        label: "No. Pengajuan",
        column: 1,
        disabled: (
          data: any,
          detailData: any,
          formMode: FormModeType,
          globVars: Map<string, string>
        ) => formMode === "update",
        type: "async-table-select",
        resourceUrl: (data, filter) =>
          `pengajuan-penambahan-kas-kecil?${genFieldQueryParamStr({
            "organisasi.id":
              filter?.["pengajuanPenambahanKasKecil.organisasi.id"],
            "penambahanKasKecils.id": "null",
          })}`,
        resourceColumn: [
          {
            dataKey: "nomor",
            label: "Nomor",
          },
          { dataKey: "tanggal", label: "Tanggal" },
          { dataKey: "voucher.kodeVoucher", label: "Kode Voucher" },
          { dataKey: "keterangan", label: "Keterangan" },
          { dataKey: "jumlah", label: "Jumlah", type: "number" },
          {
            dataKey: "voucher.isUsingPpn",
            label: "PPN",
            render: (data) => (
              // @ts-ignore
              <TableCell>{data.voucher?.isUsingPpn ? "Y" : "T"}</TableCell>
            ),
          },
        ],
        customOnChange: (
          data,
          setValue,
          getValue,
          genCode,
          globVar,
          triggerCheck
        ) => {
          setValue("pengajuanPenambahanKasKecil", data)
          if (isValueEmpty(getValue("id"))) {
            setValue(
              "itemKasKecils",
              mapItemPengajuanKasKecilToRenderable(
                data.itemPengajuanPenambahanKasKecils
              )
            )
          }
        },
      },
      {
        id: "nomor",
        label: "Nomor",
        dataViewLabel: "Nomor Penambahan",
        isGeneratedCodeField: true,
        disabled: true,
        isDataView: true,
        suffix: {
          id: "tanggal",
          isHalfSize: true,
          type: "date",
          label: "Tanggal",
          value: getToday(),
        },
      },
      {
        id: "pengajuanPenambahanKasKecil.nomor",
        type: "ignored",
        dataViewLabel: "No. Pengajuan",
      },
      {
        id: "tanggal",
        type: "date",
        isIgnored: true,
        dataViewLabel: "No. Pengajuan",
        value: getToday(),
      },
      {
        type: "blank",
        id: "blank",
      },
      {
        id: "voucher.id",
        dataViewKey: "voucher.kodeVoucher",
        dataViewLabel: "Kode Voucher",
        label: "Jenis Voucher",
        type: "async-select",
        resourceUrl: (data, filter) => {
          return `voucher?${genBaseSortQueryParam(
            "kodeVoucher"
          )}&${genFieldQueryParamStr({
            isUsingPpn: data?.pengajuanPenambahanKasKecil?.voucher?.isUsingPpn,
            "organisasi.id":
              filter?.["pengajuanPenambahanKasKecil.organisasi.id"],
            kodeVoucher_$startsWith: "B",
            isJurnalPenyesuaian: false,
            jenis: "KREDIT",
          })}`
        },
        resourceMapper: voucherMapper,
        searchBy: voucherSearchKey,
        customOnChange: (data, setValue, getValue) => {
          setValue("voucher", data)
          if (isValueEmpty(getValue("perkiraan.id"))) {
            if (!!data?.perkiraan?.id) {
              setValue("perkiraan", data?.perkiraan)
            } else {
              setValue("perkiraan.id", null)
            }
          }
        },
        resourceColumnLabelKey: voucherResourceColumnKeys,
      },

      {
        id: "perkiraan.id",
        disabled: (data, detail) => {
          return !!data?.voucher?.perkiraan?.nomorPerkiraan
        },
        label: "Nomor Perkiraan",
        type: "async-select",
        resourceUrl: `perkiraan?${genBaseSortQueryParam("nomorPerkiraan")}`,
        resourceMapper: perkiraanMapper,
        searchBy: perkiraanSearchKey,
        resourceColumnLabelKey: perkiraanResourceColumnKeys,
      },
      {
        id: "voucher.jenis",
        label: "Jenis",
        type: "radio",
        options: genOptionFromArray(["DEBIT", "KREDIT"]),
        disabled: true,
        suffix: {
          id: "voucher.isUsingPpn",
          disabled: true,
          isHalfSize: true,
          label: "PPn",
          type: "check",
        },
      },
      {
        id: "pengajuanPenambahanKasKecil.jumlah",
        label: "Jumlah",
        type: "number",
        disabled: true,
        isDataView: true,
      },
      {
        id: "pengajuanPenambahanKasKecil.keterangan",
        label: "Keterangan",
        type: "text-area",
        disabled: true,
        isDataView: true,
      },
    ]),
    additionalForms: [
      {
        id: "itemKasKecils",
        title: "Daftar Kasbon dan Biaya Reimburse",
        hideAdd: true,
        formItems: generateItemKasKecilFormItems(),
      },
    ],
  },
  "pembayaran-gl": {
    title: "Pembayaran",
    permissionRequired: "PembayaranGL",
    children: [
      "pengajuan-kb",
      "pembayaran-kb",
      "permohonan-reimburse-supplier",
      "pembayaran-reimbers-supplier",
      // "konfirmasi-biaya-supplier",
      "pengajuan-pembayaran-hutang-gl",
      "pembayaran-hutang-gl",
      "persetujuan-kasbon-biaya-import-gl",
    ],
  },
  "pengajuan-kb": {
    title: "Pengajuan KB",
    urls: generateCrudUrl("pengajuan-kb"),
    permissionRequired: "PengajuanKb",
    filter: <BaseFilter keyName="pengajuanKb" />,
    onOpen(formData, userData, setValue) {
      setValue(
        "pelanggan.id",
        formData.purchaseOrder?.salesContract?.penawaran?.pelanggan?.id
      )
    },
    requiredToAdd: ["Cabang", "Customer"],
    disableAdd(data, filterData) {
      return (
        !filterData?.["pengajuanKb.invoicePelanggan.penawaran.organisasi.id"] ||
        !filterData?.["pengajuanKb.invoicePelanggan.penawaran.pelanggan.id"]
      )
    },
    responseMapper(data) {
      return {
        ...data,
        itemPengajuanKbs:
          data.itemPengajuanKbs?.map((i: any) => ({
            ...i,
            rekeningKb: !i.rekeningKb
              ? {
                  id: "0",
                  norek: "Diambil Langsung",
                  namaRekening: "Sales/Marketing",
                }
              : i.rekeningKb,
          })) ?? [],
      }
    },
    generateCodeParam: (data, filter) => ({
      entity: {
        invoicePelanggan: {
          penawaran: {
            organisasi: {
              id: filter?.[
                "pengajuanKb.invoicePelanggan.penawaran.organisasi.id"
              ],
            },
          },
        },
      },
      entityName: "PengajuanKb",
    }),
    formGroupItems: genFormItems([
      {
        id: "invoicePelanggan.id",
        label: "Invoice",
        type: "async-table-select",
        resourceUrl: (data, filter) =>
          `pengajuan-kb/available-invoices?${genFieldQueryParamStr({
            "invoicePelanggan.penawaran.organisasi.id":
              filter?.["pengajuanKb.invoicePelanggan.penawaran.organisasi.id"],
            "invoicePelanggan.penawaran.pelanggan.id":
              filter?.["pengajuanKb.invoicePelanggan.penawaran.pelanggan.id"],
          })}`,
        column: 1,
        resourceColumn: [
          {
            dataKey: "no",
            label: "No. Invoice",
          },
          {
            dataKey: "penawaran.no",
            label: "No. Penawaran",
          },
          {
            dataKey: "penawaran.salesContracts.0.noSalesContract",
            label: "No. Sales Kontrak",
          },
          {
            dataKey: "totalTagihan",
            label: "Harga Jual",
            type: "number",
            render(data, idx, globVars) {
              return (
                <TableCell>
                  {formatNumber(data.totalTagihan / data.penawaran.rate)}
                </TableCell>
              )
            },
          },
          {
            dataKey: "penawaran.kb",
            label: "KB %",
            type: "number",
          },
          {
            dataKey: "tanggal",
            label: "Tanggal Lunas",
            render(data, idx, globVars) {
              return (
                <TableCell>
                  {formatOnlyDate(getInvoicePaidDate(data))}
                </TableCell>
              )
            },
          },
        ],
        customOnChange: (data, setValue, getValue, genCode, globVars) => {
          setValue("invoicePelanggan", data)

          const totalTagihan =
            (data.penawaran.tipe === PenawaranTypeEnum.TTLC
              ? data.totalTagihan
              : data.pelunasanPiutangs.reduce(
                  (total: number, item: any) => total + +item.netto,
                  0
                )) / data.penawaran.rate
          setValue("nilaiBayar", totalTagihan)
          const nilaiKb = (+data.penawaran.kb * totalTagihan) / 100
          setValue("nilaiKb", nilaiKb)

          const paidDate = getInvoicePaidDate(data)

          const lamaBayar = Math.round(
            (paidDate.getTime() - getInvoiceCreatedDate(data).getTime()) /
              (1000 * 3600 * 24)
          )
          setValue("lamaBayar", lamaBayar)

          let penalti = 0
          if (lamaBayar >= 61 && lamaBayar <= 70) {
            penalti = 0.25
          } else if (lamaBayar >= 71 && lamaBayar <= 80) {
            penalti = 0.5
          } else if (lamaBayar >= 81 && lamaBayar <= 90) {
            penalti = 0.75
          } else if (lamaBayar >= 91) {
            penalti = 1
          }
          penalti = penalti * nilaiKb
          setValue("penalti", penalti)

          setValue("nilaiKbRp", (nilaiKb - penalti) * +data.penawaran.rate)

          const ignoredKodeSub = globVars.get(
            GLOB_VAR_KODE_SUB_NO_BIAYA_OPERASIONAL
          )

          if (data.penawaran.tipe === PenawaranTypeEnum.TTLC) {
            const costOp = 0
            setValue("costOp", costOp)
            setValue("profit", (+data.penawaran.margin / 100) * totalTagihan)
          } else {
            let itemSuratJalanCustomers: any[] =
              data.suratJalanCustomer.itemSuratJalanCustomers
            Promise.all([
              getAPI<{ nilai: number }>(`/biayaOperasional/pengajuan-kb`, {
                divisiId: data.penawaran?.divisi?.id,
                organisasiId: data.penawaran?.organisasi?.id,
              }),
              ...itemSuratJalanCustomers.map((i: any) =>
                getAPI<{ nilaiRupiah: number }>(
                  `/hpp/barang/${
                    !!i.itemBarangJadi?.id
                      ? i.itemBarangJadi?.itemPenawarans?.[0]?.barang?.id
                      : i.itemPenawaran?.barang?.id
                  }`
                )
              ),
            ]).then((resps) => {
              const [resOp, ...hppResps] = resps

              let totalHpp = 0

              let noCostOp = false
              for (const idx in itemSuratJalanCustomers) {
                if (!!hppResps[idx]) {
                  const item = itemSuratJalanCustomers[idx]
                  const spesifikasi = (
                    !!item.itemBarangJadi?.id
                      ? item.itemBarangJadi?.itemPenawarans?.[0]
                      : item.itemPenawaran
                  )?.spesifikasi

                  const barang = (
                    !!item.itemBarangJadi?.id
                      ? item.itemBarangJadi?.itemPenawarans?.[0]
                      : item.itemPenawaran
                  )?.barang

                  if (barang.subKelompok.kode === ignoredKodeSub) {
                    noCostOp = true
                  }

                  totalHpp +=
                    getSQMForCounting(
                      spesifikasi?.panjang,
                      spesifikasi?.lebar
                    ) *
                    hppResps[idx].nilaiRupiah *
                    +item.sisaRetur
                }
              }

              const costOp = noCostOp ? 0 : !!resOp?.nilai ? +resOp.nilai : 25

              const rawProfit = totalTagihan - nilaiKb
              const profit =
                totalTagihan - totalHpp - (costOp * rawProfit) / 100 - nilaiKb
              setValue("costOp", costOp)
              setValue("profit", profit)
            })
          }
        },
      },
      {
        id: "tanggal",
        label: "Tanggal",
        type: "date",
        disabled: true,
        value: getToday(),
        isDataView: true,
        suffix: {
          id: "nomor",
          label: "No. Bayar KB",
          disabled: true,
          isGeneratedCodeField: true,
          isDataView: true,
          isHalfSize: true,
        },
      },
      {
        id: "invoicePelanggan.penawaran.salesContracts.0.noPoPelanggan",
        label: "Nomor PO",
        disabled: true,
        suffix: {
          id: "invoicePelanggan.penawaran.salesContracts.0.noSalesContract",
          label: "Nomor SC",
          disabled: true,
          isHalfSize: true,
        },
      },
      {
        id: "invoicePelanggan.penawaran.no",
        label: "Nomor Penawaran",
        disabled: true,
        isDataView: true,
        suffix: {
          id: "invoicePelanggan.no",
          label: "Nomor Invoice",
          disabled: true,
          isHalfSize: true,
        },
      },
      {
        id: "invoicePelanggan.penawaran.mataUang.mataUang",
        disabled: true,
        label: "Mata Uang",
        suffix: {
          id: "invoicePelanggan.penawaran.rate",
          label: "Nilai Rate",
          disabled: true,
          type: "number",
          isHalfSize: true,
        },
      },
      {
        id: "costOp",
        label: "Cost OP",
        type: "number",
        disabled: true,
        suffix: {
          id: "invoicePelanggan.penawaran.kb",
          label: "KB",
          disabled: true,
          type: "number",
          isHalfSize: true,
        },
      },
      {
        id: "tanggalPengajuan",
        type: "date",
        value: getToday(),
        label: "Tanggal KB",
        suffix: {
          id: "lamaBayar",
          label: "Lama Bayar (hari)",
          type: "number",
          disabled: true,
          isHalfSize: true,
        },
      },
      {
        id: "nilaiBayar",
        label: "Nilai Bayar",
        disabled: true,
        type: "number",
        suffix: {
          id: "profit",
          type: "number",
          label: "Profit",
          disabled: true,
          isHalfSize: true,
        },
      },
      {
        id: "penalti",
        label: "Penalti",
        type: "number",
        disabled: true,
        suffix: {
          id: "blank",
          type: "blank",
          isOptional: true,
          isHalfSize: true,
        },
      },
      {
        id: "nilaiKb",
        label: "Nilai KB Penawaran",
        type: "number",
        disabled: true,
        suffix: {
          id: "nilaiKbRp",
          label: "Nilai KB Didapat",
          disabled: true,
          type: "number",
          isDataView: true,
          isHalfSize: true,
        },
      },
      {
        id: "nilaiKbPengajuan",
        label: "Nilai KB Diajukan",
        type: "number",
        isIgnored: true,
        isDataView: true,
      },
      {
        id: "keterangan",
        label: "Keterangan",
        isOptional: true,
        type: "text-area",
      },
      {
        id: "invoicePelanggan.penawaran.tipe",
        isIgnored: true,
        label: "Tipe",
        isDataView: true,
      },
    ]),
    additionalForms: [
      {
        id: "itemPengajuanKbs",
        disableAdd: (data) => !data.invoicePelanggan?.penawaran?.pelanggan?.id,
        title: "Daftar Rekening",
        async validateDataCb(formData, globData, formMode, filters) {
          if (
            globData.invoicePelanggan.penawaran.tipe !== PenawaranTypeEnum.TTLC
          ) {
            const totalExisting =
              globData.itemPengajuanKbs
                ?.filter(
                  (item: any) => item.rekeningKb.id !== formData.rekeningKb.id
                )
                ?.reduce(
                  (total: number, item: any) => total + +item.jumlah,
                  0
                ) ?? 0

            const total = +formData.jumlah + totalExisting
            if (total > +globData.nilaiKbRp) {
              return `Total pembayaran KB (${formatNumber(
                +total
              )})tidak boleh lebih dari ${formatNumber(+globData.nilaiKbRp)}`
            }
          }
          if (
            formMode === "create" &&
            !!globData.itemPengajuanKbs?.find(
              (item: any) => item.rekeningKb.id === formData.rekeningKb.id
            )
          ) {
            return `Duplikat No. Rekening. No. Rekening ${formData.rekeningKb.norek} sudah digunakan`
          }

          return ""
        },
        formItems: genFormItems([
          {
            id: "rekeningKb.id",
            label: "No. Rekening",
            isDataView: true,
            type: "async-select",
            resourceUrl: (_, filter, data) =>
              `rekening-kb?${genFieldQueryParamStr({
                "pelanggan.id": data.invoicePelanggan?.penawaran?.pelanggan?.id,
              })}`,
            dataViewKey: "rekeningKb.norek",
            resourceMapper: (data) => ({
              value: data.id,
              label: `${data.norek}`,
            }),
            baseResources: [
              {
                id: "0",
                norek: "Diambil Langsung",
                namaRekening: "Sales/Marketing",
              },
            ],
            customOnChange: (
              data,
              setValue,
              getValue,
              genCode,
              globVar,
              triggerPreCheck,
              globDetail
            ) => {
              setValue("rekeningKb", data)
              if (isValueEmpty(getValue("jumlah")))
                setValue("jumlah", globDetail.nilaiKbRp)
            },
            suffix: {
              id: "rekeningKb.namaBank",
              label: "Nama Bank",
              disabled: true,
              isDataView: true,
            },
          },
          {
            id: "rekeningKb.namaRekening",
            label: "Atas Nama",
            disabled: true,
            isDataView: true,
          },
          {
            id: "jumlah",
            label: "Jumlah KB",
            isDataView: true,
            type: "number",
          },
        ]),
      },
    ],
    cetakActions: [],
  },
  "permohonan-reimburse-supplier": {
    title: "Permohonan Reimbers Biaya Supplier",
    urls: generateCrudUrl("permohonan-reimburse-biaya-supplier"),
    permissionRequired: "PermohonanReimburseBiayaSupplier",
    filter: <BaseFilter keyName="periodeOnly" />,
    generateCodeParam: {
      entity: {},
      entityName: "PermohonanReimburseBiayaSupplier",
    },
    formGroupItems: genFormItems([
      {
        id: "nomor",
        label: "Nomor",
        isGeneratedCodeField: true,
        disabled: true,
        isDataView: true,
        suffix: {
          id: "tanggal",
          label: "Tanggal",
          value: getToday(),
          type: "date",
          isDataView: true,
          isHalfSize: true,
        },
      },
      {
        id: "divisi.id",
        label: "Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
        suffix: {
          id: "isPpn",
          isHalfSize: true,
          label: "PPN",
          type: "check",
        },
      },
      {
        id: "supplier.id",
        label: "Supplier",
        type: "async-select",
        resourceUrl: `supplier?${genBaseSortQueryParam("namaPanjang")}`,
        resourceMapper: supplierOptionMapper,
        searchBy: supplierSearchKey,
        resourceColumnLabelKey: supplierResourceColumnKey,
        isDataView: true,
        dataViewKey: "supplier.namaPanjang",
      },
      {
        id: "keterangan",
        label: "Keterangan",
        type: "text-area",
        isDataView: true,
        columnRender(data) {
          return (
            <TableCell>
              <pre
                style={{
                  fontFamily: "inherit",
                }}
              >
                {data.keterangan}
              </pre>
            </TableCell>
          )
        },
      },
      {
        id: "jumlah",
        label: "Jumlah",
        type: "number",
        suffix: {
          id: "mataUang.mataUang",
          label: "Mata Uang",
          type: "async-select",
          resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
          resourceMapper: mataUangOptionMapper,
          searchBy: mataUangSearchField,
          customOnChange: (data, setValue, getValue) => {
            adjustKursValas(
              data?.mataUang,
              getValue("kodeValas.mataUang"),
              setValue
            )
          },
          isHalfSize: true,
        },
      },
      {
        id: "jumlah",
        label: "Jumlah",
        type: "number",
        isIgnored: true,
        isDataView: true,
      },
      {
        id: "kodeValas.mataUang",
        label: "Kode Valas",
        type: "async-select",
        resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
        resourceMapper: mataUangOptionMapper,
        searchBy: mataUangSearchField,
        customOnChange: (data, setValue, getValue) => {
          adjustKursValas(
            getValue("mataUang.mataUang"),
            data?.mataUang,
            setValue
          )
        },
        suffix: {
          isHalfSize: true,
          id: "rate",
          label: "Kurs Mata Uang",
          type: "number",
        },
      },
    ]),
  },
  "pembayaran-reimbers-supplier": {
    title: "Pembayaran Reimbers (Biaya - Biaya Supplier)",
    urls: generateCrudUrl("pembayaran-reimburse-biaya-supplier"),
    permissionRequired: "PembayaranReimburseBiayaSupplier",
    filter: <BaseFilter keyName="pembayaranReimburseSupplier" />,
    formGroupItems: genFormItems([
      {
        id: "permohonanReimburseBiayaSupplier.id",
        dataViewKey: "permohonanReimburseBiayaSupplier.nomor",
        label: "No. Permohonan",
        type: "async-table-select",
        resourceColumnLabelKey: "nomor",
        resourceUrl: `permohonan-reimburse-biaya-supplier?${genFieldQueryParamStr(
          {
            "pembayaranReimburseBiayaSuppliers.id": "null",
          }
        )}`,
        resourceColumn: [
          {
            dataKey: "nomor",
            label: "Nomor",
          },
          { dataKey: "tanggal", label: "Tanggal" },
          { dataKey: "supplier.namaPanjang", label: "Supplier" },
          { dataKey: "keterangan", label: "Keterangan" },
          { dataKey: "jumlah", label: "Jumlah", type: "number" },
        ],
        column: 1,
        customOnChange: (data, setValue) => {
          setValue("permohonanReimburseBiayaSupplier", data)
        },
        isDataView: true,
      },
      {
        id: "voucher.id",
        label: "Kode Voucher",
        type: "async-select",
        resourceUrl: (data, filter) =>
          `voucher?${genBaseSortQueryParam(
            "kodeVoucher"
          )}&${genFieldQueryParamStr({
            "organisasi.id":
              data?.permohonanReimburseBiayaSupplier?.organisasi?.id,
            jenis: "KREDIT",
            isUsingPpn: data?.permohonanReimburseBiayaSupplier?.isPpn,
          })}`,
        resourceMapper: voucherMapper,
        searchBy: voucherSearchKey,
        customOnChange: (data, setValue, getValue) => {
          setValue("voucher", data)
          if (isValueEmpty(getValue("perkiraan.id"))) {
            if (!!data?.perkiraan?.id) {
              setValue("perkiraan", data?.perkiraan)
            } else {
              setValue("perkiraan.id", null)
              setValue("perkiraan", null)
            }
          }
        },
        resourceColumnLabelKey: voucherResourceColumnKeys,
        suffix: {
          id: "permohonanReimburseBiayaSupplier.isPpn",
          disabled: true,
          label: "PPn",
          type: "check",
        },
      },
      {
        id: "tanggalVoucher",
        label: "Tanggal Voucher",
        type: "date",
        value: getToday(),
        isDataView: true,
        suffix: {
          id: "voucher.jenis",
          type: "radio",
          disabled: true,
          label: "Jenis",
          isHalfSize: true,
          options: genOptionFromArray(["DEBIT", "KREDIT"]),
        },
      },
      {
        id: "perkiraan.id",
        label: "Nomor Perkiraan",
        type: "async-select",
        resourceUrl: (data, filter) =>
          `perkiraan?${genFieldQueryParamStr({
            "$or_organisasi.id||organisasi.id": `${data?.permohonanReimburseBiayaSupplier?.organisasi?.id}||null`,
            level: "DETAIL",
          })}`,
        resourceColumnLabelKey: perkiraanResourceColumnKeys,
        disabled: (data, detail) => {
          return !!data?.voucher?.perkiraan?.nomorPerkiraan
        },
        resourceMapper: perkiraanMapper,
        searchBy: perkiraanSearchKey,
      },
      {
        id: "permohonanReimburseBiayaSupplier.tanggal",
        label: "Tanggal Pengajuan",
        type: "date",
        value: getToday(),
        disabled: true,
        suffix: {
          id: "blank",
          type: "blank",
          isHalfSize: true,
          isOptional: true,
        },
      },
      {
        id: "permohonanReimburseBiayaSupplier.supplier.namaPanjang",
        label: "Supplier",
        disabled: true,
        isDataView: true,
      },
      {
        id: "permohonanReimburseBiayaSupplier.keterangan",
        type: "text-area",
        label: "Keterangan",
        isDataView: true,
        disabled: true,
      },
      {
        id: "permohonanReimburseBiayaSupplier.jumlah",
        label: "Jumlah",
        type: "number",
        isDataView: true,
        disabled: true,
        suffix: {
          id: "permohonanReimburseBiayaSupplier.mataUang.mataUang",
          label: "Mata Uang",
          disabled: true,
        },
      },
      {
        id: "isConfirmed",
        label: "Konfirmasi Debit Note",
        type: "check",
        dataViewLabel: "Konfirmasi",
        columnRender(data, filterData, globVars) {
          return <TableCell>{!!data.isConfirmed ? "1" : "0"}</TableCell>
        },
      },
    ]),
  },
  "pengajuan-pembayaran-hutang-gl":
    generatePermohonanPembayaranHutangForm(true),
  "pembayaran-hutang-gl": generatePembayaranHutangForm(),
  "persetujuan-kasbon-biaya-import-gl":
    generatePersetujuanKasbonBiayaImportForm(),
  "penerimaan-komisi": {
    title: "Penerimaan Komisi",
    urls: generateCrudUrl("penerimaan-komisi"),
    permissionRequired: "PenerimaanKomisiSupplier",
    filter: <BaseFilter keyName="penerimaanKomisi" />,
    cetakActions: [],
    recalculateCallback: (rhf) => {
      const data = rhf.watch()

      const totalKomisi =
        data.ItemPenerimaanKomisis?.reduce((total: number, item: any) => {
          return total + strToSafeNum(item.debitNote?.jumlah)
        }, 0) ?? 0
      rhf.setValue("jumlahKomisi", totalKomisi)
      rhf.setValue("maxJumlahKomisi", totalKomisi)

      const baseMataUang =
        data.ItemPenerimaanKomisis?.[0]?.debitNote?.mataUang?.mataUang
      const targetMataUang = data.mataUang?.mataUang
      console.log({
        baseMataUang,
        targetMataUang,
        data: data.mataUang?.mataUang,
      })
      if (!!baseMataUang && !!targetMataUang) {
        calculateValasKurs(baseMataUang, targetMataUang).then((res) => {
          rhf.setValue("rateValas", res)
        })
      }
    },
    generateCodeParam: {
      entity: {},
      entityName: "PenerimaanKomisi",
    },
    ignoreFilterOnFetch: supplierResourceColumnKey.split(","),
    disableAdd(data, filterData) {
      return !filterData?.["supplier.id"]
    },
    requiredToAdd: ["Supplier"],
    formGroupItems: genFormItems([
      {
        id: "supplier.id",
        label: "Supplier",
        type: "async-select",
        resourceUrl: "supplier",
        resourceMapper: supplierOptionMapper,
        searchBy: supplierSearchKey,
        resourceColumnLabelKey: supplierResourceColumnKey,
      },
      {
        id: "nomor",
        disabled: true,
        label: "Nomor Penerimaan Komisi",
        isDataView: true,
        isGeneratedCodeField: true,
        suffix: {
          id: "tanggal",
          label: "Tanggal",
          type: "date",
          value: getToday(),
          isDataView: true,
          isHalfSize: true,
        },
      },
      {
        id: "voucher.id",
        label: "Kode Voucher",
        type: "async-select",
        resourceUrl: `voucher?${genBaseSortQueryParam(
          "kodeVoucher"
        )}&${genFieldQueryParamStr({
          "$or_organisasi.kode||organisasi.id": "B0||null",
          "$or_voucher.jenis||isJurnalPenyesuaian": "DEBIT||true",
        })}`,
        isCallRecalculateCb: true,
        resourceColumnLabelKey: voucherResourceColumnKeys,
        resourceMapper: voucherMapper,
        customOnChange: (data, setValue, getValue) => {
          var oldValue = getValue("voucher")
          setValue("voucher", data)
          setValue("mataUang.mataUang", data.mataUang.mataUang)
          if (isValueEmpty(getValue("perkiraan.id"))) {
            if (
              !data?.perkiraan?.nomorPerkiraan &&
              oldValue.kodeVoucher !== data.kodeVoucher
            ) {
              setValue("perkiraan.id", null)
              setValue("perkiraan.nomorPerkiraan", null)
              setValue("perkiraan.keterangan", null)
            } else {
              setValue("perkiraan", data.perkiraan)
            }
          }
        },
        searchBy: voucherSearchKey,
      },
      {
        id: "voucher.perkiraan.nomorPerkiraan",
        type: "hidden",
      },
      {
        id: "voucher.kodeVoucher",
        type: "hidden",
      },
      {
        id: "perkiraan.id",
        disabled: (data, detail) => {
          return !!data?.voucher?.perkiraan?.nomorPerkiraan
        },
        label: "Nomor Akun",
        type: "async-select",
        resourceUrl: `perkiraan?${genFieldQueryParamStr({
          level: "DETAIL",
        })}`,
        resourceMapper: perkiraanMapper,
        searchBy: perkiraanSearchKey,
        resourceColumnLabelKey: perkiraanResourceColumnKeys,
      },
      {
        id: "maxJumlahKomisi",
        label: "Max Jumlah Komisi",
        type: "hidden",
      },
      {
        id: "jumlahKomisi",
        label: "Jumlah Komisi",
        type: "number",
        isDataView: true,
        value: 0,
        customOnChange: (data, setValue, getValue) => {
          const maxKomisi = getValue("maxJumlahKomisi")

          setValue("biayaBank", +(maxKomisi - data).toFixed(4))
        },
        suffix: {
          id: "biayaBank",
          label: "Biaya Bank",
          type: "number",
          value: 0,
          isDataView: true,
          isHalfSize: true,
          disabled: false,
        },
      },
      {
        id: "mataUang.mataUang",
        label: "Kode Valas",
        type: "async-select",
        resourceUrl: `valuta-asing`,
        resourceMapper: mataUangOptionMapper,
        disabled: (data) => {
          return !data.voucher?.isJurnalPenyesuaian
        },
        isCallRecalculateCb: true,
        isDataView: true,
        suffix: {
          id: "rateValas",
          label: "Nilai Rate",
          type: "number",
          value: 1,
          isHalfSize: true,
          disabled: false,
        },
      },
      {
        id: "pendapatanLain",
        label: "Pendapatan Lain",
        type: "number",
        value: 0,
        suffix: {
          id: "jenisPendapatanLain",
          label: "Debet/Kredit",
          type: "radio",
          value: "DEBIT",
          disabled: false,
          isHalfSize: true,
          options: genOptionFromArray(["DEBIT", "KREDIT"]),
        },
      },
      {
        id: "blank",
        type: "blank",
        isOptional: true,
      },
      {
        id: "keterangan",
        label: "Keterangan",
        type: "text-area",
      },
    ]),
    additionalForms: [
      {
        id: "ItemPenerimaanKomisis",
        title: "Daftar Invoice",
        async validateDataCb(formData, globData, formMode, filters) {
          if (globData.ItemPenerimaanKomisis.length > 0) {
            if (
              formData.debitNote?.mataUang?.mataUang !==
              globData.ItemPenerimaanKomisis[0].debitNote?.mataUang?.mataUang
            ) {
              return "Tidak dapat memilih invoice dengan kode valas berbeda"
            }
          }

          return undefined
        },
        summaryCallback: [
          (items) => {
            const total = items.reduce(
              (total, i) => total + +i.debitNote.jumlah,
              0
            )

            return {
              label: "Total",
              total,
            }
          },
        ],
        formItems: [
          {
            id: "debitNote.id",
            dataViewKey: "debitNote.noDebitNote",
            resourceColumnLabelKey: "noDebitNote",
            label: "Nomor Invoice",
            type: "async-table-select",
            resourceUrl: (data, filter) =>
              `DebitNote?${genFieldQueryParamStr({
                "itemPenerimaanKomisis.id": "null",
                "$or_konfirmasiInvoice.supplier.id||pembayaranReimburseBiayaSupplier.permohonanReimburseBiayaSupplier.supplierReimburse.id": `${filter?.["supplier.id"]}||${filter?.["supplier.id"]}`,
              })}`,
            resourceColumn: [
              {
                dataKey: "noInvoice",
                label: "Nomor Invoice",
                render(data, idx, globVars) {
                  return (
                    <TableCell>
                      {data.tipe === "KOMISI"
                        ? data.noInvoice
                        : data.noDebitNote}
                    </TableCell>
                  )
                },
              },
              {
                dataKey: "tanggal",
                label: "Tanggal",
              },
              {
                dataKey: "keterangan",
                label: "Keterangan",
              },
              {
                dataKey: "jumlah",
                label: "Nilai Komisi",
                type: "number",
              },
              {
                dataKey: "mataUang.mataUang",
                label: "Valas",
              },
              {
                dataKey: "divisi.nama",
                label: "Divisi",
              },
            ],
            column: 1,
            isDataView: true,
            customOnChange: (data, setValue) => {
              setValue("debitNote", data)
            },
          },
          {
            id: "debitNote.keterangan",
            label: "Keterangan",
            type: "text-area",
            disabled: true,
            isDataView: true,
          },
          {
            id: "debitNote.tanggal",
            type: "date",
            label: "Tanggal",
            disabled: true,
            isDataView: true,
            suffix: {
              id: "debitNote.divisi.nama",
              label: "Divisi",
              disabled: true,
              isHalfSize: true,
            },
          },
          {
            id: "debitNote.mataUang.mataUang",
            isIgnored: true,
            label: "Mata Uang",
            isDataView: true,
          },
          {
            id: "debitNote.jumlah",
            type: "number",
            label: "Nilai Komisi",
            disabled: true,
            isDataView: true,
            suffix: {
              id: "debitNote.tipe",
              label: "Jenis Bayar",
              type: "radio",
              disabled: true,
              options: genOptionFromArray(["KOMISI", "REIMBURSE"]),
              isHalfSize: true,
            },
          },
        ],
      },
    ],
  },
  "pindah-tahun": {
    title: "Pindah Tahun",
    filter: <BaseFilter keyName="pindahTahun" />,
    disableAdd(data, filterData) {
      return !filterData?.["tahun"] || !filterData?.["organisasi.id"]
    },
    requiredToAdd: ["Tahun", "Cabang"],
    disablePagination: true,
    formGroupItems: genFormItems([
      {
        id: "tahun",
        label: "Tahun",
        isDataView: true,
        columnRender(data, filterData, globVars) {
          return <TableCell>{+data.tahun - 1}</TableCell>
        },
      },
      {
        id: "perkiraan.nomorPerkiraan",
        label: "Nomor Perkiraan",
        isDataView: true,
      },
      {
        id: "perkiraan.keterangan",
        label: "Keterangan",
        isDataView: true,
      },
      {
        id: "saldo",
        type: "number",
        label: "Saldo Akhir",
        isDataView: true,
      },
    ]),
    hideAdd: true,
    isOnlyViewData: true,
    urls: {
      ...generateCrudUrl("pindah-tahun"),
      read: {
        endpoint: "/saldo-awal-organisasi",
        method: "get",
      },
    },
    permissionRequired: "PindahTahun",
    pageActions: [
      {
        label: "Pindah Tahun",
        action: async (filter: any) => {
          await postAPI("/pindah-tahun", {
            organisasiId: filter?.["organisasi.id"],
            tahun: filter?.["tahun"],
            isPpn: filter?.["isPpn"],
          })
        },
        confirmMsg: "Apakah anda yakin untuk melakukan pindah tahun?",
      },
    ],
  },
  "tutup-buku": {
    title: "Tutup Buku",
    cetakActions: [],
    responseMapper(data) {
      const tahun = data?.periode?.toString()?.slice(0, 4)
      const bulan = data?.periode?.toString()?.slice(4, 6)

      return {
        ...data,
        tahun,
        bulan,
      }
    },
    customFormDataMapper(data, filterData) {
      const { tahun, bulan, ...restData } = data

      return {
        ...restData,
        periode: parseInt(`${tahun}${String(bulan).padStart(2, "0")}`),
      }
    },
    filter: <BaseFilter keyName="tutupBuku" />,
    disableAdd(data, filterData) {
      return (
        !filterData?.["periode_$btwStart"] || !filterData?.["periode_$btwEnd"]
      )
    },
    requiredToAdd: ["Tahun"],
    formGroupItems: genFormItems([
      {
        id: "bulan",
        type: "select",
        label: "Bulan",
        isDataView: true,
        columnRender: (data) => {
          return (
            // @ts-ignore
            <Text fontSize="14px" fontWeight={600}>
              {LOCALE_MONTHS[data.bulan - 1]}
            </Text>
          )
        },
        options: LOCALE_MONTHS.map((bln, key) => ({
          label: bln,
          value: key + 1,
        })),
        value: new Date().getMonth() + 1,
      },
      {
        id: "tahun",
        type: "numeric",
        label: "Tahun",
        isDataView: true,
        disabled: true,
        value: (data, user, filter) => {
          console.log({ filter })
          return (
            filter?.["periode_$btwStart"]?.toString()?.slice(0, 4) ||
            new Date().getFullYear()
          )
        },
      },
    ]),
    disablePagination: true,
    urls: {
      ...generateCrudUrl("tutup-buku"),
      read: {
        method: "get",
        endpoint: "/tutup-buku",
        param: {
          sortBy: "periode",
          sortType: "desc",
        },
      },
    },
    permissionRequired: "TutupBuku",
  },
}

export const GLBulkPages: GeneralMap<BulkPageProps> = {
  "pembayaran-kb": {
    title: "Pembayaran KB",
    permissionRequired: "PembayaranKb",
    actionLabel: "Dibayar",
    bulkReqKey: "pembyaranKbs",
    filterName: "pembayaranKb",
    bulkDataKey: "tanggalBayar",
    ignoreFilterOnFetch: ["isAllValas"],
    excelUrl: "/pengajuan-kb/download/excel",
    urls: {
      read: {
        endpoint: `/pengajuan-kb`,
        method: "get",
        param: genFieldQueryParamObj({
          profit_$gt: "$field_pengajuanKb.nilaiKb",
          "pengajuanKb.nilaiKbRp_$gt": 49999.9999,
        }),
      },
      update: {
        endpoint: `/pengajuan-kb/bulk-pembayaran-kb`,
        method: "post",
      },
    },
    responseMapper(data) {
      return {
        ...data,
        itemPengajuanKbs:
          data.itemPengajuanKbs?.map((i: any) => ({
            ...i,
            rekeningKb: !i.rekeningKb
              ? {
                  id: "0",
                  norek: "Diambil Langsung",
                  namaRekening: "Sales/Marketing",
                }
              : i.rekeningKb,
          })) ?? [],
      }
    },
    formItems: [
      {
        dataKey: "tanggal",
        label: "Tanggal",
      },
      {
        dataKey: "nomor",
        label: "No. Bayar KB",
      },
      {
        dataKey: "invoicePelanggan.penawaran.pelanggan.namaPanjang",
        label: "Customer",
      },
      {
        dataKey: "invoicePelanggan.penawaran.salesContracts.0.noPoPelanggan",
        label: "No. PO",
      },
      {
        dataKey: "invoicePelanggan.penawaran.no",
        label: "No. Penawaran",
      },
      {
        dataKey: "invoicePelanggan.penawaran.tipe",
        label: "Tipe",
      },
      {
        dataKey: "nilaiKbRp",
        label: "Nilai KB",
        type: "number",
      },
      {
        dataKey: "nilaiKbPengajuan",
        label: "Nilai KB Diajukan",
        type: "number",
      },
    ],
    additionalForms: [
      {
        id: "itemPengajuanKbs",
        title: "Daftar Rekening",
        formItems: [
          {
            dataKey: "rekeningKb.norek",
            label: "No. Rekening",
          },
          {
            dataKey: "rekeningKb.namaBank",
            label: "Nama Bank",
          },
          {
            dataKey: "rekeningKb.namaRekening",
            label: "Atas Nama",
          },
          {
            dataKey: "jumlah",
            label: "Jumlah Kb",
            type: "number",
          },
        ],
      },
    ],
    bulkRequestDataMapper(data, updateValue) {
      return {
        pembayaranKbs: data.map((i) => ({
          id: i.id,
          isBayar: updateValue[i.id],
        })),
      }
    },
    requiredFilterKey: [
      "invoicePelanggan.organisasi.id",
      "pengajuanKb.tanggalPengajuan_$btwEnd",
      "pengajuanKb.tanggalPengajuan_$btwStart",
    ],
  },
  // TODO: create common function for transfer page
  "transfer-pembelian": genTransferGLForm("PEMBELIAN", [
    {
      dataKey: "noBapb",
      label: "No. BAPB",
    },
    {
      dataKey: "tglBapb",
      label: "Trm Invoice",
      type: "date",
    },
    {
      dataKey: "noInvoice",
      label: "No. Invoice",
    },
    {
      dataKey: "supplierName",
      label: "Supplier",
    },
    {
      dataKey: "noHutang",
      label: "No. Hutang",
    },
    {
      dataKey: "namaPerHutang",
      label: "Nama Per Hutang",
    },
    {
      dataKey: "jumlah",
      label: "Jumlah Hutang",
      type: "number",
    },
    {
      dataKey: "noPerJurnal",
      label: "No. Perkiraan",
    },
    {
      dataKey: "namaPerJurnal",
      label: "Nama Perkiraan",
    },
    {
      dataKey: "kodeVoucher",
      label: "Kode Voucher",
    },
    {
      dataKey: "noVoucher",
      label: "No. Voucher",
    },
    {
      dataKey: "tglTransferGL",
      label: "Tanggal Transfer",
      type: "date",
    },
    {
      dataKey: "isTransfer",
      label: "Transfer",
    },
  ]),
  "transfer-biaya-import": genTransferGLForm("BIAYA_IMPORT", [
    {
      dataKey: "jenis",
      label: "Jenis Transaksi",
      sortable: true,
    },
    {
      dataKey: "nomor",
      label: "Nomor",
      sortable: true,
    },
    {
      dataKey: "tanggal",
      label: "Tanggal",
      type: "date",
      sortable: true,
    },
    {
      dataKey: "namaCustomer",
      label: "Customers",
      sortable: true,
    },
    {
      dataKey: "namaSupplier",
      label: "Supplier",
      sortable: true,
    },
    {
      dataKey: "kodeVoucher",
      label: "Voucher",
      sortable: true,
    },
    {
      dataKey: "noPerkiraan",
      label: "No. Perkiraan",
      sortable: true,
    },
    {
      dataKey: "namaPerkiraan",
      label: "Nama Perkiraan",
      sortable: true,
    },
    {
      dataKey: "jumlah",
      label: "Jumlah",
      type: "number",
      sortable: true,
    },
    {
      dataKey: "noVoucher",
      label: "No. Voucher",
      sortable: true,
    },
    {
      dataKey: "tglTransferGL",
      label: "Tgl Transfer",
      type: "date",
      sortable: true,
    },
  ]),
  "transfer-pembayaran-hutang": genTransferGLForm("PEMBAYARAN_HUTANG", [
    {
      dataKey: "tipe",
      label: "Keter",
      sortable: true,
    },
    {
      dataKey: "no",
      label: "Nomor",
      sortable: true,
    },
    {
      dataKey: "tanggal",
      label: "Tanggal",
      type: "date",
      sortable: true,
    },
    {
      dataKey: "namaSupplier",
      label: "Supplier",
      sortable: true,
    },
    {
      dataKey: "noPerHutang",
      label: "No. Hutang",
      sortable: true,
    },
    {
      dataKey: "namaPerHutang",
      label: "Nama Per Hutang",
      sortable: true,
    },
    {
      dataKey: "jumlah",
      label: "Jumlah Bayar",
      type: "number",
      sortable: true,
    },
    {
      dataKey: "biayaBank",
      label: "Biaya Bank",
      type: "number",
      sortable: true,
    },
    {
      dataKey: "noAkun",
      label: "Nomor Perkiraan",
      sortable: true,
    },
    {
      dataKey: "namaAkun",
      label: "Nama Perkiraan",
      sortable: true,
    },
    {
      dataKey: "kodeVoucher",
      label: "Kode Voucher",
      sortable: true,
    },
    {
      dataKey: "noVoucher",
      label: "No. Voucher",
      sortable: true,
    },
    {
      dataKey: "tglTransferGL",
      label: "Tanggal Transfer",
      type: "date",
      sortable: true,
    },
  ]),
  "transfer-penjualan": genTransferGLForm("PENJUALAN", [
    {
      dataKey: "tipePenawaran",
      label: "Tipe",
    },
    {
      dataKey: "noInvoice",
      label: "No. Invoice",
    },
    {
      dataKey: "tanggal",
      label: "Tgl Krm Invoice",
      type: "date",
    },
    {
      dataKey: "noSuratJalan",
      label: "No. SJ",
    },
    {
      dataKey: "namaPelanggan",
      label: "Customers",
    },
    {
      dataKey: "noPerPiutang",
      label: "No. Piutang",
    },
    {
      dataKey: "jumlah",
      label: "Jumlah Piutang",
      type: "number",
    },
    {
      dataKey: "ppn",
      label: "PPN",
      type: "number",
    },
    {
      dataKey: "kb",
      label: "KB(%)",
      type: "number",
    },
    {
      dataKey: "noPerkiraan",
      label: "No. Perkiraan",
    },
    {
      dataKey: "noVoucher",
      label: "Voucher",
    },
    {
      dataKey: "noVoucherHpp",
      label: "Voucher HPP",
    },
    {
      dataKey: "noVoucherKb",
      label: "Voucher KB",
    },
    {
      dataKey: "tglTransferGL",
      label: "Tgl Transfer",
      type: "date",
    },
  ]),
  "transfer-retur": genTransferGLForm("RETUR_PENJUALAN", [
    {
      dataKey: "noRetur",
      label: "No. Retur",
    },
    {
      dataKey: "tanggal",
      label: "Tgl Retur",
      type: "date",
    },
    {
      dataKey: "noSuratJalan",
      label: "No. SJ",
    },
    {
      dataKey: "namaPelanggan",
      label: "Customers",
    },
    {
      dataKey: "noPerPiutang",
      label: "No. Piutang",
    },
    {
      dataKey: "jumlah",
      label: "Jumlah Piutang",
      type: "number",
    },
    {
      dataKey: "noPerkiraan",
      label: "No. Perkiraan",
    },
    {
      dataKey: "kodeVoucher",
      label: "Voucher",
    },
    {
      dataKey: "noVoucher",
      label: "No. Voucher",
    },
    {
      dataKey: "noVoucherKb",
      label: "No. Voucher KB",
    },
    {
      dataKey: "noVoucherHpp",
      label: "Voucher HPP",
    },
    {
      dataKey: "tglTransferGL",
      label: "Tanggal Transfer",
      type: "date",
    },
  ]),
  "transfer-pelunasan": genTransferGLForm("PELUNASAN", [
    {
      dataKey: "noPelunasan",
      label: "No. Pelunasan",
    },
    {
      dataKey: "tanggal",
      label: "Tanggal",
      type: "date",
    },
    {
      dataKey: "noInvoice",
      label: "No. Invoice",
    },
    {
      dataKey: "namaPelanggan",
      label: "Customers",
    },
    {
      dataKey: "noPerPiutang",
      label: "No. Piutang",
    },
    {
      dataKey: "jumlah",
      label: "Jumlah Bayar",
      type: "number",
    },
    {
      dataKey: "noPerkiraan",
      label: "No. Perkiraan",
    },
    {
      dataKey: "namaPerkiraan",
      label: "Nama Perkiraan",
    },
    {
      dataKey: "kodeVoucher",
      label: "Kode",
    },
    {
      dataKey: "noVoucher",
      label: "No. Voucher",
    },
    {
      dataKey: "tglTransferGL",
      label: "Tanggal Transfer",
    },
  ]),
  "transfer-kasbon-karyawan": genTransferGLForm("KASBON", [
    {
      dataKey: "jenis",
      label: "Jenis Transaksi",
    },
    {
      dataKey: "nomor",
      label: "Nomor",
    },
    {
      dataKey: "tanggal",
      label: "Tanggal",
      type: "date",
    },
    {
      dataKey: "namaKaryawan",
      label: "Karyawan",
    },
    {
      dataKey: "keterangan",
      label: "Keterangan",
    },
    {
      dataKey: "kodeVoucher",
      label: "Voucher",
    },
    {
      dataKey: "noPerkiraan",
      label: "No. Perkiraan",
    },
    {
      dataKey: "namaPerkiraan",
      label: "Nama Perkiraan",
    },
    {
      dataKey: "jumlah",
      label: "Jumlah",
      type: "number",
    },
    {
      dataKey: "noVoucher",
      label: "No. Voucher",
    },
    {
      dataKey: "tglTransferGL",
      label: "Tanggal Transfer",
      type: "date",
    },
  ]),
  posting: {
    title: "Posting",
    permissionRequired: "PostingTransaksi",
    disablePagination: true,
    filterName: "postingFilter",
    isHighlightRowCb(data) {
      return data.keterangan === "TOTAL"
    },
    urls: {
      read: {
        endpoint: `/jurnal/tipe/POSTING`,
        method: "get",
        param: {
          ...genFieldQueryParamObj({
            tglTransferGL: "notNull",
            tglPosting: "null",
          }),
          ...genBaseSortQueryParamObj("id", true),
        },
      },
      update: {
        endpoint: "/posting-transaksi/posting/bulk-update",
        method: "post",
      },
    },
    bulkReqKey: "data",
    isAutoAddAll: true,
    formItems: POSTING_TRANSAKSI_COLUMNS,
    actions: [
      {
        color: "blue",
        label: "Posting",
        action: (data, updateValue) => postingBodyRequestMapper(data, true),
      },
    ],
  },
  unposting: {
    title: "Unposting",
    permissionRequired: "PostingTransaksi",
    filterName: "postingFilter",
    disablePagination: true,
    urls: {
      read: {
        endpoint: `/jurnal/tipe/POSTING`,
        method: "get",
        param: {
          ...genFieldQueryParamObj({
            tglTransferGL: "notNull",
            tglPosting: "notNull",
          }),
          ...genBaseSortQueryParamObj("id", true),
        },
      },
      update: {
        endpoint: "/posting-transaksi/posting/bulk-update",
        method: "post",
      },
    },
    bulkReqKey: "data",
    actions: [
      {
        color: "red",
        label: "Un-Posting",
        action: (data, updateValue) => postingBodyRequestMapper(data, false),
      },
    ],
    isAutoAddAll: true,
    isHighlightRowCb(data) {
      return data.keterangan === "TOTAL"
    },
    formItems: POSTING_TRANSAKSI_COLUMNS,
  },
}

export const GLPageKeys = [...Object.keys(GLPages), ...Object.keys(GLBulkPages)]
