import { BasePageFormGroupItem } from "components/page/type"

function generateItemServiceFormItems(): BasePageFormGroupItem[] {
  return [
    {
      id: "namaBarang",
      label: "Barang",
      isDataView: true,
    },
    {
      id: "qty",
      label: "Kuantitas",
      type: "number",
      isDataView: true,
    },
    {
      id: "ongkos",
      label: "Ongkos Service",
      type: "number",
      isDataView: true,
      renderCondition(formData, formMode, filterData) {
        return !filterData?.["isKirimSupplier"]
      },
    },
    {
      id: "ongkosRp",
      label: "Ongkos Service",
      type: "number",
      value: 0,
      renderCondition(formData, formMode, filterData) {
        return !!filterData?.["isKirimSupplier"]
      },
      customOnChange: (
        data,
        setValue,
        getValue,
        genCode,
        globVars,
        triggerPreCheck,
        globDetail
      ) => {
        setValue("ongkos", +data / (!globDetail.rate ? 1 : +globDetail.rate))
      },
      suffix: {
        id: "ongkos",
        label: "Konversi",
        disabled: true,
        value: 0,
        type: "number",
      },
    },
    {
      id: "ongkosSupplier",
      label: "Ongkos Supplier",
      type: "number",
      isDataView: true,
      value: 0,
      renderCondition(formData, formMode, filterData) {
        return !!filterData?.["isKirimSupplier"]
      },
    },
    {
      id: "keterangan",
      label: "Keterangan (SN)",
      type: "text-area",
      isOptional: true,
    },
  ]
}

export default generateItemServiceFormItems
