import {
  formatNumber,
  generateCrudUrl,
  genFieldQueryParamStr,
  genFormItems,
  genOptionFromArray,
  getToday,
  isValueEmpty,
  strToSafeNum,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import {
  karyawanOptionMapper,
  karyawanResourceColumnKey,
  perkiraanMapper,
  perkiraanResourceColumnKeys,
  perkiraanSearchKey,
  subDivisiKeuanganResourceColumnKey,
  voucherMapper,
  voucherResourceColumnKeys,
  voucherSearchKey,
} from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"

function generateJurnalUmumForms(): BasePageProps<any> {
  return {
    title: "Biaya Operasional / Jurnal Umum",
    permissionRequired: "JurnalUmum",
    urls: {
      ...generateCrudUrl("JurnalUmum"),
    },
    filter: <BaseFilter keyName="glCabangPeriode" />,
    generateCodeParam: (data, filter) => ({
      entityName: "JurnalUmum",
      entity: {
        organisasi: {
          id: filter?.["organisasi.id"],
        },
      },
    }),
    requiredToAdd: ["Cabang"],
    disableAdd(data, filterData) {
      return !filterData?.["organisasi.id"]
    },
    formGroupItems: genFormItems([
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "hidden",
        customOnChange: (data, setValue, getValue, genCode) => {
          setValue("organisasi", data)
          genCode()
        },
        resourceUrl: `organisasi?${genBaseSortQueryParam()}`,
      },
      {
        id: "nomor",
        disabled: true,
        label: "Nomor",
        isDataView: true,
        isGeneratedCodeField: true,
        suffix: {
          id: "tanggal",
          label: "Tanggal",
          value: getToday(),
          isDataView: true,
          isHalfSize: true,
        },
      },
      {
        id: "blank",
        type: "blank",
      },
      {
        id: "voucher.id",
        label: "Voucher",
        type: "async-select",
        resourceMapper: voucherMapper,
        searchBy: voucherSearchKey,
        resourceColumnLabelKey: voucherResourceColumnKeys,
        resourceUrl: (data, filter) =>
          `voucher?${genBaseSortQueryParam(
            "kodeVoucher"
          )}&${genFieldQueryParamStr({
            "organisasi.id": filter?.["organisasi.id"],
          })}`,
        customOnChange: (data, setValue, getValue) => {
          setValue("voucher", data)
          if (isValueEmpty(getValue("perkiraan.id"))) {
            if (!!data?.perkiraan?.id) {
              setValue("perkiraan", data?.perkiraan)
            } else {
              setValue("perkiraan.id", null)
            }
          }
          setValue("jenis", data?.jenis)
        },
      },
      {
        id: "perkiraan.id",
        disabled: (data, detail) => {
          return !!data?.voucher?.perkiraan?.nomorPerkiraan
        },
        label: "Nomor Perkiraan",
        type: "async-select",
        resourceUrl: `perkiraan?${genBaseSortQueryParam("nomorPerkiraan")}`,
        resourceMapper: perkiraanMapper,
        searchBy: perkiraanSearchKey,
        resourceColumnLabelKey: perkiraanResourceColumnKeys,
      },
      {
        id: "jenis",
        type: "radio",
        disabled: true,
        label: "Jenis",
        options: genOptionFromArray(["DEBIT", "KREDIT"]),
        suffix: {
          id: "voucher.isUsingPpn",
          disabled: true,
          label: "PPN",
          type: "check",
          isHalfSize: true,
        },
      },
      {
        id: "blank",
        type: "blank",
      },
      {
        id: "karyawan.id",
        label: "Karyawan",
        type: "async-select",
        resourceUrl: `karyawan?${genBaseSortQueryParam(
          "kode"
        )}&${genFieldQueryParamStr({ jenisJabatan: "KEUANGAN" })}`,
        resourceColumnLabelKey: karyawanResourceColumnKey,
        customOnChange: (data, setValue, getValue) => {
          setValue("karyawan", data)
          const divisiId = data.divisi.id
          if (isValueEmpty(getValue("divisi.id"))) {
            setValue("divisi.id", divisiId)
          }
        },
        dataViewLabel: "Nama Karyawan",
        dataViewKey: "karyawan.nama",
      },
      {
        id: "jumlah",
        label: "Jumlah",
        type: "number",
        value: 0,
      },
      {
        id: "keterangan",
        type: "text-area",
        isDataView: true,
        label: "Keterangan",
      },

      {
        id: "divisi.id",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
        label: "Divisi",
        customOnChange: (data, setValue) => {
          setValue("divisi", data)
          setValue("subDivisiKeuangan", null)
          const divisiId = data.id
          if (divisiId === "1") {
            setValue("subDivisiKeuangan.id", "11")
            setValue("subDivisiKeuangan.kode", "3")
            setValue("subDivisiKeuangan.nama", "BELTING")
          } else if (divisiId === "2") {
            setValue("subDivisiKeuangan.id", "12")
            setValue("subDivisiKeuangan.kode", "8")
            setValue("subDivisiKeuangan.nama", "TEXTILE")
          } else if (divisiId === "3") {
            setValue("subDivisiKeuangan.id", "5")
            setValue("subDivisiKeuangan.kode", "1")
            setValue("subDivisiKeuangan.nama", "ADMINISTRASI")
          }
        },
        suffix: {
          id: "subDivisiKeuangan.id",
          type: "async-select",
          disabled: false,
          resourceColumnLabelKey:
            "subDivisiKeuangan.kode,subDivisiKeuangan.nama",
          resourceUrl: (data) => {
            if (!data.divisi?.id) {
              return `sub-divisi-keuangan?${genBaseSortQueryParam()}`
            }
            return `sub-divisi-keuangan?${genFieldQueryParamStr({
              "divisiKeuangan.id": data.divisi?.id,
            })}&${genBaseSortQueryParam()}`
          },
          label: "Sub Divisi",
          isHalfSize: true,
        },
      },
    ]),
    additionalForms: [
      {
        id: "itemJurnalUmums",
        title: "Daftar Item Jurnal Umum",
        summaryCallback: [
          (items) => {
            return {
              label: "TOTAL",
              total: formatNumber(
                items.reduce(
                  (total, item) =>
                    total + +item.jumlah * (item.jenis === "KREDIT" ? -1 : 1),
                  0
                )
              ),
            }
          },
        ],
        formItems: [
          {
            id: "perkiraan.id",
            label: "Nomor Perkiraan",
            type: "async-select",
            resourceUrl: (data, filter) =>
              `perkiraan?${genFieldQueryParamStr({
                "$or_organisasi.id||organisasi.id": `${filter?.["organisasi.id"]}||null`,
              })}&${genBaseSortQueryParam("nomorPerkiraan")}`,
            resourceMapper: perkiraanMapper,
            resourceColumnLabelKey: perkiraanResourceColumnKeys,
            dataViewKey: "perkiraan.nomorPerkiraan",
            isDataView: true,
            customOnChange: (data, setValue) => {
              setValue("perkiraan", data)
            },
            searchBy: "nomorPerkiraan,keterangan",
          },
          {
            id: "divisi.id",
            type: "async-select",
            resourceUrl: `divisi?${genBaseSortQueryParam()}`,
            label: "Divisi",
            customOnChange: (data, setValue) => {
              setValue("divisi", data)
              setValue("subDivisiKeuangan", null)
              const divisiId = data.id
              if (divisiId === "1") {
                setValue("subDivisiKeuangan.id", "11")
                setValue("subDivisiKeuangan.kode", "3")
                setValue("subDivisiKeuangan.nama", "BELTING")
              } else if (divisiId === "2") {
                setValue("subDivisiKeuangan.id", "12")
                setValue("subDivisiKeuangan.kode", "8")
                setValue("subDivisiKeuangan.nama", "TEXTILE")
              } else if (divisiId === "3") {
                setValue("subDivisiKeuangan.id", "5")
                setValue("subDivisiKeuangan.kode", "1")
                setValue("subDivisiKeuangan.nama", "ADMINISTRASI")
              }
            },
            suffix: {
              id: "subDivisiKeuangan.id",
              type: "async-select",
              label: "Sub Divisi",
              isHalfSize: true,
              resourceColumnLabelKey: subDivisiKeuanganResourceColumnKey,
              resourceUrl: (data) => {
                if (!data.divisi?.id) {
                  return `sub-divisi-keuangan?${genBaseSortQueryParam()}`
                }
                return `sub-divisi-keuangan?${genFieldQueryParamStr({
                  "divisiKeuangan.id": data.divisi.id,
                })}&${genBaseSortQueryParam()}`
              },
            },
          },
          {
            id: "jumlah",
            label: "Jumlah",
            type: "number",
          },
          {
            id: "keterangan",
            label: "Keterangan",
            type: "text-area",
            dataViewLabel: "Deskripsi",
          },
          {
            id: "jenis",
            type: "radio",
            label: "Jenis",
            options: genOptionFromArray(["DEBIT", "KREDIT"]),
            isDataView: true,
          },
          {
            id: "jumlah",
            label: "Jumlah",
            type: "number",
            isIgnored: true,
            isDataView: true,
          },
        ],
      },
    ],
  }
}

export default generateJurnalUmumForms
