import generateLampiranKontrakForm from "common/form-generator/lampiran-kontrak"
import generatePenawaranForms from "common/form-generator/penawaran"
import generatePurchaseOrderForm from "common/form-generator/purchase-order"
import generateSalesContractForm from "common/form-generator/sales-contract"
import { GeneralMap, PenawaranTypeEnum, PoTypeEnum } from "common/type"
import {
  formatNumber,
  generateCrudUrl,
  genFieldQueryParamObj,
  genFieldQueryParamStr,
  genFormItems,
  getToday,
} from "common/util"
import { BasePageProps } from "components/page/type"
import { Text } from "@chakra-ui/react"
import BaseFilter from "components/filter/_base"
import generateInformasiPOForm, {
  InformasiPOType,
} from "common/form-generator/informasi-po"
import { genBaseSortQueryParam } from "constant/url"
import {
  supplierOptionMapper,
  supplierResourceColumnKey,
  supplierSearchKey,
} from "constant/mapper"
import { ignoredSupplierFilter } from "constant/filters"

function generateItemPOUniqueKey(itemPO: any): string {
  return `${itemPO.id}-${itemPO.itemPenawaran?.barang?.id}`
}

export const TTLCPages: GeneralMap<BasePageProps<any>> = {
  "penawaran-ttlc": generatePenawaranForms(PenawaranTypeEnum.TTLC),
  "sales-contract-ttlc": generateSalesContractForm(PenawaranTypeEnum.TTLC),
  "lampiran-kontrak-ttlc": generateLampiranKontrakForm(PenawaranTypeEnum.TTLC),
  "purchase-order-ttlc": generatePurchaseOrderForm(PoTypeEnum.TTLC),
  "konfirmasi-invoice": {
    permissionRequired: "KonfirmasiInvoice",
    title: "Konfirmasi Invoice",
    filter: <BaseFilter keyName="konfirmasiInvoice" />,
    urls: generateCrudUrl("konfirmasi-invoice"),
    generateCodeParam: {
      entityName: "KonfirmasiInvoice",
      entity: {},
    },
    requiredToAdd: ["supplier"],
    disableAdd(data, filterData) {
      return !filterData?.["supplier.id"]
    },
    ignoreFilterOnFetch: ignoredSupplierFilter,
    formGroupItems: genFormItems([
      {
        id: "supplier.id",
        label: "Supplier",
        type: "async-select",
        resourceUrl: `supplier?${genBaseSortQueryParam("namaPanjang")}`,
        resourceMapper: supplierOptionMapper,
        resourceColumnLabelKey: supplierResourceColumnKey,
        searchBy: supplierSearchKey,
      },
      {
        id: "no",
        label: "Nomor",
        disabled: true,
        isGeneratedCodeField: true,
        isDataView: true,
      },
      {
        id: "noInvoice",
        label: "Nomor Invoice",
        isDataView: true,
      },
      {
        id: "tanggal",
        type: "date",
        label: "Tanggal",
        value: getToday(),
        isDataView: true,
        suffix: {
          id: "isKonfirmasiDebitNote",
          type: "check",
          label: "Konfirmasi Debit Note",
          isHalfSize: true,
        },
      },
      {
        id: "keterangan",
        type: "text-area",
        label: "Keterangan",
        isDataView: true,
      },
    ]),
    additionalForms: [
      {
        id: "itemKonfirmasiInvoices",
        title: "Daftar Barang",
        async validateDataCb(formData, globData) {
          const existItemPOIDs = globData?.itemKonfirmasiInvoices?.map(
            (i: any) => generateItemPOUniqueKey(i.itemPurchaseOrderStock)
          )

          if (!formData.xId) {
            if (
              existItemPOIDs.includes(
                generateItemPOUniqueKey(formData.itemPurchaseOrderStock)
              )
            ) {
              return "Barang PO sudah ditambah, silahlkan edit data yang sudah ditambahkan"
            }
          }
        },
        formItems: [
          {
            id: "itemPurchaseOrderStock.id",
            column: 1,
            label: "Barang(Nomor Purchase Order)",
            type: "async-table-select",
            resourceUrl: (data, filter) =>
              `item-purchase-order-stock?${genFieldQueryParamStr({
                itemPenawaran: "notNull",
                purchaseOrderStock: "notNull",
                "itemPenawaran.penawaran.tipe": "TTLC",
                sisaBarang_$gt: 0,
                "purchaseOrderStock.supplier.id": filter?.["supplier.id"],
              })}`,
            resourceColumnLabelKey: "purchaseOrderStock.noPo",
            resourceColumn: [
              {
                dataKey: "purchaseOrderStock.noPo",
                label: "No. PO",
              },
              {
                dataKey: "itemPenawaran.barang.kode",
                label: "Kode Barang",
              },
              {
                dataKey: "itemPenawaran.barang.nama",
                label: "Nama Barang",
              },
              {
                dataKey: "sisaBarang",
                label: "Kuantitas",
              },
              {
                dataKey: "itemPenawaran.hargaJual",
                label: "Harga",
                type: "number",
              },
              {
                dataKey: "itemPenawaran.penawaran.mataUang.mataUang",
                label: "Valas",
              },
            ],
            customOnChange: (data, setValue, getValue) => {
              setValue("itemPurchaseOrderStock", data)
              if (!getValue("qty")) {
                setValue("qty", data.sisaBarang)
              }
            },
            dataViewLabel: "No. PO",
            dataViewKey: "itemPurchaseOrderStock.purchaseOrderStock.noPo",
          },
          {
            id: "itemPurchaseOrderStock.itemPenawaran.penawaran.divisi.nama",
            label: "Divisi",
            disabled: true,
            suffix: {
              id: "itemPurchaseOrderStock.itemPenawaran.barang.kode",
              label: "Kode Barang",
              disabled: true,
              isDataView: true,
              isHalfSize: true,
            },
          },

          {
            id: "itemPurchaseOrderStock.itemPenawaran.barang.nama",
            label: "Nama Barang",
            disabled: true,
            isDataView: true,
          },
          {
            id: "itemPurchaseOrderStock.itemPenawaran.hargaJual",
            label: "Harga",
            type: "number",
            disabled: true,
            isDataView: true,
            suffix: {
              id: "itemPurchaseOrderStock.purchaseOrderStock.mataUang.mataUang",
              label: "Valas",
              disabled: true,
              isDataView: true,
            },
          },
          {
            id: "qty",
            type: "number",
            label: "Kuantitas",
            isDataView: true,
            formRule(data, detail) {
              const max = data.itemPurchaseOrderStock?.sisaBarang ?? 0
              return {
                max: {
                  value: data.itemPurchaseOrderStock?.sisaBarang,
                  message: `Quantity tidak boleh lebih dari ${max}`,
                },
              }
            },
            suffix: {
              id: "blank",
              isOptional: true,
              isHalfSize: true,
              type: "blank",
            },
          },
          {
            id: "nilaiKomisi",
            type: "number",
            label: "Komisi",
            isIgnored: true,
            isDataView: true,
          },
          {
            id: "itemPurchaseOrderStock.itemPenawaran.penawaran.pelanggan.namaPanjang",
            label: "Customer",
            type: "ignored",
            isDataView: true,
          },
        ],
      },
    ],
  },
  "informasi-po": generateInformasiPOForm(InformasiPOType.TTLC),
  "pembatalan-sc": {
    disableAdd: (data, filterData) =>
      !filterData?.["organisasi.id"] || !filterData?.["divisi.id"],
    hideAdd: true,
    requiredToAdd: ["Cabang", "Divisi"],
    filter: <BaseFilter keyName="pembatalanSC" />,
    permissionRequired: "SalesContract",
    title: "Pembatalan Sales Contract",
    // TODO: get sales contract by status
    urls: {
      ...generateCrudUrl("sales-contract"),
      read: {
        endpoint: `/sales-contract`,
        method: "get",
        param: genFieldQueryParamObj({
          "penawaran.tipe": PenawaranTypeEnum.TTLC,
          "salesContract.status_$In": "BARU;BATAL;NOT_READY",
          "purchaseOrders.id": "null",
        }),
      },
    },
    customAction: {
      label: "Batalkan SC",
      color: "red",
      preventView: true,
    },
    cetakActions: [],
    customFormDataMapper: (data) => {
      return {
        ...data,
        status: "BATAL",
      }
    },
    formGroupItems: genFormItems([
      {
        id: "noSalesContract",
        label: "No. Sales Contract",
        isDataView: true,
        disabled: true,
      },
      {
        id: "penawaran.no",
        label: "Nomor Penawaran",
        isDataView: true,
        disabled: true,
      },
      {
        id: "tanggal",
        label: "Tanggal",
        type: "date",
        disabled: true,
        dataViewLabel: "Tanggal Sales Kontrak",
      },
      {
        id: "penawaran.sales.nama",
        label: "Nama Sales",
        disabled: true,
        isDataView: true,
      },
      {
        id: "noPoPelanggan",
        label: "PO Customer",
        disabled: true,
        isDataView: true,
      },
      {
        id: "penawaran.pelanggan.namaPanjang",
        label: "Customer",
        disabled: true,
        isDataView: true,
      },
      {
        id: "penawaran.tipe",
        label: "Jenis Sales Contract",
        disabled: true,
      },
      {
        id: "status",
        label: "Status",
        disabled: true,
        isDataView: true,
        columnRender: (data) => (
          // @ts-ignore
          <Text fontSize="14px" fontWeight="600">
            {data.status === "BATAL" ? "Dibatalkan" : "Aktif"}
          </Text>
        ),
      },
    ]),
    additionalForms: [
      {
        id: "penawaran.itemPenawarans",
        title: "Barang",
        hideAdd: true,
        formItems: [
          {
            id: "barang.kode",
            label: "Kode Barang",
            disabled: true,
            isDataView: true,
          },
          {
            id: "barang.nama",
            label: "Nama Barang",
            disabled: true,
            isDataView: true,
          },
          {
            id: "size",
            label: "Size",
            disabled: true,
            isDataView: true,
            type: "ignored",
            columnRender: (data) => (
              <Text fontSize="14px" fontWeight="600">
                {formatNumber(data.spesifikasi.panjang)} x{" "}
                {formatNumber(data.spesifikasi.lebar)}
              </Text>
            ),
          },
          {
            id: "qty",
            label: "Kuantitas",
            disabled: true,
            isDataView: true,
          },
          {
            id: "keterangan",
            label: "Keterangan",
            disabled: true,
            isDataView: true,
          },
        ],
      },
    ],
  },
}

export const TTLCPageKeys = Object.keys(TTLCPages)
