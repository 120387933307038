import _ from "lodash"
import { useEffect, useRef } from "react"

function deepCompareEquals(a: any, b: any) {
  return _.isEqual(a, b)
}

function useDeepCompareMemoize(value: any) {
  const ref = useRef()
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export function useDeepCompareEffect(
  callback: () => void,
  dependencies: any[]
) {
  useEffect(callback, [...dependencies.map(useDeepCompareMemoize), callback])
}
