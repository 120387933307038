import AdminLayout from "components/layout/admin"
import AuthenticatedPage from "components/route/authenticated"
import AuthContextProvider from "context/auth.context"
import React from "react"

export function withAuth<T>(Component: React.FC<T>) {
  return function (props: T) {
    return (
      <AuthenticatedPage>
        <AuthContextProvider>
          <AdminLayout>
            <Component {...props} />
          </AdminLayout>
        </AuthContextProvider>
      </AuthenticatedPage>
    )
  }
}
