import BulkUpdatePage from "components/page/bulk-update-page"
import { GLBulkPages } from "constant/form-pages/gl"

const BulkPageRenderer =
  (menuName: string): React.FC =>
  () => {
    return (
      <BulkUpdatePage
        {...{
          ...GLBulkPages,
        }[menuName]}
      />
    )
  }

export default BulkPageRenderer
