import generateItemSJIFormItems from "common/form-item-generator/item-surat-jalan-internal"
import generateSuratJalanInternalFormItems from "common/form-item-generator/surat-jalan-internal"
import { SuratJalanInternalTypeEnum } from "common/type"
import {
  generateCrudUrl,
  genFormItems,
  genFieldQueryParamObj,
  formatNumber,
  genFieldQueryParamStr,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import { getAPI } from "hooks"
import { StockAvailability } from "services/stock"

const SuratJalanTitle = {
  [SuratJalanInternalTypeEnum.STOCK]: "Surat Jalan Internal (Pusat ke Cabang)",
  [SuratJalanInternalTypeEnum.SPPB]: "Surat Jalan Internal - SPPB",
}

const SuratJalanInternalPermissionMapping = {
  [SuratJalanInternalTypeEnum.STOCK]:
    "SIT_sekawan::transaksi::franco_stock::purchasing::surat_jalan_internal",
  [SuratJalanInternalTypeEnum.SPPB]:
    "SIT_sekawan::transaksi::service::admin_penjualan::surat_jalan_internal_service_cabang",
}

// TODO: in SPPB sample and waste calculated to sqm
function generateSuratJalanInternalForm(
  tipe: SuratJalanInternalTypeEnum
): BasePageProps<any> {
  return {
    title: SuratJalanTitle[tipe],
    permissionRequired: SuratJalanInternalPermissionMapping[tipe],
    urls: {
      ...generateCrudUrl("surat-jalan-internal"),
      read: {
        endpoint: `/surat-jalan-internal`,
        method: "get",
        param: genFieldQueryParamObj({
          typeSuratJalan: tipe,
          no: "notNull",
        }),
      },
    },
    filter: (
      <BaseFilter
        keyName={
          tipe === SuratJalanInternalTypeEnum.STOCK ? "sjiPusatCabang" : "sji"
        }
      />
    ),
    generateCodeParam: {
      entityName: "SuratJalanInternal",
      entity: { typeSuratJalan: tipe },
    },
    requiredToAdd:
      tipe === SuratJalanInternalTypeEnum.STOCK ? [] : ["Cabang", "Divisi"],
    disableAdd:
      tipe === SuratJalanInternalTypeEnum.STOCK
        ? undefined
        : (data, filterData) => {
            return (
              !filterData?.["permintaanPengirimanBarang.divisi.id"] ||
              !filterData?.["asal.id"]
            )
          },
    formGroupItems: genFormItems(generateSuratJalanInternalFormItems(tipe)),
    cetakActions: [
      {
        label: "Cetak",
        url: "/surat-jalan-internal/{id}/download",
        isRefetch: true,
      },
    ],
    additionalForms: [
      {
        id: "itemSuratJalanInternals",
        title: "Barang",
        disableAdd:
          tipe === SuratJalanInternalTypeEnum.SPPB
            ? (data) => {
                return !data.permintaanPengirimanBarang?.id
              }
            : (data: any) => {
                return !data.bapb?.id
              },
        requiredToAdd:
          tipe === SuratJalanInternalTypeEnum.SPPB
            ? ["Surat Permintaan Pengiriman Barang"]
            : ["BAPB"],
        validateDataCb: async (formData, globData, formMode) => {
          let stock: StockAvailability
          try {
            stock = await getAPI("/RiwayatStockBarang/latest-stock", {
              barangId: +(
                tipe === SuratJalanInternalTypeEnum.SPPB
                  ? formData?.itemPermintaanPengirimanBarang
                  : formData?.itemBapb
              )?.barang?.id,
              organisasiId: !!globData?.bapb?.id
                ? globData.bapb.organisasi.id
                : +globData?.asal?.id,
            })
          } catch (err: any) {
            return err.message
          }
          if (
            formData?.itemBapb?.itemPurchaseOrderStock?.purchaseOrderStock
              ?.poType === "SAMPLE"
          ) {
            if (+formData.qtyFisik > stock.sample) {
              return `Qty sample tidak mencukupi (${formatNumber(
                stock.sample
              )})`
            }
          } else {
            if (+formData.qtyFisik > stock.available) {
              return `Qty available tidak mencukupi (${formatNumber(
                stock.available
              )})`
            }
          }
          if (+formData.qtySample > stock.sample) {
            return `Qty sample tidak mencukupi (${formatNumber(stock.sample)})`
          }
          if (+formData.qtyWaste > stock.waste) {
            return `Qty waste tidak mencukupi (${formatNumber(stock.waste)})`
          }
          if (
            +formData.qtyFisik + +formData.qtyWaste + +formData.qtySample <=
            0
          ) {
            return "Total qty harus > 0"
          }

          return undefined
        },
        formItems: genFormItems(generateItemSJIFormItems(tipe)),
        additionalForms:
          tipe === SuratJalanInternalTypeEnum.SPPB
            ? [
                {
                  id: "itemBarangMasukSjis",
                  title: "Barang Masuk",
                  disableAdd(data, filterData) {
                    return !data.itemPermintaanPengirimanBarang?.barang?.id
                  },
                  requiredToAdd: ["Barang"],
                  formItems: genFormItems([
                    {
                      id: "itemBarangMasuk.id",
                      type: "async-select",
                      label: "Barang Masuk",
                      resourceUrl: (data, filterData, globData) => {
                        const orgId = filterData?.["asal.id"]
                        const barangId =
                          globData.itemPermintaanPengirimanBarang?.barang?.id
                        return `item-barang-masuk?${genFieldQueryParamStr({
                          "organisasi.id": orgId,
                          "itemBapb.barang.id": barangId,
                        })}`
                      },
                      resourceMapper: (data) => ({
                        label: data.no,
                        value: data.id,
                      }),
                      customOnChange: (data, setValue) => {
                        setValue("itemBarangMasuk", data)
                      },
                      disabled(data, detailData, formMode, globVars) {
                        return formMode === "update"
                      },
                    },
                    {
                      id: "itemBarangMasuk.no",
                      isIgnored: true,
                      dataViewLabel: "No. Barang Masuk",
                    },
                    {
                      id: "itemBarangMasuk.sisaSqmAvailable",
                      disabled: true,
                      type: "number",
                      label: "Sisa SQM",
                    },
                  ]),
                },
              ]
            : undefined,
      },
    ],
  }
}

export default generateSuratJalanInternalForm
