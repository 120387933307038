import { Box, Flex, IconButton, Text } from "@chakra-ui/react"
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons"
import { useAuth } from "context/auth.context"
import { ColorDict } from "constant/theme"
import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useScreenDetector } from "hooks/device"

const TopBar = () => {
  const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const { profile } = useAuth()
  const { isMobile } = useScreenDetector()

  const Chevron = useMemo(
    () => (isMenuOpen ? ChevronUpIcon : ChevronDownIcon),
    [isMenuOpen]
  )

  return (
    <Flex
      justify={isMobile ? "flex-start" : "flex-end"}
      pr={isMobile ? 0 : "12px"}
      position="relative"
    >
      <Flex borderRadius="20px" align="center" bg="#E7EEF7" p="1px">
        <Box
          background={ColorDict.primary}
          borderRadius="full"
          w="38px"
          h="38px"
        />
        <Box pl="12px" pr="32px">
          <Text color="#737B8B" fontSize="14px">
            {profile?.nama}
          </Text>
        </Box>
        <IconButton
          bg="transparent"
          aria-label="Profile Dropdown"
          onClick={() => setIsMenuOpen((prevState) => !prevState)}
          icon={<Chevron color="#737B8B" fontSize="14px" />}
        />
      </Flex>
      {isMenuOpen && (
        <Flex
          position="absolute"
          top={"48px"}
          background="white"
          px="20px"
          py="8px"
          borderRadius="12px"
          cursor="pointer"
          onClick={() => {
            localStorage.clear()
            navigate("/login")
          }}
          _hover={{
            background: "rgba(72, 138, 199, 0.05)",
          }}
        >
          <Text fontSize="14px">Logout</Text>
        </Flex>
      )}
    </Flex>
  )
}

export default TopBar
