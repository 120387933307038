import {
  Box,
  Button,
  Flex,
  FormLabel,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react"
import { formatNumber } from "common/util"
import ModalInfo from "components/modal/modal-info"
import { useCallback, useEffect, useMemo, useState } from "react"
import Draggable from "react-draggable"
import {
  KordinatSpesifikasiBarang,
  useGetKordinatSpesifikasiBarangByItemBarangMasuk,
} from "services/kordinat-spesifikasi-barang"
import intersection from "rectangle-overlap"
import { useStateLogger } from "hooks/logger"

interface ItmWorkOrderEditorProps {
  spaceHeight: number
  spaceWidth: number
  onSave: (points: KordinatSpesifikasiBarang[]) => void
  title: string
  disabled?: boolean
  value: Point[]
  qtyFabrikasi: number
  itemBarangMasukId: number
}

interface Point {
  x: number
  y: number
}

interface PointWithSize extends Point {
  width: number
  height: number
  multiplierHeight: number
  multiplierWidth: number
  qtyFabrikasiNum: number
}

const FIXED_AREA_EDITOR_WIDTH = 500.0
const FIXED_AREA_EDITOR_HEIGHT = 900.0

function isAreasHaveIntersect(
  areas: PointWithSize[],
  checkedArea: PointWithSize
): boolean {
  for (const area of areas) {
    if (area.qtyFabrikasiNum === checkedArea.qtyFabrikasiNum) continue

    const isIntersect = intersection(
      {
        x: area.x,
        y: area.y,
        width: area.width,
        height: area.height,
      },
      {
        x: checkedArea.x,
        y: checkedArea.y,
        width: checkedArea.width,
        height: checkedArea.height,
      }
    )

    if (isIntersect) return true
  }

  return false
}

/**
 * TODO:
 * 1. Rotate -- DONE
 * 2. Enable split shape
 * 3. Enable choose which qty from stock
 * 4. Enable choose which qty from item work order
 * 5. Enable to manage scale
 */
const ItemWorkOrderEditor: React.FC<ItmWorkOrderEditorProps> = ({
  spaceHeight,
  spaceWidth,
  onSave,
  title,
  disabled,
  value,
  qtyFabrikasi,
  itemBarangMasukId,
}) => {
  const toast = useToast()
  // TODO: auto set value for update case
  const [isEditorOpen, setIsEditorOpen] = useState<boolean>(false)
  const [currQtyNum, setCurrQtyNum] = useState<number>(1)
  const [currQtyFabrikasiNum, setCurrQtyFabrikasiNum] = useState<number>(1)
  const [startPoint, setStartPoint] = useState<Point>({ x: 0, y: 0 })
  const [isIntersect, setIsIntersect] = useState<boolean>(false)

  const [pointsByStockQty, setPointsByStockQty] = useState<
    Map<number, Map<number, KordinatSpesifikasiBarang>>
  >(new Map())

  const { data: kordinats } =
    useGetKordinatSpesifikasiBarangByItemBarangMasuk(itemBarangMasukId)

  const qtyStock = useMemo(() => kordinats?.itemBarangMasuk.qty, [kordinats])
  const boardHeight = useMemo(
    () => kordinats?.itemBarangMasuk.panjang ?? 0,
    [kordinats]
  )
  const boardWidth = useMemo(
    () => kordinats?.itemBarangMasuk.lebar ?? 0,
    [kordinats]
  )

  useEffect(() => {
    if (kordinats?.data) {
      setPointsByStockQty((prevState) => {
        for (const kordinat of kordinats.data) {
          if (!prevState.has(kordinat.qtyStockNum)) {
            prevState.set(kordinat.qtyStockNum, new Map())
          }
          prevState
            .get(kordinat.qtyStockNum)!!
            .set(kordinat.qtyFabrikasiNum, kordinat)
        }

        return prevState
      })
    }
  }, [kordinats])

  const multiplierWidth = useMemo(() => {
    return boardWidth / FIXED_AREA_EDITOR_WIDTH
  }, [boardWidth])

  const multiplierHeight = useMemo(() => {
    return boardHeight / FIXED_AREA_EDITOR_HEIGHT
  }, [boardHeight])

  const onCloseEditor = useCallback(() => {
    setIsEditorOpen(false)
  }, [])

  const [scaledSpaceHeight, setScaledSpaceHeight] = useState<number>(0)
  const [scaledSpaceWidth, setScaledSpaceWidth] = useState<number>(0)

  useEffect(
    () => setScaledSpaceHeight(spaceHeight / multiplierHeight),
    [spaceHeight, multiplierHeight]
  )

  useEffect(
    () => setScaledSpaceWidth(spaceWidth / multiplierWidth),
    [spaceWidth, multiplierWidth]
  )

  const scaledBoardHeight = useMemo(
    () => boardHeight / multiplierHeight,
    [boardHeight, multiplierHeight]
  )

  const usedBlocks = useMemo(() => {
    const result: KordinatSpesifikasiBarang[] = []

    const pointsByQtyFabrikasi: Map<number, Point> =
      pointsByStockQty.get(currQtyNum) ?? new Map<number, Point>()

    pointsByQtyFabrikasi.forEach((point, qtyFabrikasiKey) => {
      result.push({
        x: point.x,
        y: point.y,
        multiplierWidth,
        multiplierHeight,
        width: scaledSpaceWidth,
        height: scaledSpaceHeight,
        qtyStockNum: currQtyNum,
        qtyFabrikasiNum: qtyFabrikasiKey,
      })
    })

    return result
  }, [
    currQtyNum,
    multiplierHeight,
    multiplierWidth,
    pointsByStockQty,
    scaledSpaceHeight,
    scaledSpaceWidth,
  ])

  const associatedQtyFabrikasi = useMemo<{ [key: number]: boolean }>(() => {
    const result: { [key: number]: boolean } = {}

    pointsByStockQty.forEach((pointsByQtyFabrikasi, qtyStockKey) => {
      pointsByQtyFabrikasi.forEach((point, qtyFabrikasiKey) => {
        result[qtyFabrikasiKey] = true
      })
    })

    return result
  }, [pointsByStockQty])

  const onDragSpace = useCallback(
    (e, data) => {
      const { x, y }: any = data
      const isIntersect = isAreasHaveIntersect(usedBlocks, {
        x,
        y,
        multiplierWidth,
        multiplierHeight,
        width: scaledSpaceWidth,
        height: scaledSpaceHeight,
        qtyFabrikasiNum: currQtyFabrikasiNum,
      })

      if (isIntersect) {
        setIsIntersect(true)
      } else {
        setIsIntersect(false)
        setStartPoint({ x, y })
      }
    },
    [
      currQtyFabrikasiNum,
      multiplierHeight,
      multiplierWidth,
      scaledSpaceHeight,
      scaledSpaceWidth,
      usedBlocks,
    ]
  )

  const onSubmit = useCallback(() => {
    if (Object.keys(associatedQtyFabrikasi).length < qtyFabrikasi) {
      toast({
        title: "Gagal",
        description: "Posisi semua qty untuk fabrikasi harus ditentukan",
        status: "error",
        duration: 8000,
        isClosable: true,
        position: "top",
      })
    } else {
      onSave(usedBlocks)
      onCloseEditor()
    }
  }, [
    associatedQtyFabrikasi,
    onCloseEditor,
    onSave,
    qtyFabrikasi,
    toast,
    usedBlocks,
  ])

  const onRotate = useCallback(() => {
    const height = scaledSpaceHeight
    setScaledSpaceHeight(scaledSpaceWidth)
    setScaledSpaceWidth(height)
  }, [scaledSpaceHeight, scaledSpaceWidth])

  const onChangeQtyFabrikasi = useCallback(
    (qtyFabrikasiNum) => {
      let point: Point
      if (!pointsByStockQty.get(currQtyNum)) {
        point = { x: 0, y: 0 }
      } else {
        const pointsByQtyFabrikasi = pointsByStockQty.get(currQtyNum)

        point = pointsByQtyFabrikasi?.has(qtyFabrikasiNum)
          ? {
              x: pointsByQtyFabrikasi?.get(qtyFabrikasiNum)!!.x,
              y: pointsByQtyFabrikasi?.get(qtyFabrikasiNum)!!.y,
            }
          : { x: 0, y: 0 }
      }

      setCurrQtyFabrikasiNum(qtyFabrikasiNum)
      setStartPoint(point)
    },
    [currQtyNum, pointsByStockQty]
  )

  const onAdd = useCallback(() => {
    if (!startPoint) return
    setPointsByStockQty((prevState) => {
      const point: KordinatSpesifikasiBarang = {
        ...startPoint,
        height: scaledSpaceHeight,
        width: scaledSpaceWidth,
        qtyFabrikasiNum: currQtyFabrikasiNum,
        multiplierHeight: multiplierHeight,
        multiplierWidth: multiplierWidth,
        qtyStockNum: currQtyNum,
      }
      const newState = new Map(prevState)
      if (!newState.has(currQtyNum)) {
        const pointByQtyFabrikasi: Map<number, KordinatSpesifikasiBarang> =
          new Map()
        pointByQtyFabrikasi.set(currQtyFabrikasiNum, point)

        newState.set(currQtyNum, pointByQtyFabrikasi)
      } else {
        newState.get(currQtyNum)?.set(currQtyFabrikasiNum, point)
      }

      return newState
    })
    // setStartPoint({ x: 0, y: 0 })
    toast({
      title: "Berhasil",
      description: "Posisi pemotongan berhasil disimpan",
      status: "success",
      duration: 8000,
      isClosable: true,
      position: "top",
    })
  }, [
    currQtyFabrikasiNum,
    currQtyNum,
    multiplierHeight,
    multiplierWidth,
    scaledSpaceHeight,
    scaledSpaceWidth,
    startPoint,
    toast,
  ])

  return (
    <>
      {Array.from(pointsByStockQty).length > 0 && (
        // @ts-ignore
        <Flex mb={2}>
          {Array.from(pointsByStockQty).map((board, key) => (
            <Box
              key={key}
              w={"150px"}
              h={"250px"}
              borderRadius={"2px"}
              border="solid 1px #e6eef7"
              position={"relative"}
            >
              {Array.from(board[1]).map((space, spaceKey) => {
                return (
                  <Box
                    key={spaceKey}
                    background={"#e6e7e8"}
                    border={"solid 1px #1186cf"}
                    w={space[1].width * (150 / FIXED_AREA_EDITOR_WIDTH)}
                    h={space[1].height * (250 / FIXED_AREA_EDITOR_HEIGHT)}
                    position={"absolute"}
                    bg="#1186cf"
                    top={`${space[1].y * (250 / FIXED_AREA_EDITOR_HEIGHT)}px`}
                    left={`${space[1].x * (150 / FIXED_AREA_EDITOR_WIDTH)}px`}
                  >
                    &nbsp;
                  </Box>
                )
              })}
            </Box>
          ))}
        </Flex>
      )}
      {/* @ts-ignore */}
      <Button
        colorScheme={"blue"}
        variant="outline"
        onClick={() => setIsEditorOpen(true)}
        isDisabled={
          disabled ||
          !itemBarangMasukId ||
          !spaceHeight ||
          !spaceWidth ||
          !qtyFabrikasi
        }
      >
        Ubah
      </Button>
      <ModalInfo onClose={onCloseEditor} isOpen={isEditorOpen} title={title}>
        <>
          <Flex justifyContent="space-between">
            <Box>
              <Box>
                <FormLabel>Pilih Stock yang Dipotong</FormLabel>
                <Flex>
                  {Array(qtyStock)
                    .fill(null)
                    .map((_, key) => (
                      <Box
                        px={3}
                        py={1}
                        border={"solid 1px #e2e8f0"}
                        borderRadius={4}
                        fontSize={14}
                        onClick={() => {
                          // @ts-ignore
                          setCurrQtyNum(key + 1)
                        }}
                        marginRight={2}
                        cursor={"pointer"}
                        fontWeight={"bold"}
                        backgroundColor={
                          currQtyNum === key + 1 ? "#4789c7" : "white"
                        }
                        color={currQtyNum === key + 1 ? "white" : "black"}
                        _hover={{
                          backgroundColor: "#4789c7",
                          color: "white",
                        }}
                      >
                        {key + 1}
                      </Box>
                    ))}
                </Flex>
              </Box>
              <Box>
                <FormLabel>Pilih Stock Fabrikasi</FormLabel>
                <Flex>
                  {Array(qtyFabrikasi)
                    .fill(null)
                    .map((_, key) => (
                      <Box
                        px={3}
                        py={1}
                        border={
                          !!associatedQtyFabrikasi[key + 1]
                            ? "solid 3px #09964d"
                            : "solid 1px #e2e8f0"
                        }
                        borderRadius={4}
                        fontSize={14}
                        marginRight={2}
                        onClick={() => {
                          // @ts-ignore
                          onChangeQtyFabrikasi(key + 1)
                        }}
                        cursor={"pointer"}
                        fontWeight={"bold"}
                        backgroundColor={
                          currQtyFabrikasiNum === key + 1 ? "#4789c7" : "white"
                        }
                        color={
                          currQtyFabrikasiNum === key + 1 ? "white" : "black"
                        }
                        _hover={{
                          backgroundColor: "#4789c7",
                          color: "white",
                        }}
                      >
                        {/* @ts-ignore */}
                        {key + 1}
                      </Box>
                    ))}
                </Flex>
              </Box>
              <Flex mb="4px" my="6px">
                <Button mr="4px" variant="outline" onClick={onRotate}>
                  Rotate
                </Button>
              </Flex>
              <Box
                border={`solid 1px ${isIntersect ? "red" : "#488AC7"}`}
                borderRadius={"2px"}
                width={`${FIXED_AREA_EDITOR_WIDTH + 2}px`}
                height={`${(scaledBoardHeight + 2).toFixed(2)}px`}
                position="relative"
              >
                <Draggable
                  axis="both"
                  handle=".handle"
                  defaultPosition={{ x: 0, y: 0 }}
                  position={
                    !!startPoint
                      ? { x: startPoint.x, y: startPoint.y }
                      : undefined
                  }
                  bounds={{
                    left: 0,
                    right: FIXED_AREA_EDITOR_WIDTH - scaledSpaceWidth,
                    top: 0,
                    bottom: scaledBoardHeight - scaledSpaceHeight,
                  }}
                  // position={null}
                  grid={[1, 1]}
                  scale={1}
                  // onStart={this.handleStart}
                  onDrag={onDragSpace}
                  onStop={() => {
                    setIsIntersect(false)
                  }}
                >
                  <Box
                    className="handle"
                    border="none"
                    bg="#1186cf"
                    cursor="pointer"
                    position={"relative"}
                    borderRadius={"2px"}
                    py="4px"
                    h={`${scaledSpaceHeight.toFixed(2)}px`}
                    w={`${scaledSpaceWidth.toFixed(2)}px`}
                  >
                    <Button
                      position={"absolute"}
                      right={"-24px"}
                      bottom={"-18px"}
                      fontSize={"10px"}
                      h={6}
                      paddingY={"1px"}
                      paddingX="12px"
                      onClick={onAdd}
                    >
                      SIMPAN
                    </Button>
                  </Box>
                </Draggable>
                {usedBlocks
                  ?.filter((i) => i.qtyFabrikasiNum !== currQtyFabrikasiNum)
                  ?.map((usedBlock, key) => {
                    return (
                      <Box
                        position={"absolute"}
                        zIndex={-1}
                        border="none"
                        bg="#666666"
                        top={usedBlock?.y}
                        left={usedBlock?.x}
                        h={`${usedBlock.height.toFixed(2)}px`}
                        w={`${usedBlock.width.toFixed(2)}px`}
                        right={
                          FIXED_AREA_EDITOR_WIDTH -
                          (usedBlock?.x + usedBlock?.width) +
                          1
                        }
                        bottom={
                          scaledBoardHeight -
                          (usedBlock?.y + usedBlock?.height) +
                          1
                        }
                      />
                    )
                  })}
              </Box>
            </Box>
            {!!startPoint && (
              <Box ml="16px" w="180px">
                <Text>Qty Stock: {qtyStock}</Text>
                <Text>Qty Fabrikasi: {qtyFabrikasi}</Text>
                <br />
                <Text>Skala X: {multiplierWidth}</Text>
                <Text>Skala Y: {multiplierHeight}</Text>
                <Text>Titik Potong:</Text>
                <Text fontSize="12px">
                  ({formatNumber(startPoint.x)} mm,
                  {formatNumber(startPoint.y)} mm)
                </Text>
                <Text fontSize="12px">
                  ({formatNumber(startPoint.x + spaceWidth)} mm,
                  {formatNumber(startPoint.y)} mm)
                </Text>
                <Text fontSize="12px">
                  ({formatNumber(startPoint.x + spaceWidth)} mm,
                  {formatNumber(startPoint.y + spaceHeight)} mm)
                </Text>
                <Text fontSize="12px">
                  ({formatNumber(startPoint.x)} mm,
                  {formatNumber(startPoint.y + spaceHeight)} mm)
                </Text>
              </Box>
            )}
          </Flex>
          <Flex justifyContent="flex-end" mt="24px">
            <Button colorScheme="primary" onClick={onSubmit}>
              Simpan
            </Button>
          </Flex>
        </>
      </ModalInfo>
    </>
  )
}

export default ItemWorkOrderEditor
