import {
  calculateJumlahHutangInPermohonan,
  calculateTotalHutang,
} from "common/form-value-function"
import {
  formatNumber,
  generateCrudUrl,
  genFieldQueryParamObj,
  genFieldQueryParamStr,
  genFormItems,
  getToday,
  isValueEmpty,
  strToSafeNum,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import { Text } from "@chakra-ui/react"
import {
  mataUangOptionMapper,
  mataUangSearchField,
  supplierOptionMapper,
  supplierSearchKey,
} from "constant/mapper"
import { MataUangFormItemDefinition } from "constant/form-item"
import { genBaseSortQueryParam } from "constant/url"
import { JenisKomisiOption } from "constant/options"
import {
  validateOnAddItemPembayaranKomisi,
  validateOnAddItemPermohonanPembayaran,
} from "common/procedures/permohonan-pembayaran"
import { GLOB_VAR_PPN } from "constant/global-var-keys"
import { ignoredSupplierFilter } from "constant/filters"

function generatePermohonanPembayaranHutangForm(
  isGl: boolean
): BasePageProps<any> {
  return {
    title: "Permohonan Pembayaran Hutang",
    requiredToAdd: ["supplier", "divisi"],
    disableAdd(data, filterData) {
      return !filterData?.["supplier.id"] || !filterData?.["divisi.id"]
    },
    ignoreFilterOnFetch: ignoredSupplierFilter,
    urls: {
      ...generateCrudUrl("permohonan-pembayaran"),
      read: {
        endpoint: `/permohonan-pembayaran`,
        param: genFieldQueryParamObj({
          jenisPermohonan: "HUTANG",
        }),
        method: "get",
      },
    },
    recalculateCallback: calculateJumlahHutangInPermohonan,
    filter: <BaseFilter keyName="permohonanPembayaran" />,
    generateCodeParam: {
      entityName: "PermohonanPembayaran",
      entity: {
        jenisPermohonan: "HUTANG",
      },
    },
    permissionRequired: `${
      isGl ? "GL_Sekawan::transaksi" : "SIT_sekawan::transaksi::lain_lain"
    }::pembayaran::permohonan_pembayaran_hutang`,
    formGroupItems: genFormItems([
      {
        id: "jenisPermohonan",
        type: "hidden",
        value: "HUTANG",
      },
      {
        id: "no",
        label: "Nomor Permohonan",
        isDataView: true,
        disabled: true,
        isGeneratedCodeField: true,
      },
      {
        id: "supplier.id",
        label: "Supplier",
        type: "async-select",
        resourceUrl: `supplier`,
        resourceMapper: supplierOptionMapper,
        searchBy: supplierSearchKey,
        resourceColumnLabelKey: "supplier.kodeSupplier,supplier.namaPanjang",
      },
      {
        id: "divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      {
        id: "tanggal",
        label: "Tanggal",
        type: "date",
        isDataView: true,
        value: getToday(),
      },
      {
        id: "jumlahHutang",
        label: "Jumlah Hutang",
        type: "number",
        disabled: true,
        isDataView: true,
        columnRender: (data) => {
          return (
            // @ts-ignore
            <Text fontWeight="600" fontSize="14px">
              {/* {formatNumber(calculateTotalHutang(data))} */}
              {formatNumber(
                data.itemPermohonanPembayarans?.reduce(
                  (total: number, item: any) => {
                    return total + +item.jumlah
                  },
                  0
                ) ?? 0
              )}
            </Text>
          )
        },
      },
      {
        id: "noAccount",
        label: "Nomor Account",
      },
      {
        id: "swiftCode",
        label: "Swift Code",
      },
      {
        id: "note",
        label: "Note",
      },
      {
        id: "isPpn",
        label: "PPN",
        type: "check",
        disabled: true,
      },
    ]),
    additionalTitle: "Permohonan Pembayaran",
    additionalForms: [
      {
        title: "Daftar Invoice",
        id: "itemPermohonanPembayarans",
        validateDataCb: validateOnAddItemPermohonanPembayaran,
        requiredToAdd: ["divisi", "supplier"],
        disableAdd: (data) => !data.divisi?.id || !data?.supplier?.id,
        formItems: genFormItems([
          {
            id: "bapb.id",
            label: "No. Invoice",
            type: "async-table-select",
            column: 1,
            resourceUrl: (formData: any, filterData: any, data) => {
              const supplierId = data?.supplier?.id
              const divisiId = data?.divisi?.id

              return `bapb?${genFieldQueryParamStr({
                "divisi.id": divisiId,
                "supplier.id": supplierId,
                "bapb.sisaBayar_$gt": 0,
              })}`
            },
            dataViewKey: "bapb.no",
            customOnChange: (data, setValue, getVal, genCode, globVars) => {
              setValue("bapb", data)
              setValue("jumlah", data?.sisaBayar?.toFixed(2))
              if (isValueEmpty("mataUang.mataUang")) {
                setValue(
                  "mataUang.mataUang",
                  data?.itemBapbs?.[0]?.mataUang?.mataUang
                )
              }
              setValue("isPpn", data?.itemBapbs?.[0]?.isPpn)
            },
            resourceColumn: [
              {
                dataKey: "noInvoice",
                label: "No. Invoice",
              },
              {
                dataKey: "no",
                label: "No. BAPB",
              },
              {
                dataKey: "tanggal",
                label: "Tanggal",
              },
              {
                dataKey: "sisaBayar",
                label: "Jumlah Hutang",
                type: "number",
              },

              {
                dataKey: "tanggalJatuhTempo",
                label: "Tanggal Jatuh Tempo",
              },
              {
                dataKey: "isPpn",
                label: "P",
                render: (data) => {
                  return (
                    // @ts-ignore
                    <Text fontSize="13PX" fontWeight={600}>
                      {!!data?.itemBapbs?.[0]?.isPpn ? "Y" : "T"}
                    </Text>
                  )
                },
              },
            ],
          },
          {
            id: "bapb.noInvoice",
            label: "Nomor Invoice",
            isDataView: true,
            disabled: true,
          },
          {
            id: "bapb.no",
            label: "Nomor BAPB",
            isDataView: true,
            disabled: true,
          },
          {
            id: "bapb.tanggal",
            label: "Tanggal",
            type: "date",
            isDataView: true,
            disabled: true,
          },
          {
            id: "jumlah",
            type: "number",
            label: "Harga",
            dataViewLabel: "Jumlah Hutang",
            isDataView: true,
            formRule(data, detail) {
              const max = data?.bapb?.sisaBayar
              return {
                max: {
                  value: max,
                  message: `Jumlah tidak boleh lebih dari ${formatNumber(max)}`,
                },
              }
            },
          },
          {
            id: "bapb.tanggalJatuhTempo",
            label: "Jatuh Tempo",
            type: "date",
            isDataView: true,
            disabled: true,
          },

          {
            id: "mataUang.mataUang",
            label: "Mata Uang",
            type: "async-select",
            disabled: true,
            resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
            resourceMapper: mataUangOptionMapper,
            searchBy: mataUangSearchField,
          },
          {
            id: "hasBukti",
            type: "radio",
            label: "Bukti",
            value: true,
            isOptional: true,
            options: [
              {
                label: "Ada",
                value: true,
              },
              {
                label: "Tidak",
                value: false,
              },
            ],
          },
        ]),
      },
      {
        title: "Pembayaran Melalui Komisi/Reimburse",
        id: "pembayaranKomisis",
        disableAdd(data) {
          return !data?.supplier?.id
        },
        requiredToAdd: ["Supplier"],
        validateDataCb: validateOnAddItemPembayaranKomisi,
        formItems: genFormItems([
          {
            id: "jenisKomisi",
            label: "Jenis Bayar",
            isDataView: true,
            type: "radio",
            options: JenisKomisiOption,
            columnRender(data) {
              return (
                // @ts-ignore
                <Text fontSize={13} fontWeight="600">
                  {
                    JenisKomisiOption.find((i) => i.value === data.jenisKomisi)
                      ?.label
                  }
                </Text>
              )
            },
          },
          {
            id: "debitNote.id",
            column: 1,
            label: "Nomor Debit Note",
            resourceUrl: (_, filter, data) =>
              `DebitNote?${genFieldQueryParamStr({
                "konfirmasiInvoice.supplier.id": data?.supplier?.id,
                sisaKomisi_$gt: 0,
              })}`,
            type: "async-table-select",
            dataViewKey: "debitNote.noDebitNote",
            resourceColumnLabelKey: "noDebitNote",
            resourceColumn: [
              {
                dataKey: "noDebitNote",
                label: "No. Debit Note",
              },
              {
                label: "Nomor Invoice Supplier",
                dataKey: "noInvoice",
              },
              {
                dataKey: "sisaKomisi",
                label: "Jumlah Komisi",
                type: "number",
              },
            ],
            isDataView: true,
            customOnChange: (data, setValue) => {
              setValue("debitNote", data)
              setValue("jumlahYangDibayar", data.sisaKomisi)
            },
          },
          {
            id: "debitNote.jumlah",
            label: "Jumlah Komisi",
            type: "number",
            isDataView: true,
            disabled: true,
          },
          {
            id: "debitNote.noInvoice",
            label: "Nomor Invoice Supplier",
            isDataView: true,
            disabled: true,
          },
          { ...MataUangFormItemDefinition, id: "debitNote.mataUang.mataUang" },
          {
            id: "debitNote.sisaKomisi",
            type: "hidden",
          },
          {
            id: "jumlahYangDibayar",
            label: "Jumlah yang Dibayar",
            type: "number",
            dataViewLabel: "Jumlah Bayar",
            formRule(data, detail) {
              const max = data.debitNote?.sisaKomisi ?? 0
              return {
                max: {
                  value: max,
                  message: `Jumlah yang dibayar tidak bisa lebih dari ${formatNumber(
                    max
                  )}`,
                },
              }
            },
          },
          {
            id: "jenisPermohonan",
            type: "hidden",
            value: "HUTANG",
          },
          {
            id: "supplier.isPkp",
            label: "PKP",
            type: "check",
            disabled: true,
            renderCondition(formData, formMode, filterData) {
              return !formData?.supplier?.isImportLuarNegeri
            },
          },
          {
            id: "isPpn",
            label: "PPN",
            type: "check",
            disabled: true,
            renderCondition(formData, formMode, filterData) {
              return !!formData?.supplier?.isImportLuarNegeri
            },
          },
          {
            id: "debitNote.keterangan",
            label: "Keterangan",
          },
        ]),
      },
    ],
  }
}

export default generatePermohonanPembayaranHutangForm
