export function mapItemPengajuanKasKecilToRenderable(items: any[]): any[] {
  return (items ?? []).map((i: any) => ({
    ...i,
    id:
      i.jurnalUmum?.id ||
      i.kasbonKaryawan?.id ||
      i.kasbonBiayaImport?.id ||
      i.penyelesaianKasbon?.id,
    keterangan:
      i.jurnalUmum?.keterangan ||
      i.kasbonKaryawan?.keterangan ||
      i.kasbonBiayaImport?.keterangan ||
      i.penyelesaianKasbon?.keterangan,
    nomor:
      i.jurnalUmum?.nomor ||
      i.kasbonKaryawan?.nomor ||
      i.kasbonBiayaImport?.nomor ||
      i.penyelesaianKasbon?.nomor,
    tanggal:
      i.jurnalUmum?.tanggal ||
      i.kasbonKaryawan?.tanggal ||
      i.kasbonBiayaImport?.tanggal ||
      i.penyelesaianKasbon?.tanggal,
  }))
}
