import {
  genFormItems,
  generateCrudUrl,
  genFieldQueryParamObj,
  formatNumber,
  getToday,
  strToSafeNum,
  genFieldQueryParamStr,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { genBaseSortQueryParam } from "constant/url"
import { Text } from "@chakra-ui/react"
import {
  mataUangOptionMapper,
  mataUangSearchField,
  supplierOptionMapper,
  supplierResourceColumnKey,
  supplierSearchKey,
} from "constant/mapper"
import { BasePageProps } from "components/page/type"
import { calculateTotalKomisiKonfirmasiInvoice } from "common/gl-util"
import {
  validateOnAddItemPembayaranKomisi,
  validateOnAddItemPermohonanPembayaran,
} from "common/procedures/permohonan-pembayaran"
import { GLOB_VAR_PPN } from "constant/global-var-keys"
import { TableCell } from "components/data-table"
import { ignoredSupplierFilter } from "constant/filters"

function generatePermohonanPembayaranTunaiForms(): BasePageProps<any> {
  return {
    title: "Permohonan Pembayaran Tunai",
    requiredToAdd: ["supplier", "divisi"],
    disableAdd(data, filterData) {
      return !filterData?.["supplier.id"] || !filterData?.["divisi.id"]
    },
    urls: {
      ...generateCrudUrl("permohonan-pembayaran"),
      read: {
        endpoint: `/permohonan-pembayaran`,
        param: {
          ...genFieldQueryParamObj({
            jenisPermohonan: "TUNAI",
          }),
          sortBy: "id",
          sortType: "desc",
        },
        method: "get",
      },
    },
    permissionRequired:
      "SIT_sekawan::transaksi::lain_lain::pembayaran::permohonan_pembayaran_tunai",
    filter: <BaseFilter keyName="permohonanPembayaran" />,
    generateCodeParam: {
      entityName: "PermohonanPembayaran",
      entity: {
        jenisPermohonan: "TUNAI",
      },
    },
    ignoreFilterOnFetch: ignoredSupplierFilter,
    formGroupItems: genFormItems([
      {
        id: "jenisPermohonan",
        type: "hidden",
        value: "TUNAI",
      },
      {
        id: "supplier.id",
        label: "Supplier",
        resourceColumnLabelKey: supplierResourceColumnKey,
        type: "async-select",
        resourceUrl: `supplier`,
        resourceMapper: supplierOptionMapper,
        searchBy: supplierSearchKey,
        customOnChange: (data, setValue) => {
          setValue("supplier", data)
        },
      },
      {
        id: "divisi.id",
        label: "Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      {
        id: "no",
        label: "Nomor Permohonan",
        isDataView: true,
        isGeneratedCodeField: true,
        disabled: true,
        suffix: {
          id: "tanggal",
          label: "Tanggal",
          type: "date",
          isDataView: true,
          value: getToday(),
          isHalfSize: true,
        },
      },
      {
        id: "supplier.isPkp",
        label: "PKP",
        type: "check",
        disabled: true,
        renderCondition(formData, formMode, filterData) {
          return !formData?.supplier?.isImportLuarNegeri
        },
      },
      {
        id: "isPpn",
        label: "PPN",
        type: "check",
        disabled: true,
        renderCondition(formData, formMode, filterData) {
          return !!formData?.supplier?.isImportLuarNegeri
        },
      },
      {
        id: "nilaiPo",
        label: "Nilai PO",
        type: "ignored",
        isDataView: true,
        columnRender: (data) => {
          return (
            // @ts-ignore
            <Text fontSize="14px" fontWeight={600}>
              {formatNumber(
                data.itemPermohonanPembayarans?.reduce(
                  (total: number, item: any) => {
                    return total + strToSafeNum(item.purchaseOrder?.nilaiPo)
                  },
                  0
                ) ?? 0
              )}
            </Text>
          )
        },
      },
      {
        id: "isPpn",
        type: "hidden",
        value: true,
      },
      {
        id: "noAccount",
        label: "Nomor Akun",
      },
      {
        id: "swiftCode",
        label: "Swift Code",
      },
      {
        id: "note",
        label: "Note",
        type: "text-area",
        isOptional: true,
      },
      {
        id: "jumlah",
        type: "ignored",
        label: "Pembayaran",
        isDataView: true,
        columnRender: (data) => (
          <Text fontSize="14px" fontWeight={600}>
            {formatNumber(
              data.itemPermohonanPembayarans?.reduce(
                (total: number, item: any) => total + strToSafeNum(item.jumlah),
                0
              )
            )}
          </Text>
        ),
      },
    ]),
    additionalTitle: "Pembayaran",
    additionalForms: [
      {
        title: "Permohonan",
        id: "itemPermohonanPembayarans",
        requiredToAdd: ["supplier", "divisi"],
        disableAdd: (data) => !data.supplier?.id || !data.divisi?.id,
        validateDataCb: validateOnAddItemPermohonanPembayaran,
        formItems: genFormItems([
          {
            id: "purchaseOrder.id",
            label: "Nomor PO",
            isDataView: true,
            type: "async-table-select",
            resourceUrl: (_, filter, data) => {
              const d = new Date()

              const q: any = {
                jenisPembayaran: "TUNAI",
                "divisi.id": data.divisi?.id,
                "supplier.id": data.supplier?.id,
                sisaBayar_$gt: 0,
                noPo: "notNull",
                "purchaseOrder.tanggal_$btwStart": `${
                  d.getFullYear() - 2
                }-01-01`,
                "purchaseOrder.tanggal_$btwEnd": `${d.getFullYear()}-12-31`,
                poType_$In: "STOCK;INDENT;SAMPLE",
                nilaiPo_$gt: 0,
              }
              return `purchase-order?${genFieldQueryParamStr(q)}`
            },
            column: 1,
            customOnChange: (data, setValue, getValues, genCode, globVars) => {
              setValue("mataUang.mataUang", data.mataUang?.mataUang)
              if (!getValues("jumlah")) {
                setValue("jumlah", data.sisaBayar.toFixed(2))
              }
              setValue("purchaseOrder", data)
              setValue(
                "nilaiGrossPo",
                (
                  (1 +
                    (!!data.suplier?.isPkp
                      ? strToSafeNum(globVars.get(GLOB_VAR_PPN), 11)
                      : 0) /
                      100) *
                  +data.nilaiPo
                ).toFixed(2)
              )
            },
            resourceColumn: [
              {
                dataKey: "noPo",
                label: "Nomor PO",
              },
              {
                dataKey: "tanggal",
                label: "Tanggal",
              },
              {
                dataKey: "nilaiPo",
                type: "number",
                label: "Nilai Nett PO",
                ignoreToSearch: true,
              },
              {
                dataKey: "sisaBayar",
                type: "number",
                label: "Sisa Bayar",
                ignoreToSearch: true,
              },
              {
                dataKey: "isPpn",
                label: "P",
                render: (data) => (
                  // @ts-ignore
                  <Text fontSize="14px" fontWeight={600}>
                    {data?.isPpn ? "Y" : "T"}
                  </Text>
                ),
              },
            ],
            resourceColumnLabelKey: "noPo",
            dataViewKey: "purchaseOrder.noPo",
          },
          {
            id: "purchaseOrder.tanggal",
            label: "Tanggal",
            type: "date",
            isDataView: true,
            disabled: true,
            dataViewKey: "purchaseOrder.tanggal",
            suffix: {
              id: "mataUang.mataUang",
              label: "Mata Uang",
              type: "async-select",
              resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
              resourceMapper: mataUangOptionMapper,
              disabled: true,
              searchBy: mataUangSearchField,
            },
          },
          {
            id: "hasBukti",
            type: "radio",
            label: "Bukti",
            isOptional: true,
            value: true,
            options: [
              {
                label: "Ada",
                value: true,
              },
              {
                label: "Tidak",
                value: false,
              },
            ],
          },
          {
            id: "nilaiGrossPo",
            label: "Nilai PO",
            type: "number",
            disabled: true,
          },
          {
            id: "purchaseOrder.nilaiPo",
            label: "Nilai PO",
            type: "number",
            isIgnored: true,
            isDataView: true,
          },
          {
            id: "jumlah",
            label: "Pembayaran",
            type: "number",
            isDataView: true,
            formRule: (data) => {
              const maxPembayaran = data.purchaseOrder?.sisaBayar
              return {
                max: {
                  message: `Pembayaran tidak boleh melebihi ${formatNumber(
                    maxPembayaran
                  )}`,
                  value: maxPembayaran,
                },
              }
            },
          },
          {
            id: "isUangMuka",
            type: "check",
            label: (data) => {
              return `Uang Muka${
                !!data?.purchaseOrder?.supplier &&
                !data?.purchaseOrder?.supplier?.isImportLuarNegeri
                  ? " +11% Pajak"
                  : ""
              }`
            },
            suffix: {
              id: "isPenyelesaianUangMuka",
              type: "check",
              isHalfSize: true,
              label: (data) => {
                return `Penyelesaian Uang Muka${
                  !!data?.purchaseOrder?.supplier &&
                  !data?.purchaseOrder?.supplier?.isImportLuarNegeri
                    ? " +11% Pajak"
                    : ""
                }`
              },
            },
          },
        ]),
      },
      {
        title: "Pembayaran Melalui Komisi",
        id: "pembayaranKomisis",
        validateDataCb: validateOnAddItemPembayaranKomisi,
        disableAdd(data) {
          return !data?.supplier?.id
        },
        requiredToAdd: ["Supplier"],
        formItems: genFormItems([
          {
            id: "debitNote.tipe",
            label: "Jenis Bayar",
            type: "ignored",
            isDataView: true,
          },
          {
            id: "debitNote.id",
            column: 1,
            label: "Nomor Debit Note",
            resourceUrl: (_, filter, data) =>
              `DebitNote?${genFieldQueryParamStr({
                sisaKomisi_$gt: 0,
                "konfirmasiInvoice.supplier.id": data?.supplier?.id,
              })}`,
            type: "async-table-select",
            dataViewKey: "debitNote.noDebitNote",
            resourceColumnLabelKey: "noDebitNote",
            resourceColumn: [
              {
                dataKey: "noDebitNote",
                label: "No. Debit Note",
              },
              {
                label: "Nomor Invoice Supplier",
                dataKey: "noInvoice",
              },
              {
                dataKey: "sisaKomisi",
                label: "Jumlah Komisi",
                type: "number",
              },
            ],
            isDataView: true,
            customOnChange: (data, setValue) => {
              setValue("debitNote", data)
              setValue("jumlahYangDibayar", data.sisaKomisi)
            },
          },
          {
            id: "debitNote.noInvoice",
            label: "Nomor Invoice Supplier",
            isDataView: true,
          },
          {
            id: "debitNote.jumlah",
            label: "Jumlah Komisi",
            type: "number",
            isDataView: true,
            disabled: true,
            suffix: {
              id: "debitNote.mataUang.mataUang",
              label: "Mata Uang",
              disabled: true,
            },
          },
          {
            id: "jumlahYangDibayar",
            label: "Jumlah yang Dibayar",
            type: "number",
            dataViewLabel: "Jumlah Bayar",
            formRule(data, detail) {
              const max = data.debitNote?.sisaKomisi ?? 0
              return {
                max: {
                  value: max,
                  message: `Jumlah yang dibayar tidak bisa lebih dari ${formatNumber(
                    max
                  )}`,
                },
              }
            },
          },
        ]),
      },
    ],
  }
}
export default generatePermohonanPembayaranTunaiForms
