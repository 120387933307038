import { getAPI } from "./get"
import { kursApiBaseUrl } from "./_common"

interface KursType {
  kurs: string
  value: number
}

export async function getKurs(): Promise<KursType[]> {
  const rsp = await getAPI<KursType[]>("/cimb-kurs", null, kursApiBaseUrl)

  return rsp
}

export async function getKursByMataUang(mataUang: string): Promise<number> {
  if (mataUang === "IDR" || mataUang === "RP") return 1
  const kurs = await getKurs()

  return kurs.find((i) => i.kurs === mataUang)?.value ?? 0
}
