import {
  GeneralMap,
  PenawaranTypeEnum,
  SuratJalanCustomerTypeEnum,
  SuratJalanInternalTypeEnum,
} from "common/type"
import {
  formatNumber,
  formatOnlyDate,
  generateCrudUrl,
  genFieldQueryParamObj,
  genFieldQueryParamStr,
  genFormItems,
  genOptionFromArray,
  getToday,
  isValueEmpty,
  strToSafeNum,
} from "common/util"
import generatePenawaranForms from "common/form-generator/penawaran"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import {
  karyawanOptionMapper,
  perkiraanMapper,
  perkiraanSearchKey,
  supplierOptionMapper,
  supplierSearchKey,
  voucherMapper,
  voucherResourceColumnKeys,
  voucherSearchKey,
} from "../mapper"
import { Text } from "@chakra-ui/react"
import { UseFormReturn } from "react-hook-form"
import generateSalesContractForm from "common/form-generator/sales-contract"
import generateLampiranKontrakForm from "common/form-generator/lampiran-kontrak"
import generateSuratJalanCustomer from "common/form-generator/surat-jalan-customer"
import generateSPBForm from "common/form-generator/spb"
import { getKursByMataUang } from "hooks/http/custom"
import { genBaseSortQueryParam } from "constant/url"
import {
  calculcateSuratJalanCustomerTotalHargaJual,
  getBaseTagihanSj,
  getPpnPercentageByPenawaran,
} from "common/procedures/surat-jalan-customer"
import { GLOB_VAR_PPN, GLOB_VAR_PPH } from "constant/global-var-keys"
import generateSuratJalanInternalForm from "common/form-generator/surat-jalan-internal"
import { TableCell } from "components/data-table"
import { LATEST_SUBMITTED_PELUNASAN_DATA_STORAGE_KEY } from "constant/storage"
import { getAdditionalCostPenawaran } from "common/procedures/penawaran"
import generateKonsinyasiForm from "common/form-generator/konsinyasi"
import { getSQM } from "common/procedures/speisfikasi"
import { StockAvailability } from "services/stock"
import { getAPI } from "hooks"
import { getValue } from "@testing-library/user-event/dist/utils"
import generateWorkOrderForm from "common/form-generator/work-order"

export const PenjualanPages: GeneralMap<BasePageProps<any>> = {
  konsinyasi: generateKonsinyasiForm(),
  "penawaran-stock": generatePenawaranForms(PenawaranTypeEnum.STOCK),
  "penawaran-indent": generatePenawaranForms(PenawaranTypeEnum.INDENT),
  "penawaran-khusus": generatePenawaranForms(PenawaranTypeEnum.KHUSUS),
  "sales-contract": generateSalesContractForm(PenawaranTypeEnum.INDENT),
  "lampiran-kontrak": generateLampiranKontrakForm(PenawaranTypeEnum.INDENT),
  "fabrikasi-barang": {
    title: "Pabrikasi Barang",
    permissionRequired: "",
    children: ["spb", "cetak-spb", "work-order", "work-order-khusus", "sttb"],
  },
  // permission unti here ----
  spb: generateSPBForm(PenawaranTypeEnum.STOCK),
  // sppb: generateSuratJalanInternalForm(SuratJalanInternalTypeEnum.SPPB),
  "cetak-spb": generateSPBForm(PenawaranTypeEnum.STOCK, true),
  "work-order": generateWorkOrderForm("STOCK"),
  "work-order-khusus": generateWorkOrderForm("BARANG_KHUSUS"),
  sttb: {
    title: "Surat Tanda Terima Barang (STTB)",
    permissionRequired: "SuratJalanInternal",
    filter: <BaseFilter keyName="sttb" />,
    generateCodeParam: {
      entityName: "SuratJalanInternal",
      entity: { typeSuratJalan: "STTB" },
    },
    requiredToAdd: ["Asal", "Tujuan", "Divisi"],
    disableAdd(data, filterData) {
      return (
        !filterData?.["asal.id"] ||
        !filterData?.["tujuan.id"] ||
        !filterData?.["divisi.id"]
      )
    },
    urls: {
      ...generateCrudUrl("surat-jalan-internal"),
      read: {
        endpoint: `/surat-jalan-internal`,
        param: genFieldQueryParamObj({
          typeSuratJalan: "STTB",
        }),
        method: "get",
      },
    },
    formGroupItems: genFormItems([
      {
        id: "typeSuratJalan",
        type: "hidden",
        value: "STTB",
      },
      {
        id: "no",
        label: "Nomor Surat Jalan",
        isDataView: true,
        isGeneratedCodeField: true,
        disabled: true,
        suffix: {
          id: "tanggal",
          label: "Tanggal",
          type: "date",
          disabled: true,
          value: getToday(),
          isDataView: true,
        },
      },
      {
        id: "blank",
        type: "blank",
        isOptional: true,
      },
      {
        id: "asal.id",
        label: "Cabang Workshop",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({
          isWorkshop: true,
        })}&${genBaseSortQueryParam()}`,
      },
      {
        id: "tujuan.id",
        label: "Cabang Tujuan",
        type: "async-select",
        isDataView: true,
        dataViewKey: "tujuan.nama",
        resourceUrl: `organisasi?${genFieldQueryParamStr(
          {}
        )}&${genBaseSortQueryParam()}`,
      },
      {
        id: "catatan",
        label: "Note",
        isDataView: true,
        isOptional: true,
        type: "text-area",
      },
    ]),
    additionalForms: [
      {
        id: "itemSuratJalanInternals",
        title: "Daftar Barang",
        formItems: [
          {
            id: "itemWorkOrder.id",
            type: "async-table-select",
            resourceUrl: (data, filterData) => {
              return `item-work-order?${genFieldQueryParamStr({
                "itemSuratJalanInternals.id": "null",
                "workOrderSfb.sfb.salesContract.penawaran.divisi.id":
                  filterData?.["divisi.id"],
                "workOrderSfb.sfb.salesContract.penawaran.organisasi.id":
                  filterData?.["tujuan.id"],
              })}`
            },
            resourceColumnLabelKey: "workOrderSfb.nomor",
            column: 1,
            resourceColumn: [
              {
                dataKey: "workOrderSfb.nomor",
                label: "Nomor WO",
              },
              {
                dataKey:
                  "workOrderSfb.sfb.salesContract.penawaran.organisasi.nama",
                label: "Cabang",
              },
              {
                dataKey:
                  "workOrderSfb.sfb.salesContract.penawaran.pelanggan.namaPanjang",
                label: "Customer",
              },
              {
                dataKey: "workOrderSfb.sfb.salesContract.noPoPelanggan",
                label: "PO Cust.",
              },
              {
                dataKey: "itemPenawaran.barang.kode",
                label: "Kode Barang",
                render(data, idx, globVars) {
                  return (
                    <TableCell>
                      {!!data.itemPenawaran
                        ? data.itemPenawaran?.barang?.kode
                        : data?.itemBarangJadi?.kodeBarang}
                    </TableCell>
                  )
                },
              },
              {
                dataKey: "itemPenawaran.barang.nama",
                label: "Nama Barang",
                render(data, idx, globVars) {
                  return (
                    <TableCell>
                      {!!data.itemPenawaran
                        ? data.itemPenawaran?.barang?.nama
                        : data?.itemBarangJadi?.namaBarang}
                    </TableCell>
                  )
                },
              },
              {
                dataKey: "itemPenawaran.spesifikasi.panjang",
                label: "L (mm)",
                type: "number",
                render(data, idx, globVars) {
                  return (
                    <TableCell>
                      {formatNumber(
                        (data.itemPenawaran ?? data.itemBarangJadi)?.spesifikasi
                          ?.panjang
                      )}
                    </TableCell>
                  )
                },
              },
              {
                dataKey: "itemPenawaran.spesifikasi.lebar",
                label: "W (mm)",
                type: "number",
                render(data, idx, globVars) {
                  return (
                    <TableCell>
                      {formatNumber(
                        (data.itemPenawaran ?? data.itemBarangJadi)?.spesifikasi
                          ?.lebar
                      )}
                    </TableCell>
                  )
                },
              },
              {
                dataKey: "jumlahAvailable",
                label: "Kuantitas",
              },
            ],
            label: "Nomor WO",
            dataViewKey: "itemWorkOrder.workOrderSfb.nomor",
            customOnChange: (data, setValue, getValue) => {
              setValue("itemWorkOrder", data)
              setValue(
                "spesifikasi",
                (data.itemPenawaran ?? data.itemBarangJadi)?.spesifikasi
              )
              setValue(
                "kodeBarang",
                !!data.itemPenawaran
                  ? data.itemPenawaran?.barang?.kode
                  : data.itemBarangJadi?.kodeBarang
              )
              setValue(
                "namaBarang",
                !!data.itemPenawaran
                  ? data.itemPenawaran?.barang?.nama
                  : data.itemBarangJadi?.namaBarang
              )
              if (
                data.workOrderSfb?.sfb?.salesContract?.penawaran?.tipe ===
                "TRIAL"
              ) {
                setValue("isTrial", true)
              }
              if (!getValue("keterangan")) {
                setValue(
                  "keterangan",
                  `${
                    data.workOrderSfb?.sfb?.noSpb
                  } - ${data.workOrderSfb?.sfb?.salesContract?.penawaran?.pelanggan?.namaPanjang?.toUpperCase()}`
                )
              }
              setValue("qty", +data.jumlahAvailable + +data.jumlahWaste)
            },
          },
          {
            id: "itemWorkOrder.workOrderSfb.sfb.noSpb",
            label: "Nomor SPB",
            disabled: true,
            suffix: {
              id: "kodeBarang",
              label: "Kode Barang",
              disabled: true,
              isHalfSize: true,
            },
          },
          {
            id: "itemWorkOrder.itemPenawaran.spesifikasi.panjang",
            type: "number",
            disabled: true,
            label: "Panjang",
            suffix: {
              id: "itemWorkOrder.itemPenawaran.spesifikasi.lebar",
              type: "number",
              disabled: true,
              label: "Lebar",
              isHalfSize: true,
            },
          },
          {
            id: "namaBarang",
            label: "Nama Barang",
            disabled: true,
            isDataView: true,
            columnRender(data, filterData, globVars) {
              return (
                <TableCell>
                  {!!data.itemWorkOrder?.itemPenawaran
                    ? data.itemWorkOrder?.itemPenawaran?.barang?.nama
                    : data?.itemWorkOrder?.itemBarangJadi?.namaBarang}
                </TableCell>
              )
            },
          },
          {
            id: "itemWorkOrder.workOrderSfb.sfb.noSpb",
            isIgnored: true,
            isDataView: true,
            label: "Kode Barang",
            columnRender(data, filterData, globVars) {
              return (
                <TableCell>
                  {!!data.itemWorkOrder?.itemPenawaran
                    ? data.itemWorkOrder?.itemPenawaran?.barang?.kode
                    : data?.itemWorkOrder?.itemBarangJadi?.kodeBarang}
                </TableCell>
              )
            },
          },
          {
            id: "qty",
            label: "Jumlah/Kuantitas",
            type: "number",
            disabled: true,
            isDataView: true,
            suffix: {
              id: "isTrial",
              label: "Trial",
              type: "check",
              isHalfSize: true,
              disabled: true,
            },
          },
          {
            id: "keterangan",
            label: "Keterangan",
            disabled: true,
          },
          {
            id: "spesifikasi.panjang",
            label: "Panjang",
            type: "ignored",
            isDataView: true,
            columnRender(data, filterData, globVars) {
              return (
                <TableCell>
                  {formatNumber(
                    (
                      data.itemWorkOrder?.itemPenawaran ??
                      data.itemWorkOrder?.itemBarangJadi
                    )?.spesifikasi?.panjang
                  )}
                </TableCell>
              )
            },
          },
          {
            id: "spesifikasi.lebar",
            label: "Lebar",
            type: "ignored",
            isDataView: true,
            columnRender(data, filterData, globVars) {
              return (
                <TableCell>
                  {formatNumber(
                    (
                      data.itemWorkOrder?.itemPenawaran ??
                      data.itemWorkOrder?.itemBarangJadi
                    )?.spesifikasi?.lebar
                  )}
                </TableCell>
              )
            },
          },
        ],
      },
    ],
  },
  "surat-jalan-customer": generateSuratJalanCustomer(
    SuratJalanCustomerTypeEnum.STOCK
  ),
  "surat-permintaan-barang": {
    title: "Surat Permintaan Barang (Antar Cabang)",
    urls: generateCrudUrl("permintaan-pengiriman-barang"),
    permissionRequired: "SuratPermintaanPengirimanBarang",
    filter: <BaseFilter keyName="sppb" />,
    generateCodeParam: (data) => {
      const asalKode = data.organisasiAsal?.kode
      if (!asalKode) return undefined
      return {
        entityName: "PermintaanPengirimanBarang",
        entity: {
          organisasiAsal: { kode: asalKode },
        },
      }
    },
    requiredToAdd: ["Cabang", "Divisi"],
    disableAdd(data, filterData) {
      return !filterData?.["organisasiAsal.id"] || !filterData?.["divisi.id"]
    },
    formGroupItems: genFormItems([
      {
        id: "organisasiAsal.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({})}`,
        customOnChange: (data, setValue, getValue, genCode) => {
          setValue("organisasiAsal", data)
          genCode()
        },
      },
      {
        id: "divisi.id",
        label: "Divisi",
        type: "async-select",
        resourceUrl: `divisi`,
      },
      {
        id: "no",
        isGeneratedCodeField: true,
        disabled: true,
        label: "No.",
        isDataView: true,
      },
      {
        id: "organisasiAsal.nama",
        isDataView: true,
        type: "ignored",
        label: "Cabang",
      },
      {
        id: "tanggal",
        label: "Tanggal",
        type: "date",
        value: getToday(),
      },
      {
        id: "organisasiTujuan.id",
        label: "Cabang Tujuan",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({})}`,
        dataViewKey: "organisasiTujuan.nama",
        isDataView: true,
      },
      {
        id: "sales.id",
        label: "SE",
        type: "async-select",
        resourceUrl: (data, filterData) =>
          `karyawan?${genFieldQueryParamStr({
            jenisJabatan: "SALES",
            "organisasi.id": filterData?.["organisasiAsal.id"],
            "divisi.id": filterData?.["divisi.id"],
          })}`,
      },
      {
        id: "cetakCounter",
        label: "Ctk",
        type: "ignored",
      },
    ]),
    additionalForms: [
      {
        id: "itemPermintaanPengirimanBarangs",
        title: "Daftar Barang",
        recalculateCallback(rhf) {
          const data = rhf.getValues()
          rhf.setValue(
            "spesifikasi.luas",
            (strToSafeNum(data?.spesifikasi?.panjang) *
              strToSafeNum(data?.spesifikasi?.lebar)) /
              1000000
          )
        },
        disableAdd: (data) => !data.divisi?.id,
        requiredToAdd: ["divisi"],
        formItems: [
          {
            id: "barang.id",
            type: "async-select",
            label: "Barang",
            resourceUrl: (_, filter, data) =>
              `barang?${genFieldQueryParamStr({
                "divisi.id_$In": `${data.divisi?.id};3`,
              })}`,
            dataViewKey: "barang.kode",
            dataViewLabel: "Kode",
            customOnChange: (data, setValue) => {
              setValue("barang", data)
            },
            suffix: {
              id: "barang.satuanJual.kode",
              label: "Satuan",
              disabled: true,
            },
          },
          {
            id: "barang.nama",
            label: "Barang",
            type: "ignored",
            isDataView: true,
          },
          {
            id: "spesifikasi.panjang",
            type: "number",
            label: "Panjang (MM)",
            value: 0,
            isDataView: true,
            isCallRecalculateCb: true,
          },
          {
            id: "spesifikasi.lebar",
            type: "number",
            label: "Lebar (MM)",
            value: 0,
            isDataView: true,
            isCallRecalculateCb: true,
          },
          {
            id: "spesifikasi.luas",
            type: "number",
            disabled: true,
            label: "Luas (SQM)",
          },
          {
            id: "qty",
            type: "number",
            label: "Qty",
            isDataView: true,
          },
          {
            id: "keterangan",
            type: "text-area",
            label: "Keterangan",
            isDataView: true,
          },
        ],
      },
    ],
  },
  "sji-antar-cabang": generateSuratJalanInternalForm(
    SuratJalanInternalTypeEnum.SPPB
  ),
  "retur-barang": {
    title: "Retur Barang",
    urls: generateCrudUrl("retur-customer"),
    filter: <BaseFilter keyName="returBarang" />,
    permissionRequired: "ReturCustomer",
    requiredToAdd: ["Organisasi", "Divisi"],
    disableAdd(data, filterData) {
      return (
        !filterData?.["suratJalanCustomer.asal.id"] ||
        !filterData?.["suratJalanCustomer.salesContract.penawaran.divisi.id"]
      )
    },
    generateCodeParam: {
      entityName: "ReturCustomer",
      entity: {},
    },
    formGroupItems: genFormItems([
      {
        id: "nomor",
        disabled: true,
        isGeneratedCodeField: true,
        label: "Nomor",
        dataViewLabel: "Nomor Retur",
        suffix: {
          id: "tanggal",
          type: "date",
          label: "Tanggal",
          value: getToday(),
          isDataView: true,
          isHalfSize: true,
        },
      },
      {
        id: "suratJalanCustomer.asal.id",
        type: "async-select",
        label: "Cabang",
        resourceUrl: "organisasi",
        suffix: {
          id: "suratJalanCustomer.salesContract.penawaran.divisi.id",
          type: "async-select",
          label: "Divisi",
          resourceUrl: "divisi",
          isHalfSize: true,
        },
      },

      {
        id: "suratJalanCustomer.id",
        label: "Invoice",
        type: "async-table-select",
        column: 1,
        resourceColumn: [
          {
            dataKey: "no",
            label: "Nomor Surat Jalan",
          },
          {
            dataKey: "tanggal",
            label: "Tanggal",
          },
          {
            dataKey: "noInvoice",
            label: "Nomor Invoice",
          },
          {
            dataKey: "salesContract.penawaran.tipe",
            label: "Jenis Penawaran",
          },
          {
            dataKey: "sisaRetur",
            label: "Jumlah",
            type: "number",
          },
        ],
        resourceUrl: (data, filter) => {
          const now = new Date()
          const limitStart = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000)
          const q: any = {
            "suratJalanCustomer.sisaRetur_$gt": "0.00001",
            "suratJalanCustomer.tanggal_$btwStart": formatOnlyDate(limitStart),
            "suratJalanCustomer.tanggal_$btwEnd": formatOnlyDate(now),
            "pelunasanPiutangs.id": "null",
          }
          const orgId = filter?.["suratJalanCustomer.asal.id"]
          const isPpn =
            filter?.["suratJalanCustomer.salesContract.penawaran.isPpn"]
          const divisiId =
            filter?.["suratJalanCustomer.salesContract.penawaran.divisi.id"]
          q["asal.id"] = orgId
          q["salesContract.penawaran.isPpn"] = isPpn
          q["salesContract.penawaran.divisi.id"] = divisiId

          return `surat-jalan-customer?${genFieldQueryParamStr(
            q
          )}&${genBaseSortQueryParam("tanggal", false)}`
        },
        resourceColumnLabelKey: "noInvoice",
        customOnChange: (data, setValue) => {
          setValue("suratJalanCustomer", data)
          setValue("pelanggan", data.tujuan)
        },
      },

      {
        id: "suratJalanCustomer.salesContract.penawaran.pelanggan.namaPanjang",
        label: "Customer",
        disabled: true,
        isDataView: true,
      },
      {
        id: "jenisRetur",
        label: "Jenis Retur",
        type: "radio",
        options: genOptionFromArray(["BATAL", "GARANSI"]),
        isDataView: true,
      },
      {
        id: "penyebab.kesalahan.id",
        type: "async-select",
        label: "Klasifikasi Retur",
        resourceUrl: `klasifikasi-penyebab-retur?${genBaseSortQueryParam()}`,
      },
      {
        id: "tindakan",
        label: "Tindakan",
        type: "radio",
        renderCondition(formData, formMode, filterData) {
          return !!formData.jenisRetur
        },
        options: (data) => {
          return genOptionFromArray(
            data.jenisRetur === "GARANSI"
              ? ["GANTI_BARANG", "PERBAIKAN"]
              : ["NOTA_RETUR", "GANTI_SJ"]
          )
        },
        isDataView: true,
      },
      {
        id: "blank",
        type: "blank",
        renderCondition(formData, formMode, filterData) {
          return !formData.jenisRetur
        },
      },
      {
        id: "penyebab.id",
        type: "async-select",
        label: "Penyebab",
        resourceUrl: (data) => {
          if (!data?.penyebab?.kesalahan?.id) {
            return `penyebab-retur?${genBaseSortQueryParam("kode")}`
          }
          return `penyebab-retur?${genBaseSortQueryParam(
            "kode"
          )}&${genFieldQueryParamStr({
            "kesalahan.id": data?.penyebab?.kesalahan?.id,
          })}`
        },
        resourceMapper: (data) => ({
          label: `${data.kode} - ${data.keterangan} - ${data.returCustomers}`,
          value: data.id,
        }),
      },
      {
        id: "targetStock",
        type: "radio",
        label: "Stock Tujuan",
        options: genOptionFromArray(["AVAILABLE", "WASTE"]),
      },
    ]),
    responseMapper(data) {
      return {
        ...data,
        itemReturCustomers: (data.itemReturCustomers ?? []).map((i: any) => ({
          ...i,
          namaBarang: !i?.itemSuratJalanCustomer?.itemPenawaran?.id
            ? i.itemSuratJalanCustomer?.itemBarangJadi.namaBarang
            : i.itemSuratJalanCustomer?.itemPenawaran?.barang?.nama,
          kodeBarang: !i?.itemSuratJalanCustomer?.itemPenawaran?.id
            ? i.itemSuratJalanCustomer?.itemBarangJadi.kodeBarang
            : i.itemSuratJalanCustomer?.itemPenawaran?.barang?.kode,
          spesifikasi: (
            data?.itemSuratJalanCustomer?.itemPenawaran ??
            data?.itemSuratJalanCustomer?.itemBarangJadi
          )?.spesifikasi,
        })),
      }
    },
    additionalForms: [
      {
        id: "itemReturCustomers",
        title: "Daftar Barang",
        disableAdd: (data) => !data.suratJalanCustomer?.id,
        requiredToAdd: ["Invoice"],
        formItems: genFormItems([
          {
            column: 1,
            id: "itemSuratJalanCustomer.id",
            label: "Barang",
            type: "async-table-select",
            resourceUrl: (_, filter, data) => {
              return `item-surat-jalan-customer?${genFieldQueryParamStr({
                "suratJalanCustomer.id": data.suratJalanCustomer?.id,
                sisaRetur_$gt: "0.001",
              })}`
            },
            resourceColumn: [
              {
                dataKey: "itemPenawaran.barang.kode",
                label: "Kode",
                render(data, idx, globVars) {
                  return (
                    <TableCell>
                      {data.itemPenawaran?.id
                        ? data.itemPenawaran.barang.kode
                        : data.itemBarangJadi.kodeBarang}
                    </TableCell>
                  )
                },
              },
              {
                dataKey: "itemPenawaran.barang.nama",
                label: "Nama Barang",
                render(data, idx, globVars) {
                  return (
                    <TableCell>
                      {data.itemPenawaran?.id
                        ? data.itemPenawaran.barang.nama
                        : data.itemBarangJadi.namaBarang}
                    </TableCell>
                  )
                },
              },
              { dataKey: "sisaRetur", label: "Jumlah", type: "number" },
              {
                dataKey: "itemPenawaran.spesifikasi.panjang",
                label: "Panjang",
                type: "number",
                render(data, idx, globVars) {
                  return (
                    <TableCell>
                      {formatNumber(
                        (data.itemPenawaran ?? data.itemBarangJadi).spesifikasi
                          ?.panjang
                      )}
                    </TableCell>
                  )
                },
              },
              {
                dataKey: "itemPenawaran.spesifikasi.lebar",
                label: "Lebar",
                type: "number",
                render(data, idx, globVars) {
                  return (
                    <TableCell>
                      {formatNumber(
                        (data.itemPenawaran ?? data.itemBarangJadi).spesifikasi
                          ?.lebar
                      )}
                    </TableCell>
                  )
                },
              },
            ],
            resourceColumnLabelKey: "namaBarang",
            customOnChange: (data, setValue) => {
              setValue("itemSuratJalanCustomer", data)
              setValue(
                "namaBarang",
                !data?.itemPenawaran?.id
                  ? data.itemBarangJadi.namaBarang
                  : data.itemPenawaran?.barang?.nama
              )

              setValue(
                "noSalesContract",
                (data?.itemPenawaran ?? data.itemBarangJadi)?.penawaran
                  ?.salesContracts?.[0]?.noSalesContract
              )

              setValue(
                "spesifikasi",
                (data?.itemPenawaran ?? data.itemBarangJadi)?.spesifikasi
              )

              setValue(
                "kodeBarang",
                !data?.itemPenawaran?.id
                  ? data.itemBarangJadi.kodeBarang
                  : data.itemPenawaran?.barang?.kode
              )
            },
          },
          {
            id: "itemSuratJalanCustomer.suratJalanCustomer.no",
            label: "Nomor Surat Jalan",
            disabled: true,
          },
          {
            id: "noSalesContract",
            label: "Nomor Sales Contract",
            disabled: true,
          },
          {
            id: "kodeBarang",
            label: "Kode",
            isIgnored: true,
            isDataView: true,
          },
          {
            id: "namaBarang",
            label: "Nama Barang",
            isIgnored: true,
            isDataView: true,
          },
          {
            id: "jumlahRetur",
            label: "Jumlah",
            isDataView: true,
            isIgnored: true,
          },
          {
            id: "spesifikasi.panjang",
            label: "Panjang",
            type: "number",
            isDataView: true,
            disabled: true,
            suffix: {
              id: "spesifikasi.lebar",
              label: "Lebar",
              type: "number",
              disabled: true,
              isHalfSize: true,
            },
          },
          {
            id: "spesifikasi.lebar",
            label: "Lebar",
            type: "ignored",
            isDataView: true,
          },
          {
            id: "itemSuratJalanCustomer.sisaRetur",
            label: "Jumlah",
            type: "number",
            disabled: true,
            suffix: {
              id: "jumlahRetur",
              label: "Jumlah Retur",
              type: "number",
              isHalfSize: true,
              formRule: (data) => {
                const max = data?.itemSuratJalanCustomer?.sisaRetur ?? 0
                return {
                  max: {
                    message: `Jumlah retur tidak boleh melebihi ${formatNumber(
                      max
                    )}`,
                    value: max,
                  },
                }
              },
            },
          },
          {
            id: "keterangan",
            label: "Keterangan",
            type: "text-area",
          },
        ]),
      },
    ],
  },
  "retur-supplier": {
    title: "Retur ke Supplier",
    urls: generateCrudUrl("retur-supplier"),
    permissionRequired: "ReturSupplier",
    filter: <BaseFilter keyName="returSupplier" />,
    generateCodeParam: {
      entity: {},
      entityName: "ReturSupplier",
    },
    formGroupItems: genFormItems([
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr(
          {}
        )}&${genBaseSortQueryParam()}`,
      },
      {
        id: "divisi.id",
        label: "Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      {
        id: "nomor",
        label: "Nomor Pengajuan",
        disabled: true,
        isGeneratedCodeField: true,
        isDataView: true,
      },
      {
        id: "tanggal",
        label: "Tanggal",
        type: "date",
        isDataView: true,
        value: getToday(),
      },
      {
        id: "supplier.id",
        label: "Supplier",
        type: "async-select",
        resourceUrl: `supplier?${genBaseSortQueryParam("namaPanjang")}`,
        resourceMapper: supplierOptionMapper,
        dataViewKey: "supplier.kodeSupplier",
        searchBy: supplierSearchKey,
        dataViewLabel: "Kode Supplier",
        customOnChange: (data, setValue) => {
          setValue("supplier", data)
        },
      },
      {
        id: "supplier.namaPanjang",
        label: "Nama Supplier",
        type: "ignored",
        isDataView: true,
      },
    ]),
    additionalForms: [
      {
        id: "itemReturSuppliers",
        title: "Daftar Barang",
        recalculateCallback: (rhf) => {
          const data = rhf.watch()

          rhf.setValue(
            "luas",
            strToSafeNum(data.spesifikasi?.panjang) *
              strToSafeNum(data.spesifikasi?.lebar)
          )
        },
        formItems: [
          {
            id: "barang.id",
            label: "Barang",
            dataViewKey: "barang.kode",
            isDataView: true,
            type: "async-select",
            resourceUrl: `barang?${genBaseSortQueryParam()}`,
            customOnChange: (data, setValue) => {
              setValue("barang", data)
            },
          },
          {
            id: "barang.nama",
            label: "Nama Barang",
            disabled: true,
            isDataView: true,
          },
          {
            id: "qty",
            label: "Qty",
            type: "number",
            isDataView: true,
          },
          {
            id: "spesifikasi.panjang",
            label: "Panjang",
            type: "number",
            isCallRecalculateCb: true,
          },
          {
            id: "spesifikasi.lebar",
            label: "Lebar",
            type: "number",
            isCallRecalculateCb: true,
          },
          {
            id: "spesifikasi.luas",
            type: "number",
            label: "Luas",
            disabled: true,
          },
          {
            id: "keterangan",
            type: "text-area",
            label: "Keterangan",
          },
        ],
      },
    ],
  },
  "kontra-bon": {
    title: "Tanggal Kirim dan Kontra Bon Invoice",
    filter: <BaseFilter keyName="kontraBon" />,
    urls: {
      ...generateCrudUrl("kontra-bon"),
      read: {
        endpoint: `/invoice-pelanggan`,
        method: "get",
        param: genFieldQueryParamObj({
          "penawaran.tipe_$NotIn": "TRIAL;TTLC",
        }),
      },
    },
    cetakActions: [],
    permissionRequired: "KontraBon",
    requiredToAdd: ["Cabang"],
    disableAdd(data, filterData) {
      return !filterData?.["penawaran.organisasi.id"]
    },
    hideAdd: true,
    responseMapper(data) {
      const { kontraBons, ...invoicePelanggan } = data
      const [kontraBon = {}] = kontraBons ?? []

      return {
        ...kontraBon,
        invoicePelanggan,
        noSuratJalan: (
          invoicePelanggan.suratJalanCustomer ?? invoicePelanggan.instruksiJos
        )?.no,
      }
    },
    formGroupItems: genFormItems([
      {
        id: "invoicePelanggan.no",
        label: "Nomor Invoice",
        isDataView: true,
        disabled: true,
      },
      {
        id: "invoicePelanggan.id",
        type: "hidden",
      },
      {
        id: "noSuratJalan",
        label: "Nomor Surat Jalan",
        disabled: true,
      },
      {
        id: "invoicePelanggan.tanggal",
        type: "ignored",
        dataViewLabel: "Tanggal",
      },
      {
        id: "invoicePelanggan.tanggalInvoice",
        label: "Tanggal",
        type: "date",
        disabled: true,
      },
      {
        id: "invoicePelanggan.penawaran.salesContracts.0.noPoPelanggan",
        label: "PO. Customer",
        disabled: true,
      },
      {
        id: "invoicePelanggan.penawaran.pelanggan.namaPanjang",
        label: "Customer",
        disabled: true,
        suffix: {
          id: "invoicePelanggan.penawaran.pelanggan.kodePelanggan",
          label: "Kode",
          disabled: true,
        },
      },
      {
        id: "invoicePelanggan.penawaran.pelanggan.namaPanjang",
        isIgnored: true,
        label: "Customers",
        isDataView: true,
      },
      {
        id: "invoicePelanggan.penawaran.mataUang.mataUang",
        label: "Mata Uang",
        value: "RP",
        disabled: true,
      },
      {
        id: "invoicePelanggan.totalTagihan",
        label: "Jumlah Piutang",
        strong: true,
        type: "number",
        dataViewLabel: "Jumlah Tagihan",
        disabled: true,
      },
      {
        id: "tanggal",
        label: "Tanggal Kontra Bon",
        type: "date",
        isDataView: true,
        isOptional: true,
        ignoreToSearch: true,
      },
      {
        id: "tanggalJanjiBayar",
        label: "Tanggal Janji Bayar",
        isOptional: true,
        type: "date",
        dataViewLabel: "Tanggal Bayar",
        ignoreToSearch: true,
      },
      {
        id: "no",
        label: "Nomor Kontra Bon",
        isGeneratedCodeField: true,
        isOptional: true,
        ignoreToSearch: true,
      },
      {
        id: "tanggalSuratJalanDiterimaPusat",
        label: "Tanggal SJ diterima Pusat",
        isOptional: true,
        type: "date",
        ignoreToSearch: true,
      },
      {
        id: "tanggalSuratJalanDiterimaCabang",
        label: "Tanggal SJ diterima Cabang",
        isOptional: true,
        type: "date",
        ignoreToSearch: true,
      },
      {
        id: "tanggalSuratJalanDiterimaKolektor",
        label: "Tanggal diterima Collector",
        isOptional: true,
        type: "date",
        ignoreToSearch: true,
      },
      {
        id: "tanggalDiterimaOs",
        label: "Tanggal diterima OS",
        isOptional: true,
        type: "date",
        ignoreToSearch: true,
      },
      {
        id: "keterangan",
        label: "Catatan/Keterangan",
        type: "text-area",
        ignoreToSearch: true,
      },
    ]),
  },
  "pelunasan-invoice": {
    title: "Penerimaan dari Customer/Pelunasan Invoice",
    urls: generateCrudUrl("pelunasan-piutang"),
    filter: <BaseFilter keyName="pelunasanPiutang" />,
    permissionRequired: "PelunasanPiutang",
    requiredToAdd: ["Cabang"],
    disableAdd(data, filterData) {
      return !filterData?.["invoicePelanggan.penawaran.organisasi.id"]
    },
    generateCodeParam: (data) => {
      if (!data.invoicePelanggan?.penawaran?.organisasi?.kode) return undefined
      return {
        entityName: "PelunasanPiutang",
        entity: {
          invoicePelanggan: {
            suratJalanCustomer: {
              asal: {
                kode: data.invoicePelanggan?.penawaran?.organisasi?.kode,
                id: data.invoicePelanggan?.penawaran?.organisasi?.id,
              },
            },
          },
        },
      }
    },
    customFormDataMapper(data, filterData) {
      localStorage.setItem(
        LATEST_SUBMITTED_PELUNASAN_DATA_STORAGE_KEY,
        JSON.stringify(data)
      )
      return data
    },
    responseMapper(data) {
      return {
        ...data,
        jumlahPiutang: +data.netto + +data.ppn - +data.pph,
        total: +data.jumlah + +data.biayaBank - +data.pendapatanLain,
      }
    },
    formGroupItems: genFormItems([
      {
        id: "invoicePelanggan.id",
        label: "No. Invoice",
        column: 1,
        type: "async-table-select",
        resourceColumnLabelKey: "no",
        resourceUrl: (data, filterData) => {
          const orgId = filterData["invoicePelanggan.penawaran.organisasi.id"]
          if (!orgId) return ""
          return `invoice-pelanggan?${genFieldQueryParamStr({
            sisaTagihan_$gt: "0.001",
            "penawaran.organisasi.id": orgId,
            tanggalInvoice: "notNull",
          })}&${genBaseSortQueryParam("tanggalInvoice", false)}`
        },
        resourceColumn: [
          {
            dataKey: "no",
            label: "Nomor Invoice",
          },
          {
            dataKey: "tanggalInvoice",
            label: "Tanggal",
          },
          {
            dataKey: "penawaran.pelanggan.namaPanjang",
            label: "Customers",
          },
          {
            dataKey: "sisaTagihan",
            label: "Jumlah",
            ignoreToSearch: true,
            type: "number",
          },
          {
            dataKey: "penawaran.mataUang.mataUang",
            label: "Valas",
            render(data, idx, globVars) {
              return (
                <TableCell>
                  {data.salesContract?.penawaran?.tipe === "TTLC"
                    ? data.salesContract?.penawaran?.mataUang?.mataUang
                    : "RP"}
                </TableCell>
              )
            },
          },
        ],
        disabled(data, detailData, formMode, globVars) {
          return formMode === "update"
        },
        customOnChange: (invoiceData, setValue, getValue, genCb, globVars) => {
          const penawaran = invoiceData.penawaran
          const sjCustomer = invoiceData.suratJalanCustomer
          const instruksiJos = invoiceData.instruksiJos
          if (!getValue("netto")) {
            const jenisPajak = invoiceData.penawaran?.pelanggan?.barang
            setValue("jenisPajak", jenisPajak)

            const jumlahTagihanCustomer = +invoiceData.sisaTagihan
            let ppn = 0
            let nett = jumlahTagihanCustomer
            const ppnPercentage = getPpnPercentageByPenawaran(
              penawaran,
              strToSafeNum(globVars.get(GLOB_VAR_PPN), 11)
            )
            const baseTotal = getBaseTagihanSj(
              sjCustomer,
              penawaran.diskon ?? 0
            )
            let biaya = getAdditionalCostPenawaran(
              penawaran,
              (1 + ppnPercentage) * baseTotal
            )
            if (nett > biaya) {
              nett -= biaya
            } else {
              biaya = 0
            }

            nett /= 1 + ppnPercentage
            ppn = ppnPercentage * nett

            const pph = [
              PenawaranTypeEnum.JOS,
              PenawaranTypeEnum.SERVICE,
            ].includes(penawaran.tipe)
              ? (strToSafeNum(globVars.get(GLOB_VAR_PPH), 2) / 100) * nett
              : 0
            const mataUang =
              penawaran.tipe === "TTLC"
                ? penawaran.mataUang
                : { mataUang: "RP" }
            setValue("mataUang", mataUang)
            setValue("invoicePelanggan", invoiceData)
            setValue("ppn", ppn.toFixed(2))
            setValue("pph", pph.toFixed(2))
            setValue("biaya", biaya.toFixed(2))
            setValue("netto", nett.toFixed(2))
            setValue("jumlahPiutang", (nett + biaya + ppn - pph).toFixed(2))
            if (mataUang) {
              getKursByMataUang(mataUang.mataUang).then((rate) => {
                setValue("rate", rate)
              })
            }
            genCb()
          }

          const isService = [
            PenawaranTypeEnum.SERVICE,
            PenawaranTypeEnum.JOS,
          ].includes(penawaran?.tipe)
          setValue("isService", isService)

          setValue("noSj", (sjCustomer ?? instruksiJos)?.no)
        },
      },
      {
        id: "nomor",
        label: "Nomor",
        isGeneratedCodeField: true,
        disabled: true,
        dataViewLabel: "Nomor Pelunasan",
      },
      {
        id: "voucher.id",
        label: "Voucher",
        type: "async-select",
        searchBy: voucherSearchKey,
        resourceMapper: voucherMapper,
        disabled(data, detailData, formMode, globVars) {
          return !data.invoicePelanggan?.id
        },
        resourceColumnLabelKey: voucherResourceColumnKeys,
        resourceUrl: (_, filterData, data) => {
          const orgId = filterData["invoicePelanggan.penawaran.organisasi.id"]
          const isPpn = !!data.invoicePelanggan?.penawaran?.isPpn
          return `voucher?${genFieldQueryParamStr({
            "organisasi.id": orgId,
            "$or_voucher.jenis||is_jurnal_penyesuaian": "DEBIT||true",
            isUsingPpn: isPpn,
          })}&${genBaseSortQueryParam("kodeVoucher")}`
        },
        customOnChange: (data, setValue, getValue) => {
          setValue("voucher", data)
          if (!!data?.perkiraan?.nomorPerkiraan) {
            setValue("noPerkiraan", data?.perkiraan)
          } else {
            if (isValueEmpty(getValue("noPerkiraan.id"))) {
              setValue("noPerkiraan.id", null)
              setValue("noPerkiraan.nomorPerkiraan", null)
            }
          }

          if (!!data?.mataUang) {
            getKursByMataUang(data.mataUang.mataUang).then((res) =>
              setValue("rateValas", res)
            )
          }
        },
      },
      {
        id: "tanggal",
        label: "Tanggal",
        type: "date",
        value: getToday(),
        isDataView: true,
      },
      {
        id: "noPerkiraan.id",
        // disabled: (data, detail) => {
        //   return (
        //     !data?.voucher?.id || !!data?.voucher?.perkiraan?.nomorPerkiraan
        //   )
        // },
        label: "Nomor Akun",
        type: "async-select",
        resourceUrl: (data, filterData) => {
          const orgId = filterData["invoicePelanggan.penawaran.organisasi.id"]
          return `perkiraan?${genFieldQueryParamStr({
            "$or_organisasi.id||organisasi.id": `${orgId}||null`,
          })}`
        },
        resourceMapper: perkiraanMapper,
        resourceColumnLabelKey:
          "noPerkiraan.nomorPerkiraan,noPerkiraan.keterangan",
        searchBy: perkiraanSearchKey,
      },
      {
        id: "invoicePelanggan.no",
        label: "Nomor Invoice",
        type: "ignored",
        disabled: true,
        isDataView: true,
      },
      {
        id: "invoicePelanggan.tanggal",
        label: "Tanggal Invoice",
        type: "date",
        disabled: true,
      },
      {
        id: "invoicePelanggan.penawaran.pelanggan.namaPanjang",
        label: "Customers",
        isDataView: true,
        disabled: true,
        suffix: {
          id: "invoicePelanggan.penawaran.pelanggan.kodePelanggan",
          label: "Kode",
          disabled: true,
        },
      },
      {
        id: "infoInvoice",
        label: "Informasi Invoice",
        type: "title",
      },
      {
        id: "blank",
        type: "blank",
      },
      {
        id: "noSj",
        label: "Nomor Surat Jalan",
        disabled: true,
      },
      {
        id: "netto",
        disabled: true,
        label: "Netto",
        type: "number",
        suffix: {
          id: "biaya",
          disabled: true,
          label: "Biaya",
          type: "number",
        },
      },
      {
        id: "ppn",
        disabled: true,
        label: "PPN",
        type: "number",
        // disabled: (data) => data.jenisPajak === "A2",
      },
      {
        id: "pph",
        disabled: true,
        label: "PPh",
        type: "number",
      },
      {
        id: "jenisPajak",
        label: "Jenis Pajak",
        disabled: true,
      },
      {
        id: "mataUang.mataUang",
        label: "Mata Uang",
        disabled: true,
      },
      {
        id: "jumlahPiutang",
        type: "number",
        disabled: true,
        strong: true,
        label: "Jumlah Piutang",
        isCallRecalculateCb: true,
        suffix: {
          id: "isService",
          type: "check",
          label: "Service",
          disabled: true,
        },
      },
      {
        id: "catatanNegoRate",
        label: "Catatan Nego Rate",
        value: 0,
        suffix: {
          id: "rate",
          type: "number",
          label: "Rate",
          disabled: true,
        },
      },
      {
        id: "metodePembayaran",
        type: "radio",
        label: "Jenis",
        options: genOptionFromArray(["CASH", "TRANSFER", "CEK", "GIRO"]),
        customOnChange: (data, setValue) => {
          if (data === "CASH") {
            setValue("namaBank", null)
          }
          if (["CASH", "TRANSFER"].includes(data)) {
            setValue("tanggalJatuhTempo", null)
            setValue("noCekGiro", null)
          }
          if (["CEK", "GIRO"].includes(data)) {
            setValue("tanggalJatuhTempo", null)
          }
        },
      },
      {
        id: "namaBank",
        label: "Nama Bank",
        disabled(data, detailData, formMode, globVars) {
          return data.metodePembayaran === "CASH"
        },
      },
      {
        id: "noCekGiro",
        label: "No. Cek/Giro",
        renderCondition: (data) =>
          data.metodePembayaran === "CEK" || data.metodePembayaran === "GIRO",
      },
      {
        id: "tanggalJatuhTempo",
        type: "date",
        value: getToday(),
        label: "Tanggal Jatuh Tempo",
        renderCondition: (data) =>
          data.metodePembayaran === "CEK" || data.metodePembayaran === "GIRO",
        suffix: {
          id: "pasteButton",
          type: "button",
          label: "Paste",
          customOnChange(data, setValue) {
            const latestPelunasanDataStr = localStorage.getItem(
              LATEST_SUBMITTED_PELUNASAN_DATA_STORAGE_KEY
            )
            if (!latestPelunasanDataStr) return
            const latestPelunasanData = JSON.parse(latestPelunasanDataStr)
            setValue("tanggalJatuhTempo", latestPelunasanData.tanggalJatuhTempo)
            setValue("namaBank", latestPelunasanData.namaBank)
            setValue("noCekGiro", latestPelunasanData.noCekGiro)
            setValue("metodePembayaran", latestPelunasanData.metodePembayaran)
          },
        },
      },
      {
        id: "jumlah",
        type: "number",
        label: "Jumlah",
        value: 0,
        customOnChange: (data, setValue, getValue) => {
          const formData = getValue()
          const jlhPiutang = strToSafeNum(formData.jumlahPiutang)
          const jlh = strToSafeNum(data)
          if (jlhPiutang > data) {
            setValue("biayaBank", jlhPiutang - jlh)
            setValue("pendapatanLain", 0)
          } else {
            setValue("pendapatanLain", jlh - jlhPiutang)
            setValue("biayaBank", 0)
          }

          setValue("total", jlhPiutang)
        },
      },
      {
        id: "biayaBank",
        type: "number",
        label: "Biaya Bank",
        value: 0,
        customOnChange: (data, setValue, getValue) => {
          const formData = getValue()
          setValue(
            "total",
            strToSafeNum(formData.jumlah) +
              strToSafeNum(data) -
              strToSafeNum(formData.pendapatanLain)
          )
        },
      },
      {
        id: "pendapatanLain",
        label: "Pendapatan Lain - Lain",
        type: "number",
        value: 0,
        customOnChange: (data, setValue, getValue) => {
          const formData = getValue()
          setValue(
            "total",
            strToSafeNum(formData.jumlah) +
              strToSafeNum(formData.biayaBank) -
              strToSafeNum(data)
          )
        },
      },
      {
        id: "total",
        label: "Total",
        disabled: true,
        strong: true,
        type: "number",
        dataViewLabel: "Jumlah Bayar",
        columnRender(data) {
          return (
            <TableCell>
              {formatNumber(
                +data.jumlah + +data.biayaBank + +data.pendapatanLain
              )}
            </TableCell>
          )
        },
      },
      // TODO: implement paste button here
    ]),
  },
  "closing-penawaran-trial": {
    title: "Closing Penawaran Trial",
    filter: <BaseFilter keyName="kontraBon" />,
    urls: {
      ...generateCrudUrl("closing-penawaran-trial"),
      read: {
        endpoint: `/invoice-pelanggan`,
        method: "get",
        param: genFieldQueryParamObj({
          "penawaran.tipe": "TRIAL",
        }),
      },
    },
    hideAdd: true,
    responseMapper(data) {
      const { kontraBons, ...invoicePelanggan } = data
      const [kontraBonFull = {}] = kontraBons ?? []

      const { closingPenawaranTrial, ...kontraBon } = kontraBonFull

      return {
        ...closingPenawaranTrial,
        kontraBon: {
          ...kontraBon,
          invoicePelanggan,
          pelanggan: invoicePelanggan?.penawaran?.pelanggan,
        },
      }
    },
    permissionRequired: "ClosingPenawaranTrial",
    formGroupItems: genFormItems([
      {
        id: "kontraBon.invoicePelanggan.suratJalanCustomer.no",
        label: "No. Surat Jalan",
        column: 1,
        isDataView: true,
        disabled: true,
      },
      {
        id: "kontraBon.invoicePelanggan.id",
        type: "hidden",
      },
      {
        id: "kontraBon.invoicePelanggan.tanggal",
        label: "Tanggal",
        type: "date",
        dataViewLabel: "Tanggal",
        disabled: true,
      },
      {
        id: "kontraBon.invoicePelanggan.no",
        label: "No. Invoice",
        disabled: true,
        isDataView: true,
      },
      {
        id: "kontraBon.invoicePelanggan.penawaran.pelanggan.namaPanjang",
        label: "Customer",
        disabled: true,
        isDataView: true,
      },
      {
        id: "kontraBon.invoicePelanggan.penawaran.mataUang.mataUang",
        label: "Mata Uang",
        value: "RP",
        disabled: true,
      },
      {
        id: "kontraBon.invoicePelanggan.penawaran.tanggalJatuhTempo",
        label: "Jatuh Tempo Trial",
        type: "date",
        disabled: true,
      },

      {
        id: "isTrialBatal",
        label: "Trial Batal",
        type: "check",
        value: false,
      },
      {
        id: "tglTrialBatal",
        label: "Tgl Trial Batal",
        type: "date",
        value: getToday(),
        renderCondition(formData, formMode, filterData) {
          return !!formData.isTrialBatal
        },
      },
      {
        id: "jenisBatal",
        type: "radio",
        options: genOptionFromArray(["BIAYA", "KEMBALI_KE_STOK"]),
        label: "Jenis Batal",
        renderCondition(formData, formMode, filterData) {
          return !!formData.isTrialBatal
        },
      },
      {
        id: "kontraBon.invoicePelanggan.totalTagihan",
        strong: true,
        label: "Jumlah Piutang",
        dataViewLabel: "Jumlah Tagihan",
        type: "number",
        disabled: true,
      },
      {
        id: "blank",
        type: "blank",
      },
      {
        id: "kontraBon.tanggal",
        type: "date",
        label: "Tanggal Kontra Bon",
        isOptional: true,
        isDataView: true,
      },
      {
        id: "kontraBon.tanggalJanjiBayar",
        label: "Tgl Janji Bayar",
        type: "date",
        isOptional: true,
        dataViewLabel: "Tanggal Bayar",
      },
      {
        id: "kontraBon.no",
        label: "No. Kontra Bon",
        isOptional: true,
      },
      {
        id: "kontraBon.tanggalSuratJalanDiterimaPusat",
        label: "Tanggal SJ diterima Pusat",
        type: "date",
        isOptional: true,
      },
      {
        id: "kontraBon.tanggalSuratJalanDiterimaCabang",
        label: "Tanggal SJ diterima Cabang",
        type: "date",
        isOptional: true,
      },
      {
        id: "kontraBon.tanggalSuratJalanDiterimaKolektor",
        label: "Tanggal diterima Collector",
        type: "date",
        isOptional: true,
      },
      {
        id: "kontraBon.tanggalDiterimaOs",
        label: "Tanggal diterima OS",
        type: "date",
        isOptional: true,
      },
      {
        id: "kontraBon.catatan",
        label: "Catatan",
        type: "text-area",
      },
      {
        id: "kontraBon.id",
        type: "hidden",
      },
    ]),
  },
}

export const PenjualanPageKeys = Object.keys(PenjualanPages)
