import generateItemPenawaranFormItems from "common/form-item-generator/item-penawaran"
import generateItemServiceFormItems from "common/form-item-generator/item-service"
import generatePenawaranFormItems from "common/form-item-generator/penawaran"
import {
  calculatePenawaranMargin,
  generateKodeBarangKhusus,
} from "common/procedures/penawaran"
import { getSQM, getSQMForCounting } from "common/procedures/speisfikasi"
import { GeneralMap, PenawaranTypeEnum } from "common/type"
import {
  generateCrudUrl,
  genFieldQueryParamStr,
  strToSafeNum,
  genFormItems,
  genOptionFromArray,
  genFieldQueryParamObj,
  formatOnlyDate,
  formatNumber,
} from "common/util"
import { TableCell } from "components/data-table"
import BaseFilter from "components/filter/_base"
import { BasePageAdditionalFormType, BasePageProps } from "components/page/type"
import { barangResourceColumnKeys, satuanBarangMapper } from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"
import { getAPI, postAPI } from "hooks"
import { UseFormReturn, set } from "react-hook-form"
import { getHppBarang } from "services/barang"
import { StockAvailability } from "services/stock"

const DEFAULT_FILTER_NAME = "penawaran"
const PenawaranFilterMap: GeneralMap<string> = {
  [PenawaranTypeEnum.INDENT]: "penawaranIndent",
  [PenawaranTypeEnum.STOCK]: "penawaranStock",
  [PenawaranTypeEnum.SERVICE]: "penawaranService",
}

const PenawaranTitleMap: GeneralMap<string> = {
  [PenawaranTypeEnum.INDENT]: "Indent & Lokal",
  [PenawaranTypeEnum.TTLC]: "TT/LC",
  [PenawaranTypeEnum.SERVICE]: "Service",
  [PenawaranTypeEnum.TRIAL]: "Trial",
  [PenawaranTypeEnum.PINJAMAN]: "Pinjaman",
  [PenawaranTypeEnum.SAMPLE]: "/ Surat Jalan Sample",
  [PenawaranTypeEnum.KHUSUS]: "Khusus",
  [PenawaranTypeEnum.STOCK]: "Stock",
}

const PenawaranPermissionMapping = {
  [PenawaranTypeEnum.INDENT]:
    "SIT_sekawan::transaksi::franco_stock::marketing::penawaran_indent",
  [PenawaranTypeEnum.TTLC]:
    "SIT_sekawan::transaksi::TT/LC::marketing::penawaran_barang",
  [PenawaranTypeEnum.SERVICE]:
    "SIT_sekawan::transaksi::service::marketing::penawaran_service",
  [PenawaranTypeEnum.TRIAL]:
    "SIT_sekawan::transaksi::franco_stock::marketing::penawaran_stock",
  [PenawaranTypeEnum.PINJAMAN]:
    "SIT_sekawan::transaksi::franco_stock::marketing::penawaran_stock",
  [PenawaranTypeEnum.SAMPLE]:
    "SIT_sekawan::transaksi::franco_stock::marketing::penawaran_stock",
  [PenawaranTypeEnum.KHUSUS]:
    "SIT_sekawan::transaksi::khusus_wo_SPB::marketing::penawaran_barang_khusus",
  [PenawaranTypeEnum.JOS]:
    "SIT_sekawan::transaksi::franco_stock::marketing::penawaran_jos",
  [PenawaranTypeEnum.STOCK]:
    "SIT_sekawan::transaksi::franco_stock::marketing::penawaran_stock",
}

let hargaJualTimeout: NodeJS.Timeout

function generatePenawaranForms(tipe: PenawaranTypeEnum): BasePageProps<any> {
  let additionalForms: BasePageAdditionalFormType[] = []

  if (tipe === PenawaranTypeEnum.SERVICE) {
    additionalForms.push({
      id: "itemServices",
      title: "Daftar Barang",
      disableAdd: (data) => !data?.divisi?.id,
      requiredToAdd: ["divisi"],
      formItems: genFormItems(generateItemServiceFormItems()),
    })
  }
  additionalForms.push({
    id:
      tipe === PenawaranTypeEnum.KHUSUS ? "itemBarangJadis" : "itemPenawarans",
    title: tipe === PenawaranTypeEnum.SERVICE ? "Sparepart" : "Daftar Barang",
    disableAdd: (data) => !data?.divisi?.id,
    requiredToAdd: ["divisi"],
    recalculateCallback: (rhf: UseFormReturn, globRhf?: UseFormReturn) => {
      const data = rhf.watch()

      const luas = getSQMForCounting(
        data.spesifikasi?.panjang,
        data.spesifikasi?.lebar
      )
      rhf.setValue("spesifikasi.luas", luas.toFixed(2))

      rhf.setValue("hargaJualTotal", +data.qty * +data.hargaJual)

      const hargaDisarankan = +data.hargaDisarankanSatuan * luas * data.qty
      rhf.setValue(
        "hargaDisarankan",
        isNaN(hargaDisarankan) ? 0 : hargaDisarankan
      )

      if (tipe === PenawaranTypeEnum.KHUSUS) {
        const globData = globRhf?.getValues()
        const namaBarang: string =
          data.itemPenawarans
            ?.map((i: any) => i.barang?.nama?.trimEnd())
            .join(" + ") ?? ""
        const kodeBarang: string = !data.itemPenawarans?.length
          ? ""
          : generateKodeBarangKhusus(
              globData?.organisasi?.kode,
              data.itemPenawarans?.map((i: any) => i.barang?.kode)
            )

        rhf.setValue("namaBarang", namaBarang)
        rhf.setValue("kodeBarang", kodeBarang)
        rhf.setValue(
          "hargaDisarankan",
          data.itemPenawarans?.reduce(
            (total: number, i: any) => total + strToSafeNum(i.hargaDisarankan),
            0
          ) ?? 0
        )
        rhf.setValue(
          "hargaJual",
          data.itemPenawarans?.reduce(
            (total: number, i: any) => total + strToSafeNum(i.hargaJual),
            0
          ) ?? 0
        )
        rhf.setValue(
          "hargaBeli",
          data.itemPenawarans?.reduce(
            (total: number, i: any) => total + strToSafeNum(i.hargaBeli),
            0
          ) ?? 0
        )
        const firstItem = data.itemPenawarans?.[0]
        rhf.setValue("qty", firstItem?.qty ?? 0)
        rhf.setValue("spesifikasi", firstItem?.spesifikasi)
        if (!rhf.getValues("satuan")) {
          rhf.setValue("satuan", firstItem?.barang?.satuanJual)
        }
      }
    },
    formItems: genFormItems(
      tipe === PenawaranTypeEnum.KHUSUS
        ? [
            {
              id: "kodeBarang",
              label: "Barang",
              disabled: true,
            },
            {
              id: "namaBarang",
              isDataView: true,
              label: "Nama Barang",
            },
            {
              id: "qty",
              type: "number",
              label: "Qty",
              isDataView: true,
              disabled: true,
              isCallRecalculateCb: true,
              suffix: {
                id: "satuan.kode",
                type: "async-select",
                resourceUrl: "satuanBarang",
                resourceMapper: satuanBarangMapper,
                isHalfSize: true,
                label: "Satuan",
              },
            },
            {
              id: "isModular",
              type: "check",
              label: "Modular",
              value: false,
            },
            {
              id: "hargaBeli",
              type: "hidden",
            },
            {
              id: "spesifikasi.panjang",
              type: "number",
              label: "Panjang",
              isOptional: true,
              renderCondition(formData, formMode, filterData) {
                return !!formData.isModular
              },
              suffix: {
                id: "spesifikasi.lebar",
                type: "number",
                label: "Lebar",
                isOptional: true,
                isHalfSize: true,
              },
            },
            {
              id: "hargaJual",
              label: "Harga Jual",
              type: "number",
              disabled: true,
            },
            {
              id: "hargaJualTotal",
              label: "Harga Jual",
              type: "number",
              isIgnored: true,
              isDataView: true,
              columnRender(data, filterData, globVars) {
                return (
                  <TableCell>
                    {formatNumber(+data.hargaJual * +data.qty)}
                  </TableCell>
                )
              },
            },
            {
              id: "hargaDisarankan",
              type: "number",
              label: "Harga Disarankan",
              disabled: true,
              isDataView: true,
            },
            {
              id: "isJosAda",
              label: "JOS",
              type: "check",
              renderCondition(formData, formMode, filter) {
                return filter?.["divisi.id"] === "1"
              },
            },
            {
              id: "hargaJos",
              type: "number",
              label: "Harga JOS",
              value: 0,
              renderCondition: (data, formMode, filter) => !!data.isJosAda,
            },
            {
              id: "tipeHidden",
              label: "Tipe Hidden",
              type: "radio",
              renderCondition: (data, formMode, filter) => !!data.isJosAda,
              options: genOptionFromArray(["INCLUDE", "HIDDEN", "TERPISAH"]),
            },
            {
              id: "jenisSpesifikasi",
              label: "Spesifikasi",
              type: "radio",
              renderCondition(formData, formMode, filter) {
                return filter?.["divisi.id"] === "1"
              },
              options: genOptionFromArray([
                "OPEN",
                "OPEN_SKIVED",
                "OPEN_FINGER",
                "ENDLESS",
                "ENDLESS_CLIPPER",
              ]),
            },
          ]
        : generateItemPenawaranFormItems(tipe)
    ),
    validateDataCb: [PenawaranTypeEnum.JOS, PenawaranTypeEnum.SERVICE].includes(
      tipe
    )
      ? undefined
      : [PenawaranTypeEnum.SAMPLE, PenawaranTypeEnum.PINJAMAN].includes(tipe)
      ? async (formData, globData, formMode) => {
          if (
            tipe === PenawaranTypeEnum.PINJAMAN &&
            globData.asalBarang !== "SEKAWAN"
          ) {
            return undefined
          }

          const stock: StockAvailability = await getAPI(
            "/RiwayatStockBarang/latest-stock",
            {
              barangId: +formData?.barang?.id,
              organisasiId: +globData?.organisasi?.id,
            }
          )

          const stockToCheck =
            tipe === PenawaranTypeEnum.PINJAMAN ||
            (tipe === PenawaranTypeEnum.SAMPLE &&
              globData.jenisStokSample === "STOCK")
              ? stock.available
              : stock.sample

          if (+formData.qty > stockToCheck) {
            return `Stock tidak cukup, mohon cek kembali. Stock terakhir: ${formatNumber(
              stockToCheck
            )}`
          }
        }
      : async (formData, globData) => {
          const hargaBeli = strToSafeNum(formData.hargaBeli)
          if (
            tipe === PenawaranTypeEnum.INDENT &&
            (hargaBeli == null || typeof hargaBeli === "undefined")
          ) {
            return "Harga beli harus > 0"
          }
          const hargaJual = strToSafeNum(formData.hargaJual)
          if (!hargaJual) {
            return "Harga jual harus > 0"
          }

          if (!formData?.barang?.isDead && hargaJual < hargaBeli) {
            return "Harga Jual tidak boleh lebih kecil dari harga Beli"
          }
        },
    additionalForms:
      tipe === PenawaranTypeEnum.KHUSUS
        ? [
            {
              id: "itemPenawarans",
              title: "Barang",
              recalculateCallback: (rhf) => {
                const data = rhf.watch()
                const luas = getSQM(
                  data.spesifikasi?.panjang,
                  data.spesifikasi?.lebar
                )

                rhf.setValue("spesifikasi.luas", luas.toFixed(2))

                rhf.setValue(
                  "hargaBeli",
                  luas * strToSafeNum(data.hargaBeliSatuan, 1)
                )
                const hargaDisarankan = +data.hargaDisarankanSatuan * luas
                const hargaDisarankanFinal = isNaN(hargaDisarankan)
                  ? 0
                  : hargaDisarankan
                rhf.setValue("hargaDisarankan", hargaDisarankanFinal)
                rhf.setValue(
                  "hargaJual",
                  (hargaDisarankanFinal + 0.001).toFixed(4)
                )
              },
              formItems: genFormItems([
                {
                  id: "barang.id",
                  label: "Barang",
                  type: "async-select",
                  resourceColumnLabelKey: barangResourceColumnKeys,
                  resourceUrl: (data, filter) => {
                    const q: any = {}

                    if (!!filter["divisi.id"]) {
                      q["divisi.id_$In"] = `${filter["divisi.id"]};3`
                    }

                    return `barang?${genFieldQueryParamStr(
                      q
                    )}&${genBaseSortQueryParam()}`
                  },
                  customOnChange: (
                    data,
                    setValue,
                    getValues,
                    genCode,
                    globVars
                  ) => {
                    setValue("barang", data)
                    if (!data) return
                    setValue("satuan.kode", data.satuanJual.kode)

                    // TODO: set this as reusable function with item penawaran one
                    getHppBarang(data.id).then((hpp) => {
                      let hargaDisarankanAdditional =
                        data.subKelompok?.maxMargin
                      if (!hargaDisarankanAdditional) {
                        hargaDisarankanAdditional = globVars.get(
                          "DEFAULT_HARGA_DISARANKAN_PERCENTAGE"
                        )
                      }
                      let minHargaDisarankanAdditional =
                        data.subKelompok?.minMargin
                      if (!minHargaDisarankanAdditional) {
                        minHargaDisarankanAdditional = globVars.get(
                          "DEFAULT_MIN_HARGA_DISARANKAN_PERCENTAGE"
                        )
                      }
                      const hargaDisarankan = !getValues("hargaDisarankan")
                        ? hpp.rerataRupiah *
                          (1 + strToSafeNum(hargaDisarankanAdditional) / 100)
                        : +getValues("hargaDisarankan") /
                          strToSafeNum(getValues("spesifikasi.luas"), 1)

                      setValue(
                        "hargaBeli",
                        +hpp.rerataRupiah *
                          strToSafeNum(getValues("spesifikasi.luas"), 1)
                      )
                      setValue("hargaBeliSatuan", +hpp.rerataRupiah)
                      setValue(
                        "hargaDisarankan",
                        hargaDisarankan *
                          strToSafeNum(getValues("spesifikasi.luas"), 1)
                      )
                      setValue("hargaDisarankanSatuan", hargaDisarankan)
                      setValue(
                        "minMargin",
                        strToSafeNum(minHargaDisarankanAdditional)
                      )
                      setValue(
                        "minHargaJualMultiplier",
                        1 + strToSafeNum(minHargaDisarankanAdditional) / 100
                      )
                    })
                  },
                },
                {
                  id: "barang.nama",
                  isDataView: true,
                  type: "ignored",
                  label: "Nama Barang",
                },
                {
                  id: "spesifikasi.panjang",
                  type: "number",
                  label: "Panjang",
                  isCallRecalculateCb: true,
                  isOptional: true,
                  suffix: {
                    id: "spesifikasi.lebar",
                    type: "number",
                    label: "Lebar",
                    isOptional: true,
                    isCallRecalculateCb: true,
                    isHalfSize: true,
                  },
                },

                {
                  id: "qty",
                  type: "number",
                  isIgnored: true,
                  label: "Qty",
                  isDataView: true,
                },
                {
                  id: "spesifikasi.luas",
                  type: "number",
                  label: "Luas",
                  disabled: true,
                  suffix: {
                    id: "qty",
                    type: "number",
                    label: "Qty",
                    isHalfSize: true,
                  },
                },
                {
                  id: "hargaDisarankanSatuan",
                  type: "hidden",
                },
                {
                  id: "hargaJual",
                  label: "Harga Jual",
                  type: "number",
                  isDataView: true,
                  formRule(data, detail) {
                    const minHarga =
                      strToSafeNum(data.minHargaJualMultiplier) *
                        strToSafeNum(data.hargaBeli, 1) +
                      0.001
                    return {
                      min: {
                        value: minHarga,
                        message: `Harga jual tidak boleh kurang dari ${formatNumber(
                          minHarga
                        )}`,
                      },
                    }
                  },
                  customOnChange: (data, setValue, getValue) => {
                    if (+data === 1) {
                      hargaJualTimeout = setTimeout(() => {
                        setValue(
                          "hargaJual",
                          +(
                            strToSafeNum(getValue("minHargaJualMultiplier")) *
                              strToSafeNum(getValue("hargaBeli"), 1) +
                            0.001
                          ).toFixed(2)
                        )
                      }, 1200)
                    } else {
                      clearTimeout(hargaJualTimeout)
                    }
                  },
                  suffix: {
                    id: "hargaDisarankan",
                    type: "number",
                    label: "Harga Disarankan",
                    disabled: true,
                    isHalfSize: true,
                  },
                },
                {
                  id: "hargaDisarankan",
                  type: "number",
                  isIgnored: true,
                  label: "Harga Disarankan",
                  isDataView: true,
                },
                {
                  id: "jarak",
                  type: "number",
                  label: "Jarak",
                  isOptional: true,
                },
                {
                  id: "posisiVertical",
                  type: "radio",
                  label: "Posisi Vertikal",
                  options: genOptionFromArray(["ATAS", "BAWAH"]),
                  isOptional: true,
                },
                {
                  id: "posisiHorizontal",
                  type: "radio",
                  label: "Posisi Horizontal",
                  options: genOptionFromArray([
                    "TENGAH",
                    "KIRI",
                    "KANAN",
                    "BERJAJAR",
                  ]),
                  isOptional: true,
                },
              ]),
            },
          ]
        : undefined,
  })

  return {
    title: `Penawaran ${PenawaranTitleMap[tipe] ?? tipe}`,
    filter: (
      <BaseFilter keyName={PenawaranFilterMap[tipe] ?? DEFAULT_FILTER_NAME} />
    ),
    permissionRequired: PenawaranPermissionMapping[tipe],
    urls: {
      ...generateCrudUrl("penawaran"),
      read: {
        method: "get",
        endpoint: `/penawaran`,
        param: {
          ...genFieldQueryParamObj({
            [tipe === PenawaranTypeEnum.STOCK ? "tipe_$In" : "tipe"]:
              tipe === PenawaranTypeEnum.STOCK
                ? "STOCK;TRIAL;KONSINYASI"
                : tipe,
          }),
          sortBy: "id",
          sortType: "desc",
        },
      },
    },
    preCheckOrderCb:
      tipe === PenawaranTypeEnum.SAMPLE
        ? (data) => {
            return new Promise((resolve) => {
              postAPI("/penawaran/pre-check", data).then((res: any) => {
                if (!res?.valid) {
                  resolve(res.message)
                } else {
                  resolve(undefined)
                }
              })
            })
          }
        : undefined,
    requiredToAdd: ["divisi", "cabang"],
    disableAdd(data, filterData) {
      return !filterData?.["divisi.id"] || !filterData?.["organisasi.id"]
    },
    generateCodeParam: (data) => {
      if (!data.divisi?.kode || !data.organisasi?.kode) return undefined
      return {
        entity: {
          tipe: data.tipe,
          divisi: { kode: data.divisi?.kode },
          organisasi: { kode: data.organisasi?.kode },
        },
        entityName: "Penawaran",
      }
    },
    responseMapper(data) {
      const {
        margin,
        tanggalJatuhTempo,
        itemPenawarans,
        itemServices,
        ...rest
      } = data
      return {
        ...rest,
        margin,
        tanggalJatuhTempo: formatOnlyDate(new Date(tanggalJatuhTempo)),
        origMataUang: rest.mataUang?.mataUang,
        itemPenawarans:
          tipe === PenawaranTypeEnum.JOS
            ? itemPenawarans.map((itemPenawaran: any) => ({
                ...itemPenawaran,
                fixedBarangKode: "XXJOS",
                fixedBarangName: "Joining On Site",
              }))
            : itemPenawarans.map((itemPenawaran: any) => ({
                ...itemPenawaran,
                hargaJualRp: (
                  +itemPenawaran.hargaJual * (!!data.rate ? +data.rate : 1)
                ).toFixed(4),
                hargaJualTotal: (+itemPenawaran.hargaJual * +data.qty).toFixed(
                  4
                ),
                hargaDisarankanSatuan:
                  +itemPenawaran.hargaDisarankan /
                  +itemPenawaran.spesifikasi?.luas,
                hargaBeliSatuan:
                  +itemPenawaran.hargaBeli / +itemPenawaran.spesifikasi?.luas,
              })),
        itemServices: itemServices?.map((itemService: any) => ({
          ...itemService,
          ongkosRp: itemService.ongkos * (!rest.rate ? 1 : +rest.rate),
        })),
      }
    },
    customFormDataMapper(data, filterData) {
      const { itemPenawarans, ...rest } = data

      return {
        ...rest,
        itemPenawarans: itemPenawarans?.map((i: any) => ({
          ...i,
          barang: {
            id: i.barang?.id,
          },
        })),
      }
    },
    recalculateCallback: calculatePenawaranMargin,
    formGroupItems: genFormItems(generatePenawaranFormItems(tipe)),
    additionalForms,
  }
}

export default generatePenawaranForms
