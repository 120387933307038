import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Box,
  Input,
  InputGroup,
  InputRightAddon,
  Spinner,
} from "@chakra-ui/react"
import { OptionType } from "common/type"
import { getDataByKey } from "common/util"
import Paper from "components/container/paper"
import DataTable from "components/data-table"
import { DataTableColumn } from "components/data-table/type"
import { useBasePage, useBasePageDataTable } from "components/page/common"
import { DataList, LiteralListMapper, useGet } from "hooks"
import { useOutsideAlerter } from "hooks/outside-handler"
import {
  createRef,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useFormContext, UseFormReturn } from "react-hook-form"
import { CustomOnChangeFunction } from "./type"

interface AsyncTableSelectProps {
  id: string
  label: any
  onChange: (...event: any[]) => void
  columns: DataTableColumn<any>[]
  url:
    | string
    | ((data: any, filterData: any | undefined, globData: any) => string)
  resourceColumnLabelKey?: string
  value: any
  disabled?: boolean
  dataViewKey?: string
  formRhf: UseFormReturn
  customOnChange?: CustomOnChangeFunction
  generateCodeCb: () => void
  onKeyDown?: KeyboardEventHandler<any>
}

const AsyncTableSelect: React.FC<AsyncTableSelectProps> = ({
  label,
  onChange,
  columns,
  url: urlProps,
  resourceColumnLabelKey,
  value: valueProps,
  disabled,
  dataViewKey,
  id,
  formRhf,
  generateCodeCb,
  customOnChange,
  onKeyDown,
}) => {
  const { filterData, globVars, preCheckOrderCb, detail } = useBasePage()
  const { pageRhf: globRhf } = useBasePageDataTable()
  const [isShowOption, setIsShowOption] = useState<boolean>(false)
  const [pageSize, setPageSize] = useState<number>(10)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [query, setQuery] = useState<string>()
  const [value, setValue] = useState<OptionType>()
  const [sorter, setSorter] = useState<{
    sortBy: string
    sortType: "asc" | "desc"
  }>()

  const inputRef = createRef<any>()
  const optionRef = createRef<any>()

  const watchData = formRhf.watch()

  const url = useMemo(() => {
    const _url =
      typeof urlProps === "function"
        ? urlProps(watchData, filterData, globRhf?.watch())
        : urlProps
    if (!_url) return undefined

    return `/${_url}`
  }, [urlProps, globRhf, filterData, watchData])

  const { data: optionList, isLoading } = useGet<DataList<any>, any>(
    url ?? "",
    LiteralListMapper,
    useMemo(() => {
      const _col = columns
        .filter((i) => !i.ignoreToSearch && !i.dataKey.includes(".0."))
        // TODO: handle .0. -> should be itemPurchaseOrderStocks rather than itemPurchaseOrderStock
        .map((i) => i.dataKey)

      const q: any = {
        page: currentPage,
        pageSize: pageSize,
        ...(sorter ?? {}),
      }

      if (!!query && _col.length > 0) {
        q["searchFields"] = _col.join(",")
        q["searchValue"] = query
      }

      return q
    }, [currentPage, pageSize, query, columns, sorter]),
    !url
  )

  const onSelect = useCallback(
    (data: any) => {
      const valueLabel = resourceColumnLabelKey
        ? getDataByKey(data, resourceColumnLabelKey)
        : data.no

      const selectedVal = optionList?.data.find((i) => i.id === data?.id)

      if (!!dataViewKey) {
        const dataViewKeyComp = dataViewKey.split(".")
        dataViewKeyComp.splice(0, 1)
        const valKey = dataViewKeyComp.join(".")
        formRhf.setValue(dataViewKey, getDataByKey(selectedVal, valKey) ?? "")
      }
      onChange(data.id)
      setValue({
        label: valueLabel,
        value: data.id,
      })
      setIsShowOption(false)
    },
    [onChange, resourceColumnLabelKey, dataViewKey, optionList, formRhf]
  )

  useEffect(() => {
    let __val
    const val = optionList?.data.find((i) => i.id === valueProps)
    if (val) {
      setValue({
        label: resourceColumnLabelKey
          ? getDataByKey(val, resourceColumnLabelKey)
          : val.no,
        value: valueProps,
      })
      __val = val
    } else {
      const labelId = `${id.replace(".id", "")}.${
        resourceColumnLabelKey ?? "no"
      }`

      setValue({
        label: getDataByKey(detail, labelId),
        value: valueProps,
      })
      const idComp = id.split(".")
      const temp = [...idComp]

      __val = getDataByKey(
        detail,
        idComp.splice(0, idComp.length - 1).join(".")
      )
    }
    if (!!__val) {
      customOnChange?.(
        __val,
        formRhf.setValue,
        formRhf.getValues,
        generateCodeCb,
        globVars,
        preCheckOrderCb,
        {}
      )
    }
  }, [
    valueProps,
    detail,
    resourceColumnLabelKey,
    optionList,
    formRhf,
    customOnChange,
    generateCodeCb,
    label,
    id,
    globVars,
    preCheckOrderCb,
  ])

  useOutsideAlerter(optionRef, function () {
    setIsShowOption(false)
  })

  return (
    <Box position="relative" ref={optionRef}>
      {/* @ts-ignore */}
      <InputGroup>
        <Input
          borderRight="none"
          ref={inputRef}
          value={value?.label}
          placeholder={label}
          onChange={(e) => e.preventDefault()}
          onFocus={() => setIsShowOption(true)}
          isDisabled={disabled || isLoading}
          onKeyDown={onKeyDown}
        />
        <InputRightAddon
          bg="transparent"
          border="solid 1px #E7EEF7"
          borderRadius={22}
          h="32px"
          cursor="pointer"
          onClick={() => {
            if (!disabled) {
              inputRef.current?.focus()
            }
          }}
        >
          {isLoading ? <Spinner size="xs" /> : <ChevronDownIcon />}
        </InputRightAddon>
      </InputGroup>
      {isShowOption && (
        <Box
          position="absolute"
          w="100%"
          left="0"
          right="0"
          zIndex="99"
          boxShadow="0px 12px 30px rgba(94, 55, 255, 0.08)"
          top="44px"
          pb="8px"
        >
          <Paper>
            <DataTable
              dataSource={optionList?.data ?? []}
              columns={columns}
              onSort={(sortBy, sortTypeNum) => {
                if (sortTypeNum !== 0) {
                  setSorter({
                    sortBy,
                    sortType: sortTypeNum === 1 ? "asc" : "desc",
                  })
                } else {
                  setSorter(undefined)
                }
              }}
              onClickRow={onSelect}
              isLoading={isLoading}
              pageSize={pageSize}
              onPageChange={(currentPage) => {
                setCurrentPage(currentPage + 1)
              }}
              onPageSizeChange={(pageSize) => {
                setPageSize(pageSize)
              }}
              onSearch={(query) => {
                setQuery(query)
              }}
              totalData={optionList?.total ?? 0}
            />
          </Paper>
        </Box>
      )}
    </Box>
  )
}

export default AsyncTableSelect
