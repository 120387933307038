import { FormErrorMessage } from "@chakra-ui/react"
import { FieldError } from "react-hook-form"

interface FormErrorProps {
  error?: FieldError
  fieldName?: string
}

function getErrorMessageDict(key: string, fieldName?: string): string {
  switch (key) {
    case "required": {
      return `${fieldName || "Field ini"} tidak boleh kosong`
    }
    default: {
      return "Terjadi Kesalahan!"
    }
  }
}

const FormError: React.FC<FormErrorProps> = ({ error, fieldName }) => {
  return (
    <FormErrorMessage fontSize={"11px"}>
      {!!error?.message
        ? error.message
        : getErrorMessageDict(error?.type ?? "", fieldName)}
    </FormErrorMessage>
  )
}

export default FormError
