import {
  formatUnderScore,
  genFieldQueryParamObj,
  genFormItems,
  genOptionFromArray,
  generateCrudUrl,
} from "common/util"
import { TableCell } from "components/data-table"
import { BasePageProps } from "components/page/type"

function generateControlPanelForm(): BasePageProps<any> {
  return {
    title: "Control Panel",
    formGroupItems: genFormItems([
      {
        id: "lockType",
        type: "select",
        options: genOptionFromArray([
          "COMPLETE_BIAYA_IMPORT",
          "EDIT_TANGGAL_INVOICE_SURAT_JALAN_CUSTOMER",
          "EDIT_TANGGAL_FAKTUR_SURAT_JALAN_CUSTOMER",
          "TAMPIL_SURAT_JALAN_CUSTOMER_LAMA_DI_RETUR",
        ]),
        label: "Tipe Kunci",
        isDataView: true,
        columnRender(data, filterData, globVars) {
          return <TableCell>{formatUnderScore(data.lockType)}</TableCell>
        },
      },
      {
        id: "isFunctionSpecific",
        type: "hidden",
        value: true,
      },
      {
        id: "noRef",
        label: "No/ID Referensi",
        isDataView: true,
      },
      {
        id: "lockOpen",
        type: "check",
        value: true,
        label: "Dibuka",
        isDataView: true,
        columnRender(data, filterData, globVars) {
          return <TableCell>{!!data.lockOpen ? "Ya" : "Tidak"}</TableCell>
        },
      },
    ]),
    permissionRequired: "",
    urls: {
      ...generateCrudUrl("lock-transaksi"),
      read: {
        endpoint: "/lock-transaksi",
        param: genFieldQueryParamObj({
          isFunctionSpecific: true,
        }),
        method: "get",
      },
      create: {
        endpoint: "/lock-transaksi/single",
        method: "post",
      },
    },
  }
}

export default generateControlPanelForm
