import {
  generateCrudUrl,
  genFieldQueryParamStr,
  genFormItems,
  getToday,
} from "common/util"
import { TableCell } from "components/data-table"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import { genBaseSortQueryParam } from "constant/url"

// TODO: remove all is invoice
function generateInstruksiJosForm(): BasePageProps<any> {
  return {
    title: "Instruksi JOS",
    urls: generateCrudUrl("instruksi-jos"),
    permissionRequired: "InstruksiJos",
    filter: <BaseFilter keyName={"instruksiJos"} />,
    disableAdd: (data, filterData) =>
      !filterData?.["sfb.workshop.id"] ||
      !filterData?.["sfb.salesContract.penawaran.divisi.id"],
    generateCodeParam: {
      entityName: "InstruksiJos",
      entity: {},
    },
    requiredToAdd: ["Cabang", "Divisi"],
    formGroupItems: genFormItems([
      {
        id: "sfb.id",
        label: "Nomor SPB",
        column: 1,
        type: "async-table-select",
        resourceUrl: (data, filterData) => {
          const q: any = {
            "salesContract.penawaran.tipe": "JOS",
            "workshop.id": filterData?.["sfb.workshop.id"],
            "salesContract.penawaran.divisi.id":
              filterData?.["sfb.salesContract.penawaran.divisi.id"],
            "instruksiJos.id": "null",
          }
          return `surat-fabrikasi-barang?${genFieldQueryParamStr(q)}`
        },
        resourceColumnLabelKey: "noSpb",
        resourceColumn: [
          {
            dataKey: "noSpb",
            label: "No. SPB",
          },
          {
            dataKey: "tanggalSpb",
            label: "Tanggal",
          },
          {
            dataKey: "salesContract.penawaran.sales.nama",
            label: "Nama Sales",
          },
          {
            dataKey: "salesContract.penawaran.pelanggan.namaPanjang",
            label: "Customer",
          },
          {
            dataKey: "salesContract.noPoPelanggan",
            label: "PO. Cust",
          },
          {
            dataKey: "jumlahCetak",
            label: "Jumlah Cetak",
          },
        ],
        customOnChange: (data, setValue) => {
          setValue("sfb", data)
        },
        isDataView: true,
        dataViewKey: "sfb.noSpb",
      },
      {
        id: "no",
        label: "Nomor IS",
        isGeneratedCodeField: true,
        disabled: true,
        dataViewLabel: "No. Instruksi JOS",
      },
      {
        id: "tanggal",
        label: "Tanggal IS",
        type: "date",
        value: getToday(),
        dataViewLabel: "Tanggal",
      },
      {
        id: "sfb.tanggalSpb",
        label: "Tanggal SPB",
        type: "date",
        disabled: true,
      },
      {
        id: "sfb.salesContract.penawaran.sales.nama",
        label: "Sales",
        disabled: true,
        isDataView: true,
      },
      {
        id: "sfb.salesContract.penawaran.pelanggan.namaPanjang",
        label: "Customer",
        disabled: true,
        isDataView: true,
      },
      {
        id: "sfb.salesContract.penawaran.pelanggan.kelompokPelanggan.nama",
        label: "Kelompok Customer",
        disabled: true,
      },
      {
        id: "sfb.salesContract.noPoPelanggan",
        label: "PO Customer",
        disabled: true,
        isDataView: true,
      },
      {
        id: "invoicePelanggans.0.total",
        label: "Total Biaya",
        type: "number",
        isIgnored: true,
      },
      {
        id: "jarakTempuh",
        type: "number",
        label: "Jarak Tempuh (KM)",
        suffix: {
          id: "isLuarJawa",
          label: "Luar Jawa",
          type: "check",
        },
      },
      {
        id: "waktuPelaksanaanMulai",
        type: "datetime-local",
        label: "Waktu Mulai",
      },
      {
        id: "waktuPelaksanaanSelesai",
        type: "datetime-local",
        label: "Waktu Selesai",
      },
      {
        id: " ",
        type: "blank",
      },
      {
        id: "rincianKerja",
        type: "text-area",
        label: "Rincian Kerja",
      },
      {
        id: "rincianLainnya",
        type: "text-area",
        label: "Rincian Lainnya",
        isOptional: true,
      },
      {
        id: "catatan",
        type: "text-area",
        isOptional: true,
        label: "Catatan",
      },
      // {
      //   id: "jmlCetakSuratTugas",
      //   label: "Jumlah Cetak Srt Tugas",
      //   isIgnored: true,
      //   isDataView: true,
      // },
      {
        id: "jmlCetakBeritaAcara",
        label: "Jumlah Cetak Berita Acara",
        isIgnored: true,
        isDataView: true,
      },
    ]),
    additionalForms: [
      {
        id: "sfb.salesContract.penawaran.itemPenawarans",
        title: "Daftar Barang",
        hideAdd: true,
        formItems: [
          {
            id: "namaBarang",
            label: "Nama Barang",
            value: "Joining On Site",
            isIgnored: true,
            columnRender(data, filterData, globVars) {
              return <TableCell>Joining On Site</TableCell>
            },
            isDataView: true,
            disabled: true,
          },
          {
            id: "qty",
            label: "Jumlah",
            isDataView: true,
            type: "number",
            disabled: true,
          },
          {
            id: "hargaJos",
            label: "Ongkos",
            isDataView: true,
            type: "number",
            disabled: true,
          },
          {
            id: "keterangan",
            label: "Keterangan",
            isDataView: true,
            disabled: true,
          },
        ],
      },
      {
        id: "alatInstruksiJos",
        title: "Daftar Alat",
        async validateDataCb(formData, globData, formMode) {
          if (
            !formData.id &&
            globData.alatInstruksiJos?.find(
              (i: any) => i.jos?.id === formData.jos?.id
            )
          ) {
            return "Alat yang dipilih tidak boleh duplikat"
          }
        },
        formItems: genFormItems([
          {
            id: "jos.kode",
            type: "ignored",
            label: "Kode",
            isDataView: true,
          },
          {
            id: "jos.id",
            label: "Mesin",
            type: "async-select",
            isDataView: true,
            dataViewKey: "jos.nama",
            resourceUrl: `jos?${genBaseSortQueryParam("kode")}`,
            customOnChange: (data, setValue) => {
              setValue("jos", data)
            },
          },
        ]),
      },
      {
        id: "teknisiInstruksiJos",
        title: "Daftar Teknisi",
        async validateDataCb(formData, globData, formMode) {
          if (
            !formData.id &&
            globData.teknisiInstruksiJos?.find(
              (i: any) => i.teknisi?.id === formData.teknisi?.id
            )
          ) {
            return "Teknisi yang dipilih tidak boleh duplikat"
          }
        },
        formItems: genFormItems([
          {
            id: "teknisi.id",
            label: "Teknisi",
            type: "async-select",
            resourceUrl: (data, filterData) =>
              `karyawan?${genBaseSortQueryParam(
                "kode"
              )}&${genFieldQueryParamStr({
                jenisJabatan: "TEKNISI",
                "organisasi.id": filterData?.["sfb.workshop.id"],
                "divisi.id":
                  filterData?.["sfb.salesContract.penawaran.divisi.id"],
              })}`,
            dataViewLabel: "Kode",
            dataViewKey: "teknisi.kode",
            resourceColumnLabelKey: "kode",
            customOnChange: (data, setValue) => {
              setValue("teknisi", data)
            },
          },
          {
            id: "teknisi.nama",
            label: "Nama Teknisi",
            disabled: true,
            isDataView: true,
          },
        ]),
      },
    ],
    cetakActions: [
      // {
      //   label: "Cetak Surat Tugas",
      //   url: "/instruksi-jos/{id}/download/surat-tugas",
      //   isRefetch: true,
      // },
      {
        label: "Cetak",
        url: "/instruksi-jos/{id}/download/berita-acara",
        isRefetch: true,
      },
    ],
  }
}

export default generateInstruksiJosForm
