import {
  GLOB_VAR_KODE_SATUAN_METER,
  GLOB_VAR_KODE_SATUAN_MM,
  GLOB_VAR_KODE_SATUAN_PJ,
  GLOB_VAR_KODE_SATUAN_ROLL,
  GLOB_VAR_KODE_SATUAN_SLV,
  GLOB_VAR_KODE_SUB_OPTIBELT,
  GLOB_VAR_KODE_SUB_OTHER_BELT,
} from "constant/global-var-keys"

export function isDisabledForBelt(
  data: any,
  globVars: Map<string, string>
): boolean {
  const otherBeltCode = globVars.get(GLOB_VAR_KODE_SUB_OTHER_BELT)
  const optiBeltCode = globVars.get(GLOB_VAR_KODE_SUB_OPTIBELT)
  const slvCode = globVars.get(GLOB_VAR_KODE_SATUAN_SLV)
  const meterCode = globVars.get(GLOB_VAR_KODE_SATUAN_METER)
  const mmCode = globVars.get(GLOB_VAR_KODE_SATUAN_MM)
  const rolCode = globVars.get(GLOB_VAR_KODE_SATUAN_ROLL)
  const pjCode = globVars.get(GLOB_VAR_KODE_SATUAN_PJ)

  const { barang } = data

  if (
    barang?.subKelompok?.kode === otherBeltCode ||
    barang?.subKelompok?.kode === optiBeltCode
  ) {
    if (
      barang?.satuanBeli?.kode === slvCode ||
      barang?.satuanBeli?.kode === pjCode
    )
      return true

    if (barang?.satuanBeli?.kode === meterCode) return true

    if (
      barang?.satuanBeli?.kode === rolCode &&
      barang?.satuanJual?.kode === mmCode &&
      barang?.satuanStock?.kode === mmCode
    )
      return true
  }
  return false
}
