import { useToast } from "@chakra-ui/react"
import { AdditionFormType, GeneralMap } from "common/type"
import { getDataByKey, getDataFromDetail } from "common/util"
import { FormGroupItem } from "components/form/type"
import { FILTER_FROM_FIELD_MAPPER } from "constant/filter-mapper"
import { useAuth } from "context/auth.context"
import { DataList, mapDataList, useGet } from "hooks"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import {
  BasePageContextType,
  BasePageDataTableContextType,
  BasePageFormGroupItem,
  FormModeType,
} from "./type"
import { useStateLogger } from "hooks/logger"
import { DataTableColumn } from "components/data-table/type"

export const BasePageContext = createContext<BasePageContextType>({
  onChangeQuery: console.log,
  globVars: new Map(),
  detail: {},
  changeListener: 0,
  preCheckOrderCb: console.log,
})

export const useBasePage = () => useContext(BasePageContext)

export const BasePageDataTableContext =
  createContext<BasePageDataTableContextType>({ formMode: "create" })

export const useBasePageDataTable = () => useContext(BasePageDataTableContext)

export function useDataPage<T>(
  endpoint: string | undefined,
  searchFields: string[],
  param?: any,
  mapper?: (data: any) => any,
  disablePagination?: boolean,
  ignoreFilterParamOnFetch?: string[],
  disableAdd?: (data: any, filterData: any) => boolean
) {
  const toast = useToast()
  const [dataListPageSize, setDataListPageSize] = useState<number>(10)
  const [dataListCurrentPage, setDataListCurrentPage] = useState<number>(1)
  const [querySearch, setQuerySearch] = useState<string>()
  const [sorter, setSorter] = useState<{
    sortBy: string
    sortType: "asc" | "desc"
  }>()
  const [filterQuery, setFilterQuery] = useState<GeneralMap<string>>()
  const [etcParam, setEtcParam] = useState<GeneralMap<string>>()

  const queryParam = useMemo(() => {
    const filterParam: GeneralMap<string> = param ? { ...param } : {}
    const _etcParam: GeneralMap<string> = {}
    if (!!filterQuery && Object.keys(filterQuery).length > 0) {
      const filterFields = [],
        filterValues = []
      for (const filterIdx in filterQuery) {
        if (ignoreFilterParamOnFetch?.includes(filterIdx)) {
          _etcParam[filterIdx] = filterQuery[filterIdx]
          continue
        }
        if (filterQuery[filterIdx] === "<ignored>") continue
        if (filterIdx === "periode") continue
        if (typeof filterQuery[filterIdx] !== "undefined") {
          if (filterIdx === "tanggalRateValas") {
            filterFields.push("tanggal")
            filterParam.filterFields = ""
            filterParam.filterValues = ""
          } else {
            filterFields.push(filterIdx)
          }
          filterValues.push(filterQuery[filterIdx])
        }
      }

      if (filterFields.length > 0) {
        const filterFieldStr = filterFields.join(",")
        const filterValueStr = filterValues.join(",")
        if (!filterParam.filterFields) {
          filterParam.filterFields = filterFieldStr
          filterParam.filterValues = filterValueStr
        } else {
          filterParam.filterFields += `,${filterFieldStr}`
          filterParam.filterValues += `,${filterValueStr}`
        }
      }
    }

    const q: any = {
      ...filterParam,
      ...(sorter ?? {}),
    }

    if (!disablePagination) {
      q.page = dataListCurrentPage
      q.pageSize = dataListPageSize
    }

    if (!!querySearch && searchFields.length > 0) {
      q["searchFields"] = searchFields.join(",")
      q["searchValue"] = querySearch
    }

    if (Object.keys(_etcParam).length > 0) setEtcParam(_etcParam)

    return q
  }, [
    disablePagination,
    ignoreFilterParamOnFetch,
    dataListCurrentPage,
    dataListPageSize,
    querySearch,
    sorter,
    filterQuery,
    param,
    searchFields,
  ])

  const isDisableAdd = useMemo(
    () => disableAdd?.({}, filterQuery),
    [disableAdd, filterQuery]
  )

  const {
    data: masters,
    doGet: fetchMasterData,
    isLoading: isLoadingGet,
    error,
  } = useGet<DataList<T>, any>(
    endpoint ?? "",
    useMemo(() => {
      return (data: any) => {
        return mapDataList(data, mapper ?? ((i: any) => i))
      }
    }, [mapper]),
    queryParam,
    useMemo(
      () => !endpoint || !filterQuery || isDisableAdd,
      [endpoint, filterQuery, isDisableAdd]
    )
  )

  useEffect(() => {
    if (!!masters?.warning) {
      toast({
        title: "Peringatan!",
        description: masters.warning,
        status: "warning",
        duration: 8000,
        isClosable: true,
        position: "top",
      })
    }
  }, [masters])

  useEffect(() => {
    if (!!error) {
      toast({
        title: "Kesalahan Mengambil Data",
        description:
          error.message ??
          "Terjadi Kesalahan saat mengambil data, mohon coba beberapa saat lagi!",
        status: "error",
        duration: 8000,
        isClosable: true,
        position: "top",
      })
    }
  }, [error, toast])

  return {
    masters,
    fetchMasterData,
    isLoadingGet,

    filterQuery,
    setFilterQuery,

    queryParam,

    setDataListPageSize,
    setDataListCurrentPage,
    setQuerySearch,
    setSorter,

    dataListPageSize,
    dataListCurrentPage,
    etcParam,

    isDisableAdd,
  }
}

export function useGetFormItem(
  formGroupItemProps: BasePageFormGroupItem[],
  pageMode: string,
  formMode: FormModeType,
  additionalForms?: AdditionFormType,
  detail?: any,
  filterQuery?: GeneralMap<string>,
  ignoreFilterOnFetch?: string[]
) {
  const { profile } = useAuth()
  const formItems = useMemo<FormGroupItem[]>(() => {
    if (!detail) return formGroupItemProps ?? []

    const result: FormGroupItem[] = []
    const ids = []
    for (const i of formGroupItemProps ?? []) {
      if (
        i.type !== "ignored" ||
        (typeof i.isIgnored === "function"
          ? i.isIgnored(formMode)
          : i.isIgnored)
      ) {
        const valueFromFilter =
          filterQuery?.[FILTER_FROM_FIELD_MAPPER[i.id] ?? i.id]

        const isHasValue = typeof valueFromFilter !== "undefined"

        if (i.type === "async-table-select") {
          const labelId = `${i.id.replace(".id", "")}.${
            i.resourceColumnLabelKey ?? "no"
          }`

          result.push({
            ...i,
            type: "hidden",
            id: labelId,
            value: getDataFromDetail(detail, labelId),
          })
        }
        ids.push(i.id)
        result.push({
          ...i,
          value:
            getDataFromDetail(detail, i.id) ??
            valueFromFilter ??
            (typeof i.value === "function"
              ? i.value(detail, profile, filterQuery)
              : i.value),
          disabled: pageMode === "view" || isHasValue || i.disabled,
        })

        if (!!i.suffix) {
          const valueFromFilterSuffix =
            filterQuery?.[FILTER_FROM_FIELD_MAPPER[i.suffix.id] || i.suffix.id]
          const isHasValueSuffix = typeof valueFromFilterSuffix !== "undefined"
          i.suffix.value =
            getDataFromDetail(detail, i.suffix.id) ??
            valueFromFilterSuffix ??
            i.suffix.value
          ids.push(i.suffix.id)
          i.suffix.disabled = isHasValueSuffix || !!i.suffix.disabled
          const itemResult: FormGroupItem = {
            ...i.suffix,
            disabled: pageMode === "view" || i.suffix.disabled,
            type: "ignored",
          }
          result.push(itemResult)
        }
      }
    }

    for (const id of ignoreFilterOnFetch ?? []) {
      if (!ids.includes(id)) {
        const valueFromFilter =
          filterQuery?.[FILTER_FROM_FIELD_MAPPER[id] ?? id]
        result.push({
          id,
          type: "hidden",
          value: valueFromFilter,
        })
      }
    }

    const _additonalForms =
      typeof additionalForms === "function"
        ? additionalForms(formMode)
        : additionalForms
    return result.concat(
      _additonalForms?.map((i) => {
        return {
          id: i.id,
          title: i.title,
          type: "hidden",
          value:
            getDataByKey(detail, i.id)?.map((i: any) => ({
              ...i,
              xId: i.id,
            })) ?? [],
          disabled: false,
          disableAdd: true,
        }
      }) ?? []
    )
  }, [
    detail,
    formGroupItemProps,
    additionalForms,
    formMode,
    filterQuery,
    profile,
    pageMode,
    ignoreFilterOnFetch,
  ])

  return formItems
}
