import { BasePageProps } from "components/page/type"
import {
  formatNumber,
  generateCrudUrl,
  genFieldQueryParamStr,
  genFormItems,
  genOptionFromArray,
  getToday,
  strToSafeNum,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { Text } from "@chakra-ui/react"
import {
  mataUangOptionMapper,
  mataUangSearchField,
  perkiraanMapper,
  perkiraanMapperOnlyNo,
  perkiraanResourceColumnKeys,
  perkiraanSearchKey,
  supplierOptionMapper,
  supplierResourceColumnKey,
  supplierSearchKey,
  voucherMapper,
  voucherSearchKey,
} from "constant/mapper"
import { calculateInformasiPembayaran } from "common/form-value-function"
import { genBaseSortQueryParam } from "constant/url"
import { getKursByMataUang } from "hooks/http/custom"
import { TableCell } from "components/data-table"
import { ignoredSupplierFilter } from "constant/filters"

function getJumlahHutangFromDataPermohonan(data: any): number {
  return data.itemPermohonanPembayarans?.reduce(
    (total: number, i: any) => total + strToSafeNum(i.jumlah),
    0
  )
}

function generatePembayaranHutangForm(): BasePageProps<any> {
  return {
    permissionRequired: "GL_Sekawan::transaksi::pembayaran::pembayaran_hutang",
    title: "Pembayaran",
    requiredToAdd: ["supplier"],
    disableAdd(data, filterData) {
      return !filterData?.["supplier.id"]
    },
    urls: generateCrudUrl("pembayaran-hutang"),
    filter: <BaseFilter keyName="pembayaranHutang" />,
    recalculateCallback: calculateInformasiPembayaran,
    generateCodeParam: (data, filter) => ({
      entityName: "PembayaranHutang",
      entity: {
        tanggal: filter?.["tanggal_$btwStart"],
      },
    }),
    ignoreFilterOnFetch: ignoredSupplierFilter,
    formGroupItems: genFormItems([
      {
        id: "supplier.id",
        label: "Supplier",
        type: "async-select",
        resourceUrl: "supplier",
        resourceMapper: supplierOptionMapper,
        searchBy: supplierSearchKey,
        resourceColumnLabelKey: supplierResourceColumnKey,
      },
      {
        id: "nomorPembayaran",
        label: "Nomor Pembayaran",
        isDataView: true,
        disabled: true,
        isGeneratedCodeField: true,
        suffix: {
          id: "tanggal",
          label: "Tanggal",
          type: "date",
          isDataView: true,
          value: getToday(),
          isHalfSize: true,
        },
      },
      {
        id: "jumlahBayar",
        label: "Jumlah Bayar",
        type: "number",
        dataViewLabel: "Nilai Bayar",
        disabled: true,
        columnRender(data, filterData, globVars) {
          return (
            <TableCell>
              {formatNumber(+data.jumlahBayar * +data.rateValas)}
            </TableCell>
          )
        },
      },
      {
        id: "mataUang.mataUang",
        label: "Mata Uang",
        type: "async-select",
        resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
        resourceMapper: mataUangOptionMapper,
        searchBy: mataUangSearchField,
        disabled: true,
        suffix: {
          id: "rateValas",
          label: "Nilai Rate - Rp",
          type: "number",
          isHalfSize: true,
          disabled: true,
        },
      },
      {
        id: "biayaBank",
        label: "Biaya Bank",
        type: "number",
        isDataView: true,
        disabled: true,
        columnRender(data, filterData, globVars) {
          return (
            <TableCell>
              {formatNumber(+data.biayaBank * +data.rateValas)}
            </TableCell>
          )
        },
        suffix: {
          id: "biayaLain",
          label: "Biaya Lain - Lain",
          type: "number",
          disabled: true,
          isHalfSize: true,
        },
      },
      {
        id: "biayaLain",
        label: "Biaya Lain - Lain",
        type: "number",
        isIgnored: true,
        isDataView: true,
        disabled: true,
        columnRender(data, filterData, globVars) {
          return (
            <TableCell>
              {formatNumber(+data.biayaLain * +data.rateValas)}
            </TableCell>
          )
        },
      },
      {
        id: "keterangan",
        label: "Keterangan",
        type: "text-area",
        isOptional: true,
      },
    ]),
    responseMapper(data) {
      return {
        ...data,
        itemPembayaranHutangs: data.itemPembayaranHutangs?.map((i: any) => ({
          ...i,
          noInvoice:
            i.jenisPermohonan === "TUNAI"
              ? undefined
              : i.itemPermohonanPembayarans?.[0]?.bapb?.noInvoice,
          noBapb:
            i.jenisPermohonan === "TUNAI"
              ? i.itemPermohonanPembayarans?.[0]?.purchaseOrder?.noPo
              : i.itemPermohonanPembayarans?.[0]?.bapb?.no,
        })),
      }
    },
    additionalTitle: "Informasi",
    additionalForms: [
      {
        id: "itemPembayaranHutangs",
        title: "Daftar Permohonan Pembayaran",
        disableAdd: (data) => !data.supplier?.id,
        requiredToAdd: ["Supplier"],
        formItems: [
          {
            id: "permohonanPembayaran.id",
            label: "Nomor Permohonan Pembayaran",
            isDataView: true,
            column: 1,
            type: "async-table-select",
            resourceUrl: (_, filter, data) =>
              `permohonan-pembayaran?${genFieldQueryParamStr({
                "supplier.id": data.supplier?.id,
                sisaHutang_$gt: 0,
              })}`,
            dataViewKey: "permohonanPembayaran.no",
            dataViewLabel: "Nomor Mohon",
            customOnChange: (data, setValue) => {
              setValue("permohonanPembayaran", data)
              setValue(
                "mataUang",
                data.itemPermohonanPembayarans?.[0]?.mataUang
              )
              setValue(
                "isUangMuka",
                data.itemPermohonanPembayarans?.[0].isUangMuka
              )
              setValue(
                "isPenyelesaianUangMuka",
                data.itemPermohonanPembayarans?.[0].isPenyelesaianUangMuka
              )
              setValue("jumlah", data.sisaHutang)
              if (data.jenisPermohonan === "TUNAI") {
                setValue(
                  "noBapb",
                  data.itemPermohonanPembayarans?.[0]?.purchaseOrder?.noPo
                )
              } else {
                setValue(
                  "noBapb",
                  data.itemPermohonanPembayarans?.[0]?.bapb?.no
                )

                setValue(
                  "noInvoice",
                  data.itemPermohonanPembayarans?.[0]?.bapb?.noInvoice
                )
              }
            },
            resourceColumn: [
              {
                dataKey: "no",
                label: "Nomor Permohonan",
              },
              {
                dataKey: "sisaHutang",
                label: "Nilai Hutang",
                type: "number",
              },
              {
                dataKey: "jenisPermohonan",
                label: "Jenis",
              },
              {
                dataKey: "itemPermohonanPembayarans.0.mataUang.mataUang",
                label: "Mata Uang",
              },
              {
                dataKey: "itemPermohonanPembayarans.0.mataUang.simbol",
                label: "Simbol Mata Uang",
              },
              {
                dataKey: "isPpn",
                label: "P",
                render: (data) => (
                  // @ts-ignore
                  <Text fontSize="14px" fontWeight={600}>
                    {data.isPpn ? "Y" : "T"}
                  </Text>
                ),
              },
              {
                dataKey: "note",
                label: "Keterangan",
              },
              {
                dataKey: "komisi",
                label: "Nilai Komisi",
                render: (data) => (
                  <Text fontSize="14px" fontWeight={600}>
                    {formatNumber(
                      data.pembayaranKomisis.reduce(
                        (total: number, item: any) =>
                          +item.jumlahYangDibayar + total,
                        0
                      ) ?? 0
                    )}
                  </Text>
                ),
              },
            ],
          },
          {
            id: "permohonanPembayaran.tanggal",
            label: "Tanggal",
            type: "date",
            disabled: true,
            suffix: {
              id: "permohonanPembayaran.divisi.nama",
              label: "Divisi",
              disabled: true,
              isHalfSize: true,
            },
          },
          {
            id: "noInvoice",
            label: "Nomor Invoice",
            disabled: true,
            suffix: {
              id: "noBapb",
              label: "No. PO/BAPB",
              disabled: true,
              isHalfSize: true,
            },
          },
          {
            id: "isUangMuka",
            label: "Uang Muka",
            type: "check",
            disabled: true,
            suffix: {
              id: "isPenyelesaianUangMuka",
              label: "Penyelesaian Uang Muka",
              type: "check",
              disabled: true,
            },
          },
          {
            id: "permohonanPembayaran.isPpn",
            label: "PPN",
            type: "check",
            disabled: true,
            suffix: {
              id: "permohonanPembayaran.supplier.isPkp",
              disabled: true,
              isHalfSize: true,
              label: "PKP",
              type: "check",
            },
          },
          {
            id: "jumlah",
            label: "Jumlah Dibayar",
            type: "number",
            dataViewLabel: "Nilai Dibayar",
            disabled: true,
            suffix: {
              id: "mataUang.mataUang",
              label: "Mata Uang",
              type: "async-select",
              resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
              resourceMapper: mataUangOptionMapper,
              searchBy: mataUangSearchField,
              isDataView: true,
              disabled: true,
            },
          },
          {
            id: "mataUang.simbol",
            label: "Simbol",
            type: "hidden",
            isDataView: true,
            disabled: true,
          },
        ],
      },
      {
        id: "detailPembayaranHutangs",
        title: "Daftar Bayar",
        disableAdd(data, filterData) {
          return !data.mataUang?.mataUang
        },
        requiredToAdd: ["Item Permohonan Pembayaran"],
        formItems: [
          {
            id: "jenisBayar",
            type: "radio",
            options: [
              ...genOptionFromArray(["BANK", "KOMISI"]),
              { label: "Reimburse", value: "REIMBERS" },
            ],
            label: "Jenis Bayar",
          },
          {
            id: "voucher.id",
            label: "Kode Voucher",
            type: "async-select",
            resourceUrl: (data, filter, detail) =>
              `voucher?${genBaseSortQueryParam(
                "kodeVoucher"
              )}&${genFieldQueryParamStr({
                jenis: "KREDIT",
                "organisasi.kode": "B0",
              })}`,
            dataViewKey: "voucher.kodeVoucher",
            resourceMapper: voucherMapper,
            searchBy: voucherSearchKey,
            isDataView: true,
            customOnChange: (data, setValue, getValue) => {
              const oldData = getValue()
              setValue("voucher", data)
              if (!!data?.perkiraan?.nomorPerkiraan) {
                setValue("perkiraan.id", data?.perkiraan?.id)
                setValue(
                  "perkiraan.nomorPerkiraan",
                  data?.perkiraan?.nomorPerkiraan
                )
                setValue("perkiraan.keterangan", data?.perkiraan?.keterangan)
              } else {
                setValue("perkiraan.id", null)
                setValue("perkiraan.nomorPerkiraan", null)
                setValue("perkiraan.keterangan", null)
              }
              if (
                data?.mataUang?.mataUang !==
                oldData?.voucher?.mataUang?.mataUang
              ) {
                getKursByMataUang(data.mataUang.mataUang).then((res) =>
                  setValue("rateValas", res)
                )
              }
            },
            suffix: {
              id: "rateValas",
              label: "Rate Valas",
              disabled: true,
              type: "number",
            },
          },
          {
            id: "perkiraan.id",
            disabled: (data, detail) => {
              return !!data?.voucher?.perkiraan?.nomorPerkiraan
            },
            label: "Nomor Akun",
            type: "async-select",
            resourceUrl: "perkiraan",
            resourceMapper: perkiraanMapper,
            searchBy: perkiraanSearchKey,
            resourceColumnLabelKey: perkiraanResourceColumnKeys,
          },
          {
            id: "perkiraan.keterangan",
            label: "Keterangan",
            isDataView: true,
            disabled: true,
          },
          {
            id: "jumlahBayar",
            label: "Jumlah Bayar",
            type: "number",
            isDataView: true,
          },
          {
            id: "biayaBank",
            label: "Biaya Bank",
            type: "number",
            isDataView: true,
            value: 0,
            suffix: {
              id: "biayaLain",
              label: "Biaya Lain",
              type: "number",
              isHalfSize: true,
              value: 0,
            },
          },
        ],
      },
    ],
  }
}

export default generatePembayaranHutangForm
