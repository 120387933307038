import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  useToast,
} from "@chakra-ui/react"
import { AdditionFormType, CommonBasePageProps } from "common/type"
import { getDataByKey, getDataFromDetail } from "common/util"
import Paper from "components/container/paper"
import DataTable, { buildCrudDataTableColumn } from "components/data-table"
import ModalConfirm from "components/modal/modal-confirm"
import ModalUndoableForm from "components/modal/modal-undoable-form"
import SubTitle from "components/text/sub-title"
import { useStateLogger } from "hooks/logger"
import { useUndoable } from "hooks/undoable"
import { useCallback, useMemo, useState } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import {
  BasePageContext,
  BasePageDataTableContext,
  useBasePage,
  useBasePageDataTable,
} from "./common"
import { BasePageFormGroupItem } from "./type"
import { useAuth } from "context/auth.context"

interface PageAdditionalFormProps<T> extends CommonBasePageProps {
  id: string
  title: string
  viewFields: BasePageFormGroupItem[]
  viewData: T[]
  onAddData: (data: any) => void
  onUpdateData: (data: any) => void
  onRemoveData: (idx: number) => void
  hideAdd?: boolean
  enableEdit?: boolean
  isOnlyViewData?: boolean
}

const _PRIMARY_KEY = "xId"

const PageAdditionalForm: React.FC<PageAdditionalFormProps<any>> = <
  T extends any
>({
  title,
  viewFields: viewFieldsProps,
  viewData,
  onAddData,
  onRemoveData,
  onUpdateData,
  hideAdd,
  enableEdit,
  recalculateCallback,
  disableAdd,
  idGenerator,
  generateCodeParam,
  validateDataCb,
  additionalForms,
  isOnlyViewData,
  requiredToAdd,
  summaryCallback,
}: PageAdditionalFormProps<T>) => {
  const toast = useToast()
  const PRIMARY_KEY = _PRIMARY_KEY
  const [detail, setDetail] = useState<T>()
  const [deleteId, setDeleteId] = useState<number>()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)

  const { globVars, onChangeQuery, filterData, changeListener } = useBasePage()
  const { profile } = useAuth()

  const rhf = useForm({
    mode: "all",
  })
  const globRhf = useFormContext()
  const globData = useMemo(() => globRhf?.watch() ?? {}, [globRhf])

  const { isCanRedo, isCanUndo, doUndo, doRedo, resetHistory } = useUndoable()
  const { pageMode, formMode } = useBasePageDataTable()

  const onCloseDetail = useCallback(() => {
    setDetail(undefined)
    resetHistory()
  }, [resetHistory])

  const onCloseDelete = useCallback(() => {
    setDeleteId(undefined)
  }, [])

  const onSubmitNewData = useCallback(
    async (data) => {
      setIsLoadingSubmit(true)
      const errorValidation = await validateDataCb?.(
        data,
        globData,
        formMode,
        filterData
      )
      if (!!errorValidation) {
        toast({
          title: "Gagal",
          description: errorValidation,
          status: "error",
          duration: 8000,
          isClosable: true,
          position: "top",
        })

        setIsLoadingSubmit(false)
        return
      }
      if (!data[PRIMARY_KEY]) {
        onAddData({
          ...data,
          [PRIMARY_KEY]: idGenerator ? idGenerator(data) : new Date().getTime(),
        })
      } else {
        onUpdateData(data)
      }
      setIsLoadingSubmit(false)
      onCloseDetail()
    },
    [
      validateDataCb,
      globData,
      PRIMARY_KEY,
      onCloseDetail,
      toast,
      onAddData,
      idGenerator,
      onUpdateData,
      formMode,
      filterData,
    ]
  )

  const viewFields = useMemo(() => {
    const base: BasePageFormGroupItem[] = [
      {
        id: "xId",
        type: "hidden",
      },
    ]

    if (!detail) return base.concat(viewFieldsProps)

    base[0].value = (detail as any)?.[PRIMARY_KEY] ?? null

    const mappedViewFieldsProps = []
    for (const viewField of viewFieldsProps) {
      const data: BasePageFormGroupItem = {
        ...viewField,
        value: getDataFromDetail(detail, viewField.id) ?? viewField.value,
        columnRender: viewField.columnRender,
        disabled: pageMode === "view" || viewField.disabled,
      }

      if (viewField.suffix) {
        data.suffix = {
          ...viewField.suffix,
          value:
            getDataFromDetail(detail, viewField.suffix.id) ??
            viewField.suffix.value,
          disabled: pageMode === "view" || viewField.suffix.disabled,
        }
      }

      mappedViewFieldsProps.push(data)
    }
    const _additonalForms =
      typeof additionalForms === "function"
        ? additionalForms(formMode)
        : additionalForms
    return base.concat(mappedViewFieldsProps).concat(
      _additonalForms?.map((i) => {
        return {
          id: i.id,
          title: i.title,
          type: "hidden",
          value:
            getDataByKey(detail, i.id)?.map((i: any) => ({
              ...i,
              xId: i.id,
            })) ?? [],
          disabled: false,
          disableAdd: true,
        }
      }) ?? []
    )
  }, [detail, viewFieldsProps, pageMode, PRIMARY_KEY])

  const isDisabledAdd = useMemo(
    () => disableAdd?.(globData, filterData),
    [disableAdd, filterData, globData]
  )
  const showAdd = useMemo(
    () => !hideAdd && pageMode !== "view",
    [hideAdd, pageMode]
  )

  return (
    <BasePageContext.Provider
      value={{
        detail,
        globVars,
        onChangeQuery,
        filterData,
        changeListener,
        preCheckOrderCb: console.log,
      }}
    >
      <BasePageDataTableContext.Provider
        value={{
          pageRhf: globRhf,
          pageMode,
          formMode,
        }}
      >
        <FormProvider {...rhf}>
          <Box my="12px">
            <Paper isBorder>
              <SubTitle>{title}</SubTitle>
              {showAdd && isDisabledAdd && !!requiredToAdd?.length && (
                // @ts-ignore
                <Alert
                  status="warning"
                  mb="8px"
                  borderRadius={12}
                  fontSize="14px"
                >
                  <AlertIcon />
                  <AlertDescription>
                    Pilih {requiredToAdd.join(", ")} terlebih dahulu
                  </AlertDescription>
                </Alert>
              )}
              <DataTable
                summaryCallback={summaryCallback}
                columns={buildCrudDataTableColumn(
                  viewFields ?? [],
                  async (data, idx) => {
                    setDetail(data)
                  },
                  setDeleteId,
                  PRIMARY_KEY,
                  true,
                  (hideAdd && !enableEdit) || pageMode === "view",
                  undefined,
                  undefined,
                  undefined,
                  filterData,
                  profile
                )}
                dataSource={viewData ?? []}
                onClickAdd={showAdd ? () => setDetail({} as any) : undefined}
                onClickRow={
                  pageMode === "view" ? (data) => setDetail(data) : undefined
                }
                totalData={viewData?.length ?? 0}
                disableAdd={isDisabledAdd}
              />
              <ModalUndoableForm
                formGroupItems={viewFields}
                isCanRedo={isCanRedo}
                isCanUndo={isCanUndo}
                isOpen={!!detail}
                onClose={onCloseDetail}
                onUndo={doUndo}
                onRedo={doRedo}
                isLoading={isLoadingSubmit}
                rhf={rhf}
                isOnlyViewData={isOnlyViewData}
                title={title}
                onSubmit={onSubmitNewData}
                recalculateCallback={recalculateCallback}
                removeAction={pageMode === "view"}
                additionalForms={additionalForms}
                generateCodeParam={generateCodeParam}
              />
              <ModalConfirm
                isOpen={!!deleteId}
                onClose={onCloseDelete}
                onConfirm={() => {
                  onRemoveData(deleteId!!)
                  onCloseDelete()
                }}
                confirmAction={{
                  title: "Hapus Data",
                  label: "Hapus",
                  color: "red",
                  msg: "Apakah anda yakin untuk menghapus data ini?",
                }}
              />
            </Paper>
          </Box>
        </FormProvider>
      </BasePageDataTableContext.Provider>
    </BasePageContext.Provider>
  )
}

export default PageAdditionalForm
