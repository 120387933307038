import { OptionType } from "common/type"

export function pelangganOptionMapper(data: any): OptionType {
  return {
    label: `${data.kodePelanggan} - ${data.namaPanjang} (${data.namaPendek})`,
    value: data.id,
  }
}

export function karyawanOptionMapper(data: any): OptionType {
  return {
    label: `${data.kode} - ${data.nama}`,
    value: data.id,
  }
}

export const pelangganSearchKeys = "kodePelanggan,namaPendek,namaPanjang"

export function mataUangOptionMapper(data: any): OptionType {
  return {
    label: `${data.mataUang} - ${data.nama}`,
    value: data.mataUang,
  }
}

export const mataUangSearchField = "nama,mataUang"

export function supplierOptionMapper(data: any): OptionType {
  return {
    label: `${data.kodeSupplier} - ${data.namaPanjang}`,
    value: data.id,
  }
}

export const supplierSearchKey = "kodeSupplier,namaPanjang,namaPendek"
export const supplierResourceColumnKey =
  "supplier.kodeSupplier,supplier.namaPanjang"

export const karyawanResourceColumnKey = "karyawan.kode,karyawan.nama"
export const subDivisiKeuanganResourceColumnKey =
  "subDivisiKeuangan.kode,subDivisiKeuangan.nama"
export function satuanBarangMapper(data: any): OptionType {
  return {
    label: data.nama,
    value: data.kode,
  }
}

export function voucherMapper(data: any): OptionType {
  return {
    label: `${data.kodeVoucher} - ${data.keterangan}`,
    value: data.id,
  }
}

export const voucherSearchKey = "kodeVoucher,keterangan"

export function perkiraanMapper(data: any): OptionType {
  return {
    value: data.id,
    label: `${data.nomorPerkiraan} - ${data.keterangan}`,
  }
}

export const perkiraanResourceColumnKeys =
  "perkiraan.nomorPerkiraan,perkiraan.keterangan"

export const voucherResourceColumnKeys =
  "voucher.kodeVoucher,voucher.keterangan"

export const barangResourceColumnKeys = "barang.kode,barang.nama"
export const pelangganResourceColumnKeys =
  "pelanggan.kode,pelanggan.namaPanjang"
export const salesResourceColumnKeys = "sales.kode,sales.nama"

export function perkiraanMapperOnlyNo(data: any): OptionType {
  return {
    value: data.id,
    label: data.nomorPerkiraan,
  }
}

export const perkiraanSearchKey = `nomorPerkiraan,keterangan`

export const transferGlBodyRequestMapper =
  (tipe: string, isPosting: boolean) =>
  (data: any[], updateValue: any): any => {
    const bulkData = []
    for (const id in updateValue) {
      if (!!updateValue[id]) {
        bulkData.push({
          id,
          isTransferGL: isPosting,
        })
      }
    }

    return {
      data: bulkData,
      tipe,
    }
  }

export function postingBodyRequestMapper(data: any[], isPosting: boolean): any {
  return {
    ids: data.filter((i) => !!i.id).map((i) => i.id),
    isPosting,
  }
}
