import axios, { AxiosResponse } from "axios"
import { useCallback, useEffect, useState } from "react"
import { apiBaseUrl, getReqHeader, HttpApiError } from "./_common"
import { ACCESS_TOKEN_KEY } from "constant"
import { useNavigate } from "react-router-dom"
import { translateApiResponse } from "common/util"
import FileDownload from "js-file-download"

export async function handleDownload(
  endpoint: string,
  params?: any,
  isDownload?: boolean
): Promise<void> {
  try {
    const response = await axios.get(`${apiBaseUrl}${endpoint}`, {
      headers: getReqHeader(),
      responseType: "blob",
      params,
    })

    if (!isDownload) {
      const blobURL = URL.createObjectURL(response.data)
      window.open(blobURL, "_blank")

      // const iframe = document.createElement("iframe") //load content in an iframe to print later
      // document.body.appendChild(iframe)

      // iframe.style.display = "none"
      // iframe.src = blobURL
      // iframe.style.position = "fixed"
      // iframe.style.right = "0"
      // iframe.style.bottom = "0"
      // iframe.style.width = "0"
      // iframe.style.height = "0"
      // iframe.style.border = "0"
      // iframe.onload = function () {
      //   setTimeout(function () {
      //     iframe.focus()
      //     iframe.contentWindow?.print()
      //   }, 0.5)
      // }
    } else {
      const fileName =
        response.headers["content-disposition"]
          ?.split(";")?.[1]
          ?.split("=")?.[1]
          ?.trim() ?? `${new Date().getTime()}.pdf`
      FileDownload(response.data, fileName)
    }
  } catch (err: any) {
    if (err?.isAxiosError) {
      throw JSON.parse(await err.response.data.text())
    }
    throw err
  }
}

export function useCetak(
  url: string | undefined,
  params?: any,
  isDownload?: boolean
) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const doCetak = useCallback(async () => {
    if (!url) return
    setIsLoading(true)
    await handleDownload(url, params, isDownload).finally(() =>
      setIsLoading(false)
    )
  }, [url, params, isDownload])

  return { isLoading, doCetak }
}

export async function getAPI<T>(
  endpoint: string,
  params: any = {},
  baseUrl: string = apiBaseUrl
): Promise<T> {
  const data = await axios.get(`${baseUrl}${endpoint}`, {
    headers: getReqHeader(),
    params,
  })

  return data.data?.data
}

export function useGet<TResponse, TRequest>(
  endpoint: string,
  mapper: (dataResponse: any) => TResponse,
  params?: TRequest,
  disableAutoFetch?: boolean
) {
  const navigate = useNavigate()
  const [data, setData] = useState<TResponse>()
  const [error, setError] = useState<HttpApiError>()
  const [isLoading, setLoading] = useState<boolean>(false)

  const doGet = useCallback(async () => {
    setLoading(true)
    const headers: any = {}
    const token = localStorage.getItem(ACCESS_TOKEN_KEY)
    if (!!token) {
      headers["Authorization"] = `Bearer ${token}`
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiBaseUrl}${endpoint}`, {
          params,
          headers,
        })
        .then((res: AxiosResponse<any>) => {
          const result = mapper(res.data)
          setData(result)
          resolve(result)
        })
        .catch((err: any) => {
          // handle error 403
          if (err.response?.status === 401) {
            localStorage.clear()
            navigate("/login")
            resolve(undefined)
          }

          setError(translateApiResponse(err))
          reject(translateApiResponse(err))
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }, [endpoint, params, mapper, navigate])

  useEffect(() => {
    if (!disableAutoFetch) {
      doGet()
    }
  }, [doGet, disableAutoFetch])

  return {
    data,
    error,
    isLoading,
    doGet,
  }
}
