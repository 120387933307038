import { IResponse, usePost } from "hooks"

export interface GeneratedCode {
  code: string
}

export interface GeneratedCodeEntity {
  organisasiId?: number
  divisiId?: number
  poType?: string
  tanggal?: string
  asalId?: number
  tipe?: string
  kodeBarang?: string
  jenisJabatan?: string
  jenisPermohonan?: string
  organisasi?: any
  organisasiAsal?: any
  invoicePelanggan?: any
  instruksiJos?: any
  itemBapb?: any
  typeSuratJalan?: string
  salesContract?: any
  pengajuanPenambahanKasKecil?: any
}

export interface CreateGeneratedCodeRequest {
  entityName: string
  entity: GeneratedCodeEntity
}

export const useCreateGeneratedCode = () =>
  usePost<IResponse<GeneratedCode>, CreateGeneratedCodeRequest>(
    "/generator/code",
    "post"
  )
