import { extendTheme } from "@chakra-ui/react"

export const Colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
}

export const ColorDict = {
  primary: "#488AC7",
  text: "#232D42",
}

export const Theme = extendTheme({
  fonts: {
    body: "Manrope",
  },
  components: {
    Button: {
      variants: {
        primary: {
          color: "white",
          backgroundColor: ColorDict.primary,
          fontSize: "13px",
          borderRadius: "99px",
          px: "22px",
          h: "32px",
        },
        outline: {
          borderRadius: 99,
          fontSize: "13px",
          px: "22px",
          h: "32px",
        },
      },
      defaultProps: {
        variant: "primary",
      },
    },
    Input: {
      variants: {
        common: {
          field: {
            borderRadius: "99px",
            border: "1px solid #E7EEF7",
            fontSize: "13px",
            _focus: {
              boxShadow: "0 0 1px 1px #488AC7",
            },
            h: "32px",
          },
        },
      },
      defaultProps: {
        variant: "common",
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: "13px",
        fontWeight: "bold",
        mb: "2px",
        color: "#232D42",
      },
    },
    Text: {
      baseStyle: {
        color: ColorDict.text,
        fontSize: "13px",
      },
    },
    Select: {
      variants: {
        common: {
          field: {
            borderRadius: "99px",
            border: "1px solid #E7EEF7",
            fontSize: "13px",
            _focus: {
              boxShadow: "0 0 1px 1px #488AC7",
            },
            h: "32px",
          },
        },
      },
      defaultProps: {
        variant: "common",
      },
    },
    InputLeftAddon: {
      baseStyle: {
        h: "36px",
      },
    },
    ModalContent: {
      defaultProps: {
        borderRadius: "16px",
      },
    },
  },
  colors: Colors,
})
