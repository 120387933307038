import {
  InformasiRatePajakFormItem,
  InformasiRateValasFormItem,
} from "common/form-item-generator/informasi"
import { GeneralMap } from "common/type"
import {
  formatNumber,
  formatOnlyDate,
  genFieldQueryParamObj,
} from "common/util"
import { InformasiPageProps } from "components/page/type"
import { Text } from "@chakra-ui/react"
import { TableCell } from "components/data-table"

export const InformasiGLPages: GeneralMap<InformasiPageProps> = {
  "informasi-rate-valas-gl": InformasiRateValasFormItem,
  "rate-pajak-gl": InformasiRatePajakFormItem,
  "buku-bank": {
    title: "Buku Bank/Kas",
    permissionRequired: "BukuBank",
    sourceUrl: "jurnal/tipe/LAPORAN",
    sourceParam: genFieldQueryParamObj({
      tglTransferGL: "notNull",
    }),
    filterName: "infoBukuBank",
    pdfUrl: "/laporan/buku-bank/download",
    fields: [
      {
        dataKey: "perkiraan.nomorPerkiraan",
        label: "No. Perkiraan",
      },
      { dataKey: "keterangan", label: "Keterangan" },
      { dataKey: "createdAt", label: "Tanggal", type: "date" },
      { dataKey: "noVoucher", label: "No. Voucher" },
      { dataKey: "debit", label: "Debit", type: "number" },
      { dataKey: "kredit", label: "Kredit", type: "number" },
      { dataKey: "currSaldo", label: "Saldo", type: "number" },
    ],
  },
  jurnal: {
    title: "Jurnal",
    permissionRequired: "LaporanJurnal",
    // TODO: filter the one already posted
    sourceUrl: `jurnal/tipe/LAPORAN`,
    // sourceUrl: `posting-perkiraan`,
    filterName: "jurnalGl",
    fields: [
      {
        dataKey: "tanggal",
        label: "Tanggal",
        render: (data) => (
          // @ts-ignore
          <Text fontSize="14px" fontWeight={600}>
            {formatOnlyDate(new Date(data.createdAt))}
          </Text>
        ),
      },
      { dataKey: "perkiraan.nomorPerkiraan", label: "Noper" },
      {
        dataKey: "perkiraan.keterangan",
        label: "Deskripsi",
      },
      { dataKey: "debit", label: "Debet" },
      { dataKey: "kredit", label: "Kredit" },
      { dataKey: "noVoucher", label: "No. Voucher" },
    ],
  },
  "buku-besar": {
    permissionRequired: "BukuBesar",
    title: "Buku Besar",
    sourceUrl: "jurnal/buku-besar",
    filterName: "bukuBesar",
    requiredToAdd: ["Divisi", "PPN", "Range Nomor Perkiraan"],
    pdfUrl: "/jurnal/buku-besar/download",
    disablePagination: true,
    disableAdd(data, filterData) {
      return (
        (typeof filterData?.["perkiraan.divisi.id"] === "undefined" &&
          typeof filterData?.[
            "$or_perkiraan.divisi.id||perkiraan.divisi.id"
          ] === "undefined") ||
        typeof filterData?.["voucher.isUsingPpn"] === "undefined"
      )
    },
    detailInfo: {
      childTables: [
        {
          id: "jurnals",
          title: "Jurnal Dalam 1 Voucher",
          fields: [
            {
              dataKey: "noVoucher",
              label: "No. Voucher",
            },
            {
              dataKey: "tglTransaksi",
              type: "date",
              label: "Tanggal",
            },
            {
              dataKey: "perkiraan.nomorPerkiraan",
              label: "Nomor Perkiraan",
            },
            {
              dataKey: "perkiraan.keterangan",
              label: "Keterangan",
            },
            {
              dataKey: "debit",
              label: "Debet",
              type: "number",
            },
            {
              dataKey: "kredit",
              label: "Kredit",
              type: "number",
            },
            {
              dataKey: "namaAkun",
              label: "Dari/Ke",
            },
            {
              dataKey: "keterangan",
              label: "Catatan",
            },
          ],
        },
      ],
    },
    fields: [
      {
        dataKey: "perkiraan.nomorPerkiraan",
        label: "Nomor Perkiraan",
      },
      {
        dataKey: "namaAkun",
        label: "Akun",
      },
      {
        dataKey: "tglTransaksi",
        label: "Tanggal",
        type: "date",
      },
      {
        dataKey: "noVoucher",
        label: "No. Voucher",
      },
      {
        dataKey: "debit",
        label: "Debit",
        type: "number",
      },
      {
        dataKey: "kredit",
        label: "Kredit",
        type: "number",
      },
      {
        dataKey: "currSaldo",
        label: "Saldo",
        type: "number",
      },
      {
        dataKey: "keterangan",
        label: "Catatan",
      },
    ],
  },
  "transaksi-per-tanggal": {
    title: "Transaksi per Tanggal",
    permissionRequired: "TransaksiPerTanggal",
    sourceUrl: "",
    fields: [
      {
        dataKey: "nomorPerkiraan",
        label: "Nomor Perkiraan",
      },
      {
        dataKey: "keterangan",
        label: "Keterangan",
      },
      {
        dataKey: "tanggal",
        label: "Tanggal",
      },
      {
        dataKey: "kodeVoucher",
        label: "No. Voucher",
      },
    ],
  },
  mutasi: {
    title: "Mutasi & Saldo Perkiraan",
    permissionRequired: "Mutasi",
    sourceUrl: "",
    fields: [
      {
        dataKey: "nomorPerkiraan",
        label: "Nomor Perkiraan",
      },
      {
        dataKey: "keterangan",
        label: "Keterangan",
      },
      {
        dataKey: "tanggal",
        label: "Tanggal",
      },
      {
        dataKey: "kodeVoucher",
        label: "No. Voucher",
      },
    ],
  },
  "trial-balance": {
    title: "Trial Balance",
    permissionRequired: "TrialBalance",
    sourceUrl: "",
    fields: [
      {
        dataKey: "nomorPerkiraan",
        label: "Nomor Perkiraan",
      },
      {
        dataKey: "keterangan",
        label: "Keterangan",
      },
      { dataKey: "saldoAwal", label: "Saldo Awal" },
      {
        dataKey: "debit",
        label: "Debet",
      },
      {
        dataKey: "kredit",
        label: "Kredit",
      },
      { dataKey: "saldoAkhir", label: "Saldo Akhir" },
    ],
  },
  cashflow: {
    title: "Cashflow",
    permissionRequired: "Cashflow",
    sourceUrl: "laporan/cashflow",
    disablePagination: true,
    isHighlightRowCb: (data) => !!data.isTebal,
    filterName: "laporanLabaRugi",
    pdfUrl: "/laporan/cashflow/download",
    fields: [
      {
        dataKey: "keterangan",
        label: "Keterangan",
        type: "pre",
      },
      {
        dataKey: "nilaiRp",
        label: "Nilai Rp",
        render(data, idx, globVars) {
          return (
            <TableCell>
              {data.nilaiRp === null ? <>&nbsp;</> : formatNumber(data.nilaiRp)}
            </TableCell>
          )
        },
      },
    ],
  },
  "rugi-laba": {
    permissionRequired: "RugiLaba",
    title: "Rugi Laba",
    sourceUrl: "laporan/laba-rugi",
    disablePagination: true,
    isHighlightRowCb: (data) => !!data.isTebal,
    filterName: "laporanLabaRugi",
    pdfUrl: "/laporan/laba-rugi/download",
    fields: [
      {
        dataKey: "keterangan",
        label: "Keterangan",
        type: "pre",
      },
      {
        dataKey: "nilaiRp",
        label: "Nilai Rp",
        type: "number",
        render(data, idx, globVars) {
          return (
            <TableCell>
              {data.nilaiRp === null ? (
                <>&nbsp;</>
              ) : data.nilaiRp < 0 ? (
                `(${formatNumber(+data.nilaiRp * -1)})`
              ) : (
                formatNumber(data.nilaiRp)
              )}
            </TableCell>
          )
        },
      },
    ],
  },
  neraca: {
    permissionRequired: "Neraca",
    title: "Neraca",
    sourceUrl: "laporan/neraca",
    disablePagination: true,
    isHighlightRowCb: (data) => !!data.isTebal,
    filterName: "laporanLabaRugi",
    pdfUrl: "/laporan/neraca/download",
    fields: [
      {
        dataKey: "keterangan",
        label: "Keterangan",
        type: "pre",
      },
      {
        dataKey: "nilaiRp",
        label: "Nilai Rp",
        render(data, idx, globVars) {
          return (
            <TableCell>
              {data.nilaiRp === null ? <>&nbsp;</> : formatNumber(data.nilaiRp)}
            </TableCell>
          )
        },
      },
    ],
  },
  "penomoran-pajak": {
    permissionRequired: "PenomoranPajak",
    title: "Penomoran Pajak",
    filterName: "noBatasPajak",
    requiredToAdd: ["Tahun"],
    disableAdd(data, filterData) {
      return !filterData?.["year"]
    },
    sourceUrl: "nomor-pajak/laporan",
    fields: [
      {
        dataKey: "kodePajak",
        label: "Kode",
      },
      {
        dataKey: "namaOrganisasi",
        label: "Cabang",
      },
      {
        dataKey: "month",
        label: "Bulan",
      },
      {
        dataKey: "noAwal",
        label: "No. Awal",
      },
      {
        dataKey: "noAkhir",
        label: "No. Akhir",
      },
      {
        dataKey: "jumlahNo",
        label: "Jumlah No.",
        type: "number",
      },
      {
        dataKey: "terpakai",
        label: "Terpakai",
        type: "number",
      },
      { dataKey: "sisa", label: "Sisa", type: "number" },
      { dataKey: "noPajak", label: "No. Pajak" },
    ],
  },
  "summary-pajak": {
    permissionRequired: "SummaryPajak",
    title: "Summary Pajak",
    sourceUrl: `surat-jalan-customer`,
    sourceParam: genFieldQueryParamObj({
      noFakturPajak: "notNull",
    }),
    filterName: "laporanSummaryPajak",
    disableAdd(data, filterData) {
      return !filterData?.["asal.id"]
    },
    requiredToAdd: ["Cabang"],
    fields: [
      {
        dataKey: "salesContract.penawaran.pelanggan.namaPanjang",
        label: "Customers",
      },
      { dataKey: "salesContract.penawaran.pelanggan.npwp", label: "NPWP" },
      {
        dataKey: "noFakturPajak",
        label: "No. Faktur Pajak",
        render(data, idx) {
          return (
            <TableCell>{`${data.isA3 ? "070" : "010"}.${data.prefixNoPajak}.${
              data.noFakturPajak
            }`}</TableCell>
          )
        },
      },
      { dataKey: "tanggal", label: "Tanggal" },
      { dataKey: "noInvoice", label: "No. Invoice" },
      {
        dataKey: "jlh",
        label: "Jumlah",
        render(data) {
          return (
            <TableCell>
              {formatNumber(
                data.itemSuratJalanCustomers?.reduce(
                  (total: number, item: any) => {
                    return (
                      total +
                      +item.jumlahAvailable * +item?.itemPenawaran?.hargaJual
                    )
                  },
                  0
                )
              )}
            </TableCell>
          )
        },
      },
    ],
  },
  "import-pajak-harian": {
    permissionRequired: "ImportPajakHarian",
    title: "Import Pajak Harian",
    sourceUrl: "",
    fields: [
      {
        dataKey: "kode",
        label: "Kode",
      },
      { dataKey: "jenis", label: "Jenis" },
      { dataKey: "fg", label: "FG" },
      {
        dataKey: "noFaktur",
        label: "No. Faktur",
      },
      { dataKey: "masa", label: "Masa" },
      { dataKey: "tahun", label: "Tahun" },
      { dataKey: "tanggal", label: "Tanggal" },
      { dataKey: "npwp", label: "NPWP" },
      { dataKey: "nama", label: "Nama" },
      { dataKey: "jlh", label: "Jumlah" },
      { dataKey: "ppn", label: "PPN" },
    ],
  },
  rincian: {
    permissionRequired: "RincianBiaya",
    title: "Rincian Biaya",
    children: [
      "rincian-biaya-per-bulan",
      "rincian-biaya",
      "rincian-biaya-per-sales",
      "bonus",
      "pembebanan-biaya-pusat",
      "pembebanan-biaya-cikarang",
    ],
  },
  "rincian-biaya-per-bulan": {
    permissionRequired: "RincianBiayaPerBulan",
    title: "Rincian Biaya per Bulan",
    sourceUrl: "",
    fields: [
      { dataKey: "keterangan", label: "Keterangan" },
      { dataKey: "habasit", label: "Habasit" },
      { dataKey: "spinning", label: "Spinning" },
      { dataKey: "jlh", label: "Jumlah" },
    ],
  },
  "rincian-biaya": {
    permissionRequired: "RincianBiaya",
    title: "Rincian Biaya",
    sourceUrl: "",
    fields: [
      { dataKey: "keterangan", label: "Keterangan" },
      { dataKey: "total", label: "Total" },
      { dataKey: "pusat", label: "Pusat" },
      { dataKey: "jakarta", label: "Jakarta" },
    ],
  },
  "rincian-biaya-per-sales": {
    permissionRequired: "RincianBiayaPerSales",
    title: "Rincian Biaya per Sales",
    sourceUrl: "",
    fields: [
      { dataKey: "keterangan", label: "Keterangan" },
      { dataKey: "pengobatan", label: "B. Pengobatan" },
      { dataKey: "dinas", label: "B. Dinas" },
      { dataKey: "rpk", label: "B. RPK" },
      { dataKey: "bensin", label: "B. Bensin" },
      { dataKey: "tol", label: "B. Tol" },
      { dataKey: "sk", label: "B. SK" },
      { dataKey: "voucherHp", label: "B. Voucher HP" },
    ],
  },
  bonus: {
    title: "Bonus",
    permissionRequired: "Bonus",
    sourceUrl: "",
    fields: [
      {
        dataKey: "organisasi.nama",
        label: "Cabang",
      },
      { dataKey: "divisi.nama", label: "Divisi" },
      { dataKey: "sales.nama", label: "Nama Sales" },
      { dataKey: "keterangan", label: "Keterangan" },
      { dataKey: "jumlah", label: "Jumlah Adm", type: "number" },
    ],
  },
  "pembebanan-biaya-pusat": {
    permissionRequired: "PembebananBiayaPusat",
    title: "Pembebanan Biaya Pusat",
    sourceUrl: "",
    fields: [
      { dataKey: "keterangan", label: "Keterangan" },
      {
        dataKey: "total",
        label: "Total",
        type: "number",
      },
      { dataKey: "pusat", label: "Pusat" },
      { dataKey: "jakarta", label: "Jakarta" },
    ],
  },
  "pembebanan-biaya-cikarang": {
    title: "Pembebanan Biaya Cikarang",
    permissionRequired: "PembebananBiayaCikarang",
    sourceUrl: "",
    fields: [
      { dataKey: "keterangan", label: "Keterangan" },
      {
        dataKey: "total",
        label: "Total",
        type: "number",
      },
      { dataKey: "pusat", label: "Pusat" },
      { dataKey: "jakarta", label: "Jakarta" },
    ],
  },
  "tagihan-lunas": {
    permissionRequired: "TagihanLunas",
    title: "Bank Masuk - Pelunasan",
    sourceUrl: "",
    fields: [
      {
        dataKey: "no",
        label: "No. Pelunasan",
      },
      { dataKey: "tanggal", label: "Tanggal" },
      { dataKey: "kode", label: "Kode" },
      { dataKey: "pelanggan.namaPanjang", label: "Nama Customer" },
      {
        dataKey: "IDR",
        label: "IDR",
        type: "number",
      },
      { dataKey: "valas", label: "Valas" },
      { dataKey: "rate", label: "Rate", type: "number" },
      {
        dataKey: "negoRate",
        label: "Nego Rate",
        type: "number",
      },
      {
        dataKey: "jlhIDR",
        label: "Jumlah IDR",
        type: "number",
      },
    ],
  },
  "tagihan-finance": {
    permissionRequired: "TagihanFinance",
    title: "Tagihan Versi Finance",
  },
}

export const InformasiGLPageKeys = Object.keys(InformasiGLPages)
