import BasePage from "components/page/base-page"

import { TTLCPages } from "constant/form-pages/ttlc"
import { ServicePages } from "constant/form-pages/service"
import { JosPages } from "constant/form-pages/jos"
import { PinjamanPages } from "constant/form-pages/pinjaman"
import { SamplePages } from "constant/form-pages/sample"
import { GLPages } from "constant/form-pages/gl"

import { MasterPages } from "constant/form-pages/master"
import { PengadaanPages } from "constant/form-pages/pengadaan"
import { PenjualanPages } from "constant/form-pages/penjualan"
import { SettingPages } from "constant/form-pages/setting"

const AllBasePages = {
  ...MasterPages,
  ...PengadaanPages,
  ...PenjualanPages,
  ...TTLCPages,
  ...ServicePages,
  ...JosPages,
  ...PinjamanPages,
  ...SamplePages,
  ...GLPages,
  ...SettingPages,
}

const RenderMasterPage =
  (menuName: string): React.FC =>
  () => {
    return <BasePage {...AllBasePages[menuName]} id={menuName} />
  }

export default RenderMasterPage
