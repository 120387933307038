import { useCallback, useState } from "react"
import {
  apiBaseUrl,
  buildEndpointWithParam,
  getReqHeader,
  HttpApiError,
} from "./_common"
import { ACCESS_TOKEN_KEY } from "constant"
import axios, { AxiosResponse } from "axios"
import { GeneralMap } from "common/type"
import { translateApiResponse } from "common/util"

export async function postAPI<T>(
  endpoint: string,
  params: any = {},
  baseUrl: string = apiBaseUrl
): Promise<T> {
  const data = await axios.post(`${baseUrl}${endpoint}`, params, {
    headers: getReqHeader(),
  })

  return data.data?.data
}

export function usePost<TResponse, TRequest>(
  endpoint: string,
  method: "post" | "put" | "patch" | "delete" | "get",
  pathParam?: { [key: string]: any }
) {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<HttpApiError>()

  const doFetch = useCallback(
    (
      param: TRequest,
      onFlightPathParam?: GeneralMap<string>
    ): Promise<TResponse | undefined> => {
      setLoading(true)
      const headers: any = {
        "Content-Type": "application/json",
      }
      const token = localStorage.getItem(ACCESS_TOKEN_KEY)
      if (!!token) {
        headers["Authorization"] = `Bearer ${token}`
      }

      const _pathParam = onFlightPathParam ?? pathParam
      const apiEndpoint = !!_pathParam
        ? buildEndpointWithParam(endpoint, { ..._pathParam, ...param })
        : endpoint

      return new Promise((resolve, reject) => {
        axios(`${apiBaseUrl}${apiEndpoint}`, {
          method: method || "POST",
          headers,
          data: param,
        })
          .then((res: AxiosResponse<TResponse>) => {
            resolve(res.data)
          })
          .catch((err: any) => {
            setError(translateApiResponse(err))
            reject(translateApiResponse(err))
          })
          .finally(() => {
            setLoading(false)
          })
      })
    },
    [endpoint, method, pathParam]
  )

  return {
    isLoading: loading,
    doFetch,
    apiError: error,
  }
}
