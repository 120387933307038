import generatePenawaranForms from "common/form-generator/penawaran"
import { GeneralMap, PenawaranTypeEnum } from "common/type"
import {
  generateCrudUrl,
  genFieldQueryParamStr,
  genFormItems,
  genOptionFromArray,
  getToday,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"

export const PinjamanPages: GeneralMap<BasePageProps<any>> = {
  "penawaran-pinjaman": generatePenawaranForms(PenawaranTypeEnum.PINJAMAN),
  "pengembalian-barang": {
    title: "Pengembalian Barang Pinjaman",
    urls: generateCrudUrl("pengembalian-barang-pinjaman"),
    permissionRequired: "PengembalianBarangPinjaman",
    filter: <BaseFilter keyName="pengembalianPinjaman" />,
    requiredToAdd: ["Cabang", "Divisi"],
    disableAdd(data, filterData) {
      return (
        !filterData?.["penawaran.organisasi.id"] ||
        !filterData?.["penawaran.divisi.id"]
      )
    },
    generateCodeParam: {
      entityName: "PengembalianBarangPinjaman",
      entity: {},
    },
    formGroupItems: genFormItems([
      {
        id: "penawaran.id",
        label: "No. Pinjaman",
        type: "async-table-select",
        resourceUrl: (data, filterData) =>
          `penawaran?${genFieldQueryParamStr({
            tipe: PenawaranTypeEnum.PINJAMAN,
            "organisasi.id": filterData?.["penawaran.organisasi.id"],
            "divisi.id": filterData?.["penawaran.divisi.id"],
            "pengembalianBarangPinjamans.id": "null",
          })}`,
        resourceColumn: [
          {
            dataKey: "no",
            label: "No. Pinjaman",
          },
          {
            dataKey: "pelanggan.namaPanjang",
            label: "Customer",
          },
          {
            dataKey: "sales.nama",
            label: "Sales",
          },
        ],
        column: 1,
        customOnChange: (data, setValue) => {
          setValue("penawaran", data)
        },
      },
      {
        id: "no",
        label: "No. Pengembalian",
        isGeneratedCodeField: true,
        disabled: true,
        isDataView: true,
      },
      {
        id: "penawaran.no",
        label: "No. Pinjaman",
        type: "ignored",
        isDataView: true,
      },
      {
        id: "tanggalPengembalian",
        label: "Tanggal Pengembalian",
        type: "date",
        value: getToday(),
      },
      {
        id: "penawaran.pelanggan.namaPanjang",
        disabled: true,
        label: "Customer",
        isDataView: true,
      },
      {
        id: "penawaran.namaKontak",
        disabled: true,
        label: "Kontak / Attn",
      },
      {
        id: "penawaran.cc",
        label: "CC",
        disabled: true,
      },
      {
        id: "penawaran.sales.nama",
        label: "Sales",
        disabled: true,
        isDataView: true,
      },
      {
        id: "penawaran.oms",
        label: "OMS",
        disabled: true,
      },
      {
        id: "penawaran.referensi",
        label: "Referensi",
        disabled: true,
      },
      {
        id: "penawaran.asalBarang",
        disabled: true,
        label: "Asal Barang",
        options: genOptionFromArray(["SEKAWAN", "CUSTOMER"]),
        isDataView: true,
      },
      {
        id: "penawaran.pelangganAsalPinjam.namaPanjang",
        disabled: true,
        label: "Barang Dari Cust.",
      },
      {
        id: "keterangan",
        label: "Note",
        type: "text-area",
      },
    ]),
    additionalForms: [
      {
        id: "penawaran.itemPenawarans",
        title: "Daftar Barang",
        hideAdd: true,
        formItems: [
          {
            id: "barang.kode",
            isDataView: true,
            label: "Kode",
          },
          {
            id: "barang.nama",
            isDataView: true,
            label: "Nama Barang",
          },
          {
            id: "qty",
            isDataView: true,
            label: "Qty",
          },
        ],
      },
    ],
  },
}

export const PinjamanPageKeys = Object.keys(PinjamanPages)
