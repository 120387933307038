import { isDisabledForBelt } from "common/master-util"
import {
  generateCrudUrl,
  genFieldQueryParamStr,
  genFormItems,
  genOptionFromArray,
  getToday,
  strToSafeNum,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import {
  barangResourceColumnKeys,
  mataUangOptionMapper,
  mataUangSearchField,
  supplierOptionMapper,
  supplierResourceColumnKey,
  supplierSearchKey,
} from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"

function generatePermohonanPengadaanForm(): BasePageProps<any> {
  return {
    title: "Permohonan Pengadaan Barang",
    urls: {
      ...generateCrudUrl("permohonan-pengadaan-barang"),
      read: {
        endpoint: "/permohonan-pengadaan-barang",
        method: "get",
        param: {
          sortBy: "id",
          sortType: "asc",
        },
      },
    },
    filter: <BaseFilter keyName="permohonanPengadaanBarang" />,
    permissionRequired:
      "SIT_sekawan::transaksi::franco_stock::marketing::permohonan_pengadaan_barang",
    alertMessage:
      "Barang tidak laku dalam 3 bulan maka seluruh biaya akan dibebankan ke PEMOHON",
    generateCodeParam: {
      entityName: "PermohonanPengadaanBarang",
      entity: {},
    },
    requiredToAdd: ["cabang", "divisi", "jenis barang"],
    disableAdd(data, filterData) {
      return (
        !filterData?.["organisasi.id"] ||
        !filterData?.["divisi.id"] ||
        !filterData?.["tipePps"]
      )
    },
    formGroupItems: genFormItems([
      {
        id: "no",
        label: "Nomor Permohonan",
        isDataView: true,
        isGeneratedCodeField: true,
        disabled: true,
        suffix: {
          id: "tanggal",
          label: "Tanggal",
          type: "date",
          isDataView: true,
          value: getToday(),
        },
      },
      {
        id: "organisasiId",
        label: "Nama Cabang",
        sourceValueKey: "organisasi",
        type: "async-select",
        resourceUrl: `organisasi?${genBaseSortQueryParam()}`,
        suffix: {
          id: "divisi.id",
          isHalfSize: true,
          sourceValueKey: "divisi",
          label: "Nama Divisi",
          type: "async-select",
          resourceUrl: `divisi?${genBaseSortQueryParam()}`,
          customOnChange: (data, setValue) => {
            setValue("divisi", data)
          },
        },
      },
      {
        id: "divisi.id",
        type: "hidden",
      },
      {
        id: "supplier.id",
        label: "Supplier",
        sourceValueKey: "supplier",
        type: "async-select",
        dataViewKey: "supplier.namaPanjang",
        resourceColumnLabelKey: supplierResourceColumnKey,
        isDataView: true,
        resourceUrl: (data, filterData) => {
          const q: any = {}
          if (filterData["tipePps"] === "IMPORT") {
            q["isImportLuarNegeri"] = true
          } else if (filterData["tipePps"] === "LOKAL") {
            q["isImportLuarNegeri"] = false
          }

          return `supplier?${genBaseSortQueryParam("namaPanjang")}${
            Object.keys(q).length > 0 ? `&${genFieldQueryParamStr(q)}` : ""
          }`
        },
        resourceMapper: supplierOptionMapper,
        searchBy: supplierSearchKey,
        customOnChange: (data, setValue) => {
          setValue("supplier", data)
        },
        suffix: {
          id: "supplier.isPkp",
          type: "check",
          disabled: true,
          label: "PKP",
        },
      },
      {
        id: "tipePps",
        label: "Jenis Barang",
        type: "radio",
        options: genOptionFromArray(["IMPORT", "LOKAL", "SISA_PROJEK"]),
      },
      {
        id: "isPpn",
        label: "PPN",
        type: "hidden",
        value: true,
      },
      {
        id: "jenisPermintaan",
        label: "Jenis Permintaan",
        type: "radio",
        options: genOptionFromArray(["STOCK", "SAMPLE", "INVENTARIS"]),
        isDataView: true,
        value: "STOCK",
      },
      {
        id: "mataUang.mataUang",
        label: "Mata Uang",
        type: "async-select",
        resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
        resourceMapper: mataUangOptionMapper,
        searchBy: mataUangSearchField,
        value(data, userData, filterData) {
          return filterData?.["tipePps"] === "LOKAL" ? "RP" : undefined
        },
        suffix: {
          id: "namaPemohon",
          label: "Pemohon",
          isHalfSize: true,
        },
      },
      {
        id: "modeOfShipment",
        label: "Mode Of Shipment",
        suffix: { id: "packing", type: "number", label: "Packing", value: 0 },
      },
      {
        id: "freight",
        type: "number",
        label: "Freight",
        value: 0,
        suffix: {
          id: "insurance",
          type: "number",
          label: "Insurance",
          value: 0,
          isHalfSize: true,
        },
      },
    ]),
    additionalForms: [
      {
        id: "itemPengadaanStocks",
        title: "Barang",
        requiredToAdd: ["divisi"],
        idGenerator: (data) => {
          return `${data.barang?.id}-${data.spesifikasi?.panjang}-${data.spesifikasi?.lebar}`
        },
        disableAdd: (data) => {
          return !data.divisi.id
        },
        recalculateCallback: (rhf) => {
          const data = rhf.watch()

          rhf.setValue(
            "spesifikasi.luas",
            (strToSafeNum(data.spesifikasi?.panjang) *
              strToSafeNum(data.spesifikasi?.lebar)) /
              1000000
          )
        },
        formItems: genFormItems([
          {
            id: "barang.id",
            label: "Barang",
            dataViewLabel: "Kode Barang",
            dataViewKey: "barang.kode",
            type: "async-select",
            resourceColumnLabelKey: barangResourceColumnKeys,
            resourceUrl: (_, filter, data) => {
              const queryObj: any = {
                // TODO: get divisi umum id from globvars
                "subKelompok.kelompokUtama.divisi.id_$In": `${data.divisi?.id};3`,
              }

              return `barang?${genFieldQueryParamStr(queryObj)}`
            },
            customOnChange: (
              data,
              setValue,
              getValue,
              generateCb,
              globVars
            ) => {
              setValue("barang", data)
              if (isDisabledForBelt({ barang: data }, globVars)) {
                setValue("spesifikasi.panjang", null)
                setValue("spesifikasi.lebar", null)
                setValue("spesifikasi.luas", null)
              }
            },
          },
          {
            id: "judul",
            isOptional: true,
            label: "Judul Barang",
          },
          {
            id: "footer",
            isOptional: true,
            label: "Footer Barang",
          },
          {
            id: "barang.nama",
            label: "Nama",
            type: "ignored",
            isDataView: true,
          },
          ...[
            "barang.subKelompok.kode",
            "barang.satuanBeli.kode",
            "barang.satuanJual.kode",
            "barang.satuanStock.kode",
          ].map((i) => ({
            id: i,
            type: "hidden" as any,
          })),
          {
            id: "spesifikasi.panjang",
            label: "Panjang (MM)",
            type: "number",
            isOptional: true,
            isCallRecalculateCb: true,
            disabled(data, detailData, formMode, globVars) {
              return isDisabledForBelt(data, globVars)
            },
            suffix: {
              id: "spesifikasi.lebar",
              label: "Lebar (MM)",
              type: "number",
              isOptional: true,
              isCallRecalculateCb: true,
              disabled(data, detailData, formMode, globVars) {
                return isDisabledForBelt(data, globVars)
              },
            },
          },
          {
            id: "qty",
            label: "Quantity",
            type: "number",
            isDataView: true,
          },
          {
            id: "calonPembeli",
            label: "Calon Pembeli",
            isOptional: true,
            dataViewLabel: "Customer",
          },
          {
            id: "hargaUnit",
            label: "Unit Price",
            type: "number",
          },
          {
            id: "diskon",
            label: "Diskon (%)",
            type: "number",
            value: 0,
          },
        ]),
      },
    ],
  }
}

export default generatePermohonanPengadaanForm
