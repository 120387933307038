import generateBAPBFormItems from "common/form-item-generator/bapb"
import generateItemBAPBFormItems from "common/form-item-generator/item-bapb"
import { BAPBTypeEnum, GeneralMap } from "common/type"
import {
  generateCrudUrl,
  genFieldQueryParamObj,
  genFieldQueryParamStr,
  genFormItems,
  strToSafeNum,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import PendingPoView from "components/pending-po"
import { ignoredSupplierFilter } from "constant/filters"
import { getKursByMataUang } from "hooks/http/custom"

// TODO: refactor to better file separation
function generateItemPOUniqueKey(itemPO: any): string {
  const itemTrx = itemPO.itemPengadaanStock ?? itemPO.itemPenawaran
  return `${itemPO.id}-${itemTrx?.spesifikasi?.panjang}-${itemTrx?.spesifikasi?.lebar}`
}

function generateBapbForm(tipe: BAPBTypeEnum): BasePageProps<any> {
  return {
    requiredToAdd: ["cabang", "supplier", "divisi"],
    disableAdd(data, filterData) {
      return (
        !filterData?.["organisasi.id"] ||
        !filterData?.["supplier.id"] ||
        !filterData?.["divisi.id"]
      )
    },
    permissionRequired:
      "SIT_sekawan::transaksi::franco_stock::purchasing::BAPB",
    title: `Berita Acara Penerimaan Barang ${
      tipe === BAPBTypeEnum.SERVICE ? "Service" : ""
    } (BAPB)`,
    urls: {
      ...generateCrudUrl("bapb"),
      read: {
        endpoint: `/bapb`,
        param: genFieldQueryParamObj({
          tipe,
        }),
        method: "get",
      },
    },
    filter: <BaseFilter keyName="bapbFilter" />,
    generateCodeParam: {
      entityName: "Bapb",
      entity: {
        tipe,
      },
    },
    recalculateCallback: (rhf) => {
      const data = rhf.getValues()

      const itemBapbs = data.itemBapbs ?? []

      let totalPacking = 0,
        totalFreight = 0,
        totalInsurance = 0

      const usedPoId: GeneralMap<boolean> = {}

      let totalCost = 0
      for (const itemBapb of itemBapbs) {
        const diskSupplier =
          +itemBapb.itemPurchaseOrderStock?.purchaseOrderStock?.diskonSupplier /
          100
        totalCost +=
          (1 - diskSupplier) *
          +itemBapb.harga *
          +itemBapb.itemPurchaseOrderStock?.qty
      }

      for (const itemBapb of itemBapbs) {
        const po = itemBapb.itemPurchaseOrderStock?.purchaseOrderStock
        if (!usedPoId[po.id]) {
          totalPacking += strToSafeNum(po?.packing)
          totalFreight += strToSafeNum(po?.freight)
          totalInsurance += (strToSafeNum(po?.insurance) / 100) * totalCost
          usedPoId[po.id] = true
        }
      }

      // TODO: handle if value ever changed
      rhf.setValue("freight", totalFreight)
      rhf.setValue("packing", totalPacking)
      rhf.setValue("insurance", totalInsurance)

      const mataUang = itemBapbs[0]?.mataUang?.mataUang
      if (!!mataUang) {
        rhf.setValue("mataUang.mataUang", mataUang)
        getKursByMataUang(mataUang).then((res) => rhf.setValue("rate", res))
      }
    },
    ignoreFilterOnFetch: ignoredSupplierFilter,
    additionalView: PendingPoView,
    formGroupItems: genFormItems(generateBAPBFormItems(tipe)),
    additionalForms: [
      {
        id: "itemBapbs",
        title: "Daftar Barang Aktual",
        validateDataCb: async (data, globData) => {
          const itemBapbs = globData.itemBapbs ?? []
          const totalReal =
            +data.qtyAktual + +data.jumlahHilangRusak + +data.foc
          if (totalReal !== +data.qtyInvoice) {
            return "Qty Invoice tidak sama dengan total Qty"
          }

          if (!data.xId) {
            if (itemBapbs.length > 0) {
              const firstPpn =
                data.itemPurchaseOrderStock?.purchaseOrderStock?.isPpn
              for (const itemBapb of itemBapbs) {
                if (
                  firstPpn !==
                  itemBapb.itemPurchaseOrderStock?.purchaseOrderStock?.isPpn
                ) {
                  return "Item PO dengan status PPN berbeda tidak dapat disimpan"
                }
              }
            }
            const existItemPOID = itemBapbs.map((i: any) =>
              generateItemPOUniqueKey(i.itemPurchaseOrderStock)
            )
            if (
              existItemPOID.includes(
                generateItemPOUniqueKey(data.itemPurchaseOrderStock)
              )
            ) {
              return "Barang PO sudah ditambah, silahlkan edit data yang sudah ditambahkan"
            }
          }
          const mataUangBapb = globData.mataUang?.mataUang
          if (!!mataUangBapb && mataUangBapb !== data.mataUang?.mataUang) {
            return "Mata uang tidak boleh berbeda"
          }
        },

        // disableAdd: (data) => !data.purchaseOrder?.id,
        generateCodeParam: (data) => {
          if (!data.barang?.kode) return undefined
          return {
            entityName: "ItemBarangMasukTemp",
            entity: { itemBapb: { barang: { kode: data.barang.kode } } },
          }
        },
        disableAdd: (data) => !data.divisi?.id || !data.supplier?.id,
        requiredToAdd: ["Divisi", "Supplier"],
        formItems: genFormItems(generateItemBAPBFormItems(tipe)),
      },
    ],
  }
}

export default generateBapbForm
