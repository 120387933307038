import { getKursByMataUang } from "hooks/http/custom"
import { UseFormReturn } from "react-hook-form"
import { strToSafeNum } from "./util"

export function getTotalPembayaranFromBiayaImport(data: any): number {
  return (
    strToSafeNum(data.do) +
    strToSafeNum(data.beaMasuk) +
    strToSafeNum(data.ppn) +
    strToSafeNum(data.pph) +
    strToSafeNum(data.pphAngkutan) +
    strToSafeNum(data.biayaBank) +
    strToSafeNum(data.bungaBank) +
    strToSafeNum(data.ongkosAngkut) +
    strToSafeNum(data.lainLain) +
    strToSafeNum(data.sewaGudang) +
    strToSafeNum(data.handlingCost) +
    strToSafeNum(data.forwardingCost) +
    (!data.isFreightLangsung ? strToSafeNum(data.freight) : 0)
  )
}

export function calculateTotalPembayaran(rhf: UseFormReturn): void {
  const data = rhf.getValues()

  rhf.setValue("tanggalPpn", data.tanggalBeaMasuk)
  rhf.setValue("tanggalPph", data.tanggalBeaMasuk)
  rhf.setValue("jumlah", getTotalPembayaranFromBiayaImport(data))

  const totalBiFromBapb = data.itemBiayaImports?.reduce(
    (total: number, item: any) => {
      return (
        total +
        +item.bapb?.freight +
        +item.bapb?.fobCharge +
        +item.bapb?.insurance +
        +item.bapb?.packing
      )
    },
    0
  )
  rhf.setValue("isFreightLangsung", totalBiFromBapb > 0)
}

export function calculateInformasiPembayaran(rhf: UseFormReturn): void {
  const data = rhf.getValues()
  let jlhBiayaBank = 0,
    jlhBiayaLain = 0,
    jlhBayar = 0

  for (const itemPembayaran of data.detailPembayaranHutangs ?? []) {
    jlhBiayaBank += strToSafeNum(itemPembayaran.biayaBank)
    jlhBiayaLain += strToSafeNum(itemPembayaran.biayaLain)
    jlhBayar += strToSafeNum(itemPembayaran.jumlahBayar)
  }

  rhf.setValue("jumlahBayar", jlhBayar)
  rhf.setValue("biayaBank", jlhBiayaBank)
  rhf.setValue("biayaLain", jlhBiayaLain)

  const mataUang = data?.itemPembayaranHutangs?.[0]?.mataUang
  rhf.setValue("mataUang", mataUang)

  if (!!mataUang?.mataUang) {
    if (mataUang?.mataUang === "IDR") {
      rhf.setValue("rateValas", 1)
    } else {
      getKursByMataUang(mataUang?.mataUang).then((valas) =>
        rhf.setValue("rateValas", valas)
      )
    }
  }
}

export function sumNilaiPO(res: any): number {
  return (
    strToSafeNum(res?.fobCharge) +
      strToSafeNum(res?.bankCharge) +
      strToSafeNum(res?.komisi) +
      strToSafeNum(res?.packing) +
      strToSafeNum(res?.insurance) +
      strToSafeNum(res?.freight) -
      strToSafeNum(res?.diskon) +
      res?.itemPurchaseOrderStocks?.reduce((total: number, item: any) => {
        const hargaUnit =
          item.itemPengadaanStock?.hargaUnit ?? item.itemPenawaran?.hargaJual

        const qty = item.itemPengadaanStock?.qty ?? item.itemPenawaran?.qty
        return total + strToSafeNum(hargaUnit) * strToSafeNum(qty)
      }, 0) ?? 0
  )
}

export function calculateTotalHutang(data: any): number {
  return data?.itemPermohonanPembayarans?.reduce(
    (totalPembayaran: number, item: any) =>
      totalPembayaran +
        item.bapb?.itemBapbs?.reduce((total: number, item: any) => {
          let multiplier = 1
          if (item.spesifikasi?.panjang) {
            multiplier *= +item.spesifikasi?.panjang
            if (item.spesifikasi?.lebar) {
              multiplier *= +item.spesifikasi?.lebar / 1000000
            } else {
              multiplier /= 1000
            }
          } else {
            if (item.spesifikasi?.lebar) {
              multiplier *= +item.spesifikasi?.lebar
            }
          }
          return (
            total +
            strToSafeNum(item.harga) * strToSafeNum(item.qtyAktual) * multiplier
          )
        }, 0) ?? 0,
    0
  )
}

export function calculateJumlahHutangInPermohonan(rhf: UseFormReturn): void {
  const data = rhf.getValues()

  rhf.setValue("jumlahHutang", calculateTotalHutang(data))

  rhf.setValue(
    "isPpn",
    data?.itemPermohonanPembayarans?.[0]?.bapb?.itemBapbs?.[0]?.isPpn
  )
}
