import {
  Checkbox,
  Flex,
  Text,
  Radio,
  RadioGroup,
  Stack,
  Input,
  Textarea,
  InputGroup,
  InputLeftAddon,
  Button,
} from "@chakra-ui/react"
import { Select } from "chakra-react-select"
import { useBasePage, useBasePageDataTable } from "components/page/common"
import { SELECT_CHAKRA_STYLES } from "constant/style"
import { useCallback, useEffect, useMemo } from "react"
import CurrencyFormat from "react-currency-format"
import AreaEditor from "./item-work-order-editor"
import FormGroupAsyncSelect from "./async-select"
import AsyncTableSelect from "./async-table-select"
import { FormFieldProps } from "./type"
import { useFormContext } from "react-hook-form"
import { formatLocalDate, formatOnlyDate } from "common/util"
import SelectMultipleItemForm from "./select-multiple-item-form"
import { useStateLogger } from "hooks/logger"
import { useAuth } from "context/auth.context"

const FormField: React.FC<FormFieldProps> = (props) => {
  const {
    type: typeProps,
    id,
    value: valueProps,
    onChange,
    options: optionProps,
    label,
    resourceColumn,
    resourceUrl,
    resourceColumnLabelKey,
    strong,
    disabled: disabledProps,
    formRhf,
    dataViewKey,
    customOnChange,
    watchData,
    itemWorkOrderEditorProps,
    generateCodeCb,
    inputPrefix: inputPrefixProps,
    resourceMapper,
  } = props

  if (id == "lokasiGudang.id") {
    console.log({ id, ty: typeof disabledProps })
  }

  const { pageMode, formMode, pageRhf } = useBasePageDataTable()
  const { globVars, preCheckOrderCb, filterData } = useBasePage()
  const { profile } = useAuth()

  const type = useMemo(() => {
    if (typeof typeProps === "function") {
      return typeProps(watchData, profile)
    }

    return typeProps
  }, [typeProps, watchData])

  const value = valueProps as any
  const _itemWorkOrderEditorProps = itemWorkOrderEditorProps
    ? itemWorkOrderEditorProps(watchData)
    : undefined

  const inputPrefix = useMemo(() => {
    return typeof inputPrefixProps === "function"
      ? inputPrefixProps(watchData)
      : inputPrefixProps
  }, [watchData, inputPrefixProps])

  const rhf = useFormContext()

  const formRhfData = formRhf.watch()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const detail = pageRhf?.watch() ?? {}

  const disabled: boolean = useMemo(
    () =>
      pageMode === "view" ||
      !!(typeof disabledProps === "function"
        ? disabledProps(watchData, formRhfData, formMode, globVars)
        : disabledProps),
    [pageMode, disabledProps, watchData, formRhfData, formMode, globVars]
  )

  const handleEnterInField = useCallback((event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form
      if (!!form) {
        const index = [...form].indexOf(event.target)
        form.elements[index + 1].focus()
        event.preventDefault()
      }
    }
  }, [])

  const options = useMemo(() => {
    return typeof optionProps === "function"
      ? optionProps(watchData)
      : optionProps
  }, [optionProps, watchData])

  const onChangeMutipleSelector = useCallback(
    (selectedIds: string[]) => {
      customOnChange?.(
        selectedIds,
        formRhf.setValue,
        formRhf.getValues,
        generateCodeCb,
        globVars,
        preCheckOrderCb,
        detail
      )
    },
    [
      customOnChange,
      detail,
      formRhf.getValues,
      formRhf.setValue,
      generateCodeCb,
      globVars,
      preCheckOrderCb,
    ]
  )

  switch (type) {
    case "button": {
      // Use onChange as onClick handler of button
      return (
        // @ts-ignore
        <Button
          type="button"
          onClick={() => {
            customOnChange?.(
              null,
              formRhf.setValue,
              formRhf.getValues,
              generateCodeCb,
              globVars,
              preCheckOrderCb,
              detail
            )
          }}
        >
          {label}
        </Button>
      )
    }
    case "radio": {
      return (
        <RadioGroup
          onKeyDown={handleEnterInField}
          onChange={(val) => {
            if (typeof options?.[0]?.value === "boolean") {
              onChange(val === "1" ? true : false)
            } else {
              onChange(val)
            }
            customOnChange?.(
              val,
              formRhf.setValue,
              formRhf.getValues,
              generateCodeCb,
              globVars,
              preCheckOrderCb,
              detail
            )
          }}
          value={typeof value === "boolean" ? (!value ? "0" : "1") : value}
          isDisabled={disabled}
        >
          <Stack direction={"row"} h="32px">
            {options?.map((opt, key) => (
              <Radio
                key={key}
                checked={
                  (typeof value === "boolean"
                    ? !value
                      ? "0"
                      : "1"
                    : value) === opt.value
                }
                value={
                  typeof opt.value === "boolean"
                    ? !opt.value
                      ? "0"
                      : "1"
                    : opt.value
                }
              >
                <Text>{opt.label}</Text>
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      )
    }
    case "async-select": {
      return <FormGroupAsyncSelect {...props} onKeyDown={handleEnterInField} />
    }
    case "select": {
      return (
        <Select
          chakraStyles={SELECT_CHAKRA_STYLES}
          onKeyDown={handleEnterInField}
          placeholder={`Pilih ${label}`}
          value={options?.find((i) => i.value === value)}
          onChange={(val: any) => {
            onChange(val?.value)
            customOnChange?.(
              val,
              formRhf.setValue,
              formRhf.getValues,
              generateCodeCb,
              globVars,
              preCheckOrderCb,
              detail
            )
          }}
          isDisabled={disabled}
          options={options}
        />
      )
    }
    case "check": {
      return (
        <Flex align="center" h="32px">
          <Checkbox
            mr="8px"
            isChecked={value}
            onKeyDown={handleEnterInField}
            onChange={({ target: { checked } }) => {
              onChange(checked)
              customOnChange?.(
                checked,
                formRhf.setValue,
                formRhf.getValues,
                generateCodeCb,
                globVars,
                preCheckOrderCb,
                detail
              )
            }}
            _focus={{ outline: "none" }}
            _checked={{ outline: "none" }}
            isDisabled={disabled}
          />
          <Text whiteSpace={"nowrap"}>{label}</Text>
        </Flex>
      )
    }
    case "file": {
      return (
        <Input
          type={type}
          placeholder={label as string}
          isDisabled={disabled}
          onKeyDown={handleEnterInField}
          onChange={(e) => {
            onChange(e.target.files?.[0])
            customOnChange?.(
              e.target.files?.[0],
              formRhf.setValue,
              formRhf.getValues,
              generateCodeCb,
              globVars,
              preCheckOrderCb,
              detail
            )
          }}
        />
      )
    }
    case "blank": {
      return <>&nbsp;</>
    }
    case "async-table-select": {
      return (
        <AsyncTableSelect
          id={id}
          label={label}
          onChange={onChange}
          onKeyDown={handleEnterInField}
          columns={resourceColumn!!}
          resourceColumnLabelKey={resourceColumnLabelKey}
          url={resourceUrl!!}
          disabled={disabled}
          value={value}
          dataViewKey={dataViewKey}
          formRhf={formRhf}
          customOnChange={customOnChange}
          generateCodeCb={generateCodeCb}
        />
      )
    }
    case "number": {
      return (
        <CurrencyFormat
          thousandSeparator=","
          decimalSeparator="."
          decimalPrecision={3}
          onKeyDown={handleEnterInField}
          value={value}
          onValueChange={(val: any) => {
            onChange(val.value)
            customOnChange?.(
              val.value,
              formRhf.setValue,
              formRhf.getValues,
              generateCodeCb,
              globVars,
              preCheckOrderCb,
              detail
            )
          }}
          step="0.1"
          placeholder={label as string}
          style={{
            height: strong ? "40px" : "32px",
            border: "solid 1px #E7EEF7",
            width: "100%",
            borderRadius: "16px",
            fontSize: strong ? "16px" : "13px",
            padding: "0 16px",
            fontWeight: strong ? "bold" : "normal",
          }}
          disabled={disabled}
        />
      )
    }
    case "text-area":
      return (
        <Textarea
          placeholder={label as string}
          value={value}
          onChange={({ target: { value } }) => {
            onChange(value)
            customOnChange?.(
              value,
              formRhf.setValue,
              formRhf.getValues,
              generateCodeCb,
              globVars,
              preCheckOrderCb,
              detail
            )
          }}
          fontSize={"13px"}
          isDisabled={disabled}
          _disabled={{
            bg: "#efefef4d",
          }}
        />
      )
    case "area-editor":
      return (
        <AreaEditor
          spaceHeight={_itemWorkOrderEditorProps?.spaceHeight ?? 0}
          spaceWidth={_itemWorkOrderEditorProps?.spaceWidth ?? 0}
          value={[]}
          onSave={(points) => {
            onChange(points)
            customOnChange?.(
              points,
              formRhf.setValue,
              formRhf.getValues,
              generateCodeCb,
              globVars,
              preCheckOrderCb,
              detail
            )
          }}
          title={label as string}
          qtyFabrikasi={_itemWorkOrderEditorProps?.fabricatedQty ?? 0}
          disabled={disabled}
          itemBarangMasukId={_itemWorkOrderEditorProps?.itemBarangMasukId ?? 0}
        />
      )
    case "select-multiple-item":
      return (
        <SelectMultipleItemForm
          resourceUrl={`/${
            typeof resourceUrl === "function"
              ? resourceUrl(watchData, filterData, null)
              : resourceUrl
          }`}
          resourceMapper={resourceMapper}
          disabled={disabled}
          // TODO: more general for this
          baseData={watchData["itemBarangMasukSjis"]}
          onChange={onChangeMutipleSelector}
        />
      )
    default:
      return (
        <InputGroup>
          {inputPrefix && (
            <InputLeftAddon
              bg="transparent"
              border="solid 1px #E7EEF7"
              borderRadius={22}
              h="32px"
            >
              <Text fontSize="13px" fontWeight={600}>
                {inputPrefix}
              </Text>
            </InputLeftAddon>
          )}
          <Input
            type={type === "numeric" ? "number" : type}
            onKeyDown={handleEnterInField}
            placeholder={label as string}
            value={
              type === "datetime-local"
                ? formatLocalDate(new Date(value))
                : value
            }
            onChange={({ target: { value } }) => {
              onChange(value)
              customOnChange?.(
                value,
                formRhf.setValue,
                formRhf.getValues,
                generateCodeCb,
                globVars,
                preCheckOrderCb,
                detail
              )
            }}
            fontWeight={strong ? "bold" : "normal"}
            height={strong ? "40px" : "32px"}
            fontSize={strong ? "16px" : "13px"}
            isDisabled={disabled}
          />
        </InputGroup>
      )
  }
}

export default FormField
