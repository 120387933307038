import { GeneralMap } from "common/type"
import { InformasiPageProps } from "components/page/type"

export const TagihanLaporanPages = (
  idParent: string
): GeneralMap<InformasiPageProps> => ({
  [idParent]: {
    title: "Tagihan",
    permissionRequired: "Tagihan",
    children: [
      "laporan-tagihan",
      "laporan-posisi-tagihan",
      "laporan-tagihan-lunas",
    ],
  },
  "laporan-tagihan": {
    title: "Laporan Tagihan",
    permissionRequired: "LaporanTagihan",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "Nama Customers",
      },
      {
        dataKey: "",
        label: "No. Invoice",
      },
      {
        dataKey: "",
        label: "Tanggal",
      },
      {
        dataKey: "",
        label: "Tgl Krm Inv.",
      },
      {
        dataKey: "",
        label: "0 - >= 4mg",
      },
      {
        dataKey: "",
        label: ">4 - <= 10mg",
      },
      {
        dataKey: "",
        label: "10mg",
      },
    ],
  },
  "laporan-posisi-tagihan": {
    title: "Laporan Posisi Tagihan",
    permissionRequired: "LaporanPosisiTagihan",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "Nama Customers",
      },
      {
        dataKey: "",
        label: "No. Invoice",
      },
      {
        dataKey: "",
        label: "Tanggal",
      },
      {
        dataKey: "",
        label: "Tgl. Jth Tempo",
      },
      {
        dataKey: "",
        label: "Netto",
      },
      {
        dataKey: "",
        label: "Retur",
      },
      {
        dataKey: "",
        label: "Ppn",
      },
    ],
  },
  "laporan-tagihan-lunas": {
    title: "Laporan Tagihan Lunas",
    permissionRequired: "LaporanTagihanLunas",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "Nama Customers",
      },
      {
        dataKey: "",
        label: "No. Pelunasan",
      },
      {
        dataKey: "",
        label: "Tanggal",
      },
      {
        dataKey: "",
        label: "Netto",
      },
      {
        dataKey: "",
        label: "Retur",
      },
      {
        dataKey: "",
        label: "Ppn",
      },
      {
        dataKey: "",
        label: "Jumlah Bayar",
      },
      {
        dataKey: "",
        label: "No. Invoice",
      },
    ],
  },
})
