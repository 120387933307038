import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react"
import { getItemPoUnitPrice } from "common/procedures/purchase-order"
import {
  formatNumber,
  formatOnlyDate,
  genFieldQueryParamObj,
} from "common/util"
import Paper from "components/container/paper"
import DataTable, { TableCell } from "components/data-table"
import { DataTableColumn } from "components/data-table/type"
import FormError from "components/error/form-error"
import ModalInfo from "components/modal/modal-info"
import { useBasePage, useDataPage } from "components/page/common"
import Title from "components/text/title"
import { ignoredSupplierFilter } from "constant/filters"
import { useCetak } from "hooks"
import { useStateLogger } from "hooks/logger"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Controller, useForm } from "react-hook-form"

interface PendingPoViewProps {}

const PENDING_PO_COLUMNS: DataTableColumn<any>[] = [
  {
    dataKey: "purchaseOrderStock.noPo",
    label: "Nomor PO",
  },
  {
    dataKey: "itemPengadaanStock.barang.kode",
    label: "Kode Barang",
    render(data, idx, globVars) {
      return (
        <TableCell>
          {!!data.itemService
            ? "-"
            : (data.itemPengadaanStock ?? data.itemPenawaran).barang.kode}
        </TableCell>
      )
    },
  },
  {
    dataKey: "itemPengadaanStock.barang.nama",
    label: "Nama Barang",
    render(data, idx, globVars) {
      return (
        <TableCell>
          {!!data.itemService
            ? data.itemService.namaBarang
            : (data.itemPengadaanStock ?? data.itemPenawaran).barang.nama}
        </TableCell>
      )
    },
  },
  {
    dataKey: "qty",
    label: "Qty PO",
    type: "number",
  },
  {
    dataKey: "itemPengadaanStock.hargaUnit",
    label: "Harga",
    type: "number",
    render: (data) => {
      return <TableCell>{formatNumber(getItemPoUnitPrice(data))}</TableCell>
    },
  },
  {
    dataKey: "purchaseOrderStock.mataUang.mataUang",
    label: "Mata Uang",
  },
  {
    dataKey: "purchaseOrderStock.isPpn",
    label: "PPN",
    render: (data) => {
      return (
        <TableCell>{data?.purchaseOrderStock?.isPpn ? "1" : "0"}</TableCell>
      )
    },
  },
  {
    dataKey: "sisaBarang",
    label: "Sisa Barang",
    type: "number",
  },
]
const PendingPoView: React.FC<PendingPoViewProps> = ({}) => {
  const toast = useToast()
  const { changeListener, filterData } = useBasePage()

  const {
    masters,
    isLoadingGet,
    setFilterQuery,
    setDataListPageSize,
    setDataListCurrentPage,
    setQuerySearch,
    setSorter,
    dataListPageSize,
    queryParam,
    fetchMasterData,
    isDisableAdd,
  } = useDataPage(
    `/item-purchase-order-stock`,
    useMemo(() => PENDING_PO_COLUMNS.map((i) => i.dataKey), []),
    useMemo(
      () =>
        genFieldQueryParamObj({
          sisaBarang_$gt: "0",
        }),
      []
    ),
    undefined,
    undefined,
    undefined,
    useCallback((data: any, filterData: any) => {
      return (
        !filterData?.["purchaseOrderStock.divisi.id"] ||
        !filterData?.["purchaseOrderStock.supplier.id"]
      )
    }, [])
  )

  useEffect(() => {
    if (changeListener > 0) {
      fetchMasterData()
    }
  }, [changeListener, fetchMasterData])

  useEffect(() => {
    const filterUsed: any = {
      "purchaseOrderStock.status": "READY_FOR_BAPB",
    }
    for (const i in filterData) {
      if (["organisasi.id", ...ignoredSupplierFilter].includes(i)) continue
      if (i.includes("purchaseOrder.")) {
        filterUsed[i.replace("purchaseOrder.", "purchaseOrderStock.")] =
          filterData[i]
      } else if (i.includes("bapb.")) {
        filterUsed[i.replace("bapb.", "purchaseOrderStock.")] = filterData[i]
      } else {
        filterUsed[`purchaseOrderStock.${i}`] = filterData[i]
      }

      const periodStart = new Date()
      periodStart.setTime(periodStart.getTime() - 1080 * 24 * 60 * 60 * 1000)
      filterUsed["purchaseOrderStock.tanggal_$btwStart"] =
        formatOnlyDate(periodStart)
      filterUsed["purchaseOrderStock.tanggal_$btwEnd"] = formatOnlyDate(
        new Date()
      )
    }
    setFilterQuery(filterUsed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData])

  const excelForm = useForm()

  const [isDownloadExcel, setIsDownloadExcel] = useState<boolean>(false)
  const excelFileName = excelForm.watch("fileName")

  const cetakQueryParam = useMemo(() => {
    const { page, pageSize, ...restParam } = queryParam

    return restParam
  }, [queryParam])

  const { doCetak: downloadExcel, isLoading: isLoadingExcel } = useCetak(
    "/purchase-order/download/excel",
    useMemo(
      () => ({ ...cetakQueryParam, fileName: excelFileName }),
      [cetakQueryParam, excelFileName]
    ),
    true
  )

  const onDownloadExcel = useCallback(async () => {
    try {
      await downloadExcel()

      setIsDownloadExcel(false)
      excelForm.reset()
    } catch (err: any) {
      toast({
        title: "Gagal",
        description: err.message ?? "Terjadi Kesalahan",
        status: "error",
        duration: 8000,
        isClosable: true,
        position: "top",
      })
    }
  }, [downloadExcel, excelForm, toast])

  return (
    <Paper>
      <Title size="sm">Pending PO</Title>
      {isDisableAdd && (
        // @ts-ignore
        <Alert status="warning" mb="8px" borderRadius={12} fontSize="14px">
          <AlertIcon />
          <AlertDescription>
            Pilih cabang, divisi, supplier terlebih dahulu
          </AlertDescription>
        </Alert>
      )}
      <DataTable
        columns={PENDING_PO_COLUMNS}
        dataSource={masters?.data ?? []}
        isLoading={isLoadingGet}
        hideData={isDisableAdd || isLoadingGet}
        onPageSizeChange={(pageSize) => {
          setDataListPageSize(pageSize)
        }}
        onPageChange={(currentPage) => {
          setDataListCurrentPage(currentPage + 1)
        }}
        onSearch={(query) => {
          setDataListCurrentPage(1)
          setQuerySearch(query)
        }}
        onSort={(sortBy, sortTypeNum) => {
          if (sortTypeNum !== 0) {
            setSorter({
              sortBy,
              sortType: sortTypeNum === 1 ? "asc" : "desc",
            })
          } else {
            setSorter(undefined)
          }
        }}
        totalData={masters?.total ?? 0}
        pageSize={dataListPageSize}
      />
      <Flex justifyContent="flex-end" mt="12px">
        <Button onClick={() => setIsDownloadExcel(true)}>
          Export to Excel
        </Button>
      </Flex>
      <ModalInfo
        isOpen={isDownloadExcel}
        onClose={() => setIsDownloadExcel(false)}
        title="Download Laporan Excel"
      >
        <>
          <FormControl
            isRequired={true}
            isInvalid={!!excelForm.formState?.errors?.fileName}
          >
            <FormLabel>Nama File</FormLabel>
            <Controller
              control={excelForm.control}
              name={"fileName"}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Input
                  placeholder={"Nama File"}
                  value={value}
                  onChange={({ target: { value } }) => {
                    onChange(value)
                  }}
                  height={"32px"}
                  fontSize={"13px"}
                />
              )}
            />
            <FormError error={excelForm.formState?.errors?.fileName} />
          </FormControl>
          <Flex justify="flex-end">
            <Button
              colorScheme="blue"
              variant="outline"
              mt="8px"
              onClick={excelForm.handleSubmit(onDownloadExcel)}
              isLoading={isLoadingExcel}
            >
              Export Excel
            </Button>
          </Flex>
        </>
      </ModalInfo>
    </Paper>
  )
}

export default PendingPoView
