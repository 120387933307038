import { DataList, useGet } from "hooks"

export interface KordinatSpesifikasiBarang {
  x: number
  y: number
  qtyStockNum: number
  qtyFabrikasiNum: number
  multiplierWidth: number
  multiplierHeight: number
  width: number
  height: number
}

function kordinatSpesifikasiBarangMapper(data: any): KordinatSpesifikasiBarang {
  return {
    x: data.x ?? 0,
    y: data.y ?? 0,
    qtyStockNum: data.qtyStockNum ?? 1,
    qtyFabrikasiNum: data.qtyFabrikasiNum ?? 1,
    multiplierHeight: data.multiplierHeight ?? 1,
    multiplierWidth: data.multiplierWidth ?? 1,
    width: data.width ?? 0,
    height: data.height ?? 0,
  }
}

interface KordinatSpesifikasiType extends DataList<KordinatSpesifikasiBarang> {
  itemBarangMasuk: { qty: number; panjang: number; lebar: number }
}

function kordinatSpesifikasiBarangListMapper(
  list: any
): KordinatSpesifikasiType {
  const data = list.data?.items?.map(kordinatSpesifikasiBarangMapper)
  return {
    data,
    itemBarangMasuk: list.data?.itemBarangMasuk,
    total: data.length,
  }
}

export const useGetKordinatSpesifikasiBarangByItemBarangMasuk = (
  itemBarangMasukId: number | undefined
) =>
  useGet<KordinatSpesifikasiType, any>(
    `/kordinat-spesifikasi-barang/by-item-barang-masuk/${itemBarangMasukId}`,
    kordinatSpesifikasiBarangListMapper,
    null,
    !itemBarangMasukId
  )
