import { Box, Grid, GridItem, Text } from "@chakra-ui/react"
import Sidebar from "components/sidebar"
import TopBar from "./component/topbar"
import { useScreenDetector } from "hooks/device"

const AdminLayout: React.FC = ({ children }) => {
  const { isMobile } = useScreenDetector()
  return (
    // @ts-ignore
    <Grid gap={isMobile ? 0 : 4} templateColumns="repeat(18, 1fr)">
      <GridItem colSpan={isMobile ? 18 : 1}>
        <Sidebar />
      </GridItem>
      <GridItem colSpan={isMobile ? 18 : 17}>
        <Box
          pr={isMobile ? "0px" : "24px"}
          px={isMobile ? "12px" : "4px"}
          py={isMobile ? "8px" : "22px"}
          maxH="100vh"
          overflow="auto"
        >
          <TopBar />
          {children}
        </Box>
      </GridItem>
    </Grid>
  )
}

export default AdminLayout
