import { GeneralMap } from "common/type"
import { revertMap } from "common/util"

export const FILTER_FROM_FIELD_MAPPER: GeneralMap<string> = {
  kelompokUtamaId: "kelompokUtama.id",
  kelompokId: "kelompok.id",
  divisiId: "divisi.id",
  organisasiId: "organisasi.id",
  jenisStock: "jenis_stock",
  "subKelompok.kelompok.kelompokUtama.id": "kelompokUtama.id",
  "subKelompok.kelompok.id": "kelompok.id",
}

export const FIELD_FROM_FILTER_MAPPER: GeneralMap<string> = revertMap(
  FILTER_FROM_FIELD_MAPPER
)
