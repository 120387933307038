import {
  generateCrudUrl,
  genFieldQueryParamStr,
  genFormItems,
  getToday,
} from "common/util"
import { TableCell } from "components/data-table"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import { genBaseSortQueryParam } from "constant/url"

function generateInvoiceJosForm(): BasePageProps<any> {
  return {
    title: "Invoice JOS",
    urls: generateCrudUrl("invoice-pelanggan"),
    permissionRequired: "InstruksiJos",
    filter: <BaseFilter keyName="invoiceJos" />,
    disableAdd: (data, filterData) =>
      !filterData?.["instruksiJos.sfb.salesContract.penawaran.organisasi.id"] ||
      !filterData?.["instruksiJos.sfb.salesContract.penawaran.divisi.id"],
    generateCodeParam: (data, filterData) => {
      const kodeCabang =
        data.instruksiJos?.sfb?.salesContract?.penawaran?.organisasi?.kode
      if (!kodeCabang) return undefined
      return {
        entityName: "InvoiceJos",
        entity: {
          instruksiJos: {
            sfb: {
              salesContract: {
                penawaran: {
                  organisasi: {
                    kode: kodeCabang,
                  },
                },
              },
            },
          },
        },
      }
    },
    requiredToAdd: ["Cabang", "Divisi"],
    formGroupItems: genFormItems([
      {
        id: "instruksiJos.id",
        label: "Instruksi JOS",
        column: 1,
        disabled(data, detailData, formMode, globVars) {
          return formMode === "update"
        },
        type: "async-table-select",
        resourceUrl: (data, filterData) => {
          const q: any = {
            "sfb.salesContract.penawaran.tipe": "JOS",
            "sfb.salesContract.penawaran.organisasi.id":
              filterData?.[
                "instruksiJos.sfb.salesContract.penawaran.organisasi.id"
              ],
            "sfb.salesContract.penawaran.divisi.id":
              filterData?.[
                "instruksiJos.sfb.salesContract.penawaran.divisi.id"
              ],
            "invoicePelanggans.id": "null",
          }
          return `instruksi-jos?${genFieldQueryParamStr(q)}`
        },
        resourceColumnLabelKey: "no",
        resourceColumn: [
          {
            dataKey: "no",
            label: "No. Instruksi JOS",
          },
          {
            dataKey: "tanggal",
            label: "Tanggal",
          },
          {
            dataKey: "sfb.salesContract.penawaran.sales.nama",
            label: "Nama Sales",
          },
          {
            dataKey: "sfb.salesContract.penawaran.pelanggan.namaPanjang",
            label: "Customer",
          },
          {
            dataKey: "sfb.salesContract.noPoPelanggan",
            label: "PO. Cust",
          },
        ],
        customOnChange: (data, setValue, getValue, genCode) => {
          setValue("instruksiJos", data)
          if (!!data.sfb?.salesContract?.penawaran) {
            setValue("penawaran", data.sfb.salesContract.penawaran)
            setValue("penawaran.salesContracts", [data.sfb.salesContract])
          }
          genCode()
        },
        isDataView: true,
        dataViewKey: "instruksiJos.no",
      },
      {
        id: "no",
        label: "Nomor Invoice",
        isGeneratedCodeField: true,
        disabled: true,
        dataViewLabel: "No. Invoice JOS",
      },
      {
        id: "tanggalInvoice",
        label: "Tanggal",
        type: "date",
        value: getToday(),
        disabled(data, detailData, formMode, globVars) {
          return formMode === "update" && !!data.tanggalInvoice
        },
        dataViewLabel: "Tanggal",
      },
      {
        id: "instruksiJos.sfb.tanggalSpb",
        label: "Tanggal SPB",
        type: "date",
        disabled: true,
      },
      {
        id: "penawaran.sales.nama",
        label: "Sales",
        disabled: true,
        isDataView: true,
      },
      {
        id: "penawaran.pelanggan.namaPanjang",
        label: "Customer",
        disabled: true,
        isDataView: true,
      },
      {
        id: "penawaran.pelanggan.kelompokPelanggan.nama",
        label: "Kelompok Customer",
        disabled: true,
      },
      {
        id: "penawaran.salesContracts.0.noPoPelanggan",
        label: "PO Customer",
        disabled: true,
        isDataView: true,
      },
      {
        id: "totalTagihan",
        label: "Total Biaya",
        type: "number",
        isIgnored: true,
        isDataView: true,
      },
      {
        id: "instruksiJos.jarakTempuh",
        type: "number",
        label: "Jarak Tempuh (KM)",
        disabled: true,
        suffix: {
          id: "instruksiJos.isLuarJawa",
          label: "Luar Jawa",
          disabled: true,
          type: "check",
        },
      },
      {
        id: "instruksiJos.waktuPelaksanaanMulai",
        type: "datetime-local",
        label: "Waktu Mulai",
        disabled: true,
      },
      {
        id: "instruksiJos.waktuPelaksanaanSelesai",
        type: "datetime-local",
        label: "Waktu Selesai",
        disabled: true,
      },
      {
        id: " ",
        type: "blank",
      },
      {
        id: "instruksiJos.rincianKerja",
        type: "text-area",
        label: "Rincian Kerja",
        disabled: true,
      },
      {
        id: "instruksiJos.rincianLainnya",
        type: "text-area",
        label: "Rincian Lainnya",
        isOptional: true,
        disabled: true,
      },
      {
        id: "instruksiJos.catatan",
        type: "text-area",
        isOptional: true,
        label: "Catatan",
        disabled: true,
      },
      {
        id: "jumlahCetakInvoice",
        label: "Jumlah Cetak Invoice",
        isIgnored: true,
        isDataView: true,
        value: 0,
      },
      {
        id: "jumlahCetakFakturPajak",
        label: "Jumlah Cetak Faktur Pajak",
        isIgnored: true,
        isDataView: true,
        value: 0,
      },
    ]),
    additionalForms: [
      {
        id: "penawaran.itemPenawarans",
        title: "Daftar Barang",
        hideAdd: true,
        formItems: [
          {
            id: "namaBarang",
            label: "Nama Barang",
            value: "Joining On Site",
            isIgnored: true,
            columnRender(data, filterData, globVars) {
              return <TableCell>Joining On Site</TableCell>
            },
            isDataView: true,
            disabled: true,
          },
          {
            id: "qty",
            label: "Jumlah",
            isDataView: true,
            type: "number",
            disabled: true,
          },
          {
            id: "hargaJos",
            label: "Ongkos",
            isDataView: true,
            type: "number",
            disabled: true,
          },
          {
            id: "keterangan",
            label: "Keterangan",
            isDataView: true,
            disabled: true,
          },
        ],
      },
      {
        id: "instruksiJos.alatInstruksiJos",
        title: "Daftar Alat",
        hideAdd: true,
        formItems: genFormItems([
          {
            id: "jos.kode",
            label: "Kode",
            disabled: true,
            isDataView: true,
          },
          {
            id: "jos.nama",
            label: "Mesin",
            disabled: true,
            isDataView: true,
          },
        ]),
      },
      {
        id: "instruksiJos.teknisiInstruksiJos",
        title: "Daftar Teknisi",
        hideAdd: true,
        formItems: genFormItems([
          {
            id: "teknisi.kode",
            label: "Kode",
            isDataView: true,
            disabled: true,
          },
          {
            id: "teknisi.nama",
            label: "Nama Teknisi",
            disabled: true,
            isDataView: true,
          },
        ]),
      },
    ],
    cetakActions: [
      {
        label: "Cetak Invoice",
        url: "/invoice-pelanggan/{id}/download/invoice",
      },
      {
        label: "Cetak Faktur Pajak",
        url: "/invoice-pelanggan/{id}/download/faktur-pajak",
        params: [
          {
            id: "tanggalFaktur",
            type: "date",
            label: "Tanggal Faktur Pajak",
            value: getToday(),
            disabled(data) {
              return !!data.tanggalFaktur
            },
          },
        ],
      },
    ],
  }
}

export default generateInvoiceJosForm
