import { SpesificModuleFormItem, SuratJalanCustomerTypeEnum } from "common/type"
import { genFieldQueryParamStr, getToday } from "common/util"
import { BasePageFormGroupItem } from "components/page/type"
import { Text } from "@chakra-ui/react"
import {
  pelangganOptionMapper,
  pelangganResourceColumnKeys,
  pelangganSearchKeys,
} from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"

function generateSuratJalanCustomerFormItems(
  tipe: SuratJalanCustomerTypeEnum
): BasePageFormGroupItem[] {
  const items: SpesificModuleFormItem<SuratJalanCustomerTypeEnum>[] = [
    {
      id: "tujuan.id",
      column: 1,
      label: "Customer",
      type: "async-select",
      resourceUrl: (data, filterData) => {
        return `pelanggan?${genBaseSortQueryParam(
          "namaPanjang"
        )}&${genFieldQueryParamStr({
          "organisasi.id": filterData?.["asal.id"],
        })}`
      },
      dataViewKey: "tujuan.namaPanjang",
      searchBy: pelangganSearchKeys,
      resourceColumnLabelKey: pelangganResourceColumnKeys,
      isDataView: tipe === SuratJalanCustomerTypeEnum.SERVICE,
      resourceMapper: pelangganOptionMapper,
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      column: 1,
      id: "salesContract.id",
      label: "No. Sales Contract",
      type: "async-table-select",
      disabled: (data) => !data.tujuan?.id,
      resourceUrl: (_, filterData, data) => {
        const q: any = {
          "penawaran.tipe_$In":
            tipe === SuratJalanCustomerTypeEnum.STOCK
              ? "STOCK;INDENT;TRIAL;SERVICE;BARANG_KHUSUS;KONSINYASI"
              : tipe,
          "salesContract.sisaSuratJalan_$gt": 0,
          "salesContract.status_$neq": "NOT_READY",
        }
        const organisasiId = filterData["asal.id"]
        if (!!organisasiId) {
          q["penawaran.organisasi.id"] = organisasiId
        }
        const divisiId = filterData["salesContract.penawaran.divisi.id"]
        if (!!divisiId) {
          q["penawaran.divisi.id"] = divisiId
        }
        const pelangganId = data.tujuan?.id
        if (!!pelangganId) {
          q["penawaran.pelanggan.id"] = pelangganId
        }
        const isPpn = filterData["salesContract.penawaran.isPpn"]
        if (typeof isPpn !== "undefined") {
          q["penawaran.isPpn"] = isPpn
        }
        return `sales-contract?${genFieldQueryParamStr(q)}`
      },
      resourceColumnLabelKey: "noSalesContract",
      resourceColumn: [
        {
          dataKey: "noSalesContract",
          label: "Nomor Sales Contract",
        },
        {
          dataKey: "penawaran.no",
          label: "Nomor Penawaran",
        },
        {
          dataKey: "penawaran.sales.nama",
          label: "Sales",
        },
        {
          dataKey: "penawaran.pelanggan.namaPanjang",
          label: "Customer",
        },
        {
          dataKey: "penawaran.isAtSpb",
          label: "Melalui Pabrikasi",
          render: (data) => {
            return (
              // @ts-ignore
              <Text fontSize="13px" fontWeight="600">
                {data.penawaran?.isAtSpb ? "Ya" : "Tidak"}
              </Text>
            )
          },
        },
      ],
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
      customOnChange: (data, setValue) => {
        setValue("salesContract", data)
        setValue(
          "typeSuratJalan",
          data.penawaran.tipe === "SERVICE" ? "SERVICE" : "STOCK"
        )
      },
    },
    {
      id: "asal.id",
      label: "Cabang Asal",
      type: "async-select",
      customOnChange: (data, setValue, getValue, genCode) => {
        setValue("asal", data)
        genCode()
      },
      resourceUrl: `organisasi?${genBaseSortQueryParam()}`,
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "no",
      label: "Nomor Surat Jalan",
      disabled: true,
      isDataView: true,
      isGeneratedCodeField: true,
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
      suffix: {
        id: "tanggal",
        type: "date",
        label: "Tanggal",
        value: getToday(),
        isDataView: true,
        isHalfSize: true,
      },
    },

    {
      id: "typeSuratJalan",
      type: tipe !== SuratJalanCustomerTypeEnum.STOCK ? "hidden" : "text",
      label: "Jenis SJ",
      disabled: true,
      enabledAt: [
        SuratJalanCustomerTypeEnum.SERVICE,
        SuratJalanCustomerTypeEnum.STOCK,
      ],
      suffix:
        tipe === SuratJalanCustomerTypeEnum.STOCK
          ? {
              id: "salesContract.penawaran.isAtSpb",
              disabled: true,
              type: "check",
              label: "Penawaran Melalui SPB WO",
              isHalfSize: true,
            }
          : undefined,
    },
    {
      id: "ratePajak",
      label: "Rate Pajak",
      type: "number",
      value: 1,
      enabledAt: [SuratJalanCustomerTypeEnum.SERVICE],
    },
    {
      id: "salesContract.penawaran.pelanggan.kodePelanggan",
      type: "ignored",
      label: "Kode",
      isDataView: true,
      enabledAt: [SuratJalanCustomerTypeEnum.STOCK],
    },
    {
      id: "salesContract.penawaran.pelanggan.namaPanjang",
      type: "ignored",
      label: "Nama Customers",
      isDataView: true,
      enabledAt: [SuratJalanCustomerTypeEnum.STOCK],
    },
    {
      id: "catatan",
      label: "Note",
      type: "text-area",
      isDataView: true,
      isOptional: true,
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "jumlahCetakSuratJalan",
      label: "Cetak SJ",
      type: "ignored",
      isDataView: true,
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "invoicePelanggan.id",
      type: "hidden",
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "invoicePelanggan.jumlahCetakInvoice",
      label: "Cetak INV",
      type: "ignored",
      isDataView: true,
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "invoicePelanggan.jumlahCetakFakturPajak",
      label: "Cetak Pajak",
      type: "ignored",
      isDataView: true,
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
  ]
  return items
    .filter((i: SpesificModuleFormItem<SuratJalanCustomerTypeEnum>) =>
      i.enabledAt.includes(tipe)
    )
    .map(({ enabledAt, ...data }) => data)
}

export default generateSuratJalanCustomerFormItems
