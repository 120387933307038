import generateInstruksiJosForm from "common/form-generator/instruksi-jos"
import generateInvoiceJosForm from "common/form-generator/invoice-jos"
import generatePenawaranForms from "common/form-generator/penawaran"
import generateSalesContractForm from "common/form-generator/sales-contract"
import generateSPBForm from "common/form-generator/spb"
import { GeneralMap, PenawaranTypeEnum } from "common/type"
import { BasePageProps } from "components/page/type"

export const JosPages: GeneralMap<BasePageProps<any>> = {
  "penawaran-jos": generatePenawaranForms(PenawaranTypeEnum.JOS),
  "sales-kontrak-jos": generateSalesContractForm(PenawaranTypeEnum.JOS),
  "spb-jos": generateSPBForm(PenawaranTypeEnum.JOS, false),
  "cetak-spb-jos": generateSPBForm(PenawaranTypeEnum.JOS, true),
  "instruksi-jos": generateInstruksiJosForm(),
  "invoice-jos": generateInvoiceJosForm(),
}

export const JosPageKeys = Object.keys(JosPages)
