import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react"
import { getDataByKey } from "common/util"
import DataTable from "components/data-table"
import FormError from "components/error/form-error"
import BaseFilter from "components/filter/_base"
import ModuleDashboard from "components/layout/module-dashboard"
import ModalInfo from "components/modal/modal-info"
import SubTitle from "components/text/sub-title"
import { withAuth } from "hoc/auth"
import { useCetak } from "hooks"
import { useCallback, useMemo, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { BasePageContext, useDataPage } from "./common"
import { InformasiPageProps } from "./type"
import { useStateLogger } from "hooks/logger"

const InfoPage: React.FC<InformasiPageProps> = ({
  sourceUrl,
  sourceParam,
  fields,
  requiredToAdd,
  title,
  filterName,
  pdfUrl,
  excelUrl,
  detailInfo,
  responseMapper,
  disablePagination,
  ignoreFilterParamOnFetch,
  disableAdd,
  isHighlightRowCb,
}) => {
  const toast = useToast()
  const [detail, setDetail] = useState<any>()
  const [isDownloadExcel, setIsDownloadExcel] = useState<boolean>(false)
  const {
    masters,
    isLoadingGet,
    setFilterQuery,
    setDataListPageSize,
    dataListCurrentPage,
    setDataListCurrentPage,
    setQuerySearch,
    setSorter,
    dataListPageSize,
    queryParam,
    etcParam,
    isDisableAdd,
  } = useDataPage(
    `/${sourceUrl}`,
    useMemo(() => fields?.map((item) => item.dataKey) ?? ["nama"], [fields]),
    sourceParam,
    responseMapper,
    disablePagination,
    ignoreFilterParamOnFetch,
    disableAdd
  )

  const excelForm = useForm()

  // useEffect(() => {
  //   if (!filterQuery) setFilterQuery({})
  // }, [])

  const cetakQueryParam = useMemo(() => {
    const { page, pageSize, ...restParam } = queryParam

    return restParam
  }, [queryParam])

  const { doCetak, isLoading: isLoadingCetak } = useCetak(
    pdfUrl,
    useMemo(() => {
      const q = { ...cetakQueryParam }
      if (!!etcParam) {
        for (const i in etcParam) {
          q[i] = etcParam[i]
        }
      }
      return q
    }, [cetakQueryParam, etcParam])
  )

  const excelFileName = excelForm.watch("fileName")
  const { doCetak: downloadExcel, isLoading: isLoadingExcel } = useCetak(
    excelUrl,
    useMemo(
      () => ({ ...cetakQueryParam, fileName: excelFileName }),
      [cetakQueryParam, excelFileName]
    ),
    true
  )

  const onDownloadExcel = useCallback(async () => {
    try {
      await downloadExcel()

      setIsDownloadExcel(false)
      excelForm.reset()
    } catch (err: any) {
      toast({
        title: "Gagal",
        description: err.message ?? "Terjadi Kesalahan",
        status: "error",
        duration: 8000,
        isClosable: true,
        position: "top",
      })
    }
  }, [downloadExcel, excelForm, toast])

  const dataTableColumns = useMemo(() => {
    return (
      fields?.map((i) => {
        const { sortKey, ...field } = i
        return {
          ...field,
          disableSort: !sortKey,
        }
      }) ?? []
    )
  }, [fields])

  return (
    <BasePageContext.Provider
      value={{
        onChangeQuery: setFilterQuery,
        globVars: new Map(),
        detail,
        changeListener: 0,
        preCheckOrderCb: console.log,
      }}
    >
      <ModuleDashboard
        title={`${title}`}
        filter={filterName ? <BaseFilter keyName={filterName} /> : undefined}
        dataView={
          <>
            {isDisableAdd && (
              <Alert
                status="warning"
                mb="8px"
                borderRadius={12}
                fontSize="14px"
              >
                <AlertIcon />
                <AlertDescription>
                  Pilih {requiredToAdd?.join(", ")} terlebih dahulu
                </AlertDescription>
              </Alert>
            )}
            <DataTable
              columns={dataTableColumns}
              dataSource={masters?.data ?? []}
              totalData={!disablePagination ? masters?.total ?? 0 : undefined}
              pageSize={dataListPageSize}
              onClickRow={
                detailInfo
                  ? (data) => {
                      setDetail(data)
                    }
                  : undefined
              }
              hideData={isDisableAdd || isLoadingGet}
              onPageChange={(currentPage) => {
                setDataListCurrentPage(currentPage + 1)
              }}
              onPageSizeChange={(pageSize) => {
                setDataListPageSize(pageSize)
              }}
              isHighlightRowCb={isHighlightRowCb}
              currPage={dataListCurrentPage - 1}
              onSearch={(query) => {
                setQuerySearch(query)
              }}
              onSort={(sortBy, sortTypeNum) => {
                if (sortTypeNum !== 0) {
                  setSorter({
                    sortBy,
                    sortType: sortTypeNum === 1 ? "asc" : "desc",
                  })
                } else {
                  setSorter(undefined)
                }
              }}
              isLoading={isLoadingGet}
            />
            {!isDisableAdd && (
              <Flex justifyContent="flex-end">
                {pdfUrl && (
                  <Button
                    colorScheme="green"
                    variant="outline"
                    isLoading={isLoadingCetak}
                    onClick={() =>
                      doCetak().catch((err) => {
                        toast({
                          title: "Gagal",
                          description: err.message ?? "Terjadi Kesalahan",
                          status: "error",
                          duration: 8000,
                          isClosable: true,
                          position: "top",
                        })
                      })
                    }
                  >
                    Cetak
                  </Button>
                )}
                {excelUrl && (
                  <Button
                    colorScheme="blue"
                    variant="outline"
                    ml="8px"
                    onClick={() => setIsDownloadExcel(true)}
                    isLoading={isLoadingExcel}
                  >
                    Export Excel
                  </Button>
                )}
              </Flex>
            )}
            {detailInfo && (
              <ModalInfo
                isOpen={!!detail}
                onClose={() => setDetail(undefined)}
                title={`${title}`}
              >
                {detailInfo.childTables.map((child, key) => {
                  return (
                    <>
                      <Box mt="20px">
                        <SubTitle>{child.title}</SubTitle>
                      </Box>
                      <DataTable
                        dataSource={getDataByKey(detail, child.id) ?? []}
                        columns={child.fields}
                      />
                    </>
                  )
                })}
              </ModalInfo>
            )}
            <ModalInfo
              isOpen={isDownloadExcel}
              onClose={() => setIsDownloadExcel(false)}
              title="Download Laporan Excel"
            >
              <>
                <FormControl
                  isRequired={true}
                  isInvalid={!!excelForm.formState?.errors?.fileName}
                >
                  <FormLabel>Nama File</FormLabel>
                  <Controller
                    control={excelForm.control}
                    name={"fileName"}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        placeholder={"Nama File"}
                        value={value}
                        onChange={({ target: { value } }) => {
                          onChange(value)
                        }}
                        height={"32px"}
                        fontSize={"13px"}
                      />
                    )}
                  />
                  <FormError error={excelForm.formState?.errors?.fileName} />
                </FormControl>
                <Flex justify="flex-end">
                  <Button
                    colorScheme="blue"
                    variant="outline"
                    mt="8px"
                    onClick={excelForm.handleSubmit(onDownloadExcel)}
                    isLoading={isLoadingExcel}
                  >
                    Export Excel
                  </Button>
                </Flex>
              </>
            </ModalInfo>
          </>
        }
      />
    </BasePageContext.Provider>
  )
}

export default withAuth(InfoPage)
