import { useCallback, useEffect, useMemo, useState } from "react"
import DataTable, { buildCrudDataTableColumn } from "components/data-table"
import ModuleDashboard from "components/layout/module-dashboard"
import { withAuth } from "hoc/auth"
import {
  buildEndpointWithParam,
  getAPI,
  handleDownload,
  postAPI,
  usePost,
} from "hooks"
import { FormProvider, useForm } from "react-hook-form"
import {
  BasePageProps,
  BasePageMode,
  PageAction,
  FormModeType,
  LockModuleSpec,
} from "./type"
import { useUndoable } from "hooks/undoable"
import { DataTableColumn } from "components/data-table/type"
import { useDeepCompareEffect } from "hooks/effect"
import { useDependentFormFields } from "hooks/form/dependent-field"
import { uploadFile } from "hooks/http/file"
import ModalUndoableForm from "components/modal/modal-undoable-form"
import ModalDelete from "components/modal/modal-confirm"
import { useAuth } from "context/auth.context"
import {
  BasePageContext,
  BasePageDataTableContext,
  useGetFormItem,
  useDataPage,
} from "./common"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react"
import ModalConfirm from "components/modal/modal-confirm"
import _ from "lodash"
import { useGetAllGlobVar } from "services/glob-var"
import {
  cleanObject,
  dateToStr,
  getDataByKey,
  getPeriodOfDate,
  translateApiResponse,
} from "common/util"
import { useStateLogger } from "hooks/logger"

interface BasePageActionProps {
  pageActions: PageAction[]
  filterData: any
  fetchMasterData: () => Promise<any>
}

const BasePageAction: React.FC<BasePageActionProps> = ({
  pageActions,
  filterData,
  fetchMasterData,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [confirmCb, setConfirmCb] = useState<() => void>()
  const toast = useToast()

  const onSuccessFn = useCallback(
    (message: string) => {
      toast({
        title: "Berhasil",
        description: message,
        status: "success",
        duration: 8000,
        isClosable: true,
        position: "top",
      })
      setIsLoading(false)
      fetchMasterData().then(() => {})
    },
    [toast, fetchMasterData]
  )

  const onFailFn = useCallback(
    (message: string) => {
      toast({
        title: "Gagal",
        description: message,
        status: "error",
        duration: 8000,
        isClosable: true,
        position: "top",
      })
      setIsLoading(false)
    },
    [toast]
  )

  return (
    <>
      {/* @ts-ignore */}
      <Flex justifyContent={"flex-end"} mt="12px">
        {pageActions.map((pageAction, key) => (
          <>
            {isLoading ? (
              <Spinner color="primary" />
            ) : (
              <Button
                variant="primary"
                onClick={() =>
                  // @ts-ignore
                  setConfirmCb(() => () => {
                    setIsLoading(true)
                    pageAction
                      .action(filterData)
                      .then(() => onSuccessFn("Berhasil"))
                      .catch((err) =>
                        onFailFn(translateApiResponse(err).message)
                      )
                  })
                }
                key={key}
              >
                {pageAction.label}
              </Button>
            )}
            <ModalConfirm
              isOpen={!!confirmCb}
              onClose={() => setConfirmCb(undefined)}
              onConfirm={() => {
                confirmCb?.()
                setConfirmCb(undefined)
              }}
              confirmAction={{
                color: "blue",
                msg: pageAction.confirmMsg,
                label: pageAction.label,
                title: `Konfirmasi ${pageAction.label}`,
              }}
            />
          </>
        ))}
      </Flex>
    </>
  )
}

const moduleWithLocks: LockModuleSpec[] = [
  {
    id: "purchase-order",
    title: "Buka PPS",
    getKeys: (data) => {
      return [data.pengadaan?.no]
    },
    getIsLocked(data) {
      return !!data.pengadaan?.lockTransaksi?.lockOpen
    },
  },
  {
    id: "purchase-order-ttlc",
    title: "Buka SC",
    getKeys: (data) => {
      return [data.salesContract?.noSalesContract]
    },
    getIsLocked(data) {
      return !!data.salesContract?.lockTransaksi?.lockOpen
    },
  },
  {
    id: "bapb",
    title: "Buka PO",
    getKeys: (data) => {
      const insertedNoRef: Map<string, boolean> = new Map()
      const refs: string[] = []

      for (const itemBapb of data.itemBapbs ?? []) {
        const noPo = itemBapb?.itemPurchaseOrderStock?.purchaseOrderStock?.noPo
        if (!noPo) continue
        if (!insertedNoRef.has(noPo)) {
          refs.push(noPo)
          insertedNoRef.set(noPo, true)
        }
      }

      return refs
    },
    getIsLocked(data) {
      return !!data.itemBapbs?.[0]?.itemPurchaseOrderStock?.purchaseOrderStock
        ?.lockTransaksi?.lockOpen
    },
  },
  {
    id: "surat-jalan-internal",
    title: "Buka BAPB",
    getKeys(data) {
      return [data?.bapb?.no]
    },
    getIsLocked(data) {
      return !!data?.bapb?.lockTransaksi?.lockOpen
    },
  },
  {
    id: "sales-contract-ttlc",
    title: "Buka Penawaran",
    getKeys(data) {
      return [data?.penawaran?.no]
    },
    getIsLocked(data) {
      return !!data?.penawaran?.lockTransaksi?.lockOpen
    },
  },
  {
    id: "sales-contract",
    title: "Buka Penawaran",
    getKeys(data) {
      return [data?.penawaran?.no]
    },
    getIsLocked(data) {
      return !!data?.penawaran?.lockTransaksi?.lockOpen
    },
  },
  {
    id: "persetujuan-kasbon-biaya-import",
    title: "Buka Kasbon",
    getKeys(data) {
      return [data.nomor]
    },
    getIsLocked(data) {
      return !!data?.lockTransaksi?.lockOpen
    },
  },
  {
    id: "lampiran-kontrak",
    title: "Buka Sales Contract",
    getKeys(data) {
      return [data.salesContract.noSalesContract]
    },
    getIsLocked(data) {
      return !!data?.salesContract.lockTransaksi?.lockOpen
    },
  },
  {
    id: "sales-kontrak-jos",
    title: "Buka Penawaran",
    getKeys(data) {
      return [data.penawaran.no]
    },
    getIsLocked(data) {
      return !!data?.penawaran.lockTransaksi?.lockOpen
    },
  },
  {
    id: "spb-jos",
    title: "Buka SC",
    getKeys(data) {
      return [data.salesContract.noSalesContract]
    },
    getIsLocked(data) {
      return !!data?.salesContract.lockTransaksi?.lockOpen
    },
  },
  {
    id: "instruksi-jos",
    title: "Buka SPB",
    getKeys(data) {
      return [data.sfb.noSpb]
    },
    getIsLocked(data) {
      return !!data?.sfb.lockTransaksi?.lockOpen
    },
  },
  {
    id: "invoice-jos",
    title: "Buka Instruksi",
    getKeys(data) {
      return [data.instruksiJos.no]
    },
    getIsLocked(data) {
      return !!data?.instruksiJos.lockTransaksi?.lockOpen
    },
  },
]

const BasePage: React.FC<BasePageProps<any>> = <T extends any>({
  id,
  title,
  isHighlightRowCb,
  urls,
  formGroupItems: formGroupItemProps,
  filter,
  primaryKey,
  pageActions,
  additionalForms,
  additionalTitle,
  recalculateCallback,
  cetakActions,
  customFormDataMapper,
  onOpen,
  customAction,
  disableAdd,
  alertMessage,
  generateCodeParam,
  isOnlyViewData,
  dataRenderCondition,
  requiredToAdd,
  responseMapper,
  additionalView: AdditionalView,
  preCheckOrderCb: preCheckOrderCbProps,
  hideAdd,
  ignoreFilterOnFetch,
  disablePagination,
  summaryCallback,
}: BasePageProps<T>) => {
  const toast = useToast()
  const { profile } = useAuth()
  const [pageMode, setPageMode] = useState<BasePageMode>("view")
  const [formMode, setFormMode] = useState<FormModeType>("create")
  const [changeListener, setChangeListener] = useState<number>(0)

  const [detail, setDetail] = useState<T>()
  const [deleteId, setDeleteId] = useState<number>()
  const [lockInfo, setLockInfo] = useState<{
    refs: string[]
    lockOpen: boolean
  }>()

  const rhf = useForm({
    mode: "all",
  })
  const { reset } = rhf
  const formData = rhf.watch()

  const { data: globVarsData } = useGetAllGlobVar()

  useEffect(() => {
    document.title = `${title} | Sekawan`
  }, [title])

  const {
    masters,
    fetchMasterData,
    isLoadingGet,
    filterQuery,
    setFilterQuery,
    setDataListPageSize,
    setDataListCurrentPage,

    setQuerySearch,
    setSorter,
    dataListPageSize,
    dataListCurrentPage,
    isDisableAdd,
  } = useDataPage<T>(
    urls?.read.endpoint,
    useMemo(() => {
      return (
        formGroupItemProps
          ?.filter((item) => {
            const isDataview =
              typeof item.isDataView === "function"
                ? item.isDataView(profile)
                : !!item.isDataView
            if (item.id === "hargaBeli") console.log({ isDataview })
            return (isDataview || !!item.dataViewLabel) && !item.ignoreToSearch
          })
          .map(
            (item) =>
              (typeof item.dataViewKey === "function"
                ? null
                : item.dataViewKey) ?? item.id
          ) ?? ["name"]
      )
    }, [formGroupItemProps, profile]),
    urls?.read.param,
    responseMapper,
    disablePagination,
    ignoreFilterOnFetch,
    disableAdd
  )

  const { doFetch: createData, isLoading: loadingCreateData } = usePost(
    urls?.create.endpoint ?? "",
    urls?.create.method ?? "post",
    useMemo(
      () => ({
        id: (detail as any)?.[primaryKey ?? "id"],
      }),
      [detail, primaryKey]
    )
  )
  const { doFetch: updateData, isLoading: loadingUpdateData } = usePost(
    urls?.update.endpoint ?? "",
    urls?.update.method ?? "patch",
    useMemo(
      () => ({
        id: (detail as any)?.[primaryKey ?? "id"],
      }),
      [detail, primaryKey]
    )
  )
  const { doFetch: deleteData, isLoading: loadingDelete } = usePost(
    urls?.delete.endpoint ?? "",
    urls?.delete.method ?? "delete",
    { id: deleteId }
  )

  const { isCanRedo, isCanUndo, doUndo, doRedo, addHistory, resetHistory } =
    useUndoable()

  useDeepCompareEffect(() => {
    if (formData != null && Object.keys(formData).length > 0) {
      addHistory(formData)
    }
  }, [formData, addHistory])

  const onCloseModal = useCallback(() => {
    resetHistory()
    reset({})
    setDetail(undefined)
  }, [reset, resetHistory])

  const checkDate = useCallback((filter, data) => {
    if (["kontra-bon"].includes(id ?? "")) return
    let date: Date | undefined
    for (const idx in filter) {
      if (
        idx.endsWith("_$btwEnd") &&
        (idx.includes("tanggal") || idx.includes("tgl"))
      ) {
        date = new Date(filter?.[idx])
      }
    }

    if (!!date) {
      let tanggal
      console.log({ data })
      for (const idx in data) {
        if (
          (idx === "tanggal" ||
            idx === "tanggalVoucher" ||
            idx.includes("tanggal")) &&
          idx !== "tanggalJatuhTempo"
        ) {
          tanggal = new Date(data[idx])
          break
        }
      }
      console.log({ tanggal, date })
      if (!!tanggal) {
        if (getPeriodOfDate(tanggal) !== getPeriodOfDate(date)) {
          throw new Error("Tanggal tidak boleh diluar periode")
        }
      }
    }
  }, [])

  const onSubmit = useCallback(
    async (_formData: any) => {
      if (_.has(_formData, "createdAt")) {
        delete _formData.createdAt
      }
      if (_.has(_formData, "updatedAt")) {
        delete _formData.updatedAt
      }

      const formData = cleanObject(_formData)

      if (formData.id === -1) delete formData.id
      try {
        const data: any = {}
        for (const i in formData) {
          if (i === "ignored") continue
          if (!!formData[i] && formData[i] instanceof File) {
            data[i] = await uploadFile(formData[i])
          } else {
            if (formData[i] !== "") {
              data[i] = formData[i]
            } else {
              data[i] = null
            }
          }
        }

        const sendData = customFormDataMapper
          ? customFormDataMapper(data, filterQuery)
          : data

        checkDate(filterQuery, sendData)

        if (formMode === "update") {
          if (id === "persetujuan-kasbon-biaya-import") {
            await updateData(sendData, formData)
          } else {
            await updateData(sendData)
          }
        } else {
          if (id === "persetujuan-kasbon-biaya-import") {
            await createData(sendData, formData)
          } else {
            await createData(sendData)
          }
        }
        fetchMasterData()
        toast({
          title: "Berhasil",
          description: "Data berhasil disimpan",
          status: "success",
          duration: 8000,
          isClosable: true,
          position: "top",
        })
        setChangeListener((prevState) => prevState + 1)
        onCloseModal()
      } catch (err: any) {
        toast({
          title: "Gagal",
          description: err.message ?? "Terjadi Kesalahan",
          status: "error",
          duration: 8000,
          isClosable: true,
          position: "top",
        })
      }
    },
    [
      customFormDataMapper,
      filterQuery,
      onCloseModal,
      fetchMasterData,
      toast,
      id,
      updateData,
      formMode,
      createData,
    ]
  )

  const formGroupItems = useGetFormItem(
    formGroupItemProps ?? [],
    pageMode,
    formMode,
    additionalForms,
    detail,
    filterQuery,
    ignoreFilterOnFetch
  )

  const { onChangeField } = useDependentFormFields({
    items: formGroupItems,
    setValue: rhf.setValue,
    mapper: {},
  })

  const _cetakActions = useMemo(() => {
    const cetakActionResult =
      typeof cetakActions === "function" ? cetakActions(detail) : cetakActions
    const id = (detail as any)?.[primaryKey ?? "id"]
    return (
      cetakActionResult?.map((i) => ({
        ...i,
        url: !id
          ? i.url
          : buildEndpointWithParam(i.url ?? "", {
              id,
            }),
      })) ?? [
        {
          label: "Cetak",
          url: !id
            ? urls?.download.endpoint ?? ""
            : buildEndpointWithParam(urls?.download.endpoint ?? "", {
                id: (detail as any)?.[primaryKey ?? "id"],
              }),
        },
      ]
    )
  }, [cetakActions, detail, primaryKey, urls])

  const [isLoadingCheckTrx, setIsLoadingCheckTrx] = useState<boolean>(false)
  const onCheckTrx = useCallback(
    async (idTrx) => {
      // TODO:set as constant
      if (idTrx === -1) return
      setIsLoadingCheckTrx(true)
      try {
        await getAPI("/lock-transaksi/check", {
          type: id,
          id: idTrx,
        })
        setIsLoadingCheckTrx(false)
      } catch (err: any) {
        toast({
          title: "Gagal",
          description:
            err.response?.data?.message ?? err.message ?? "Terjadi Kesalahan",
          status: "error",
          duration: 8000,
          isClosable: true,
          position: "top",
        })
        setIsLoadingCheckTrx(false)
        throw err
      }
    },
    [id, toast]
  )

  const onLockTrx = useCallback(async () => {
    try {
      await postAPI("/lock-transaksi", {
        noRefs: lockInfo?.refs,
        lockType: id,
        lockOpen: lockInfo?.lockOpen,
      })

      fetchMasterData()

      toast({
        title: "Berhasil",
        description: "Kunci data berhasil diubah",
        status: "success",
        duration: 8000,
        isClosable: true,
        position: "top",
      })

      setLockInfo(undefined)
    } catch (err: any) {
      toast({
        title: "Gagal",
        description:
          err.response?.data?.message ?? err.message ?? "Terjadi Kesalahan",
        status: "error",
        duration: 8000,
        isClosable: true,
        position: "top",
      })
    }
  }, [lockInfo?.refs, lockInfo?.lockOpen, id, fetchMasterData, toast])

  // TODO: need to reset pagination after filter changed
  const dataListColumns = useMemo<DataTableColumn<any>[]>(() => {
    const moduleForLock = moduleWithLocks.find((i) => i.id === id)
    return buildCrudDataTableColumn(
      formGroupItemProps ?? [],
      async (data) => {
        await onCheckTrx(data.id).then(() => {
          setPageMode("edit")
          if (!data || data.id === -1) {
            setFormMode("create")
          } else {
            setFormMode("update")
          }
          setDetail(data)
        })
      },
      setDeleteId,
      primaryKey,
      undefined,
      isOnlyViewData,
      _cetakActions.length === 1
        ? async (data) => {
            try {
              await handleDownload(
                buildEndpointWithParam(
                  _cetakActions?.[0]?.url ?? urls?.download?.endpoint ?? "",
                  {
                    id: data?.[primaryKey ?? "id"],
                  }
                )
              )
              if (!!_cetakActions[0].isRefetch) {
                await fetchMasterData?.()
              }
            } catch (err: any) {
              toast({
                title: "Gagal",
                description: err.message ?? "Terjadi Kesalahan",
                status: "error",
                duration: 8000,
                isClosable: true,
                position: "top",
              })
            }
          }
        : undefined,
      !!moduleForLock
        ? async (data: any, lockOpen: boolean) => {
            setLockInfo({
              refs: moduleForLock.getKeys(data),
              lockOpen,
            })
          }
        : undefined,
      moduleForLock,
      filterQuery,
      profile
    )
  }, [
    formGroupItemProps,
    primaryKey,
    isOnlyViewData,
    _cetakActions,
    urls?.download.endpoint,
    id,
    onCheckTrx,
    fetchMasterData,
    toast,
    filterQuery,
  ])

  const onCloseDelete = useCallback(() => {
    setDeleteId(undefined)
  }, [])

  const onDelete = useCallback(async () => {
    try {
      if (urls?.delete.customHandler) {
        await urls?.delete.customHandler(
          {
            id: deleteId,
          },
          deleteData
        )
      } else {
        await deleteData({})
      }
      onCloseDelete()
      fetchMasterData()
      toast({
        title: "Berhasil",
        description: "Data berhasil dihapus",
        status: "warning",
        duration: 8000,
        isClosable: true,
        position: "top",
      })
      setChangeListener((prevState) => prevState + 1)
    } catch (err: any) {
      toast({
        title: "Gagal",
        description: err.message ?? "Terjadi Kesalahan",
        status: "error",
        duration: 8000,
        isClosable: true,
        position: "top",
      })
    }
  }, [
    urls?.delete,
    onCloseDelete,
    fetchMasterData,
    toast,
    deleteId,
    deleteData,
  ])

  useEffect(() => {
    if (!!detail) {
      setTimeout(() => {
        onOpen?.(detail, profile, rhf.setValue, filterQuery, formMode)
      }, 1000)
    }
  }, [detail, profile, rhf, onOpen, filterQuery, formMode])

  const isRenderData = useMemo(() => {
    if (!dataRenderCondition) return true
    return dataRenderCondition(filterQuery)
  }, [dataRenderCondition, filterQuery])

  useEffect(() => {
    if (!filter) {
      setFilterQuery({})
    }
  }, [filter, setFilterQuery])

  const preCheckOrderCb = useCallback(() => {
    if (!preCheckOrderCbProps) return

    preCheckOrderCbProps(rhf.getValues()).then((res) => {
      if (!!res) {
        toast({
          title: "Perhatian!!",
          description: res,
          status: "warning",
          isClosable: true,
          position: "top",
        })
      }
    })
  }, [preCheckOrderCbProps, rhf, toast])

  const onChangeQuery = useCallback(
    (filter) => {
      if (!!ignoreFilterOnFetch) {
        for (const i of ignoreFilterOnFetch) {
          rhf.setValue(i, filter[i])
        }
      }
      setFilterQuery(filter)
    },
    [ignoreFilterOnFetch, rhf, setFilterQuery]
  )

  return (
    <BasePageContext.Provider
      value={{
        onChangeQuery,
        filterData: filterQuery,
        globVars: globVarsData ?? new Map(),
        detail,
        changeListener,
        preCheckOrderCb,
      }}
    >
      <ModuleDashboard
        title={`Daftar ${title}`}
        filter={filter}
        additionalView={!!AdditionalView ? <AdditionalView /> : undefined}
        dataView={
          isRenderData ? (
            <BasePageDataTableContext.Provider
              value={{
                pageMode,
                setPageMode,
                formMode: formMode,
                pageRhf: rhf,
              }}
            >
              <FormProvider {...rhf}>
                {isDisableAdd && (
                  <Alert
                    status="warning"
                    mb="8px"
                    borderRadius={12}
                    fontSize="14px"
                  >
                    <AlertIcon />
                    <AlertDescription>
                      Pilih {requiredToAdd?.join(", ")} terlebih dahulu
                    </AlertDescription>
                  </Alert>
                )}
                <DataTable
                  isHighlightRowCb={isHighlightRowCb}
                  summaryCallback={summaryCallback}
                  dataSource={masters?.data ?? []}
                  columns={dataListColumns}
                  hideData={isDisableAdd || isLoadingGet}
                  pageSize={disablePagination ? undefined : dataListPageSize}
                  disableAdd={isDisableAdd}
                  onClickAdd={
                    hideAdd
                      ? undefined
                      : () => {
                          setPageMode("edit")
                          setFormMode("create")
                          setDetail({} as any)
                        }
                  }
                  totalData={!disablePagination ? masters?.total : 0}
                  onPageChange={(currentPage) => {
                    setDataListCurrentPage(currentPage + 1)
                  }}
                  onClickRow={(_data) => {
                    setPageMode("view")
                    const data = _data as any
                    if (!data.id || data.id === -1) {
                      setFormMode("create")
                    } else {
                      setFormMode("update")
                    }
                    setDetail(data)
                  }}
                  onPageSizeChange={(pageSize) => {
                    setDataListPageSize(pageSize)
                  }}
                  onSearch={(query) => {
                    setDataListCurrentPage(1)
                    setQuerySearch(query)
                  }}
                  onSort={(sortBy, sortTypeNum) => {
                    if (sortTypeNum !== 0) {
                      setSorter({
                        sortBy,
                        sortType: sortTypeNum === 1 ? "asc" : "desc",
                      })
                    } else {
                      setSorter(undefined)
                    }
                  }}
                  currPage={dataListCurrentPage - 1}
                  isLoading={isLoadingGet}
                />

                <ModalUndoableForm
                  isOnlyViewData={isOnlyViewData}
                  formGroupItems={formGroupItems}
                  isCanRedo={isCanRedo}
                  isLoading={
                    loadingCreateData || loadingUpdateData || isLoadingCheckTrx
                  }
                  isCanUndo={isCanUndo}
                  isOpen={!!detail}
                  onClose={onCloseModal}
                  onRedo={doRedo}
                  onUndo={doUndo}
                  onSubmit={onSubmit}
                  rhf={rhf}
                  onFormGroupChangeField={onChangeField}
                  title={title}
                  additionalForms={additionalForms}
                  additionalTitle={additionalTitle}
                  recalculateCallback={recalculateCallback}
                  generateCodeParam={generateCodeParam}
                  cetakActions={_cetakActions}
                  customAction={customAction}
                  alertMessage={alertMessage}
                  onCheckTrx={async () => {
                    if (!(detail as any)?.id) return
                    return await onCheckTrx((detail as any).id)
                  }}
                  fetchMasterData={fetchMasterData}
                />
                <ModalDelete
                  isOpen={!!deleteId}
                  onClose={onCloseDelete}
                  onConfirm={onDelete}
                  isLoading={loadingDelete}
                  confirmAction={{
                    title: "Hapus Data",
                    label: "Hapus",
                    color: "red",
                    msg: "Apakah anda yakin untuk menghapus data ini?",
                  }}
                />

                <ModalDelete
                  isOpen={!!lockInfo}
                  onClose={() => setLockInfo(undefined)}
                  onConfirm={onLockTrx}
                  confirmAction={{
                    title: "Kunci Data",
                    label: "Ubah",
                    color: "orange",
                    msg: "Apakah anda yakin untuk mengubah kunci data ini?",
                  }}
                />
              </FormProvider>
              {pageActions && (
                <BasePageAction
                  pageActions={pageActions}
                  filterData={filterQuery}
                  fetchMasterData={fetchMasterData}
                />
              )}
            </BasePageDataTableContext.Provider>
          ) : (
            <Flex justifyContent="center" alignItems="center" py="12px">
              <Text color="gray">Proses filter terlebih dahulu</Text>
            </Flex>
          )
        }
      />
    </BasePageContext.Provider>
  )
}

export default withAuth(BasePage)
