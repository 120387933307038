import { mapPageDictToMenu } from "common/menu-util"
import { MenuItem } from "common/type"
import { JosPageKeys, JosPages } from "./form-pages/jos"
import { GLBulkPages, GLPageKeys, GLPages } from "./form-pages/gl"
import { MasterPageKeys, MasterPages } from "./form-pages/master"
import { PengadaanPageKeys, PengadaanPages } from "./form-pages/pengadaan"
import { PenjualanPageKeys, PenjualanPages } from "./form-pages/penjualan"
import { PinjamanPageKeys, PinjamanPages } from "./form-pages/pinjaman"
import { SamplePageKeys, SamplePages } from "./form-pages/sample"
import { ServicePageKeys, ServicePages } from "./form-pages/service"
import { TTLCPageKeys, TTLCPages } from "./form-pages/ttlc"
import { InformasiPageKeys, InformasiPages } from "./form-pages/informasi"
import {
  InformasiGLPageKeys,
  InformasiGLPages,
} from "./form-pages/informasi-gl"
import { LaporanPageKeys, LaporanPages } from "./form-pages/laporan"
import { SettingPageKeys, SettingPages } from "./form-pages/setting"

export const MENU: MenuItem = {
  id: "root",
  title: "Root",
  children: [
    {
      id: "dashboard",
      menuUrl: "/",
      title: "Dashboard",
      children: [],
    },
    {
      id: "user-management",
      menuUrl: "/user-management",
      title: "User Management",
      permissionRequired: "SIT_master::utility::password",
      children: [],
    },
    {
      id: "audit-trail",
      menuUrl: "/audit-trail",
      title: "Log User",
      permissionRequired: "SIT_master::log_user",
      children: [],
    },
    {
      id: "master",
      title: "Master Data",
      children: [
        {
          id: "perusahaan",
          title: "Perusahaan",
          menuUrl: `/master/perusahaan`,
          children: [],
        },
        ...mapPageDictToMenu(MasterPageKeys, "master", MasterPages),
      ],
    },
    {
      id: "pengadaan",
      title: "Pengadaan",
      children: mapPageDictToMenu(
        PengadaanPageKeys,
        "pengadaan",
        PengadaanPages
      ),
    },
    {
      id: "penjualan",
      title: "Penjualan Barang",
      children: mapPageDictToMenu(
        PenjualanPageKeys,
        "penjualan",
        PenjualanPages
      ),
    },
    {
      id: "ttlc",
      title: "TT/LC",
      children: mapPageDictToMenu(TTLCPageKeys, "ttlc", TTLCPages),
    },
    {
      id: "service",
      title: "Service",
      children: mapPageDictToMenu(ServicePageKeys, "service", ServicePages),
    },
    {
      id: "jos",
      title: "JOS",
      children: mapPageDictToMenu(JosPageKeys, "jos", JosPages),
    },
    {
      id: "pinjaman",
      title: "Pinjaman",
      children: mapPageDictToMenu(PinjamanPageKeys, "pinjaman", PinjamanPages),
    },
    {
      id: "sample",
      title: "Sample",
      children: mapPageDictToMenu(SamplePageKeys, "sample", SamplePages),
    },
    {
      id: "gl",
      title: "GL",
      children: mapPageDictToMenu(GLPageKeys, "gl", GLPages, GLBulkPages),
    },
    {
      id: "informasi",
      title: "Informasi",
      children: mapPageDictToMenu(
        InformasiPageKeys,
        "informasi",
        undefined,
        undefined,
        InformasiPages
      ),
    },
    {
      id: "informasi-gl",
      title: "Informasi GL",
      children: mapPageDictToMenu(
        InformasiGLPageKeys,
        "informasi-gl",
        undefined,
        undefined,
        InformasiGLPages
      ),
    },
    {
      id: "laporan",
      title: "Laporan",
      children: mapPageDictToMenu(
        LaporanPageKeys,
        "laporan",
        undefined,
        undefined,
        LaporanPages
      ),
    },
    {
      id: "setting",
      title: "Pengaturan",
      children: mapPageDictToMenu(SettingPageKeys, "setting", SettingPages),
    },
  ],
}
