import { Text } from "@chakra-ui/react"
import generateLampiranKontrakFormItems from "common/form-item-generator/lampiran-kontrak"
import { GeneralMap, PenawaranTypeEnum } from "common/type"
import {
  formatNumber,
  generateCrudUrl,
  genFieldQueryParamObj,
  genFormItems,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"

const TITLE: GeneralMap<string> = {
  [PenawaranTypeEnum.STOCK]: "Lampiran Kontrak Franco",
}
function generateLampiranKontrakForm(
  tipe: PenawaranTypeEnum
): BasePageProps<any> {
  return {
    permissionRequired: `SIT_sekawan::transaksi::TT/LC::${
      tipe === PenawaranTypeEnum.INDENT ? "marketing" : "purchasing"
    }::lampiran_kontrak`,
    title: TITLE[tipe] ?? "Lampiran Kontrak",
    urls: {
      ...generateCrudUrl("lampiran-kontrak"),
      read: {
        method: "get",
        endpoint: "/lampiran-kontrak",
        param: {
          ...genFieldQueryParamObj({
            "salesContract.penawaran.tipe": tipe,
          }),
          sortBy: "id",
          sortType: "desc",
        },
      },
    },
    filter: <BaseFilter keyName="lampiranKontrak" />,
    requiredToAdd: ["Cabang", "Divisi"],
    disableAdd(data, filterData) {
      return (
        !filterData?.["salesContract.penawaran.organisasi.id"] ||
        !filterData?.["salesContract.penawaran.divisi.id"]
      )
    },
    formGroupItems: genFormItems(generateLampiranKontrakFormItems(tipe)),
    additionalForms: [
      {
        id: "salesContract.penawaran.itemPenawarans",
        hideAdd: true,
        title: "Daftar Barang",
        formItems: [
          {
            id: "barang.kode",
            label: "Kode Barang",
            isDataView: true,
          },
          {
            id: "barang.nama",
            label: "Nama Barang",
            isDataView: true,
          },
          {
            id: "qty",
            label: "Quantity",
            isDataView: true,
            type: "number",
          },
          {
            id: "hargaJual",
            label: "Amount",
            isDataView: true,
            type: "number",
            columnRender(data) {
              return (
                // @ts-ignore
                <Text fontSize="14px" fontWeight="600">
                  {formatNumber(+data.hargaJual * +data.qty)}
                </Text>
              )
            },
          },
        ],
      },
    ],
  }
}

export default generateLampiranKontrakForm
