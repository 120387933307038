import { getValue } from "@testing-library/user-event/dist/utils"
import {
  PenawaranTypeEnum,
  PoTypeEnum,
  SpesificModuleFormItem,
} from "common/type"
import {
  genFieldQueryParamStr,
  genOptionFromArray,
  getToday,
  isValueEmpty,
} from "common/util"
import { BasePageFormGroupItem } from "components/page/type"
import { SEKAWAN_ADDRESS } from "constant"
import {
  pelangganOptionMapper,
  pelangganSearchKeys,
  supplierOptionMapper,
  supplierResourceColumnKey,
  supplierSearchKey,
} from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"

function generatePurchaseOrderFormItems(
  poType: PoTypeEnum
): BasePageFormGroupItem[] {
  const items: SpesificModuleFormItem<PoTypeEnum>[] = [
    {
      id: "createdAt",
      type: "hidden",
      enabledAt: [PoTypeEnum.STOCK],
    },
    {
      id: "poType",
      type: "hidden",
      value: poType,
      enabledAt: [
        PoTypeEnum.STOCK,
        PoTypeEnum.TTLC,
        PoTypeEnum.SERVICE,
        PoTypeEnum.INDENT,
      ],
    },
    {
      id: "noPo",
      isIgnored: true,
      isDataView: true,
      label: "Nomor PO",
      enabledAt: [PoTypeEnum.STOCK, PoTypeEnum.INDENT, PoTypeEnum.TTLC],
    },
    {
      id: "salesContract.id",
      label: "No. Penawaran",
      type: "async-table-select",
      resourceUrl: (data, filterData) => {
        const q: any = {
          "penawaran.tipe": poType,
          "penawaran.divisi.id":
            filterData?.["salesContract.penawaran.divisi.id"],
          "purchaseOrders.id": "null",
          "salesContract.status": "NEED_PO",
        }

        if (poType !== PoTypeEnum.SERVICE) {
          q["lampiranKontraks.id"] = "notNull"
          if (!!filterData?.["supplier.id"])
            q["lampiranKontraks.supplier.id"] = filterData?.["supplier.id"]
        } else {
          q["instruksiServices.id"] = "notNull"
          q["instruksiServices.supplierIS.id"] = filterData?.["supplier.id"]
        }
        return `sales-contract?${genFieldQueryParamStr(
          q
        )}&${genBaseSortQueryParam("id", false)}`
      },
      customOnChange: (data, setValue, getValues, genCode, globVars) => {
        setValue("salesContract", data)
        setValue("scRef", data.noSalesContract)
        const formData = getValues()
        setValue("isPoCustomer", false)
        if (!formData.modeOfShipment)
          setValue("modeOfShipment", data?.penawaran?.modeOfShipment)
        if (!formData.termOfPayment)
          setValue("termOfPayment", data?.penawaran?.termOfPayment)
        if (formData.isPpn === null || formData.isPpn === undefined)
          setValue("isPpn", data?.penawaran?.isPpn)
        if (!formData.tanggal) setValue("tanggal", data?.tanggal)

        setValue("mataUang", data?.penawaran?.mataUang)
        setValue("pelanggan", data?.penawaran?.pelanggan)

        if (isValueEmpty(formData.tandaTanganOleh)) {
          setValue("tandaTanganOleh", globVars.get("KABAG_PURCHASING"))
        }

        setValue("komisi", data?.penawaran?.komisi ?? 0)
        if (!getValues("komisiReal")) {
          const nilaiContract =
            data?.penawaran.itemPenawarans?.reduce(
              (total: number, item: any) => {
                return total + +item.qty * +item.hargaJual
              },
              0
            ) ?? 0
          const totalSelisihHarga =
            data?.penawaran.itemPenawarans?.reduce(
              (total: number, item: any) => {
                return total + +item.qty * (+item.hargaJual - +item.hargaBeli)
              },
              0
            ) ?? 0

          setValue(
            "komisiReal",
            ((+(data?.penawaran?.komisi ?? 0) +
              +(data?.penawaran?.diskonSupplier ?? 0)) *
              nilaiContract) /
              100 +
              totalSelisihHarga
          )
        }
        if (isValueEmpty(formData.freight))
          setValue("freight", data?.penawaran?.freight ?? 0)
        if (isValueEmpty(formData.packing))
          setValue("packing", data?.penawaran?.packing ?? 0)
        if (isValueEmpty(formData.insurance))
          setValue("insurance", data?.penawaran?.insurance ?? 0)
        if (isValueEmpty(formData.fobCharge))
          setValue("fobCharge", data?.penawaran?.fobCharge ?? 0)
        if (isValueEmpty(formData.bankCharge))
          setValue("bankCharge", data?.penawaran?.bankCharge ?? 0)

        if (isValueEmpty(formData.diskonSupplier))
          setValue("diskonSupplier", data?.penawaran?.diskonSupplier ?? 0)
        setValue("organisasi.id", data?.penawaran?.organisasi?.id)
        setValue("divisi.id", data?.penawaran?.divisi?.id)
        setValue("conditionOfDelivery", data?.penawaran?.placeOfDelivery)
        if (poType === PoTypeEnum.SERVICE) {
          setValue("supplier", data?.instruksiServices?.[0]?.supplier)
        } else {
          setValue("supplier", data?.lampiranKontraks?.[0]?.supplier)
        }

        let i = 0
        const existingItems = getValues("itemPurchaseOrderStocks")
        if (!existingItems?.length) {
          let items: any[] =
            data?.penawaran.itemPenawarans?.map((item: any) => {
              const val = {
                itemPenawaran: item,
                xId: i,
              }
              i++
              return val
            }) ?? []
          if (poType === PoTypeEnum.SERVICE) {
            items = items.concat(
              data?.penawaran?.itemServices?.map((item: any) => {
                i += 1
                return {
                  itemService: item,
                  xId: i,
                }
              }) ?? []
            )
          }
          setValue("itemPurchaseOrderStocks", items)
        }
      },
      resourceColumn: [
        {
          dataKey: "penawaran.no",
          label: "No. Penawaran",
        },
        {
          dataKey: "noSalesContract",
          label: "No. Sales Contract",
        },
        {
          dataKey: "penawaran.tipe",
          label: "Jenis Penawaran",
        },
        {
          dataKey: "penawaran.sales.nama",
          label: "Nama Sales",
        },
        {
          dataKey: "penawaran.pelanggan.namaPanjang",
          label: "Nama Customer",
        },
      ],
      resourceColumnLabelKey: "penawaran.no",
      column: 1,
      dataViewLabel:
        poType === PoTypeEnum.SERVICE ? "Nomor PO" : "Nomor Penawaran",
      dataViewKey:
        poType === PoTypeEnum.SERVICE ? "noPo" : "salesContract.penawaran.no",
      enabledAt: [PoTypeEnum.TTLC, PoTypeEnum.SERVICE, PoTypeEnum.INDENT],
    },
    {
      id: "salesContract.tanggal",
      label: "Tanggal",
      type: "ignored",
      isDataView: true,
      enabledAt: [PoTypeEnum.TTLC],
    },
    {
      id: "salesContract.noPoPelanggan",
      type: "hidden",
      enabledAt: [PoTypeEnum.TTLC],
    },
    {
      id: "salesContract.noSalesContract",
      type: "hidden",
      enabledAt: [PoTypeEnum.TTLC],
    },

    {
      id: "supplier.id",
      label: "Supplier",
      type: "async-select",
      resourceColumnLabelKey: supplierResourceColumnKey,
      resourceUrl: `supplier?${genBaseSortQueryParam("namaPanjang")}`,
      resourceMapper: supplierOptionMapper,
      searchBy: supplierSearchKey,
      customOnChange: (data, setValue) => {
        setValue("supplier", data)
      },
      disabled: poType === PoTypeEnum.TTLC,
      enabledAt: [
        PoTypeEnum.INDENT,
        PoTypeEnum.STOCK,
        PoTypeEnum.SERVICE,
        PoTypeEnum.TTLC,
      ],
    },

    {
      id: "supplier.isPkp",
      label: "PKP",
      type: "check",
      disabled: true,
      enabledAt: [PoTypeEnum.INDENT],
      suffix: {
        id: "revisi",
        label: "Revisi",
        isOptional: true,
        disabled(data, detailData, formMode, globVars) {
          return formMode === "create"
        },
        isHalfSize: true,
      },
    },
    {
      id: "pelanggan.id",
      label: "Customer",
      type: "async-select",
      disabled: true,
      resourceUrl: `pelanggan?${genBaseSortQueryParam("namaPanjang")}`,
      resourceMapper: pelangganOptionMapper,
      searchBy: pelangganSearchKeys,
      resourceColumnLabelKey: "pelanggan.kodePelanggan,pelanggan.namaPanjang",
      enabledAt: [PoTypeEnum.INDENT, PoTypeEnum.TTLC, PoTypeEnum.SERVICE],
    },
    {
      id: "scRef",
      disabled: true,
      label: "Nomor Sales Contract / PO Customer",
      enabledAt: [PoTypeEnum.TTLC, PoTypeEnum.SERVICE, PoTypeEnum.INDENT],
      suffix: {
        id: "isPoCustomer",
        label: "PO Customer",
        type: "check",
        customOnChange: (data, setValue, getValue) => {
          const sc = getValue("salesContract")
          if (!data) {
            setValue("scRef", sc?.noSalesContract)
          } else {
            setValue("scRef", sc?.noPoPelanggan)
          }
        },
      },
    },
    {
      id: "salesContract.noSalesContract",
      enabledAt: [PoTypeEnum.INDENT],
      label: "Nomor Sales Contract",
      type: "ignored",
      isDataView: true,
    },
    {
      id: "salesContract.penawaran.diskon",
      type: "number",
      label: "Diskon",
      disabled: true,
      enabledAt: [PoTypeEnum.SERVICE],
    },
    {
      id: "salesContract.penawaran.fobCharge",
      type: "number",
      label: "FOB Charge",
      disabled: true,
      enabledAt: [PoTypeEnum.SERVICE],
    },
    {
      id: "pengadaan.id",
      label: "Permohonan Pengadaan Barang",
      type: "async-table-select",
      resourceUrl: (data, filterData) => {
        const q: any = {
          "purchaseOrders.id": "null",
        }

        if (!!filterData?.["pengadaan.divisi.id"]) {
          q["divisi.id"] = filterData?.["pengadaan.divisi.id"]
        }

        if (!!filterData?.["supplier.id"]) {
          q["supplier.id"] = filterData?.["supplier.id"]
        }

        const n = new Date()

        // TODO: open this
        // q[
        //   "permohonanPengadaanBarang.tanggal_$btwStart"
        // ] = `${n.getFullYear()}-01-01`
        // q[
        //   "permohonanPengadaanBarang.tanggal_$btwEnd"
        // ] = `${n.getFullYear()}-${String(n.getMonth() + 1).padStart(
        //   2,
        //   "0"
        // )}-${String(n.getDate()).padStart(2, "0")}`

        return `permohonan-pengadaan-barang?${genFieldQueryParamStr(q)}`
      },
      customOnChange: (data, setValue, getValues, genCode) => {
        const oldData = getValues()
        if (!oldData.modeOfShipment) {
          setValue("modeOfShipment", data?.modeOfShipment)
        }
        setValue("isPpn", data?.isPpn)
        setValue("tanggal", data?.tanggal)
        setValue("poType", data?.jenisPermintaan)
        setValue("mataUang", data?.mataUang)
        if (isValueEmpty(oldData.packing)) setValue("packing", data?.packing)
        if (isValueEmpty(oldData.insurance))
          setValue("insurance", data?.insurance)
        if (isValueEmpty(oldData.freight)) setValue("freight", data?.freight)
        setValue("supplier.id", data?.supplier?.id)
        setValue("organisasi.id", data?.organisasi?.id)
        setValue("divisi.id", data?.divisi?.id)
        setValue("pengadaan.tipePps", data?.tipePps)

        if (
          isValueEmpty(oldData.itemPurchaseOrderStocks) ||
          !oldData.itemPurchaseOrderStocks.length
        ) {
          let i = 1
          setValue(
            "itemPurchaseOrderStocks",
            data?.itemPengadaanStocks?.map((item: any) => {
              const val = {
                itemPengadaanStock: item,
                xId: i,
              }
              i++
              return val
            }) ?? []
          )
        }
        genCode()
      },
      resourceColumn: [
        {
          dataKey: "no",
          label: "Nomor Permohonan",
        },
        {
          dataKey: "tanggal",
          label: "Tanggal",
        },
        {
          dataKey: "jenisPermintaan",
          label: "Jenis Permintaan",
        },
        {
          dataKey: "supplier.namaPanjang",
          label: "Supplier",
        },
        {
          dataKey: "modeOfShipment",
          label: "Mode of Shipment",
        },
      ],
      column: 1,
      enabledAt: [PoTypeEnum.STOCK],
    },
    {
      id: "pengadaan.tipePps",
      type: "hidden",
      enabledAt: [PoTypeEnum.STOCK, PoTypeEnum.INDENT],
    },
    {
      id: "tanggal",
      label: "Tanggal PO",
      type: "ignored",
      isDataView: poType === PoTypeEnum.STOCK,
      enabledAt: [PoTypeEnum.INDENT, PoTypeEnum.STOCK],
    },
    {
      id: "organisasi.id",
      type: "hidden",
      enabledAt: [PoTypeEnum.INDENT, PoTypeEnum.STOCK],
    },
    {
      id: "divisi.id",
      type: "hidden",
      enabledAt: [PoTypeEnum.INDENT, PoTypeEnum.STOCK],
    },
    {
      id: "poType",
      label: "Jenis PO",
      type: "radio",
      disabled: poType === "STOCK",
      options: genOptionFromArray(["STOCK", "SAMPLE"]),
      enabledAt: [PoTypeEnum.STOCK],
      renderCondition(formData, formMode, filterData) {
        return !formData.supplier?.isImportLuarNegeri
      },
      suffix: {
        id: "supplier.isPkp",
        label: "PKP",
        type: "check",
        disabled: true,
        isHalfSize: true,
      },
    },
    {
      id: "poType",
      label: "Jenis PO",
      type: "radio",
      disabled: poType === "STOCK",
      options: genOptionFromArray(["STOCK", "SAMPLE"]),
      enabledAt: [PoTypeEnum.STOCK],
      renderCondition(formData, formMode, filterData) {
        return !!formData.supplier?.isImportLuarNegeri
      },
      suffix: {
        id: "isPpn",
        label: "PPN",
        type: "check",
        disabled: true,
        isHalfSize: true,
      },
    },
    {
      id: "pengadaan.no",
      label: "Nomor PPS",
      type: "ignored",
      isDataView: true,
      enabledAt: [PoTypeEnum.STOCK],
    },
    {
      id: "pengadaan.tanggal",
      label: "Tanggal PPS",
      isDataView: true,
      type: "ignored",
      enabledAt: [PoTypeEnum.STOCK],
    },
    {
      id: "noPo",
      label: "Nomor PO",
      isGeneratedCodeField: true,
      disabled: true,
      enabledAt: [
        PoTypeEnum.INDENT,
        PoTypeEnum.STOCK,
        PoTypeEnum.TTLC,
        PoTypeEnum.SERVICE,
        PoTypeEnum.INDENT,
      ],
      suffix:
        poType === PoTypeEnum.INDENT
          ? {
              id: "tanggal",
              label: "Tanggal",
              type: "date",
              value: getToday(),
              isHalfSize: true,
            }
          : {
              id: "revisi",
              label: "Revisi",
              isOptional: true,
              disabled(data, detailData, formMode, globVars) {
                return formMode === "create"
              },
            },
    },
    {
      id: "tanggal",
      label: "Tanggal",
      type: "date",
      value: getToday(),
      enabledAt: [PoTypeEnum.STOCK, PoTypeEnum.SERVICE, PoTypeEnum.TTLC],
      suffix:
        poType === PoTypeEnum.STOCK
          ? {
              id: "mataUang.mataUang",
              label: "Mata Uang",
              disabled: true,
            }
          : { id: "", type: "blank", isOptional: true, isHalfSize: true },
    },
    {
      id: "komisi",
      label: "Komisi (%)",
      type: "number",
      disabled: true,
      suffix: {
        id: "",
        type: "blank",
        isOptional: true,
        isHalfSize: true,
      },
      enabledAt: [PoTypeEnum.TTLC],
    },
    {
      id: "isPpn",
      label: "Transaksi PPN",
      type: "hidden",
      disabled: true,
      enabledAt: [PoTypeEnum.INDENT, PoTypeEnum.STOCK],
    },
    {
      id: "modeOfShipment",
      label: "Mode Of Shipment",
      isDataView: poType === PoTypeEnum.STOCK,
      enabledAt: [
        PoTypeEnum.INDENT,
        PoTypeEnum.STOCK,
        PoTypeEnum.TTLC,
        PoTypeEnum.SERVICE,
        PoTypeEnum.INDENT,
      ],
      suffix: {
        id: "conditionOfDelivery",
        label: "Condition Of Delivery",
        disabled: poType === PoTypeEnum.TTLC,
        isHalfSize: true,
      },
    },

    {
      id: "termOfPayment",
      label: "Term Of Payment",
      enabledAt: [
        PoTypeEnum.INDENT,
        PoTypeEnum.STOCK,
        PoTypeEnum.TTLC,
        PoTypeEnum.SERVICE,
        PoTypeEnum.INDENT,
      ],
      suffix: {
        id: "requestShipment",
        label: "Request Shipment",
        isHalfSize: true,
      },
    },
    {
      id: "tandaTanganOleh",
      label: "Sign By",
      suffix: {
        id: "blank",
        type: "blank",
        isOptional: true,
        isHalfSize: true,
      },
      enabledAt: [
        PoTypeEnum.INDENT,
        PoTypeEnum.STOCK,
        PoTypeEnum.TTLC,
        PoTypeEnum.SERVICE,
        PoTypeEnum.INDENT,
      ],
    },
    {
      id: "packing",
      label: "Packing",
      type: "number",
      value: 0,
      enabledAt: [
        PoTypeEnum.INDENT,
        PoTypeEnum.SERVICE,
        PoTypeEnum.INDENT,
        PoTypeEnum.STOCK,
      ],
      suffix: {
        id: "insurance",
        label: "Insurance (%)",
        value: 0,
        type: "number",
        isHalfSize: true,
      },
    },
    {
      id: "freight",
      label: "Freight",
      value: 0,
      type: "number",
      enabledAt: [
        PoTypeEnum.INDENT,
        PoTypeEnum.STOCK,
        PoTypeEnum.SERVICE,
        PoTypeEnum.INDENT,
      ],
      suffix: {
        id: "fobCharge",
        label: "FOB Charge",
        type: "number",
        value: 0,
        isHalfSize: true,
      },
    },
    {
      id: "bankCharge",
      label: "Bank Charge",
      type: "number",
      value: 0,
      enabledAt: [PoTypeEnum.INDENT],
      suffix: {
        id: "diskonSupplier",
        label: "Diskon Supplier (%)",
        type: "number",
        value: 0,
        isHalfSize: true,
      },
    },
    {
      id: "jenisPembayaran",
      label: "Jenis Pembayaran",
      type: "radio",
      options: genOptionFromArray(["TUNAI", "KREDIT"]),
      enabledAt: [PoTypeEnum.INDENT, PoTypeEnum.STOCK],
    },
    {
      id: "customers",
      isOptional: true,
      label: "Customer",
      type: "text-area",
      enabledAt: [PoTypeEnum.STOCK],
    },
    {
      id: "pelanggan.namaPanjang",
      type: "ignored",
      label: "Customer",
      isDataView: true,
      enabledAt: [PoTypeEnum.SERVICE, PoTypeEnum.INDENT],
    },
    {
      id: "salesContract.penawaran.no",
      type: "ignored",
      label: "No. Penawaran",
      isDataView: true,
      enabledAt: [PoTypeEnum.SERVICE],
    },
    {
      id: "salesContract.noSalesContract",
      type: "ignored",
      label: "No. Sales Contract",
      isDataView: true,
      enabledAt: [PoTypeEnum.SERVICE],
    },
    {
      id: "catatanRevisi",
      label: "Catatan Revisi",
      isOptional: true,
      enabledAt: [PoTypeEnum.INDENT, PoTypeEnum.STOCK, PoTypeEnum.TTLC],
    },
    {
      id: "catatan",
      label: "Note",
      type: "text-area",
      isOptional: true,
      enabledAt: [
        PoTypeEnum.INDENT,
        PoTypeEnum.STOCK,
        PoTypeEnum.TTLC,
        PoTypeEnum.SERVICE,
      ],
    },
    {
      id: "bankCharge",
      type: "number",
      label: "Bank Charge",
      value: 0,
      enabledAt: [PoTypeEnum.SERVICE],
    },
    {
      id: "space",
      type: "blank",
      enabledAt: [PoTypeEnum.TTLC],
    },
    {
      id: "commissionTitle",
      label: "Commission",
      type: "title",
      enabledAt: [PoTypeEnum.TTLC],
    },
    {
      id: "space",
      type: "blank",
      enabledAt: [PoTypeEnum.TTLC],
    },
    {
      id: "komisiReal",
      label: "Real",
      type: "number",
      isCallRecalculateCb: true,
      value: 0,
      suffix: {
        id: "komisiExtra",
        label: "Extra",
        type: "number",
        isCallRecalculateCb: true,
        isHalfSize: true,
        value: 0,
      },
      enabledAt: [PoTypeEnum.TTLC],
    },
    {
      id: "komisiOther",
      label: "Other",
      type: "number",
      isCallRecalculateCb: true,
      value: 0,
      suffix: {
        id: "",
        type: "blank",
        isOptional: true,
        isHalfSize: true,
      },
      enabledAt: [PoTypeEnum.TTLC],
    },
    {
      id: "deliveryAddress",
      label: "Delivery Address",
      type: "text-area",
      disabled: true,
      value: SEKAWAN_ADDRESS,
      enabledAt: [PoTypeEnum.INDENT, PoTypeEnum.STOCK],
    },
  ]

  return items
    .filter((i: SpesificModuleFormItem<PoTypeEnum>) =>
      i.enabledAt.includes(poType)
    )
    .map(({ enabledAt, ...data }) => data)
}

export default generatePurchaseOrderFormItems
