import { GeneralMap } from "common/type"
import { strToSafeNum } from "common/util"
import { PROFILE_ID_KEY } from "constant"
import { IResponse, useGet } from "hooks"

interface Organisasi {
  id: number
  isPusat: boolean
  kode: string
  nama: string
}

interface Permission {
  id: number
  permission: string
}

export interface Profile {
  id: number
  kode: string
  nama: string
  organisasi: Organisasi
  userPermissions: GeneralMap<string[]>
}

function profileMapper(rsp: any): IResponse<Profile> {
  const userPermissions: GeneralMap<string[]> = {}
  for (const userPermission of rsp?.data?.userPermissions ?? []) {
    const permissionComp = userPermission.permission.split(":::")
    if (!userPermissions[permissionComp[0]]) {
      userPermissions[permissionComp[0]] = [permissionComp[1]]
    } else {
      userPermissions[permissionComp[0]].push(permissionComp[1])
    }
  }
  return {
    data: {
      id: rsp?.data?.id,
      kode: rsp?.data?.kode,
      nama: rsp?.data?.nama,
      organisasi: {
        id: strToSafeNum(rsp?.data?.organisasi?.id),
        kode: rsp?.data?.organisasi?.kode ?? "",
        nama: rsp?.data?.organisasi?.nama ?? "",
        isPusat: !!rsp?.data?.organisasi?.isPusat,
      },
      userPermissions: userPermissions,
    },
  }
}

export const useGetProfile = (profileId: string | null) => {
  return useGet<IResponse<Profile>, any>(
    `/karyawan/${profileId}`,
    profileMapper,
    !profileId
  )
}
