import {
  genFieldQueryParamStr,
  getToday,
  getTodayPeriodOnly,
} from "common/util"
import { BasePageFormGroupItem } from "components/page/type"
import {
  mataUangOptionMapper,
  mataUangSearchField,
  pelangganOptionMapper,
  pelangganSearchKeys,
  supplierOptionMapper,
  supplierSearchKey,
} from "./mapper"
import { genBaseSortQueryParam } from "./url"

export const MataUangFormItemDefinition: BasePageFormGroupItem = {
  id: "mataUang",
  label: "Mata Uang",
  type: "async-select",
  resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
  resourceMapper: mataUangOptionMapper,
  searchBy: mataUangSearchField,
}

export const PeriodeFilterFormItemDefinition: BasePageFormGroupItem = {
  id: "tanggal",
  label: "Periode",
  type: "month",
  value: getTodayPeriodOnly(),
}

export const SupplierFilterFormItemDefinition: BasePageFormGroupItem = {
  id: "supplier.id",
  label: "Nama Supplier",
  type: "async-select",
  resourceUrl: `supplier?${genBaseSortQueryParam("kodeSupplier")}`,
  resourceMapper: supplierOptionMapper,
  customOnChange: (data, setValue) => {
    setValue("supplier.kodeSupplier", data.kodeSupplier)
    setValue("supplier.namaPanjang", data.namaPanjang)
    setValue("supplier.isImportLuarNegeri", data.isImportLuarNegeri)
  },
  searchBy: supplierSearchKey,
}

export const BarangFilterFormItemDefinition: BasePageFormGroupItem = {
  id: "barang.id",
  label: "Barang",
  type: "async-select",
  resourceUrl: `barang`,
}

export const SubKelompokBarangFilterFormItemDefinition: BasePageFormGroupItem =
  {
    id: "subKelompok.id",
    type: "async-select",
    resourceUrl: "subKelompokBarang",
    isOptional: true,
    label: "Sub Kelompok Barang",
  }

export const PelangganFilterFormItemDefinition: BasePageFormGroupItem = {
  id: "pelanggan.id",
  label: "Customer",
  type: "async-select",
  resourceUrl: `pelanggan?${genBaseSortQueryParam("namaPanjang")}`,
  resourceMapper: pelangganOptionMapper,
  searchBy: pelangganSearchKeys,
}

export const SalesFilterFormItemDefinition: BasePageFormGroupItem = {
  id: "sales.id",
  label: "Sales",
  type: "async-select",
  resourceUrl: `karyawan?${genFieldQueryParamStr({
    jenisJabatan: "SALES",
  })}`,
}

export const OrganisasiFilterFormItemDefinition: BasePageFormGroupItem = {
  id: "organisasi.id",
  label: "Cabang",
  type: "async-select",
  resourceUrl: `organisasi?${genBaseSortQueryParam()}`,
}

export const OrganisasiSalesFilterFormItemDefinition: BasePageFormGroupItem = {
  ...OrganisasiFilterFormItemDefinition,
  // resourceUrl: `organisasi?${genFieldQueryParamStr({
  //   isWorkshop: false,
  //   isPusat: false,
  // })}`,
}

export const DivisiFilterFormItemDefinition: BasePageFormGroupItem = {
  id: "divisi.id",
  label: "Divisi",
  type: "async-select",
  resourceUrl: `divisi?${genBaseSortQueryParam()}`,
}

export const PurchaseOrderFormItemDefinition: BasePageFormGroupItem = {
  id: "purchaseOrderId",
  label: "Nomor PO",
  isDataView: true,
  type: "async-table-select",
  resourceUrl: "purchase-order",
  column: 1,
  resourceColumn: [
    {
      dataKey: "noPo",
      label: "Nomor PO",
    },
    {
      dataKey: "tanggal",
      label: "Tanggal",
    },
  ],
  resourceColumnLabelKey: "noPo",
}

export const BiayaImportFields: BasePageFormGroupItem[] = [
  {
    id: "tanggal",
    label: "Tanggal",
    type: "date",
    isDataView: true,
    value: getToday(),
  },
  {
    id: "isComplete",
    label: "Biaya Import Complete",
    type: "check",
  },
  {
    id: "forwarding.id",
    label: "Forwarding",
    type: "async-select",
    resourceUrl: `angkutan`,
    resourceMapper(data) {
      return {
        label: `${data.kodeAngkutan} - ${data.namaPanjang}`,
        value: data.id,
      }
    },
    searchBy: "namaPendek,namaPanjang,kodeAngkutan",
    dataViewKey: "forwarding.namaPanjang",
    isDataView: true,
  },
  {
    id: "beaMasuk",
    label: "Bea Masuk",
    type: "number",
    isCallRecalculateCb: true,
    value: 0,
  },
  {
    id: "ppn",
    label: "PPN",
    type: "number",
    isCallRecalculateCb: true,
    value: 0,
  },
  {
    id: "pph",
    label: "PPh",
    type: "number",
    isCallRecalculateCb: true,
    value: 0,
  },
  {
    id: "tanggalBeaMasuk",
    label: "Tanggal Bea Masuk, PPN, PPh",
    type: "date",
    value: getToday(),
  },
  {
    id: "biayaBank",
    label: "Biaya Bank",
    type: "number",
    isCallRecalculateCb: true,
    value: 0,
  },
  {
    id: "bungaBank",
    label: "Bunga Bank",
    type: "number",
    isCallRecalculateCb: true,
    value: 0,
  },
  {
    id: "sewaGudang",
    label: "Sewa Gudang",
    type: "number",
    isCallRecalculateCb: true,
    value: 0,
  },
  {
    id: "ongkosAngkut",
    label: "Ongkos Angkut",
    type: "number",
    isCallRecalculateCb: true,
    value: 0,
  },
  {
    id: "do",
    label: "DO",
    type: "number",
    isCallRecalculateCb: true,
    value: 0,
  },
  {
    id: "lainLain",
    label: "Biaya Lain",
    type: "number",
    isCallRecalculateCb: true,
    value: 0,
  },
  {
    id: "handlingCost",
    type: "number",
    label: "Handling Cost",
    isCallRecalculateCb: true,
    value: 0,
  },
  {
    id: "freight",
    label: "Freight",
    type: "number",
    suffix: {
      id: "isFreightLangsung",
      label: "Freight Langsung",
      type: "check",
    },
    isCallRecalculateCb: true,
    value: 0,
  },
  {
    id: "forwardingCost",
    label: "Forwarding Cost",
    type: "number",
    isCallRecalculateCb: true,
    value: 0,
  },
  {
    id: "note",
    label: "Note",
    type: "text-area",
  },
  {
    id: "jumlah",
    label: "Jumlah Total",
    type: "number",
    dataViewLabel: "Jumlah",
    disabled: true,
    strong: true,
  },
]
