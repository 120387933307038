import { Button } from "@chakra-ui/react"
import { flattenObj, queryStringToObject } from "common/util"
import FormGroup from "components/form/form-group"
import { useBasePage } from "components/page/common"
import { useDependentFormFields } from "hooks/form/dependent-field"
import { useCallback, useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { FilterDict } from "../../constant/filters"
import { useAuth } from "context/auth.context"
import { FilterGroupItem } from "components/form/type"

interface BaseFilterProps {
  keyName: string
}

function getEndDateOfPeriod(val: string): Date {
  const periodComp = val.split("-")
  const endMonth = new Date(parseInt(periodComp[0]), parseInt(periodComp[1]), 0)

  return endMonth
}

function getFilterSendData(data: any): any {
  const sendData: any = {}
  for (const i in data) {
    let transFilterIdx = i
    let transFilterValue = data[i]

    if (transFilterIdx.endsWith(".ignored")) {
      continue
    }
    if (transFilterIdx.endsWith("organisasi.id") && transFilterValue === "0") {
      continue
    }
    if (transFilterValue === "") continue
    if (
      transFilterIdx.endsWith(".tanggal") ||
      [
        "tanggal",
        "tanggalInvoice",
        "tanggalVoucher",
        "pengajuanKb.tanggalPengajuan",
        "tanggalSpb",
        "createdAt",
      ].includes(transFilterIdx)
    ) {
      const endMonth = getEndDateOfPeriod(transFilterValue)
      sendData[
        `${transFilterIdx}_$btwEnd`
      ] = `${transFilterValue}-${endMonth.getDate()}`

      transFilterIdx = `${transFilterIdx}_$btwStart`
      transFilterValue = `${transFilterValue}-01`
    } else {
      // custom handling for several filter index
      if (transFilterIdx.includes("divisi.id") && transFilterValue === "4") {
        sendData[`$or_${transFilterIdx}||${transFilterIdx}`] = "null||notNull"

        continue
      }

      if (transFilterIdx === "periodeTahun") {
        sendData["periode_$btwStart"] = +`${transFilterValue}01`
        sendData["periode_$btwEnd"] = +`${transFilterValue}12`
        continue
      }

      if (transFilterIdx === "tanggalBiayaOperasional") {
        const monthYearComp = transFilterValue.split("-")
        sendData["bulan"] = parseInt(monthYearComp[1])
        sendData["tahun"] = parseInt(monthYearComp[0])
        continue
      }

      if (transFilterIdx.endsWith("tglTransaksiPeriodStart")) {
        const module = transFilterIdx.split(".")
        sendData[
          (module.length > 1 ? `${module[0]}.` : "") + "tglTransaksi_$btwStart"
        ] = `${transFilterValue}-01`
        continue
      }

      if (transFilterIdx.endsWith("tglTransaksiPeriodEnd")) {
        const module = transFilterIdx.split(".")
        const endMonth = getEndDateOfPeriod(transFilterValue)
        sendData[
          (module.length > 1 ? `${module[0]}.` : "") + "tglTransaksi_$btwEnd"
        ] = `${transFilterValue}-${endMonth.getDate()}`
        continue
      }

      if (transFilterIdx.endsWith("periodStart")) {
        const module = transFilterIdx.split(".")
        const appendix = module[module.length - 1].replace("periodStart", "")
        sendData[
          (module.length > 1 ? `${module[0]}.` : "") + `${appendix}_$btwStart`
        ] = `${transFilterValue}-01`
        continue
      }

      if (transFilterIdx.endsWith("periodEnd")) {
        const module = transFilterIdx.split(".")
        const endMonth = getEndDateOfPeriod(transFilterValue)
        const appendix = module[module.length - 1].replace("periodEnd", "")
        sendData[
          (module.length > 1 ? `${module[0]}.` : "") + `${appendix}_$btwEnd`
        ] = `${transFilterValue}-${endMonth.getDate()}`
        continue
      }

      if (transFilterIdx.endsWith("periodEndOnly")) {
        const module = transFilterIdx.split(".")
        const endMonth = getEndDateOfPeriod(transFilterValue)
        const end = `${transFilterValue}-${endMonth.getDate()}`
        sendData[
          (module.length > 1 ? `${module[0]}.` : "") + "tanggal_$btwEnd"
        ] = end

        sendData[
          (module.length > 1 ? `${module[0]}.` : "") + "tanggal_$btwStart"
        ] = `${endMonth.getFullYear()}-01-01`
        continue
      }

      if (transFilterIdx.endsWith("createdAtPeriode")) {
        const module = transFilterIdx.split(".")
        sendData[
          (module.length > 1 ? `${module[0]}.` : "") + "createdAt_$btwStart"
        ] = `${transFilterValue}-01`
        const endMonth = getEndDateOfPeriod(transFilterValue)
        sendData[
          (module.length > 1 ? `${module[0]}.` : "") + "createdAt_$btwEnd"
        ] = `${transFilterValue}-${endMonth.getDate()}`
        continue
      }

      if (transFilterIdx.endsWith("createdAtPeriodStart")) {
        const module = transFilterIdx.split(".")
        sendData[
          (module.length > 1 ? `${module[0]}.` : "") + "createdAt_$btwStart"
        ] = `${transFilterValue}-01`
        continue
      }

      if (transFilterIdx.endsWith("createdAtPeriodEnd")) {
        const module = transFilterIdx.split(".")
        const endMonth = getEndDateOfPeriod(transFilterValue)
        sendData[
          (module.length > 1 ? `${module[0]}.` : "") + "createdAt_$btwEnd"
        ] = `${transFilterValue}-${endMonth.getDate()}`
        continue
      }

      if (transFilterIdx.endsWith("periodYear")) {
        const module = transFilterIdx.split(".")
        module.pop()
        sendData[
          (module.length > 0 ? `${module.join(".")}.` : "") +
            "tanggal_$btwStart"
        ] = `${transFilterValue}-01-01`
        sendData[
          (module.length > 0 ? `${module.join(".")}.` : "") + "tanggal_$btwEnd"
        ] = `${transFilterValue}-12-31`
        continue
      }

      if (transFilterIdx.endsWith("_laporanOmset")) {
        if (transFilterValue == 1) {
          continue
        }
        sendData[transFilterIdx.replaceAll("_laporanOmset", "")] =
          transFilterValue
        continue
      }

      if (transFilterIdx.endsWith("periodEndOmset")) {
        sendData[transFilterIdx] = getEndDateOfPeriod(transFilterValue)
        continue
      }
    }
    sendData[transFilterIdx] = transFilterValue
  }

  return sendData
}

let t: NodeJS.Timeout
const BaseFilter: React.FC<BaseFilterProps> = ({ keyName }) => {
  const { onChangeQuery } = useBasePage()

  const auth = useAuth()

  const rhf = useForm()

  const formItems = useMemo<FilterGroupItem[]>(() => {
    const items = (FilterDict[keyName]?.items ?? []).map((i) => {
      const res = { ...i }
      if (res.id.endsWith("organisasi.id") || res.id.endsWith("asal.id")) {
        res.value = auth?.profile?.organisasi?.id?.toString()
        res.disabled = !auth?.profile?.organisasi.isPusat
      }

      return res
    })

    return items
  }, [auth, keyName])

  useEffect(() => {
    clearTimeout(t)
    t = setTimeout(() => {
      const __data: any = {}
      for (const _item of formItems) {
        if (typeof _item.value !== "undefined") {
          __data[_item.id] = _item.value

          if (!!_item.suffix) {
            __data[_item.suffix.id] = _item.suffix.value
          }
        }
      }
      const param: any = getFilterSendData(__data)
      onChangeQuery(param)
    }, 300)
  }, [formItems, onChangeQuery])

  const onProses = rhf.handleSubmit(
    useCallback(
      (data) => {
        const param = getFilterSendData(flattenObj(data))
        onChangeQuery(param)
      },
      [onChangeQuery]
    )
  )

  return (
    <FormProvider {...rhf}>
      <FormGroup items={formItems} formHook={rhf} />
      {/* @ts-ignore */}
      <Button mt="16px" onClick={onProses}>
        Proses
      </Button>
    </FormProvider>
  )
}

export default BaseFilter
