import { PenawaranTypeEnum, SpesificModuleFormItem } from "common/type"
import {
  genFieldQueryParamStr,
  genOptionLiteralFromArray,
  getToday,
} from "common/util"
import { BasePageFormGroupItem } from "components/page/type"
import {
  barangResourceColumnKeys,
  mataUangOptionMapper,
  mataUangSearchField,
  pelangganOptionMapper,
  salesResourceColumnKeys,
} from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"

function generateSalesContractFormItems(
  tipe: PenawaranTypeEnum
): BasePageFormGroupItem[] {
  const items: SpesificModuleFormItem<PenawaranTypeEnum>[] = [
    {
      id: "status",
      type: "hidden",
      value: "BARU",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
      ],
    },
    {
      id: "noSalesContract",
      type: "ignored",
      label: "Nomor Sales Contract",
      isDataView: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
      ],
    },
    {
      id: "penawaran.id",
      label: "Penawaran",
      type: "async-table-select",
      resourceUrl: (data, filterData) => {
        const q: any = {
          [tipe === PenawaranTypeEnum.INDENT ? "tipe_$In" : "tipe"]:
            tipe === PenawaranTypeEnum.INDENT
              ? "STOCK;INDENT;TRIAL;BARANG_KHUSUS;KONSINYASI"
              : tipe,
          "salesContracts.id": "null",
          "penawaran.tanggal_$btwEnd":
            filterData?.["salesContract.tanggal_$btwEnd"],
          "penawaran.tanggal_$btwStart":
            filterData?.["salesContract.tanggal_$btwStart"],
        }

        const filterOrgId = filterData["penawaran.organisasi.id"]
        const filterDivisiId = filterData["penawaran.divisi.id"]
        if (!!filterOrgId) q["organisasi.id"] = filterOrgId
        if (!!filterDivisiId) q["divisi.id"] = filterDivisiId

        return `penawaran?${genFieldQueryParamStr(
          q
        )}&sortBy=penawaran.id&sortType=desc`
      },
      column: 1,
      resourceColumn: [
        {
          dataKey: "no",
          label: "Nomor Penawaran",
        },
        {
          dataKey: "sales.nama",
          label: "Nama Sales",
        },
        {
          dataKey: "pelanggan.namaPanjang",
          label: "Nama Customer",
        },
        {
          dataKey: "tipe",
          label: "Jenis",
          ignoreToSearch: true,
        },
      ],
      customOnChange: (
        data,
        setValue,
        getValue,
        genCode,
        globVars,
        triggerPreCheck
      ) => {
        setValue("penawaran", data)
        setValue("isIndent", data.tipe === "INDENT")
        setValue("catatan", data.no)

        if (data.tipe === "BARANG_KHUSUS") {
          setValue(
            "penawaran.itemPenawarans",
            data.itemBarangJadis?.map((item: any) => ({
              ...item,
              barang: {
                kode: item.kodeBarang,
                nama: item.namaBarang,
              },
            })) ?? []
          )
        }

        genCode()
        triggerPreCheck()
      },
      dataViewKey: "penawaran.no",
      dataViewLabel: "Nomor Penawaran",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
      ],
    },
    {
      id: "penawaran.pelanggan.id",
      type: "async-select",
      label: "Customer",
      dataViewKey: "penawaran.pelanggan.namaPanjang",
      resourceUrl: `pelanggan?${genBaseSortQueryParam("namaPanjang")}`,
      resourceMapper: pelangganOptionMapper,
      resourceColumnLabelKey:
        "penawaran.pelanggan.kodePelanggan,penawaran.pelanggan.namaPanjang,penawaran.pelanggan.namaPendek",
      disabled: true,
      dataViewLabel: "Nama Customer",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
      ],
    },
    {
      id: "isIndent",
      label: "Indent/Lokal",
      type: "check",
      disabled: true,
      enabledAt: [PenawaranTypeEnum.INDENT, PenawaranTypeEnum.JOS],
    },
    {
      id: "penawaran.namaKontak",
      label: "Kontak/Attn",
      disabled: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
      ],
      suffix:
        tipe !== PenawaranTypeEnum.SERVICE
          ? {
              id: "penawaran.cc",
              label: "CC",
              disabled: true,
              isHalfSize: true,
            }
          : undefined,
    },

    {
      id: "penawaran.modeOfShipment",
      label: "Mode Of Shipment",
      disabled: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
      ],
      suffix: {
        id: "penawaran.timeOfDelivery",
        label: "Term Of Delivery/Shipment",
        disabled: true,
      },
    },
    {
      id: "penawaran.termOfPayment",
      label: "Term Of Payment",
      disabled: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
      ],
      suffix: {
        id: "penawaran.offerValidity",
        label: "Offer Validity",
        disabled: true,
        isHalfSize: true,
      },
    },
    {
      id: "penawaran.diskon",
      label: "Diskon Customer (%)",
      type: "number",
      disabled: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.SERVICE,
      ],
      suffix: [PenawaranTypeEnum.INDENT, PenawaranTypeEnum.TTLC].includes(tipe)
        ? {
            id: "penawaran.kb",
            label: "KB (%)",
            disabled: true,
            isHalfSize: true,
          }
        : undefined,
    },
    {
      id: "noSalesContract",
      disabled: true,
      label: "Nomor",
      isGeneratedCodeField: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
      ],
      suffix: {
        id: "tanggal",
        label: "Tanggal",
        type: "date",
        value: getToday(),
        isHalfSize: true,
      },
    },
    {
      id: "penawaran.mataUang.mataUang",
      label: "Mata Uang",
      type: "async-select",
      resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
      resourceMapper: mataUangOptionMapper,
      searchBy: mataUangSearchField,
      disabled: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.JOS,
      ],
      suffix: {
        id: "penawaran.sales.id",
        label: "Sales",
        type: "async-select",
        resourceUrl: `karyawan?${genFieldQueryParamStr({
          jenisJabatan: "SALES",
        })}&${genBaseSortQueryParam("nama")}`,
        resourceColumnLabelKey: salesResourceColumnKeys,
        resourceMapper: (data) => ({
          value: data.id,
          label: data.nama,
        }),
        disabled: true,
        isHalfSize: true,
      },
    },

    {
      id: "penawaran.sales.id",
      label: "Sales",
      type: "async-select",
      resourceUrl: `karyawan?${genFieldQueryParamStr({
        jenisJabatan: "SALES",
        resourceColumnLabelKey: salesResourceColumnKeys,
      })}&${genBaseSortQueryParam("nama")}`,
      resourceMapper: (data) => ({
        value: data.id,
        label: data.nama,
      }),
      disabled: true,
      enabledAt: [PenawaranTypeEnum.SERVICE],
    },
    {
      id: "penawaran.placeOfDelivery",
      label: "Place of Delivery",
      type: "radio",
      disabled: true,
      options: genOptionLiteralFromArray(["EXWORK", "FOB", "CNF", "CIF"]),
      enabledAt: [PenawaranTypeEnum.TTLC],
    },
    {
      id: "penawaran.paymentOption",
      label: "Payment",
      type: "radio",
      options: [
        { label: "TT", value: "TT" },
        { label: "LC", value: "LC" },
      ],
      disabled: true,
      enabledAt: [PenawaranTypeEnum.TTLC],
    },
    {
      id: "penawaran.cc",
      label: "CC",
      disabled: true,
      enabledAt: [PenawaranTypeEnum.TTLC, PenawaranTypeEnum.JOS],
      suffix: {
        id: "blank",
        type: "blank",
        isOptional: true,
        isHalfSize: true,
      },
    },
    {
      id: "penawaran.freight",
      label: "Freight",
      type: "number",
      disabled: true,
      renderCondition(formData, formMode, filterData) {
        return (
          (tipe === PenawaranTypeEnum.SERVICE &&
            !!formData.penawaran?.isKirimSupplier) ||
          tipe !== PenawaranTypeEnum.SERVICE
        )
      },
      enabledAt: [PenawaranTypeEnum.TTLC, PenawaranTypeEnum.SERVICE],
      suffix: {
        id: "penawaran.packing",
        label: "Packing",
        type: "number",
        disabled: true,
        renderCondition(formData, formMode, filterData) {
          return (
            (tipe === PenawaranTypeEnum.SERVICE &&
              !!formData.penawaran?.isKirimSupplier) ||
            tipe !== PenawaranTypeEnum.SERVICE
          )
        },
        isHalfSize: true,
      },
    },
    {
      id: "penawaran.insurance",
      label: "Insurance (%)",
      type: "number",
      disabled: true,
      renderCondition(formData, formMode, filterData) {
        return (
          (tipe === PenawaranTypeEnum.SERVICE &&
            !!formData.penawaran?.isKirimSupplier) ||
          tipe !== PenawaranTypeEnum.SERVICE
        )
      },
      enabledAt: [PenawaranTypeEnum.TTLC, PenawaranTypeEnum.SERVICE],
      suffix: {
        id: "penawaran.fobCharge",
        label: "FOB Charge (%)",
        type: "number",
        disabled: true,
        renderCondition(formData, formMode, filterData) {
          return (
            (tipe === PenawaranTypeEnum.SERVICE &&
              !!formData.penawaran?.isKirimSupplier) ||
            tipe !== PenawaranTypeEnum.SERVICE
          )
        },
        isHalfSize: true,
      },
    },
    {
      id: "penawaran.beaMasuk",
      label: "Bea Masuk (%)",
      type: "number",
      disabled: true,
      renderCondition(formData, formMode, filterData) {
        return (
          (tipe === PenawaranTypeEnum.SERVICE &&
            !!formData.penawaran?.isKirimSupplier) ||
          tipe !== PenawaranTypeEnum.SERVICE
        )
      },
      enabledAt: [PenawaranTypeEnum.JOS, PenawaranTypeEnum.SERVICE],
    },

    {
      id: "noPoPelanggan",
      label: "PO Customer",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
      ],
      suffix: {
        id: "penandaTangan",
        label: "Penanda Tangan",
        isHalfSize: true,
        isOptional: true,
      },
    },
    {
      id: "penawaran.tipe",
      isDataView: true,
      type: "ignored",
      label: "Jenis Penawaran",
      enabledAt: [PenawaranTypeEnum.INDENT],
    },
    {
      id: "catatan",
      label: "Note",
      type: "text-area",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
      ],
    },
  ]

  return items
    .filter((i: SpesificModuleFormItem<PenawaranTypeEnum>) =>
      i.enabledAt.includes(tipe)
    )
    .map(({ enabledAt, ...data }) => data)
}

export default generateSalesContractFormItems
