import { ChakraStylesConfig } from "chakra-react-select"

export const SELECT_CHAKRA_STYLES: ChakraStylesConfig = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "32px",
    height: "32px",
    _disabled: {
      bg: "#efefef4d",
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "32px",
    fontSize: "13px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "32px",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "13px",
  }),
}
