import { strToSafeNum } from "common/util"

export function getSQM(panjang?: number, lebar?: number): number {
  if (!panjang && !lebar) return 0

  if (!panjang && !!lebar) return lebar
  if (!lebar && !!panjang) return +panjang / 1000
  return (strToSafeNum(panjang) * strToSafeNum(lebar)) / 1000000
}

export function getSQMForCounting(panjang?: number, lebar?: number): number {
  const sqm = getSQM(panjang, lebar)
  if (sqm === 0) return 1
  return sqm
}
