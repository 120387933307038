import {
  basicKodeNamaMapper,
  basicSearchByKeys,
  genFieldQueryParamStr,
  genOptionFromArray,
  getToday,
  getTodayPeriodOnly,
} from "common/util"
import { FilterGroupItem } from "components/form/type"
import { LOCALE_MONTHS } from "./etc"
import {
  BarangFilterFormItemDefinition,
  DivisiFilterFormItemDefinition,
  OrganisasiFilterFormItemDefinition,
  OrganisasiSalesFilterFormItemDefinition,
  PelangganFilterFormItemDefinition,
  PeriodeFilterFormItemDefinition,
  SalesFilterFormItemDefinition,
  SubKelompokBarangFilterFormItemDefinition,
  SupplierFilterFormItemDefinition,
} from "./form-item"
import {
  mataUangOptionMapper,
  mataUangSearchField,
  pelangganOptionMapper,
  pelangganSearchKeys,
  perkiraanMapper,
  perkiraanResourceColumnKeys,
  perkiraanSearchKey,
  supplierOptionMapper,
  supplierSearchKey,
  voucherMapper,
  voucherSearchKey,
} from "./mapper"
import { genBaseSortQueryParam } from "./url"

interface FilterDictItem {
  items: FilterGroupItem[]
}

export const ignoredSupplierFilter = [
  "supplier.kodeSupplier",
  "supplier.namaPanjang",
  "supplier.isImportLuarNegeri",
]

const laporanPenjualanPeriodRange: any = {
  id: "suratJalanCustomer.periodStart",
  label: "Periode",
  type: "month",
  value: getTodayPeriodOnly(),
  suffix: {
    id: "suratJalanCustomer.periodEnd",
    type: "month",
    value: getTodayPeriodOnly(),
    isIgnored: true,
  },
}

const LAPORAN_STOCK_FILTERS: any[] = [
  { ...DivisiFilterFormItemDefinition, id: "barang.divisi.id" },
  {
    ...PeriodeFilterFormItemDefinition,
    id: "riwayatStockBarang.createdAtPeriode",
  },
  {
    id: "isSaldoAwal",
    type: "radio",
    label: "Tipe",
    options: [
      {
        label: "Saldo Awal",
        value: "true",
      },
      {
        label: "Saldo Awal + Mutasi",
        value: "<ignored>",
      },
    ],
  },
]

export const FilterDict: {
  [key: string]: FilterDictItem
} = {
  saldoAwalOrganisasi: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "organisasi.id",
      },
      {
        id: "tahun",
        type: "numeric",
        label: "Tahun",
        value: new Date().getFullYear(),
      },
    ],
  },
  hargaBarang: {
    items: [
      {
        id: "barang.divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
    ],
  },
  divisiOnly: {
    items: [
      {
        id: "divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
    ],
  },
  hppManual: {
    items: [
      {
        id: "barang.divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
    ],
  },
  divisiPeriode: {
    items: [
      {
        id: "divisiId",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `/divisi?${genBaseSortQueryParam()}`,
      },
      PeriodeFilterFormItemDefinition,
    ],
  },
  biayaOperasional: {
    items: [
      DivisiFilterFormItemDefinition,
      { ...PeriodeFilterFormItemDefinition, id: "tanggalBiayaOperasional" },
    ],
  },
  organisasiSupplier: {
    items: [
      {
        id: "cabang.id",
        label: "Nama Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genBaseSortQueryParam()}`,
        searchBy: basicSearchByKeys,
      },
      {
        id: "divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
        searchBy: basicSearchByKeys,
      },
      SupplierFilterFormItemDefinition,
    ],
  },
  kelompokBarang: {
    items: [
      {
        id: "kelompokUtama.id",
        label: "Kelompok Utama Barang",
        type: "async-select",
        resourceUrl: `kelompokUtamaBarang?${genBaseSortQueryParam()}`,
        resourceMapper: basicKodeNamaMapper,
      },
    ],
  },
  subKelompokBarang: {
    items: [
      {
        id: "kelompokUtama.id",
        label: "Kelompok Utama Barang",
        type: "async-select",
        resourceUrl: `kelompokUtamaBarang?${genBaseSortQueryParam()}`,
        resourceMapper: basicKodeNamaMapper,
      },
      // {
      //   id: "kelompok.id",
      //   label: "Kelompok Barang",
      //   type: "async-select",
      //   resourceUrl: `kelompokBarang?${genBaseSortQueryParam()}`,
      //   resourceMapper: basicKodeNamaMapper,
      //   dependOn: ["kelompokUtama.id"],
      // },
    ],
  },
  isSupplierImport: {
    items: [
      {
        id: "isImportLuarNegeri",
        label: "Supplier Import/Luar Negeri",
        type: "check",
        value: false,
      },
    ],
  },
  targetPenjualan: {
    items: [
      {
        id: "organisasi.id",
        label: "Nama Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genBaseSortQueryParam()}`,
      },
      {
        id: "divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      {
        id: "tahun",
        label: "Tahun",
        type: "numeric",
      },
      {
        id: "subKelompokBarang.kelompokUtama.id",
        label: "Kelompok Utama Barang",
        type: "async-select",
        resourceUrl: `kelompokUtamaBarang?${genBaseSortQueryParam()}`,
      },
      {
        id: "subKelompokBarang.id",
        label: "Sub Kelompok Barang",
        type: "async-select",
        resourceUrl: "subKelompokBarang",
        dependOn: ["subKelompokBarang.kelompokUtama.id"],
      },
    ],
  },
  kesalahanRetur: {
    items: [
      {
        id: "kesalahanReturId",
        label: "Kesalahan Retur",
        type: "async-select",
        resourceUrl: `klasifikasi-penyebab-retur?${genBaseSortQueryParam()}`,
      },
    ],
  },
  permohonanPengadaanBarang: {
    items: [
      OrganisasiFilterFormItemDefinition,
      {
        id: "divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      {
        ...PeriodeFilterFormItemDefinition,
        id: "permohonanPengadaanBarang.tanggal",
      },
      {
        id: "tipePps",
        label: "Jenis Barang",
        type: "radio",
        options: genOptionFromArray(["IMPORT", "LOKAL", "SISA_PROJEK"]),
      },
    ],
  },
  poTtlc: {
    items: [
      { ...PeriodeFilterFormItemDefinition, id: "purchaseOrder.tanggal" },
      {
        ...DivisiFilterFormItemDefinition,
        id: "salesContract.penawaran.divisi.id",
      },
    ],
  },
  supplierDivisi: {
    items: [
      SupplierFilterFormItemDefinition,
      {
        id: "pengadaan.divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      { ...PeriodeFilterFormItemDefinition, id: "purchaseOrder.tanggal" },
    ],
  },
  poIndent: {
    items: [
      SupplierFilterFormItemDefinition,
      {
        ...DivisiFilterFormItemDefinition,
        id: "salesContract.penawaran.divisi.id",
      },
      { ...PeriodeFilterFormItemDefinition, id: "purchaseOrder.tanggal" },
    ],
  },
  permohonanPembayaran: {
    items: [
      {
        id: "supplier.id",
        label: "Nama Supplier",
        type: "async-select",
        resourceUrl: `supplier?${genBaseSortQueryParam("namaPanjang")}`,
        resourceMapper: supplierOptionMapper,
        searchBy: supplierSearchKey,
        customOnChange: (data, setValue) => {
          setValue("supplier.kodeSupplier", data.kodeSupplier)
          setValue("supplier.namaPanjang", data.namaPanjang)
        },
      },
      {
        id: "divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      {
        ...PeriodeFilterFormItemDefinition,
        id: "permohonanPembayaran.tanggal",
      },
    ],
  },
  pembayaranHutang: {
    items: [
      {
        id: "supplier.id",
        label: "Nama Supplier",
        type: "async-select",
        resourceMapper: supplierOptionMapper,
        resourceUrl: `supplier?${genBaseSortQueryParam("namaPanjang")}`,
        searchBy: supplierSearchKey,
        customOnChange: (data, setValue) => {
          setValue("supplier.kodeSupplier", data.kodeSupplier)
          setValue("supplier.namaPanjang", data.namaPanjang)
        },
      },
      PeriodeFilterFormItemDefinition,
    ],
  },
  bapbFilter: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        disabled: true,
      },
      {
        id: "divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      SupplierFilterFormItemDefinition,
      { ...PeriodeFilterFormItemDefinition, id: "bapb.tanggal" },
    ],
  },
  customer: {
    items: [PelangganFilterFormItemDefinition, PeriodeFilterFormItemDefinition],
  },
  sjiPusatCabang: {
    items: [PeriodeFilterFormItemDefinition],
  },
  sji: {
    items: [
      { ...OrganisasiFilterFormItemDefinition, id: "asal.id" },
      {
        ...DivisiFilterFormItemDefinition,
        id: "permintaanPengirimanBarang.divisi.id",
      },
      PeriodeFilterFormItemDefinition,
    ],
  },
  periodeOnly: {
    items: [PeriodeFilterFormItemDefinition],
  },
  infoPo: {
    items: [
      { ...PeriodeFilterFormItemDefinition, id: "purchaseOrder.tanggal" },
    ],
  },
  penawaranStock: {
    items: [
      OrganisasiFilterFormItemDefinition,
      DivisiFilterFormItemDefinition,
      { ...PeriodeFilterFormItemDefinition, id: "penawaran.tanggal" },
      {
        id: "tipe",
        label: "Jenis Stok",
        type: "radio",
        options: genOptionFromArray(["STOCK", "TRIAL", "KONSINYASI"]),
        value: "STOCK",
      },
      {
        id: "isMmub",
        label: "MMUB",
        type: "check",
        value: false,
      },
    ],
  },
  penawaranIndent: {
    items: [
      {
        id: "organisasi.id",
        label: "Nama Cabang",
        type: "async-select",
        resourceUrl: `organisasi`,
      },
      { ...PeriodeFilterFormItemDefinition, id: "penawaran.tanggal" },
      {
        id: "divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      {
        id: "tipeIndent",
        label: "Jenis",
        type: "radio",
        options: [
          {
            label: "Lokal/Khusus",
            value: "LOKAL",
          },
          {
            label: "Indent",
            value: "INDENT",
          },
        ],
      },
    ],
  },
  penawaran: {
    items: [
      OrganisasiFilterFormItemDefinition,
      { ...PeriodeFilterFormItemDefinition, id: "penawaran.tanggal" },
      {
        id: "divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
    ],
  },
  pengembalianPinjaman: {
    items: [
      { ...OrganisasiFilterFormItemDefinition, id: "penawaran.organisasi.id" },
      {
        ...PeriodeFilterFormItemDefinition,
        id: "tanggal",
      },
      {
        ...DivisiFilterFormItemDefinition,
        id: "penawaran.divisi.id",
      },
    ],
  },
  penawaranService: {
    items: [
      OrganisasiFilterFormItemDefinition,
      { ...PeriodeFilterFormItemDefinition, id: "penawaran.tanggal" },
      {
        ...DivisiFilterFormItemDefinition,
        suffix: {
          id: "isKirimSupplier",
          type: "check",
          label: "Kirim ke Supplier",
          value: false,
        },
      },
    ],
  },
  salesContract: {
    items: [
      {
        id: "penawaran.organisasi.id",
        label: "Nama Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genBaseSortQueryParam()}`,
      },
      { ...PeriodeFilterFormItemDefinition, id: "salesContract.tanggal" },
      {
        id: "penawaran.divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
    ],
  },
  salesContractStock: {
    items: [
      {
        id: "penawaran.organisasi.id",
        label: "Nama Cabang",
        type: "async-select",
        resourceUrl: `organisasi`,
      },
      { ...PeriodeFilterFormItemDefinition, id: "salesContract.tanggal" },
      {
        id: "penawaran.divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      {
        id: "isMmub",
        label: "MMUB",
        type: "check",
      },
    ],
  },
  lampiranKontrak: {
    items: [
      {
        ...OrganisasiSalesFilterFormItemDefinition,
        id: "salesContract.penawaran.organisasi.id",
      },
      { ...PeriodeFilterFormItemDefinition, id: "salesContract.tanggal" },
      {
        id: "salesContract.penawaran.divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
    ],
  },
  spb: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "salesContract.penawaran.organisasi.id",
      },
      { ...PeriodeFilterFormItemDefinition, id: "tanggalSpb" },
      {
        ...DivisiFilterFormItemDefinition,
        id: "salesContract.penawaran.divisi.id",
      },
    ],
  },
  cetakSpb: {
    items: [
      {
        id: "workshop.id",
        label: "Nama Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({
          isWorkshop: "TRUE",
        })}`,
      },
      { ...PeriodeFilterFormItemDefinition, id: "tanggalSpb" },
      {
        id: "salesContract.penawaran.divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
    ],
  },
  workOrder: {
    items: [
      {
        id: "sfb.workshop.id",
        label: "Nama Workshop",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({
          isWorkshop: true,
        })}&${genBaseSortQueryParam()}`,
      },
      { ...PeriodeFilterFormItemDefinition, id: "workOrder.tanggal" },
      {
        ...DivisiFilterFormItemDefinition,
        id: "sfb.salesContract.penawaran.divisi.id",
      },
    ],
  },
  suratJalanCustomer: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "asal.id",
      },
      { ...PeriodeFilterFormItemDefinition, id: "suratJalanCustomer.tanggal" },
      {
        id: "salesContract.penawaran.divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      {
        id: "salesContract.penawaran.isPpn",
        label: "PPN",
        type: "check",
        value: false,
      },
      {
        id: "salesContract.penawaran.isMmub",
        label: "MMUB",
        type: "check",
        value: false,
      },
    ],
  },
  returBarang: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "suratJalanCustomer.asal.id",
      },
      { ...PeriodeFilterFormItemDefinition, id: "returCustomer.tanggal" },
      {
        ...DivisiFilterFormItemDefinition,
        id: "suratJalanCustomer.salesContract.penawaran.divisi.id",
      },
      {
        id: "suratJalanCustomer.salesContract.penawaran.isPpn",
        label: "PPN",
        type: "check",
        value: false,
      },
    ],
  },
  kontraBon: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "penawaran.organisasi.id",
      },
      { ...PeriodeFilterFormItemDefinition, id: "invoicePelanggan.tanggal" },
    ],
  },
  sttb: {
    items: [
      {
        id: "asal.id",
        label: "Cabang Workshop",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({
          isWorkshop: true,
        })}&${genBaseSortQueryParam()}`,
      },
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "tujuan.id",
        label: "Cabang Tujuan",
      },
      {
        id: "divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      PeriodeFilterFormItemDefinition,
    ],
  },
  pelunasanPiutang: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "invoicePelanggan.penawaran.organisasi.id",
      },
      { ...PeriodeFilterFormItemDefinition, id: "pelunasanPiutang.tanggal" },
    ],
  },
  konfirmasiInvoice: {
    items: [
      {
        id: "supplier.id",
        label: "Supplier",
        type: "async-select",
        resourceUrl: `supplier?${genBaseSortQueryParam("namaPanjang")}`,
        resourceMapper: supplierOptionMapper,
        searchBy: supplierSearchKey,
        customOnChange: (data, setValue) => {
          setValue("supplier.kodeSupplier", data.kodeSupplier)
          setValue("supplier.namaPanjang", data.namaPanjang)
        },
      },
      PeriodeFilterFormItemDefinition,
    ],
  },
  pembatalanSC: {
    items: [
      {
        id: "organisasi.id",
        label: "Nama Cabang",
        type: "async-select",
        resourceUrl: `organisasi`,
      },
      {
        id: "divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      {
        id: "salesContract.tanggalperiodStart",
        label: "Periode Awal",
        type: "month",
        value: getTodayPeriodOnly(),
      },
      {
        id: "salesContract.tanggalperiodEnd",
        label: "Periode Akhir",
        type: "month",
        value: getTodayPeriodOnly(),
      },
      {
        ...SalesFilterFormItemDefinition,
        isOptional: true,
        resourceUrl: (data) => {
          const q: any = { jenisJabatan: "SALES" }
          if (data.organisasi?.id) {
            q["organisasi.id"] = data.organisasi?.id
          }
          if (data.divisi?.id) {
            q["divisi.id"] = data.divisi?.id
          }
          return `karyawan?${genFieldQueryParamStr(q)}`
        },
        customOnChange: (data, setValue) => {
          setValue("allSales.ignored", false)
        },
        disabled: (data) => {
          return !!data.allSales?.ignored
        },
        suffix: {
          id: "allSales.ignored",
          type: "check",
          label: "Semua",
          value: true,
          isIgnored: true,
          customOnChange: (data, setValue) => {
            setValue("sales.id", null)
            setValue("sales", null)
          },
        },
      },
      {
        id: "pelanggan.id",
        label: "Customer",
        type: "async-select",
        resourceMapper: pelangganOptionMapper,
        resourceUrl: (data) => {
          const q: any = {}
          if (data.organisasi?.id) {
            q["organisasi.id"] = data.organisasi?.id
          }
          return `pelanggan?${genFieldQueryParamStr(q)}`
        },
        searchBy: pelangganSearchKeys,
        customOnChange: (data, setValue) => {
          setValue("allPelanggan.ignored", false)
        },
        disabled: (data) => {
          return !!data.allPelanggan?.ignored
        },
        suffix: {
          id: "allPelanggan.ignored",
          type: "check",
          label: "Semua",
          value: true,
          isIgnored: true,
          customOnChange: (data, setValue) => {
            setValue("pelanggan.id", null)
            setValue("pelanggan", null)
          },
        },
      },
    ],
  },
  service: {
    items: [
      {
        id: "organisasi.id",
        label: "Nama Workshop",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({
          isWorkshop: true,
        })}`,
      },
      PeriodeFilterFormItemDefinition,
      {
        id: "divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
    ],
  },
  instruksiService: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "salesContract.penawaran.organisasi.id",
      },
      { ...PeriodeFilterFormItemDefinition, id: "instruksiService.tanggal" },
      {
        ...DivisiFilterFormItemDefinition,
        id: "salesContract.penawaran.divisi.id",
      },
    ],
  },
  closingTrial: {
    items: [
      {
        id: "organisasi.id",
      },
    ],
  },
  rateValas: {
    items: [
      {
        id: "tanggalRateValas",
        type: "date",
        label: "Tanggal",
        value: getToday(),
      },
    ],
  },
  noBatasPajak: {
    items: [
      {
        id: "year",
        label: "Tahun",
        type: "numeric",
        value: new Date().getFullYear(),
      },
    ],
  },
  laporanSummaryPajak: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "asal.id",
      },
      {
        id: "suratJalanCustomer.periodStart",
        type: "month",
        label: "Periode",
        value: getTodayPeriodOnly(),
        suffix: {
          id: "suratJalanCustomer.periodEnd",
          type: "month",
          value: getTodayPeriodOnly(),
        },
      },
    ],
  },
  infoBarang: {
    items: [
      {
        id: "subKelompok.id",
        label: "Kelompok Barang",
        type: "async-select",
        resourceUrl: `subKelompokBarang?${genBaseSortQueryParam()}`,
      },
    ],
  },
  cabangOnly: {
    items: [OrganisasiFilterFormItemDefinition],
  },
  teknisi: {
    items: [
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({
          isWorkshop: true,
        })}`,
      },
    ],
  },
  glCabangPeriode: {
    items: [
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({})}`,
      },
      PeriodeFilterFormItemDefinition,
    ],
  },
  pengajuanKasKecil: {
    items: [
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({})}`,
      },
      { ...PeriodeFilterFormItemDefinition, id: "pengajuan.tanggal" },
    ],
  },
  penyelesaianKasbon: {
    items: [
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({})}`,
      },
      PeriodeFilterFormItemDefinition,
    ],
  },
  penambahanKasKecil: {
    items: [
      {
        id: "pengajuanPenambahanKasKecil.organisasi.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({})}`,
      },
      PeriodeFilterFormItemDefinition,
    ],
  },
  pengajuanKb: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "pengajuanKb.invoicePelanggan.penawaran.organisasi.id",
      },
      { ...PeriodeFilterFormItemDefinition, id: "pengajuanKb.tanggal" },
      {
        id: "pengajuanKb.invoicePelanggan.penawaran.pelanggan.id",
        label: "Customer",
        type: "async-select",
        resourceUrl: (data) =>
          `pelanggan?${genBaseSortQueryParam(
            "namaPanjang"
          )}&${genFieldQueryParamStr({
            "organisasi.id":
              data?.pengajuanKb?.invoicePelanggan?.penawaran?.organisasi?.id,
          })}`,
        resourceMapper: pelangganOptionMapper,
        searchBy: pelangganSearchKeys,
      },
    ],
  },
  pembayaranKb: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "invoicePelanggan.organisasi.id",
      },
      {
        ...PeriodeFilterFormItemDefinition,
        id: "pengajuanKb.tanggalPengajuan",
      },
      {
        id: "penawaran.mataUang.mataUang",
        label: "Mata Uang",
        type: "async-select",
        isOptional: true,
        resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
        resourceMapper: mataUangOptionMapper,
        searchBy: mataUangSearchField,
        disabled: (data) => !!data.isAllValas,
        suffix: {
          id: "isAllValas",
          type: "check",
          label: "Semua",
          isOptional: true,
          value: true,
          customOnChange: (data, setValue) => {
            if (!!data) {
              setValue("penawaran.mataUang.mataUang", null)
            }
          },
        },
      },
    ],
  },
  pembayaranReimburseSupplier: {
    items: [
      {
        ...PeriodeFilterFormItemDefinition,
        id: "tanggalVoucher",
      },
    ],
  },
  penerimaanKomisi: {
    items: [
      PeriodeFilterFormItemDefinition,
      {
        id: "supplier.id",
        label: "Supplier",
        type: "async-select",
        resourceUrl: `supplier?${genBaseSortQueryParam("namaPanjang")}`,
        resourceMapper: supplierOptionMapper,
        searchBy: supplierSearchKey,
        customOnChange: (data, setValue) => {
          setValue("supplier.kodeSupplier", data.kodeSupplier)
          setValue("supplier.namaPanjang", data.namaPanjang)
        },
      },
    ],
  },
  infoRate: {
    items: [
      {
        id: "tanggal_$btwStart",
        type: "date",
        label: "Tanggal",
        value: getToday(),
        suffix: {
          id: "tanggal_$btwEnd",
          type: "date",
          label: " ",
          value: getToday(),
        },
      },
    ],
  },
  pencarianPoTTLC: {
    items: [
      {
        id: "salesContract.noSalesContract",
        label: "No. Sales Kontrak",
      },
      {
        id: "salesContract.penawaran.organisasi.id",
        type: "async-select",
        label: "Cabang",
        resourceUrl: `organisasi?${genFieldQueryParamStr({
          isPusat: false,
          isWorkshop: false,
        })}`,
      },
      {
        id: "salesContract.penawaran.sales.id",
        type: "async-select",
        label: "Sales",
        resourceUrl: `karyawan?${genFieldQueryParamStr({
          jenisJabatan: "SALES",
        })}`,
      },
      {
        id: "salesContract.penawaran.modeOfShipment",
        label: "Mode Of Shipment",
      },
    ],
  },
  lapKbTidakDibayar: {
    items: [
      {
        id: "organisasi.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({})}`,
      },
      PeriodeFilterFormItemDefinition,
    ],
  },
  lapHutangSupplier: {
    items: [
      {
        id: "tanggal",
        label: "Tanggal",
        type: "date",
      },
    ],
  },
  jurnal: {
    items: [
      OrganisasiFilterFormItemDefinition,
      {
        id: "isPpn",
        label: "PPN",
        type: "check",
      },
      {
        id: "tanggal",
        label: "Tanggal",
        type: "date",
      },
      {
        id: "voucher",
        label: "Jenis Voucher",
        type: "async-select",
        resourceUrl: `voucher?${genBaseSortQueryParam("kodeVoucher")}`,
        resourceMapper: voucherMapper,
        searchBy: voucherSearchKey,
      },
    ],
  },
  instruksiJos: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        resourceUrl: `organisasi?${genBaseSortQueryParam()}&${genFieldQueryParamStr(
          { isWorkshop: true }
        )}`,
        id: "sfb.workshop.id",
      },
      {
        ...DivisiFilterFormItemDefinition,
        id: "sfb.salesContract.penawaran.divisi.id",
      },
      { ...PeriodeFilterFormItemDefinition, id: "instruksiJos.tanggal" },
    ],
  },
  invoiceJos: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "instruksiJos.sfb.salesContract.penawaran.organisasi.id",
      },
      {
        ...DivisiFilterFormItemDefinition,
        id: "instruksiJos.sfb.salesContract.penawaran.divisi.id",
      },
      { ...PeriodeFilterFormItemDefinition, id: "tanggalInvoice" },
    ],
  },
  returSupplier: {
    items: [
      OrganisasiFilterFormItemDefinition,
      {
        id: "divisi.id",
        label: "Nama Divisi",
        type: "async-select",
        resourceUrl: `divisi?${genBaseSortQueryParam()}`,
      },
      PeriodeFilterFormItemDefinition,
    ],
  },
  pindahTahun: {
    items: [
      OrganisasiFilterFormItemDefinition,
      {
        id: "forPindahTahun",
        type: "hidden",
        value: true,
      },
      {
        id: "tahun",
        label: "Tahun",
        type: "numeric",
        value: new Date().getFullYear(),
      },
    ],
  },
  emptyFilter: { items: [] },
  laporanStock: {
    items: [
      OrganisasiFilterFormItemDefinition,
      {
        id: "barang.subKelompok.kelompokUtama.divisi.id",
        type: "async-select",
        label: "Divisi",
        resourceUrl: `divisi`,
      },
      {
        ...PeriodeFilterFormItemDefinition,
        id: "createdAt",
      },
    ],
  },
  laporanStockPerCabang: {
    items: [
      OrganisasiFilterFormItemDefinition,
      ...LAPORAN_STOCK_FILTERS,
      {
        id: "barang.subKelompok.id",
        type: "async-select",
        resourceUrl: "subKelompokBarang",
        isOptional: true,
        label: "Sub Kelompok Barang",
      },
    ],
  },
  kartuStock: {
    items: [
      OrganisasiFilterFormItemDefinition,
      BarangFilterFormItemDefinition,
      {
        id: "riwayatStockBarang.createdAtPeriodStart",
        label: "Periode",
        type: "month",
        value: getTodayPeriodOnly(),
        suffix: {
          id: "riwayatStockBarang.createdAtPeriodEnd",
          type: "month",
          value: getTodayPeriodOnly(),
          isIgnored: true,
        },
      },
    ],
  },
  transferGlPeriod: {
    items: [
      {
        id: "tglTransaksiPeriodStart",
        label: "Periode",
        type: "month",
        value: getTodayPeriodOnly(),
        suffix: {
          id: "tglTransaksiPeriodEnd",
          type: "month",
          value: getTodayPeriodOnly(),
        },
      },
    ],
  },
  transferPenjualan: {
    items: [
      OrganisasiFilterFormItemDefinition,
      {
        id: "tglTransaksiPeriodStart",
        label: "Periode",
        type: "month",
        value: getTodayPeriodOnly(),
        suffix: {
          id: "tglTransaksiPeriodEnd",
          type: "month",
          value: getTodayPeriodOnly(),
        },
      },
    ],
  },
  sppb: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "organisasiAsal.id",
      },
      DivisiFilterFormItemDefinition,
      {
        ...PeriodeFilterFormItemDefinition,
        id: "permintaanPengirimanBarang.tanggal",
      },
    ],
  },
  jurnalGl: {
    items: [{ ...PeriodeFilterFormItemDefinition, id: "createdAt" }],
  },
  infoBukuBank: {
    items: [
      OrganisasiFilterFormItemDefinition,
      { ...PeriodeFilterFormItemDefinition, id: "createdAt" },
      {
        id: "perkiraan.id",
        label: "Nomor Perkiraan",
        resourceMapper: perkiraanMapper,
        searchBy: perkiraanSearchKey,
        type: "async-select",
        resourceUrl: "perkiraan",
      },
    ],
  },
  laporanSpb: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "salesContract.penawaran.organisasi.id",
      },
      { ...PeriodeFilterFormItemDefinition, id: "tanggalSpb" },
      {
        ...DivisiFilterFormItemDefinition,
        id: "salesContract.penawaran.divisi.id",
      },
      {
        id: "salesContract.penawaran.sales.id",
        label: "Sales",
        type: "async-select",
        resourceUrl: `karyawan?${genFieldQueryParamStr({
          jenisJabatan: "SALES",
        })}`,
        isOptional: true,
      },
    ],
  },
  jurnalSc: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "penawaran.organisasi.id",
      },
      {
        ...DivisiFilterFormItemDefinition,
        id: "penawaran.divisi.id",
      },
      { ...PeriodeFilterFormItemDefinition, id: "salesContract.tanggal" },
      {
        id: "penawaran.pelanggan.id",
        label: "Customer",
        type: "async-select",
        resourceUrl: `pelanggan`,
        isOptional: true,
        resourceMapper: pelangganOptionMapper,
        searchBy: pelangganSearchKeys,
      },
      {
        id: "penawaran.sales.id",
        label: "Sales",
        type: "async-select",
        resourceUrl: `karyawan?${genFieldQueryParamStr({
          jenisJabatan: "SALES",
        })}`,
        isOptional: true,
      },
      // TODO: add filter barang to item penawaran
    ],
  },
  laporanHpp: {
    items: [
      {
        ...DivisiFilterFormItemDefinition,
        id: "barang.divisi.id",
      },
      {
        id: "barang.id",
        label: "Barang",
        isOptional: true,
        disabled: (data) => !data.barang?.divisi?.id,
        type: "async-select",
        resourceUrl: (data) => {
          return `barang?${genFieldQueryParamStr({
            "divisi.id_$In": `${data.barang?.divisi?.id};3`,
          })}`
        },
        customOnChange: (data, setValue) => {
          setValue("barang.subKelompok.nama", data?.subKelompok?.nama)
          setValue("barang.satuanStock.nama", data?.satuanStock?.nama)
        },
      },
      {
        id: "barang.subKelompok.nama",
        label: "Sub Kelompok",
        disabled: true,
        isOptional: true,
      },
      {
        id: "barang.satuanStock.nama",
        label: "Satuan",
        disabled: true,
        isOptional: true,
      },
    ],
  },
  postingFilter: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        suffix: {
          id: "voucher.isUsingPpn",
          type: "check",
          label: "PPN",
          value: false,
        },
      },
      {
        id: "tglTransaksiPeriodStart",
        label: "Periode",
        type: "month",
        value: getTodayPeriodOnly(),
        suffix: {
          id: "tglTransaksiPeriodEnd",
          type: "month",
          isHalfSize: true,
          value: getTodayPeriodOnly(),
        },
      },
      {
        id: "noVoucher",
        isOptional: true,
        label: "No. Voucher",
      },
    ],
  },
  dashboard: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "organisasiId",
        isOptional: true,
      },
      {
        id: "year",
        type: "numeric",
        label: "Tahun",
        value: new Date().getFullYear(),
        suffix: {
          id: "month",
          type: "select",
          label: "Bulan",
          options: LOCALE_MONTHS.map((bln, key) => ({
            label: bln,
            value: key + 1,
          })),
          isHalfSize: true,
          value: new Date().getMonth() + 1,
        },
      },
    ],
  },
  progressPenawaran: {
    items: [
      OrganisasiFilterFormItemDefinition,
      { ...PeriodeFilterFormItemDefinition, id: "penawaran.tanggal" },
      {
        id: "tipe",
        label: "Tipe Penawaran",
        type: "radio",
        options: [
          {
            label: "TT-LC",
            value: "TTLC",
          },
          {
            label: "Stock",
            value: "STOCK",
          },
          {
            label: "Indent",
            value: "INDENT",
          },
        ],
      },
      // TODO: add filter status
    ],
  },
  laporanPenjualan: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "suratJalanCustomer.salesContract.penawaran.organisasi.id",
      },
      {
        ...DivisiFilterFormItemDefinition,
        id: "suratJalanCustomer.salesContract.penawaran.divisi.id",
      },
      laporanPenjualanPeriodRange,
    ],
  },
  laporanPenjualanPerBulan: {
    items: [
      { ...PeriodeFilterFormItemDefinition, id: "suratJalanCustomer.tanggal" },
      {
        ...DivisiFilterFormItemDefinition,
        id: "suratJalanCustomer.salesContract.penawaran.divisi.id",
      },
      {
        id: "itemPenawaran.barang.subKelompok.kelompokUtama.id",
        label: "Kelompok Barang",
        type: "async-select",
        resourceUrl: "KelompokUtamaBarang",
      },
    ],
  },
  laporanPenjualanPerCustProduk: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "suratJalanCustomer.salesContract.penawaran.organisasi.id",
      },
      {
        ...DivisiFilterFormItemDefinition,
        id: "suratJalanCustomer.salesContract.penawaran.divisi.id",
      },
      laporanPenjualanPeriodRange,
      {
        id: "itemPenawaran.barang.id",
        label: "Barang",
        type: "async-select",
        resourceUrl: "barang",
        isOptional: true,
      },
      {
        id: "suratJalanCustomer.salesContract.penawaran.pelanggan.id",
        label: "Customer",
        type: "async-select",
        resourceUrl: "pelanggan",
        isOptional: true,
        resourceMapper: pelangganOptionMapper,
        searchBy: pelangganSearchKeys,
      },
      {
        id: "suratJalanCustomer.salesContract.penawaran.sales.id",
        label: "Sales",
        type: "async-select",
        isOptional: true,
        resourceUrl: `karyawan?${genFieldQueryParamStr({
          jenisJabatan: "SALES",
        })}`,
      },
      {
        id: "jenisLaporan",
        type: "radio",
        label: "Jenis Laporan",
        value: "PER_CUST_REKAP",
        options: genOptionFromArray([
          "PER_CUST_REKAP",
          "PER_CUST_DETAIL",
          "PER_BARANG",
          "PER_SALES",
        ]),
      },
    ],
  },
  laporanStockBarang: {
    items: LAPORAN_STOCK_FILTERS,
  },
  laporanStockBarangJadi: {
    items: [
      {
        id: "workshop.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({
          isWorkshop: true,
        })}`,
      },
      {
        id: "workOrderSfbs",
        label: "Jenis Laporan",
        type: "radio",
        options: [
          {
            label: "Barang Jadi",
            value: "notNull",
          },
          { label: "Standing Order", value: "null" },
        ],
      },
    ],
  },
  laporanPps: {
    items: [
      {
        id: "pengadaan.periodYear",
        type: "year",
        value: new Date().getFullYear(),
        label: "Tahun",
      },
      {
        ...SubKelompokBarangFilterFormItemDefinition,
        id: "barang.subKelompok.id",
      },
    ],
  },
  laporanBarangPenawaran: {
    items: [
      { ...OrganisasiFilterFormItemDefinition, id: "penawaran.organisasi.id" },
      { ...OrganisasiFilterFormItemDefinition, id: "penawaran.divisi.id" },
      {
        ...PelangganFilterFormItemDefinition,
        id: "penawaran.pelanggan.id",
        isOptional: true,
      },
      {
        ...SalesFilterFormItemDefinition,
        id: "penawaran.sales.id",
        isOptional: true,
      },
      { ...SupplierFilterFormItemDefinition, isOptional: true },
      {
        ...SubKelompokBarangFilterFormItemDefinition,
        id: "barang.subKelompok.id",
        isOptional: true,
      },
      {
        ...BarangFilterFormItemDefinition,
        isOptional: true,
      },
      {
        id: "penawaran.periodStart",
        label: "Periode",
        type: "month",
        value: getTodayPeriodOnly(),
        isOptional: true,
        suffix: {
          id: "penawaran.periodEnd",
          type: "month",
          value: getTodayPeriodOnly(),
          isIgnored: true,
          isOptional: true,
        },
      },
    ],
  },
  laporanKomisiTTLC: {
    items: [
      {
        id: "status",
        type: "radio",
        options: [
          { label: "Belum Kirim DN", value: "BELUM_KIRIM_DN" },
          ...genOptionFromArray([
            "BELUM_KONFIRMASI",
            "SUDAH_KONFIRMASI",
            "SUDAH_BAYAR",
            "GABUNGAN",
          ]),
        ],
      },
      { ...SupplierFilterFormItemDefinition, isOptional: true },
      {
        id: "konfirmasiInvoice.periodStart",
        label: "Periode",
        type: "month",
        value: getTodayPeriodOnly(),
        isOptional: true,
        suffix: {
          id: "konfirmasiInvoice.periodEnd",
          type: "month",
          value: getTodayPeriodOnly(),
          isIgnored: true,
          isOptional: true,
        },
      },
    ],
  },
  laporanOmset: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        // id: "salesContract.penawaran.organisasi.id_laporanOmset",
        id: "organisasiId",
      },
      {
        type: "numeric",
        // id: "salesContract.periodYear",
        id: "year",
        label: "Tahun",
        value: new Date().getFullYear(),
      },
      {
        id: "isDibayar",
        label: "Nilai Omset",
        type: "radio",
        options: [
          {
            label: "Seluruhnya",
            value: "<ignored>",
          },
          {
            label: "Lunas",
            value: "true",
          },
          {
            label: "Belum Dibayar",
            value: "false",
          },
        ],
      },
    ],
  },
  laporanOmsetTTLC: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        id: "organisasiId",
      },
      {
        type: "month",
        id: "periodEndOmset",
        label: "Periode",
        value: getTodayPeriodOnly(),
      },
    ],
  },
  laporanOmsetSubKelompok: {
    items: [
      // {
      //   ...OrganisasiFilterFormItemDefinition,
      //   id: "salesContract.penawaran.organisasi.id",
      // },
      {
        ...PeriodeFilterFormItemDefinition,
        id: "salesContract.periodEndOnly",
      },
      {
        id: "pelunasanPiutangs.id",
        label: "Nilai Omset",
        type: "radio",
        options: [
          {
            label: "Seluruhnya",
            value: "<ignored>",
          },
          {
            label: "Lunas",
            value: "notNull",
          },
          {
            label: "Belum Dibayar",
            value: "null",
          },
        ],
      },
    ],
  },
  konsinyasi: {
    items: [
      OrganisasiFilterFormItemDefinition,
      DivisiFilterFormItemDefinition,
      { ...PeriodeFilterFormItemDefinition, id: "konsinyasi.tanggal" },
    ],
  },
  informasiPo: {
    items: [
      { ...PeriodeFilterFormItemDefinition, id: "purchaseOrder.tanggal" },
    ],
  },
  laporanLabaRugi: {
    items: [
      {
        ...PeriodeFilterFormItemDefinition,
        id: "tanggalStart",
        suffix: {
          ...PeriodeFilterFormItemDefinition,
          id: "tanggalEnd",
          isHalfSize: true,
          label: "",
        },
      },
      {
        id: "isPpn",
        type: "check",
        value: false,
        isOptional: true,
        label: "PPN",
      },
    ],
  },
  tutupBuku: {
    items: [
      {
        id: "periodeTahun",
        type: "numeric",
        label: "Tahun",
        value: new Date().getFullYear(),
      },
    ],
  },
  bukuBesar: {
    items: [
      {
        ...OrganisasiFilterFormItemDefinition,
        isOptional: true,
        baseResources: [
          {
            nama: "Semua Cabang",
            id: "0",
          },
        ],
      },
      {
        ...PeriodeFilterFormItemDefinition,
        id: "jurnal.tglTransaksiperiodStart",
        suffix: {
          ...PeriodeFilterFormItemDefinition,
          id: "jurnal.tglTransaksiperiodEnd",
          isHalfSize: true,
          label: undefined,
        },
      },
      {
        id: "perkiraan.divisi.id",
        label: "Divisi",
        type: "radio",
        options: [
          {
            label: "BELTING",
            value: "1",
          },
          {
            label: "TEXTILE",
            value: "2",
          },
          {
            label: "UMUM",
            value: "3",
          },
          {
            label: "SEMUA",
            value: "4",
          },
        ],
        suffix: {
          id: "voucher.isUsingPpn",
          label: "PPN",
          type: "check",
          value: false,
        },
      },
      {
        id: "blank",
        type: "blank",
        isOptional: true,
      },
      {
        id: "perkiraan.nomorPerkiraan_$gte",
        resourceMapper: (data) => ({
          value: `'${(data.nomorPerkiraan as string).trimEnd()}'`,
          label: `${data.nomorPerkiraan} - ${data.keterangan}`,
        }),
        label: "No. Perkiraan Awal",
        searchBy: perkiraanSearchKey,
        resourceColumnLabelKey: perkiraanResourceColumnKeys,
        type: "async-select",
        resourceUrl: (data) => {
          const q: any = {
            level: "DETAIL",
          }
          if (!!data.organisasi?.id && data.organisasi.id !== "0") {
            q[
              "$or_organisasi.id||organisasi.id"
            ] = `${data.organisasi?.id}||null`
          }
          return `perkiraan?${genFieldQueryParamStr(q)}`
        },
      },
      {
        id: "perkiraan.nomorPerkiraan_$lte",
        resourceMapper: (data) => ({
          value: `'${(data.nomorPerkiraan as string).trimEnd()}'`,
          label: `${data.nomorPerkiraan} - ${data.keterangan}`,
        }),
        resourceColumnLabelKey: perkiraanResourceColumnKeys,
        label: "No. Perkiraan Akhir",
        searchBy: perkiraanSearchKey,
        type: "async-select",
        resourceUrl: (data) => {
          const q: any = {
            level: "DETAIL",
          }
          if (!!data.organisasi?.id && data.organisasi.id !== "0") {
            q[
              "$or_organisasi.id||organisasi.id"
            ] = `${data.organisasi?.id}||null`
          }
          return `perkiraan?${genFieldQueryParamStr(q)}`
        },
      },
    ],
  },
}
