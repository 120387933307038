export function calcNilaiScOfPo(po: any): number {
  return po?.itemPurchaseOrderStocks?.reduce((total: number, itemPo: any) => {
    return total + +itemPo.itemPenawaran?.hargaJual * +itemPo.qty
  }, 0)
}

export function calcNilaiPoTTLC(po: any): number {
  return po?.itemPurchaseOrderStocks?.reduce((total: number, itemPo: any) => {
    return total + +itemPo.itemPenawaran?.hargaBeli * +itemPo.itemPenawaran?.qty
  }, 0)
}

export function calcBiayaPO(po: any): number {
  return +po?.fobCharge + +po?.packing + +po.insurance
}
