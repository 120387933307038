import { PenawaranTypeEnum } from "common/type"
import { formatNumber, strToSafeNum } from "common/util"
import { postAPI } from "hooks"
import { UseFormReturn } from "react-hook-form"

export function calculatePenawaranMargin(rhf: UseFormReturn<any>) {
  const data = rhf.getValues()

  postAPI<number>("/penawaran/margin", data).then((margin) => {
    rhf.setValue("margin", margin.toFixed(2))
  })
}

export function getAdditionalCostPenawaran(
  penawaran: any,
  baseTotal: number
): number {
  let total = baseTotal
  const rate = !penawaran?.rate ? 1 : +penawaran?.rate

  total += strToSafeNum(penawaran?.freight, 0) * rate
  total += strToSafeNum(penawaran?.packing, 0) * rate
  total += strToSafeNum(penawaran?.fobCharge, 0) * rate

  if (penawaran.insurance) {
    const insurance = (strToSafeNum(penawaran?.insurance, 0) / 100) * total
    total += insurance
  }

  return total - baseTotal
}

export function generateKodeBarangKhusus(
  organizationCode: string,
  barangCodes: string[]
) {
  if (!organizationCode || !barangCodes?.length) return ""

  const barangSpace = 9

  const now = new Date()
  const datePart = `${String(now.getFullYear()).substring(2, 4)}${String(
    now.getMonth() + 1
  ).padStart(2, "0")}${String(now.getDate()).padStart(2, "0")}${String(
    now.getHours()
  ).padStart(2, "0")}${String(now.getMinutes()).padStart(2, "0")}${String(
    now.getSeconds()
  ).padStart(2, "0")}`
  const barangCode = barangCodes
    .join("")
    .slice(barangSpace * -1)
    .padStart(barangSpace, "0")
  const orgCode = organizationCode.slice(-1)
  return `K${orgCode}${barangCode}${datePart}`
}

export const penawaranDiskonRule =
  (tipe: PenawaranTypeEnum) => (data: any, detail: any) => {
    let minMargin = -999999
    let totalBeli = 0,
      totalJual = 0
    if (data !== 0) {
      let itemPenawarans = data.itemPenawarans
      if (tipe === PenawaranTypeEnum.KHUSUS) {
        itemPenawarans = []
        for (const item of data.itemBarangJadis ?? []) {
          itemPenawarans = itemPenawarans.concat(item.itemPenawarans)
        }
      }
      minMargin =
        itemPenawarans?.reduce((total: number, item: any) => {
          totalBeli += +item.hargaBeli
          totalJual += +item.hargaJual
          return total + strToSafeNum(item.barang?.subKelompok?.minMargin)
        }, 0) / strToSafeNum(itemPenawarans?.length)
    }

    const tempMargin =
      (((1 -
        strToSafeNum(data.kb, 0) / 100 -
        strToSafeNum(data.komisi, 0) / 100) *
        totalJual -
        totalBeli) /
        totalBeli) *
      100

    let maxDiskon = tempMargin - +minMargin
    if (maxDiskon < 0) {
      maxDiskon = 0
    }
    return {
      max: {
        value: maxDiskon,
        message: `Diskon tidak boleh lebih dari ${formatNumber(maxDiskon)}`,
      },
    }
  }
