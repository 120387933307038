import {
  BAPBTypeEnum,
  GeneralMap,
  PoTypeEnum,
  SuratJalanInternalTypeEnum,
} from "common/type"
import {
  generateCrudUrl,
  genFieldQueryParamObj,
  genFieldQueryParamStr,
  genFormItems,
  getToday,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import { mataUangOptionMapper, mataUangSearchField } from "../mapper"
import generatePurchaseOrderForm from "common/form-generator/purchase-order"
import generateBapbForm from "common/form-generator/bapb"
import generateSuratJalanInternalForm from "common/form-generator/surat-jalan-internal"
import generatePermohonanPembayaranHutangForm from "common/form-generator/permohonan-pembayaran-hutang"
import generatePembayaranHutangForm from "common/form-generator/pembayaran-hutang"
import generatePersetujuanKasbonBiayaImportForm from "common/form-generator/persetujuan-kasbon-biaya-import"
import { genBaseSortQueryParam } from "constant/url"
import generatePermohonanPengadaanForm from "common/form-generator/permohonan-pengadaan-barang"
import generatePermohonanPembayaranTunaiForms from "common/form-generator/permohonan-pembayaran-tunai"

import generateBiayaImportForms from "common/form-generator/biaya-import"

export const PengadaanPages: GeneralMap<BasePageProps<any>> = {
  "permohonan-pengadaan-barang": generatePermohonanPengadaanForm(),
  "purchase-order": generatePurchaseOrderForm(PoTypeEnum.STOCK),
  "purchase-order-indent": generatePurchaseOrderForm(PoTypeEnum.INDENT),
  "permohonan-pembayaran-tunai": generatePermohonanPembayaranTunaiForms(),
  "permohonan-pembayaran-hutang": generatePermohonanPembayaranHutangForm(false),
  pembayaran: generatePembayaranHutangForm(),
  bapb: generateBapbForm(BAPBTypeEnum.STOCK),
  // "informasi-po-stock": generateInformasiPOForm(InformasiPOType.STOCK),
  "surat-jalan-internal": generateSuratJalanInternalForm(
    SuratJalanInternalTypeEnum.STOCK
  ),
  "kasbon-biaya-import": {
    permissionRequired:
      "SIT_sekawan::transaksi::lain_lain::pembayaran::kasbon_biaya_import",
    title: "Pengajuan Kasbon Biaya Import",
    urls: {
      ...generateCrudUrl("kasbon-biaya-import"),
      read: {
        method: "get",
        endpoint: `/kasbon-biaya-import`,
        param: {
          sortBy: "id",
          sortType: "desc",
        },
      },
    },
    filter: <BaseFilter keyName="periodeOnly" />,
    generateCodeParam: {
      entityName: "KasbonBiayaImport",
      entity: {},
    },
    formGroupItems: genFormItems([
      {
        id: "nomor",
        label: "Nomor",
        isGeneratedCodeField: true,
        isDataView: true,
        disabled: true,
      },
      {
        id: "tanggal",
        label: "Tanggal",
        type: "date",
        isDataView: true,
        value: getToday(),
      },
      {
        id: "jumlah",
        label: "Jumlah",
        type: "number",
        dataViewLabel: "Jumlah Kasbon",
      },
      {
        id: "mataUang.mataUang",
        label: "Mata Uang",
        type: "async-select",
        resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
        value: "RP",
        resourceMapper: mataUangOptionMapper,
        searchBy: mataUangSearchField,
      },
      {
        id: "karyawan.id",
        label: "Pemohon",
        type: "async-select",
        resourceUrl: `karyawan?${genBaseSortQueryParam(
          "kode"
        )}&${genFieldQueryParamStr({ "organisasi.kode": "B0" })}`,
      },
      {
        id: "keterangan",
        label: "Keterangan",
        isDataView: true,
        type: "text-area",
      },
    ]),
  },
  "persetujuan-kasbon-biaya-import": generatePersetujuanKasbonBiayaImportForm(),
  "biaya-import": generateBiayaImportForms(false),
  "biaya-import-tambahan": generateBiayaImportForms(true),
}

export const PengadaanPageKeys = Object.keys(PengadaanPages)
