import generateKasbonKaryawanFormItems from "common/form-item-generator/kasbon-karyawan"
import { GeneralMap, KasbonKaryawanTypeEnum } from "common/type"
import {
  generateCrudUrl,
  genFieldQueryParamObj,
  genFormItems,
  genOptionFromArray,
  strToSafeNum,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import { perkiraanMapper } from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"

const KasbonTitles: GeneralMap<string> = {
  [KasbonKaryawanTypeEnum.KASBON]: "Pemasukan Data Kasbon Karyawan",
  [KasbonKaryawanTypeEnum.REIMBURSE]: "Jurnal Umum",
}

function generateKasbonKaryawanForms(
  tipe: KasbonKaryawanTypeEnum
): BasePageProps<any> {
  return {
    title: KasbonTitles[tipe],
    permissionRequired: "KasbonKaryawan",
    urls: {
      ...generateCrudUrl("kasbon-karyawan"),
      read: {
        endpoint: `/kasbon-karyawan`,
        param: genFieldQueryParamObj({ tipe }),
        method: "get",
      },
    },
    filter: <BaseFilter keyName="glCabangPeriode" />,
    recalculateCallback:
      tipe === KasbonKaryawanTypeEnum.REIMBURSE
        ? (rhf) => {
            const data = rhf.watch()
            const total =
              data.itemKasbonKaryawans?.reduce(
                (total: number, item: any) =>
                  total +
                  strToSafeNum(item.jumlah) * (item.jenis === "DEBIT" ? 1 : -1),
                0
              ) ?? 0
            rhf.setValue("jumlah", total)
          }
        : undefined,
    generateCodeParam: (data, filter) => ({
      entityName: "KasbonKaryawan",
      entity: { tipe, organisasi: { id: filter["organisasi.id"] } },
    }),
    formGroupItems: genFormItems(generateKasbonKaryawanFormItems(tipe)),
    additionalForms:
      tipe === KasbonKaryawanTypeEnum.REIMBURSE
        ? [
            {
              id: "itemKasbonKaryawans",
              title: "Daftar Item Biaya Operasional",
              formItems: [
                {
                  id: "perkiraan.id",
                  label: "Nomor Perkiraan",
                  type: "async-select",
                  resourceUrl: `perkiraan?${genBaseSortQueryParam(
                    "nomorPerkiraan"
                  )}`,
                  resourceMapper: perkiraanMapper,
                  dataViewKey: "perkiraan.nomorPerkiraan",
                  isDataView: true,
                  customOnChange: (data, setValue) => {
                    setValue("perkiraan", data)
                    setValue("divisi.id", data?.divisi?.id)
                  },
                  searchBy: "nomorPerkiraan,keterangan",
                },
                {
                  id: "perkiraan.divisi.nama",
                  label: "Divisi",
                  disabled: true,
                },
                {
                  id: "keterangan",
                  label: "Keterangan",
                  type: "text-area",
                  dataViewLabel: "Deskripsi",
                },
                {
                  id: "jenis",
                  type: "radio",
                  label: "Jenis",
                  options: genOptionFromArray(["DEBIT", "KREDIT"]),
                  isDataView: true,
                },
                {
                  id: "jumlah",
                  label: "Jumlah",
                  type: "number",
                  isDataView: true,
                },
              ],
            },
          ]
        : [],
  }
}

export default generateKasbonKaryawanForms
