import { KasbonKaryawanTypeEnum, SpesificModuleFormItem } from "common/type"
import {
  genFieldQueryParamStr,
  genOptionFromArray,
  getToday,
} from "common/util"
import { BasePageFormGroupItem } from "components/page/type"
import {
  karyawanResourceColumnKey,
  voucherMapper,
  voucherSearchKey,
} from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"

function generateKasbonKaryawanFormItems(
  tipe: KasbonKaryawanTypeEnum
): BasePageFormGroupItem[] {
  const items: SpesificModuleFormItem<KasbonKaryawanTypeEnum>[] = [
    {
      id: "tipe",
      type: "hidden",
      value: tipe,
      enabledAt: [
        KasbonKaryawanTypeEnum.KASBON,
        KasbonKaryawanTypeEnum.REIMBURSE,
      ],
    },
    {
      id: "organisasi.id",
      type: "hidden",
      enabledAt: [KasbonKaryawanTypeEnum.KASBON],
    },
    {
      id: "nomor",
      disabled: true,
      label: "Nomor",
      isDataView: true,
      isGeneratedCodeField: true,
      enabledAt: [
        KasbonKaryawanTypeEnum.KASBON,
        KasbonKaryawanTypeEnum.REIMBURSE,
      ],
      suffix: {
        id: "tanggal",
        label: "Tanggal",
        value: getToday(),
        isDataView: true,
        isHalfSize: true,
      },
    },
    {
      id: "blank",
      type: "blank",
      enabledAt: [
        KasbonKaryawanTypeEnum.KASBON,
        KasbonKaryawanTypeEnum.REIMBURSE,
      ],
    },
    {
      id: "karyawan.id",
      label: "Karyawan",
      type: "async-select",
      resourceUrl: `karyawan?${genBaseSortQueryParam("kode")}`,
      customOnChange: (data, setValue) => {
        setValue("karyawan", data)
        const divisiId = data.divisi.id
        if (divisiId === "1") {
          setValue("subDivisiKeuangan.id", "11")
        } else if (divisiId === "2") {
          setValue("subDivisiKeuangan.id", "12")
        } else if (divisiId === "3") {
          setValue("subDivisiKeuangan.id", "5")
        }
      },
      dataViewLabel: "Nama Karyawan",
      resourceColumnLabelKey: karyawanResourceColumnKey,
      dataViewKey: "karyawan.nama",
      enabledAt: [
        KasbonKaryawanTypeEnum.KASBON,
        KasbonKaryawanTypeEnum.REIMBURSE,
      ],
    },
    {
      id: "karyawan.divisi.nama",
      label: "Divisi",
      disabled: true,
      enabledAt: [
        KasbonKaryawanTypeEnum.KASBON,
        KasbonKaryawanTypeEnum.REIMBURSE,
      ],
      suffix: {
        id: "subDivisiKeuangan.id",
        label: "Sub Divisi",
        type: "async-select",
        resourceUrl: (data) => {
          if (!data.karyawan?.id) {
            return `sub-divisi-keuangan`
          }
          return `sub-divisi-keuangan?${genFieldQueryParamStr({
            "divisiKeuangan.id": data.karyawan.divisi.id,
          })}`
        },
        disabled: true,
        isHalfSize: true,
        customOnChange: (data, setValue) => {
          setValue("subDivisiKeuangan", data)
        },
      },
    },
    {
      id: "voucher.id",
      label: "Voucher",
      type: "async-select",
      resourceMapper: voucherMapper,
      searchBy: voucherSearchKey,
      resourceUrl: (data, filter) => {
        return `voucher?${genBaseSortQueryParam(
          "kodeVoucher"
        )}&${genFieldQueryParamStr({
          "$or_organisasi.id||organisasi.id": `${filter?.["organisasi.id"]}||null`,
          jenis: "KREDIT",
          isJurnalPenyesuaian: false,
        })}`
      },
      customOnChange: (data, setValue) => {
        setValue("voucher", data)
      },
      enabledAt: [
        KasbonKaryawanTypeEnum.KASBON,
        KasbonKaryawanTypeEnum.REIMBURSE,
      ],
    },
    {
      id: "voucher.isUsingPpn",
      disabled: true,
      label: "PPN",
      type: "check",
      enabledAt: [
        KasbonKaryawanTypeEnum.KASBON,
        KasbonKaryawanTypeEnum.REIMBURSE,
      ],
    },
    {
      id: "voucher.jenis",
      type: "radio",
      disabled: true,
      label: "Jenis",
      options: genOptionFromArray(["DEBIT", "KREDIT"]),
      enabledAt: [KasbonKaryawanTypeEnum.REIMBURSE],
    },

    {
      id: "keterangan",
      type: "text-area",
      isDataView: true,
      label: "Keterangan",
      enabledAt: [
        KasbonKaryawanTypeEnum.KASBON,
        KasbonKaryawanTypeEnum.REIMBURSE,
      ],
    },
    {
      id: "jumlah",
      label: "Jumlah",
      type: "number",
      strong: true,
      disabled: tipe === KasbonKaryawanTypeEnum.REIMBURSE,
      enabledAt: [
        KasbonKaryawanTypeEnum.KASBON,
        KasbonKaryawanTypeEnum.REIMBURSE,
      ],
    },

    // {
    //   id: "tipePersetujuan",
    //   label: "Persetujuan",
    //   type: "radio",
    //   options: genOptionFromArray(["OS_CABANG", "ACC_PUSAT", "DIREKSI"]),
    //   enabledAt: [KasbonKaryawanTypeEnum.KASBON],
    // },
  ]

  return items
    .filter((i: SpesificModuleFormItem<KasbonKaryawanTypeEnum>) =>
      i.enabledAt.includes(tipe)
    )
    .map(({ enabledAt, ...data }) => data)
}

export default generateKasbonKaryawanFormItems
