import { SpesificModuleFormItem, SuratJalanCustomerTypeEnum } from "common/type"
import { formatNumber, genFieldQueryParamStr } from "common/util"
import { TableCell } from "components/data-table"
import { BasePageFormGroupItem } from "components/page/type"

function generateItemSuratJalanCustomerFormItems(
  tipe: SuratJalanCustomerTypeEnum
): BasePageFormGroupItem[] {
  const items: SpesificModuleFormItem<SuratJalanCustomerTypeEnum>[] = [
    {
      id: "item.id",
      label: "Barang (Nomor Sales Kontrak)",
      column: 1,
      type: "async-table-select",
      resourceUrl: (data, filter, detail) => {
        const q: any = {
          "penawaran.id": detail.salesContract?.penawaran?.id,
        }
        if (
          detail.salesContract?.penawaran?.tipe ===
          SuratJalanCustomerTypeEnum.SERVICE
        ) {
          q["itemSuratJalanCustomers.id"] = "null"
        } else if (
          detail.salesContract?.penawaran?.tipe ===
          SuratJalanCustomerTypeEnum.BARANG_KHUSUS
        ) {
          q["itemBarangJadi.sisaSuratJalan_$gt"] = "0.00001"
        } else {
          q["itemPenawaran.sisaSuratJalan_$gt"] = "0.00001"
        }

        const baseUrl =
          detail.salesContract?.penawaran?.tipe ===
          SuratJalanCustomerTypeEnum.BARANG_KHUSUS
            ? "item-barang-jadi"
            : "item-penawaran"

        return `${baseUrl}?${genFieldQueryParamStr(q)}&sortBy=id&sortType=asc`
      },
      resourceColumn: [
        {
          dataKey: "barang.kode",
          label: "Kode",
          render(data, idx, globVars) {
            return (
              <TableCell>
                {data.kodeBarang ?? (!!data.barang ? data.barang?.kode : "-")}
              </TableCell>
            )
          },
        },
        {
          dataKey: "barang.nama",
          label: "Nama Barang",
          render(data, idx, globVars) {
            return (
              <TableCell>
                {data.namBarang ?? data.barang?.nama ?? data.namaBarang}
              </TableCell>
            )
          },
        },
        {
          dataKey: "penawaran.salesContracts.0.noSalesContract",
          label: "Nomor Sales Kontrak",
        },
        {
          dataKey: "penawaran.tipe",
          label: "Jenis Penawaran",
        },
        {
          dataKey: "penawaran.salesContracts.0.tanggal",
          label: "Tanggal",
        },
        {
          dataKey: "penawaran.salesContracts.0.noPoPelanggan",
          label: "PO Cust.",
        },
        {
          dataKey: "sisaSuratJalan",
          label: "Quantity",
          type: "number",
          render(data, idx, globVars) {
            return (
              <TableCell>
                {formatNumber(
                  data.penawaran?.tipe !== "SERVICE"
                    ? data.sisaSuratJalan
                    : data.qty
                )}
              </TableCell>
            )
          },
        },
        {
          dataKey: "hargaJual",
          label: "Harga Satuan",
          type: "number",
          render(data, idx, globVars) {
            return (
              <TableCell>
                {formatNumber(
                  data.ongkos ?? data.hargaJual + +data.hargaJos / +data.qty
                )}
              </TableCell>
            )
          },
        },
      ],
      resourceColumnLabelKey: "penawaran.salesContracts.0.noSalesContract",
      customOnChange: (data, setValue, getValue) => {
        setValue("penawaran", data.penawaran)
        if (!!data.penawaran?.id) {
          if (data.penawaran?.tipe === "BARANG_KHUSUS") {
            setValue("itemBarangJadi", data)
            setValue("namaBarang", data.namaBarang)
            if (!getValue("jumlahAvailable")) {
              setValue("jumlahAvailable", +data.sisaSuratJalan)
              setValue("qty", +data.sisaSuratJalan)
            }
          } else {
            if (!!data.barang) {
              setValue("itemPenawaran", data)
              setValue("itemService", null)
              if (!getValue("jumlahAvailable")) {
                setValue("jumlahAvailable", +data.sisaSuratJalan)
                setValue("qty", +data.sisaSuratJalan)
              }
            } else {
              setValue("itemService", data)
              setValue("itemPenawaran", null)
              if (!getValue("jumlahAvailable")) {
                setValue("jumlahAvailable", +data.qty)
                setValue("qty", +data.qty)
              }
            }
            setValue(
              "namaBarang",
              !!data.barang ? data.barang.nama : data.namaBarang
            )
          }
        }

        setValue("tanggalSc", data.penawaran?.salesContracts?.[0]?.tanggal)
        setValue(
          "noPoPelanggan",
          data.penawaran?.salesContracts?.[0]?.noPoPelanggan
        )
      },
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      isDataView: true,
      label: "Barang (Nomor Sales Kontrak)",
      isIgnored: true,
      id: "penawaran.salesContracts.0.noSalesContract",
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "penawaran.tipe",
      label: "Jenis Penawaran",
      isDataView: true,
      type: "ignored",
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "penawaran.salesContracts.0.tanggal",
      type: "date",
      label: "Tanggal SC",
      disabled: true,
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
      suffix: {
        id: "penawaran.salesContracts.0.noPoPelanggan",
        disabled: true,
        label: "Nomor PO Customer",
        isDataView: true,
        isHalfSize: true,
      },
    },
    {
      id: "penawaran.isPpn",
      label: "PPN",
      type: "check",
      disabled: true,
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "itemPenawaran.barang.nama",
      label: "Barang",
      disabled: true,
      renderCondition(formData, formMode, filterData) {
        return !!formData.itemPenawaran?.id
      },
      suffix: {
        id: "itemPenawaran.barang.kode",
        disabled: true,
        label: "Kode",
      },
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "itemService.namaBarang",
      disabled: true,
      label: "Barang",
      renderCondition(formData, formMode, filterData) {
        return !!formData.itemService?.id
      },
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "namaBarang",
      type: "ignored",
      label: "Nama Barang",
      isDataView: true,
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "itemPenawaran.penawaran.mataUang.mataUang",
      disabled: true,
      label: "Mata Uang",
      renderCondition(formData, formMode, filterData) {
        return !!formData.itemPenawaran?.id
      },
      suffix: {
        id: "itemPenawaran.hargaJual",
        disabled: true,
        label: "Harga",
        type: "number",
        isHalfSize: true,
      },
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "itemService.ongkos",
      disabled: true,
      label: "Harga",
      type: "number",
      renderCondition(formData, formMode, filterData) {
        return !!formData.itemService?.id
      },
      suffix: {
        id: "itemService.penawaran.mataUang.mataUang",
        disabled: true,
        label: "Mata Uang",
      },
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "itemPenawaran.spesifikasi.panjang",
      disabled: true,
      type: "number",
      label: "Panjang",
      renderCondition(formData, formMode, filterData) {
        return !!formData.itemPenawaran?.id
      },
      enabledAt: [SuratJalanCustomerTypeEnum.STOCK],
      suffix: {
        id: "itemPenawaran.spesifikasi.lebar",
        disabled: true,
        type: "number",
        label: "Lebar",
        renderCondition(formData, formMode, filterData) {
          return !!formData.itemPenawaran?.id
        },
      },
    },
    {
      id: "qty",
      disabled: true,
      label: "Kuantitas",
      renderCondition(formData, formMode, filterData) {
        return !!formData.itemPenawaran?.id
      },
      suffix: {
        id: "penawaran.diskon",
        label: "Diskon (%)",
        disabled: true,
        type: "number",
        isHalfSize: true,
      },
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "itemService.qty",
      disabled: true,
      label: "Kuantitas",
      renderCondition(formData, formMode, filterData) {
        return !!formData.itemService?.id
      },
      suffix: {
        id: "penawaran.diskon",
        label: "Diskon (%)",
        disabled: true,
        type: "number",
        isHalfSize: true,
      },
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "note",
      label: "Keterangan",
      isOptional: true,
      type: "text-area",
      enabledAt: [SuratJalanCustomerTypeEnum.STOCK],
    },
    {
      id: "blank",
      type: "blank",
      enabledAt: [SuratJalanCustomerTypeEnum.STOCK],
    },
    {
      id: "label",
      type: "title",
      label: "Jenis Stok",
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
    },
    {
      id: "blank",
      type: "blank",
      enabledAt: [SuratJalanCustomerTypeEnum.STOCK],
    },
    {
      id: "jumlahAvailable",
      label: "Available",
      dataViewLabel: "Kuantitas",
      type: "number",
      value: 0,
      disabled(data, detailData, formMode, globVars) {
        return data.penawaran?.tipe === "SERVICE"
      },
      formRule(data, detail) {
        const max = +data?.itemPenawaran?.sisaSuratJalan + +data

        return {
          max: {
            value: max,
            message: `Tidak boleh lebih dari ${formatNumber(max)}`,
          },
        }
      },
      enabledAt: [
        SuratJalanCustomerTypeEnum.STOCK,
        SuratJalanCustomerTypeEnum.SERVICE,
      ],
      suffix:
        tipe === SuratJalanCustomerTypeEnum.STOCK
          ? {
              id: "jumlahWaste",
              label: "Waste",
              type: "number",
              value: 0,
              disabled(data, detailData, formMode, globVars) {
                return !!data.penawaran?.isAtSpb
              },
              isHalfSize: true,
            }
          : undefined,
    },
    {
      id: "jumlahSample",
      label: "Sample",
      type: "number",
      value: 0,
      disabled(data, detailData, formMode, globVars) {
        return !!data.penawaran?.isAtSpb
      },
      enabledAt: [SuratJalanCustomerTypeEnum.STOCK],
      suffix: {
        id: "blank",
        type: "blank",
        isHalfSize: true,
        isOptional: true,
      },
    },
  ]

  return items
    .filter((i: SpesificModuleFormItem<SuratJalanCustomerTypeEnum>) =>
      i.enabledAt.includes(tipe)
    )
    .map(({ enabledAt, ...data }) => data)
}

export default generateItemSuratJalanCustomerFormItems
