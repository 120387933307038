import { useCallback, useMemo, useState } from "react"
import _ from "lodash"

let t: NodeJS.Timeout
export function useUndoable<DataType>() {
  const [histories, setHistories] = useState<DataType[]>([])
  const [currIndex, setCurrIndex] = useState<number>(0)

  const state = useMemo(() => histories[currIndex], [currIndex, histories])

  const addHistory = useCallback(
    (value: DataType) => {
      clearTimeout(t)
      t = setTimeout(() => {
        if (_.isEqual(state, value)) {
          return
        }

        const copy = histories.slice(0, currIndex + 1)
        copy.push(value)
        setHistories(copy)
        setCurrIndex(copy.length - 1)
      }, 800)
    },
    [state, histories]
  )

  const doUndo = useCallback(() => {
    const newIdx = Math.max(0, Number(currIndex) - 1)
    setCurrIndex(newIdx)
    return histories[newIdx]
  }, [currIndex, histories])

  const doRedo = useCallback(() => {
    const newIdx = Math.min(histories.length - 1, Number(currIndex) + 1)
    setCurrIndex(newIdx)
    return histories[newIdx]
  }, [currIndex, histories])

  const resetHistory = useCallback(() => {
    setHistories([])
    setCurrIndex(0)
  }, [])

  return {
    isCanUndo: currIndex > 0,
    isCanRedo: currIndex < histories.length - 1,
    addHistory,
    doUndo,
    doRedo,
    resetHistory,
  }
}
