import { DataList, useGet, usePost } from "hooks"

export interface GlobVar {
  key: string
  value: string
}

export function mapGlobVar(data: any): GlobVar {
  return {
    key: data?.key ?? "",
    value: data?.value ?? "",
  }
}

export function mapGlobVarList(data: any): Map<string, any> {
  const result = new Map<string, any>()
  for (const item of (data?.data?.items ?? []) as GlobVar[]) {
    result.set(item.key, item.value)
  }
  return result
}

export const useGetAllGlobVar = () =>
  useGet<Map<string, any>, any>("/global-constant", mapGlobVarList)
