import { Text } from "@chakra-ui/react"
import { GeneralMap } from "common/type"
import { ColorDict } from "constant/theme"
import { useMemo } from "react"

interface TitleProps {
  size?: string
}

const TitleSizeMap: GeneralMap<number> = {
  sm: 24,
  md: 28,
}

const Title: React.FC<TitleProps> = ({ children, size }) => {
  const fontSize = useMemo(() => TitleSizeMap[size ?? "md"], [size])

  return (
    // @ts-ignore
    <Text
      fontSize={`${fontSize}px`}
      color={ColorDict.primary}
      fontWeight={600}
      mb="12px"
    >
      {children}
    </Text>
  )
}

export default Title
