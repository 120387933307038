import { GeneralMap } from "common/type"
import { genFieldQueryParamStr } from "common/util"
import { PERMISSIONS } from "constant/role"
import { DataList, LiteralListMapper, useGet, usePost } from "hooks"
import { useMemo } from "react"

export interface PermissionAction {
  R: boolean
  W: boolean
  D: boolean
}

export interface PermissionModule {
  modules?: string[]
  views?: string[]
  children?: { [key: string]: PermissionModule }
}

export interface RoleRequest {
  roleName: string
  permissions: string[]
}

export function mapPermissionModuleResponse(data: any): {
  [key: string]: PermissionModule
} {
  return data.data
}

export function isPermissionGranted(
  permission?: string[],
  action?: string
): boolean {
  if (!permission) return false
  if (!action) return true
  return permission.includes(action)
}

export const useGetAllPermissionModule = () =>
  useGet<{ [key: string]: PermissionModule }, any>(
    "/permission/modules",
    mapPermissionModuleResponse
  )

export const useGetAllAdmin = () =>
  useGet<any, any>(
    `/karyawan?${genFieldQueryParamStr({
      jenisJabatan: "ADMIN",
    })}`,
    LiteralListMapper
  )

export const useCreateAdmin = () => usePost<any, any>("/karyawan", "post")

export const useUpdateAdmin = () =>
  usePost<any, RoleRequest>("/karyawan/{id}", "patch")

export const useDeleteAdmin = () =>
  usePost<any, any>("/karyawan/{id}", "delete")
