import { Box } from "@chakra-ui/react"

interface PaperProps {
  isBorder?: boolean
  pb?: string
}

const Paper: React.FC<PaperProps> = ({ children, isBorder, pb }) => {
  return (
    <Box
      bg="white"
      borderRadius="18px"
      boxShadow="0px 4px 6px rgba(114, 128, 157, 0.02)"
      pt="13px"
      pb={pb ?? "23px"}
      px="23px"
      border={isBorder ? "1px solid #E7EEF7" : "none"}
    >
      {children}
    </Box>
  )
}

export default Paper
