import { PenawaranTypeEnum, SpesificModuleFormItem } from "common/type"
import {
  dateToStr,
  formatNumber,
  genFieldQueryParamStr,
  genOptionFromArray,
  getToday,
  isDivisiBelting,
  isDivisiTextile,
  strToSafeNum,
} from "common/util"
import { BasePageFormGroupItem } from "components/page/type"
import {
  karyawanOptionMapper,
  mataUangOptionMapper,
  mataUangSearchField,
  pelangganOptionMapper,
  pelangganResourceColumnKeys,
  pelangganSearchKeys,
} from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"
import { getKursByMataUang } from "hooks/http/custom"
import { penawaranDiskonRule } from "common/procedures/penawaran"

function genDivisiSpec(tipe: PenawaranTypeEnum): BasePageFormGroupItem {
  return {
    id: "divisi.id",
    label: "Divisi",
    type: "async-select",
    resourceUrl: `divisi?${genBaseSortQueryParam()}`,
    isCallRecalculateCb: true,
    customOnChange: (data, setValue, getValue, genCode) => {
      setValue("divisi", data)
      if (tipe === PenawaranTypeEnum.SAMPLE) {
        if (isDivisiBelting(data)) {
          setValue("jenisStokSample", "STOCK")
        }
      }

      if (tipe === PenawaranTypeEnum.TTLC) {
        if (isDivisiTextile(data)) {
          setValue("paymentOption", "LC")
        }
      }
      genCode()
    },
  }
}

function generatePenawaranFormItems(
  tipe: PenawaranTypeEnum
): BasePageFormGroupItem[] {
  const items: SpesificModuleFormItem<PenawaranTypeEnum>[] = [
    {
      id: "tipe",
      type: "hidden",
      value: tipe,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.TRIAL,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.KHUSUS,
      ],
    },
    {
      id: "tanggal",
      type: "hidden",
      value: getToday(),
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.TRIAL,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.KHUSUS,
      ],
    },
    {
      id: "margin",
      label: "Margin (%)",
      type: [PenawaranTypeEnum.STOCK, PenawaranTypeEnum.KHUSUS].includes(tipe)
        ? "hidden"
        : "number",
      disabled: true,
      strong: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.KHUSUS,
      ],
    },
    {
      id: "space",
      type: "blank",
      enabledAt: [PenawaranTypeEnum.INDENT, PenawaranTypeEnum.TTLC],
    },
    {
      id: "konsinyasi.id",
      label: "Konsinyasi",
      isOptional: true,
      type: "async-table-select",
      renderCondition(formData, formMode, filterData) {
        return formData.tipe === "KONSINYASI"
      },
      customOnChange: (data, setValue, getValue, genCode) => {
        setValue("pelanggan", data?.pelanggan)
        setValue("sales", data?.sales)
        setValue("keterangan", data?.keterangan)
        const itemPenawarans =
          data?.itemKonsinyasis?.map((i: any, key: number) => ({
            barang: i.barang,
            spesifikasi: i.spesifikasi,
            qty: i.qty,
            keterangan: i.keterangan,
            xId: new Date().getTime() + key,
          })) ?? []
        console.log({ itemPenawarans })
        setValue("itemPenawarans", itemPenawarans)
        genCode()
      },
      resourceUrl: (d, filter) => {
        const q: any = {
          "penawarans.id": "null",
        }

        if (filter["divisi.id"]) {
          q["divisi.id"] = filter["divisi.id"]
        }

        if (filter["organisasi.id"]) {
          q["organisasi.id"] = filter["organisasi.id"]
        }

        return `konsinyasi?${genFieldQueryParamStr(q)}&${genBaseSortQueryParam(
          "tanggal",
          false
        )}`
      },
      resourceColumn: [
        {
          dataKey: "no",
          label: "Nomor",
        },
        {
          dataKey: "pelanggan.namaPanjang",
          label: "Nama Customer",
        },
        {
          dataKey: "sales.nama",
          label: "Nama Sales",
        },
      ],
      column: 1,
      enabledAt: [PenawaranTypeEnum.STOCK],
    },
    {
      id: "isKirimSupplier",
      type: "check",
      label: "Kirim ke Supplier",
      column: 1,
      enabledAt: [PenawaranTypeEnum.SERVICE],
    },
    {
      id: "organisasi.id",
      label: "Cabang",
      type: "async-select",
      customOnChange: (data, setValue, getValue, genCode) => {
        setValue("organisasi", data)
        genCode()
      },
      resourceUrl: `organisasi?${genBaseSortQueryParam()}`,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.TRIAL,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.KHUSUS,
      ],
      suffix: {
        ...genDivisiSpec(tipe),
        isHalfSize: true,
      },
    },
    {
      id: "tipe",
      type: "radio",
      label: "Tipe",
      options: genOptionFromArray(["STOCK", "TRIAL", "KONSINYASI"]),
      value: "STOCK",
      enabledAt: [PenawaranTypeEnum.STOCK],
      suffix: {
        id: "isMmub",
        type: "check",
        label: "MMUB",
      },
    },
    {
      id: "tipeIndent",
      label: "Jenis Indent/Lokal",
      type: "radio",
      options: [
        {
          label: "Lokal/Khusus",
          value: "LOKAL",
        },
        {
          label: "Indent",
          value: "INDENT",
        },
      ],
      enabledAt: [PenawaranTypeEnum.INDENT],
      customOnChange: (data, setValue) => {
        if (data === "INDENT") {
          setValue("isPkp", null)
        }
      },
      suffix: {
        id: "isPkp",
        type: (data) => {
          return data.tipeIndent === "LOKAL" ? "check" : "hidden"
        },
        value: true,
        isOptional: true,
        label: (data) => {
          return data.tipeIndent === "LOKAL" ? "Supplier PKP/Non-PKP" : ""
        },
        isHalfSize: true,
      },
    },
    {
      id: "isPkp",
      type: (data) => {
        return data.tipeIndent === "LOKAL" ? "check" : "hidden"
      },
      value: true,
      isOptional: true,
      label: (data) => {
        return data.tipeIndent === "LOKAL" ? "Supplier PKP/Non-PKP" : ""
      },
      enabledAt: [PenawaranTypeEnum.STOCK],
    },
    {
      id: "no",
      label: "Nomor",
      isDataView: true,
      isGeneratedCodeField: true,
      disabled: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.TRIAL,
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.STOCK,
      ],
      suffix:
        tipe === PenawaranTypeEnum.INDENT
          ? {
              id: "revisi",
              label: "Rev.",
              isOptional: true,
              isHalfSize: true,
            }
          : {
              id: "isPpn",
              type: "check",
              label: "PPN",
              value: false,
              isHalfSize: true,
            },
    },

    {
      id: "pelanggan.id",
      label: "Customer",
      type: "async-select",
      resourceColumnLabelKey: pelangganResourceColumnKeys,
      resourceUrl: (data, filterData) => {
        console.log({ data })
        return `pelanggan?${genBaseSortQueryParam(
          "namaPanjang"
        )}&${genFieldQueryParamStr({
          "organisasi.id": filterData?.["organisasi.id"],
          isPpn: data?.isPpn,
        })}`
      },
      resourceMapper: pelangganOptionMapper,
      dataViewKey: "pelanggan.namaPanjang",
      dataViewLabel: "Nama Customer",
      customOnChange: (
        data,
        setValue,
        getValue,
        genCb,
        globVars,
        triggerPreCheck
      ) => {
        setValue("namaKontak", data.kontak)
        triggerPreCheck()
      },
      searchBy: pelangganSearchKeys,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.TRIAL,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.STOCK,
      ],
    },
    {
      id: "isPpn",
      type: "hidden",
      label: "PPN",
      value: true,
      enabledAt: [PenawaranTypeEnum.INDENT],
    },
    {
      id: "no",
      label: "Nomor",
      isDataView: true,
      isGeneratedCodeField: true,
      disabled: true,
      enabledAt: [PenawaranTypeEnum.SAMPLE, PenawaranTypeEnum.PINJAMAN],
    },
    {
      id: "isPpn",
      type: "hidden",
      value: false,
      enabledAt: [PenawaranTypeEnum.SAMPLE],
    },
    {
      id: "tanggal",
      type: "date",
      label: "Tanggal",
      isIgnored: true,
      isDataView: true,
      enabledAt: [PenawaranTypeEnum.SERVICE],
    },
    {
      id: "sales.id",
      type: "async-select",
      resourceUrl: (data, filterData) =>
        `karyawan?${genFieldQueryParamStr({
          jenisJabatan: "SALES",
          "organisasi.id": filterData?.["organisasi.id"],
          "divisi.id": filterData?.["divisi.id"],
        })}&${genBaseSortQueryParam()}`,
      resourceMapper: karyawanOptionMapper,
      dataViewKey: "sales.nama",
      dataViewLabel: "Nama Sales",
      label: "Sales",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.TRIAL,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.KHUSUS,
      ],
      suffix:
        tipe !== PenawaranTypeEnum.SERVICE
          ? {
              id: "oms",
              label: "OMS",
              isHalfSize: true,
            }
          : undefined,
    },
    {
      id: "namaKontak",
      label: "Kontak/Attn",
      disabled: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.TRIAL,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.SERVICE,
      ],
      suffix:
        tipe !== PenawaranTypeEnum.SERVICE
          ? {
              id: "cc",
              label: "CC",
              isOptional: true,
              isHalfSize: true,
            }
          : undefined,
    },

    {
      id: "modeOfShipment",
      label: "Mode of Shipment",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.TRIAL,
        PenawaranTypeEnum.STOCK,
      ],
      suffix:
        tipe === PenawaranTypeEnum.STOCK
          ? undefined
          : {
              id: "placement",
              label: "Placement",
              renderCondition(formData, formMode, filterData) {
                return (
                  (tipe === PenawaranTypeEnum.SERVICE &&
                    !!formData.isKirimSupplier) ||
                  tipe !== PenawaranTypeEnum.SERVICE
                )
              },
              isHalfSize: true,
            },
    },
    {
      id: "placeOfDelivery",
      type: "radio",
      value: "EXWORK",
      options: ["EXWORK", "FOB", "CNF", "CIF"].map((i) => ({
        label: i,
        value: i,
      })),
      label: "Place of Delivery",
      enabledAt: [PenawaranTypeEnum.TTLC, PenawaranTypeEnum.STOCK],
    },
    {
      id: "paymentOption",
      label: "Payment",
      type: "radio",
      value: "TT",
      options: [
        { label: "TT", value: "TT" },
        { label: "LC", value: "LC" },
      ],
      enabledAt: [PenawaranTypeEnum.TTLC],
    },

    {
      id: "timeOfDelivery",
      label: "Time of Delivery/Shipment",
      renderCondition(formData, formMode, filterData) {
        return (
          (tipe === PenawaranTypeEnum.SERVICE && !!formData.isKirimSupplier) ||
          tipe !== PenawaranTypeEnum.SERVICE
        )
      },
      isOptional: tipe === PenawaranTypeEnum.JOS,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.TRIAL,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.STOCK,
      ],
      suffix: {
        id: "termOfPayment",
        label: "Term of Payment",
        value: "14 Days - (FULL AMOUNT)",
        isOptional: tipe === PenawaranTypeEnum.JOS,
        isHalfSize: true,
      },
    },
    {
      id: "termOfPayment",
      label: "Term of Payment",
      value: "14 Days - (FULL AMOUNT)",
      isOptional: tipe === PenawaranTypeEnum.JOS,
      enabledAt: [PenawaranTypeEnum.KHUSUS, PenawaranTypeEnum.STOCK],
      suffix: {
        id: "offerValidity",
        label: "Offer Validity",
        isOptional: true,
        isHalfSize: true,
      },
    },
    {
      id: "offerValidity",
      label: "Offer Validity",
      isOptional: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.TRIAL,
      ],
    },
    {
      id: "blank",
      type: "blank",
      isOptional: true,
      enabledAt: [PenawaranTypeEnum.KHUSUS],
    },
    {
      id: "mataUang.mataUang",
      label: "Mata Uang",
      type: [PenawaranTypeEnum.SAMPLE].includes(tipe)
        ? "hidden"
        : "async-select",
      resourceUrl: `valuta-asing?${genBaseSortQueryParam("mataUang")}`,
      disabled: (data) => data.tipeIndent === "LOKAL",
      resourceMapper: mataUangOptionMapper,
      searchBy: mataUangSearchField,
      value: "RP",
      customOnChange: (data, setValue, getValue) => {
        if (!!data?.mataUang && data?.mataUang !== getValue("origMataUang")) {
          getKursByMataUang(data.mataUang).then((res) => {
            setValue("rate", res)
            setValue("origMataUang", data?.mataUang)
          })
        }
      },
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.TRIAL,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.SERVICE,
      ],
      suffix: [PenawaranTypeEnum.INDENT, PenawaranTypeEnum.SERVICE].includes(
        tipe
      )
        ? {
            id: "rate",
            label: "Rate",
            type: "number",
            disabled: true,
            isHalfSize: true,
          }
        : [PenawaranTypeEnum.STOCK, PenawaranTypeEnum.KHUSUS].includes(tipe)
        ? {
            id: "diskon",
            value: 0,
            type: "number",
            label: "Diskon (%)",
            formRule: penawaranDiskonRule(tipe),
            isHalfSize: true,
          }
        : [PenawaranTypeEnum.TTLC].includes(tipe)
        ? {
            id: "komisi",
            type: "number",
            label: "Komisi (%)",
            isHalfSize: true,
            value: 0,
            isCallRecalculateCb: tipe === PenawaranTypeEnum.TTLC,
          }
        : {
            id: "blank",
            type: "blank",
            isOptional: true,
            isHalfSize: true,
          },
    },
    {
      id: "kb",
      type: "number",
      value: 0,
      label: "KB (%)",
      formRule(data, detail) {
        const margin = data.margin ?? 0
        let max = 0
        if (margin >= 10 && margin <= 25) {
          max = 2.5
        } else if (margin > 25) {
          max = 5
        }

        return {
          max: {
            value: max,
            message: `KB tidak boleh lebih dari ${max}%`,
          },
        }
      },
      isCallRecalculateCb: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.KHUSUS,
      ].includes(tipe),
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.TRIAL,
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.STOCK,
      ],
      suffix: {
        id: "blank",
        type: "hidden",
        isOptional: true,
        isHalfSize: true,
      },
    },
    {
      id: "catatan",
      label: "Note",
      value:
        PenawaranTypeEnum.TTLC === tipe
          ? "PLEASE DO NOT PRODUCE BEFORE RECEIVE THE PAYMENT"
          : undefined,
      type: "text-area",
      isOptional: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.TRIAL,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.KHUSUS,
      ],
    },
    {
      id: "revisi",
      label: "Rev.",
      isOptional: true,
      enabledAt: [
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.STOCK,
      ],
    },
    {
      id: "tanggalJatuhTempo",
      type: "date",
      value: (function () {
        const now = new Date()
        return dateToStr(new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000))
      })(),
      label: "Tanggal Jatuh Tempo",
      renderCondition(formData, formMode, filterData) {
        return (
          PenawaranTypeEnum.TTLC === tipe ||
          (PenawaranTypeEnum.STOCK === tipe && formData.tipe === "TRIAL")
        )
      },
      enabledAt: [PenawaranTypeEnum.TTLC, PenawaranTypeEnum.STOCK],
    },
    {
      id: "origMataUang",
      type: "hidden",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.TRIAL,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.SERVICE,
      ],
    },
    {
      id: "diskon",
      value: 0,
      type: "number",
      label:
        tipe === PenawaranTypeEnum.INDENT
          ? "Diskon Customer (%)"
          : "Diskon (%)",
      formRule: [PenawaranTypeEnum.INDENT, PenawaranTypeEnum.TTLC].includes(
        tipe
      )
        ? undefined
        : penawaranDiskonRule(tipe),
      isCallRecalculateCb: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.KHUSUS,
      ].includes(tipe),
      suffix:
        tipe === PenawaranTypeEnum.TTLC
          ? {
              id: "dataDicetak.diskon",
              type: "check",
              label: "Dicetak",
              value: true,
              isOptional: true,
              isHalfSize: true,
            }
          : [PenawaranTypeEnum.INDENT, PenawaranTypeEnum.SERVICE].includes(tipe)
          ? {
              id: "diskonSupplier",
              value: 0,
              label: "Diskon Supplier (%)",
              type: "number",
              isCallRecalculateCb: [
                PenawaranTypeEnum.INDENT,
                PenawaranTypeEnum.TTLC,
              ].includes(tipe),
              renderCondition(formData, formMode, filterData) {
                return (
                  (tipe === PenawaranTypeEnum.SERVICE &&
                    !!formData.isKirimSupplier) ||
                  tipe !== PenawaranTypeEnum.SERVICE
                )
              },
              isHalfSize: true,
            }
          : undefined,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.TRIAL,
      ],
    },
    {
      id: "packing",
      value: 0,
      isCallRecalculateCb: tipe === PenawaranTypeEnum.INDENT,
      type: "number",
      label: "Packing",
      suffix: {
        id: "dataDicetak.packing",
        label: "Dicetak",
        type: "check",
        value: true,
        isHalfSize: true,
        isOptional: true,
        isCallRecalculateCb: tipe === PenawaranTypeEnum.INDENT,
      },
      renderCondition(formData, formMode, filterData) {
        return (
          (tipe === PenawaranTypeEnum.SERVICE && !!formData.isKirimSupplier) ||
          tipe !== PenawaranTypeEnum.SERVICE
        )
      },
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
      ],
    },
    {
      id: "bankCharge",
      type: "number",
      label: "Bank Charge",
      isCallRecalculateCb: true,
      value: 0,
      renderCondition(formData, formMode, filterData) {
        return (
          (tipe === PenawaranTypeEnum.SERVICE && !!formData.isKirimSupplier) ||
          tipe !== PenawaranTypeEnum.SERVICE
        )
      },
      enabledAt: [PenawaranTypeEnum.INDENT, PenawaranTypeEnum.SERVICE],
      suffix: {
        id: "beaMasuk",
        type: "number",
        value: 0,
        label: "Bea Masuk (%)",
        isCallRecalculateCb: tipe === PenawaranTypeEnum.INDENT,
        renderCondition(formData, formMode, filterData) {
          return (
            (tipe === PenawaranTypeEnum.SERVICE &&
              !!formData.isKirimSupplier) ||
            tipe !== PenawaranTypeEnum.SERVICE
          )
        },
        isHalfSize: true,
      },
    },
    {
      id: "freight",
      label: "Freight",
      type: "number",
      value: 0,
      suffix: {
        id: "dataDicetak.freight",
        label: "Dicetak",
        type: "check",
        value: true,
        isHalfSize: true,
        isCallRecalculateCb: tipe === PenawaranTypeEnum.INDENT,
        isOptional: true,
      },
      isCallRecalculateCb: tipe === PenawaranTypeEnum.INDENT,
      renderCondition(formData, formMode, filterData) {
        return (
          (tipe === PenawaranTypeEnum.SERVICE && !!formData.isKirimSupplier) ||
          tipe !== PenawaranTypeEnum.SERVICE
        )
      },
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
      ],
    },
    {
      id: "handlingCost",
      type: "number",
      label: "Handling Cost (%)",
      value: 0,
      isCallRecalculateCb: true,
      renderCondition(formData, formMode, filterData) {
        return (
          (tipe === PenawaranTypeEnum.SERVICE && !!formData.isKirimSupplier) ||
          tipe !== PenawaranTypeEnum.SERVICE
        )
      },
      enabledAt: [PenawaranTypeEnum.INDENT, PenawaranTypeEnum.SERVICE],
      suffix: {
        id: "combineShipment",
        type: "number",
        label: "Com. Shipment (%)",
        value: 0,
        isCallRecalculateCb: true,
        renderCondition(formData, formMode, filterData) {
          return (
            (tipe === PenawaranTypeEnum.SERVICE &&
              !!formData.isKirimSupplier) ||
            tipe !== PenawaranTypeEnum.SERVICE
          )
        },
        isHalfSize: true,
      },
    },
    {
      id: "fobCharge",
      type: "number",
      value: 0,
      label: "FOB Charge",
      isCallRecalculateCb: tipe === PenawaranTypeEnum.INDENT,
      suffix: {
        id: "dataDicetak.fobCharge",
        label: "Dicetak",
        type: "check",
        isHalfSize: true,
        value: true,
        isCallRecalculateCb: tipe === PenawaranTypeEnum.INDENT,
        isOptional: true,
      },
      renderCondition(formData, formMode, filterData) {
        return (
          (tipe === PenawaranTypeEnum.SERVICE && !!formData.isKirimSupplier) ||
          tipe !== PenawaranTypeEnum.SERVICE
        )
      },
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
      ],
    },
    {
      id: "inLandCost",
      type: "number",
      label: "In Land Cost",
      isOptional: true,
      value: 0,
      // TODO: move this as one function
      renderCondition(formData, formMode, filterData) {
        return (
          (tipe === PenawaranTypeEnum.SERVICE && !!formData.isKirimSupplier) ||
          tipe !== PenawaranTypeEnum.SERVICE
        )
      },
      isCallRecalculateCb: true,
      enabledAt: [PenawaranTypeEnum.INDENT, PenawaranTypeEnum.SERVICE],
      suffix: {
        id: "biayaTransfer",
        type: "number",
        isCallRecalculateCb: true,
        label: "Biaya Transfer",
        value: 0,
        renderCondition(formData, formMode, filterData) {
          return (
            (tipe === PenawaranTypeEnum.SERVICE &&
              !!formData.isKirimSupplier) ||
            tipe !== PenawaranTypeEnum.SERVICE
          )
        },
        isHalfSize: true,
      },
    },

    {
      id: "insurance",
      type: "number",
      value: 0,
      isCallRecalculateCb: tipe === PenawaranTypeEnum.INDENT,
      label: "Insurance (%)",
      suffix: {
        id: "dataDicetak.insurance",
        label: "Dicetak",
        type: "check",
        isHalfSize: true,
        value: true,
        isCallRecalculateCb: tipe === PenawaranTypeEnum.INDENT,
        isOptional: true,
      },
      renderCondition(formData, formMode, filterData) {
        return (
          (tipe === PenawaranTypeEnum.SERVICE && !!formData.isKirimSupplier) ||
          tipe !== PenawaranTypeEnum.SERVICE
        )
      },
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.SERVICE,
      ],
    },
    {
      id: "namaLainnya",
      label: "Lainnya",
      isOptional: true,
      suffix: {
        id: "nilaiLainnya",
        label: "Nilai",
        type: "number",
        isHalfSize: true,
        isOptional: true,
      },
      enabledAt: [PenawaranTypeEnum.TTLC],
    },
    {
      id: "garansi",
      label: "Garansi (Hari)",
      type: "number",
      isOptional: true,
      renderCondition(formData, formMode, filterData) {
        return (
          (tipe === PenawaranTypeEnum.SERVICE && !formData.isKirimSupplier) ||
          tipe !== PenawaranTypeEnum.SERVICE
        )
      },
      enabledAt: [
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.STOCK,
      ],
      suffix: {
        id: "referensi",
        label: "Referensi",
        isOptional: true,
        isHalfSize: true,
      },
    },
    {
      id: "referensi",
      label: "Referensi",
      isOptional: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.TRIAL,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
      ],
    },
    {
      id: "jenisStokSample",
      type: "radio",
      options: genOptionFromArray(["STOCK", "SAMPLE"]),
      label: "Jenis Stock Sample",
      enabledAt: [PenawaranTypeEnum.SAMPLE],
    },
    {
      id: "isDeliverToPabrik",
      label: "Alamat Pabrik",
      type: "check",
      suffix: {
        id: "isAtSpb",
        type: "check",
        value: tipe === PenawaranTypeEnum.KHUSUS,
        label: "SPB",
        isHalfSize: true,
        disabled: tipe === PenawaranTypeEnum.KHUSUS,
      },
      enabledAt: [PenawaranTypeEnum.STOCK, PenawaranTypeEnum.KHUSUS],
    },
    {
      id: "isDeliverToPabrik",
      label: "Alamat Pabrik",
      type: "check",
      enabledAt: [PenawaranTypeEnum.JOS],
    },
    {
      id: "isDeliverToPabrik",
      label: "Alamat Pabrik",
      type: "check",
      enabledAt: [PenawaranTypeEnum.SERVICE, PenawaranTypeEnum.SAMPLE],
    },
    {
      id: "isDeliverToPabrik",
      label: "Alamat Pabrik",
      type: "check",
      enabledAt: [PenawaranTypeEnum.INDENT],
    },
    {
      id: "asalBarang",
      type: "radio",
      options: genOptionFromArray(["SEKAWAN", "CUSTOMER"]),
      label: "Asal Barang",
      enabledAt: [PenawaranTypeEnum.PINJAMAN],
    },
    {
      id: "pelangganAsalPinjam.id",
      type: "async-select",
      resourceUrl: (data, filterData) =>
        `pelanggan?${genBaseSortQueryParam(
          "namaPanjang"
        )}&${genFieldQueryParamStr({
          "organisasi.id": filterData?.["organisasi.id"],
        })}`,
      searchBy: pelangganSearchKeys,
      resourceMapper: pelangganOptionMapper,
      label: "Barang dari Cust.",
      renderCondition(formData, formMode, filterData) {
        return formData.asalBarang === "CUSTOMER"
      },
      enabledAt: [PenawaranTypeEnum.PINJAMAN],
    },
  ]

  return items
    .filter((i: SpesificModuleFormItem<PenawaranTypeEnum>) =>
      i.enabledAt.includes(tipe)
    )
    .map(({ enabledAt, ...data }) => data)
}

export default generatePenawaranFormItems
