import { Text } from "@chakra-ui/react"
import { PenawaranTypeEnum, SpesificModuleFormItem } from "common/type"
import {
  formatNumber,
  genFieldQueryParamStr,
  genOptionFromArray,
  strToSafeNum,
} from "common/util"
import { TableCell } from "components/data-table"
import { BasePageFormGroupItem } from "components/page/type"
import {
  barangResourceColumnKeys,
  satuanBarangMapper,
  voucherResourceColumnKeys,
} from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"
import { getHargaBarang, getHppBarang } from "services/barang"

let hargaJualTimeout: NodeJS.Timeout
function generateItemPenawaranFormItems(
  tipe: PenawaranTypeEnum
): BasePageFormGroupItem[] {
  const items: SpesificModuleFormItem<PenawaranTypeEnum>[] = [
    {
      id: "fixedBarangKode",
      label: "Kode",
      isDataView: true,
      isIgnored: true,
      value: "XXJOS",
      enabledAt: [PenawaranTypeEnum.JOS],
    },
    {
      id: "fixedBarangName",
      label: "Nama Barang",
      isDataView: true,
      isIgnored: true,
      value: "Joining On Site",
      enabledAt: [PenawaranTypeEnum.JOS],
    },
    {
      id: "barang.id",
      label: "Barang",
      disabled(data, detailData, formMode, globVars) {
        return !!detailData?.id
      },
      resourceColumnLabelKey: barangResourceColumnKeys,
      type: "async-select",
      resourceMapper:
        tipe === PenawaranTypeEnum.INDENT
          ? (data) => ({
              label: `${data.kode} - ${data.nama}${
                !!data.negara ? ` - ${data.negara?.kode} ` : ""
              }${!!data.supplier ? ` - ${data.supplier?.namaPendek} ` : ""}`,
              value: data.id,
            })
          : undefined,
      resourceUrl: (data, filterData) => {
        const q: any = {}

        if (!!filterData?.["divisi.id"]) {
          q["divisi.id_$In"] = `${filterData["divisi.id"]};3`
        }

        return `barang?${genFieldQueryParamStr(q)}&${genBaseSortQueryParam()}`
      },
      customOnChange: (data, setValue, getValues, genCode, globVars) => {
        if (
          [
            PenawaranTypeEnum.KHUSUS,
            PenawaranTypeEnum.STOCK,
            PenawaranTypeEnum.SERVICE,
          ].includes(tipe)
        ) {
          if (!data) return

          getHppBarang(data.id).then((hpp) => {
            let hargaDisarankanAdditional = data.subKelompok?.maxMargin
            if (!hargaDisarankanAdditional) {
              hargaDisarankanAdditional = globVars.get(
                "DEFAULT_HARGA_DISARANKAN_PERCENTAGE"
              )
            }
            let minHargaDisarankanAdditional = data.subKelompok?.minMargin
            if (!minHargaDisarankanAdditional) {
              minHargaDisarankanAdditional = globVars.get(
                "DEFAULT_MIN_HARGA_DISARANKAN_PERCENTAGE"
              )
            }
            const hargaDisarankan = !getValues("hargaDisarankan")
              ? hpp.rerataRupiah *
                (1 + strToSafeNum(hargaDisarankanAdditional) / 100)
              : +getValues("hargaDisarankan") /
                strToSafeNum(getValues("spesifikasi.luas"), 1)

            setValue(
              "hargaBeli",
              +hpp.rerataRupiah * strToSafeNum(getValues("spesifikasi.luas"), 1)
            )
            setValue("hargaBeliSatuan", +hpp.rerataRupiah)
            setValue(
              "hargaDisarankan",
              hargaDisarankan * strToSafeNum(getValues("spesifikasi.luas"), 1)
            )
            setValue(
              "hargaDisarankanSatuan",
              hargaDisarankan / strToSafeNum(getValues("qty"), 1)
            )
            setValue("minMargin", strToSafeNum(minHargaDisarankanAdditional))
            setValue(
              "minHargaJualMultiplier",
              1 + strToSafeNum(minHargaDisarankanAdditional) / 100
            )
          })
        }
        setValue("barang", data)
        if (!getValues("satuan")?.kode) {
          setValue("satuan", data.satuanJual)
        }
      },
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.STOCK,
      ],
    },
    {
      id: "barang.subKelompok.minMargin",
      type: "hidden",
      enabledAt: [PenawaranTypeEnum.STOCK],
    },
    {
      id: "barang.kode",
      type: "ignored",
      label: "Kode Barang",
      isDataView: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.STOCK,
      ],
    },
    {
      id: "barang.nama",
      type: "ignored",
      label: "Nama Barang",
      isDataView: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.STOCK,
      ],
    },
    {
      id: "judul",
      label: "Judul Barang",
      isOptional: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.JOS,
      ],
    },
    {
      id: "footer",
      isOptional: true,
      label: "Footer",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.STOCK,
      ],
    },
    {
      id: "spesifikasi.panjang",
      label: "Panjang (mm)",
      type: "number",
      isOptional: true,
      isCallRecalculateCb: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.SERVICE,
      ],
      suffix: {
        id: "spesifikasi.lebar",
        label: "Lebar (mm)",
        isCallRecalculateCb: true,
        type: "number",
        isOptional: true,
        isHalfSize: true,
      },
    },

    {
      id: "spesifikasi.luas",
      label: "Luas",
      type: "number",
      disabled: true,
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.SERVICE,
      ],
      suffix: {
        id: "qty",
        label: "Kuantitas",
        type: "number",
        isCallRecalculateCb: true,
        formRule:
          tipe === PenawaranTypeEnum.SAMPLE
            ? (data, detail) => {
                const maxValue = 1 / (data.spesifikasi?.luas ?? 1)
                return {
                  max: {
                    value: maxValue,
                    message: `Qty sample tidak boleh lebih dari ${formatNumber(
                      maxValue
                    )}${!!data.spesifikasi?.luas ? " (qty * luas < 1)" : ""}`,
                  },
                }
              }
            : undefined,
        isHalfSize: true,
      },
    },
    {
      id: "qty",
      type: "number",
      isIgnored: true,
      dataViewLabel: "Kuantitas",
      enabledAt: [PenawaranTypeEnum.JOS],
    },
    {
      id: "qty",
      label: "Kuantitas",
      type: "number",
      value: tipe === PenawaranTypeEnum.JOS ? 1 : undefined,
      dataViewLabel: tipe === PenawaranTypeEnum.JOS ? undefined : "Kuantitas",
      formRule:
        tipe === PenawaranTypeEnum.SAMPLE
          ? (data, detail) => {
              const maxValue = 1 / (data.spesifikasi?.luas ?? 1)
              return {
                max: {
                  value: maxValue,
                  message: `Qty sample tidak boleh lebih dari ${formatNumber(
                    maxValue
                  )}${!!data.spesifikasi?.luas ? " (qty * luas < 1)" : ""}`,
                },
              }
            }
          : undefined,

      enabledAt: [PenawaranTypeEnum.JOS, PenawaranTypeEnum.KHUSUS],
    },
    {
      id: "satuan.kode",
      type: "async-select",
      resourceUrl: "satuanBarang",
      resourceMapper: satuanBarangMapper,
      label: "Satuan",
      enabledAt: [
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.SERVICE,
      ],
      suffix: {
        id: "blank",
        type: "hidden",
        isOptional: true,
        isHalfSize: true,
      },
    },

    {
      id: "hargaJual",
      label: "Harga Jual",
      type: "number",
      isCallRecalculateCb: true,
      formRule: (data) => {
        const hargaBeli = strToSafeNum(data.hargaBeli, 1)
        const minHargaJual = !data.barang?.isDead
          ? +data.minHargaJualMultiplier * hargaBeli
          : 0
        return {
          min: {
            value: minHargaJual,
            message: `Harga jual minimal > ${formatNumber(minHargaJual)}`,
          },
        }
      },
      enabledAt: [
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.SERVICE,
      ],
      isDataView: tipe === PenawaranTypeEnum.SERVICE,
      customOnChange: (data, setValue, getValue) => {
        if (+data === 1 && tipe === PenawaranTypeEnum.STOCK) {
          hargaJualTimeout = setTimeout(() => {
            setValue(
              "hargaJual",
              +(
                strToSafeNum(getValue("minHargaJualMultiplier")) *
                  strToSafeNum(getValue("hargaBeli"), 1) +
                0.001
              ).toFixed(2)
            )
          }, 1200)
        } else {
          clearTimeout(hargaJualTimeout)
        }
      },
      suffix: {
        isHalfSize: true,
        id: "hargaJualTotal",
        label: "Harga Jual Total",
        disabled: true,
        type: "number",
      },
    },
    {
      id: "hargaJualRp",
      label: "Harga Jual",
      type: "number",
      customOnChange: (
        data,
        setValue,
        getValue,
        genCode,
        globVars,
        trigger,
        globDetail
      ) => {
        setValue(
          "hargaJual",
          (+data / +globDetail.rate).toFixed(4).replace(".0000", "")
        )
      },
      suffix: {
        id: "hargaJual",
        label: "Konversi",
        type: "number",
        disabled: true,
        isHalfSize: true,
      },
      enabledAt: [PenawaranTypeEnum.INDENT],
    },
    {
      id: "hargaBeli",
      label: "Harga Beli",
      value: 0,
      type: (data, user) =>
        tipe === PenawaranTypeEnum.STOCK
          ? "hidden"
          : [PenawaranTypeEnum.INDENT, PenawaranTypeEnum.TTLC].includes(tipe) &&
            !user?.organisasi.isPusat
          ? "hidden"
          : "number",
      isDataView: (user) =>
        [PenawaranTypeEnum.TTLC, PenawaranTypeEnum.INDENT].includes(tipe) &&
        !!user?.organisasi.isPusat,
      renderCondition(formData, formMode, filterData) {
        return (
          (tipe === PenawaranTypeEnum.SERVICE &&
            !!filterData?.["isSupplier"]) ||
          tipe !== PenawaranTypeEnum.SERVICE
        )
      },
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.SERVICE,
      ],
      suffix: {
        id: "blank",
        type: "hidden",
        isOptional: true,
        isHalfSize: true,
      },
    },
    {
      id: "amount",
      isDataView: true,
      label: "Amount",
      type: "ignored",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.STOCK,
      ],
      columnRender(data) {
        return (
          // @ts-ignore
          <Text fontSize={14} fontWeight="600">
            {formatNumber(+data.qty * +data.hargaJual)}
          </Text>
        )
      },
    },
    {
      id: "hargaDisarankanSatuan",
      type: "hidden",
      enabledAt: [PenawaranTypeEnum.KHUSUS, PenawaranTypeEnum.STOCK],
    },
    {
      id: "hargaDisarankanSatuan",
      label: "Harga Disarankan",
      disabled: true,
      type: "number",
      suffix: {
        isHalfSize: true,
        id: "hargaDisarankan",
        label: "Harga Disarankan Total",
        type: "number",
        disabled: true,
        isDataView: ![PenawaranTypeEnum.SERVICE].includes(tipe),
        renderCondition(formData, formMode, filterData) {
          return (
            (tipe === PenawaranTypeEnum.SERVICE &&
              !filterData?.["isSupplier"]) ||
            tipe !== PenawaranTypeEnum.SERVICE
          )
        },
        value: 0,
      },
      enabledAt: [
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.STOCK,
        PenawaranTypeEnum.SERVICE,
      ],
    },
    {
      id: "hargaService",
      type: "ignored",
      isDataView: true,
      label(data, filter) {
        return !!filter?.["isSupplier"] ? "Harga Beli" : "Harga Disarankan"
      },
      columnRender(data, filter, globVars) {
        return (
          <TableCell>
            {formatNumber(
              !!filter?.["isSupplier"] ? data?.hargaBeli : data?.hargaDisarankan
            )}
          </TableCell>
        )
      },
      enabledAt: [PenawaranTypeEnum.SERVICE],
    },
    {
      id: "isJosAda",
      label: "JOS",
      type: "check",
      renderCondition(formData, formMode, filter) {
        return filter?.["divisi.id"] === "1"
      },
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.KHUSUS,
        PenawaranTypeEnum.STOCK,
      ],
    },
    {
      id: "hargaJos",
      type: "number",
      label: "Harga JOS",
      isDataView: tipe === PenawaranTypeEnum.JOS,
      value: 0,
      renderCondition:
        tipe === PenawaranTypeEnum.JOS
          ? undefined
          : (data, formMode, filter) =>
              !!data.isJosAda && filter?.["divisi.id"] === "1",
      enabledAt: [
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.JOS,
        PenawaranTypeEnum.STOCK,
      ],
    },
    {
      id: "pilihan",
      label: "Hidden",
      type: "radio",
      options: genOptionFromArray(["INCLUDE", "HIDDEN", "TERPISAH"]),
      renderCondition:
        tipe === PenawaranTypeEnum.JOS
          ? undefined
          : (data, formMode, filter) =>
              !!data.isJosAda && filter?.["divisi.id"] === "1",
      enabledAt: [PenawaranTypeEnum.KHUSUS, PenawaranTypeEnum.STOCK],
    },
    {
      id: "jenisSpesifikasi",
      label: "Spesifikasi",
      type: "radio",
      renderCondition(formData, formMode, filter) {
        return filter?.["divisi.id"] === "1"
      },
      options: genOptionFromArray([
        "OPEN",
        "OPEN_SKIVED",
        "OPEN_FINGER",
        "ENDLESS",
        "ENDLESS_CLIPPER",
      ]),
      enabledAt: [PenawaranTypeEnum.KHUSUS, PenawaranTypeEnum.STOCK],
    },
    {
      id: "harga",
      disabled: true,
      label: "Harga",
      type: "number",
      enabledAt: [PenawaranTypeEnum.KHUSUS],
    },
    // TODO: remove from db
    {
      id: "jenisHarga",
      type: "radio",
      label: "Jenis Harga",
      options: [
        {
          label: "Normal/SQM",
          value: "NORMAL",
        },
        ...genOptionFromArray(["METER", "PIECES"]),
      ],
      enabledAt: [],
    },
    // TODO: remove from db
    {
      id: "tipeHidden",
      label: "Tipe Hidden",
      type: "radio",
      options: genOptionFromArray(["HIDDEN_1", "HIDDEN_2", "TIDAK"]),
      enabledAt: [],
    },
    {
      id: "keterangan",
      label: "Keterangan",
      type: "text-area",
      isOptional: true,
      enabledAt: [
        PenawaranTypeEnum.PINJAMAN,
        PenawaranTypeEnum.SAMPLE,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
      ],
    },
    {
      id: "isHargaTermasukService",
      label: "Harga termasuk Service",
      type: "check",
      value: false,
      enabledAt: [PenawaranTypeEnum.SERVICE],
    },
  ]

  return items
    .filter((i: SpesificModuleFormItem<PenawaranTypeEnum>) =>
      i.enabledAt.includes(tipe)
    )
    .map(({ enabledAt, ...data }) => data)
}

export default generateItemPenawaranFormItems
