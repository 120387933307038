import { getAPI } from "hooks"

export interface HargaBarang {
  hargaBeli: number
  hargaJual: number
}

export interface HppBarang {
  nilaiRupiah: number
  rerataRupiah: number
}

export const getHargaBarang = async (
  barangId: number
): Promise<HargaBarang> => {
  const hargaBarang = await getAPI<any>(`/harga-barang/${barangId}`, null)
  return {
    hargaBeli: hargaBarang?.hargaBeli ?? 0,
    hargaJual: hargaBarang?.hargaJual ?? 0,
  }
}

export const getHppBarang = async (barangId: number): Promise<HppBarang> => {
  const hppBarang = await getAPI<any>(`/hpp/barang/${barangId}`, null)

  return {
    nilaiRupiah: hppBarang?.nilaiRupiah ?? 0,
    rerataRupiah: hppBarang?.rerataRupiah ?? 0,
  }
}
