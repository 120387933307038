import { Box } from "@chakra-ui/react"
import Paper from "components/container/paper"
import SubTitle from "components/text/sub-title"
import Title from "components/text/title"
import { useScreenDetector } from "hooks/device"
import { ReactElement } from "react"

interface ModuleDashboardProps {
  title: string
  filter?: ReactElement
  dataView: ReactElement
  additionalView?: ReactElement
}

const ModuleDashboard: React.FC<ModuleDashboardProps> = ({
  title,
  filter,
  dataView,
  additionalView,
}) => {
  const { isMobile } = useScreenDetector()
  return (
    <Box mt="0" pr="12px" pt={isMobile ? "8px" : 0}>
      <Title size={isMobile ? "sm" : "md"}>{title}</Title>
      {!!filter && (
        <>
          <Paper>
            <SubTitle>Filter</SubTitle>
            <Box
              border="1px solid #E7EEF7"
              borderRadius="12px"
              px="24px"
              pt="12px"
              pb="18px"
              mt="6px"
            >
              {filter}
            </Box>
          </Paper>
          <Box h="16px" />
        </>
      )}
      {additionalView && (
        <>
          {additionalView}
          <Box h="16px" />
        </>
      )}
      <Paper pb="16px">{dataView}</Paper>
    </Box>
  )
}

export default ModuleDashboard
