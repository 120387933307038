import {
  generateCrudUrl,
  genFieldQueryParamObj,
  genFieldQueryParamStr,
  genOptionFromArray,
  getToday,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import {
  mataUangOptionMapper,
  mataUangSearchField,
  perkiraanMapper,
  perkiraanResourceColumnKeys,
  perkiraanSearchKey,
  voucherMapper,
  voucherSearchKey,
} from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"

function generatePersetujuanKasbonBiayaImportForm(): BasePageProps<any> {
  return {
    id: "persetujuan-kasbon-biaya-import",
    title: "Persetujuan Kasbon Biaya Impor",
    permissionRequired:
      "GL_Sekawan::transaksi::pembayaran::persetujuan_kasbon_biaya_import",
    urls: {
      ...generateCrudUrl("kasbon-biaya-import"),
      create: {
        endpoint: "/kasbon-biaya-import/{id}",
        method: "patch",
      },
      delete: {
        endpoint: "/kasbon-biaya-import/{id}/cancel-approval",
        method: "patch",
      },
      read: {
        method: "get",
        endpoint: `/kasbon-biaya-import`,
        param: {
          ...genFieldQueryParamObj({
            tanggalPersetujuan: "notNull",
          }),
          sortBy: "id",
          sortType: "desc",
        },
      },
    },
    cetakActions: [],
    filter: <BaseFilter keyName="periodeOnly" />,
    formGroupItems: [
      {
        id: "nomor",
        disabled: true,
        label: "Kasbon",
        renderCondition(formData, formMode) {
          return formMode === "update"
        },
        column: 1,
      },
      {
        id: "id",
        label: "Kasbon",
        resourceUrl: `kasbon-biaya-import?${genFieldQueryParamStr({
          tanggalPersetujuan: "null",
        })}&sortBy=id&sortType=desc`,
        column: 1,
        type: "async-table-select",
        dataViewLabel: "Nomor",
        dataViewKey: "nomor",
        renderCondition: (data, formMode) => formMode === "create",
        resourceColumnLabelKey: "nomor",
        resourceColumn: [
          {
            dataKey: "nomor",
            label: "Nomor",
          },
          {
            dataKey: "tanggal",
            label: "Tanggal",
          },
          {
            dataKey: "jumlah",
            label: "Jumlah Kasbon",
            type: "number",
          },
          {
            dataKey: "keterangan",
            label: "Keterangan",
          },
        ],
        customOnChange: (data, setValue) => {
          setValue("id", data?.id)
          setValue("tanggal", data?.tanggal)
          setValue("jumlah", data?.jumlah)
          setValue("keterangan", data?.keterangan)
          setValue("nomor", data?.nomor)
          setValue("mataUang", data?.mataUang)
        },
      },
      {
        id: "tanggal",
        label: "Tanggal",
        type: "date",
        isDataView: true,
        disabled: true,
        value: getToday(),
      },
      {
        id: "isPersetujuan",
        value: true,
        type: "hidden",
      },
      {
        id: "jumlah",
        label: "Jumlah",
        type: "number",
        dataViewLabel: "Jumlah Kasbon",
        disabled: true,
        suffix: {
          id: "mataUang.mataUang",
          label: "Mata Uang",
          disabled: true,
        },
      },
      {
        id: "keterangan",
        label: "Keterangan",
        isDataView: true,
        disabled: true,
        type: "text-area",
      },

      {
        id: "voucher.jenis",
        disabled: true,
        label: "Jenis",
        type: "radio",
        options: genOptionFromArray(["DEBIT", "KREDIT"]),
        suffix: {
          id: "voucher.isUsingPpn",
          type: "check",
          disabled: true,
          label: "PPN",
        },
      },
      {
        id: "voucher.id",
        label: "Jenis Voucher",
        type: "async-select",
        resourceUrl: `voucher?${genBaseSortQueryParam(
          "kodeVoucher"
        )}&${genFieldQueryParamStr({
          "organisasi.kode": "B0",
          jenis: "KREDIT",
          "$or_isJurnalPenyesuaian||isJurnalPenyesuaian": "false||null",
        })}`,
        dataViewLabel: "Voucher",
        dataViewKey: "voucher.kodeVoucher",
        resourceMapper: voucherMapper,
        searchBy: voucherSearchKey,
        customOnChange: (data, setValue, getValue) => {
          setValue("voucher", data)
          if (!!data?.perkiraan?.id) {
            setValue("perkiraan", data?.perkiraan)
          } else {
            setValue("perkiraan.id", null)
          }
        },
      },
      {
        id: "perkiraan.id",
        label: "Nomor Perkiraan",
        type: "async-select",
        resourceUrl: `perkiraan?${genFieldQueryParamStr({
          "$or_organisasi.kode||organisasi.id": "B0||null",
        })}`,
        resourceColumnLabelKey: perkiraanResourceColumnKeys,
        disabled: (data, detail) => {
          return !!data?.voucher?.perkiraan?.nomorPerkiraan
        },
        resourceMapper: perkiraanMapper,
        searchBy: perkiraanSearchKey,
      },
      {
        id: "tanggalPersetujuan",
        label: "Tanggal Setuju",
        type: "date",
        value: getToday(),
        suffix: {
          id: "isDibayar",
          label: "Dibayar",
          type: "check",
        },
      },
    ],
  }
}

export default generatePersetujuanKasbonBiayaImportForm
