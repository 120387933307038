import { Spinner, FormLabel, Box, Flex, Checkbox, Text } from "@chakra-ui/react"
import { GeneralMap, OptionType } from "common/type"
import { basicKodeNamaMapper } from "common/util"
import { mapDataList, useGet } from "hooks"
import { useCallback, useEffect, useMemo, useState } from "react"

interface SelectMultipleItemFormProps {
  onChange: (selectedIds: string[]) => void
  resourceUrl: string
  resourceMapper?: (data: any) => OptionType
  value?: string[]
  disabled?: boolean
  baseData?: any[]
}

function SelectMultipleItemForm({
  onChange,
  resourceUrl,
  resourceMapper,
  value,
  disabled,
  baseData,
}: SelectMultipleItemFormProps) {
  const baseValue: GeneralMap<boolean> = {}
  if (!!value) {
    for (const item of value) {
      baseValue[item] = true
    }
  }
  const [selectedKeys, setSelectedKeys] =
    useState<GeneralMap<boolean>>(baseValue)

  const onSelect = useCallback((id: string, value) => {
    setSelectedKeys((prevState) => ({
      ...prevState,
      [id]: value,
    }))
  }, [])

  useEffect(() => {
    onChange(Object.keys(selectedKeys))
  }, [selectedKeys])

  const { data, isLoading } = useGet(
    resourceUrl,
    useMemo(() => {
      return (_data) => {
        return mapDataList(_data, resourceMapper || basicKodeNamaMapper)
      }
    }, [resourceMapper]),
    null,
    !resourceUrl
  )

  const isDisabled = useMemo(() => !!disabled || !data, [disabled, data])

  return (
    <>
      {isLoading ? (
        // @ts-ignore
        <Spinner size="lg" />
      ) : (
        <Box
          px="16px"
          py="8px"
          borderRadius="8px"
          border="solid 1px #E2E8F0"
          maxHeight="250px"
          minHeight="45px"
          overflow="auto"
          backgroundColor={isDisabled ? "#EFEFEF4D" : "white"}
          cursor={isDisabled ? "no-drop" : "normal"}
        >
          {data?.data?.map((item, key) => (
            // @ts-ignore
            <Flex key={key} my="4px">
              <Checkbox
                mr="8px"
                onChange={({ target: { checked } }) => {
                  onSelect(item.value, checked)
                }}
              />{" "}
              <Text>{item.label}</Text>
            </Flex>
          ))}
        </Box>
      )}
    </>
  )
}

export default SelectMultipleItemForm
