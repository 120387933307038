import { BAPBTypeEnum, GeneralMap, SpesificModuleFormItem } from "common/type"
import { genFieldQueryParamStr, getToday, strToSafeNum } from "common/util"
import { BasePageFormGroupItem } from "components/page/type"
import {
  supplierOptionMapper,
  supplierResourceColumnKey,
  supplierSearchKey,
} from "constant/mapper"

function generateBAPBFormItems(tipe: BAPBTypeEnum): BasePageFormGroupItem[] {
  const items: SpesificModuleFormItem<BAPBTypeEnum>[] = [
    {
      id: "tipe",
      label: "Tipe",
      type: "hidden",
      value: tipe,
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
    },
    {
      id: "supplier.id",
      label: "Supplier",
      type: "async-select",
      resourceUrl: "supplier",
      resourceMapper: supplierOptionMapper,
      searchBy: supplierSearchKey,
      resourceColumnLabelKey: supplierResourceColumnKey,
      enabledAt: [BAPBTypeEnum.STOCK],
    },
    {
      id: "organisasi.id",
      label: "Cabang",
      type: "hidden",
      resourceUrl: `organisasi`,
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
    },
    {
      id: "divisi.id",
      label: "Divisi",
      type: "hidden",
      resourceUrl: `divisi`,
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
    },
    {
      id: "rate",
      label: "Kurs",
      type: "number",
      disabled: true,
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
      suffix: {
        id: "blank",
        type: "hidden",
        isHalfSize: true,
        isOptional: true,
      },
    },
    {
      id: "no",
      label: "Nomor BAPB",
      isDataView: true,
      isGeneratedCodeField: true,
      disabled: true,
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
      suffix: {
        id: "tanggal",
        label: "Tanggal",
        type: "date",
        isDataView: true,
        value: getToday(),
        isHalfSize: true,
      },
    },
    {
      id: "modeOfShipment",
      label: "Mode Of Shipment",
      dataViewLabel: "Keterangan",
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
      suffix: {
        id: "tanggalJatuhTempo",
        label: "Tanggal Jatuh Tempo",
        type: "date",
        value: getToday(),
        isHalfSize: true,
      },
    },
    {
      id: "noInvoice",
      label: "Nomor Invoice Supplier",
      dataViewLabel: "Dokumen",
      isDataView: true,
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
    },
    {
      id: "beaMasuk",
      label: "Bea Masuk",
      type: "number",
      enabledAt: [BAPBTypeEnum.SERVICE],
    },
    {
      id: "packing",
      label: "Packing",
      value: 0,
      type: "number",
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
      suffix: {
        id: "fobCharge",
        label: "FOB Charge",
        value: 0,
        type: "number",
        isHalfSize: true,
      },
    },

    {
      id: "freight",
      label: "Freight",
      type: "number",
      value: 0,
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
      customOnChange: (data, setValue) => {
        setValue("freightHidden", data)
      },
      suffix: {
        id: "insurance",
        label: "Insurance",
        type: "number",
        value: 0,
        isHalfSize: true,
      },
    },
    // Use to track freight change if user ever edit value the add new item
    {
      id: "freightHidden",
      type: "hidden",
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
    },
  ]

  return items
    .filter((i: SpesificModuleFormItem<BAPBTypeEnum>) =>
      i.enabledAt.includes(tipe)
    )
    .map(({ enabledAt, ...data }) => data)
}

function getItemBapbsBiayaTotal(
  data: any,
  type: "packing" | "freight" | "insurance"
): number {
  let total = 0
  const usedPoId: GeneralMap<boolean> = {}

  for (const itemBapb of data.itemBapbs ?? []) {
    const po = itemBapb.itemPurchaseOrderStock?.purchaseOrderStock
    if (!usedPoId[po.id]) {
      total += strToSafeNum(po?.[type])
      usedPoId[po.id] = true
    }
  }

  return total
}
export default generateBAPBFormItems
