import { Text } from "@chakra-ui/react"
import { ColorDict } from "constant/theme"
import { useScreenDetector } from "hooks/device"

const SubTitle: React.FC = ({ children }) => {
  const { isMobile } = useScreenDetector()
  return (
    // @ts-ignore
    <Text
      fontSize={isMobile ? "16px" : "20px"}
      color={ColorDict.primary}
      fontWeight={700}
    >
      {children}
    </Text>
  )
}

export default SubTitle
