import { Text } from "@chakra-ui/react"
import { SpesificModuleFormItem, SuratJalanInternalTypeEnum } from "common/type"
import {
  formatNumber,
  genFieldQueryParamStr,
  getToday,
  strToSafeNum,
} from "common/util"
import { BasePageFormGroupItem } from "components/page/type"
import { genBaseSortQueryParam } from "constant/url"

function generateSuratJalanInternalFormItems(
  tipe: SuratJalanInternalTypeEnum
): BasePageFormGroupItem[] {
  const items: SpesificModuleFormItem<SuratJalanInternalTypeEnum>[] = [
    {
      id: "typeSuratJalan",
      value: tipe,
      type: "hidden",
      enabledAt: [
        SuratJalanInternalTypeEnum.STOCK,
        SuratJalanInternalTypeEnum.SPPB,
      ],
    },
    {
      id: "bapb.id",
      label: "BAPB",
      type: "async-table-select",
      resourceUrl: (data, filter) => {
        return `bapb?${genFieldQueryParamStr({
          tipe:
            tipe === SuratJalanInternalTypeEnum.STOCK ? "BARANG" : "SERVICE",
          "itemBapbs.sisaBarang_$gt": 0,
        })}`
      },
      column: 1,
      // resourceColumnLabelKey: "no",
      customOnChange: (data, setValue) => {
        setValue("bapb", data)
      },
      resourceColumn: [
        {
          dataKey: "no",
          label: "Nomor BAPB",
        },
        {
          dataKey: "tanggal",
          label: "Tanggal",
        },
        {
          dataKey: "keterangan",
          label: "Keterangan",
        },
        {
          dataKey: "noInvoice",
          label: "Nomor Invoice Supplier",
        },
        {
          dataKey: "supplier.namaPanjang",
          label: "Supplier",
        },
        {
          dataKey: "sisaBarang",
          label: "Sisa",
          render: (data) => {
            return (
              // @ts-ignore
              <Text fontWeight={600} fontSize="13px">
                {formatNumber(
                  data.itemBapbs?.reduce(
                    (total: number, item: any) =>
                      total + strToSafeNum(item.sisaBarang),
                    0
                  ) ?? 0
                )}
              </Text>
            )
          },
        },
      ],
      enabledAt: [SuratJalanInternalTypeEnum.STOCK],
    },
    {
      id: "bapb.organisasi.id",
      type: "hidden",
      enabledAt: [SuratJalanInternalTypeEnum.STOCK],
    },
    {
      id: "permintaanPengirimanBarang.id",
      label: "Surat Permintaan Pengiriman Barang",
      type: "async-table-select",
      resourceUrl: (data, filterData) => {
        const orgId = filterData["asal.id"]
        const divisiId = filterData["permintaanPengirimanBarang.divisi.id"]
        if (!orgId || !divisiId) return ""
        return `permintaan-pengiriman-barang?${genFieldQueryParamStr({
          "organisasiTujuan.id": orgId,
          "divisi.id": divisiId,
          totalSisaSji_$gt: 0.0001,
        })}&${genBaseSortQueryParam("id", false)}`
      },
      column: 1,
      resourceColumn: [
        {
          dataKey: "no",
          label: "No.",
        },
        {
          dataKey: "organisasiAsal.nama",
          label: "Cabang",
        },
        {
          dataKey: "tanggal",
          label: "Tanggal",
        },
        {
          dataKey: "sales.nama",
          label: "SE",
        },
      ],
      customOnChange: (data, setValue) => {
        setValue("permintaanPengirimanBarang", data)
        setValue("asal", data.organisasiTujuan)
        setValue("tujuan", data.organisasiAsal)
      },
      enabledAt: [SuratJalanInternalTypeEnum.SPPB],
    },
    {
      id: "permintaanPengirimanBarang.divisi.nama",
      label: "Divisi",
      disabled: true,
      enabledAt: [SuratJalanInternalTypeEnum.SPPB],
    },
    {
      id: "bapb.tanggal",
      disabled: true,
      label: "Tgl. BAPB",
      suffix: {
        id: "bapb.modeOfShipment",
        disabled: true,
        isIgnored: true,
        label: "Keterangan BAPB",
        isHalfSize: true,
      },
      enabledAt: [SuratJalanInternalTypeEnum.STOCK],
    },
    {
      id: "bapb.noInvoice",
      disabled: true,
      label: "Dokumen",
      suffix: {
        id: "bapb.supplier.namaPanjang",
        disabled: true,
        isIgnored: true,
        label: "Supplier",
        isHalfSize: true,
      },
      enabledAt: [SuratJalanInternalTypeEnum.STOCK],
    },
    {
      id: "no",
      isGeneratedCodeField: true,
      label: "Nomor",
      dataViewLabel: "Nomor Surat Jalan",
      disabled: true,
      enabledAt: [
        SuratJalanInternalTypeEnum.STOCK,
        SuratJalanInternalTypeEnum.SPPB,
      ],
      suffix: {
        id: "tanggal",
        type: "date",
        label: "Tanggal",
        isDataView: true,
        value: getToday(),
        isHalfSize: true,
      },
    },
    {
      id: "bapb.no",
      isDataView: true,
      isIgnored: true,
      label: "No. BAPB",
      enabledAt: [SuratJalanInternalTypeEnum.STOCK],
    },

    {
      id: "asal.id",
      type: "async-select",
      label: "Cabang Asal",
      resourceUrl: "organisasi?filterFields=jenis&filterValues=CABANG",
      dataViewKey: "tujuan.nama",
      isDataView: tipe !== SuratJalanInternalTypeEnum.SPPB,
      enabledAt: [SuratJalanInternalTypeEnum.SPPB],
    },
    {
      id: "tujuan.kode",
      type: "ignored",
      label: "Kode",
      isDataView: true,
      enabledAt: [
        SuratJalanInternalTypeEnum.STOCK,
        SuratJalanInternalTypeEnum.SPPB,
      ],
    },
    {
      id: "tujuan.id",
      label:
        SuratJalanInternalTypeEnum.SPPB === tipe ? "Cabang Tujuan" : "Cabang",
      type: "async-select",
      dataViewKey: "tujuan.nama",
      isDataView: true,
      resourceUrl: `organisasi?${genBaseSortQueryParam()}`,
      enabledAt: [
        SuratJalanInternalTypeEnum.STOCK,
        SuratJalanInternalTypeEnum.SPPB,
      ],
      // disabled: tipe !== SuratJalanInternalTypeEnum.SPPB,
      customOnChange: (data, setValue) => {
        console.log({ tujuan: data })
        setValue("tujuan", data)
        if (!data.isPusat) {
          setValue("lokasiGudang.id", null)
        }
      },
    },
    {
      id: "tipePenawaran",
      type: "hidden",
      value: tipe,
      enabledAt: [SuratJalanInternalTypeEnum.STOCK],
    },
    {
      id: "catatan",
      label: "Note",
      isOptional: true,
      type: "text-area",
      isDataView: true,
      enabledAt: [
        SuratJalanInternalTypeEnum.STOCK,
        SuratJalanInternalTypeEnum.SPPB,
      ],
    },
    {
      id: "lokasiGudang.id",
      label: "Lokasi Gudang Pusat",
      type: "async-select",
      disabled: (data) => {
        console.log("lok", { data })
        return !data.tujuan?.isPusat
      },
      resourceUrl: `organisasi?${genFieldQueryParamStr({
        isGudangPusat: true,
      })}&${genBaseSortQueryParam()}`,
      enabledAt: [SuratJalanInternalTypeEnum.STOCK],
    },
    {
      id: "jlhCetak",
      type: "number",
      label: "Jumlah Cetak",
      isIgnored: true,
      isDataView: true,
      enabledAt: [SuratJalanInternalTypeEnum.SPPB],
    },
  ]

  return items
    .filter((i: SpesificModuleFormItem<SuratJalanInternalTypeEnum>) =>
      i.enabledAt.includes(tipe)
    )
    .map(({ enabledAt, ...data }) => data)
}

export default generateSuratJalanInternalFormItems
