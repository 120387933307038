import InfoPage from "components/page/info-page"
import { InformasiPages } from "constant/form-pages/informasi"
import { InformasiGLPages } from "constant/form-pages/informasi-gl"
import { LaporanPages } from "constant/form-pages/laporan"

const InformationPageRenderer =
  (menuName: string): React.FC =>
  () => {
    return (
      <InfoPage
        {...{
          ...InformasiPages,
          ...InformasiGLPages,
          ...LaporanPages,
        }[menuName]}
      />
    )
  }

export default InformationPageRenderer
