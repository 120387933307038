import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { ACCESS_TOKEN_KEY } from "constant"

const AuthenticatedPage: React.FC = ({ children }) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem(ACCESS_TOKEN_KEY)) {
      navigate("/login", { replace: true })
    }
  }, [navigate])
  return <>{children}</>
}

export default AuthenticatedPage
