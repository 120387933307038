export async function validateOnAddItemPermohonanPembayaran(
  formData: any,
  globData: any
): Promise<string | undefined> {
  if (
    +formData.jumlah < +formData.purchaseOrder?.nilaiPo &&
    !formData.isUangMuka &&
    !formData.isPenyelesaianUangMuka
  ) {
    return "Pembayaran tidak bisa partial jika bukan uang muka atau penyelesaian uang muka!"
  }

  if (
    +formData.jumlah >= +formData.purchaseOrder?.nilaiPo &&
    (!!formData.isUangMuka || !!formData.isPenyelesaianUangMuka)
  ) {
    return "Pembayaran full, uang muka atau penyelesaian uang muka tidak boleh dicentang"
  }

  if (!!formData.isUangMuka && !!formData.isPenyelesaianUangMuka) {
    return "Uang muka dan penyelesaian uang muka tidak bisa dicentang bersamaan"
  }

  const uangMuka = formData.purchaseOrder?.itemPermohonanPembayarans?.find(
    (i: any) => !!i.isUangMuka
  )
  if (!formData.id && !!uangMuka && !!formData.isUangMuka) {
    return "Uang muka untuk po ini sudah ada"
  }
}

export async function validateOnAddItemPembayaranKomisi(
  formData: any,
  globData: any
) {
  const totalHutang = globData.itemPermohonanPembayarans?.reduce(
    (total: number, i: any) => {
      return total + +i.jumlah
    },
    0
  )

  const totalKomisi =
    globData.pembayaranKomisis?.reduce((total: number, i: any) => {
      return total + +i.jumlahYangDibayar
    }, 0) + +formData.jumlahYangDibayar

  if (totalKomisi > totalHutang) {
    return "Total pembayaran dari komisi tidak boleh lebih besar dari total hutang"
  }
}
