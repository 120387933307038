import { genFieldQueryParamStr, getToday, strToSafeNum } from "common/util"
import { TableCell } from "components/data-table"
import { BasePageFormGroupItem } from "components/page/type"
import {
  karyawanOptionMapper,
  karyawanResourceColumnKey,
  pelangganOptionMapper,
  pelangganResourceColumnKeys,
  pelangganSearchKeys,
} from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"
import dayjs from "dayjs"

function generateKonsinyasiFormItems(): BasePageFormGroupItem[] {
  return [
    {
      id: "organisasi.id",
      label: "Cabang",
      type: "async-select",
      resourceUrl: `organisasi`,
      customOnChange: (data, setValue) => {
        setValue("organisasi", data)
      },
      suffix: {
        id: "divisi.id",
        label: "Divisi",
        type: "async-select",
        resourceUrl: `divisi`,
        isHalfSize: true,
      },
    },
    {
      id: "organisasi.isWorkshop",
      type: "hidden",
    },
    {
      id: "isMmub",
      label: "MMUB",
      type: "check",
    },
    {
      id: "no",
      label: "Nomor",
      isDataView: true,
      isGeneratedCodeField: true,
      disabled: true,
      suffix: {
        id: "tanggal",
        type: "date",
        label: "Tanggal",
        value: getToday(),
        isHalfSize: true,
      },
    },
    {
      id: "pelanggan.id",
      label: "Customer",
      type: "async-select",
      resourceColumnLabelKey: pelangganResourceColumnKeys,
      resourceUrl: (data, filter) =>
        `pelanggan?${genFieldQueryParamStr({
          "organisasi.id": filter?.["organisasi.id"],
        })}&${genBaseSortQueryParam("namaPanjang")}`,
      resourceMapper: pelangganOptionMapper,
      searchBy: pelangganSearchKeys,
      dataViewKey: "pelanggan.namaPanjang",
      dataViewLabel: "Nama Customer",
    },
    {
      id: "sales.id",
      type: "async-select",
      resourceUrl: (data, filter) =>
        `karyawan?${genFieldQueryParamStr({
          jenisJabatan: "SALES",
          "organisasi.id": filter?.["organisasi.id"],
          "divisi.id": filter?.["divisi.id"],
        })}&${genBaseSortQueryParam()}`,
      resourceMapper: karyawanOptionMapper,
      resourceColumnLabelKey: karyawanResourceColumnKey,
      dataViewKey: "sales.nama",
      dataViewLabel: "Nama Sales",
      label: "Sales",
      suffix: {
        id: "workshop.id",
        label: "Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({
          isWorkshop: true,
        })}`,
        customOnChange: (data, setValue) => {
          setValue("workshop", data)
        },
        isHalfSize: true,
      },
    },
    {
      id: "teknisi.id",
      type: "async-select",
      disabled(data, detailData, formMode, globVars) {
        return !data.workshop?.id
      },
      resourceColumnLabelKey: karyawanResourceColumnKey,
      resourceUrl: (data, filter) =>
        `karyawan?${genFieldQueryParamStr({
          jenisJabatan: "TEKNISI",
          "organisasi.id": data.workshop?.id,
          "divisi.id": filter?.["divisi.id"],
        })}&${genBaseSortQueryParam()}`,
      resourceMapper: karyawanOptionMapper,
      dataViewKey: "teknisi.nama",
      dataViewLabel: "Nama Teknisi",
      label: "Teknisi",
      suffix: {
        id: "",
        type: "blank",
        isOptional: true,
        isHalfSize: true,
      },
    },
    {
      id: "noPoPelanggan",
      label: "No. PO",
      isOptional: true,
    },
    {
      id: "keterangan",
      type: "text-area",
      label: "Keterangan",
      isOptional: true,
    },
    {
      id: "lama",
      isIgnored: true,
      dataViewLabel: "Lama",
      columnRender(data, filterData, globVars) {
        let display = " "
        const dateKonsinyasi = dayjs(data.tanggal)
        const datePenawaran = !data.penawarans?.[0]?.tanggal
          ? dayjs()
          : dayjs(data.penawarans?.[0]?.tanggal)

        return (
          <TableCell>
            {datePenawaran.diff(dateKonsinyasi, "days").toString()}
          </TableCell>
        )
      },
    },
  ]
}

export default generateKonsinyasiFormItems
