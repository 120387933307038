import generatePenawaranForms from "common/form-generator/penawaran"
import generatePurchaseOrderForm from "common/form-generator/purchase-order"
import generateSalesContractForm from "common/form-generator/sales-contract"
import { GeneralMap, PenawaranTypeEnum, PoTypeEnum } from "common/type"
import {
  generateCrudUrl,
  genFieldQueryParamStr,
  genFormItems,
  getToday,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import { supplierOptionMapper, supplierSearchKey } from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"

export const ServicePages: GeneralMap<BasePageProps<any>> = {
  // "tanda-terima": {
  //   title: "Tanda Terima Service",
  //   urls: generateCrudUrl("tanda-terima-service"),
  //   permissionRequired: "TandaTerimaService",
  //   filter: <BaseFilter keyName="service" />,
  //   formGroupItems: genFormItems([
  //     {
  //       id: "no",
  //       label: "Nomor Tanda Terima",
  //       disabled: true,
  //       generateCodeParam: (data) => {
  //         if (!data.organisasi?.id) return undefined
  //         return {
  //           entityName: "TandaTerimaService",
  //           entity: { organisasiId: data.organisasi?.id },
  //         }
  //       },
  //       isDataView: true,
  //     },
  //     {
  //       id: "tanggalTerima",
  //       label: "Tanggal Terima",
  //       type: "date",
  //       value: getToday(),
  //       isDataView: true,
  //     },
  //     {
  //       id: "organisasi.id",
  //       label: "Nama Cabang",
  //       type: "async-select",
  //       resourceUrl: `organisasi?${genFieldQueryParamStr({
  //         jenis: "CABANG",
  //       })}`,
  //     },
  //     {
  //       id: "sales.id",
  //       label: "Nama Sales",
  //       type: "async-select",
  //       resourceUrl: `karyawan?${genFieldQueryParamStr({
  //         jenisJabatan: "SALES",
  //       })}`,
  //       dataViewKey: "sales.nama",
  //       isDataView: true,
  //     },
  //     {
  //       id: "pelanggan.id",
  //       label: "Nama Pelanggan",
  //       type: "async-select",
  //       resourceUrl: "pelanggan",
  //       resourceMapper: pelangganOptionMapper,
  //       dataViewKey: "pelanggan.namaPanjang",
  //       isDataView: true,
  //     },
  //     {
  //       id: "includeKePenawaran",
  //       type: "check",
  //       label: "Include ke Penawaran",
  //     },
  //     {
  //       id: "catatan",
  //       label: "Catatan",
  //       type: "text-area",
  //     },
  //   ]),
  //   additionalForms: [
  //     {
  //       id: "itemTandaTerimaServices",
  //       title: "Barang",
  //       formItems: [
  //         {
  //           id: "barang.id",
  //           label: "Nama Barang",
  //           type: "async-select",
  //           resourceUrl: `barang`,
  //           customOnChange: (data, setValue) => {
  //             setValue("barang", data)
  //           },
  //           dataViewKey: "barang.nama",
  //           isDataView: true,
  //         },
  //         {
  //           id: "qty",
  //           label: "Jumlah",
  //           type: "number",
  //           isDataView: true,
  //         },
  //         {
  //           id: "keterangan",
  //           label: "Keterangan",
  //           isDataView: true,
  //         },
  //         {
  //           id: "jenisService",
  //           type: "radio",
  //           label: "Jenis Service",
  //           options: genOptionFromArray(["NORMAL", "GARANSI"]),
  //         },
  //         {
  //           id: "jenisRusak",
  //           type: "radio",
  //           label: "Jenis Rusak",
  //           options: genOptionFromArray([
  //             "NORMAL",
  //             "CUSTOMER",
  //             "SALES",
  //             "TEKNISI",
  //             "OMS",
  //           ]),
  //         },
  //       ],
  //     },
  //   ],
  // },
  // "estimasi-biaya": {
  //   title: "Estimasi Biaya Service",
  //   urls: generateCrudUrl("estimasi-biaya-service"),
  //   permissionRequired: "EstimasiBiayaService",
  //   filter: <BaseFilter keyName="service" />,
  //   customFormDataMapper: (data) => {
  //     const { _itemEstimasiBiayaServices, ...restData } = data

  //     return {
  //       ...restData,
  //       itemEstimasiBiayaServices: _itemEstimasiBiayaServices,
  //     }
  //   },
  //   formGroupItems: genFormItems([
  //     {
  //       id: "tandaTerimaService.id",
  //       label: "Nomor Tanda Terima",
  //       type: "async-table-select",
  //       resourceColumn: [
  //         {
  //           dataKey: "no",
  //           label: "Nomor Tanda Terima",
  //         },
  //         {
  //           dataKey: "tanggalTerima",
  //           label: "Tanggal",
  //         },
  //         {
  //           dataKey: "organisasi.nama",
  //           label: "Nama Cabang",
  //         },
  //       ],
  //       resourceUrl: "tanda-terima-service",
  //       customOnChange: (data, setValue, getValues) => {
  //         setValue("tandaTerimaService", data)
  //         setValue(
  //           "_itemEstimasiBiayaServices",
  //           data.itemTandaTerimaServices?.map((i: any) => {
  //             return {
  //               xId: i.id,
  //               itemTandaTerimaService: i,
  //               ongkos: i?.itemEstimasiBiayaServices?.[0]?.ongkos,
  //             }
  //           }) ?? []
  //         )
  //       },
  //       column: 1,
  //       isDataView: true,
  //       dataViewKey: "tandaTerimaService.no",
  //     },
  //     {
  //       id: "tandaTerimaService.tanggalTerima",
  //       type: "date",
  //       disabled: true,
  //       label: "Tanggal Terima",
  //       isDataView: true,
  //     },
  //     {
  //       id: "tandaTerimaService.sales.nama",
  //       label: "Nama Sales",
  //       disabled: true,
  //     },
  //     {
  //       id: "tandaTerimaService.pelanggan.namaPanjang",
  //       label: "Nama Customer",
  //       disabled: true,
  //       isDataView: true,
  //     },
  //     {
  //       id: "supplier.id",
  //       label: "Nama Supplier",
  //       type: "async-select",
  //       resourceUrl: "supplier",
  //       resourceMapper: supplierOptionMapper,
  //     },
  //     {
  //       id: "no",
  //       label: "Nomor Estimasi Service",
  //       generateCodeParam: {
  //         entityName: "EstimasiBiayaService",
  //         entity: {},
  //       },
  //       isDataView: true,
  //       disabled: true,
  //     },
  //     {
  //       id: "tanggalEstimasi",
  //       type: "date",
  //       label: "Tanggal Estimasi",
  //       value: getToday(),
  //       isDataView: true,
  //     },
  //     {
  //       id: "mataUang.mataUang",
  //       type: "async-select",
  //       label: "Mata Uang",
  //       resourceUrl: "valuta-asing",
  //       resourceMapper: mataUangOptionMapper,
  // searchBy: mataUangSearchField,
  //     },
  //     {
  //       id: "isKirimKeSupplier",
  //       label: "Kirim ke Supplier",
  //       type: "check",
  //     },
  //     {
  //       id: "keterangan",
  //       label: "Keterangan",
  //       type: "text-area",
  //     },
  //   ]),
  //   additionalForms: [
  //     {
  //       id: "_itemEstimasiBiayaServices",
  //       title: "Daftar Barang",
  //       hideAdd: true,
  //       enableEdit: true,
  //       idGenerator: (data) => {
  //         return data?.itemTandaTerimaService?.id
  //       },
  //       formItems: [
  //         {
  //           id: "itemTandaTerimaService.id",
  //           type: "hidden",
  //         },
  //         {
  //           id: "itemTandaTerimaService.barang.nama",
  //           label: "Nama Barang",
  //           isDataView: true,
  //           disabled: true,
  //         },
  //         {
  //           id: "itemTandaTerimaService.qty",
  //           label: "Jumlah",
  //           isDataView: true,
  //           type: "ignored",
  //         },
  //         {
  //           id: "ongkos",
  //           label: "Ongkos Service",
  //           type: "number",
  //           isDataView: true,
  //         },
  //         {
  //           id: "itemTandaTerimaService.keterangan",
  //           label: "Keterangan",
  //           isDataView: true,
  //           type: "ignored",
  //         },
  //       ],
  //     },
  //     {
  //       id: "sparepartItemServices",
  //       title: "Daftar Sparepart",
  //       formItems: [
  //         {
  //           id: "sparepartBarang.id",
  //           label: "Sparepart",
  //           type: "async-select",
  //           resourceUrl: "barang",
  //           dataViewLabel: "Kode",
  //           dataViewKey: "sparepartBarang.kode",
  //           customOnChange: (data, setValue) => {
  //             setValue("sparepartBarang", data)
  //           },
  //         },
  //         {
  //           id: "sparepartBarang.nama",
  //           label: "Nama Barang",
  //           isDataView: true,
  //           type: "ignored",
  //         },
  //         {
  //           id: "qty",
  //           label: "Kuantitas",
  //           type: "number",
  //           isDataView: true,
  //         },
  //         {
  //           id: "available",
  //           label: "Available",
  //           type: "number",
  //         },
  //         {
  //           id: "waste",
  //           label: "Waste",
  //           type: "number",
  //         },
  //         {
  //           id: "sample",
  //           label: "Sample",
  //           type: "number",
  //         },
  //         {
  //           id: "sparepartSpesifikasi.panjang",
  //           label: "Panjang",
  //           type: "number",
  //         },
  //         {
  //           id: "sparepartSpesifikasi.lebar",
  //           label: "Lebar",
  //           type: "number",
  //         },
  //       ],
  //     },
  //   ],
  // },
  "penawaran-service": generatePenawaranForms(PenawaranTypeEnum.SERVICE),
  "sales-contract-service": generateSalesContractForm(
    PenawaranTypeEnum.SERVICE
  ),

  // "bapb-service": generateBapbForm(BAPBTypeEnum.SERVICE),
  // "sji-service-bapb": generateSuratJalanInternalForm(
  //   SuratJalanInternalTypeEnum.SERVICE
  // ),
  "instruksi-service": {
    permissionRequired: "InstruksiService",
    title: "Instruksi Service",
    filter: <BaseFilter keyName="instruksiService" />,
    urls: generateCrudUrl("instruksi-service"),
    generateCodeParam: {
      entityName: "InstruksiService",
      entity: {},
    },
    cetakActions: [
      {
        label: "Cetak",
        url: "/instruksi-service/{id}/download",
        isRefetch: true,
      },
    ],
    requiredToAdd: ["Cabang", "Divisi"],
    disableAdd(data, filterData) {
      return (
        !filterData?.["salesContract.penawaran.organisasi.id"] ||
        !filterData?.["salesContract.penawaran.divisi.id"]
      )
    },
    formGroupItems: genFormItems([
      {
        id: "salesContract.id",
        type: "async-table-select",
        column: 1,
        label: "No. Penawaran",
        resourceUrl: (data, filterData) =>
          `sales-contract?${genFieldQueryParamStr({
            "penawaran.tipe": PenawaranTypeEnum.SERVICE,
            "penawaran.organisasi.id":
              filterData?.["salesContract.penawaran.organisasi.id"],
            "penawaran.divisi.id":
              filterData?.["salesContract.penawaran.divisi.id"],
            "instruksiServices.id": "null",
          })}`,
        resourceColumn: [
          {
            dataKey: "noSalesContract",
            label: "Nomor Sales Kontrak",
          },
          {
            dataKey: "penawaran.no",
            label: "No. Penawaran",
          },
          {
            dataKey: "penawaran.sales.nama",
            label: "Nama Sales",
          },
          {
            dataKey: "penawaran.pelanggan.namaPanjang",
            label: "Nama Pelanggan",
          },
        ],
        resourceColumnLabelKey: "noSalesContract",
        customOnChange: (data, setValue) => {
          setValue("salesContract", data)
        },
      },
      {
        id: "no",
        label: "Nomor Instruksi Service",
        disabled: true,
        isGeneratedCodeField: true,
        dataViewLabel: "Nomor IS",
      },
      {
        id: "salesContract.penawaran.no",
        label: "No. Penawaran",
        type: "ignored",
        isDataView: true,
      },
      {
        id: "salesContract.tanggal",
        type: "date",
        label: "Tanggal Penawaran",
        disabled: true,
      },
      {
        id: "salesContract.penawaran.sales.nama",
        label: "Sales",
        disabled: true,
        isDataView: true,
      },
      {
        id: "salesContract.penawaran.pelanggan.namaPanjang",
        label: "Customer",
        disabled: true,
        isDataView: true,
      },
      {
        id: "salesContract.noPoPelanggan",
        label: "PO Customer",
        disabled: true,
        isDataView: true,
      },
      {
        id: "tanggal",
        label: "Tanggal Instruksi Service",
        type: "date",
        value: getToday(),
        dataViewLabel: "Tanggal IS",
      },
      {
        id: "supplier.id",
        label: "Supplier",
        renderCondition(formData, formMode, filterData) {
          return !!formData.salesContract?.penawaran?.isKirimSupplier
        },
        type: "async-select",
        resourceUrl: `supplier?${genBaseSortQueryParam("kodeSupplier", true)}`,
        resourceMapper: supplierOptionMapper,
        searchBy: supplierSearchKey,
      },
      {
        id: "keterangan",
        label: "Note",
        type: "text-area",
      },
      {
        id: "jmlCetak",
        type: "ignored",
        isDataView: true,
        label: "Jumlah Cetak",
      },
    ]),
  },
  "po-service": generatePurchaseOrderForm(PoTypeEnum.SERVICE), // "sji-instruksi-service": generateSuratJalanInternalForm(
  //   SuratJalanInternalTypeEnum.INSTRUKSI_SERVICE
  // ),
  // "sj-customer-service": generateSuratJalanCustomer(
  //   SuratJalanCustomerTypeEnum.SERVICE
  // ),
}

export const ServicePageKeys = Object.keys(ServicePages)
