import { PenawaranTypeEnum, SpesificModuleFormItem } from "common/type"
import { TableCell } from "components/data-table"
import { BasePageFormGroupItem } from "components/page/type"

function generateItemSalesContractFormItems(
  tipe: PenawaranTypeEnum
): BasePageFormGroupItem[] {
  const items: SpesificModuleFormItem<PenawaranTypeEnum>[] = [
    {
      id: "barang.kode",
      label: "Kode Barang",
      isDataView: true,
      disabled: true,
      enabledAt: [PenawaranTypeEnum.TTLC, PenawaranTypeEnum.INDENT],
    },
    {
      id: "barang.nama",
      label: "Nama Barang",
      isDataView: true,
      disabled: true,
      enabledAt: [
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.SERVICE,
      ],
    },
    {
      id: "josName",
      label: "Nama Barang",
      columnRender(data, filterData, globVars) {
        return <TableCell>Joining On Site</TableCell>
      },
      isDataView: true,
      disabled: true,
      isIgnored: true,
      enabledAt: [PenawaranTypeEnum.JOS],
    },
    {
      id: "qty",
      label: "Kuantitas",
      disabled: true,
      isDataView: true,
      enabledAt: [
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.SERVICE,
        PenawaranTypeEnum.JOS,
      ],
    },
    {
      id: "hargaJual",
      label: "Harga Jual",
      disabled: true,
      isDataView: true,
      type: "number",
      enabledAt: [
        PenawaranTypeEnum.TTLC,
        PenawaranTypeEnum.INDENT,
        PenawaranTypeEnum.SERVICE,
      ],
    },
    {
      id: "hargaJos",
      label: "Amount",
      disabled: true,
      isDataView: true,
      type: "number",
      enabledAt: [PenawaranTypeEnum.JOS],
    },
  ]

  return items
    .filter((i: SpesificModuleFormItem<PenawaranTypeEnum>) =>
      i.enabledAt.includes(tipe)
    )
    .map(({ enabledAt, ...data }) => data)
}

export default generateItemSalesContractFormItems
