import { genFieldQueryParamStr, getToday, strToSafeNum } from "common/util"
import { BasePageFormGroupItem } from "components/page/type"
import { karyawanOptionMapper, pelangganOptionMapper } from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"

function generateItemKonsinyasiFormItems(): BasePageFormGroupItem[] {
  return [
    {
      id: "materialNumber",
      label: "Material Number",
    },
    {
      id: "mesin",
      label: "Material Number",
    },
    {
      id: "barang.id",
      label: "Barang",
      type: "async-select",
      resourceUrl: (_, filter, data) => {
        const q: any = {}

        if (data?.divisi?.id) {
          q["divisi.id_$In"] = `${data?.divisi?.id};3`
        }

        return `barang?${genFieldQueryParamStr(q)}&${genBaseSortQueryParam()}`
      },
      dataViewKey: "barang.kode",
      dataViewLabel: "Kode Barang",
      customOnChange: (data, setValue) => {
        setValue("barang", data)
      },
    },
    {
      id: "barang.nama",
      label: "Nama",
      type: "ignored",
      isDataView: true,
    },
    {
      id: "qty",
      type: "number",
      isDataView: true,
      label: "Qty",
      suffix: { id: "line", label: "Line", type: "number" },
    },
    {
      id: "spesifikasi.panjang",
      label: "Panjang (mm)",
      type: "number",
      isDataView: true,
      isOptional: true,
      isCallRecalculateCb: true,
      suffix: {
        id: "spesifikasi.lebar",
        label: "Lebar (mm)",
        isCallRecalculateCb: true,
        isDataView: true,
        type: "number",
        isOptional: true,
        isHalfSize: true,
      },
    },
    {
      id: "spesifikasi.luas",
      label: "Luas",
      type: "number",
      dataViewLabel: "SQM",
      isDataView: true,
      disabled: true,
    },
    {
      id: "keterangan",
      type: "text-area",
      isOptional: true,
      label: "Keterangan",
    },
  ]
}

export default generateItemKonsinyasiFormItems
