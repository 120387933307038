import { InformasiPageProps } from "components/page/type"

export const InformasiRateValasFormItem: InformasiPageProps = {
  title: "Rate Valas",
  filterName: "infoRate",
  permissionRequired: "RateValas",
  disablePagination: true,
  fields: [
    {
      dataKey: "tanggal",
      label: "Tanggal",
    },
    {
      dataKey: "USD",
      label: "USD",
      type: "number",
    },
    {
      dataKey: "CHF",
      label: "CHF",
      type: "number",
    },
    {
      dataKey: "EUR",
      label: "EUR",
      type: "number",
    },
    {
      dataKey: "JPY",
      label: "JPY",
      type: "number",
    },
    {
      dataKey: "GBP",
      label: "GBP",
      type: "number",
    },
    {
      dataKey: "SGD",
      label: "SGD",
      type: "number",
    },
    {
      dataKey: "HKS",
      label: "HKS",
      type: "number",
    },
    {
      dataKey: "RMB",
      label: "RMB",
      type: "number",
    },
  ],
  sourceUrl: "laporan/rate-valas",
  pdfUrl: "/laporan/rate-valas/download",
  excelUrl: "/laporan/rate-valas/excel",
}

export const InformasiRatePajakFormItem: InformasiPageProps = {
  title: "Rate Pajak",
  filterName: "infoRate",
  permissionRequired: "RatePajak",
  fields: [
    {
      dataKey: "tanggal",
      label: "Tanggal",
    },
    {
      dataKey: "USD",
      label: "USD",
      type: "number",
    },
    {
      dataKey: "CHF",
      label: "CHF",
      type: "number",
    },
    {
      dataKey: "EUR",
      label: "EUR",
      type: "number",
    },
    {
      dataKey: "JPY",
      label: "JPY",
      type: "number",
    },
    {
      dataKey: "GBP",
      label: "GBP",
      type: "number",
    },
    {
      dataKey: "SGD",
      label: "SGD",
      type: "number",
    },
    {
      dataKey: "HKS",
      label: "HKS",
      type: "number",
    },
    {
      dataKey: "RMB",
      label: "RMB",
      type: "number",
    },
  ],
  sourceUrl: "rate-pajak",
}
