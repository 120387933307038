import { InformasiPageProps } from "components/page/type"

export const LaporanKomisiPage: InformasiPageProps = {
  title: "Laporan Komisi",
  permissionRequired: "LaporanKomisi",
  sourceUrl: "konfirmasi-invoice",
  pdfUrl: "konfirmasi-invoice/laporan-komisi/download",
  filterName: "laporanKomisiTTLC",
  fields: [
    {
      dataKey: "supplier.namaPanjang",
      label: "Nama Supplier",
    },
    {
      dataKey:
        "ItemPenerimaanKomisis.0.salesContract.penawaran.pelanggan.namaPanjang",
      label: "Nama Customers",
    },
    {
      dataKey: "ItemPenerimaanKomisis.0.salesContract.purchaseOrders.0.noPo",
      label: "No. PO",
    },
    {
      dataKey: "ItemPenerimaanKomisis.0.debitNote.noInvoice",
      label: "No. Invoice",
    },
    {
      dataKey: "",
      label: "Tanggal",
    },
    {
      dataKey: "",
      label: "Curr",
    },
    {
      dataKey: "",
      label: "Nilai",
    },
  ],
}

export const LapHutangSupplierPage: InformasiPageProps = {
  title: "Laporan Hutang Supplier",
  sourceUrl: "/laporan/hutang-supplier",
  permissionRequired: "LaporanHutangSupplier",
  filterName: "lapHutangSupplier",
  excelUrl: "/laporan/hutang-supplier/download-excel",
  fields: [
    {
      dataKey: "",
      label: "lokal",
    },
    {
      dataKey: "",
      label: "Nama Supplier",
    },
    {
      dataKey: "",
      label: "Tgl BAPB",
    },
    { dataKey: "", label: "No. BAPB" },
    { dataKey: "", label: "No. PO" },
    { dataKey: "", label: "No. Invoice" },
    { dataKey: "", label: "Tgl Jatuh Tempo" },
    { dataKey: "", label: "Curr" },
    { dataKey: "", label: "Nilai PO" },
    { dataKey: "", label: "Ppn" },
  ],
}

export const LapBiayaSupplier: InformasiPageProps = {
  title: "Laporan Biaya Supplier",
  sourceUrl: "",
  permissionRequired: "LaporanBiayaSupplier",
  fields: [
    {
      dataKey: "",
      label: "No. Debit Note",
    },
    {
      dataKey: "",
      label: "Periode",
    },
    {
      dataKey: "",
      label: "Tanggal",
    },
    {
      dataKey: "",
      label: "Keterangan",
    },
    {
      dataKey: "",
      label: "Jumlah",
    },
  ],
}
