import { getToday } from "common/util"
import { TableCell } from "components/data-table"
import { BasePageFormGroupItem } from "components/page/type"

function generateBiayaImportFormItems(
  isTambahan: boolean
): BasePageFormGroupItem[] {
  const base: BasePageFormGroupItem[] = isTambahan
    ? [
        {
          id: "tanggal",
          label: "Tanggal",
          type: "date",
          isDataView: true,
          value: getToday(),
        },
      ]
    : []

  return base.concat([
    {
      id: "forwarding.id",
      label: "Forwarding",
      type: "async-select",
      resourceUrl: `angkutan`,
      disabled: isTambahan,
      resourceMapper(data) {
        return {
          label: `${data.kodeAngkutan} - ${data.namaPanjang}`,
          value: data.id,
        }
      },
      searchBy: "namaPendek,namaPanjang,kodeAngkutan",
      dataViewKey: "forwarding.namaPanjang",
      isDataView: true,
    },
    {
      id: "beaMasuk",
      label: "Bea Masuk",
      type: "number",
      isCallRecalculateCb: true,
      value: 0,
      suffix: {
        id: "ppn",
        label: "PPN",
        type: "number",
        isCallRecalculateCb: true,
        value: 0,
        isHalfSize: true,
      },
    },
    {
      id: "pph",
      label: "PPh Import",
      type: "number",
      isCallRecalculateCb: true,
      value: 0,
      suffix: {
        id: "tanggalBeaMasuk",
        label: "Tanggal Bea Masuk, PPN, PPh",
        type: "date",
        value: getToday(),
        isHalfSize: true,
      },
    },
    {
      id: "biayaBank",
      label: "Biaya Bank",
      type: "number",
      isCallRecalculateCb: true,
      value: 0,
      suffix: {
        id: "bungaBank",
        label: "Bunga Bank",
        type: "number",
        isCallRecalculateCb: true,
        value: 0,
        isHalfSize: true,
      },
    },
    {
      id: "blank",
      type: "blank",
      isOptional: true,
    },
    {
      id: "sewaGudang",
      label: "Sewa Gudang",
      type: "number",
      isCallRecalculateCb: true,
      value: 0,
      suffix: {
        id: "do",
        label: "DO",
        type: "number",
        isCallRecalculateCb: true,
        isHalfSize: true,
        value: 0,
      },
    },
    {
      id: "handlingCost",
      type: "number",
      label: "Handling Cost",
      isCallRecalculateCb: true,
      value: 0,
      suffix: {
        id: "forwardingCost",
        label: "Forwarding Cost",
        type: "number",
        isCallRecalculateCb: true,
        isHalfSize: true,
        value: 0,
      },
    },
    {
      id: "ongkosAngkut",
      label: "Ongkos Angkut",
      type: "number",
      isCallRecalculateCb: true,
      value: 0,
      suffix: {
        id: "lainLain",
        label: "Biaya Lain",
        type: "number",
        isCallRecalculateCb: true,
        isHalfSize: true,
        value: 0,
      },
    },

    {
      id: "freight",
      label: "Freight",
      type: "number",
      suffix: isTambahan
        ? undefined
        : {
            id: "isFreightLangsung",
            label: "Freight Langsung",
            type: "check",
            customOnChange: (data, setValue) => {
              if (!!data) {
                setValue("freight", 0)
              }
            },
            disabled: true,
            isCallRecalculateCb: true,
            isHalfSize: true,
          },
      isCallRecalculateCb: true,
      value: 0,
    },

    {
      id: "note",
      label: "Note",
      type: "text-area",
    },
    {
      id: "jumlah",
      label: "Jumlah Total",
      type: "number",
      dataViewLabel: "Jumlah",
      disabled: true,
      strong: true,
    },
    {
      id: "isComplete",
      label: isTambahan ? "Hitung HPP" : "Biaya Import Complete",
      type: "check",
      isDataView: true,
      dataViewLabel: "Complete",
      columnRender(data, filterData, globVars) {
        return <TableCell>{!data.isComplete ? "Tidak" : "Ya"}</TableCell>
      },
    },
  ])
}

export default generateBiayaImportFormItems
