import {
  LapBiayaSupplier,
  LapHutangSupplierPage,
  LaporanKomisiPage,
} from "common/form-item-generator/common-informasi"
import { GeneralMap } from "common/type"
import { InformasiPageProps } from "components/page/type"
import { TagihanLaporanPages } from "../../common/form-item-generator/tagihan"

export const LaporanPages: GeneralMap<InformasiPageProps> = {
  "laporan-kb-tidak-dibayar": {
    title: "Lap. Kick Back (KB) Tidak Dibayar",
    sourceUrl: "/laporan/kb-tidak-dibayar",
    filterName: "lapKbTidakDibayar",
    permissionRequired: "LaporanKbTidakDibayar",
    pdfUrl: "/laporan/kb-tidak-dibayar/download",
    fields: [
      {
        dataKey: "salesContract.penawaran.pelanggan.namaPanjang",
        label: "Nama Customer",
      },
      {
        dataKey: "",
        label: "No. Penawaran",
      },
      {
        dataKey: "",
        label: "No. Invoice",
      },
      { dataKey: "", label: "Tanggal" },
      { dataKey: "", label: "Harga Jual" },
      { dataKey: "", label: "Belting" },
      { dataKey: "", label: "Textil" },
    ],
  },
  ...TagihanLaporanPages("lap-tagihan"),
  "lap-komisi": LaporanKomisiPage,
  "lap-hutang-supplier": LapHutangSupplierPage,
  "lap-biaya-supplier": LapBiayaSupplier,
  "lap-kasbon-karyawan": {
    title: "Laporan Kasbon Karyawan",
    sourceUrl: "",
    permissionRequired: "LaporanKasbonKaryawan",
    fields: [
      {
        dataKey: "",
        label: "Nomor",
      },
      {
        dataKey: "",
        label: "Tanggal",
      },
      {
        dataKey: "",
        label: "Nama Karyawan",
      },
      {
        dataKey: "",
        label: "Divisi",
      },
      {
        dataKey: "",
        label: "Keterangan",
      },
      {
        dataKey: "",
        label: "Jumlah",
      },
      {
        dataKey: "",
        label: "No. Penyelesaian",
      },
      {
        dataKey: "",
        label: "Tgl Selesai",
      },
    ],
  },
  "lap-reimburse-karyawan": {
    title: "Laporan Reimburse - Biaya Operasional Karyawan",
    sourceUrl: "",
    permissionRequired: "LaporanKasbonKaryawan",
    fields: [
      {
        dataKey: "",
        label: "Nomor",
      },
      {
        dataKey: "",
        label: "Tanggal",
      },
      {
        dataKey: "",
        label: "Nama Karyawan",
      },
      {
        dataKey: "",
        label: "Keterangan",
      },
      {
        dataKey: "",
        label: "Jumlah",
      },
    ],
  },
  "lap-penambahan-kas-kecil": {
    title: "Laporan Penambahan Kas Kecil",
    permissionRequired: "LaporanPenambahanKasKecil",
    sourceUrl: "",
    fields: [
      {
        dataKey: "",
        label: "Nomor",
      },
      {
        dataKey: "",
        label: "Tanggal",
      },
      {
        dataKey: "",
        label: "Voucher",
      },
      {
        dataKey: "",
        label: "Keterangan",
      },
      {
        dataKey: "",
        label: "Jumlah",
      },
      {
        dataKey: "",
        label: "PPn",
      },
      {
        dataKey: "",
        label: "No. Penambahan",
      },
      {
        dataKey: "",
        label: "Tanggal",
      },
      {
        dataKey: "",
        label: "Voucher",
      },
    ],
  },
  "lap-summ-kas-bank": {
    title: "Laporan Summary Kas dan Bank",
    permissionRequired: "LaporanSummaryKasBank",
  },
}

export const LaporanPageKeys = Object.keys(LaporanPages)
