export const GLOB_VAR_KODE_SATUAN_SLV = "KODE_SLV"
export const GLOB_VAR_KODE_SATUAN_PJ = "KODE_SATUAN_PJ"
export const GLOB_VAR_KODE_SATUAN_METER = "KODE_SATUAN_METER"
export const GLOB_VAR_KODE_SATUAN_MM = "KODE_SATUAN_MM"
export const GLOB_VAR_KODE_SATUAN_ROLL = "KODE_SATUAN_ROL"
export const GLOB_VAR_KODE_SUB_OPTIBELT = "KODE_SUB_OPTIBELT"
export const GLOB_VAR_KODE_SUB_OTHER_BELT = "KODE_SUB_OTHER_BELT"

export const GLOB_VAR_PPN = "PPN"
export const GLOB_VAR_PPH = "PPH"

export const GLOB_VAR_KODE_SUB_NO_BIAYA_OPERASIONAL =
  "KODE_SUB_TANPA_BIAYA_OPERASIONAL"
