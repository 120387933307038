import { BAPBTypeEnum, SpesificModuleFormItem } from "common/type"
import { Text } from "@chakra-ui/react"
import { mataUangOptionMapper, mataUangSearchField } from "constant/mapper"
import { BasePageFormGroupItem } from "components/page/type"
import { formatNumber, genFieldQueryParamStr, strToSafeNum } from "common/util"
import { DataTableColumn } from "components/data-table/type"
import { getKursByMataUang } from "hooks/http/custom"
import { genBaseSortQueryParam } from "constant/url"
import { SetFieldValue } from "react-hook-form"
import { TableCell } from "components/data-table"
import { getItemPoUnitPrice } from "common/procedures/purchase-order"

function getKonversiValue(
  barang: any,
  panjang: number | null,
  lebar: number | null,
  qty: number | undefined
): number {
  let sqm = 1
  if (!!panjang) {
    let divider = 1000
    sqm *= +panjang

    if (!!lebar) {
      sqm *= +lebar
      divider *= 1000
    }

    // if (barang?.satuanStock?.kode !== "MM") {
    sqm /= divider
    // }
  } else {
    if (!!lebar) {
      sqm *= +lebar
    }
  }

  if (!!panjang || !!lebar) {
    const jlhKonversi = +barang?.jlhKonversi
    if (jlhKonversi > 0) {
      sqm *= jlhKonversi
    }
  }

  return sqm * strToSafeNum(qty)
}
function onChangeKonversi(
  barang: any,
  panjang: number | null,
  lebar: number | null,
  qty: number | undefined,
  setValue: SetFieldValue<any>
) {
  setValue("jumlahKonversi", getKonversiValue(barang, panjang, lebar, qty))
}

function generateItemBAPBFormItems(
  tipe: BAPBTypeEnum
): BasePageFormGroupItem[] {
  const relationName =
    tipe === BAPBTypeEnum.STOCK ? "itemPengadaanStock" : "itemPenawaran"
  const addColumn: DataTableColumn<any>[] = [
    {
      dataKey: "itemPengadaanStock.hargaUnit",
      label: "Harga",
      type: "number",
      render(data, idx, globVars) {
        return <TableCell>{formatNumber(getItemPoUnitPrice(data))}</TableCell>
      },
    },
    {
      dataKey: "purchaseOrderStock.mataUang.mataUang",
      label: "Mata Uang",
    },
    {
      dataKey: "purchaseOrderStock.isPpn",
      label: "PPN",
      render: (data: any) => (
        // @ts-ignore
        <Text fontSize="14px" fontWeight={600}>
          {data?.purchaseOrderStock?.isPpn ? "Y" : "T"}
        </Text>
      ),
    },
  ]
  const items: SpesificModuleFormItem<BAPBTypeEnum>[] = [
    {
      id: "itemPurchaseOrderStock.purchaseOrderStock.noPo",
      type: "ignored",
      dataViewLabel: "No. PO",
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
    },
    {
      id: "itemPurchaseOrderStock.id",
      label: "Barang (Nomor PO)",
      type: "async-table-select",
      isDataView: BAPBTypeEnum.SERVICE === tipe,
      resourceUrl: (_, filter, data) =>
        `item-purchase-order-stock?${genFieldQueryParamStr({
          "purchaseOrderStock.poType_$In": "STOCK;INDENT;SAMPLE;SERVICE",
          "purchaseOrderStock.status": "READY_FOR_BAPB",
          sisaBarang_$gt: 0,
          "purchaseOrderStock.supplier.id": data.supplier?.id,
          "purchaseOrderStock.divisi.id": data.divisi?.id,
        })}`,
      column: 1,
      customOnChange: (data, setValue, getValue, genCode) => {
        setValue("itemPurchaseOrderStock", data)
        const dataItem =
          data?.itemPenawaran ?? data.itemPengadaanStock ?? data.itemService

        const mataUang = data?.purchaseOrderStock?.mataUang?.mataUang
        if (!!dataItem?.barang) {
          //   setValue("barang.nama", dataItem?.namaBarang)
          //   setValue("barang.satuanBeli.nama", "PCS")
          //   setValue("barang.satuanStock.nama", "PCS")
          // } else {
          setValue("barang", dataItem?.barang)
        }
        if (!getValue("qtyInvoice")) {
          setValue("qtyInvoice", dataItem?.qty)
        }
        // setValue("jenisHarga", data.jenisHarga)
        const existingAktual = getValue("qtyAktual") ?? 0
        setValue("sisaBarang", +data.sisaBarang + existingAktual)
        setValue(
          "diskon",
          dataItem?.diskonSupplier ?? dataItem?.penawaran?.diskonSupplier
        )
        if (!getValue("spesifikasi.panjang")) {
          setValue("spesifikasi.panjang", dataItem.spesifikasi?.panjang)
        }

        if (!getValue("spesifikasi.lebar")) {
          setValue("spesifikasi.lebar", dataItem.spesifikasi?.lebar)
        }
        setValue(
          "itemPurchaseOrderStock.itemPengadaanStock.spesifikasi.panjang",
          dataItem.spesifikasi?.panjang
        )
        setValue(
          "itemPurchaseOrderStock.itemPengadaanStock.spesifikasi.lebar",
          dataItem.spesifikasi?.lebar
        )

        getKursByMataUang(mataUang).then((res) => setValue("rateValas", res))
        if (!getValue("jumlahKonversi")) {
          onChangeKonversi(
            dataItem?.barang,
            dataItem?.spesifikasi?.panjang,
            dataItem?.spesifikasi?.lebar,
            dataItem?.qty,
            setValue
          )
        }

        setValue("hargaPO", dataItem?.hargaUnit)
        if (!getValue("harga")) {
          setValue("harga", getItemPoUnitPrice(data))
        }

        const jenisPermintaan =
          data.itemPengadaanStock?.pengadaan?.jenisPermintaan
        setValue("jenisPermintaan", jenisPermintaan)

        if (jenisPermintaan === "SAMPLE") {
          setValue("isPpn", false)
        } else {
          setValue("isPpn", data.purchaseOrderStock?.isPpn)
        }

        setValue(
          "isSisaProject",
          data.itemPengadaanStock?.pengadaan?.tipePps === "SISA_PROJEK"
        )

        if (!getValue("mataUang.mataUang")) {
          setValue("mataUang.mataUang", mataUang)
        }

        genCode()
      },
      resourceColumn: [
        {
          dataKey: "purchaseOrderStock.noPo",
          label: "Nomor PO",
        },
        {
          dataKey: `${relationName}.barang.kode`,
          label: "Kode",
          render: (data) => {
            const entity = data.itemPengadaanStock ?? data.itemPenawaran

            return <TableCell>{entity?.barang?.kode ?? "-"}</TableCell>
          },
        },
        {
          dataKey: `${relationName}.barang.nama`,
          label: "Nama",
          render: (data) => {
            let nama = ""
            const itemData = data?.itemPengadaanStock ?? data?.itemPenawaran
            if (!itemData) {
              nama = data.itemService?.namaBarang
            } else {
              nama = itemData?.barang?.nama
            }

            return <TableCell>{nama}</TableCell>
          },
        },
        {
          dataKey: `${relationName}.qty`,
          label: "Kuantitas",
          render: (data) => {
            const itemData = data?.itemPengadaanStock ?? data?.itemPenawaran

            return (
              <TableCell>
                {formatNumber(
                  !itemData ? data.itemService?.qty : itemData?.qty
                )}
              </TableCell>
            )
          },
        },
        ...addColumn,
        {
          dataKey: "sisaBarang",
          label: "Sisa",
        },
      ],
      resourceColumnLabelKey: "purchaseOrderStock.noPo",
      dataViewKey: "itemPurchaseOrderStock.purchaseOrderStock.noPo",
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
    },
    {
      id: "itemPurchaseOrderStock.purchaseOrderStock.id",
      type: "hidden",
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
    },
    {
      id: "jenisHarga",
      type: "radio",
      label: "Jenis Harga",
      // disabled: true,
      value: "NORMAL",
      options: [
        {
          label: "Normal/SQM",
          value: "NORMAL",
        },
        {
          label: "Meter",
          value: "METER",
        },
        {
          label: "Pieces",
          value: "PIECES",
        },
      ],
      enabledAt: [BAPBTypeEnum.SERVICE],
    },
    {
      id: "isPpn",
      label: "PPN",
      type: "hidden",
      disabled: true,
      enabledAt: [BAPBTypeEnum.STOCK],
    },
    {
      id: "noMasukBarang",
      label: "Nomor Masuk Barang",
      isGeneratedCodeField: true,
      disabled: true,
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
      suffix:
        tipe === BAPBTypeEnum.STOCK
          ? {
              id: `barang.kode`,
              label: "Kode Barang",
              isDataView: true,
              disabled: true,
              isHalfSize: true,
            }
          : undefined,
    },
    {
      id: "barang.nama",
      label: "Nama Barang",
      disabled: true,
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
    },
    {
      id: "noManufaktur",
      label: "Nomor Manufaktur",
      enabledAt: [BAPBTypeEnum.STOCK],
      suffix: {
        id: "qtyInvoice",
        label: "Qty Invoice",
        type: "number",
        isHalfSize: true,
        formRule: (data, detail) => {
          const maxSisa = detail?.itemPurchaseOrderStock?.qty
          return {
            max: {
              value: maxSisa,
              message: `Quantity tidak boleh lebih dari ${maxSisa}`,
            },
          }
        },
      },
    },
    {
      id: "barang.nama",
      isDataView: true,
      isIgnored: true,
      label: "Nama Barang",
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
    },
    {
      id: "qtyInvoice",
      label: "Qty Invoice",
      type: "number",
      enabledAt: [BAPBTypeEnum.SERVICE],
      formRule: (data, detail) => {
        const maxSisa = detail?.itemPurchaseOrderStock?.qty
        return {
          max: {
            value: maxSisa,
            message: `Quantity tidak boleh lebih dari ${maxSisa}`,
          },
        }
      },
    },
    {
      id: "spesifikasi.panjang",
      label: "Panjang",
      type: "number",
      isOptional: true,
      customOnChange: (data, setValue, getValue) => {
        onChangeKonversi(
          getValue("barang"),
          data,
          getValue("spesifikasi.lebar"),
          getValue("qtyAktual")
            ? +getValue("qtyAktual") + +getValue("foc")
            : getValue("qtyInvoice"),
          setValue
        )
      },
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
      suffix: {
        id: "spesifikasi.lebar",
        label: "Lebar",
        type: "number",
        isHalfSize: true,
        isOptional: true,
        customOnChange: (data, setValue, getValue) => {
          onChangeKonversi(
            getValue("barang"),
            getValue("spesifikasi.panjang"),
            data,
            getValue("qtyAktual")
              ? +getValue("qtyAktual") + +getValue("foc")
              : getValue("qtyInvoice"),
            setValue
          )
        },
      },
    },
    {
      id: "qtyAktual",
      label: "Aktual Barang",
      type: "number",
      dataViewLabel: "Quantity",
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
      suffix:
        tipe === BAPBTypeEnum.STOCK
          ? {
              id: "barang.satuanBeli.nama",
              label: "Satuan",
              disabled: true,
            }
          : undefined,
      formRule: (data, detail) => {
        const currSisa =
          data.sisaBarang ??
          strToSafeNum(detail?.itemPurchaseOrderStock?.sisaBarang) +
            strToSafeNum(detail?.jumlahHilangRusak) +
            strToSafeNum(detail?.foc) +
            strToSafeNum(detail?.qtyAktual)

        const maxSisa =
          strToSafeNum(currSisa) - strToSafeNum(data.jumlahHilangRusak)
        return {
          max: {
            value: maxSisa,
            message: `Quantity tidak boleh lebih dari ${maxSisa}`,
          },
        }
      },
      customOnChange: (data, setValue, getValue) => {
        onChangeKonversi(
          getValue("barang"),
          getValue("spesifikasi.panjang"),
          getValue("spesifikasi.lebar"),
          +data + +getValue("foc"),
          setValue
        )
      },
    },
    {
      id: "jumlahKonversi",
      label: "Qty Barang Satuan Terkecil",
      type: "number",
      enabledAt: [BAPBTypeEnum.STOCK],
      description: (data) => {
        if (!data.barang?.satuanBeli?.nama || !data.barang?.satuanStock?.nama) {
          return undefined
        }
        return `1 ${data.barang?.satuanBeli?.nama} = ${formatNumber(
          +data.jumlahKonversi / (+data.qtyAktual + +data.foc)
        )} ${data.barang?.satuanStock?.nama}`
      },
      suffix:
        tipe === BAPBTypeEnum.STOCK
          ? {
              id: "barang.satuanStock.nama",
              disabled: true,
              description: (data) => {
                if (
                  !data.barang?.satuanBeli?.nama ||
                  !data.barang?.jlhKonversi
                ) {
                  return undefined
                }
                return " "
              },
              label: "Satuan",
            }
          : undefined,
    },
    {
      id: "barang.satuanStock.kode",
      type: "hidden",
      enabledAt: [BAPBTypeEnum.STOCK],
    },
    {
      id: "barang.jlhKonversi",
      type: "hidden",
      enabledAt: [BAPBTypeEnum.STOCK],
    },
    {
      id: "jumlahHilangRusak",
      label: "Rusak / Hilang",
      value: 0,
      type: "number",
      formRule: (data, detail) => {
        const currSisa =
          data.sisaBarang ??
          strToSafeNum(detail?.itemPurchaseOrderStock?.sisaBarang) +
            strToSafeNum(detail?.jumlahHilangRusak) +
            strToSafeNum(detail?.foc) +
            strToSafeNum(detail?.qtyAktual)

        const maxSisa = strToSafeNum(currSisa) - strToSafeNum(data.qtyAktual)
        return {
          max: {
            value: maxSisa,
            message: `Quantity tidak boleh lebih dari ${maxSisa}`,
          },
        }
      },
      suffix: {
        id: "foc",
        label: "Free Of Charge",
        type: "number",
        value: 0,
        isHalfSize: true,
        formRule: (data, detail) => {
          const currSisa =
            data.sisaBarang ??
            strToSafeNum(detail?.itemPurchaseOrderStock?.sisaBarang) +
              strToSafeNum(detail?.jumlahHilangRusak) +
              strToSafeNum(detail?.foc) +
              strToSafeNum(detail?.qtyAktual)

          const maxSisa = strToSafeNum(currSisa) - strToSafeNum(data.qtyAktual)
          return {
            max: {
              value: maxSisa,
              message: `Quantity tidak boleh lebih dari ${maxSisa}`,
            },
          }
        },
        customOnChange: (data, setValue, getValue) => {
          onChangeKonversi(
            getValue("barang"),
            getValue("spesifikasi.panjang"),
            getValue("spesifikasi.lebar"),
            +data + +getValue("qtyAktual"),
            setValue
          )
        },
      },
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
    },
    {
      id: "isSisaProject",
      label: "Sisa Proyek",
      type: "check",
      disabled: true,
      enabledAt: [BAPBTypeEnum.STOCK],
    },
    {
      id: "harga",
      label: "Harga",
      type: "number",
      customOnChange: (data, setValue, getValue) => {
        const formData = getValue()
        setValue(
          "kursRupiah",
          strToSafeNum(data) * strToSafeNum(formData?.rate)
        )
      },
      isDataView: true,
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
      suffix: {
        id: "diskon",
        label: "Diskon",
        type: "number",
        disabled: true,
      },
    },
    {
      id: "mataUang.mataUang",
      label: "Mata Uang",
      disabled: true,
      enabledAt: [BAPBTypeEnum.STOCK, BAPBTypeEnum.SERVICE],
      customOnChange: (data, setValue, getValue) => {
        const mataUang = data?.mataUang
        if (!mataUang) return
        const formdata = getValue()
        if (mataUang === "IDR") {
          setValue("rate", 1)
          setValue("kursRupiah", formdata.harga)
        } else {
          getKursByMataUang(mataUang).then((res) => {
            setValue("rate", res)
            setValue("kursRupiah", formdata.harga * res)
          })
        }
      },
      suffix: {
        id: "rate",
        isHalfSize: true,
        label: "Kurs Rp.",
        disabled: true,
        type: "number",
      },
    },
    // {
    //   id: "isRevised",
    //   type: "ignored",
    //   dataViewLabel: "Revisi",
    //   columnRender(data) {
    //     // TODO: fix bug when choose item barang same first, popup error, but when change it to right one, show R when its unexpected
    //     const konversi = getKonversiValue(
    //       data.barang,
    //       data.spesifikasi?.panjang,
    //       data?.spesifikasi?.lebar,
    //       +data.qtyAktual + +data.foc
    //     )

    //     const konversiPo = getKonversiValue(
    //       data.barang,
    //       data.itemPurchaseOrderStock?.itemPengadaanStock?.spesifikasi?.panjang,
    //       data?.itemPurchaseOrderStock?.itemPengadaanStock?.spesifikasi?.lebar,
    //       +data.qtyAktual + +data.foc
    //     )

    //     const expectedKonversi = +data.jumlahKonversi
    //     return (
    //       <TableCell>
    //         {konversi !== expectedKonversi || konversiPo !== expectedKonversi
    //           ? "R"
    //           : ""}
    //       </TableCell>
    //     )
    //   },
    //   enabledAt: [BAPBTypeEnum.STOCK],
    // },
    {
      id: "kursRupiah",
      label: "Harga dalam Rupiah",
      type: "number",
      disabled: true,
      enabledAt: [BAPBTypeEnum.SERVICE],
    },
    {
      id: "komisi",
      label: "Komisi",
      type: "number",
      customOnChange: (data, setValue, getValue) => {
        const harga = getValue("harga")

        const komisi = (strToSafeNum(data) / 100) * harga

        setValue("jumlahKomisi", komisi)
      },
      enabledAt: [BAPBTypeEnum.SERVICE],
    },
    {
      id: "jumlahKomisi",
      label: "Jumlah Komisi",
      type: "number",
      disabled: true,
      enabledAt: [BAPBTypeEnum.SERVICE],
    },
  ]

  return items
    .filter((i: SpesificModuleFormItem<BAPBTypeEnum>) =>
      i.enabledAt.includes(tipe)
    )
    .map(({ enabledAt, ...data }) => data)
}

export default generateItemBAPBFormItems
