import generateItemSJIFormItems from "common/form-item-generator/item-surat-jalan-internal"
import generateItemWorkOrderFormIte from "common/form-item-generator/item-work-order"
import generateSuratJalanInternalFormItems from "common/form-item-generator/surat-jalan-internal"
import { getSQM } from "common/procedures/speisfikasi"
import { AdditionFormType, SuratJalanInternalTypeEnum } from "common/type"
import {
  generateCrudUrl,
  genFormItems,
  genFieldQueryParamObj,
  formatNumber,
  genFieldQueryParamStr,
  getToday,
} from "common/util"
import { TableCell } from "components/data-table"
import BaseFilter from "components/filter/_base"
import { BasePageAdditionalFormType, BasePageProps } from "components/page/type"
import { karyawanOptionMapper } from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"
import { getAPI } from "hooks"
import { StockAvailability } from "services/stock"

const itemWorkOrdersForms = (
  tipe: "BARANG_KHUSUS" | "STOCK"
): BasePageAdditionalFormType => ({
  id: "itemWorkOrders",
  title: "Daftar Barang",
  disableAdd: (data: any) => !data.sfb?.salesContract?.penawaran?.id,
  async validateDataCb(formData: any, globData: any, formMode: any) {
    const requestedSqm =
      getSQM(
        formData.itemPenawaran?.spesifikasi?.panjang,
        formData.itemPenawaran?.spesifikasi?.lebar
      ) * +formData.jumlahAvailable
    const availableSqm =
      getSQM(
        formData.itemBarangMasuk?.panjang,
        formData.itemBarangMasuk?.lebar
      ) * +formData.itemBarangMasuk?.qty
    if (requestedSqm > availableSqm) {
      return `Stock tidak mencukupi (${formatNumber(availableSqm)}`
    }
    if (!!formData.itemPenawaran?.id) {
      const stock: StockAvailability = await getAPI(
        "/RiwayatStockBarang/latest-stock",
        {
          barangId: +formData?.itemPenawaran?.barang?.id,
          organisasiId: +globData?.sfb?.workshop?.id,
        }
      )

      let latestStock = !!formData.itemBarangMasuk.isWaste
        ? +stock.waste
        : +stock.available
      const q =
        (!!formData.itemBarangMasuk.isWaste
          ? +formData.jumlahWaste
          : +formData.jumlahAvailable) *
        getSQM(
          formData.itemPenawaran?.spesifikasi?.panjang,
          formData.itemPenawaran?.spesifikasi?.lebar
        )
      if (formMode === "update") {
        latestStock += q
      }

      if (q > latestStock) {
        return `Stock tidak cukup, mohon cek kembali. Stock terakhir: ${formatNumber(
          stock.available
        )}`
      }
    }
    return undefined
  },
  formItems: genFormItems(generateItemWorkOrderFormIte(tipe)),
})

// TODO: in SPPB sample and waste calculated to sqm
function generateWorkOrderForm(
  tipe: "BARANG_KHUSUS" | "STOCK"
): BasePageProps<any> {
  return {
    permissionRequired: "WorkOrderSfb",
    title: "Work Order" + (tipe === "BARANG_KHUSUS" ? " (Khusus)" : ""),
    urls: {
      ...generateCrudUrl("work-order"),
      read: {
        endpoint: "/work-order",
        method: "get",
        param: genFieldQueryParamObj({
          [tipe === "STOCK"
            ? "sfb.salesContract.penawaran.tipe_$neq"
            : "sfb.salesContract.penawaran.tipe"]: "BARANG_KHUSUS",
        }),
      },
    },
    filter: <BaseFilter keyName="workOrder" />,
    generateCodeParam: {
      entityName: "WorkOrderSfb",
      entity: {},
    },
    requiredToAdd: ["Cabang", "Divisi"],
    disableAdd(data, filterData) {
      return (
        !filterData?.["sfb.workshop.id"] ||
        !filterData?.["sfb.salesContract.penawaran.divisi.id"]
      )
    },
    formGroupItems: genFormItems([
      {
        id: "sfb.id",
        type: "async-table-select",
        label: "Nomor SPB",
        isDataView: true,
        column: 1,
        resourceUrl: (data, filters) => {
          return `surat-fabrikasi-barang?${genFieldQueryParamStr({
            "workshop.id": filters?.["sfb.workshop.id"],
            [tipe === "STOCK"
              ? "salesContract.penawaran.tipe_$neq"
              : "salesContract.penawaran.tipe"]: "BARANG_KHUSUS",
            "salesContract.penawaran.divisi.id":
              filters?.["sfb.salesContract.penawaran.divisi.id"],
            "suratFabrikasiBarang.sisaWo_$gt": 0.0001,
          })}`
        },
        resourceColumnLabelKey: "noSpb",
        dataViewKey: "sfb.noSpb",
        resourceColumn: [
          {
            dataKey: "noSpb",
            label: "Nomor SPB",
          },
          {
            dataKey: "tanggalSpb",
            label: "Tanggal SPB",
          },
          {
            dataKey: "salesContract.penawaran.no",
            label: "No. Penawaran",
          },
          {
            dataKey: "salesContract.penawaran.tanggal",
            label: "Tanggal Penawaran",
          },
          {
            dataKey: "salesContract.penawaran.sales.nama",
            label: "Sales",
          },
          {
            dataKey: "salesContract.penawaran.pelanggan.namaPanjang",
            label: "Customers",
          },
          {
            label: "PO. Cust",
            dataKey: "salesContract.noPoPelanggan",
          },
        ],
        customOnChange: (data, setValue) => {
          setValue("sfb", data)
        },
      },
      {
        id: "sfb.workshop.id",
        label: "Workshop",
        type: "async-select",
        resourceUrl: `organisasi?${genFieldQueryParamStr({
          isWorkshop: true,
        })}`,
      },
      {
        id: "sfb.tanggalSpb",
        type: "ignored",
        label: "Tanggal SPB",
        isDataView: true,
      },
      {
        id: "nomor",
        label: "Nomor WO",
        disabled: true,
        isGeneratedCodeField: true,
        isDataView: true,
        suffix: {
          id: "tanggal",
          label: "Tanggal WO",
          disabled: true,
          isDataView: true,
          type: "date",
          value: getToday(),
          isHalfSize: true,
        },
      },
      {
        id: "sfb.salesContract.penawaran.sales.nama",
        label: "Nama Sales",
        type: "ignored",
        isDataView: true,
      },
      {
        id: "sfb.salesContract.penawaran.pelanggan.namaPanjang",
        label: "Nama Customer",
        type: "ignored",
        isDataView: true,
      },
      {
        id: "sfb.salesContract.noPoPelanggan",
        label: "PO Cust.",
        type: "ignored",
        isDataView: true,
      },
      {
        id: "sfb.salesContract.penawaran.jlhCetak",
        label: "Cetak",
        type: "ignored",
        isDataView: true,
      },
    ]),
    additionalForms: [
      tipe === "BARANG_KHUSUS"
        ? {
            id: "itemWorkOrders",
            title: "Daftar Barang Khusus",
            disableAdd: (data) => !data.sfb?.salesContract?.penawaran?.id,
            additionalForms: [
              {
                id: "itemWorkOrders",
                title: "Daftar Barang",
                disableAdd: (data: any) => {
                  return !data.itemBarangJadi?.id
                },
                async validateDataCb(
                  formData: any,
                  globData: any,
                  formMode: any,
                  filters: any
                ) {
                  const workshopId = filters?.["sfb.workshop.id"]
                  const requestedSqm =
                    getSQM(
                      formData.itemPenawaran?.spesifikasi?.panjang,
                      formData.itemPenawaran?.spesifikasi?.lebar
                    ) * +formData.jumlahAvailable
                  const availableSqm =
                    getSQM(
                      formData.itemBarangMasuk?.panjang,
                      formData.itemBarangMasuk?.lebar
                    ) * +formData.itemBarangMasuk?.qty
                  if (requestedSqm > availableSqm) {
                    return `Stock tidak mencukupi (${formatNumber(
                      availableSqm
                    )}`
                  }
                  if (!!formData.itemPenawaran?.id) {
                    const stock: StockAvailability = await getAPI(
                      "/RiwayatStockBarang/latest-stock",
                      {
                        barangId: +formData?.itemPenawaran?.barang?.id,
                        organisasiId: +workshopId,
                      }
                    )

                    let latestStock = !!formData.itemBarangMasuk.isWaste
                      ? +stock.waste
                      : +stock.available
                    const q =
                      (!!formData.itemBarangMasuk.isWaste
                        ? +formData.jumlahWaste
                        : +formData.jumlahAvailable) *
                      getSQM(
                        formData.itemPenawaran?.spesifikasi?.panjang,
                        formData.itemPenawaran?.spesifikasi?.lebar
                      )
                    if (formMode === "update") {
                      latestStock += q
                    }

                    if (q > latestStock) {
                      return `Stock tidak cukup, mohon cek kembali. Stock terakhir: ${formatNumber(
                        stock.available
                      )}`
                    }
                  }
                  return undefined
                },
                formItems: genFormItems(generateItemWorkOrderFormIte(tipe)),
              },
            ],
            formItems: genFormItems([
              {
                id: "itemBarangJadi.id",
                label: "Barang",
                dataViewLabel: "Kode Barang Habasit",
                column: 1,
                type: "async-table-select",
                resourceUrl: (data, filter, detail) => {
                  return `item-barang-jadi?${genFieldQueryParamStr({
                    "penawaran.id": detail.sfb?.salesContract?.penawaran?.id,
                    "itemBarangJadi.sisaWo_$gt": 0.0001,
                  })}`
                },
                resourceColumnLabelKey: "namaBarang",
                dataViewKey: "itemBarangJadi.kodeBarang",
                resourceColumn: [
                  {
                    dataKey: "kodeBarang",
                    label: "Kode Barang",
                  },
                  {
                    dataKey: "namaBarang",
                    label: "Nama Barang",
                  },
                  {
                    dataKey: "spesifikasi.panjang",
                    label: "Panjang",
                    type: "number",
                  },
                  {
                    dataKey: "spesifikasi.lebar",
                    label: "Lebar",
                    type: "number",
                  },
                  {
                    dataKey: "sisaWo",
                    label: "Qty",
                    type: "number",
                  },
                  {
                    dataKey: "keterangan",
                    label: "Keterangan",
                  },
                ],
                customOnChange: (data, setValue) => {
                  setValue("itemBarangJadi", data)
                },
              },
              {
                id: "itemBarangJadi.namaBarang",
                label: "Nama Barang",
                isIgnored: true,
                isDataView: true,
              },
            ]),
          }
        : itemWorkOrdersForms(tipe),
      {
        id: "itemTeknisiWorkOrders",
        title: "Teknisi",
        formItems: genFormItems([
          {
            id: "teknisi.id",
            label: "Teknisi",
            type: "async-select",
            resourceUrl: (data, filters) => {
              return `karyawan?${genFieldQueryParamStr({
                jenisJabatan: "TEKNISI",
                "organisasi.id": filters?.["sfb.workshop.id"],
              })}&${genBaseSortQueryParam()}`
            },
            isDataView: true,
            resourceMapper: karyawanOptionMapper,
            dataViewKey: "teknisi.kode",
            customOnChange: (data, setValue) => {
              setValue("teknisi", data)
            },
          },
          {
            id: "teknisi.nama",
            disabled: true,
            label: "Nama",
            isIgnored: true,
            isDataView: true,
          },
        ]),
      },
    ],
  }
}

export default generateWorkOrderForm
