import { InformasiPOType } from "common/form-generator/informasi-po"
import {
  calcBiayaPO,
  calcNilaiPoTTLC,
  calcNilaiScOfPo,
} from "common/procedures/informasi-po"
import { SpesificModuleFormItem } from "common/type"
import { formatNumber, genFieldQueryParamStr } from "common/util"
import { TableCell } from "components/data-table"
import { BasePageFormGroupItem } from "components/page/type"
import {
  supplierOptionMapper,
  supplierResourceColumnKey,
  supplierSearchKey,
} from "constant/mapper"
import { genBaseSortQueryParam } from "constant/url"

function calcNilaiPps(pengadaan: any, tipe: InformasiPOType): number {
  return (
    pengadaan?.itemPengadaanStocks?.reduce((total: number, item: any) => {
      let multiplier = 1
      if (item.spesifikasi?.panjang) {
        multiplier *= +item.spesifikasi?.panjang
        if (item.spesifikasi?.lebar) {
          multiplier *= +item.spesifikasi?.lebar / 1000000
        } else {
          multiplier /= 1000
        }
      } else {
        if (item.spesifikasi?.lebar) {
          multiplier *= +item.spesifikasi?.lebar
        }
      }
      return (
        total +
        +item.qty *
          (tipe === InformasiPOType.STOCK ? +item.hargaUnit : +item.hargaBeli) *
          multiplier
      )
    }, 0) ?? 0
  )
}

function generateInformasiPOFormItems(
  tipe: InformasiPOType
): BasePageFormGroupItem[] {
  const items: SpesificModuleFormItem<InformasiPOType>[] = [
    {
      id: "purchaseOrder.id",
      label: "Purchase Order",
      column: 1,
      type: "async-table-select",
      resourceUrl: (data, filterData) => {
        const q: any = {
          "informasiPos.id": "null",
          poType: "TTLC",
          ...filterData,
        }
        return `purchase-order?${genFieldQueryParamStr(
          q
        )}&${genBaseSortQueryParam("id", false)}`
      },
      resourceColumnLabelKey: "noPo",
      resourceColumn:
        tipe === InformasiPOType.TTLC
          ? [
              {
                dataKey: "noPo",
                label: "No. PO",
              },
              {
                dataKey: "nilaiPo",
                label: "Nilai PO",
                type: "number",
                render: (data) => (
                  <TableCell>{formatNumber(calcNilaiPoTTLC(data))}</TableCell>
                ),
              },
              {
                dataKey: "salesContract.penawaran.no",
                label: "No. Sales Kontrak",
              },
              {
                dataKey: "nilaiSc",
                label: "Nilai SC",
                render: (data) => (
                  <TableCell>{formatNumber(calcNilaiScOfPo(data))}</TableCell>
                ),
              },
              {
                dataKey: "supplier.namaPanjang",
                label: "Nama Supplier",
              },
            ]
          : [
              {
                dataKey: "noPo",
                label: "No. PO",
              },
              {
                dataKey: "nilaiPo",
                label: "Nilai PO",
                type: "number",
              },
              {
                dataKey: "pengadaan.no",
                label: "Nomor PPS",
              },
              {
                dataKey: "nilaiPps",
                label: "Nilai PPS",
                render: (data) => (
                  <TableCell>
                    {formatNumber(calcNilaiPps(data.pengadaan, tipe))}
                  </TableCell>
                ),
              },
              {
                dataKey: "supplier.namaPanjang",
                label: "Nama Supplier",
              },
            ],
      customOnChange: (data, setValue, getValue) => {
        setValue("purchaseOrder", data)
        if (!getValue("modeOfShipment")) {
          setValue("modeOfShipment", data.modeOfShipment)
        }
        if (tipe === InformasiPOType.TTLC) {
          setValue("nilaiSc", calcNilaiScOfPo(data))
          setValue("nilaiPo", calcNilaiPoTTLC(data))
        } else {
          setValue("nilaiPps", calcNilaiPps(data.pengadaan, tipe))
        }
        setValue("biaya", calcBiayaPO(data))
      },
      enabledAt: [InformasiPOType.STOCK, InformasiPOType.TTLC],
      isDataView: true,
      dataViewKey: "purchaseOrder.noPo",
    },
    {
      id: "purchaseOrder.tanggal",
      isDataView: true,
      label: "Tanggal",
      disabled: true,
      dataViewLabel: "Tanggal PO",
      enabledAt: [InformasiPOType.STOCK, InformasiPOType.TTLC],
      suffix:
        tipe === InformasiPOType.TTLC
          ? {
              id: `purchaseOrder.salesContract.noSalesContract`,
              label: "Nomor SC",
              isDataView: true,
              disabled: true,
              isHalfSize: true,
            }
          : undefined,
    },
    {
      id: `purchaseOrder.supplier.id`,
      label: "Supplier",
      type: "async-select",
      isDataView: true,
      dataViewKey: "purchaseOrder.supplier.namaPanjang",
      resourceUrl: `supplier`,
      resourceMapper: supplierOptionMapper,
      searchBy: supplierSearchKey,
      resourceColumnLabelKey: supplierResourceColumnKey,
      disabled: true,
      enabledAt: [InformasiPOType.TTLC],
    },
    {
      id: `purchaseOrder.pengadaan.no`,
      label: "Nomor PPS",
      isDataView: true,
      disabled: true,
      enabledAt: [InformasiPOType.STOCK],
    },
    {
      id: `purchaseOrder.salesContract.noPoPelanggan`,
      label: "Nomor PO Cust.",
      disabled: true,
      enabledAt: [InformasiPOType.TTLC],
      suffix: {
        id: `purchaseOrder.salesContract.penawaran.sales.nama`,
        label: "Sales",
        disabled: true,
        isHalfSize: true,
      },
    },

    {
      id: "purchaseOrder.pengadaan.namaPemohon",
      label: "Pemohon",
      disabled: true,
      enabledAt: [InformasiPOType.STOCK],
    },
    {
      id: "nilaiPps",
      label: "Nilai PPS",
      disabled: true,
      type: "number",
      enabledAt: [InformasiPOType.STOCK],
    },
    {
      id: "nilaiSc",
      label: "Nilai SC",
      disabled: true,
      isDataView: true,
      type: "number",
      columnRender(data) {
        return (
          <TableCell>
            {formatNumber(calcNilaiScOfPo(data.purchaseOrder))}
          </TableCell>
        )
      },
      enabledAt: [InformasiPOType.TTLC],
      suffix: {
        id: "nilaiPo",
        label: "Nilai PO",
        disabled: true,
        type: "number",
        isHalfSize: true,
      },
    },
    {
      id: "nilaiPo",
      label: "Nilai PO",
      disabled: true,
      isDataView: true,
      isIgnored: tipe === InformasiPOType.TTLC,
      type: "number",
      // TODO: remove this, show data from API
      columnRender(data) {
        return (
          <TableCell>
            {formatNumber(calcNilaiPoTTLC(data.purchaseOrder))}
          </TableCell>
        )
      },
      enabledAt: [InformasiPOType.STOCK, InformasiPOType.TTLC],
    },
    {
      id: "biaya",
      label: "Biaya",
      disabled: true,
      enabledAt: [InformasiPOType.STOCK, InformasiPOType.TTLC],
      suffix:
        tipe === InformasiPOType.TTLC
          ? {
              id: "purchaseOrder.salesContract.penawaran.komisi",
              label: "Komisi (%)",
              disabled: true,
              type: "number",
              isHalfSize: true,
            }
          : undefined,
    },

    {
      id: "purchaseOrder.salesContract.penawaran.kb",
      label: "KB (%)",
      disabled: true,
      type: "number",
      enabledAt: [InformasiPOType.TTLC],
      suffix: {
        id: "purchaseOrder.salesContract.penawaran.margin",
        label: "Margin (%)",
        disabled: true,
        type: "number",
        isHalfSize: true,
      },
    },
    {
      id: "modeOfShipment",
      label: "Mode Of Shipment",
      enabledAt: [InformasiPOType.STOCK, InformasiPOType.TTLC],
      suffix: {
        id: "purchaseOrder.mataUang.mataUang",
        label: "Currency",
        disabled: true,
        isHalfSize: true,
      },
    },

    {
      id: "purchaseOrder.supplier.namaPanjang",
      label: "Nama Supplier",
      isIgnored: true,
      enabledAt: [InformasiPOType.STOCK],
    },
    {
      id: "payment",
      type: "number",
      label: "Payment",
      isIgnored: true,
      isDataView: true,
      columnRender(data) {
        return (
          <TableCell>
            {formatNumber(
              data.informasiPayments?.reduce(
                (total: number, item: any) => total + +item.jumlah,
                0
              ) ?? 0
            )}
          </TableCell>
        )
      },
      enabledAt: [InformasiPOType.STOCK, InformasiPOType.TTLC],
    },
    {
      id: "purchaseOrder.tanggalBayar",
      type: "date",
      label: "Tgl Payment",
      isIgnored: true,
      isDataView: true,
      enabledAt: [InformasiPOType.STOCK, InformasiPOType.TTLC],
    },
    {
      id: "informasiShipments.0.tanggalShipment",
      isDataView: true,
      label: "Tanggal Shipment",
      isIgnored: true,
      enabledAt: [InformasiPOType.TTLC],
    },
  ]

  return items
    .filter((i: SpesificModuleFormItem<InformasiPOType>) =>
      i.enabledAt.includes(tipe)
    )
    .map(({ enabledAt, ...data }) => data)
}

export default generateInformasiPOFormItems
