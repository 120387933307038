import generateBiayaImportFormItems from "common/form-item-generator/biaya-import"
import { calculateTotalPembayaran } from "common/form-value-function"
import {
  genFieldQueryParamObj,
  genFieldQueryParamStr,
  genFormItems,
  generateCrudUrl,
  getToday,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"

function generateBiayaImportForms(isTambahan: boolean): BasePageProps<any> {
  return {
    title: "Biaya Import" + (isTambahan ? " Tambahan" : ""),
    permissionRequired:
      "SIT_sekawan::transaksi::lain_lain::pembayaran::biaya_import",

    generateCodeParam: isTambahan
      ? undefined
      : {
          entityName: "BiayaImport",
          entity: {},
        },
    customFormDataMapper(data, filterData) {
      const { biayaImports, isComplete, ...restData } = data
      return {
        ...restData,
        tanggalSelesai: isComplete ? getToday() : null,
        biayaImports: biayaImports?.map((i: any) => {
          const { isComplete: isCompleteTambahan, ...restDataTambahan } = i

          return {
            ...restDataTambahan,
            tanggalSelesai: isCompleteTambahan ? getToday() : null,
          }
        }),
      }
    },
    responseMapper(data) {
      const { biayaImports, tanggalSelesai, ...restData } = data

      return {
        ...restData,
        isComplete: !!tanggalSelesai,
        biayaImports: biayaImports?.map((i: any) => {
          const {
            tanggalSelesai: tanggalSelesaiTambahan,
            ...restDataTambahan
          } = i

          return {
            ...restDataTambahan,
            nomor: restData.nomor,
            isComplete: !!tanggalSelesaiTambahan,
          }
        }),
      }
    },
    urls: {
      ...generateCrudUrl("biaya-import"),
      read: {
        method: "get",
        endpoint: `/biaya-import`,
        param: {
          ...genFieldQueryParamObj({
            biayaImportInduk: isTambahan ? "notNull" : "null",
          }),
          sortBy: "id",
          sortType: "desc",
        },
      },
    },
    filter: <BaseFilter keyName="periodeOnly" />,
    formGroupItems: genFormItems([
      isTambahan
        ? {
            id: "biayaImportInduk.id",
            label: "Biaya Import",
            type: "async-table-select",
            column: 1,
            resourceColumnLabelKey: "nomor",
            resourceUrl: `biaya-import?${genFieldQueryParamStr({
              biayaImportInduk: "null",
            })}&sortBy=id&sortType=desc`,
            dataViewLabel: "Nomor Biaya Import",
            dataViewKey: "biayaImportInduk.nomor",
            resourceColumn: [
              {
                dataKey: "nomor",
                label: "Nomor",
              },
              {
                dataKey: "tanggal",
                label: "Tanggal",
              },
              {
                dataKey: "forwarding.namaPanjang",
                label: "Forwarding",
              },
              {
                dataKey: "jumlah",
                label: "Jumlah",
                type: "number",
              },
            ],
            customOnChange: (data, setValue) => {
              setValue("biayaImportInduk", data)
              setValue("forwarding", data.forwarding)
            },
          }
        : {
            id: "nomor",
            label: "Nomor",
            dataViewLabel: "Nomor Biaya Import",
            disabled: true,
            isGeneratedCodeField: true,
            suffix: {
              id: "tanggal",
              label: "Tanggal",
              type: "date",
              isDataView: true,
              isHalfSize: true,
              value: getToday(),
            },
          },
      ...generateBiayaImportFormItems(isTambahan),
    ]),
    recalculateCallback: calculateTotalPembayaran,
    additionalForms: [
      {
        id: isTambahan
          ? "biayaImportInduk.itemBiayaImports"
          : "itemBiayaImports",
        title: "Daftar Invoice",
        hideAdd: isTambahan,
        async validateDataCb(formData, globData) {
          const bapbId = formData.bapb?.id
          const duplicateBapb = globData.itemBiayaImports?.find(
            (i: any) => i?.bapb?.id === bapbId
          )
          if (!!duplicateBapb) {
            return "Tidak dapat menambah bapb yang sama"
          }
          return undefined
        },
        formItems: genFormItems([
          {
            id: "bapb.id",
            label: "Nomor BAPB",
            disabled: isTambahan,
            type: "async-table-select",
            resourceUrl: `bapb?${genFieldQueryParamStr({
              "itemBiayaImports.id": "null",
              "itemBapb.itemPurchaseOrderStock.purchaseOrderStock.poType_$neq":
                "SAMPLE",
            })}&sortBy=id&sortType=desc`,
            isDataView: true,
            column: 1,
            dataViewKey: "bapb.no",
            customOnChange: (data, setValue) => {
              setValue("bapb", data)
            },
            resourceColumn: [
              {
                dataKey: "no",
                label: "Nomor BAPB",
              },
              {
                dataKey: "noInvoice",
                label: "Nomor Invoice",
              },
              {
                dataKey: "tanggal",
                label: "Tanggal",
              },
              {
                dataKey: "supplier.kodeSupplier",
                label: "Kode Supplier",
              },
              {
                dataKey: "supplier.namaPanjang",
                label: "Nama Supplier",
              },
            ],
          },
          {
            id: "bapb.noInvoice",
            label: "Nomor Invoice",
            isDataView: true,
            disabled: true,
          },
          {
            id: "bapb.tanggal",
            label: "Tanggal",
            isDataView: true,
            type: "date",
            disabled: true,
            suffix: {
              id: "blank",
              type: "blank",
              isOptional: true,
              isHalfSize: true,
            },
          },
          {
            id: "bapb.supplier.kodeSupplier",
            label: "Kode Supplier",
            isDataView: true,
            type: "ignored",
          },
          {
            label: "Nama Supplier",
            id: "bapb.supplier.namaPanjang",
            isDataView: true,
            disabled: true,
          },
        ]),
      },
    ],
  }
}

export default generateBiayaImportForms
