import {
  BasePageProps,
  BulkPageProps,
  AppPageInterface,
  InformasiPageProps,
} from "components/page/type"
import { isPermissionGranted } from "services/permission"
import { AppTypeEnum, GeneralMap, MenuItem } from "./type"

export function mapPageDictToMenu(
  pages: string[],
  prefix: string,
  dict?: GeneralMap<BasePageProps<any>>,
  bulkDict?: GeneralMap<BulkPageProps>,
  informasiDict?: GeneralMap<InformasiPageProps>
): MenuItem[] {
  const result: MenuItem[] = []
  const childWithParentKeys: GeneralMap<boolean> = {}
  for (const menuKey of pages) {
    let type: AppTypeEnum = AppTypeEnum.BASE
    let page: AppPageInterface | undefined = dict?.[menuKey]

    if (!page) {
      page = bulkDict?.[menuKey]
      type = AppTypeEnum.BULK
    }

    if (!page) {
      page = informasiDict?.[menuKey]
      type = AppTypeEnum.INFORMATION
    }

    if (!page) continue

    if (!!page.children) {
      for (const i of page.children) {
        childWithParentKeys[i] = true
      }
    }

    if (!childWithParentKeys[menuKey]) {
      const menu: MenuItem = {
        id: menuKey,
        title: page.title,
        menuUrl: `/${prefix}/${menuKey}`,
        permissionRequired: page.permissionRequired,
        children:
          page.children?.map((child) => {
            const inst =
              bulkDict?.[child] ?? informasiDict?.[child] ?? dict?.[child]
            return {
              id: child,
              title: inst?.title ?? "",
              menuUrl: `/${prefix}/${menuKey}/${child}`,
              permissionRequired: inst?.permissionRequired,
              children: [],
              type: bulkDict?.[child]
                ? AppTypeEnum.BULK
                : informasiDict?.[child]
                ? AppTypeEnum.INFORMATION
                : AppTypeEnum.BASE,
            }
          }) ?? [],
        type,
      }

      result.push(menu)
    }
  }

  return result
}

export function filterMenu(
  isAdmin: boolean,
  permissions: GeneralMap<string[]>,
  origMenus: MenuItem[]
): MenuItem[] {
  const resultMenu: MenuItem[] = []

  for (const menu of origMenus) {
    if (menu.children.length > 0) {
      const filteredChildren = filterMenu(isAdmin, permissions, menu.children)
      if (filteredChildren.length > 0) {
        resultMenu.push({
          ...menu,
          children: filteredChildren,
        })
      }
    } else {
      if (
        isAdmin ||
        isPermissionGranted(permissions[menu.permissionRequired ?? ""])
      ) {
        resultMenu.push(menu)
      }
    }
  }

  return resultMenu
}
