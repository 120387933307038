import { FormModeType } from "components/page/type"
import { FormGroupItem } from "./type"

export function isRequired(
  item: FormGroupItem,
  data: any,
  formMode: FormModeType,
  globVars: Map<string, string>
): boolean {
  if (item.type === "button") return false
  const isDisabled =
    typeof item.disabled === "function"
      ? item.disabled(data, {}, formMode, globVars)
      : item.disabled

  return item.type !== "check" && !item.isOptional && !isDisabled
}
