import { GeneralMap } from "common/type"
import { FormGroupItem } from "components/form/type"
import { FILTER_FROM_FIELD_MAPPER } from "constant/filter-mapper"
import { useCallback, useMemo } from "react"
import { UseFormSetValue } from "react-hook-form"

export function useDependentFormFields(param: {
  items: FormGroupItem[]
  setValue: UseFormSetValue<any>
  mapper?: GeneralMap<any>
}) {
  const itemWithDependChild = useMemo<GeneralMap<any>>(() => {
    const result: GeneralMap<any> = {}
    for (const item of param.items) {
      if (item.dependOn && item.dependOn.length > 0) {
        for (const dependOnItem of item.dependOn) {
          if (!result[dependOnItem]) {
            result[dependOnItem] = [item.id]
          } else {
            result[dependOnItem].push(item.id)
          }
        }
      }
    }

    return result
  }, [param.items])

  const onChangeField = useCallback(
    (_field: string) => {
      const field = (param.mapper ?? FILTER_FROM_FIELD_MAPPER)[_field] ?? _field
      if (itemWithDependChild[field]) {
        for (const idx of itemWithDependChild[field]) {
          param.setValue(idx, null)
          onChangeField(idx)
        }
      }
    },
    [itemWithDependChild, param]
  )

  return { onChangeField }
}
