import {
  BasePageFormGroupItem,
  BasePageURL,
  BulkPageURL,
} from "components/page/type"
import { HttpApiError } from "hooks"
import { GeneralMap, OptionType, PageWithChild } from "./type"

export function generateBulkPageUrl(
  baseEndpoint: string,
  param?: any
): BulkPageURL {
  return {
    read: {
      method: "get",
      endpoint: `/${baseEndpoint}`,
      param,
    },
    update: {
      method: "patch",
      endpoint: `/${baseEndpoint}`,
    },
  }
}

export function generateCrudUrl(
  baseEndpoint: string,
  param?: any
): BasePageURL {
  return {
    read: {
      method: "get",
      endpoint: `/${baseEndpoint}`,
      param,
    },
    create: {
      method: "post",
      endpoint: `/${baseEndpoint}`,
    },
    update: {
      method: "patch",
      endpoint: `/${baseEndpoint}/{id}`,
    },
    delete: {
      method: "delete",
      endpoint: `/${baseEndpoint}/{id}`,
    },
    detail: {
      method: "patch",
      endpoint: `/${baseEndpoint}/{id}`,
    },
    download: {
      method: "get",
      endpoint: `/${baseEndpoint}/{id}/download`,
    },
  }
}

export function formatUnderScore(str: string): string {
  return str
    .split("_")
    .map((i) =>
      i.replace(
        /(\w)(\w*)/g,
        (_, firstChar, rest) => firstChar.toUpperCase() + rest.toLowerCase()
      )
    )
    .join(" ")
}

export function genOptionFromArray(items: string[]): OptionType[] {
  return items.map((item) => ({
    label: formatUnderScore(item),
    value: item,
  }))
}

export function genOptionLiteralFromArray(items: string[]): OptionType[] {
  return items.map((i) => ({
    label: i,
    value: i,
  }))
}

export const basicSearchByKeys: string = "kode,nama"

export function basicKodeNamaMapper(data: any): OptionType {
  return {
    label: `${data?.kode ? `${data?.kode} - ` : ""}${data?.nama}`,
    value: data?.id,
  }
}

export function revertMap(data: GeneralMap<string>): GeneralMap<string> {
  const result: GeneralMap<string> = {}
  for (const idx in data) {
    result[data[idx]] = idx
  }

  return result
}

export function genFormItems(
  items: BasePageFormGroupItem[]
): BasePageFormGroupItem[] {
  return [
    {
      id: "id",
      type: "hidden",
    },
    ...items,
  ]
}

export function genFieldQueryParamStr(param: GeneralMap<any>): string {
  if (Object.keys(param).length === 0) return ""
  const filterFields = [],
    filterValues = []
  for (const i in param) {
    filterFields.push(i)
    filterValues.push(param[i])
  }

  return `filterFields=${filterFields.join(
    ","
  )}&filterValues=${filterValues.join(",")}`
}

export function genFieldQueryParamObj(param: GeneralMap<any>): any {
  const filterFields = [],
    filterValues = []
  for (const i in param) {
    filterFields.push(i)
    filterValues.push(param[i])
  }

  return {
    filterFields: filterFields.join(","),
    filterValues: filterValues.join(","),
  }
}

export function flattenObjectToStr(
  baseStr: string,
  data: GeneralMap<any>
): string {
  let result = baseStr
  for (const i in data) {
    if (typeof data[i] === "object" && !!data[i]) {
      result += `.${flattenObjectToStr(i, data[i])}`
    } else {
      result += `.${i}`
    }
  }

  return result
}

export function flattenObj(ob: any): any {
  let result: any = {}

  for (const i in ob) {
    if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i])
      for (const j in temp) {
        result[i + "." + j] = temp[j]
      }
    } else {
      result[i] = ob[i]
    }
  }
  return result
}

export function getDataByKey(dict: GeneralMap<any> | any, key: string): any {
  const keys = key.split(".")
  let res = dict
  for (const idx of keys) {
    res = res?.[idx]
  }

  return res
}

export function strToSafeNum(
  str: string | number | undefined,
  defaultFallback: number = 0
): number {
  if (!str) return defaultFallback

  return parseFloat(str as string)
}

export function getDataFromDetail(detail: any, _key: string): any {
  if (_key.includes(".")) {
    return getDataByKey(detail as any, _key)
  }
  if (_key.endsWith("Id")) {
    const newKey = _key.replace("Id", "")
    return getDataByKey(detail as any, `${newKey}.id`)
  }
  const res = (detail as any)[_key]
  if (typeof res === "object") {
    return res?.id ?? res?.kode ?? res?.mataUang
  }

  return res
}

export function formatNumber(value: number | undefined): string {
  if (isNaN(value as number)) return ""
  const res =
    typeof value !== "undefined"
      ? new Intl.NumberFormat("id-ID", {}).format(value)
      : ""

  return res
}

export function dateToStr(date: Date): string {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`
}

export function getToday(): string {
  return dateToStr(new Date())
}

export function getTodayPeriodOnly(): string {
  const now = new Date()
  return getPeriodOfDate(now)
}

export function getPeriodOfDate(date: Date) {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`
}

export function translateApiResponse(err: any): HttpApiError {
  let msg = "Terjadi kesalahan! Mohon coba beberapa saat lagi."
  let code = 500
  if (err.response?.data) {
    msg = err.response?.data?.message
    code = err.response?.data?.statusCode
  } else {
    console.log("Unhandled error", err)
  }

  return { code, message: msg }
}

export function createPageChildParentMap(
  data: PageWithChild[]
): GeneralMap<string> {
  const result: GeneralMap<string> = {}

  for (const item of data) {
    for (const child of item.children) {
      result[child] = item.id
    }
  }

  return result
}

export function isDivisiTextile(divisi: any): boolean {
  return divisi?.kode === "2" || divisi?.nama?.toLowerCase() === "textile"
}

export function isDivisiBelting(divisi: any): boolean {
  return divisi?.kode === "1" || divisi?.nama?.toLowerCase() === "belting"
}

export function formatOnlyDate(date: Date | undefined): string {
  if (!date) return ""
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`
}

export function formatLocalDate(date: Date | undefined): string {
  if (!date) return ""
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")} ${String(
    date.getHours()
  ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`
}

export function getRandomInt(max: number): number {
  return Math.floor(Math.random() * max)
}

export function calculatePoPrice(itemPoData: any): number {
  let price: number = strToSafeNum(itemPoData.itemPengadaanStock?.hargaUnit)

  if (!!itemPoData?.itemPengadaanStock?.spesifikasi?.panjang) {
    price *=
      strToSafeNum(itemPoData?.itemPengadaanStock?.spesifikasi?.panjang) / 1000

    if (!!itemPoData?.itemPengadaanStock?.spesifikasi?.lebar) {
      price *=
        strToSafeNum(itemPoData?.itemPengadaanStock?.spesifikasi?.lebar) / 1000
    }
  } else {
    if (!!itemPoData?.itemPengadaanStock?.spesifikasi?.lebar) {
      price *= strToSafeNum(itemPoData?.itemPengadaanStock?.spesifikasi?.lebar)
    }
  }

  return price
}

export function cleanObject(object: any) {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === "object") {
      cleanObject(v)
    }
    if (v === null || v === "") {
      if (Array.isArray(object)) {
        object.splice(parseInt(k), 1)
      } else {
        if (["id", "panjang", "lebar"].includes(k)) delete object[k]
      }
    }
  })

  return object
}

export function queryStringToObject(url: string): any {
  const urlParam = new URLSearchParams(url)
  return Object.fromEntries(urlParam)
}

export function formatKartuStockDisplay(currValue: number, delta: number) {
  return (
    `${formatNumber(+currValue.toFixed(4) + +delta.toFixed(4))}` +
    (!!delta ? ` (${delta > 0 ? "+" : "-"}${formatNumber(delta)})` : "")
  )
}

export function isValueEmpty(value: any) {
  return typeof value === "undefined" || value === null
}
