import { FormGroupItemType } from "components/form/type"
import {
  BasePageAdditionalFormType,
  BasePageFormGroupItem,
  FormModeType,
} from "components/page/type"
import { UseFormReturn } from "react-hook-form"
import { CreateGeneratedCodeRequest } from "services/generated-code.service"

export enum PenawaranTypeEnum {
  STOCK = "STOCK",
  INDENT = "INDENT",
  TTLC = "TTLC",
  SERVICE = "SERVICE",
  JOS = "JOS",
  TRIAL = "TRIAL",
  PINJAMAN = "PINJAMAN",
  SAMPLE = "SAMPLE",
  KHUSUS = "BARANG_KHUSUS",
}

export enum PoTypeEnum {
  STOCK = "STOCK",
  INDENT = "INDENT",
  TTLC = "TTLC",
  SERVICE = "SERVICE",
}

export enum BAPBTypeEnum {
  STOCK = "BARANG",
  SERVICE = "SERVICE",
}

export enum SuratJalanInternalTypeEnum {
  STOCK = "STOCK",
  SPPB = "SPPB",
}

export enum SuratJalanCustomerTypeEnum {
  STOCK = "STOCK",
  SERVICE = "SERVICE",
  BARANG_KHUSUS = "BARANG_KHUSUS",
}

export enum KasbonKaryawanTypeEnum {
  KASBON = "KASBON",
  REIMBURSE = "REIMBURSE",
}

export enum AppTypeEnum {
  BULK = "bulk",
  INFORMATION = "information",
  BASE = "base",
}

export interface OptionType {
  label: string
  value: any
}

export type GeneralMap<T> = { [key: string]: T }

export type AdditionFormType =
  | BasePageAdditionalFormType[]
  | ((formMode: FormModeType) => BasePageAdditionalFormType[])
export interface CommonBasePageProps {
  recalculateCallback?: (
    rhf: UseFormReturn<any>,
    globRhf?: UseFormReturn<any>
  ) => void
  alertMessage?: string | ((data: any) => string | undefined)
  idGenerator?: (data: any) => string
  primaryKey?: string
  additionalForms?: AdditionFormType
  summaryCallback?: ((items: any[]) => {
    label: string
    total: string | number
  })[]
  generateCodeParam?:
    | CreateGeneratedCodeRequest
    | ((data: any, filterData: any) => CreateGeneratedCodeRequest | undefined)
  validateDataCb?: (
    formData: any,
    globData: any,
    formMode?: "update" | "create",
    filters?: any
  ) => Promise<string | undefined>
  disableAdd?: (data: any, filterData: any) => boolean
  hideAdd?: boolean
  requiredToAdd?: string[]
}

export interface MenuItem {
  id: string
  title: string
  menuUrl?: string
  permissionRequired?: string
  type?: AppTypeEnum
  children: MenuItem[]
}

export interface PageWithChild {
  id: string
  title: string
  children: string[]
}

export interface CetakItemParam {
  id: string
  label: string
  type: FormGroupItemType
  value?: any
  disabled?: (data: any) => boolean
}

export interface CetakItem {
  label: string
  url: string
  isRefetch?: boolean
  params?: CetakItemParam[]
}

export interface SpesificModuleFormItem<T> extends BasePageFormGroupItem {
  enabledAt: T[]
}
