import {
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  FormLabel,
  Input,
  ModalFooter,
  Button,
  useToast,
  ModalCloseButton,
  Flex,
  Checkbox,
  Text,
} from "@chakra-ui/react"
import { CetakItem } from "common/type"
import { handleDownload } from "hooks"
import React, { useEffect, useMemo, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import FormError from "components/error/form-error"
import { getDataByKey } from "common/util"
import { useBasePage } from "components/page/common"

interface ModalCetakWithParamProps {
  cetakActionWithParam: CetakItem
  onClose: () => void
  reloadData?: () => Promise<any>
}

export const ModalCetakWithParam: React.FC<ModalCetakWithParamProps> = ({
  cetakActionWithParam,
  onClose,
  reloadData,
}) => {
  const toast = useToast()
  const formRhf = useForm()
  const {
    watch,
    control,
    formState: { errors },
    setValue,
  } = formRhf
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { detail } = useBasePage()

  const data = watch()

  useEffect(() => {
    if (!!cetakActionWithParam.params?.length) {
      for (const i of cetakActionWithParam.params ?? []) {
        const defaultValue = getDataByKey(detail, i.id) ?? i.value
        if (typeof defaultValue !== "undefined") {
          setValue(i.id, defaultValue)
          continue
        }
        if (typeof i.value !== "undefined") {
          setValue(i.id, i.value)
          continue
        }
      }
    }
  }, [cetakActionWithParam, setValue, detail])

  return (
    <Modal isOpen={!!cetakActionWithParam.params?.length} onClose={onClose}>
      <ModalOverlay />

      {/* @ts-ignore */}
      <ModalContent borderRadius="16px">
        <ModalCloseButton
          bg="rgba(138, 146, 166, 0.12)"
          borderRadius={99}
          fontSize={"6px"}
          mt="8px"
          mr="10px"
        />
        <ModalBody paddingTop="48px">
          {cetakActionWithParam?.params?.map((i) => (
            <FormControl isRequired={true}>
              <FormLabel fontSize="12px" fontWeight="normal">
                {i.label}
              </FormLabel>
              <Controller
                control={control}
                name={i.id}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => {
                  if (i.type === "check") {
                    return (
                      <Flex align="center" h="32px">
                        <Checkbox
                          mr="8px"
                          isChecked={value}
                          onChange={({ target: { checked } }) => {
                            onChange(checked)
                          }}
                          _focus={{ outline: "none" }}
                          _checked={{ outline: "none" }}
                          isDisabled={i.disabled?.(detail)}
                        />
                        <Text whiteSpace={"nowrap"}>{i.label}</Text>
                      </Flex>
                    )
                  }
                  return (
                    <Input
                      type={i.type}
                      onChange={({ target: { value: fieldValue } }) =>
                        onChange(fieldValue)
                      }
                      isDisabled={i.disabled?.(detail)}
                      value={value}
                    />
                  )
                }}
              />
              <FormError
                error={getDataByKey(errors, i.id)}
                fieldName={i.label}
              />
            </FormControl>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true)
              await handleDownload(cetakActionWithParam.url, data)
                .then(() => {
                  onClose()
                  reloadData?.()
                })
                .catch((err) => {
                  toast({
                    title: "Gagal",
                    description: err.message ?? "Terjadi Kesalahan",
                    status: "error",
                    duration: 8000,
                    isClosable: true,
                    position: "top",
                  })
                })
                .finally(() => {
                  setIsLoading(false)
                })
            }}
          >
            Cetak
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalCetakWithParam
