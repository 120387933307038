import {
  Modal,
  Text,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react"
import { ColorDict } from "constant/theme"

interface ModalInfoProps {
  isOpen: boolean
  onClose: () => void
  title: string
}

const ModalInfo: React.FC<ModalInfoProps> = ({
  isOpen,
  onClose,
  children,
  title,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
      <ModalOverlay />
      <ModalContent borderRadius="16px">
        <ModalHeader pb="4px">
          <Text color={ColorDict.primary} fontSize={"20px"}>
            {title}
          </Text>
        </ModalHeader>
        <ModalCloseButton
          bg="rgba(138, 146, 166, 0.12)"
          borderRadius={99}
          fontSize={"6px"}
          mt="8px"
          mr="10px"
        />
        <ModalBody pb="20px">
          {typeof children === "string" ? <Text>{children}</Text> : children}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalInfo
