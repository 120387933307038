import { AsyncSelect } from "chakra-react-select"
import { OptionType } from "common/type"
import {
  basicKodeNamaMapper,
  basicSearchByKeys,
  getDataByKey,
} from "common/util"
import { useBasePage, useBasePageDataTable } from "components/page/common"
import { FILTER_FROM_FIELD_MAPPER } from "constant/filter-mapper"
import { SELECT_CHAKRA_STYLES } from "constant/style"
import { getAPI } from "hooks"
import { useEffect, useMemo, useState } from "react"
import { FormFieldProps } from "./type"
import { useStateLogger } from "hooks/logger"

function getObjId(id: string): string {
  let objId = "id"
  const idComp = id.split(".")
  if (idComp.length > 1) {
    objId = idComp.pop() ?? "id"
  }

  return objId
}

const FormGroupAsyncSelect: React.FC<FormFieldProps> = ({
  id,
  value,
  onChange,
  label,
  resourceUrl,
  resourceMapper,
  watchData,
  dependOn,
  disabled,
  formRhf,
  dataViewKey,
  resourceColumnLabelKey,
  customOnChange,
  generateCodeCb,
  onKeyDown,
  baseResources,
  searchBy,
}) => {
  const [rawValue, setRawValue] = useState<any>(null)
  const [rawOptions, setRawOptions] = useState<any[]>([])
  const [selOptions, setSelOptions] = useState<OptionType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [_inputValue, setInputValue] = useState<string>("")
  const { formMode } = useBasePageDataTable()
  const { filterData, globVars, detail, preCheckOrderCb } = useBasePage()
  const { pageRhf: globRhf } = useBasePageDataTable()

  const _resourceMapper = useMemo(
    () => resourceMapper ?? basicKodeNamaMapper,
    [resourceMapper]
  )
  const _id = useMemo(() => getObjId(id), [id])

  const thisFormLabel = useMemo(() => {
    if (!!resourceColumnLabelKey) {
      const labelKeys = resourceColumnLabelKey.split(",")
      const res = []
      for (const labelKey of labelKeys) {
        const d = formRhf.watch(labelKey)
        if (!!d) res.push(d)
      }

      return res.length > 0 ? res.join(" - ") : null
    }
    return value
  }, [formRhf, resourceColumnLabelKey, value])

  const loadOptions = (inputValue: string) => {
    return new Promise((resolve) => {
      setIsLoading(true)
      const params: any = {
        ...(!!inputValue
          ? {
              searchFields: searchBy ?? basicSearchByKeys,
              searchValue: inputValue,
            }
          : {}),
      }
      params["page"] = 1
      params["pageSize"] = 30
      if (dependOn) {
        const filterFields = [],
          filterValues = []
        for (const i of dependOn) {
          const idx = FILTER_FROM_FIELD_MAPPER[i] ?? i
          const watchValue = getDataByKey(watchData, i)
          if (!!watchValue) {
            filterValues.push(watchValue)
            filterFields.push(idx)
          }
        }
        if (filterFields.length > 0) {
          params.filterFields = filterFields.join(",")
          params.filterValues = filterValues.join(",")
        }
      }

      getAPI<any>(
        `/${
          typeof resourceUrl === "function"
            ? resourceUrl(
                formRhf.watch(),
                filterData,
                !globRhf ? {} : globRhf?.watch()
              )
            : resourceUrl
        }`,
        params
      )
        .then((res) => {
          const opts = [...(baseResources ?? []), ...(res?.items ?? [])]
          setRawOptions(opts)
          const result = opts.map(_resourceMapper) ?? []

          setSelOptions(result)
          setInputValue(inputValue)
          resolve(result)
        })
        .finally(() => setIsLoading(false))
    })
  }

  useEffect(() => {
    if (!value && !thisFormLabel) {
      setRawValue(null)
      return
    }
    let __val
    let selectedVal = rawOptions.find((i) => i[_id] === value)
    if (!selectedVal) {
      const labelId = `${id.replace(".id", "")}`
      const d = getDataByKey(detail, labelId)
      if (d && !thisFormLabel) {
        setRawValue(_resourceMapper(d))
      } else if (!!thisFormLabel) {
        setRawValue({
          label: thisFormLabel,
          value: value,
        })
      }
      __val = d
    } else {
      __val = selectedVal
      setRawValue(_resourceMapper(selectedVal))
    }

    if (!!__val) {
      customOnChange?.(
        __val,
        formRhf.setValue,
        formRhf.getValues,
        generateCodeCb,
        globVars,
        preCheckOrderCb,
        globRhf?.watch()
      )
    }
  }, [
    thisFormLabel,
    value,
    globRhf,
    preCheckOrderCb,
    formRhf,
    customOnChange,
    rawOptions,
    resourceMapper,
    generateCodeCb,
    _id,
    _resourceMapper,
    globVars,
    id,
    resourceColumnLabelKey,
    detail,
  ])

  useEffect(() => {
    loadOptions("")
  }, [])

  return (
    <AsyncSelect
      chakraStyles={SELECT_CHAKRA_STYLES}
      onKeyDown={onKeyDown}
      isDisabled={
        typeof disabled === "function"
          ? disabled?.(watchData, globRhf?.watch(), formMode, globVars)
          : disabled
      }
      placeholder={`Pilih ${label}`}
      onInputChange={(inputValue, { action }) => {
        if (action === "input-change") {
          setInputValue(inputValue)
        }
      }}
      value={rawValue}
      onChange={(val: any) => {
        const selectedVal = rawOptions.find((i) => {
          return i[_id] === val?.value
        })

        if (dataViewKey) {
          const dataViewKeyComp = (dataViewKey as string).split(".")
          const valKey = dataViewKeyComp.pop()
          formRhf.setValue(
            dataViewKey as string,
            selectedVal?.[valKey ?? ""] ?? ""
          )
        }
        onChange(val?.value)
        setRawValue(val)
        customOnChange?.(
          selectedVal,
          formRhf.setValue,
          formRhf.getValues,
          generateCodeCb,
          globVars,
          preCheckOrderCb,
          {}
        )
      }}
      defaultOptions={selOptions}
      onFocus={() => {
        loadOptions(_inputValue)
      }}
      loadOptions={loadOptions}
      isLoading={isLoading}
    />
  )
}

export default FormGroupAsyncSelect
