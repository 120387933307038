import { BasePageFormGroupItem } from "components/page/type"

function generateItemKasKecilFormItems(): BasePageFormGroupItem[] {
  return [
    {
      id: "nomor",
      label: "Nomor",
      disabled: true,
      isDataView: true,
    },
    {
      id: "tipe",
      type: "hidden",
    },
    {
      id: "tanggal",
      label: "Tanggal",
      disabled: true,
      type: "date",
      isDataView: true,
    },
    {
      id: "keterangan",
      label: "Keterangan",
      disabled: true,
      isDataView: true,
    },
    {
      id: "jumlah",
      label: "Jumlah",
      disabled: true,
      type: "number",
      isDataView: true,
    },
  ]
}

export default generateItemKasKecilFormItems
