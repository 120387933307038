import { PenawaranTypeEnum } from "common/type"
import {
  generateCrudUrl,
  genFieldQueryParamObj,
  genFieldQueryParamStr,
  genFormItems,
  getToday,
  isDivisiBelting,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import { CIKARANG_ORG_ID } from "constant/etc"
import { genBaseSortQueryParam, genBaseSortQueryParamObj } from "constant/url"

function generateSPBForm(
  tipe: PenawaranTypeEnum,
  isCetak?: boolean
): BasePageProps<any> {
  return {
    title: isCetak ? "Cetak SPB" : "Surat Permintaan Barang/JOS",
    permissionRequired: "SuratFabrikasiBarang",
    generateCodeParam: (data, filter) => {
      const kodeOrg = data.salesContract?.penawaran?.organisasi?.kode
      const orgId = data.salesContract?.penawaran?.organisasi?.id
      if (!kodeOrg) return undefined

      return {
        entityName: "SuratFabrikasiBarang",
        entity: {
          salesContract: {
            penawaran: {
              organisasi: {
                kode: data.salesContract?.penawaran?.organisasi?.kode,
                id: orgId,
              },
            },
          },
        },
      }
    },
    hideAdd: isCetak,
    requiredToAdd: ["Cabang", "Divisi"],
    disableAdd: (data, filterData) =>
      (isCetak && !filterData?.["workshop.id"]) ||
      (!isCetak && !filterData?.["salesContract.penawaran.organisasi.id"]) ||
      !filterData?.["salesContract.penawaran.divisi.id"],
    isOnlyViewData: isCetak,
    urls: {
      ...generateCrudUrl("surat-fabrikasi-barang"),
      read: {
        endpoint: `/surat-fabrikasi-barang`,
        param: {
          ...genFieldQueryParamObj({
            [tipe === PenawaranTypeEnum.STOCK
              ? "salesContract.penawaran.tipe_$In"
              : "salesContract.penawaran.tipe"]:
              tipe === PenawaranTypeEnum.STOCK
                ? "STOCK;TRIAL;BARANG_KHUSUS;KONSINYASI"
                : tipe,
          }),
          ...genBaseSortQueryParamObj("noSpb", false),
        },
        method: "get",
      },
    },
    responseMapper(data) {
      let itemPenawarans: any[] = []
      if (!!data.salesContract?.penawaran?.itemBarangJadis?.length) {
        for (const itemBarangJadi of data.salesContract?.penawaran
          ?.itemBarangJadis) {
          itemPenawarans.push({
            ...itemBarangJadi,
            barang: {
              kode: itemBarangJadi.kodeBarang,
              nama: itemBarangJadi.namaBarang,
            },
          })
        }
      } else {
        itemPenawarans = data.salesContract?.penawaran?.itemPenawarans
      }
      return {
        ...data,
        itemPenawarans,
      }
    },
    filter: <BaseFilter keyName={isCetak ? "cetakSpb" : "spb"} />,
    formGroupItems: genFormItems([
      {
        id: "salesContract.id",
        column: 1,
        label: "Nomor Penawaran",
        type: "async-table-select",
        resourceUrl: (data, filterData) => {
          const q: any = {
            "penawaran.organisasi.id":
              filterData?.["salesContract.penawaran.organisasi.id"],
            "penawaran.divisi.id":
              filterData?.["salesContract.penawaran.divisi.id"],
            "suratFabrikasiBarangs.id": "null",
          }
          if (tipe === PenawaranTypeEnum.STOCK) {
            q["penawaran.tipe_$In"] = "STOCK;TRIAL;BARANG_KHUSUS;KONSINYASI"
          } else {
            q["penawaran.tipe"] = tipe
          }
          if (tipe !== PenawaranTypeEnum.JOS) {
            q["penawaran.isAtSpb"] = true
          }
          return `sales-contract?${genFieldQueryParamStr(
            q
          )}&sortBy=createdAt&sortType=desc`
        },
        isDataView: true,
        resourceColumnLabelKey: "penawaran.no",
        resourceColumn: [
          {
            dataKey: "penawaran.no",
            label: "Nomor Penawaran",
          },
          {
            dataKey: "penawaran.sales.nama",
            label: "Nama Sales",
          },
          {
            dataKey: "penawaran.pelanggan.namaPanjang",
            label: "Customer",
          },
          {
            dataKey: "noPoPelanggan",
            label: "PO Cust.",
          },
        ],
        dataViewKey: "salesContract.penawaran.no",
        customOnChange: (data, setValue, getValue, genCode) => {
          setValue("salesContract", data)
          let itemPenawarans: any[] = []
          if (!!data.penawaran?.itemBarangJadis?.length) {
            for (const itemBarangJadi of data.penawaran?.itemBarangJadis) {
              itemPenawarans =
                data.penawaran?.itemBarangJadis?.map((i: any) => ({
                  ...i,
                  barang: {
                    kode: itemBarangJadi.kodeBarang,
                    nama: itemBarangJadi.namaBarang,
                  },
                })) ?? []
            }
          } else {
            itemPenawarans = data.penawaran.itemPenawarans
          }
          setValue("itemPenawarans", itemPenawarans)
          genCode()
        },
      },
      {
        id: "noSpb",
        label: "Nomor SPB",
        isDataView: true,
        isGeneratedCodeField: true,
        disabled: true,
        suffix: {
          id: "tanggalSpb",
          type: "date",
          label: "Tanggal SPB",
          isDataView: true,
          value: getToday(),
          isHalfSize: true,
        },
      },
      {
        id: "salesContract.tanggal",
        label: "Tanggal Penawaran",
        type: "date",
        disabled: true,
        suffix: {
          id: "salesContract.penawaran.sales.nama",
          label: "Sales",
          disabled: true,
          isHalfSize: true,
        },
      },
      {
        id: "salesContract.penawaran.sales.nama",
        label: "Nama Sales",
        disabled: true,
        isIgnored: true,
        isDataView: true,
      },
      {
        id: "salesContract.penawaran.pelanggan.namaPanjang",
        disabled: true,
        label: "Customer",
        isDataView: true,
      },
      {
        id: "salesContract.noPoPelanggan",
        label: "PO Cust.",
        disabled: true,
        isDataView: true,
        suffix: {
          id: "salesContract.penawaran.pelanggan.kelompokPelanggan.nama",
          label: "Kelompok Customer",
          disabled: true,
          isHalfSize: true,
        },
      },
      {
        id: "workshop.id",
        label: "Dikerjakan di WO -  Cabang",
        type: "async-select",
        resourceUrl: `organisasi?${genBaseSortQueryParam()}&${genFieldQueryParamStr(
          {
            isWorkshop: true,
          }
        )}`,
      },
      {
        id: "note",
        isOptional: true,
        label: "Catatan",
        type: "text-area",
      },
      {
        id: isCetak ? "jlhCetakWo" : "jlhCetak",
        label: "Jumlah Cetak",
        type: "ignored",
        isDataView: true,
      },
      {
        id: "isSpbBatal",
        type: "check",
        label: "SPB Batal",
      },
    ]),
    cetakActions: [
      {
        label: "Cetak",
        url: isCetak
          ? "/surat-fabrikasi-barang/{id}/download-wo"
          : "/surat-fabrikasi-barang/{id}/download",
        isRefetch: true,
      },
    ],
    additionalForms: [
      {
        id: "itemPenawarans",
        title: "Daftar Barang",
        hideAdd: true,
        formItems: [
          ...(tipe === PenawaranTypeEnum.JOS
            ? ([
                {
                  id: "judul",
                  label: "Judul Barang",
                  isDataView: true,
                  disabled: true,
                },
                {
                  id: "hargaJos",
                  label: "Harga",
                  isDataView: true,
                  type: "number",
                  disabled: true,
                },
              ] as any)
            : [
                {
                  id: "barang.kode",
                  label: "Kode Barang",
                  isDataView: true,
                  disabled: true,
                },
                {
                  id: "barang.nama",
                  label: "Nama Barang",
                  isDataView: true,
                  disabled: true,
                },
                {
                  id: "spesifikasi.panjang",
                  label: "L(mm)",
                  isDataView: true,
                  type: "number",
                  disabled: true,
                },
                {
                  id: "spesifikasi.lebar",
                  label: "W(mm)",
                  isDataView: true,
                  type: "number",
                  disabled: true,
                },
              ]),
          {
            id: "qty",
            label: "Quantity",
            isDataView: true,
            type: "number",
            disabled: true,
          },
          {
            id: "keterangan",
            label: "Keterangan",
            isDataView: true,
            disabled: true,
          },
        ],
      },
    ],
  }
}

export default generateSPBForm
