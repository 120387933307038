import { PROFILE_ID_KEY } from "constant"
import { useStateLogger } from "hooks/logger"
import React, { createContext, useContext, useMemo } from "react"
import { useGetProfile, Profile } from "services/profile"

interface AuthContextType {
  profile?: Profile
}

const AuthContext = createContext<AuthContextType>({})

const AuthContextProvider: React.FC = ({ children }) => {
  const { data: profile } = useGetProfile(localStorage.getItem(PROFILE_ID_KEY))

  return (
    <AuthContext.Provider
      value={{
        profile: profile?.data,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)

export default AuthContextProvider
