import { getKursByMataUang } from "hooks/http/custom"
import { SetFieldValue } from "react-hook-form"

export async function calculateValasKurs(
  baseMataUang: string,
  targetMataUang: string
): Promise<number> {
  if (baseMataUang === targetMataUang) return 1

  return new Promise((resolve) => {
    getKursByMataUang(baseMataUang).then((kursBase: number) => {
      getKursByMataUang(targetMataUang).then((kursTarget) => {
        const kurs = +kursBase / +kursTarget

        resolve(+kurs.toFixed(4))
      })
    })
  })
}
export function adjustKursValas(
  baseMataUang: string | undefined,
  targetMataUang: string | undefined,
  setValue: SetFieldValue<any>
) {
  if (!baseMataUang || !targetMataUang) return
  calculateValasKurs(baseMataUang, targetMataUang).then((kurs) => {
    if (!isNaN(kurs)) {
      setValue("rate", kurs)
    }
  })
}
