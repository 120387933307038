import generateItemSalesContractFormItems from "common/form-item-generator/item-sales-contract"
import generateSalesContractFormItems from "common/form-item-generator/sales-contract"
import { AdditionFormType, PenawaranTypeEnum } from "common/type"
import {
  generateCrudUrl,
  genFieldQueryParamObj,
  genFormItems,
} from "common/util"
import BaseFilter from "components/filter/_base"
import { BasePageProps } from "components/page/type"
import { postAPI } from "hooks"

const SalesContractPermissionMapping = {
  [PenawaranTypeEnum.INDENT]:
    "SIT_sekawan::transaksi::franco_stock::marketing::sales_contract",
  [PenawaranTypeEnum.TTLC]:
    "SIT_sekawan::transaksi::TT/LC::marketing::sales_contract",
  [PenawaranTypeEnum.SERVICE]:
    "SIT_sekawan::transaksi::service::marketing::sales_contract",
  [PenawaranTypeEnum.TRIAL]:
    "SIT_sekawan::transaksi::franco_stock::marketing::sales_contract",
  [PenawaranTypeEnum.PINJAMAN]:
    "SIT_sekawan::transaksi::franco_stock::marketing::sales_contract",
  [PenawaranTypeEnum.SAMPLE]:
    "SIT_sekawan::transaksi::franco_stock::marketing::sales_contract",
  [PenawaranTypeEnum.KHUSUS]:
    "SIT_sekawan::transaksi::franco_stock::marketing::sales_contract",
  [PenawaranTypeEnum.JOS]:
    "SIT_sekawan::transaksi::franco_stock::marketing::sales_contract",
  [PenawaranTypeEnum.STOCK]:
    "SIT_sekawan::transaksi::franco_stock::marketing::sales_contract",
}

function generateSalesContractForm(
  tipe: PenawaranTypeEnum
): BasePageProps<any> {
  const additionalForms: AdditionFormType = []
  if (tipe === PenawaranTypeEnum.SERVICE) {
    additionalForms.push({
      id: "penawaran.itemServices",
      title: "Daftar Barang",
      hideAdd: true,
      formItems: [
        {
          id: "namaBarang",
          label: "Barang",
          isDataView: true,
          disabled: true,
        },
        {
          id: "qty",
          label: "Kuantitas",
          type: "number",
          isDataView: true,
          disabled: true,
        },
        {
          id: "ongkos",
          label: "Ongkos",
          type: "number",
          isDataView: true,
          disabled: true,
        },
      ],
    })
  }

  additionalForms.push({
    id: "penawaran.itemPenawarans",
    title: tipe === PenawaranTypeEnum.SERVICE ? "Sparepart" : "Daftar Barang",
    hideAdd: true,
    formItems: generateItemSalesContractFormItems(tipe),
  })
  return {
    title: "Sales Kontrak",
    permissionRequired: SalesContractPermissionMapping[tipe],
    urls: {
      ...generateCrudUrl("sales-contract"),
      read: {
        endpoint: `/sales-contract`,
        method: "get",
        param: {
          ...genFieldQueryParamObj({
            [tipe === PenawaranTypeEnum.INDENT
              ? "penawaran.tipe_$In"
              : "penawaran.tipe"]:
              tipe === PenawaranTypeEnum.INDENT
                ? "STOCK;INDENT;TRIAL;BARANG_KHUSUS;KONSINYASI"
                : tipe,
          }),
          sortBy: "noSalesContract",
          sortType: "desc",
        },
      },
    },
    requiredToAdd: ["divisi", "cabang"],
    disableAdd(data, filterData) {
      return (
        !filterData?.["penawaran.divisi.id"] ||
        !filterData?.["penawaran.organisasi.id"]
      )
    },
    filter: (
      <BaseFilter
        keyName={
          tipe === PenawaranTypeEnum.STOCK
            ? "salesContractStock"
            : "salesContract"
        }
      />
    ),
    preCheckOrderCb(data) {
      return new Promise((resolve) => {
        postAPI("/sales-contract/pre-check", data).then((res: any) => {
          if (!res?.valid) {
            resolve(res.message)
          }
          resolve(undefined)
        })
      })
    },
    generateCodeParam: (data) => {
      if (!data.penawaran?.organisasi?.kode) return undefined
      return {
        entityName: "SalesContract",
        entity: {
          tipe,
          organisasi: {
            kode: data.penawaran?.organisasi?.kode,
          },
        },
      }
    },
    formGroupItems: genFormItems(generateSalesContractFormItems(tipe)),
    additionalForms,
  }
}

export default generateSalesContractForm
