import axios from "axios"
import { apiBaseUrl, getReqHeader } from "./_common"

export async function uploadFile(file: File): Promise<string> {
  const formData = new FormData()
  formData.append("file", file)

  const rsp = await axios({
    url: `${apiBaseUrl}/upload-file`,
    data: formData,
    method: "POST",
    headers: getReqHeader(),
  })

  return rsp.data?.data?.fileUrl ?? ""
}
