import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons"
import { Box, Text, Image, Stack, Flex, Link } from "@chakra-ui/react"
import { MenuItem } from "common/type"
import { MENU } from "constant/menu"
import { ColorDict } from "constant/theme"
import { FC, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { filterMenu } from "common/menu-util"
import { useAuth } from "context/auth.context"
import { useScreenDetector } from "hooks/device"

const MenuIcon = () => {
  return (
    <Flex
      alignItems="center"
      justify="center"
      bg="white"
      borderRadius="12px"
      p="9px"
      cursor="pointer"
      mr="8px"
      minW="32px"
    >
      <Image
        src="/images/common_menu.svg"
        alt="menu"
        width="15px"
        height="15px"
      />
    </Flex>
  )
}

interface MenuProps {
  menu: MenuItem
  level: number
  isWide: boolean
}

const Menu: React.FC<MenuProps> = ({ menu, level, isWide }) => {
  const [isChildOpen, setIsChildOpen] = useState<boolean>(false)
  const [isActive, setIsActive] = useState<boolean>(false)
  const location = useLocation()

  useMemo(() => {
    if (location.pathname !== "/") {
      if (location.pathname.includes(`/${menu.id}`)) {
        setIsChildOpen(true)
      } else {
        setIsChildOpen(false)
      }
    }

    if (location.pathname === menu.menuUrl) {
      setIsActive(true)
    }
  }, [location.pathname, menu.id, menu.menuUrl])

  const containerProps =
    menu.children.length > 0
      ? {
          bg: isChildOpen ? "rgba(72, 138, 199, 0.12)" : "transparent",
          my: "12px",
          borderRadius: "12px",
          boxShadow: "0px 4px 6px rgba(114, 128, 157, 0.02)",
          py: "6px",
          onClick: () => setIsChildOpen((prevState) => !prevState),
          cursor: "pointer",
        }
      : { py: "4px" }

  const LabelComp = !!menu.children?.length ? Text : Link

  return (
    <>
      {/* @ts-ignore */}
      <Flex
        pl="16px"
        ml={`${level * 24}px`}
        alignItems="center"
        color={isActive ? ColorDict.primary : "#8A92A6"}
        {...containerProps}
        _hover={{
          color: ColorDict.primary,
        }}
      >
        <MenuIcon />
        {isWide && (
          <>
            <LabelComp
              href={menu.menuUrl}
              color="inherit !important"
              fontWeight="600"
              fontSize="13px"
              textDecoration="none"
              _hover={{
                textDecoration: "none",
              }}
              minH="20px"
              width="100%"
            >
              {menu.title}
            </LabelComp>
            {menu.children.length > 0 &&
              (isChildOpen ? (
                <ChevronUpIcon mx="8px" />
              ) : (
                <ChevronDownIcon mx="8px" />
              ))}
          </>
        )}
      </Flex>

      {isWide &&
        isChildOpen &&
        menu.children.map((child, keyChild) => (
          <Menu key={keyChild} menu={child} level={level + 1} isWide={isWide} />
        ))}
    </>
  )
}

const MobileTopBar: React.FC<{ menus: MenuItem[] }> = ({ menus }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <Box background={"white"}>
      <Stack
        direction="row"
        align="center"
        py="12px"
        px="16px"
        position="relative"
      >
        <Image
          src="/images/logo_sekawan.png"
          alt="Sekawan"
          width="22px"
          height="22px"
        />
        <Text
          color="#000099"
          fontWeight="700"
          fontSize="14px"
          whiteSpace="nowrap"
        >
          SEKAWAN DASHBOARD
        </Text>
        <Box
          position="absolute"
          right="12px"
          borderRadius="8px"
          boxShadow="0px 4px 6px rgba(114, 128, 157, 0.02)"
          bg="white"
          pt="4px"
          pb="6px"
          px="8px"
          cursor="pointer"
          onClick={() => setIsOpen((prevState) => !prevState)}
        >
          {isOpen ? (
            <ChevronUpIcon color={ColorDict.primary} />
          ) : (
            <ChevronDownIcon color={ColorDict.primary} />
          )}
        </Box>
      </Stack>
      {isOpen && (
        <Box
          maxH="50vh"
          overflow="auto"
          w="100%"
          borderBottom="solid 2px #488ac7"
          pr="12px"
        >
          {menus.map((menu, key) => (
            <Menu menu={menu} key={key} level={0} isWide={true} />
          ))}
        </Box>
      )}
    </Box>
  )
}

const Sidebar = () => {
  const [isWide, setIsWide] = useState<boolean>(true)
  const { profile } = useAuth()
  const { isMobile } = useScreenDetector()

  const menus = useMemo(() => {
    return filterMenu(true, profile?.userPermissions ?? {}, MENU.children)
  }, [profile])

  return !isMobile ? (
    <Box
      minWidth={isWide ? "300px" : "100px"}
      maxH="100vh"
      py="32px"
      px="16px"
      display={["invisible", "block"]}
    >
      <Stack direction="row" align="center" pl="24px" position="relative">
        <Image
          src="/images/logo_sekawan.png"
          alt="Sekawan"
          width="22px"
          height="22px"
        />
        {isWide && (
          <Text
            color="#000099"
            fontWeight="700"
            fontSize="14px"
            whiteSpace="nowrap"
          >
            SEKAWAN DASHBOARD
          </Text>
        )}
        <Box
          position="absolute"
          right="-10px"
          borderRadius="8px"
          boxShadow="0px 4px 6px rgba(114, 128, 157, 0.02)"
          bg="white"
          pt="4px"
          pb="6px"
          px="8px"
          cursor="pointer"
          onClick={() => setIsWide((prevState) => !prevState)}
        >
          {isWide ? (
            <ChevronLeftIcon color={ColorDict.primary} />
          ) : (
            <ChevronRightIcon color={ColorDict.primary} />
          )}
        </Box>
      </Stack>
      <Box mt="32px" maxH="calc(100vh - 100px)" overflow="auto" w="100%">
        {menus.map((menu, key) => (
          <Menu menu={menu} key={key} level={0} isWide={isWide} />
        ))}
      </Box>
    </Box>
  ) : (
    <MobileTopBar menus={menus} />
  )
}

export default Sidebar
