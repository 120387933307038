import loadable from "@react-loadable/revised"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import LoadingPage from "components/loading/loading-page"
import { Box } from "@chakra-ui/react"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js"

import MasterRenderer from "./master"
import { MENU } from "constant/menu"
import { AppTypeEnum, MenuItem } from "common/type"
import BulkPageRenderer from "./bulk"
import InformationPageRenderer from "./information"
import ItemWorkOrderEditor from "components/form/item-work-order-editor"

const LoginPage = loadable({
  loader: () => import("./login"),
  loading: LoadingPage,
})

const HomePage = loadable({
  loader: () => import("./home"),
  loading: LoadingPage,
})

const MasterPerusahaanPage = loadable({
  loader: () => import("./master/perusahaan"),
  loading: LoadingPage,
})

const UserManagementPage = loadable({
  loader: () => import("./user-management"),
  loading: LoadingPage,
})

const ControlPanelPage = loadable({
  loader: () => import("./control-panel"),
  loading: LoadingPage,
})

const AuditTrailPage = loadable({
  loader: () => import("./audit-trail"),
  loading: LoadingPage,
})

const TestPage = loadable({
  loader: () => import("./test"),
  loading: LoadingPage,
})

function renderMenuChildren(item: MenuItem, basePath: string): any {
  return item.children.map((childItem: MenuItem, childKey: number) => {
    const path = `${basePath}/${childItem.id}`
    const Component =
      childItem.type === AppTypeEnum.BULK
        ? BulkPageRenderer(childItem.id)
        : childItem.type === AppTypeEnum.INFORMATION
        ? InformationPageRenderer(childItem.id)
        : MasterRenderer(childItem.id)
    return (
      <>
        <Route key={`${childKey}`} path={path} element={<Component />} />
        {renderMenuChildren(childItem, path)}
      </>
    )
  })
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement
)

const App = () => {
  return (
    <Box bg="#F6F8FE" minH="100vh">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/test" element={<TestPage />} />
          <Route path="/master/perusahaan" element={<MasterPerusahaanPage />} />
          <Route path="/control-panel" element={<ControlPanelPage />} />
          <Route path="/user-management" element={<UserManagementPage />} />
          <Route path="/audit-trail" element={<AuditTrailPage />} />
          {renderMenuChildren(MENU, "")}
        </Routes>
      </BrowserRouter>
    </Box>
  )
}

export default App
